<div class="container-fluid p-0">
  <div class="row ml-1">
    <h2 class="h2">Administer Transforms</h2>
  </div>

  <!-- filtering buttons section -->
  <div class="filter-btns">
    <!-- <button class="btn btn-primary col-1 m-1" (onclick)="displayRules('enabled')">
      Enabled
    </button>
    <button class="btn btn-info col-1 m-1" (onclick)="displayRules('disabled')">
      Disabled
    </button> -->
    <button id="deleted"class="btn col-1 m-1" (click)="displayRules($event)"
    [ngClass]="this.showDeleted ? 'btn-primary' : 'btn-secondary'" >
      Deleted
    </button>
    <button id="all"class="btn col-1 m-1" (click)="displayRules($event)"
    [ngClass]="this.showAll ? 'btn-primary' : 'btn-secondary'"
    >
      All
    </button>
  </div>

  <!-- new rules buttons section -->
  <div class="new-rules-btns">
    <button class="btn btn-info m-2" (click)="openModalDialog(content, 'New')">
      New Rule
    </button>
  </div>

  <!-- rules table -->
  <div class="table-responsive">
    <table class="table mt-3">
      <thead>
        <tr>
          <th scope="col" class="col-2 sort_column"
          (click)="this.orderByAscDec()">Reference</th>
          <th scope="col" class="col-4 sort_column" (click)="this.sortAlphabetically()">Description</th>
          <th scope="col" class="col-3 sort_column" (click)="this.orderByAscDec()">Date</th>
          <th scope="col" class="col-4 text-left">Actions</th>
        </tr>
      </thead>
      <tbody class="table-borderless">
        <tr *ngFor="let rule of displayAdminEnrichmentRules">
          <td>{{rule.id}}</td>
          <td>{{rule.description}}</td>
          <td>{{rule.date_updated}}</td>
          <td>
            <button class="btn btn-primary" (click)="
            openModalDialog(content, 'Edit', rule)" [disabled]="rule.deleted">Edit</button>
            <button class="btn btn-danger" (click)="this.softDeleteRule(rule.id)" [disabled]="rule.deleted">Delete</button>
            <button *ngIf="rule.deleted" class="btn btn-primary" (click)="this.restoreRule(rule.id)">Restore</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- pop-up modal/dialog for creating/editing rules -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{NewOrEditRule}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" ngbAutofocus>
    <app-enrichment-rule [modal]="modal" [editRule]="this.editRule"></app-enrichment-rule>
  </div>
  <!-- <div class="modal-footer"></div> -->
</ng-template>
