import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router'
import {Observable} from 'rxjs'
import {ApiService} from '../services/api.service'
import {GetConfigFileResponse} from '../model/config-responses'

@Injectable({providedIn: 'root'})
export class LatestApprovalResolver implements Resolve<GetConfigFileResponse> {
  constructor(private api: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<GetConfigFileResponse> {
    const sourceSystem = route.paramMap.get('sourceSystem')
    const p1 = route.paramMap.get('p1')
    const p2 = route.paramMap.get('p2')
    const p3 = route.paramMap.get('p3')

    return this.api.get<GetConfigFileResponse>(`/config/latest/lambda-approvals/config/approvals/${sourceSystem}/${p1}/${p2}/${p3}/Approvals.json`)
  }
}
