import {Injectable} from '@angular/core'
//import {AmplifyService} from '@flowaccount/aws-amplify-angular'
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import * as JSZip from 'jszip'
import {ToastrService} from 'ngx-toastr'
import {BehaviorSubject, combineLatest, from, merge, Observable, of, Subject} from 'rxjs'
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
  tap,
  toArray
} from 'rxjs/operators'
import {ChangeDetails} from '../common/domain/change'
import {ConfigDetail, ConfigSource} from '../common/domain/config'
import {
  DocgenConfig,
  DocgenConfigAndMetadata,
  DocgenConfigKeyIndex,
  DocgenConfigMetadata
} from '../common/domain/docgen-config'
import {
  PublishConfig,
  PublishConfigAndMetadata,
  PublishConfigKeyIndex,
  PublishConfigMetadata,
  TargetSystem,
} from '../common/domain/publish-config'
import {VersionSummary} from '../common/domain/version'
import {SaveConfigRequest} from '../model/config-requests'
import {
  GetConfigFileResponse,
  ListConfigDetailsResponse,
  PublishConfigFailedResponse,
  PublishConfigResponse
} from '../model/config-responses'
import {ChangeDetectingBehaviourSubject} from '../util/change-detecting-behaviour-subject'
import {ApiService} from './api.service'
import {PermissionsService} from './permissions.service'
import { TestManagerService } from './test-manager.service'
import {StatusService} from './status.service'
import {WorkspaceService} from './workspace.service'
import {DateTime} from 'luxon'
import {ConfigVersion, FullConfig} from '../model/infra-config-response'
import {Environment} from './environment-service';
import {AuditInfo} from '../model/ruleset-audit';
// import adyJSon from './dev-test-json/lambda-ingest-product-ady.json'

const deepEqual = require('deep-equal')

export interface Template {
  name: string
  path: string
  versions: TemplateVersion[]
}

export interface ConfigBlob {
  data: string
}

export interface TemplateVersion {
  commitId: string
  date: DateTime
  environment: string
}

export interface CommitChanges extends CommitCommon {
  parents: ChangeSet[]
}

interface BasicChange {
  type: string
  blob: string
  path: string
}

interface Deletion extends BasicChange {
  type: 'DELETE'
}

interface Addition extends BasicChange {
  type: 'ADD'
}

interface Modification extends BasicChange {
  type: 'MODIFY'
  originalBlob: string
  originalPath?: string
}

type Change = Deletion | Addition | Modification

interface ChangeSet {
  parentId: string
  changes: Change[]
}

interface CommitCommon {
  id: string
  message: string
  date: DateTime
  user: string
  email: string
}

export interface DeployedVersion {
  environment: Environment
  accountVersion: ConfigVersion
  commitLog: CommitLog | undefined
}

interface CommitLog {
  id: string
  message: string
  date: string
  user: string
  email: string
  parents: string[]
}

interface BlobMetadata {
  blobId?: string
  path?: string
  mode?: string
}

interface Difference {
  beforeBlob?: BlobMetadata
  afterBlob?: BlobMetadata
  changeType?: ChangeTypeEnum
}

type ChangeTypeEnum = 'A' | 'M' | 'D' | string

export type DifferenceList = Difference[]

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  initial$ = of(undefined)
  reload$ = new Subject()

  loadedConfig: Observable<ListConfigDetailsResponse[]> = merge(this.initial$, this.reload$)
    .pipe(
      switchMap(_ => {
        return of('lambda-ingest-product', 'api-publish', 'auto-deploy-templates', 'lambda-approvals')
          .pipe(
            // tap(res => {
            //   console.log('Loading configs for ' + res)
            // }),
            mergeMap((source: ConfigSource) => {
              return this.api.get<ListConfigDetailsResponse>('/config/details/' + source)
                .pipe(
                  // tap(res => {
                  //   console.log('Loaded configs response for ' + source)
                  //   console.dir(res)
                  // }),
                  switchMap(res => {
                    if (typeof res.configs === 'string') {
                      console.debug('Unzipping: start')
                      const zip = new JSZip()
                      return zip.loadAsync(res.configs, {base64: true})
                        .then(zipObject => {
                          const unzippedData = zipObject.file('config').async('text')
                          console.debug('Unzipping: end')
                          return unzippedData
                        })
                        .then((configs: string) => {
                          console.debug('Loading config from zip content: start')
                          res.configs = JSON.parse(configs) as ConfigDetail[]

                          // console.info('Unzipped ' + source)
                          // console.dir(res.configs)

                          // console.debug('configs  from zip content:')
                          // console.dir(res.configs)
                          console.debug('Loading config from zip content: end')
                          return res
                        })
                      // Paul Lockwood's code to Override with data from a local .json file
                      // .then((fullConfig: FullConfig) => {
                      //   if (source === 'lambda-ingest-product') {
                      //     fullConfig.configs = (adyJSon as any).configs as ConfigDetail[];
                      //   }
                      //   return fullConfig
                      // })
                    }
                    return of(res)
                  }),
                )
            }),

            // Paul Lockwood's code to filter to a specfic mnemonic
            //  The json can then be saved locally, tinkered with and loaded above to override what comes from AWS
            //  This is useful during development
            // map((curFullConfig: FullConfig) => {

            //   if (curFullConfig.source === 'lambda-ingest-product') {

            //     // e.g. 'lambda-ingest-product' has 202 configs. This code filters to one Mnemonic type
            //     curFullConfig.configs = (curFullConfig.configs as ConfigDetail[]).filter((curConfigDetail: ConfigDetail) => {

            //       // filteredConfigs = (curFullConfig.configs as ConfigDetail[]).filter((curConfigDetail: ConfigDetail) => {
            //       let useThisOne = false;

            //       if (curConfigDetail.path.toLowerCase().includes('config.json')) {
            //         const docGenConfig = JSON.parse(curConfigDetail.content) as DocgenConfig;
            //         // console.dir(docGenConfig)

            //         if (docGenConfig !== undefined && docGenConfig.analysePayload !== undefined && docGenConfig.analysePayload.rules !== undefined) {
            //           const foundIndex = docGenConfig.analysePayload.rules.findIndex(curAnalyseRuleTable => curAnalyseRuleTable.mnemonic === 'ADY')
            //           useThisOne = foundIndex !== -1
            //         }

            //         // If a RuleSet is DW, then dump all other RuleSets
            //         if (useThisOne) {
            //           docGenConfig.analyseEnriched = new AnalyseConfig()
            //           docGenConfig.analysePayload.rules = docGenConfig.analysePayload.rules.filter(x => x.mnemonic === 'ADY')
            //           curConfigDetail.content = JSON.stringify(docGenConfig)
            //         }
            //       }
            //       // console.info(useThisOne + ' <<')
            //       return useThisOne

            //     }); // curFullConfig.configs =

            //   } // if 'lambda-ingest-product'

            //   // curFullConfig.configs = new Array<ConfigDetail>()
            //   console.info('return curFullConfig: ' + curFullConfig.source + ' - ' + curFullConfig.configs.length)
            //   return curFullConfig;
            // }),
            // tap(res => {
            //   const stringified = JSON.stringify(res)
            //   console.info(stringified)
            // }),

            toArray(),

            // tap(res => {
            //   console.log('Configs are Loaded:')
            //   console.dir(res)
            //   console.log(JSON.stringify(res[0]))
            //   console.log(JSON.stringify(res[1]))
            //   console.log(JSON.stringify(res[2]))
            //   console.log(JSON.stringify(res[3]))
            // }),
          )
      }),
    ).pipe(
      distinctUntilChanged(deepEqual),
      shareReplay(1),
    )

  loadedVersions: Observable<VersionSummary[]> = this.loadedConfig
    .pipe(
      map(res => res.map(r => {
        return {
          source: r.source,
          version: r.version.version,
          buildTime: r.version.buildTime,
          buildNumber: r.version.buildNumber,
        }
      })),
      map(res => {
        return res.sort((x, y) => x.source.localeCompare(y.source))
      }),
      // tap(res => {
      //   console.log('Sorted loaded versions')
      //   console.dir(res)
      // }),
    ).pipe(
      distinctUntilChanged(deepEqual),
      shareReplay(1),
      // tap(res => {
      //   console.log('Final loaded versions')
      //   console.dir(res)
      // }),
    )

  allConfigs = this.loadedConfig
    .pipe(
      map(configs => {
        return configs.reduce((a, c) => {
          return a.concat(c.configs)
        }, [])
      }),
      // tap((configs: ConfigDetail[]) => {
      //   console.log('Loaded configs - flattened')
      //   console.dir(configs)
      // }),
      distinctUntilChanged(deepEqual),
      shareReplay(1),
    )

  allDocgenConfigs = this.allConfigs
    .pipe(
      map(configs => configs.filter(config => config.source === 'lambda-ingest-product')),
      // tap((configs) => {
      //   console.log('Loaded docgen configs')
      //   console.dir(configs)
      // }),
      distinctUntilChanged(deepEqual),
      shareReplay(1),
    )

  allPublishConfigs = this.allConfigs
    .pipe(
      map(configs => configs.filter(config => config.source === 'api-publish')),
      // tap((configs) => {
      //   console.log('Loaded publish configs')
      //   console.dir(configs)
      // }),
      distinctUntilChanged(deepEqual),
      shareReplay(1),
    )

  allTemplateConfigs = this.allConfigs
    .pipe(
      map(configs => configs.filter(config => config.source === 'auto-deploy-templates')),
      // tap((configs) => {
      //   console.log('Loaded templates')
      //   console.dir(configs)
      // }),
      distinctUntilChanged(deepEqual),
      shareReplay(1),
    )


  // Used by Config-Filter screen
  // plugh - Can this code be relocated?
  private filteredDocgenConfigsSubject = new BehaviorSubject<DocgenConfigMetadata[]>([])
  public filteredDocgenConfigs = this.filteredDocgenConfigsSubject
    .pipe(
      distinctUntilChanged(deepEqual),
      shareReplay(1),
    )

  public docgenConfigsFiltered = combineLatest([
    this.allDocgenConfigs,
    this.workspaceService.selectedWorkspace,
    this.filteredDocgenConfigs,
  ]).pipe(
    // tap(([configs, workspace]) => console.log('Processing ' + configs.length + ' configs in ' + workspace)),
    map(([configDetails, workspace, filteredDocgenConfigs]) => {
      if (configDetails === undefined) {
        return []
      }
      if(filteredDocgenConfigs && filteredDocgenConfigs.length > 0) {
        this.testManagerService.setFilterCleared(false);
      } else {
        this.testManagerService.setFilterCleared(true);
      }
      const settings = configDetails
        .filter(config => config.path.startsWith('settings'))
      const schemas = configDetails.filter(config => config.path.startsWith('schemas'))
      const transforms = configDetails.filter(config => config.path.startsWith('transforms'))
      const rulesets = configDetails.filter(config => config.path.startsWith('rulesets'))
      const configs = settings.map(setting => {
        const match = setting.path.match(/^.*\/([^\/]+)\/([^\/]+)\/([^\/]+)\/([^\/]+)\/Config\.json$/)
        if (match) {
          const sourceSystem = match[DocgenConfigKeyIndex.SOURCE_SYSTEM]
          const programme = match[DocgenConfigKeyIndex.PROGRAMME]
          const productType = match[DocgenConfigKeyIndex.PRODUCT_TYPE]
          const documentType = match[DocgenConfigKeyIndex.DOCUMENT_TYPE]
          const path = `${sourceSystem}/${programme}/${productType}/${documentType}`

          if (filteredDocgenConfigs && filteredDocgenConfigs.length > 0) {
            const findIndex = filteredDocgenConfigs.findIndex(
              (x) =>
                x.sourceSystem === sourceSystem
                && x.programme === programme
                && x.productType === productType
                && x.documentType === documentType,
            )
            if (findIndex === -1) {
              return undefined
            }
          }

          const config: DocgenConfig = JSON.parse(setting.content)

          const configSchemas = []
            .concat(config.validatePayload.schema)
            .concat(config.validateEnriched.schema)
            .filter(x => x)
          const configTransforms = []
            .concat(config.transform.transforms)
            .concat(config.enrich.transforms)
            .filter(x => x)

          const configRulesets = rulesets.filter(x=> JSON.parse(x.content).entries.includes(path))

          const docgenConfigResult: DocgenConfigAndMetadata = {
            metadata: {
              sourceSystem: sourceSystem,
              programme: programme,
              productType: productType,
              documentType: documentType,
            },
            settings: setting,
            schemas: schemas.filter(schema => configSchemas.includes(schema.path)),
            transforms: transforms.filter(transform => configTransforms.includes(transform.path)),
            rulesets: configRulesets
          }
          return docgenConfigResult
        }
      }).filter(x => x)
      return configs
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  filteredPublishConfigsSubject = new BehaviorSubject<PublishConfigAndMetadata[]>([])
  filteredPublishConfigs = this.filteredPublishConfigsSubject
    .pipe(
      distinctUntilChanged(deepEqual),
      shareReplay(1),
    )

  publishConfigs = combineLatest([
    this.allPublishConfigs,
    this.workspaceService.selectedWorkspace,
  ]).pipe(
    // tap(([configs, workspace]) => console.log('Processing ' + configs.length + ' configs in ' + workspace)),
    map(([configDetails, workspace]) => {
      if (configDetails === undefined) {
        return []
      }
      const publishSettings = configDetails
        .filter(config => config.path.startsWith('settings'))
      const schemas = configDetails.filter(config => config.path.startsWith('schemas'))
      const transforms = configDetails.filter(config => config.path.startsWith('transforms'))
      const configs = publishSettings.map(setting => {
        const match = setting.path.match(/^.*\/([^\/]+)\/([^\/]+)\/([^\/]+)\/([^\/]+)\/Config\.json$/)
        if (match) {
          const sourceSystem = match[PublishConfigKeyIndex.SOURCE_SYSTEM]
          const businessUnit = match[PublishConfigKeyIndex.BUSINESS_UNIT]
          const payloadType = match[PublishConfigKeyIndex.PAYLOAD_TYPE]
          const targetSystem = match[PublishConfigKeyIndex.TARGET_SYSTEM] as TargetSystem
          const loaded = JSON.parse(setting.content) as PublishConfig
          const config = Object.assign(PublishConfig.create(), loaded)

          const configSchemas = []
            .concat(config.validatePayload.schema)
            .concat(config.validateEnriched.schema)
            .filter(x => x)
          const configTransforms = []
            .concat(config.transform.transforms)
            .concat(config.enrich.transforms)
            .concat(config.combine.transforms)
            .concat(config.encapsulate.transforms)
            .filter(x => x)

          const publishConfigResult: PublishConfigAndMetadata = {
            metadata: {
              sourceSystem: sourceSystem,
              businessUnit: businessUnit,
              payloadType: payloadType,
              targetSystem: targetSystem,
            },
            settings: setting,
            schemas: schemas.filter(schema => configSchemas.includes(schema.path)),
            transforms: transforms.filter(transform => configTransforms.includes(transform.path)),
          }
          return publishConfigResult
        }
      }).filter(x => x)
      return configs
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  private selectedDocgenConfigSubject = new ChangeDetectingBehaviourSubject<DocgenConfigAndMetadata>('Selected Docgen Config', undefined)
  selectedDocgenConfig = this.selectedDocgenConfigSubject.asObservable()

  private selectedPublishConfigSubject = new ChangeDetectingBehaviourSubject<PublishConfigAndMetadata>('Selected Publish Config', undefined)
  selectedPublishConfig = this.selectedPublishConfigSubject.asObservable()

  private readOnly = true

  constructor(
    private api: ApiService,
    //private amplifyService: AuthenticatorService,
    private statusService: StatusService,
    private testManagerService: TestManagerService,
    private workspaceService: WorkspaceService,
    private permissionsService: PermissionsService,
    // private changeService: ChangeService,
    private toast: ToastrService,
  ) {
    permissionsService.readonly.subscribe(permission => this.readOnly = permission)
  }

  publishChangesToRepositories(commitMessage: string, files: ChangeDetails[]): Observable<PublishConfigResponse[]> {
    if (this.readOnly) {
      this.toast.warning('This would have published your changes into the pipeline for deployment')
      return
    }
    return of('lambda-ingest-product', 'api-publish', 'auto-deploy-templates', 'lambda-approvals')
      .pipe(
        filter((source: ConfigSource) => files.some(change => change.source === source)),
        // tap(r => {
        //   console.log('Affected repos')
        //   console.dir(r)
        // }),
        mergeMap((source: ConfigSource) => {
          return this.loadedVersions
            .pipe(
              take(1),
              switchMap((versions) => {
                const version = versions.find(version => version.source === source)
                const request: SaveConfigRequest = {
                  delete: files.filter(f => f.state === 'toDelete' && f.source === source).map(f => f.path),
                  update: files.filter(f => f.state !== 'toDelete' && f.source === source),
                  commitMessage,
                  parentCommit: version.version,
                }
                console.log('Publishing to ' + source)
                console.dir(request)

                return this.api.post<SaveConfigRequest, PublishConfigResponse>('/config/publish/' + source, request)
                  .pipe(
                    tap(res => {
                      console.log('Publish Response from ' + source)
                      console.dir(res)
                    }),
                    catchError(e => {
                      const response: PublishConfigFailedResponse = {
                        source: source,
                        message: 'Failed to call API',
                      } as PublishConfigFailedResponse
                      return of(response)
                    }),
                  )
              }),
            )
        }),
        // tap(r => {
        //   console.log('Before toArray repos')
        //   console.dir(r)
        // }),
        toArray(),
        // tap(r => {
        //   console.log('after toArray repos')
        //   console.dir(r)
        // }),
      )
  }

  selectDocgenConfig(configMetadata: DocgenConfigMetadata) {
    this.docgenConfigsFiltered.pipe(
      map(configs => {
        const config = configs.find(c => {
            if(configMetadata['dynamicMapping'] && configMetadata['dynamicMapping'] === 'true') {
              return c.metadata.sourceSystem && configMetadata.sourceSystem &&
                c.metadata.documentType && configMetadata.documentType &&
                c.metadata.sourceSystem === configMetadata.sourceSystem &&
                c.metadata.documentType === configMetadata.documentType
            }
            return c.metadata &&
              configMetadata &&
              c.metadata.sourceSystem === configMetadata.sourceSystem &&
              c.metadata.programme === configMetadata.programme &&
              c.metadata.productType === configMetadata.productType &&
              c.metadata.documentType === configMetadata.documentType
          },
        )
        return config
      }),
    ).subscribe(config => {
      this.selectedDocgenConfigSubject.next(config)
    })
  }


  selectPublishConfig(configMetadata: PublishConfigMetadata) {
    this.publishConfigs.pipe(
      map(configs => {
        const config = configs.find(c => {
            return c.metadata &&
              configMetadata &&
              c.metadata.sourceSystem === configMetadata.sourceSystem &&
              c.metadata.businessUnit === configMetadata.businessUnit &&
              c.metadata.payloadType === configMetadata.payloadType &&
              c.metadata.targetSystem === configMetadata.targetSystem
          },
        )
        return config
      }),
    ).subscribe(config => {
      this.selectedPublishConfigSubject.next(config)
    })
  }

  reload() {
    this.reload$.next()
  }

  setFilteredDocgenConfigs(configs: DocgenConfigMetadata[]) {
    this.filteredDocgenConfigsSubject.next(configs)
  }

  setFilteredPublishConfigs(configs: PublishConfigAndMetadata[]) {
    this.filteredPublishConfigsSubject.next(configs)
  }

  /**
   * Returns a promise for a collection of commit changes
   */
  getConfigHistory(repositoryName: string, depth: number, days: number): Observable<CommitChanges[]> {
    return this.api.get<CommitChanges[]>('/config/history/' + repositoryName + '/' + depth + '/' + days)
  }

  getConfigBlob(source: string, blobId: string, decode: boolean=false): Observable<ConfigBlob> {
    return this.api.get<ConfigBlob>('/config/blob/' + source + '/' + blobId)
    .pipe(map(d => {
      if(decode) {
      const decoded = atob(d.data);
      const obj : ConfigBlob = {data: decoded}
       return obj;
      }
      return d;
    }))
  }

  getConfigVersion(source: string, account: string): Observable<ConfigVersion> {
    return this.api.get<ConfigVersion>(`/config/version/${source}/${account}/LONDON`);
  }

  getConfigFile(source: string, version: string, path: string): Observable<GetConfigFileResponse> {
    return this.api.get<GetConfigFileResponse>(`/config/commit/${version}/${source}/${path}`)
  }

  getDeployedVersions(config: string, environments: Environment[]): Observable<DeployedVersion[]> {

    return from(environments).pipe(
      mergeMap(environment => {
        return this.getConfigVersion(config, environment.id.toLocaleLowerCase()).pipe(
          map((accountVersion: ConfigVersion) => {
            return {
              environment: environment,
              accountVersion: accountVersion
            } as DeployedVersion
          })
        )
      }),
      toArray()
    )
  }

  getConfigDifferences(repository: string, commitId: string, parentId: string) {
    return this.api.get<Difference[]>('/config/differences/' + repository + '/' + commitId + '/' + parentId)
  }

  async getConfigForEnvironment(sources: string[], environment: string, region: string): Promise<FullConfig[]> {
    return await Promise.all(sources.map(async source => {
      return await this.api.get<ListConfigDetailsResponse>('/config/details/' + source + '/' + environment + '/' + region)
        .pipe(take(1)).toPromise()
        .then( response => {
          if(typeof response.configs === 'string') {
            const zip = new JSZip()
            return zip.loadAsync(response.configs, {base64: true})
              .then(zipObject => {
                const unzippedData = zipObject.file('config').async('text')
                console.debug('Unzipping: end')
                return unzippedData
              }).then((configs: string) => {
                response.configs = JSON.parse(configs) as ConfigDetail[]
                return response
              })
          }
          return response
        })
    }))
  }

  rollbackCommitChanges(message: string, editedConfigChange: ChangeDetails[]) {

    this.publishChangesToRepositories(message, editedConfigChange).subscribe((result: PublishConfigResponse[]) => {

      const statusId = this.statusService.start('Reverting commit')

      this.statusService.complete(statusId)
      this.reload()

      if (result.every(res => res.status === 'OK')) {
        this.statusService.clear('Reverting commit')
        this.statusService.start('Configuration published', 'ALERT', 5)
        this.toast.success('Files committed to Repository', 'Published Successfully')
      } else {
        this.statusService.start('FAILED TO REVERT COMMIT!', 'ALERT', 30)
        this.toast.error(result
          .filter(res => res.status !== 'OK' && res.message)
          .map((res: PublishConfigFailedResponse) => res.source + ': ' + res.message)
          .join(''), 'REVERT COMMIT FAILED')
      }
    })
  }

  getAuditFile(configType: string, name: string) {
    return this.api.get<AuditInfo[]>(`/config/audit/${configType}/${name}`)
  }
}
