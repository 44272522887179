<div class="jumbotron jumbotron-fluid">
  <div class="container">
    <h1 class="display-4">Rule Approval {{this.fullMnemonic}}</h1>
    <hr class="my-4">
    <p class="lead display-contents">Please ensure that the following information is correct before approving this rule.</p>
    <div class="container">
      <div class="row" *ngIf="ruleTable">
        <div class="col-sm-4">
          Rule Description
        </div>
        <div class="col-sm-8">
          {{ruleTable.description}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          Rule
        </div>
        <div class="col-sm-8">
          {{this.fullMnemonic}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          Service
        </div>
        <div class="col-sm-8">
          {{service}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          Source System
        </div>
        <div class="col-sm-8">
          {{this.docgenConfigMetadata?.sourceSystem}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          Programme / Business Unit
        </div>
        <div class="col-sm-8">
          {{this.docgenConfigMetadata?.programme}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          Product / Payload Type
        </div>
        <div class="col-sm-8">
          {{this.docgenConfigMetadata?.productType}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          Document Type / Target System
        </div>
        <div class="col-sm-8">
          {{this.docgenConfigMetadata?.documentType}}
        </div>
      </div>
    </div>
    <hr class="my-4">
    <button type="button" class="btn btn-primary btn-lg" [disabled]="approving" *ngIf="canApprove()"
            (click)="approve()">Approve
    </button>
    <div class="error" *ngIf="warning">{{warning}}</div>
    <div class="error" *ngIf="broken">FAILED TO LOAD - PLEASE TRY AGAIN</div>
    <div class="success" *ngIf="alreadyApproved()">This has already been approved
      <ng-container *ngIf="approvedBy()">by {{approvedBy() | titlecase}}</ng-container>
      <ng-container *ngIf="approvedDate()"> {{approvedDate() | amTimeAgo}}</ng-container>
    </div>
    <ng-container *ngIf="ruleTable && ruleRow">
      <hr class="my-4">
      <p class="lead display-contents">Matching Rule
        <ng-container *ngIf="ruleRow.description"> : {{ruleRow.description}}</ng-container>
      </p>
      <div class="container rule-definition">
        <div class="row" *ngFor="let field of ruleTable.fields; index as i">
          <div class="col-sm-6">
            {{field}}
          </div>
          <div class="col-sm-6">
            {{ruleRow.expected[i]}}
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
