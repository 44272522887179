import {Component, OnInit} from '@angular/core';
import {FullConfig} from '../../model/infra-config-response';
import {ConfigService} from '../../services/config.service';
import {ConfigDetail} from '../../common/domain/config';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

export interface ReleaseChanges {
  environment: string
  allSourceConfigs?: FullConfig[]
  config?: FullConfig
  lowerEnv: boolean
}

@Component({
  selector: 'app-docgen-release-changes',
  templateUrl: './docgen-release-changes.component.html',
  styleUrls: ['./docgen-release-changes.component.scss']
})
export class DocgenReleaseChangesComponent implements OnInit {

  public environment: string
  public lowerEnvironment: string
  public loadedData = false
  private loadedDataChange: Subject<boolean> = new Subject<boolean>();
  public environmentDisplayName: string

  public showRules = false
  public showConfiguration = false
  public showSchemas = false
  public showTransforms = false
  public showTemplates = false
  public filteredConfig: ReleaseChanges[]
  public allConfigs: ReleaseChanges[]
  private _subscriptions = new Array<Subscription>()

  private rulesetsConfig: ReleaseChanges[]
  private transformsConfig: ReleaseChanges[]
  private schemasConfig: ReleaseChanges[]
  private configurationsConfig: ReleaseChanges[]
  private templatesConfig: ReleaseChanges[]

  constructor(
    private configService: ConfigService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._subscriptions.forEach(x => x.unsubscribe())
    this.allConfigs = []
    this._activatedRoute.params.subscribe(parameter => {
      this.environment = parameter.env
    })
    this.lowerEnvironment = this.getLowerEnvironment()
    this.getConfig([this.environment, this.lowerEnvironment])

    this.loadedDataChange.subscribe((value) => {
      this.loadedData = value
      this.displayRules()
    })

    this.environmentDisplayName = this.environment
  }

  async getConfig(environments: string[]) {
    environments.map( async env => {
      await this.configService.getConfigForEnvironment(['lambda-ingest-product', 'auto-deploy-templates'], env, 'london')
        .then(
          fullConfig => {
            const lowerEnv = env === this.lowerEnvironment ? true : false
            return {environment: env, allSourceConfigs: fullConfig, lowerEnv: lowerEnv}
          }
        ).then(configs => {
            this.allConfigs.push(configs)
            if (this.allConfigs.length > 1) {
              this.filterConfigByType('rulesets', 'lambda-ingest-product').then(rulesConfig => {
                this.rulesetsConfig = rulesConfig
              })
              this.filterConfigByType('transforms', 'lambda-ingest-product').then(transformsConfig => {
                this.transformsConfig = transformsConfig
              })
              this.filterConfigByType('schemas', 'lambda-ingest-product').then(schemasConfig => {
                this.schemasConfig = schemasConfig
              })
              this.filterConfigByType('settings', 'lambda-ingest-product').then(configurationConfig => {
                this.configurationsConfig = configurationConfig
              })
              this.filterConfigByType('templates', 'auto-deploy-templates').then(templateConfiguration => {
                this.templatesConfig = templateConfiguration
              })
            }
        }).finally( () =>{
          if (this.rulesetsConfig && this.transformsConfig && this.schemasConfig && this.configurationsConfig && this.templatesConfig) {
            this.loadedDataChange.next(true)
          }
        })
    })
  }

  displayRules() {
    this.refresh()
    this.filteredConfig = this.rulesetsConfig
    this.showRules = !this.showRules
  }

  displayTransforms() {
    this.refresh()
    this.filteredConfig = this.transformsConfig
    this.showTransforms = !this.showTransforms
  }

  displaySchemas() {
    this.refresh()
    this.filteredConfig = this.schemasConfig
    this.showSchemas = !this.showSchemas
  }

  displayConfiguration() {
    this.refresh()
    this.filteredConfig = this.configurationsConfig
    this.showConfiguration = !this.showConfiguration
  }

  displayTemplates() {
    this.refresh()
    this.filteredConfig = this.templatesConfig
    this.showTemplates = !this.showTemplates
  }

  filterConfigByType(typeFilter: string, source: string): Promise<ReleaseChanges[]> {
    const filteredConfig: ReleaseChanges[] = []
    const copiedAllConfigs: ReleaseChanges[] = JSON.parse(JSON.stringify(this.allConfigs))
    copiedAllConfigs.map(envConfig => {
      envConfig.allSourceConfigs.map(sourceConfig => {
        if(sourceConfig.source === source) {
          const copiedSourceConfig = Object.assign({}, sourceConfig)
          const configDetails = copiedSourceConfig.configs as ConfigDetail[]
          copiedSourceConfig.configs = configDetails.filter(c => c.path.startsWith(typeFilter))
          filteredConfig.push({environment: envConfig.environment, config: copiedSourceConfig, lowerEnv: envConfig.lowerEnv})
        }
      })
    })
    return Promise.resolve(filteredConfig)
  }

  getLowerEnvironment() {
    let lowerEnvironment: string
    if(this.environment === 'dev') {
      lowerEnvironment = 'tools'
    } else if (this.environment === 'uat') {
      lowerEnvironment = 'dev'
    } else if (this.environment === 'prod') {
      lowerEnvironment = 'uat'
    }
    return lowerEnvironment
  }

  refresh() {
    this.filteredConfig = []
    this.showRules = false
    this.showConfiguration = false
    this.showSchemas = false
    this.showTransforms = false
    this.showTemplates = false
  }

}
