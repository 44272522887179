<h3>Details of changes to be released to {{environmentDisplayName}}</h3>

<ng-container *ngIf="loadedData">
  <div class='mb-3 mt-n2 toggle-button'>
    <button class='btn btn-sm rounded-pill mr-2' id='showDeleted'
            [ngClass]="this.showRules ? 'btn-primary' : 'btn-secondary'"
            (click)="displayRules()">Ruleset</button>
    <button class='btn btn-sm rounded-pill mr-2' id='showConfiguration'
            [ngClass]="this.showConfiguration ? 'btn-primary' : 'btn-secondary'"
            (click)="displayConfiguration()">Configuration</button>
    <button class='btn btn-sm rounded-pill mr-2' id='showSchemas'
            [ngClass]="this.showSchemas ? 'btn-primary' : 'btn-secondary'"
            (click)="displaySchemas()">Schemas</button>
    <button class='btn btn-sm rounded-pill mr-2' id='showTransforms'
            [ngClass]="this.showTransforms ? 'btn-primary' : 'btn-secondary'"
            (click)="displayTransforms()">Transforms</button>
    <button class='btn btn-sm rounded-pill mr-2' id='showTemplates'
            [ngClass]="this.showTemplates ? 'btn-primary' : 'btn-secondary'"
            (click)="displayTemplates()">Templates</button>
  </div>
</ng-container>

<ng-container *ngIf="showRules">
  <app-config-release-changes [specifiedConfig]="filteredConfig" [specifiedConfigName]="'Ruleset'"></app-config-release-changes>
</ng-container>
<ng-container *ngIf="showTransforms">
  <app-config-release-changes [specifiedConfig]="filteredConfig" [specifiedConfigName]="'Transform'"></app-config-release-changes>
</ng-container>
<ng-container *ngIf="showSchemas">
  <app-config-release-changes [specifiedConfig]="filteredConfig" [specifiedConfigName]="'Schema'"></app-config-release-changes>
</ng-container>
<ng-container *ngIf="showConfiguration">
  <app-config-release-changes [specifiedConfig]="filteredConfig" [specifiedConfigName]="'Configuration'"></app-config-release-changes>
</ng-container>
<ng-container *ngIf="showTemplates">
  <app-config-release-changes [specifiedConfig]="filteredConfig" [specifiedConfigName]="'Template'"></app-config-release-changes>
</ng-container>

