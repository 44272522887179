<!-- Ignore RuleSet Control -->
<span class="ignore-span" *ngIf="!canIgnoreRule">
    <mat-icon>info</mat-icon>
    You don't have access to ignore rules.
</span>
<div *ngIf="canIgnoreRule">
    Ignore {{ this.ruleSetMnemonic }} RuleSet in:
    <ul>
        <span *ngFor="let env of this.AllEnvs">
            {{ env.display | titlecase }}
            <mat-slide-toggle [checked]="this.ruleIsExcluded(this.ruleSetMnemonic, true, env.id)"
                (change)="this.actionToggleIgnoreInEnv($event.checked, this.ruleSetMnemonic, env.id)">

            </mat-slide-toggle>
        </span>
    </ul>
    
    <BR>
        
        Ignore Individual Rules in:
        <div>
            <ul *ngFor="let curRule of this.normalisedRuleSetOrginal?.rule">
                {{ this.ruleSetMnemonic + '-' + curRule.mnemonic }}&nbsp;&nbsp;
                
                <ng-container *ngIf="this.canIgnoreRule">
                    <span *ngFor="let env of this.AllEnvs">
                        {{ env.display | titlecase }}
                        <mat-slide-toggle
                        [checked]="this.ruleIsExcluded(this.ruleSetMnemonic + '-'+ curRule.mnemonic, true, env.id)"
                        (change)="this.actionToggleIgnoreInEnv($event.checked, this.ruleSetMnemonic + '-'+ curRule.mnemonic, env.id)">
                    </mat-slide-toggle>
                </span>
            </ng-container>
            
            
        </ul>
    </div>
    
</div>