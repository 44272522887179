<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <mat-form-field color="primary">
    <input class="dialog-form-field name-input" matInput [(ngModel)]="data.name" maxlength="500" placeholder="{{data.title}}">
    <mat-hint align="start"><strong>{{hint}}</strong></mat-hint>
    <mat-hint align="end">{{data.name.length}} / 500</mat-hint>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button class="cancel-button" mat-button (click)="cancel()">Cancel</button>
  <button class="ok-button" mat-button [mat-dialog-close]="data.name" cdkFocusInitial>Ok</button>
</div>
