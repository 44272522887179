import { ValidationRule, RuleType } from '../models/md-validation-models';

export const sortStringArray = (array: ValidationRule[], flag: boolean, value: string): void => {
    array.sort((a, b) => {
        return flag ? (a[value] < b[value] ? -1 : 1) : (a[value] > b[value] ? -1 : 1);
    })
};

export const addTypeToRule = (rulesArray: ValidationRule[], ruleType: RuleType): Array<ValidationRule> => {
    return rulesArray.map((rule) => {
        return { ...rule, type: ruleType }
    });
};
