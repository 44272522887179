import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import cloneDeep from 'clone-deep';
import deepFreeze from 'deep-freeze';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {ChangeDetails} from 'src/app/common/domain/change';
import {ConfirmDialogComponent} from 'src/app/dialogs/confirm-dialog.component';
import {EnvEntry} from 'src/app/dialogs/ruleset-2021-view-edit-dialog/logic/env-entry';
import {PushConfigRequest} from 'src/app/model/config-requests';
import {GetConfigFileSuccessResponse, PublishConfigResponse} from 'src/app/model/config-responses';
import {NormalisedRuleSet} from 'src/app/model/normalised-ruleset';
import {RuleExclusion} from 'src/app/model/rule-exclusion';
import {ApiService} from 'src/app/services/api.service';
import {ChangeService} from 'src/app/services/change.service';
import {PermissionsService} from 'src/app/services/permissions.service';
import {UserNameService} from 'src/app/services/user-name.service';
import { EnvironmentService } from 'src/app/services/environment-service';

@Component({
  selector: 'app-ruleset-2021-ignore',
  templateUrl: './ruleset-2021-ignore.component.html',
  styleUrls: ['./ruleset-2021-ignore.component.scss']
})
export class Ruleset2021IgnoreComponent implements OnInit {

  @Input()
  public ruleSetMnemonic: string = 'abc';

  @Output() onRuleIgnored = new EventEmitter()

  // Ignore RuleSet and Rule
  public canIgnoreRule = false
  private _username: string
  private ruleExclusion: RuleExclusion = {}
  public normalisedRuleSetOrginal: NormalisedRuleSet // used by html

  private subscriptions = new Subscription()

  private ENVS: EnvEntry[]
  private ENVS2: EnvEntry[]

  constructor(
    private _api: ApiService,
    private _changeService: ChangeService,
    // private dialog: MatDialog,
    private _modalService: NgbModal,
    private _permissionsService: PermissionsService,
    private _toastService: ToastrService,
    private _userNameService: UserNameService,
    private environmentService: EnvironmentService,
    // private workspaceAutoSaveService: WorkspaceAutoSaveService,
  ) { }

  public async ngOnInit() {
    // console.info('Ruleset2021ignoreComponent ngOnInit()')
    // this.subscriptions.add(this._permissionsService.addRules.subscribe(permission => this.canAddRule = permission))
    // this.subscriptions.add(this._permissionsService.deleteRule.subscribe(permission => this.canDeleteRule = permission))

    this.ENVS = this.environmentService.availableTargetEnvironments.map(env => {
      return {
        id: env.id.toLowerCase(),
        display: env.id.toLowerCase(),
      }
    })

    this.subscriptions.add(
      this._userNameService.userName.subscribe((username: string) => {
        this._username = username
      })
    )

    this.subscriptions.add(
      this._permissionsService.canApproveRule.subscribe(permission => this.canIgnoreRule = permission)
    )

    const normalisedRuleSetChangeDetails: ChangeDetails = await this._changeService.getObject(`rulesets/${this.ruleSetMnemonic}.json`)
    {
      const normalisedRuleSet = JSON.parse(normalisedRuleSetChangeDetails.content) as NormalisedRuleSet
      // console.info('this.normalisedRuleSet:')
      // console.dir(this.normalisedRuleSet)
      this.normalisedRuleSetOrginal = cloneDeep(normalisedRuleSet, true) as unknown as NormalisedRuleSet
      deepFreeze(this.normalisedRuleSetOrginal)
    }

    this.subscriptions.add(
      this._api.get<GetConfigFileSuccessResponse>(`/config/latest/lambda-approvals/config/ignored-mnemonics/ignored-mnemonics.json`)
        .subscribe((configResponse: GetConfigFileSuccessResponse) => {
          this.ruleExclusion = {}
          // @ts-ignore
          if (configResponse.code === 'FileDoesNotExistException') {
            configResponse.data = JSON.stringify({ignoredMnemonics: []})
          }
          this.ENVS.forEach(env => {
            (JSON.parse(configResponse.data) as RuleExclusion)[env.id]?.ignoredMnemonics.forEach(rule => {
              const ruleExclusion = this.ruleExclusion[env.id]?.ignoredMnemonics.find(r => r.mnemonic === rule.mnemonic)
              if (!ruleExclusion) {
                const newRuleExclusion = Object.assign({}, rule, {excludedIn: [env.id]})
                if (!this.ruleExclusion[env.id]) {
                  this.ruleExclusion[env.id] = {ignoredMnemonics: []}
                }
                this.ruleExclusion[env.id].ignoredMnemonics.push(newRuleExclusion)
              } else {
                ruleExclusion.excludedIn.push(env.display)
              }
            })
          })
        }),
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  // Ignore RuleSet and Rule Code
  public get AllEnvs() {
    return this.ENVS
  }

  public actionToggleIgnoreInEnv(checked: boolean, ruleMnemonic: string, environment: string) {
    const configPath = `/config/latest/lambda-approvals/config/ignored-mnemonics/ignored-mnemonics.json`
    const confirmDialog = this._modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })
    confirmDialog.componentInstance.title = 'Ignore Rule Immediately'
    confirmDialog.componentInstance.message = `Are you sure you want to ${checked ? 'ignore' : 'include'} ${ruleMnemonic} immediately in ${environment}?`

    confirmDialog.result.then()
      .then(_ => {
        this._api.get<GetConfigFileSuccessResponse>(configPath)
          .subscribe(latest => {
            // @ts-ignore
            if (latest.code === 'FileDoesNotExistException') {
              latest.data = JSON.stringify({})
            }
            const ruleExclusionConfig = JSON.parse(latest.data) as RuleExclusion
            let updated
            if (!ruleExclusionConfig[environment]?.ignoredMnemonics) {
              ruleExclusionConfig[environment] = {ignoredMnemonics: []}
            }
            if (!ruleExclusionConfig[environment].ignoredMnemonics.find(rule => rule.mnemonic === ruleMnemonic)) {
              updated = Object.assign({}, ruleExclusionConfig, {
                [environment]: {
                  ignoredMnemonics: ruleExclusionConfig[environment].ignoredMnemonics.concat({
                    mnemonic: ruleMnemonic,
                    updatedBy: this._username,
                    updatedDate: new Date().toISOString(),
                  }),
                },
              })
            } else {
              updated = Object.assign({}, ruleExclusionConfig)
              const updatedRuleIndex = updated[environment].ignoredMnemonics.findIndex(rule => rule.mnemonic === ruleMnemonic)
              updated[environment].ignoredMnemonics.splice(updatedRuleIndex, 1)
            }
            const updatedConfig = JSON.stringify(updated, undefined, 2)
            const ruleIgnorePayload: PushConfigRequest = {
              commitMessage: `Rule ${ruleMnemonic} ignored in ${environment} via Storm by ${this._username}`,
              data: updatedConfig,
              parentCommit: latest.branchVersion,
            }
            this._api.post(configPath, ruleIgnorePayload)
              .subscribe((res: PublishConfigResponse) => {
                if (res.status === 'OK') {
                  this._toastService.success('Rule ignore request submitted successfully')
                  this.onRuleIgnored.emit()
                } else {
                  this._toastService.error('Rule ignore failed - Please reload and try again')
                }
                // Reload the values
                this.ngOnInit()
              })
          })
      })
      .catch(_ => {
        this._toastService.warning('Ignore rule cancelled')
        // Reload the values
        this.ngOnInit()
      })
  }

  public ruleIsExcluded(ruleMnemonic: string, useFullMatch: boolean, environment: string) {
    return !!this.ruleExclusion[environment]?.ignoredMnemonics?.find(rule =>
      (rule.mnemonic === ruleMnemonic || (!useFullMatch && rule.mnemonic.startsWith(`${ruleMnemonic}-`)))
      && rule.excludedIn.includes(environment),
    )
  }
  // End Ignore RuleSet and Rule Code

}
