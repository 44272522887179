import {Component, OnDestroy, OnInit} from "@angular/core";
import {ConfigService} from "../../services/config.service";
import {combineLatest, ObservableInput, of, Subject, Subscription} from "rxjs";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {ChangeService} from "../../services/change.service";
import {switchMap} from "rxjs/operators";
import {ChangeDetails, DocgenConfigAndMetadataChangeDetails} from "../../common/domain/change";
import {MatDialog} from "@angular/material/dialog";
import {DocgenConfig, DocgenConfigAndMetadata,} from "../../common/domain/docgen-config";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {RepositoryAndNameDialogComponent} from "../../dialogs/repository-and-name-dialog.component";

export type ChangeWithService = ChangeDetails & { service: string };

@Component({
  selector: "app-sample-list",
  templateUrl: "./config-samples-component.html",
  styleUrls: ["./config-samples-component.scss"],
})
export class ConfigSamplesEditComponent implements OnInit, OnDestroy {
  sub = new Subscription();

  type: string;
  typeSingular: string;
  changeDetails: ChangeWithService[] = [];
  selectedConfig: DocgenConfigAndMetadata;
  configs: DocgenConfigAndMetadata[] = [];
  missingTemplates: ChangeDetails[] = [];

  constructor(
    private configService: ConfigService,
    private changeService: ChangeService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private modalService: NgbModal
  ) {}

  usageMap: {[key: string]: string[]} = {}

  ngOnInit(): void {

    const userFilteredDocgenConfigs$ = this.changeService.getUserFilteredDocgenConfigs()

    //get all templates from all Config.jsons
    const settingsConfig$ = this.changeService.getObjectsOfType('settings')
      .pipe(
        switchMap((configs: ChangeDetails[]) =>
          of(
            configs.filter(
              (config) => config.source === 'lambda-ingest-product'
            )
          )
        ),
      );

    //Get all samples from Config
    const samplesConfig$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): ObservableInput<ChangeDetails[]> => {
          this.type = "samples"
          this.typeSingular = "sample"
          return this.changeService.getObjectsOfType(this.type)
        },
      ),
    );

    combineLatest([samplesConfig$, settingsConfig$, userFilteredDocgenConfigs$]).subscribe(
      ([samplesConfig, settingsConfig, userPreferences]) => {

        if(samplesConfig.length === 0 || settingsConfig.length === 0 || userPreferences.length === 0) {
          return;
        }

        this.usageMap = {}
        this.missingTemplates = []
        settingsConfig.forEach((config) => {
          const docgenConfig = JSON.parse(config.content) as DocgenConfig;

          if (docgenConfig.renderDocument.templateResourceSelector) {
            const sampleName = docgenConfig.renderDocument.templateResourceSelector + ".xml"
            const renderX: ChangeDetails = {
              source: 'auto-deploy-templates',
              name: sampleName,
              path: 'samples/' + sampleName,
              size: 0,
              hash: '',
              modified: new Date(),
              state: 'missing',
              content: ''
            }

            this.usageMap[sampleName] = this.usageMap[sampleName] || []
            this.usageMap[sampleName].push(config.path.replace('settings/', '').replace('/Config.json', ''))

            //Only add unique renderDocs
            if (!this.missingTemplates.find(docs => docs.name === renderX.name )){
              this.missingTemplates.push(renderX);
            }
          }
        });

        //templates that don't have samples
        this.missingTemplates = this.getUserFilteredConfigs(this.missingTemplates, userPreferences, this.usageMap)

        const filteredConfig = this.getUserFilteredConfigs(samplesConfig, userPreferences, this.usageMap);
        this.changeDetails = filteredConfig.map(c => Object.assign({}, c, {service: (c.source === 'lambda-ingest-product' ? 'Documents' : c.source === 'api-publish' ? 'Routing' : 'Templates')}))
      }
    );

    //Remove existing samples from list of missing Templates
    this.changeDetails.forEach((x, index) => this.removeSamplesFromRenderList(x, index))

    this.sub.add(
      this.configService.selectedDocgenConfig.subscribe(
        (config: DocgenConfigAndMetadata) => (this.selectedConfig = config)
      )
    );
    this.sub.add(
      this.configService.docgenConfigsFiltered.subscribe(
        (configs) => (this.configs = configs)
      )
    );
  }

  private getUserFilteredConfigs(configs: ChangeDetails[], userPreferences: DocgenConfigAndMetadataChangeDetails[], useageMap: {[key: string]: string[]}) {
    return configs.filter(configDetail => userPreferences.find((b) => {
      const userPreferenceConfigKey = `${b.metadata.sourceSystem}/${b.metadata.programme}/${b.metadata.productType}/${b.metadata.documentType}`
      let sampleWithoutProjId = configDetail.name
      const usageMapElement = useageMap[sampleWithoutProjId];
      if(usageMapElement) {
        if(usageMapElement.includes(userPreferenceConfigKey)) {
          return true
        }
      } else {
        return true // always display samples which have no config key attached
      }
    }));
  }

  removeSamplesFromRenderList(aConfig: ChangeDetails, index: number){
      const configRenderDoc = aConfig.name
      var index = this.missingTemplates.findIndex(docs => docs.name === configRenderDoc )
      if (index >= 0) {
        this.missingTemplates.splice(index, 1)
      }
      else
      {
        aConfig.state = 'unused'
      }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  delete(config: ChangeDetails): void {
    this.changeService.deleteObject(config);
  }

  edit(config: ChangeDetails): void {
    this.router.navigate([
      "config",
      config.source,
      this.type,
      "edit",
      { path: config.path },
    ]);
  }

  add(): void {
    const filename =
      this.type === "schemas"
        ? "schema.xsd"
        : this.type === "transforms"
        ? "transform.xslt"
        : this.type === "samples"
        //? "sample.xml"
        ? name
        : "filename.ext";

    const dialog = this.modalService.open(RepositoryAndNameDialogComponent);
    dialog.componentInstance.title =
      "Repository / " + this.type.replace(/s$/, "") + " name";
    dialog.componentInstance.type = this.type;
    dialog.componentInstance.name = filename;

    dialog.result
      .then((res) => {
        console.log("Got result");
        console.dir(res);
        if (res) {
          this.router.navigate([
            "config",
            res.repository,
            this.type,
            "edit",
            { path: `${this.type}/${res.name}` },
          ]);
        }
      })
      .catch((err) => {
        console.log("Cancelled");
      });
  }

  getDisplayState(config: ChangeDetails): string {
    switch (config.state) {
      case "original": {
        return "";
      }
      case "modified": {
        return "MODIFIED";
      }
      case "new": {
        return "NEW";
      }
      case "toDelete": {
        return "TO BE DELETED";
      }
      case "unused": {
        return "NOT USED"
      }
      case "missing": {
        return "MISSING"
      }
      default: {
        return "?";
      }
    }
  }

  isReferencedByConfig(item: ChangeDetails): boolean {
    if (this.selectedConfig) {
      const metadata = this.selectedConfig.metadata;
      const configurationObjectPath = `settings/${metadata.sourceSystem}/${metadata.programme}/${metadata.productType}/${metadata.documentType}/Config.json`;
      const configurationObject = this.changeService.getObject(
        configurationObjectPath
      );
      const config: DocgenConfig = JSON.parse(configurationObject.content);
      return []
        .concat(config.validatePayload.schema)
        .concat(config.validateEnriched.schema)
        .concat(config.transform.transforms)
        .concat(config.enrich.transforms)
        .filter((x) => x)
        .includes(item.path);
    }
    return false;
  }

  isAvailableToConfig(item: ChangeDetails): boolean {
    if (this.configs && this.configs.length > 0) {
      return this.configs.some((availableConfig) => {
        const metadata = availableConfig.metadata;
        const configurationObjectPath = `settings/${metadata.sourceSystem}/${metadata.programme}/${metadata.productType}/${metadata.documentType}/Config.json`;
        const configurationObject = this.changeService.getObject(
          configurationObjectPath
        );
        const config: DocgenConfig = JSON.parse(configurationObject.content);
        return []
          .concat(config.validatePayload.schema)
          .concat(config.validateEnriched.schema)
          .concat(config.transform.transforms)
          .concat(config.enrich.transforms)
          .concat(
            config.renderDocument.templateProjectId +
              "+" +
              config.renderDocument.templateResourceSelector
          );
        // .filter(x => x)
        //.includes(item.path)
      });
    }
    return false;
  }

  restore(config: ChangeDetails) {
    this.changeService.revertChange(config);
  }
}
