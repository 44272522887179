<h1 mat-dialog-title>{{data.title}}</h1>
<div class="warning" *ngIf="data.warning" style="font-size: 13px">{{data.warning}}</div>

<div mat-dialog-content>
  <mat-form-field color="primary">
    <input class="dialog-form-field" matInput [(ngModel)]="comment" [maxlength]="commentMaxLength" placeholder="comment">
    <mat-hint align="start"><strong>Please enter a comment</strong></mat-hint>
    <mat-hint align="end">{{comment.length}} / {{commentMaxLength}}</mat-hint>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="confirm()" cdkFocusInitial>Ok</button>
</div>

