import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {Subject} from 'rxjs'
import {ManagementInformationService} from '../../../services/management-information.service'
import {takeUntil} from 'rxjs/operators'

@Component({
  selector: 'app-client-service-levels',
  templateUrl: './client-service-levels.component.html',
  styleUrls: ['./client-service-levels.component.scss']
})
export class ClientServiceLevelsComponent implements OnInit, OnDestroy, OnChanges {
  loading = true
  cancelRequests = new Subject<void>()

  pdfFilename: string
  pdf: string
  @Input() fromDate: string = ''
  @Input() toDate: string = ''

  constructor(private managementInformationService: ManagementInformationService) { }

  ngOnInit(): void {
    this.loading = true
  }

  ngOnDestroy(): void {
    this.cancelRequests.next()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.fromDate && this.toDate) {
      this.managementInformationService.getSlaReport(this.fromDate + '/' + this.toDate)
        .pipe(
          takeUntil(this.cancelRequests)
        )
        .subscribe((res: { report: string, filename: string }) => {
          console.log('Got client sla report:')
          console.dir(res)
          this.pdf = res.report
          this.pdfFilename = res.filename || 'client-sla-report.pdf'
          this.loading = false
        })
    } else {
      // cancel any existing request
      this.loading = true
      this.cancelRequests.next()
    }
  }
}

