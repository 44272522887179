import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {XPath} from '../common/domain/payload-inspector'
import {StatusService} from './status.service'
import {catchError, tap} from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class PayloadInspectorService {
  constructor(
    private api: ApiService,
    private statusService: StatusService,
  ) {
  }

  inspect(testSuite: string, xpaths: XPath[], originals: boolean = false) {
    const statusId = this.statusService.start('Inspecting Test Suite')
    const request = {
      testSuite: testSuite,
      paths: xpaths,
      originals: originals,
    }
    return this.api.post('/inspect/suite/', request)
      .pipe(
        tap(_ => this.statusService.complete(statusId)),
        catchError(e => this.statusService.start('Failed to inspect suite', 'ALERT', 10)),
      )
  }

  inspectTests(tests: string[], xpaths: XPath[]) {
    // const statusId = this.statusService.start('Inspecting Tests')
    const request = {
      tests: tests,
      paths: xpaths,
    }
    console.dir(request)
    return this.api.post('/inspect/tests/', request)
      .pipe(
        // tap(_ => this.statusService.complete(statusId)),
        // catchError(e => this.statusService.start('Failed to inspects', 'ALERT', 10)),
      )
  }


}
