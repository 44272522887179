import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {ManagementInformationService} from '../../../services/management-information.service'
import {takeUntil} from 'rxjs/operators'
import {Subject} from 'rxjs'

@Component({
  selector: 'app-client-summary-report',
  templateUrl: './client-summary-report.component.html',
  styleUrls: ['./client-summary-report.component.scss']
})
export class ClientSummaryReportComponent implements OnInit, OnDestroy, OnChanges {
  loading = true
  cancelRequests = new Subject<void>()

  pdfFilename: string
  pdf: string
  @Input() fromDate: string = ''
  @Input() toDate: string = ''

  constructor(private managementInformationService: ManagementInformationService) { }

  ngOnInit(): void {
    this.loading = true
  }

  ngOnDestroy(): void {
    this.cancelRequests.next()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.fromDate && this.toDate) {
      this.managementInformationService.getClientReport(this.fromDate + '/' + this.toDate)
        .pipe(
          takeUntil(this.cancelRequests)
        )
        .subscribe((res: { report: string, filename: string }) => {
          console.log('Got client report:')
          console.dir(res)
          this.pdf = res.report
          this.pdfFilename = res.filename || 'client-summary-report.pdf'
          this.loading = false
        })
    } else {
      // cancel any existing request
      this.loading = true
      this.cancelRequests.next()
    }
  }

}
