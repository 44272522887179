
<h2>Smart DX Administration</h2>

<mat-grid-list cols="3" rowHeight="7:1">
  <mat-grid-tile>ENVIRONMENT</mat-grid-tile>
  <mat-grid-tile>LONDON</mat-grid-tile>
  <mat-grid-tile>FRANKFURT</mat-grid-tile>

  <div *ngIf="envAvailable('DEV')">
    <mat-grid-tile>DEVELOPMENT</mat-grid-tile>
    <mat-grid-tile>
      <button mat-raised-button color="primary" [disabled]="!canRestartSmartDx" (click)="restart('DEV', Regions.London)" class="me-2">Restart</button>
      <button mat-raised-button color="primary" [disabled]="!canRestartSmartDx" (click)="flushAppliance('DEV', Regions.London)">Flush Cache</button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button mat-raised-button color="primary" [disabled]="!canRestartSmartDx" (click)="restart('DEV', Regions.Frankfurt)" class="me-2">Restart</button>
      <button mat-raised-button color="primary" [disabled]="!canRestartSmartDx" (click)="flushAppliance('DEV', Regions.Frankfurt)">Flush Cache</button>
    </mat-grid-tile>
  </div>

  <div *ngIf="envAvailable('UAT')">
    <mat-grid-tile>UAT</mat-grid-tile>
    <mat-grid-tile>
      <button mat-raised-button color="primary" [disabled]="!canRestartSmartDx" (click)="restart('UAT', Regions.London)" class="me-2">Restart</button>
      <button mat-raised-button color="primary" [disabled]="!canRestartSmartDx" (click)="flushAppliance('UAT', Regions.London)">Flush Cache</button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button mat-raised-button color="primary" [disabled]="!canRestartSmartDx" (click)="restart('UAT', Regions.Frankfurt)" class="me-2">Restart</button>
      <button mat-raised-button color="primary" [disabled]="!canRestartSmartDx" (click)="flushAppliance('UAT', Regions.Frankfurt)">Flush Cache</button>
    </mat-grid-tile>
  </div>

  <div *ngIf="envAvailable('PROD')">
    <mat-grid-tile>PROD</mat-grid-tile>
    <mat-grid-tile>
      <button mat-raised-button color="warn" [disabled]="!canRestartSmartDx" (click)="restart('PROD', Regions.London)" class="me-2">Restart</button>
      <button mat-raised-button color="warn" [disabled]="!canRestartSmartDx" (click)="flushAppliance('PROD', Regions.London)">Flush Cache</button>
    </mat-grid-tile>
    <mat-grid-tile>
      <button mat-raised-button color="warn" [disabled]="!canRestartSmartDx" (click)="restart('PROD', Regions.Frankfurt)" class="me-2">Restart</button>
      <button mat-raised-button color="warn" [disabled]="!canRestartSmartDx" (click)="flushAppliance('PROD', Regions.Frankfurt)">Flush Cache</button>
    </mat-grid-tile>
  </div>
</mat-grid-list>


