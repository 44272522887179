<ng-container *ngIf="loading">
  Loading...
</ng-container>

<ng-container *ngIf="!loading">
  <ng-container *ngIf="results.length === 0">
    <div>No Management Information Found</div>
  </ng-container>
  <ng-container *ngIf="results.length > 0">
    <form class="form-inline">
      <button type="button" class="btn btn-primary" (click)="downloadCsv()">Download</button>
      <div class="input-group">
        <input type="text" placeholder="Filter..." [formControl]="filter" class="form-control ml-2"/>
      </div>
    </form>
  </ng-container>
</ng-container>

<table mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date</th>
    <td mat-cell *matCellDef="let el">{{el.date}}</td>
  </ng-container>
  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Time</th>
    <td mat-cell *matCellDef="let el">{{el.time}}</td>
  </ng-container>
  <ng-container matColumnDef="environment">
    <th mat-header-cell *matHeaderCellDef> Environment</th>
    <td mat-cell *matCellDef="let el">{{el.environment}}</td>
  </ng-container>
  <ng-container matColumnDef="region">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Region</th>
    <td mat-cell *matCellDef="let el">{{el.region}}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
    <td mat-cell *matCellDef="let el">{{el.status}}</td>
  </ng-container>
  <ng-container matColumnDef="sourceIdentifier">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Source Identifier</th>
    <td mat-cell *matCellDef="let el">{{el.sourceIdentifier}}</td>
  </ng-container>
  <ng-container matColumnDef="config">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Config Key</th>
    <td mat-cell *matCellDef="let el">{{el.config}}</td>
  </ng-container>
  <ng-container matColumnDef="latency">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Latency</th>
    <td mat-cell *matCellDef="let el">{{el.latency}}</td>
  </ng-container>
  <ng-container matColumnDef="validatePayload">
    <th mat-header-cell *matHeaderCellDef> CS Schema</th>
    <td mat-cell *matCellDef="let el">{{el.validatePayload}}</td>
  </ng-container>
  <ng-container matColumnDef="transform">
    <th mat-header-cell *matHeaderCellDef> Transform</th>
    <td mat-cell *matCellDef="let el">{{el.transform}}</td>
  </ng-container>
  <ng-container matColumnDef="enrich">
    <th mat-header-cell *matHeaderCellDef> Enrichment</th>
    <td mat-cell *matCellDef="let el">{{el.enrich}}</td>
  </ng-container>
  <ng-container matColumnDef="retrieve">
    <th mat-header-cell *matHeaderCellDef> Retrieve</th>
    <td mat-cell *matCellDef="let el">{{el.retrieve}}</td>
  </ng-container>
  <ng-container matColumnDef="combine">
    <th mat-header-cell *matHeaderCellDef> Combine</th>
    <td mat-cell *matCellDef="let el">{{el.combine}}</td>
  </ng-container>
  <ng-container matColumnDef="encapsulate">
    <th mat-header-cell *matHeaderCellDef> Encapsulate</th>
    <td mat-cell *matCellDef="let el">{{el.encapsulate}}</td>
  </ng-container>
  <ng-container matColumnDef="validateTarget">
    <th mat-header-cell *matHeaderCellDef> Target Schema</th>
    <td mat-cell *matCellDef="let el">{{el.validateEnriched}}</td>
  </ng-container>
  <ng-container matColumnDef="publish">
    <th mat-header-cell *matHeaderCellDef> Publish</th>
    <td mat-cell *matCellDef="let el">{{el.publish}}</td>
  </ng-container>
  <ng-container matColumnDef="retrieveLatency">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Retrieve Latency</th>
    <td mat-cell *matCellDef="let el">{{el.retrieveLatency}}</td>
  </ng-container>
  <ng-container matColumnDef="publishLatency">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Publish Latency</th>
    <td mat-cell *matCellDef="let el">{{el.publishLatency}}</td>
  </ng-container>
  <ng-container matColumnDef="processingTime">
    <th mat-header-cell *matHeaderCellDef> mat-sort-header Processing Time</th>
    <td mat-cell *matCellDef="let el">{{el.processingTime}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" showFirstLastButtons></mat-paginator>
