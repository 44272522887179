// import IDiffEditorConstructionOptions = monaco.editor.IDiffEditorConstructionOptions
// import IStandaloneEditorConstructionOptions = monaco.editor.IStandaloneEditorConstructionOptions

export const jsonViewerOptions: any = {
  theme: 'vs',
  language: 'json',
  formatOnType: true,
  readOnly: true,
}

export const xmlViewerOptions: any = {
  theme: 'vs',
  language: 'xml',
  formatOnType: true,
  readOnly: true,
}

export const jsonEditorOptions: any = {
  theme: 'vs',
  language: 'json',
  formatOnType: true,
  automaticLayout: true
}

export const xmlEditorOptions: any = {
  theme: 'vs',
  language: 'xml',
  formatOnType: true,
  automaticLayout: true
}

export const jsonSchemaEditorOptions: any = {
  theme: 'vs',
  language: 'json',
  formatOnType: true,
  automaticLayout: true
}

export const jsonataEditorOptions: any = {
  theme: 'jsonata-vs',
  language: 'jsonata',
  formatOnType: true,
  automaticLayout: true
}

export const jsonDiffViewerOptions: any = {
  theme: 'vs',
  readOnly: true,
  renderSideBySide: true,
  maxComputationTime: 3000,
  minimap: {},
  automaticLayout: true
}

export const preProcessorEditorOptions: any = {
  theme: 'vs',
  language: 'typescript',
  readOnly: false, 
  autoIndent: 'full' ,
  formatOnPaste: true,
  automaticLayout: true
}