import {CommonConfig, ConfigDetail, TransformConfig} from './config'

export type TargetSystem = 'RegXChange' | 'WMDaten' | 'SIX' | 'RegXChange_EET' | 'WMDaten_EDDy' | ''

export type SecondTargetSystem = 'RegXChange' | 'WMDaten' | 'SIX' | 'RegXChange_EET' | 'WMDaten_EDDy' | ''

export type FormatTransform = 'JSONTOXML' | 'None'

export class TargetSystemConfig {
  targetSystem: TargetSystem
  queryParams: string
  regxchangeRetrieveSchema: string
  regxchangeSubmitSchema: string
  formatTransformation: FormatTransform
  secondTargetSystem? : SecondTargetSystem
}

export class PublishConfig extends CommonConfig {
  publish: TargetSystemConfig
  combine: TransformConfig
  encapsulate: TransformConfig


  private constructor() {
    super()
    this.publish = new TargetSystemConfig()
    this.combine = new TransformConfig()
    this.encapsulate = new TransformConfig()
  }

  public static create(): PublishConfig {
    return new PublishConfig()
  }
}

export interface PublishConfigMetadata {
  sourceSystem: string
  businessUnit: string
  payloadType: string
  targetSystem: string
}

export interface PublishConfigAndMetadata {
  metadata: PublishConfigMetadata
  settings: ConfigDetail
  schemas: ConfigDetail[]
  transforms: ConfigDetail[]
}

export enum PublishConfigKeyIndex {
  SOURCE_SYSTEM = 1,
  BUSINESS_UNIT = 2,
  PAYLOAD_TYPE = 3,
  TARGET_SYSTEM = 4,
}
