import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {OPS_URL, valueReplaced} from '../constants'

export interface Settings {
  environment: string
  hostname: string
  region: string
  userPoolId: string
  webClientId: string
  permissionsEndpoint: string
}

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private readonly hostname: string
  private loadedSettings: Promise<Settings | void>

  constructor(
    private httpClient: HttpClient,
  ) {
    const hostname = window.location.hostname.includes('localhost') ? valueReplaced(OPS_URL) ? OPS_URL : 'ops.cs.inspire.direct' : window.location.hostname
    this.hostname = hostname
    const settingsUrl = 'https://api.' + this.hostname + '/v1/settings'
    console.info(`Loading settings from: ${settingsUrl}`)
    this.loadedSettings = this.httpClient
      .post(settingsUrl, {}, {
        headers: {
          Accept: 'application/json',
        },
      })
      .toPromise()
      .then(response => {
        return Object.assign({
          hostname: this.hostname,
        }, response) as Settings
      })
  }

  async settings() {
    return await this.loadedSettings
  }
}

