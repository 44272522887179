<div class="container-fluid flex-column">
  <!-- editors section container -->
  <div class="container col-12 m-0 p-0 row editors-container">
    <!-- left editor  -->
    <div class="col-lg-5 p-0 left-editor">
      <h3 class="h3">Incoming Json Payload</h3>
      <json-editor
        #leftEditor
        [options]="leftEditorOptions"
        [data]="leftEditorPayload"
      ></json-editor>
    </div>

    <!-- middle section -->
    <div
      class="
        container
        align-items-end
        col-lg-2
        justify-content-around
        m-0
        p-0
        row
        middle-section
      "
    >
      <div class="enrich-btn w-100 mt-lg-5 pt-lg-5 text-center">
        <button class="btn btn-info w-75 mt-lg-5" (click)="payloadTransformation(this.selectedPayload.id)"
                [disabled]="isSmartDxPayload">Auto Transform</button>
      </div>

      <div class="new-multiplicity-btn w-100 text-center">
        <button
          class="btn btn-secondary w-75"
          (click)="openMultiplicityModal(content)"
        >
          New Multiplicity
        </button>
      </div>

      <div class="validate-btn mb-lg-5 pb-lg-5 w-100 text-center">
        <button class="btn btn-primary mb-lg-5 w-75" (click)="validatePayload()">Validate</button>
      </div>
    </div>

    <!-- right editor -->
    <div class="col-lg-5 p-0 right-editor">
      <div class="right-editor">
        <h3 class="h3">Outgoing SmartDX Payload</h3>
        <json-editor
          #rightEditor
          [options]="rightEditorOptions"
          [data]="this.rightEditorPayload"
        ></json-editor>
      </div>
    </div>
  </div>

  <!-- action buttons container -->
  <div
    class="
      container
      row
      justify-content-end
      col-12
      my-3
      ml-1
      mr-0
      action-btns-container
    "
  >
    <div class="d-flex mr-auto validation-output ">
      <h4 class="h4 pt-1">Output</h4>
      <button
        mat-mini-fab
        class="ml-2 output-btn"
        ngbTooltip="Open output in new tab"
        placement="bottom"
        [disabled]='!this.isThereAnyOutput'
        (click)="openRuleValidationResultsWindow()"
      >
        <mat-icon>open_in_new</mat-icon>
      </button>
    </div>
    <div class="save-btn">
      <button class="btn btn-info ml-5" (click)="saveAsDraft()">Save as Draft</button>
    </div>

    <div class="ml-3 send-btn">
      <button class="btn btn-primary" (click)="sendToSmartDx()">Send to SmartDX</button>
    </div>
    <div class="ml-3 close-btn">
      <button
        class="btn btn-secondary"
        (click)="closePayloadEditor()"
      >
        Close
      </button>
    </div>
  </div>

  <!-- validation output section -->
  <ng-container *ngIf="this.isThereAnyOutput">
    <app-output-display [outputResponsesProp]="this.outputResponses"></app-output-display>
  </ng-container>

<!-- New multiplicity pop-up modal  -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Multiplicity</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.close()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" ngbAutofocus>
    <form
      [formGroup]="multiplicityForm"
      (ngSubmit)="addMultiplicity(); modal.close()"
    >
      <fieldset class="form-group">
        <div class="form-group row m-0">
          <label for="nameSelect" class="col-form-label col-lg-3">Name:</label>
          <select
            formControlName="multiplicity"
            id="nameSelect"
            class="form-control form-control-sm col-lg-9"
          >
            <option disabled selected readonly>Select multiplicity</option>
            <optgroup
              *ngFor="let multiplicity of multiplicities"
              [label]="multiplicity.optionsGroup"
            >
              <option
                *ngFor="let item of multiplicity.multiplicities"
                [ngValue]="item"
              >
                {{ item.name }}
              </option>
            </optgroup>
          </select>
        </div>

        <div class="form-group row m-0">
          <label for="number" class="col-lg-3 col-form-label"
          >Number Required:</label
          >
          <input
            formControlName="numberRequired"
            type="number"
            id="number"
            class="form-control form-control-sm col-lg-1"
            min="1"
            required
          />
          <ng-container
            *ngIf="this.multiplicityForm.get('numberRequired').invalid"
          >
            <small class="form-text text-danger ml-5"
            >Only positive integers between 0 and 99 are allowed</small
            >
          </ng-container>
        </div>

        <div class="form-group row m-0">
          <label for="destinationSelect" class="col-form-label col-lg-3"
          >Destination:</label
          >

          <input
            type="text"
            id="destinationSelect"
            class="form-control form-control-sm col-lg-9"
            [value]="
              this.multiplicityForm.value.multiplicity &&
              this.multiplicityForm.value.multiplicity.path
            "
            placeholder="Destination path"
            [readonly]="true"
          />
        </div>
      </fieldset>

      <div class="form-row float-right mt-0">
        <button
          class="btn btn-primary mr-2"
          type="submit"
          [disabled]="!this.multiplicityForm.valid"
        >
          Apply
        </button>

        <button
          class="btn btn-secondary mr-2"
          (click)="modal.close(); this.initializeForm()"
        >
          Close
        </button>
      </div>
    </form>
  </div>
  <!-- <div class="modal-footer"></div> -->
</ng-template>
