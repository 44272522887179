// import * as stringify from 'csv-stringify/lib/sync'
import {take} from 'rxjs/operators'
import {DocgenConfig} from 'src/app/common/domain/docgen-config'
import {ChangeService} from 'src/app/services/change.service'
import { unparse } from 'papaparse'
import saveAs from 'file-saver'
export class UserActionDownloadErrors {

    constructor(
        private _changeService: ChangeService,
    ) {
    }

    // 100% a Copy/ Paste of the old code
    //  I do not like this code, but it appears to produce exactly the same output as the old code does
    public downloadAllErrorMessages() {
        const csvData = []

        this._changeService.getUserFilteredDocgenConfigs()
            .pipe(
                take(1),
            )
            .subscribe(docgens => {
                docgens.forEach(config => {
                    const dc = JSON.parse(config.settings.content) as DocgenConfig
                    dc.analysePayload.rules
                        .filter(r => r.ruleType === 'whitelist')
                        .forEach(rule => {
                            csvData.push(Object.assign({
                                menmonic: 'PW-' + rule.mnemonic,
                                name: rule.name,
                                type: rule.ruleType,
                                description: rule.description || rule.name,
                                help: rule.help || '',
                            }, config.metadata))
                        })
                    dc.analysePayload.rules
                        .filter(r => r.ruleType === 'blacklist')
                        .forEach(rule => {
                            csvData.push(Object.assign({
                                menmonic: 'PB-' + rule.mnemonic,
                                name: rule.name,
                                type: rule.ruleType,
                                description: rule.description || rule.name,
                                help: rule.help || '',
                            }, config.metadata))
                            rule.rule.forEach(row => {
                                csvData.push(Object.assign({
                                    menmonic: 'PB-' + rule.mnemonic + '-' + row.mnemonic,
                                    name: '',
                                    type: rule.ruleType,
                                    description: row.description || rule.description || rule.name,
                                    help: row.help || rule.help,
                                }, config.metadata))
                            })
                        })
                    dc.analysePayload.rules
                        .filter(r => r.ruleType === 'notSupported')
                        .forEach(rule => {
                            csvData.push(Object.assign({
                                menmonic: 'PU-' + rule.mnemonic,
                                name: rule.name,
                                type: rule.ruleType,
                                description: rule.description || rule.name,
                                help: rule.help || '',
                            }, config.metadata))
                            rule.rule.forEach(row => {
                                csvData.push(Object.assign({
                                    menmonic: 'PU-' + rule.mnemonic + '-' + row.mnemonic,
                                    name: '',
                                    type: rule.ruleType,
                                    description: row.description || rule.description || rule.name,
                                    help: row.help || rule.help,
                                }, config.metadata))
                            })
                        })
                    dc.analyseEnriched.rules
                        .filter(r => r.ruleType === 'whitelist')
                        .forEach(rule => {
                            csvData.push(Object.assign({
                                menmonic: 'SW-' + rule.mnemonic,
                                name: rule.name,
                                type: rule.ruleType,
                                description: rule.description || rule.name,
                                help: rule.help || '',
                            }, config.metadata))
                        })
                    dc.analyseEnriched.rules
                        .filter(r => r.ruleType === 'blacklist')
                        .forEach(rule => {
                            csvData.push(Object.assign({
                                menmonic: 'SB-' + rule.mnemonic,
                                name: rule.name,
                                type: rule.ruleType,
                                description: rule.description || rule.name,
                                help: rule.help || '',
                            }, config.metadata))
                            rule.rule.forEach(row => {
                                csvData.push(Object.assign({
                                    menmonic: 'SB-' + rule.mnemonic + '-' + row.mnemonic,
                                    name: '',
                                    type: rule.ruleType,
                                    description: row.description || rule.description || rule.name,
                                    help: row.help || rule.help,
                                }, config.metadata))
                            })
                        })
                    dc.analyseEnriched.rules
                        .filter(r => r.ruleType === 'notSupported')
                        .forEach(rule => {
                            csvData.push(Object.assign({
                                menmonic: 'SU-' + rule.mnemonic,
                                name: rule.name,
                                type: rule.ruleType,
                                description: rule.description || rule.name,
                                help: rule.help || '',
                            }, config.metadata))
                            rule.rule.forEach(row => {
                                csvData.push(Object.assign({
                                    menmonic: 'SU-' + rule.mnemonic + '-' + row.mnemonic,
                                    name: '',
                                    type: rule.ruleType,
                                    description: row.description || rule.description || rule.name,
                                    help: row.help || rule.help,
                                }, config.metadata))
                            })
                        })
                })
            })

        const allCsv = unparse(csvData, {
            header: true,
        })

        const blob = new Blob([allCsv], {type: 'text/csv;charset=utf-8'})
        saveAs(blob, 'errorMessages.csv')
    }

}
