<table class="mt-3 table table-borderless">
  <thead>
  <th>Date</th>
  <th>User</th>
  <th>Comment</th>
  <th>Type</th>
  <th></th>
  </thead>
  <tbody>
  <tr *ngFor="let history of history" class="align-middle">
    <td>{{history.date | amDateFormat:'MMMM Do YYYY, h:mm:ss a'}}</td>
    <td>{{history.metadata['username']}}</td>
    <td>{{history.metadata['comment']}}</td>
    <td>{{history.metadata['testtype']}}</td>
    <td>
      <button type="button" class="btn btn-warning" (click)="selectVersion(history)">Load</button>
    </td>
  </tr>
  </tbody>
</table>
