<div class="container-fluid">
  <div class="row">
    <div class="col-lg-9">
      <ng-container>
        <app-test-selector service="docgen" allowDelete="true" (cleared)="testReset()"></app-test-selector>
      </ng-container>
      <ng-container *ngIf="payloadValue">
        <div class="row mb-2">
       
        <div id="payload-country-value-field" class="col-sm-4">
          <label for='country' class='inspire-surround-label-flex'>Country: </label>
          <input id='country' type="text" class='rem5w form-control'  [(ngModel)]="this.payloadCountryValue" maxlength="50"/>
        </div>
        <div id="payload-language-value-field" class="col-sm-4">
          <label for='payloadLanguageValue' class='inspire-surround-label-flex'>Language: </label>
          <select class="rem5w form-control" id="payloadLanguageValue" name="Language"
                  [(ngModel)]="this.payloadLanguageValue">
            <option *ngFor="let language of payloadLanguageOptions">{{language}}</option>
          </select>
        </div>
        <div id="payload-jurisdiction-value-field" class="col-sm-4">
          <label for='jurisdiction' class='inspire-surround-label-flex'>Jurisdiction: </label>
          <input id='jurisdiction' type="text" class='rem5w form-control'  [(ngModel)]="this.payloadJurisdictionValue" maxlength="50"/>
        </div>
      </div>
      </ng-container>

      <ng-container *ngIf="loadedVersion">Loaded Version: {{loadedVersion}}</ng-container>
    </div>
    <div class="col-lg-3">
      <app-docgen-graphic id="lambda-status-graphic-icon"
                          [width]="240"
                          [height]="100"
                          [genDocStatus]="documentStatus"
                          [expected]="expectedDocument"
                          [pdfDiffResponse]="pdfDiffResponse"
                          [xmlDiffResponse]="xmlDiffResponse"
                          [refresh]="refreshGraphic"
      ></app-docgen-graphic>
    </div>
  </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="documentStatus.generating"></mat-progress-bar>

<div class="container-fluid">
  <ul ngbNav #docgenNav="ngbNav" (navChange)="onNavChange($event)" [(activeId)]="activeTab" class="nav-tabs">
    <li ngbNavItem="payload">
      <a ngbNavLink>Payload</a>
      <ng-template ngbNavContent>
        <div class="payload-input">
          <ngx-monaco-editor
            class="monaco-editor payload-editor"
            [options]="payloadEditorConfig"
            [(ngModel)]="payloadValue"
            (ngModelChange)="refresh()"
          >
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="overview">
      <a ngbNavLink>Overview</a>
      <ng-template ngbNavContent>
        <div class="lambda-graphic">
          <app-docgen-graphic id="lambda-status-graphic"
                              [width]="1200"
                              [height]="560"
                              [genDocStatus]="documentStatus"
                              [expected]="expectedDocument"
                              [pdfDiffResponse]="pdfDiffResponse"
                              [xmlDiffResponse]="xmlDiffResponse"
                              [refresh]="refreshGraphic"
          ></app-docgen-graphic>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="pdf" *ngIf="documentStatus.pdf">
      <a ngbNavLink>PDF</a>
      <ng-template ngbNavContent>
        <div class="pdf" id="pdf" *ngIf="documentStatus.displayPdf">
          <ngx-extended-pdf-viewer [src]="documentStatus.pdf"
                                   [filenameForDownload]="(this.testDefinition ? this.testDefinition.name + '-generated' : 'actualDocument') + '.pdf'"
                                   useBrowserLocale="true"
                                   delayFirstView="1000"
                                   [showPresentationModeButton]="false"
                                   [showOpenFileButton]="false"
                                   [showPrintButton]="false"
                                   [showBookmarkButton]="false"
                                   [showRotateButton]="false"
                                   minHeight="600px"
                                   height="600px"></ngx-extended-pdf-viewer>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="expected" *ngIf="expectedDocument">
      <a ngbNavLink>Expected</a>
      <ng-template ngbNavContent>
        <div class="pdf" id="expectedPdf" *ngIf="expectedDocument.displayPdf">
          <ngx-extended-pdf-viewer [base64Src]="expectedDocument.base64"
                                   [filenameForDownload]="(this.testDefinition ? this.testDefinition.name + '-expected' : 'expectedDocument') + '.pdf'"
                                   useBrowserLocale="true"
                                   delayFirstView="1000"
                                   [showPresentationModeButton]="false"
                                   [showOpenFileButton]="false"
                                   [showPrintButton]="false"
                                   [showBookmarkButton]="false"
                                   [showRotateButton]="false"
                                   minHeight="600px"
                                   height="600px"></ngx-extended-pdf-viewer>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="pdfDiff" *ngIf="documentStatus.hasPdfDiff ">
      <a ngbNavLink>Diff <span *ngIf="pdfDiffResponse.ignoreTotal">({{pdfDiffResponse.ignoreCount}} of {{pdfDiffResponse.ignoreTotal}} ignored)</span></a>
      <ng-template ngbNavContent>
        <div>
          <ng-container *ngIf="documentStatus.htmlDiff && documentStatus.hasPdfDiff">
            <mat-progress-bar mode="indeterminate" *ngIf="documentStatus.generatingDiff"></mat-progress-bar>
            <div class="result text pdfhtmldiff" [innerHTML]="documentStatus.htmlDiff">
              <span class="removed"></span>
              <span class="added"></span>
            </div>
          </ng-container>
          <ng-container *ngIf="documentStatus.rawDiff && !documentStatus.htmlDiff">
            <ngx-monaco-editor
              class="monaco-editor"
              [options]="readOnlyJsonConfig"
              [ngModel]="documentStatus.rawDiff">
            </ngx-monaco-editor>
          </ng-container>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="pdfVisualDiff" *ngIf="documentStatus.hasPdfDiff">
      <a ngbNavLink>Visual Diff</a>
      <ng-template ngbNavContent>
        <div>
          <ng-container>
            <mat-progress-bar mode="indeterminate" *ngIf="documentStatus.generatingDiff"></mat-progress-bar>
            <div class="result text pdfhtmldiff" [innerHTML]="pdfVisualDiffResponse">
            </div>
          </ng-container>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="apiApprovalResponse" *ngIf="documentStatus.approvalResponse">
      <a ngbNavLink>Approval Response</a>
      <ng-template ngbNavContent>
        <div class="approval-response-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyJsonConfig"
            [ngModel]="documentStatus.approvalResponse">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <!--    <li ngbNavItem="xmlDiff" *ngIf="documentStatus.hasXmlDiff">-->
    <!--      <a ngbNavLink>XML Diff</a>-->
    <!--      <ng-template ngbNavContent>-->
    <!--        <h2>TODO - Display XML Diff</h2>-->
    <!--        &lt;!&ndash;        <mat-tab label="Expected Enriched">&ndash;&gt;-->
    <!--        &lt;!&ndash;          <div class=".xml-output ace">&ndash;&gt;-->
    <!--        &lt;!&ndash;            <ace [config]="aceReadonlyXmlConfig"&ndash;&gt;-->
    <!--        &lt;!&ndash;                 [(value)]="testDefinition.enriched">&ndash;&gt;-->
    <!--        &lt;!&ndash;            </ace>&ndash;&gt;-->
    <!--        &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </mat-tab>&ndash;&gt;-->
    <!--        &lt;!&ndash;        <mat-tab&ndash;&gt;-->
    <!--        &lt;!&ndash;          label="XML Diff (+{{xmlDiffResponse.addedCount}}|~{{xmlDiffResponse.changedCount}}|-{{xmlDiffResponse.removedCount}})">&ndash;&gt;-->
    <!--        &lt;!&ndash;          <div class=".log-output ace">&ndash;&gt;-->
    <!--        &lt;!&ndash;            <ace [config]="aceReadonlyJsonConfig"&ndash;&gt;-->
    <!--        &lt;!&ndash;                 [(value)]="documentStatus.rawXmlDiff">&ndash;&gt;-->
    <!--        &lt;!&ndash;            </ace>&ndash;&gt;-->
    <!--        &lt;!&ndash;          </div>&ndash;&gt;-->
    <!--        &lt;!&ndash;        </mat-tab>&ndash;&gt;-->

    <!--      </ng-template>-->
    <!--    </li>-->

    <li ngbNavItem="csSchema" *ngIf="documentStatus.displayValidation()">
      <a ngbNavLink>CS Schema</a>
      <ng-template ngbNavContent>
        <div class="container-fluid" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="source-schema-input">
                <ngx-monaco-editor
                  class="monaco-editor source-schema-editor"
                  [options]="sourceSchemaEditorConfig"
                  [(ngModel)]="csSchemaValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="documentStatus.validated">
                <div class="source-validation-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonConfig"
                    [ngModel]="documentStatus.validated">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="transform1" *ngIf="documentStatus.transformed1">
      <a ngbNavLink>Transform 1</a>
      <ng-template ngbNavContent>
        <div fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="transform-input">
                <ngx-monaco-editor
                  class="monaco-editor transform-editor"
                  [options]="transformEditorConfig"
                  [(ngModel)]="transform1Value"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="documentStatus.transformed1">
                <div class="transform-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readonlyXmlConfig"
                    [ngModel]="documentStatus.transformed1">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="transform2" *ngIf="documentStatus.transformed2">
      <a ngbNavLink>Transform 2</a>
      <ng-template ngbNavContent>
        <div fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="transform-input">
                <ngx-monaco-editor
                  class="monaco-editor transform-editor"
                  [options]="transformEditorConfig"
                  [(ngModel)]="transform2Value"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="documentStatus.transformed2">
                <div class="transform-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readonlyXmlConfig"
                    [ngModel]="documentStatus.transformed2">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="enrich1" *ngIf="documentStatus.enriched1">
      <a ngbNavLink>Enrich 1</a>
      <ng-template ngbNavContent>
        <div fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="enrich-input">
                <ngx-monaco-editor
                  class="monaco-editor enrich-editor"
                  [options]="transformEditorConfig"
                  [(ngModel)]="enrich1Value"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="documentStatus.enriched1">
                <div class="enrich-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readonlyXmlConfig"
                    [ngModel]="documentStatus.enriched1">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="enrich2" *ngIf="documentStatus.enriched2">
      <a ngbNavLink>Enrich 2</a>
      <ng-template ngbNavContent>
        <div fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="enrich-input">
                <ngx-monaco-editor
                  class="monaco-editor enrich-editor"
                  [options]="transformEditorConfig"
                  [(ngModel)]="enrich2Value"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="documentStatus.enriched2">
                <div class="enrich-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readonlyXmlConfig"
                    [ngModel]="documentStatus.enriched2">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="targetSchema" *ngIf="documentStatus.displayEnrichedValidation()">
      <a ngbNavLink>Target Schema</a>
      <ng-template ngbNavContent>
        <div fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="target-schema-input">
                <ngx-monaco-editor
                  class="monaco-editor target-schema-editor"
                  [options]="targetSchemaEditorConfig"
                  [(ngModel)]="targetSchemaValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="documentStatus.enrichedValidated">
                <div class="target-validation-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonConfig"
                    [ngModel]="documentStatus.enrichedValidated">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="analysed" *ngIf="documentStatus.displayAnalysed()">
      <a ngbNavLink>Analysed (Payload)</a>
      <ng-template ngbNavContent>
        <div class="result analysis">
          <app-analysis-view [analysisResult]="documentStatus.analysed" [configKey]="configKey"
                             [analysisStage]="'ANALYSE_PRODUCT'"
                             [ignoredRules]="documentStatus.ignoredAnalysed"
                             [test]="testDefinition && testDefinition.name"></app-analysis-view>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="enrichedAnalysed" *ngIf="documentStatus.displayEnrichedAnalysed()">
      <a ngbNavLink>Analysed (Enriched)</a>
      <ng-template ngbNavContent>
        <div class="result analysis">
          <app-analysis-view [analysisResult]="documentStatus.enrichedAnalysed" [configKey]="configKey"
                             [analysisStage]="'ANALYSE_SOLO'"
                             [ignoredRules]="documentStatus.ignoredEnrichedAnalysed"
                             [test]="testDefinition && testDefinition.name"></app-analysis-view>
        </div>
      </ng-template>
    </li>

    <!--    <mat-tab label="Configuration" *ngIf="documentStatus.configString">-->
    <!--      <div class=".log-output ace">-->
    <!--        <ace [config]="aceEditableJsonConfig" [(value)]="documentStatus.configString"></ace>-->
    <!--      </div>-->
    <!--    </mat-tab>-->

    <li ngbNavItem="smartDxErrors" *ngIf="documentStatus.smartDxMessages && documentStatus.smartDxMessages.length > 0">
      <a ngbNavLink>{{renderDocumentService}} Errors</a>
      <ng-template ngbNavContent>
        <div class="result smartDx">
          <ul *ngFor="let message of documentStatus.smartDxMessages">
            <li>{{message.msgText}}</li>
          </ul>
        </div>
      </ng-template>
    </li>


    <li ngbNavItem="testHistory" *ngIf="testHistory">
      <a ngbNavLink>History</a>
      <ng-template ngbNavContent>
        <app-test-history-list [history]="testHistory"
                               (selectedVersion)="selectTestHistory($event)"></app-test-history-list>
      </ng-template>
    </li>

    <li ngbNavItem="apiErrorResponse" *ngIf="documentStatus.errorResponse">
      <a ngbNavLink>API Error Response</a>
      <ng-template ngbNavContent>
        <div class="error-response-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyJsonConfig"
            [ngModel]="documentStatus.errorResponse">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem="trace" *ngIf="documentStatus.status">
      <a ngbNavLink>Trace</a>
      <ng-template ngbNavContent>
        <div class="trace-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyJsonConfig"
            [ngModel]="documentStatus.status">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>


    <li ngbNavItem="chart" *ngIf="displayChart">
      <a ngbNavLink>Chart</a>
      <ng-template ngbNavContent>
        <div class="chart-output">
          <div class="chart-element" (click)="saveChartConfiguration()">
            <canvas baseChart width="676" height="450"
                    [datasets]="chartDatasets"
                    [options]="chartOptions"
                    [colors]="chartColours"
                    legend="false"
                    chartType="line">
            </canvas>
          </div>
        </div>
      </ng-template>
    </li>

  </ul>

  <div [ngbNavOutlet]="docgenNav" class="tab-content"></div>
</div>

<ng-container class="actions">
  <ng-container>
    <button type="button" class="btn btn-primary me-2 mt-2" (click)="generateDocument(false)">Generate Test Document</button>
    <button type="button" class="btn btn-info me-2 mt-2" (click)="generateDocument(true, false, true)">Generate Document (with Events)</button>
    <button type="button" class="btn btn-info me-2 mt-2" (click)="generateDocument(true)">Generate Document</button>
    <button type="button" class="btn btn-primary me-2 mt-2" *ngIf="wordAvailable" (click)="generateDocument(true, true)">Generate Word</button>
    <button type="button" class="btn btn-info me-2 mt-2" (click)="downloadDoc()"
            *ngIf="documentStatus.doc && config">Download Word
    </button>
    <button type="button" class="btn btn-primary me-2 mt-2" (click)="generateDocument(false, false, false, true)">Check Payload
    </button>
    <ng-container *ngIf="!documentStatus.generating">
      <ng-container *ngIf="!expectedDocument && !testDefinition?.document">
        <button type="button" class="btn btn-info me-2 mt-2" (click)="saveTestPayload()">Save As Test Payload</button>
      </ng-container>
      <ng-container *ngIf="documentStatus.pdf">
        <button type="button" class="btn btn-warning me-2 mt-2" (click)="saveRegressionTest()">Save As Regression Test</button>
      </ng-container>
      <ng-container *ngIf="!expectedDocument && !documentStatus.pdf">
        <input id="expectedFile1" type="file" class="inputfile" (change)="uploadExpectedFile($event)"/>
        <label for="expectedFile1">
          <span type="button" class="btn btn-info me-2 mt-2">
          Upload Expected File
          </span>
        </label>
      </ng-container>
      <ng-container *ngIf="expectedDocument && !documentStatus.pdf">
        <button type="button" class="btn btn-warning me-2 mt-2" (click)="resetExpected()">Remove Expected</button>
        <button type="button" class="btn btn-danger me-2 mt-2" (click)="saveAcceptanceTest()">Save As Acceptance Test</button>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="(documentStatus.htmlDiff && documentStatus.hasPdfDiff) || documentStatus.hasDocDiff || documentStatus.hasFontDiff">
      <button type="button" class="btn btn-danger me-2 mt-2"
              *ngIf="!documentStatus.draftableUrl && !documentStatus.generatingDiff" (click)="draftableReport()">View
        Draftable
      </button>
      <button type="button" class="btn btn-danger me-2 mt-2" *ngIf="documentStatus.draftableUrl && !documentStatus.generatingDiff"
              (click)="viewDraftableReport()">View Draftable
      </button>
      <!-- <button type="button" class="btn btn-danger"
              *ngIf="!documentStatus.draftableUrl && !documentStatus.generatingDiff" (click)="draftableReport(true)">View
        Draftable (Beta)
      </button> -->
      <!-- <button type="button" class="btn btn-danger" *ngIf="documentStatus.draftableUrl && !documentStatus.generatingDiff"
              (click)="viewDraftableReport(true)">View Draftable (Beta)
      </button> -->
    </ng-container>
    <ng-container *ngIf="isJson">
      <button type="button" class="btn btn-info me-2 mt-2" (click)="openJmesPathDialog()">JMES Evaluator</button>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="testDefinition">
    <button type="button" (click)="openRegExDialog()" class="btn btn-primary me-2 mt-2">Ignore Patterns</button>
  </ng-container>
</ng-container>
