import {Injectable} from '@angular/core'
import {ChangeService} from './change.service'
import {StatusService} from './status.service'
import {WorkspaceService} from './workspace.service'
import {combineLatest} from 'rxjs'
import {ConfigMonitorService} from './config-monitor.service'
import {DocgenConfigAndMetadataChangeDetails} from '../common/domain/change'
import {SaveWorkspaceRequest} from '../model/workspace-requests'
import {Workspace, WorkspaceName} from '../common/domain/workspaces'
import {ConfigService} from './config.service'
import {ConfigSource} from '../common/domain/config'

const deepEquals = require('deep-equal')

@Injectable({
  providedIn: 'root',
})
export class WorkspaceAutoSaveService {
  private versions: { source: ConfigSource, version: string}[]
  private configs: DocgenConfigAndMetadataChangeDetails[] = []
  private canSave: boolean
  private workspace: Workspace

  constructor(
    private changeService: ChangeService,
    private configService: ConfigService,
    private configMonitorService: ConfigMonitorService,
    private workspaceService: WorkspaceService,
    private statusService: StatusService,
  ) {
    combineLatest([this.configMonitorService.canSave,
      this.changeService.getUserFilteredDocgenConfigs(),
      this.configService.loadedVersions,
      this.workspaceService.selectedWorkspace,
    ])
      .subscribe(([canSave, configs, versions, workspace]) => {
        this.versions = versions
        this.configs = configs
        this.canSave = canSave && versions && versions.length > 0 && configs && configs.length > 0
        this.workspace = workspace
      })
  }

  newWorkspace(workspaceName: WorkspaceName, selected: string[]) {
    if (selected) {
      const saveRequest: SaveWorkspaceRequest = {
        name: workspaceName,
        parentCommit: this.versions.find(v => v.source === 'lambda-ingest-product')!.version,
        // @ts-ignore
        // TODO - XXX - Better storage of versions...
        versions: this.versions,
        changes: this.changeService.getAllChanges().filter(change => change.state !== 'original'),
      }
      const statusId = this.statusService.start('Creating workspace')
      this.workspaceService.saveWorkspace(workspaceName, saveRequest)
        .subscribe(() => {
          this.workspaceService.selectWorkspace(workspaceName)
          this.statusService.complete(statusId)
        })
    }
  }

  saveWorkspace() {
    if (this.workspace) {
      const changes = this.changeService.getAllChanges().filter(change => change.state !== 'original')
      if (!deepEquals(changes, this.workspace.changes)) {
        const statusId = this.statusService.start('Saving workspace')
        const saveRequest: SaveWorkspaceRequest = {
          name: this.workspace.name,
          parentCommit: this.workspace.parentCommit,
          changes,
        }
        this.workspaceService.saveWorkspace(this.workspace.name, saveRequest)
          .subscribe(() => this.statusService.complete(statusId))
      }
    }
  }
}
