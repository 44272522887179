import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {jsonEditorOptions} from '../../util/editor-configurations'

const jmespath = require('jmespath')

@Component({
  selector: 'app-jmes-path-evaluator',
  templateUrl: './jmes-path-evaluator.component.html',
  styleUrls: ['./jmes-path-evaluator.component.scss'],
})
export class JmesPathEvaluatorComponent implements OnInit, OnDestroy, OnChanges {
  $destroy = new EventEmitter()

  @Input() payload: object
  @Input() isPayloadEvaluator: boolean

  payloadModel: string
  expression = '@'
  result: string

  options = jsonEditorOptions

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.$destroy.emit()
  }

  evaluate() {
    if (this.expression && this.payloadModel) {
      if (!this.expression.endsWith('.')) {
        try {
          const p = JSON.parse(this.payloadModel)
          const quantoMatch = this.expression.match(/^quanto(Pairs|PairsCount)?\((.*?),(.*?)\)$/)
          if (quantoMatch) {
            // console.log('QUANTO')
            const productSearchResult = jmespath.search(p, quantoMatch[2])
            const product = this.format(productSearchResult)
            const underlyings = jmespath.search(p, quantoMatch[3])

            // console.dir({
            //   product: product,
            //   underlyings: underlyings
            // })
            const pairs = quantoMatch[1] === 'Pairs'
            const pairCount = quantoMatch[1] === 'PairsCount'
            const mix= quantoMatch[1] === 'Mix'
            if (pairs || pairCount) {
              const quantoUnderlyings = underlyings
                .filter(u => u !== product)
                .filter((value, index, array) => array.indexOf(value) === index)
              if (pairCount) {
                this.result = JSON.stringify(quantoUnderlyings.length)
              } else {
                if (quantoUnderlyings.length === 0) {
                  this.result = 'blank'
                } else {
                  const asCurrencyPair = (product: string, underlying: string) => underlying.localeCompare(product) < 0 ? underlying + product : product + underlying
                  const quantoUnderlyingPairs = quantoUnderlyings.map(underlying => asCurrencyPair(product, underlying))
                  const values = quantoUnderlyingPairs.map(x => `${x}`)
                  values.sort()
                  this.result = `${values.join(',')}`
                }
              }
            } else if(mix) {
              const quanto = underlyings.some(v => v !== product)
              const settlementBased = underlyings.some((v => v === product))
              const result = quanto && settlementBased
              this.result = JSON.stringify(result)
            } else {
              const quanto = underlyings.some(v => v !== product)
              this.result = JSON.stringify(quanto)
            }
          } else {
            const searchResult = jmespath.search(p, this.expression)
            this.result = this.format(searchResult)
          }
        } catch (e) {
          this.result = 'Invalid Payload'
        }
      }
    } else {
      this.result = ''
    }
    // console.log('Searching for ' + this.expression)
    // console.dir(this.result)
  }

  format = value => {
    if (value === null || value === undefined) {
      return 'null'
    } else if (value === '') {
      return 'blank'
    } else if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
      return '' + value
      // } else if (Array.isArray(value)) {
      //   return value.map(x => this.format(x)).join(',')
    } else {
      return JSON.stringify(value, undefined, 2)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.payloadModel = JSON.stringify(this.payload, undefined, 2)
    this.evaluate()
  }
}
