import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Inject,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  
} from "rxjs/operators";

import { Observable, of, Subject, Subscription, merge } from "rxjs";

import {
  GetRecommendedTestsResponse,
  RecommendedTest,
} from "../model/test-management-responses";
import { TestManagerService } from "../services/test-manager.service";

@Component({
  selector: "test-suite-recomendtions",
  templateUrl: "./test-recommendation.component.html",
  styleUrls: ["./test-recommendation.component.scss"],
})
export class TestSuiteRecomendationsComponent implements OnInit {
  subs = new Subscription();

  @Output() submitClicked = new EventEmitter<any>();
  rule: string;
  isSelected = false
  testName = "Assign Test";
  currentRule = ''
  selectedRecomendedTest = "There is no recomended test";
  recommendedTests: GetRecommendedTestsResponse[] = [];

  recomendedTestWithAlternatives = [];
  @ViewChild("instance") instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  public model: any;

  constructor(
    private testManagerService: TestManagerService,
    public ruleSetDialogRef: MatDialogRef<TestSuiteRecomendationsComponent>,

    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? this.recomendedTestWithAlternatives
          : this.recomendedTestWithAlternatives.filter(
            (v) => v.testName.toLowerCase().indexOf(term.toLowerCase()) > -1
          )
        ).slice(0, 10)
      )
    );
  };
  formatter = (x: { testName: string }) => {
    x.testName;
    this.selectedRecomendedTest = x.testName;
  };
  async ngOnInit() {
    this.subs.add(
      this.testManagerService.recommendedTests.subscribe((results) => {
        Object.keys(results).map((key) => {
          const res = results[key];
          let value = this.data.ruleData.find((r) => {
            if (r.recomendedTest) {
              this.testName = `Replace Test`;
              this.currentRule = `(${r.recomendedTest})`
            }
            this.rule = r.ruleMnemonic;

            if (r.currentFullMnemonic === key) {
              this.selectedRecomendedTest = results[key].testName;
              if (!this.recommendedTests.includes(results[key])) {
                this.recommendedTests.push(results[key]);
              }
              this.getAlternativeForDropDown();
            }
          });
        });
      })
    );
    this.isSelected = true;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  closeDialog() {
    this.ruleSetDialogRef.close(undefined);
  }

  saveSelectedTest() {
    if (this.selectedRecomendedTest === "There is no recomended test") {
      this.submitClicked.emit(undefined);
      this.ruleSetDialogRef.close(undefined);
    } else {
      this.data.ruleData.map((r) => {
        if (r.ruleMnemonic === this.rule) {
          r.assign = true;
          r.recomendedTest = this.selectedRecomendedTest;
          this.submitClicked.emit(r.recomendedTest);
        }
      });
    }
    this.ruleSetDialogRef.close();
  }

  getAlternativeForDropDown() {
    const obj = {
      executions: this.recommendedTests[0].executions,
      lastExecuted: this.recommendedTests[0].lastExecuted,
      testName: this.recommendedTests[0].testName,
    };

    this.recomendedTestWithAlternatives.push(obj) as unknown as RecommendedTest[];
    if (this.recommendedTests[0].alternatives !== undefined) {
      Object.entries(this.recommendedTests[0].alternatives).map((r) => {
        this.recomendedTestWithAlternatives.push(r[1]);
      });
    }
  }
}
