import { Injectable } from '@angular/core';
import {ApiService} from "../../../../services/api.service";
import {StatusService} from "../../../../services/status.service";
import {GenerateRequest, GenerateResponse} from "../models/md-gendoc-model";
import {BehaviorSubject} from "rxjs";
import {ToastrService} from "ngx-toastr";


@Injectable({
  providedIn: 'root'
})
export class ManualDraftingGendocService {

  public smartdxSuccesResponseSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public smartdxFailureResponseSubject: BehaviorSubject<GenerateResponse> = new BehaviorSubject<GenerateResponse>(undefined);

  constructor(
    private api: ApiService,
    private statusService: StatusService,
    private toast: ToastrService) { }

  sendToSmartdx(request: GenerateRequest) {
    this.toast.info('Sending payload to smartdx.')
    return this.api.post<any, any>(`/md-gendoc/generate/${request.id}`, request).subscribe(response => {
      if (response.status === 'PASSED') {
        this.smartdxSuccesResponseSubject.next(request.id)
      } else {
        this.smartdxFailureResponseSubject.next(response)
      }
    })
  }
}
