<app-config-workspace-buttons></app-config-workspace-buttons>

<h2>Dynamic Mappings</h2>

<ng-container *ngIf="canCreateConfig">
  <button mat-raised-button color="warn" (click)="createDynamicMapping()">Create New Mapping</button>
  Create a new mapping based on source system and document type
</ng-container>

<div class='mb-3 mt-n2 toggle-button'>
  <button class='btn btn-sm rounded-pill mr-2' id='showDeleted'
          [ngClass]="this.showDeletedMappingsSelected ? 'btn-primary' : 'btn-secondary'"
          (click)="toggleChip()">Show deleted Mappings</button>
</div>

<ng-container *ngIf="!this.showDeletedMappingsSelected">
  <div class="tableFixHead scroll-inherit-important">
    <table class="table">
      <thead>
      <tr>
        <th scope="col">Source System</th>
        <th scope="col">Document Type</th>
        <th scope="col">Program/Region Extractor</th>
        <th scope="col">Product Family/ Product Type Extractor</th>
        <th scope="col">Payload Type (json/xml)</th>
        <th scope="col">Additional Extractor Fields</th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of this.activeDynamicMappings">
          <td>{{item.sourceSystem}}</td>
          <td>{{item.documentType}}</td>
          <td>{{item.programme}}</td>
          <td>{{item.productType}}</td>
          <td>{{item.payloadType}}</td>
          <td class="additionalExtractorFields">
            <div *ngIf="item.extractorFields">
              {{item.extractorFields.extractorPath}}:
              <div *ngFor="let condition of item.extractorFields.conditions; let i = index">
                when {{condition.value}} set product type {{condition.newProductType}},
              </div>
            </div>
          </td>
          <td *ngIf="canEditMappings">
            <button mat-raised-button color="accent" (click)="edit(item)" [disabled]="!canEditMappings">Edit</button>
          </td>
          <td>
            <button mat-raised-button color="accent" (click)="delete(item)" [disabled]="!canEditMappings">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="this.showDeletedMappingsSelected">
  <div class="tableFixHead scroll-inherit-important">
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Source System</th>
        <th scope="col">Document Type</th>
        <th scope="col">Program/Region Extractor</th>
        <th scope="col">Product Family/ Product Type Extractor</th>
        <th scope="col">Payload Type (json/xml)</th>
        <th scope="col">Additional Extractor Fields</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of this.deletedDynamicMappings">
        <td>{{item.sourceSystem}}</td>
        <td>{{item.documentType}}</td>
        <td>{{item.programme}}</td>
        <td>{{item.productType}}</td>
        <td>{{item.payloadType}}</td>
        <td class="additionalExtractorFields">
          <div *ngIf="item.extractorFields">
            Extractor Path - {{item.extractorFields.extractorPath}}
            <div *ngFor="let condition of item.extractorFields.conditions; let i = index">
              Conditions {{i + 1}}: if is equal to {{condition.value}}, set new product pype {{condition.newProductType}}
            </div>
          </div>
        </td>
        <td>
          <button mat-raised-button color="accent" (click)="restoreDeleted(item)" [disabled]="!canEditMappings">Restore</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-container>
