import {PublishConfigAndMetadata} from '../common/domain/publish-config'

export class PublishPayloadRequest {
  payload: string
  sourceSystem: string
  businessUnit: string
  payloadType: string
  targetSystem: string
  productIdentifier: string
  config: PublishConfigAndMetadata
  contentType: 'application/xml' | 'application/json'
  stubbedRetrieveResponse?: string

  setConfig(config: PublishConfigAndMetadata) {
    this.config = config

    if (config && config.metadata) {
      this.sourceSystem = config.metadata.sourceSystem
      this.businessUnit = config.metadata.businessUnit
      this.payloadType = config.metadata.payloadType
      this.targetSystem = config.metadata.targetSystem
    }
  }
}
