import {Injectable} from '@angular/core'
import deepEqual from 'deep-equal'
import {ToastrService} from 'ngx-toastr'
import {combineLatest, Subject} from 'rxjs'
import {debounceTime, distinctUntilChanged, shareReplay, skip, skipWhile} from 'rxjs/operators'
import {DocgenConfigMetadata} from '../common/domain/docgen-config'
import {ApiService} from './api.service'
import {ConfigService} from './config.service'
import {DEFAULT_THEME, ThemeService} from './theme.service'
import {TokenService} from './token.service'

class UserPreferemces {
  theme = DEFAULT_THEME
  docgenConfigs = [] as DocgenConfigMetadata[]
}

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  loading = true

  private userPreferencesSubject = new Subject<UserPreferemces>()

  public userPreferences = this.userPreferencesSubject
    .pipe(
      skipWhile(() => this.loading),
      distinctUntilChanged(),
      shareReplay(1),
    )

  constructor(
    private tokenService: TokenService,
    private apiService: ApiService,
    private themeService: ThemeService,
    private configService: ConfigService,
    private toast: ToastrService,
  ) {
    tokenService.idToken.subscribe(idToken => {
      if (idToken) {
        apiService.post('/preferences', {}, {
          headers: {
            ID_TOKEN: idToken,
          },
        }).subscribe(response => {
          const preferences: UserPreferemces = Object.assign(new UserPreferemces(), response)
          themeService.setTheme(preferences.theme)
          configService.setFilteredDocgenConfigs(preferences.docgenConfigs)

          this.loading = false
          this.userPreferencesSubject.next(preferences)
        })
      }
    })

    combineLatest([
      themeService.theme,
      // This is needed to trigger the save. i.e. when configService.filteredDocgenConfigs changes it should be saved
      configService.filteredDocgenConfigs,
    ]).pipe(
      // tap(res => {
      //   console.log('Checking For User Preference Update: ' + this.loading)
      //   console.dir(res)
      // }),
      skipWhile(() => this.loading),
      distinctUntilChanged(deepEqual),
      // tap(res => {
      //   console.log('Triggering User Preference Update: ' + this.loading)
      //   console.dir(res)
      // }),
    ).subscribe(([
      theme,
      configs,
    ]) => {
      this.userPreferencesSubject.next({
        theme: theme,
        docgenConfigs: configs,
      })
    })

    this.userPreferences.pipe(
      skip(1),
      // tap(pref => {
      //   console.log('User Preferences Updated:')
      //   console.dir(pref)
      // }),
      debounceTime(1000),
      distinctUntilChanged(),
      // tap(pref => {
      //   console.log('Saving Updated User Preferences:')
      //   console.dir(pref)
      // }),
    ).subscribe(preferences => {
      this.apiService.post('/preferences', preferences)
        .subscribe(() => {
          this.toast.success('Saved user preferences.')
        })
    },
    )
  }
}
