import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core'
import {DateTime} from 'luxon'
import {Environment, ENVIRONMENTS, Region} from '../../../services/environment-service'
import {of, Subject} from 'rxjs'
import {debounceTime, distinctUntilChanged, map, mergeMap, switchMap, takeUntil, tap, toArray} from 'rxjs/operators'
import {MatTableDataSource} from '@angular/material/table'
import {ManagementInformationService, MiEvent} from '../../../services/management-information.service'
// import {stringify} from 'csv/lib/sync'
import {MatPaginator} from '@angular/material/paginator'
import { unparse } from 'papaparse'
import saveAs from 'file-saver'
const equals = require('fast-deep-equal')

const environmentLookup = (account) => [ENVIRONMENTS.TOOLS,ENVIRONMENTS.STAGING, ENVIRONMENTS.UAT, ENVIRONMENTS.PROD].filter(env => !!env).find((v) => v && (v.account === account))

interface SmartDxMonitorMiEvent extends MiEvent {
  source: 'inspire.smartdx-monitor'
}

interface FlattenedSmartDxMonitorMiEvent {
  timestamp: string
  date: string
  time: string
  environment: string
  region: string
  status: string
}

interface Request {
  environment: Environment
  fromDate: string
  toDate: string
}

@Component({
  selector: 'app-smart-dx-monitoring',
  templateUrl: './smart-dx-monitoring.component.html',
  styleUrls: ['./smart-dx-monitoring.component.scss'],
})
export class SmartDxMonitoringComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  loading = true
  cancelRequests = new Subject<void>()

  @Input() environment: Environment
  @Input() fromDate: string
  @Input() toDate: string

  results: FlattenedSmartDxMonitorMiEvent[] = []
  columns: string[] = [
    'date',
    'time',
    'environment',
    'region',
    'status',
  ]
  dataSource = new MatTableDataSource<FlattenedSmartDxMonitorMiEvent>()
  requests = new Subject<Request>()

  constructor(private managementInformationService: ManagementInformationService) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    // this.loading = true
    this.requests
      .pipe(
        takeUntil(this.cancelRequests),
        debounceTime(1000),
        distinctUntilChanged(equals),
        tap(_ => this.loading = true),
        tap(request => {
          console.log('Processing: ')
          console.dir(request)
        }),
        switchMap((request: Request) => {
          if (request.environment && request.fromDate) {
            const dateRange = request.fromDate + (request.toDate ? '/' + request.toDate : '')
            return of(...request.environment.regions)
              .pipe(
                mergeMap((region: Region) => {
                  console.log('Fetching data for ' + region.display)
                  return this.managementInformationService.getManagementInformation<SmartDxMonitorMiEvent[]>(
                    request.environment.id,
                    region.id,
                    'smartdx-monitor',
                    dateRange)
                    .pipe(
                      tap(results => {
                        console.log('Fetched Mi Data For ' + request.environment.display + ' in ' + region.display)
                        console.dir(results)
                      }),
                    )
                }),
                toArray(),
                tap((results: SmartDxMonitorMiEvent[][]) => {
                  console.log('Raw Mi Data')
                  console.dir(results)
                }),
                map((events: SmartDxMonitorMiEvent[][]): SmartDxMonitorMiEvent[] => {
                  return events.reduce((r, a) => r.concat(...a), <SmartDxMonitorMiEvent[]> [])
                }),
                tap((results: any[]) => {
                  console.log('Combined Mi Data')
                  console.dir(results)
                }),
                tap(_ => this.loading = false),
              )

          } else {
            return []
          }
        }),
      )
      .subscribe((events: SmartDxMonitorMiEvent[]) => {
        const flattened = events.map(e => this.format(e))
        flattened.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
        this.results = flattened
        this.dataSource.data = this.results
      })
    this.ngOnChanges(undefined)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.requests.next({
      environment: this.environment,
      fromDate: this.fromDate,
      toDate: this.toDate,
    })
  }

  ngOnDestroy(): void {
    this.cancelRequests.next()
  }

  format(e: SmartDxMonitorMiEvent): FlattenedSmartDxMonitorMiEvent {
    const environment = environmentLookup(e.account).display
    const region = e.region === 'eu-central-1' ? 'Frankfurt' : 'London'
    return {
      timestamp: e.time,
      date: DateTime.fromISO(e.time).toFormat('dd/MM/yyyy'),
      time: DateTime.fromISO(e.time).toFormat('HH:mm:ss'),
      environment: environment,
      region: region,
      status: e['detail-type'],
    }
  }

  downloadCsv() {
    const csv = unparse(this.results, {
      header: true,
    })
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
    const filename = ('mi-smartdx-monitoring-' + this.fromDate + (this.toDate ? '-to-' + this.toDate : '') + '.csv')
    console.log('Saving: ' + filename)
    saveAs(blob, filename)
  }
}
