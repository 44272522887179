import {Component, OnDestroy, OnInit} from '@angular/core'
import {WorkspaceService} from '../services/workspace.service'
import {PermissionsService} from '../services/permissions.service'
import {combineLatest, Subscription} from 'rxjs'
import {WorkspaceAutoSaveService} from '../services/workspace-auto-save.service'
import {ChangeService} from '../services/change.service'
import {StatusService} from '../services/status.service'
import {Workspace} from '../common/domain/workspaces'
import {MatSelectChange} from '@angular/material/select'

@Component({
  selector: 'app-workspace-selector',
  templateUrl: './workspace-selector.component.html',
  styleUrls: ['./workspace-selector.component.scss'],
})
export class WorkspaceSelectorComponent implements OnInit, OnDestroy {
  sub = new Subscription()
  workspaces: string[]
  selectedWorkspace: string
  activeWorkspace: Workspace
  useWorkspace = false
  disabled = false

  constructor(
    private workspaceService: WorkspaceService,
    private workspaceAutoSaveService: WorkspaceAutoSaveService,
    private permissionsService: PermissionsService,
    private changeService: ChangeService,
    private statusService: StatusService,
  ) {
    permissionsService.workspaces.subscribe(workspaces => this.useWorkspace = workspaces)
  }

  ngOnInit() {
    combineLatest([this.permissionsService.workspaces,
      this.workspaceService.workspaceNames,
      this.workspaceService.selectedWorkspace,
      this.changeService.changes])
      .subscribe(([useWorkspaces, workspaceNames, workspace, changes]) => {
        if (!!workspaceNames) {
          workspaceNames = workspaceNames.sort((a,b) => { return +a.toLowerCase() <= +b.toLowerCase() ? -1 : 1})
        }
        this.useWorkspace = useWorkspaces
        this.workspaces = workspaceNames || []
        this.activeWorkspace = workspace
        this.selectedWorkspace = workspace && workspace.name
        this.disabled = workspace === undefined && changes.some(c => c.state !== 'original')
      })
  }

  async ngOnDestroy() {
    await this.workspaceAutoSaveService.saveWorkspace()
    this.sub.unsubscribe()
  }

  async selectWorkspace(event: MatSelectChange) {
    const statusId = this.statusService.start('Switching workspace')
    await this.workspaceAutoSaveService.saveWorkspace()
    await this.changeService.clearChanges()
    await this.workspaceService.selectWorkspace(event.value)
    this.statusService.complete(statusId)
  }
}
