import {DocumentMessage} from '../common/domain/render'
import {IngestProductDocgenTraceResponse} from './public-api-response'
import {DocgenConfigAndMetadata} from '../common/domain/docgen-config'

export class ExpectedDocument {
  bytes: Uint8Array
  base64: string
  file: File
  displayPdf: boolean
}

export class GenerateDocumentStatus {
  generating: boolean
  requestId: string
  displayPdf: boolean
  pdf: Uint8Array
  doc: Uint8Array
  transformed1: string
  transformed2: string
  transformed: string
  enriched1: string
  enriched2: string
  enriched: string
  log: string
  analysed: string
  ignoredAnalysed: string
  enrichedAnalysed: string
  ignoredEnrichedAnalysed: string
  validated: string
  enrichedValidated: string
  generatingDiff: boolean
  hasXmlDiff: boolean
  hasPdfDiff: boolean
  hasDocDiff: boolean
  htmlDiff: string
  hasFontDiff: boolean
  rawDiff: string
  status: string
  base64Document: string
  configString: string
  config: DocgenConfigAndMetadata
  draftableUrl: string
  xmlDiff: any[]
  rawXmlDiff: string
  smartDxMessages?: DocumentMessage[]
  rawResponse?: IngestProductDocgenTraceResponse
  errorResponse: string
  approvalResponse: string

  reset() {
    this.requestId = undefined
    this.displayPdf = false
    this.pdf = undefined
    this.doc = undefined
    this.transformed1 = undefined
    this.transformed2 = undefined
    this.transformed = undefined
    this.enriched1 = undefined
    this.enriched2 = undefined
    this.enriched = undefined
    this.log = undefined
    this.enrichedAnalysed = undefined
    this.validated = undefined
    this.enrichedValidated = undefined
    this.hasXmlDiff = undefined
    this.hasPdfDiff = undefined
    this.htmlDiff = undefined
    this.rawDiff = undefined
    this.generatingDiff = false
    this.config = undefined
    this.status = undefined
    this.base64Document = undefined
    this.draftableUrl = undefined
    this.xmlDiff = []
    this.rawXmlDiff = undefined
    this.errorResponse = undefined
    this.approvalResponse = undefined
  }

  displayTabs() {
    return this.generating || this.status
  }

  displayValidation() {
    if (!this.validated) {
      return false
    }
    const validationResult = JSON.parse(this.validated)
    // @ts-ignore
    return validationResult.status !== 'OK'
  }

  displayEnrichedValidation() {
    if (!this.enrichedValidated) {
      return false
    }
    const validationResult = JSON.parse(this.enrichedValidated)
    // @ts-ignore
    return validationResult.status !== 'OK'
  }

  displayAnalysed() {
    if (!this.analysed) {
      return false
    }
    const analysisResult = JSON.parse(this.analysed)
    // @ts-ignore
    return analysisResult.status !== 'SKIPPED'
  }

  displayEnrichedAnalysed() {
    if (!this.enrichedAnalysed) {
      return false
    }
    const analysisResult = JSON.parse(this.enrichedAnalysed)
    // @ts-ignore
    return analysisResult.status !== 'SKIPPED'
  }

  setConfig(config: DocgenConfigAndMetadata) {
    if (config) {
      this.config = config
      this.configString = JSON.stringify(JSON.parse(config.settings.content), undefined, 2)
    } else {
      this.config = undefined
      this.configString = undefined
    }
  }
}
