<h3>Management Information</h3>

<div class="report-selection">
  <mat-form-field>
    <mat-label>MI Dataset</mat-label>
    <mat-select [formControl]="selectedReportControl">
      <mat-option *ngFor="let report of reports" [value]="report">{{report.display}}</mat-option>
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="allowEnvironmentSelection">
    <app-source-environment-selector></app-source-environment-selector>
  </ng-container>

  <ng-container *ngIf="singleDateSelection">
    <mat-form-field>
      <mat-label>Date</mat-label>
      <mat-select (valueChange)="setDateRange($event)">
        <mat-option *ngFor="let dateRange of quickDates" [value]="dateRange">{{dateRange.display}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="dateRangeSelection">
    <mat-form-field>
      <mat-label>Range</mat-label>
      <mat-select (valueChange)="setDateRange($event)">
        <mat-option *ngFor="let dateRange of quickDateRanges" [value]="dateRange">{{dateRange.display}}</mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <form class="form-inline" *ngIf="singleDateSelection || dateRangeSelection">
    <div class="form-group">
      <div class="input-group">
        <input #dpFromDate
               class="form-control" placeholder="yyyy-mm-dd"
               name="dpFromDate"
               maxlength="10"
               [formControl]="fromDateControl">
        <input #dpToDate
               *ngIf="dateRangeSelection"
               class="form-control" placeholder="yyyy-mm-dd"
               name="dpToDate"
               maxlength="10"
               [formControl]="toDateControl">
      </div>
      <button class="btn btn-outline-primary ms-2" (click)="download()" type="button">
        <fa-icon icon="play"></fa-icon>
      </button>
    </div>
  </form>
</div>

<!--<pre class="debug">-->
<!--  Report: {{ report | json }}-->
<!--  Environment: {{ environment | json }}-->
<!--  From: {{ fromDate }}-->
<!--  To: {{ toDate }}-->
<!--</pre>-->

<ng-container *ngIf="!(fetch | async)">
  <div>Select the source, environment if needed and dates - then hit play to fetch the report / data.</div>
  <div>Please note, some datasets are too big for large date ranges and will fail.</div>
  <div>Connectivity in particular gets too big after roughly 1 weeks worth of events</div>
</ng-container>
<ng-container *ngIf="fetch | async">
  <ng-container *ngIf="report?.id === 'client-summary' && this.fromDate && this.toDate">
    <app-client-summary-report [fromDate]="fromDate" [toDate]="toDate"></app-client-summary-report>
  </ng-container>

  <ng-container *ngIf="report?.id === 'client-sla' && this.fromDate && this.toDate">
    <app-client-service-levels [fromDate]="fromDate" [toDate]="toDate"></app-client-service-levels>
  </ng-container>

  <ng-container *ngIf="report?.id === 'document-generation' && this.fromDate">
    <app-docgen-management-information [fromDate]="fromDate" [toDate]="toDate"
                                       [environment]="environment"></app-docgen-management-information>
  </ng-container>

  <ng-container *ngIf="report?.id === 'jira-issues' && this.fromDate">
    <app-jira-management-information [fromDate]="fromDate" [toDate]="toDate"
                                     [environment]="environment"></app-jira-management-information>
  </ng-container>

  <ng-container *ngIf="report?.id === 'approvals' && this.fromDate">
    <app-approvals-management-information [fromDate]="fromDate"
                                          [toDate]="toDate"></app-approvals-management-information>
  </ng-container>

  <ng-container *ngIf="report?.id === 'connectivity' && this.fromDate">
    <app-connectivity-management-information [fromDate]="fromDate" [toDate]="toDate"
                                             [environment]="environment"></app-connectivity-management-information>
  </ng-container>

  <ng-container *ngIf="report?.id === 'smartdx-requests' && this.fromDate">
    <app-smart-dx-requests [fromDate]="fromDate" [toDate]="toDate" [environment]="environment"></app-smart-dx-requests>
  </ng-container>

  <ng-container *ngIf="report?.id === 'smartdx-maintenance' && this.fromDate">
    <app-smart-dx-maintenance [fromDate]="fromDate" [toDate]="toDate"
                              [environment]="environment"></app-smart-dx-maintenance>
  </ng-container>

  <ng-container *ngIf="report?.id === 'smartdx-monitoring' && this.fromDate">
    <app-smart-dx-monitoring [fromDate]="fromDate" [toDate]="toDate"
                             [environment]="environment"></app-smart-dx-monitoring>
  </ng-container>
  
  <ng-container *ngIf="report?.id === 'wsd-docauto-requests' && this.fromDate">
    <app-wsd-docauto-requests [fromDate]="fromDate" [toDate]="toDate"
                             [environment]="environment"></app-wsd-docauto-requests>
  </ng-container>
                             
  <ng-container *ngIf="report?.id === 'wsd-priipcloud-requests' && this.fromDate">
    <app-wsd-priipcloud-requests [fromDate]="fromDate" [toDate]="toDate"
                             [environment]="environment"></app-wsd-priipcloud-requests>
  </ng-container>
  <ng-container *ngIf="report?.id === 'validation-report' && this.fromDate">
    <app-validation-report [fromDate]="fromDate" [toDate]="toDate"
                             [environment]="environment"></app-validation-report>
  </ng-container>
</ng-container>
