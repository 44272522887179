import {Component, OnInit} from '@angular/core';
import {Observable, of, Subscription} from 'rxjs';
import {PermissionsService} from '../../services/permissions.service';
import {ChangeDetails, ChangedState} from '../../common/domain/change';
import {CreateDynamicMappingsDialogComponent} from '../../dialogs/create-dynamic-mappings-dialog/create-dynamic-mappings-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ConfigDetail} from '../../common/domain/config';
import {ChangeService} from '../../services/change.service';
import {WorkspaceAutoSaveService} from '../../services/workspace-auto-save.service';
import {switchMap, take} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {UserNameService} from '../../services/user-name.service';
import {ConfirmDialogComponent} from '../../dialogs/confirm-dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EditDynamicMappingsDialogComponent} from "../../dialogs/edit-dynamic-mappings-dialog/edit-dynamic-mappings-dialog.component";

const uuid = require('uuid').v4

export interface DynamicMapping {
  id: string
  sourceSystem: string
  documentType: string
  programme: string
  productType: string
  payloadType: 'xml' | 'json'
  deleted?: boolean
  userName?: string
  extractorFields?: DmExtractorFields
}

export interface DmExtractorFields {
  extractorPath: string,
  conditions: Conditions[]
}

interface Conditions {
  value: string,
  newProductType: string
}

export interface RequestMapping {
  configDetail: ConfigDetail
  state: ChangedState
  mappings: DynamicMapping[]
  sourceSystem: string
}

@Component({
  selector: 'app-config-mappings-dynamic',
  templateUrl: './config-mappings-dynamic.component.html',
  styleUrls: ['./config-mappings-dynamic.component.scss']
})
export class ConfigMappingsDynamicComponent implements OnInit {
  sub = new Subscription()
  canCreateConfig = false
  canEditMappings = false
  public requestMappings: RequestMapping[] = []
  public activeDynamicMappings: DynamicMapping[] = []
  private username: string
  payloadTypes: string[] = ['xml', 'json']
  deletedDynamicMappings: DynamicMapping[] = []
  public showDeletedMappingsSelected = false

  constructor(
    private permissionsService: PermissionsService,
    private dialog: MatDialog,
    private changeService: ChangeService,
    protected workspaceAutoSaveService: WorkspaceAutoSaveService,
    private toast: ToastrService,
    private userNameService: UserNameService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    const requestMappings$ = this.changeService.getObjectsOfType('request-mappings')
      .pipe(
        switchMap((configs: ChangeDetails[]) => of(configs.filter(config => config.source === 'lambda-ingest-product'))),
      )
    this.sub.add(requestMappings$.subscribe((changes: ChangeDetails[]) => {
        if(changes.length > 0) {
          this.getDataFromChangeService(requestMappings$)
        }
      }
    ))

    this.sub.add(this.userNameService.userName.pipe().subscribe((username: string) => {
      this.username = username
    }))

    this.permissionsService.createConfig.subscribe((permission: boolean) => this.canCreateConfig = permission)
    this.permissionsService.editMappings.subscribe((permission: boolean) => this.canEditMappings = permission)
  }

  async getDataFromChangeService(requestMappings$: Observable<ChangeDetails[]>) {
    const userFilteredDocgenConfigs$ = this.changeService.getUserFilteredDocgenConfigs()
    const [changeServiceRequestMappings, userFilteredDocgenConfigs] = await Promise.all([
      requestMappings$.pipe(take(1)).toPromise(),
      userFilteredDocgenConfigs$.pipe(take(1)).toPromise()
    ])
    const requestMappings = changeServiceRequestMappings.map(
      configDetail => {
        const dynamicMappings = JSON.parse(configDetail.content) as DynamicMapping[]
        const newRequestMapping: RequestMapping = {
          configDetail: configDetail,
          mappings: dynamicMappings,
          state: configDetail.state,
          sourceSystem: dynamicMappings[0].sourceSystem
        }
        return newRequestMapping
      })

    this.requestMappings = requestMappings.sort((a, b) => (a.sourceSystem < b.sourceSystem ? -1 : 1));
    const activeMappings: DynamicMapping[] = []
    this.activeDynamicMappings = this.filterRequestMappings(activeMappings, true)
    const deletedMappings: DynamicMapping[] = []
    this.deletedDynamicMappings = this.filterRequestMappings(deletedMappings, false)
  }

  filterRequestMappings(filteredMapping: DynamicMapping[], wantActive: boolean) {
    this.requestMappings.map(requestMapping => {
      requestMapping.mappings.map(mapping => {
        if (wantActive) {
          if(!mapping.deleted) {
            filteredMapping.push(mapping)
          }
        } else {
          if(mapping.deleted) {
            filteredMapping.push(mapping)
          }
        }
      })
    })
    return filteredMapping
  }

  async createDynamicMapping() {
    const dialogRef = this.dialog.open(CreateDynamicMappingsDialogComponent, {
      width: '750px',
      data: {
        panelClass: 'dialogFormField500'
      }
    })
    await dialogRef.afterClosed().toPromise().then((newMapping: DynamicMapping) => {
      let content: DynamicMapping[] = []
      newMapping.id = uuid()
      newMapping.sourceSystem = newMapping.sourceSystem.toLowerCase()
      newMapping.userName = this.username
      const sourceSystemMapping: RequestMapping = this.requestMappings.filter(m => m.sourceSystem === newMapping.sourceSystem)[0]
      if (sourceSystemMapping) {
        if (!this.isUniqueMapping(newMapping)) {
          this.toast.error(`The mapping for source system: ${sourceSystemMapping.sourceSystem}, document type: ${newMapping.documentType} and payload type: ${newMapping.payloadType} already exists`)
          return
        }
        sourceSystemMapping.mappings.push(newMapping)
        content = sourceSystemMapping.mappings
      } else {
        content.push(newMapping)
      }
      this.saveChange(newMapping.sourceSystem, content)
    });
  }

  async edit(item: DynamicMapping) {
    const dialogRef = this.dialog.open(EditDynamicMappingsDialogComponent, {
      width: '750px',
      data: {
        panelClass: 'dialogFormField500',
        editedMapping: item
      }
    })
    await dialogRef.afterClosed().toPromise().then((editedMapping: DynamicMapping) => {
      if(editedMapping === undefined) {
        return
      }
      const sourceSystemMapping: RequestMapping = this.requestMappings.filter(m => m.sourceSystem === editedMapping.sourceSystem)[0]
      sourceSystemMapping.mappings.map(mapping => {
        if(mapping.id === item.id) {
          mapping.programme = editedMapping.programme
          mapping.productType = editedMapping.productType
          mapping.payloadType = editedMapping.payloadType
          mapping.extractorFields = editedMapping.extractorFields
        }
      })
      this.saveChange(editedMapping.sourceSystem, sourceSystemMapping.mappings)
    });
  }

  saveChange(sourceSystem: string, content: DynamicMapping[]) {
    const fileName = `${sourceSystem}.json`
    const filepath = `request-mappings/${fileName}`
    const newChangeDetails: ConfigDetail = {
      content: JSON.stringify(content, null, 2),
      name: fileName,
      path: filepath,
      size: undefined,
      source: 'lambda-ingest-product',
      hash: undefined,
      modified: undefined,
      state: undefined,
    } as ConfigDetail
    this.changeService.addChange(newChangeDetails)
    this.workspaceAutoSaveService.saveWorkspace()
  }

  delete(item: DynamicMapping) {
    const dialogRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })

    dialogRef.componentInstance.title = 'Please confirm'
    dialogRef.componentInstance.message = 'Are you sure you want to delete'
    dialogRef.result
      .then(confirm => {
        const sourceSystemMapping: RequestMapping = this.requestMappings.filter(m => m.sourceSystem === item.sourceSystem)[0]
        sourceSystemMapping.mappings.map(mapping => {
          if(!mapping.deleted && mapping.id === item.id) {
            mapping.deleted = true
          }
        })
        this.saveChange(item.sourceSystem, sourceSystemMapping.mappings)
        this.toast.success('Deleted Mapping')
      }).catch(err => {
      this.toast.info('Cancelled', undefined, {
        timeOut: 1500,
      })
    })
    this.ngOnInit()
  }

  restoreDeleted(item: DynamicMapping) {
    if(!this.isUniqueMapping(item)) {
      this.toast.error(`Cannot restore: the mapping for source system: ${item.sourceSystem}, document type: ${item.documentType} and payload type: ${item.payloadType} already exists`)
      return
    }
    const sourceSystemMapping: RequestMapping = this.requestMappings.filter(m => m.sourceSystem === item.sourceSystem)[0]
    sourceSystemMapping.mappings.map(mapping => {
      if(mapping.id === item.id) {
        mapping.deleted = false
      }
    })
    this.saveChange(item.sourceSystem, sourceSystemMapping.mappings)
    this.toast.success('Restored Mapping')
    this.ngOnInit()
  }

  isUniqueMapping(newMapping: DynamicMapping): boolean {
    let unique = true
    this.activeDynamicMappings.map(existingMapping => {
      if (existingMapping.sourceSystem === newMapping.sourceSystem && existingMapping.documentType === newMapping.documentType && existingMapping.payloadType === newMapping.payloadType) {
        unique = false
      }
      return
    })
    return unique
  }

  toggleChip() {
    this.showDeletedMappingsSelected = !this.showDeletedMappingsSelected
  }

}
