import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {IngestProductPublishTraceResponse} from '../model/public-api-response'
import {PublishPayloadRequest} from '../model/publish-payload-request'
import {Environment, ENVIRONMENTS, EnvironmentService} from './environment-service'
import {map, switchMap, take, tap} from 'rxjs/operators'
import {PerformPublishGetRequest, PerformTraceRequest} from '../model/test-management-requests'
import {PublishConfig} from '../common/domain/publish-config'
import {ConfigDetail} from '../common/domain/config'

@Injectable({
  providedIn: 'root',
})
export class PublishPayloadService {

  constructor(
    private apiService: ApiService,
    private environmentService: EnvironmentService) {
    environmentService.targetEnvironment
  }

  getPublished(request: PublishPayloadRequest) {
    // return of({}) ||
    const config: PublishConfig = JSON.parse(request.config.settings.content);
    return this.environmentService.targetEnvironment
      .pipe(
        take(1),
        switchMap((env: Environment) => {
          if (env === ENVIRONMENTS.STAGING) {
            const url = this.getRequestUrl(request)
            return this.apiService.get<any>(url, {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                source_system: request.sourceSystem,
                schema: config.publish.regxchangeRetrieveSchema ? config.publish.regxchangeRetrieveSchema : 'regxchange'
              },
            }).pipe(
              tap(res => {
                console.log('Got publish response')
                console.dir(res)
              }),
              map(res => {
                return res.data || res
              }),
            )
          } else {
            return this.environmentService.region.pipe(
              take(1),
              switchMap(region => {
                const performPublishGetRequest: PerformPublishGetRequest = {
                  environment: env.id,
                  region: region.id,
                  action: 'PerformPublishGet',
                  requestIdentifier: request.productIdentifier,
                  product: {
                    service: 'publish',
                    sourceSystem: request.sourceSystem,
                    businessUnit: request.businessUnit,
                    payloadType: request.payloadType,
                    targetSystem: request.targetSystem,
                  },
                  retrieveSchema: config.publish.regxchangeRetrieveSchema ? config.publish.regxchangeRetrieveSchema : 'regxchange'
                }
                const url = '/tests/'
                return this.apiService.post<PerformPublishGetRequest, any>(url, performPublishGetRequest, {
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    source_system: request.sourceSystem,
                  },
                })
                  .pipe(
                    tap(res => {
                      console.log('Got publish response from ' + env.display + ' at ' + region.display)
                      console.dir(res)
                    }),
                    map(res => {
                      const resObject = (typeof res === 'string') ? JSON.parse(res) : res
                      return resObject['body'] ? resObject['body'] : resObject
                    }),
                  )
              }),
            )
          }
        }),
      )
  }

  publishPayload(request: PublishPayloadRequest, privateConfig: boolean, testMode: boolean, forceEvents?: boolean) {
    interface PrivatePublishConfigBody {
      config?: PublishConfig
      payload: string
      mimeType: string
      schemas?: ConfigDetail[]
      transforms?: ConfigDetail[]
      stubbedRetrieveResponse?: string
    }

    const privateConfigBody = (): PrivatePublishConfigBody => {
      return {
        config: request.config ? JSON.parse(request.config.settings.content) : undefined,
        payload: request.payload,
        mimeType: request.contentType,
        schemas: request.config ? request.config.schemas.map(r => {
          return {
            name: r.name,
            path: r.path,
            content: r.content,
            source: r.source,
          } as ConfigDetail
        }) : undefined,
        transforms: request.config ? request.config.transforms.map(r => {
          return {
            name: r.name,
            path: r.path,
            content: r.content,
            source: r.source,
          } as ConfigDetail
        }) : undefined,
        stubbedRetrieveResponse: request.stubbedRetrieveResponse,
      }
    }

    const privateTestConfigBody: PrivatePublishConfigBody = {
      payload: request.payload,
      mimeType: request.contentType,
      stubbedRetrieveResponse: request.stubbedRetrieveResponse,
    }
    const payload = privateConfig
      ? JSON.stringify(privateConfigBody())
      : testMode
        ? JSON.stringify(privateTestConfigBody)
        : request.payload

    return this.environmentService.targetEnvironment
      .pipe(
        take(1),
        switchMap((env: Environment) => {
          if (env === ENVIRONMENTS.STAGING) {
            const url = this.getRequestUrl(request)
            return this.apiService.post<string, IngestProductPublishTraceResponse>(url, payload, {
              params: {
                testOnly: (testMode ? 'true' : 'false'),
                forceEvents: (forceEvents ? 'true' : 'false'),
                privateConfig: (privateConfig ? 'true' : 'false'),
                trace: 'true',
                forcePublish: 'true',
                 // use stormRequest field to determine if it's an internal request or not
                 stormRequest: 'true',
              },
              headers: {
                Accept: 'application/json',
                'Content-Type': request.contentType,
                source_system: request.sourceSystem,
              },
            })
          } else {
            return this.environmentService.region.pipe(
              take(1),
              switchMap(region => {
                const performTraceRequest: PerformTraceRequest = {
                  action: 'PerformTrace',
                  environment: env.id,
                  region: region.id,
                  payload: payload,
                  requestIdentifier: request.productIdentifier,
                  product: {
                    service: 'publish',
                    sourceSystem: request.sourceSystem,
                    businessUnit: request.businessUnit,
                    payloadType: request.payloadType,
                    targetSystem: request.targetSystem,
                  },
                  // use stormRequest field to determine if it's an internal request or not
                  stormRequest: 'true',
                  service: 'publish',
                  testOnly: testMode,
                  forceEvents: forceEvents,
                }
                const url = '/tests/'
                return this.apiService.post<PerformTraceRequest, IngestProductPublishTraceResponse>(url, performTraceRequest, {
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': request.contentType,
                    source_system: request.sourceSystem,
                  },
                })
              }),
            )
          }
        }),
      )
  }

  private getRequestUrl(request: PublishPayloadRequest) {
    // /gendoc/{documentType}/{language}/{programme}/{productWrapper}/{productType}/{productIdentifier}/{productState}
    const url = '/publish/' +
      request.businessUnit + '/' +
      request.payloadType + '/' +
      request.targetSystem + '/' +
      request.productIdentifier + '/'
    return url
  }
}
