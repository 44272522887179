import {AnalyseFields, AnalyseRow, Deletion, RuleType} from '../common/domain/analyse'
import { Combination } from './combination.request'
import {RuleApprovals} from './rule-approvals'
import {UsageStage} from './usage-stage'

export class NormalisedRuleSet {

    constructor(filePath: string) {
        // e.g 'config/rulesets/ADYR.json'
        this.filePath = filePath
    }

    public mnemonic = ''
    public stage: UsageStage = 'solo'
    public ruleType: RuleType
    public entries = new Array<string>()

    public name: string = ''
    public fields: AnalyseFields = new Array<string>()
    public approvalRequiredRegEx = ''
    public approvalRequired = true
    public rule?: AnalyseRow[] = new Array<AnalyseRow>()
    public ruleApprovals = new Array<RuleApprovals>()
    public documentFormatType?: 'word' | 'pdf'
    public linkedRules?: {
        rulesets: string;
       combinations: Combination[];
    }

    // Optional (but save to the file system if populated)
    public description?: string
    public help?: string
    public test?: string
    public deletion?: Deletion

    public lastUpdated = '' // UTC Timestamp

    // Added on class Instantiation, delete before saving
    public filePath?: string
}