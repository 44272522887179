import {Component, Inject} from '@angular/core'
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'

export interface DeleteAnalysisRuleDetails {
  title: string
  comment?: string
}

@Component({
  templateUrl: 'analysis-delete-rule-dialog.component.html',
  styleUrls: ['./analysis-delete-rule-dialog.component.scss'],
})
export class AnalysisDeleteRuleDialogComponent {

  constructor(public dialogRef: MatDialogRef<AnalysisDeleteRuleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeleteAnalysisRuleDetails) {
  }

  cancel() {
    this.dialogRef.close(undefined)
  }
}
