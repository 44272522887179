<app-config-workspace-buttons></app-config-workspace-buttons>

<h2>Config Mapping</h2>

<button mat-raised-button color="accent" class="me-2" (click)="downloadTemplateMapping()">Download CSV</button>
<ng-container *ngIf="canCreateConfig">
  <button mat-raised-button color="warn" (click)="createConfig()">Create New Config</button>
  Create a bare configuration file for a new document type
</ng-container>

<table class="table table-borderless">
  <thead>
    <tr>
      <th>Source</th>
      <th>Programme</th>
      <th>Product</th>
      <th>Document</th>
      <th>Supported</th>
      <th>CS Schema</th>
      <th>Transform (Step 1)</th>
      <th>Transform (Step 2)</th>
      <th>Enrich (Step 1)</th>
      <th>Enrich (Step 2)</th>
      <th>Solo Schema</th>
      <th>Project</th>
      <th>PDF Selector</th>
      <th>Word Selector</th>
      <th>Render Service</th>
      <th>Max Page Size</th>
      <th>Chart rendering</th>
      <th></th>
    </tr>
  </thead>
  <ng-container *ngFor="let item of this.templateMappings; trackBy:identify">
    <tr *ngIf="!isItemDeleted(item)">
      <td>{{item.sourceSystem}}</td>
      <td>{{item.programme}}</td>
      <td>{{item.productType}}</td>
      <td>{{item.documentType}}</td>
      <td>
        <mat-select [(value)]="item.supported" (valueChange)="updateItem(item)" [disabled]="!canEditSupportedStatus">
          <mat-option *ngFor="let mode of supportedConfigMode" [value]="mode">{{mode}}</mat-option>
        </mat-select>
      </td>
      <td>
        <mat-select class="schema" [(value)]="item.sparrowSchema" (valueChange)="updateItem(item)"
          [disabled]="!canEditMappings">
          <mat-option *ngFor="let mode of schemas" [value]="mode">{{mode}}</mat-option>
        </mat-select>
      </td>
      <td>
        <mat-select class="transform" [(value)]="item.transform1" (valueChange)="updateItem(item)"
                    [disabled]="!canEditMappings">
          <mat-option *ngFor="let mode of transforms" [value]="mode">{{mode}}</mat-option>
        </mat-select>
      </td>
      <td>
        <mat-select class="transform" [(value)]="item.transform2" (valueChange)="updateItem(item)"
                    [disabled]="!canEditMappings">
          <mat-option *ngFor="let mode of transforms" [value]="mode">{{mode}}</mat-option>
        </mat-select>
      </td>
      <td>
        <mat-select class="transform" [(value)]="item.enrich1" (valueChange)="updateItem(item)"
          [disabled]="!canEditMappings">
          <mat-option *ngFor="let mode of transforms" [value]="mode">{{mode}}</mat-option>
        </mat-select>
      </td>
      <td>
        <mat-select class="transform" [(value)]="item.enrich2" (valueChange)="updateItem(item)"
          [disabled]="!canEditMappings">
          <mat-option *ngFor="let mode of transforms" [value]="mode">{{mode}}</mat-option>
        </mat-select>
      </td>
      <td>
        <mat-select class="schema" [(value)]="item.soloSchema" (valueChange)="updateItem(item)"
          [disabled]="!canEditMappings">
          <mat-option *ngFor="let mode of schemas" [value]="mode">{{mode}}</mat-option>
        </mat-select>
      </td>
      <td>
        <input class="number" matInput type="text" (blur)="updateItem(item)" [(ngModel)]="item.projectId"
          [readonly]="!canEditTemplates">
      </td>
      <td>
        <input class="number" matInput type="text" (blur)="updateItem(item)" [(ngModel)]="item.templateSelectorId"
              [readonly]="!canEditTemplates">
      </td>
      <td>
        <input class="number" matInput type="text" (blur)="updateItem(item)" [(ngModel)]="item.wordTemplateSelectorId"
              [readonly]="!canEditTemplates">
      </td>
      <td>
        <mat-select class="render-service" [(value)]="item.renderService" (valueChange)="updateItem(item)"
                    matTooltip="Select render service. Uses SmartDX if nothing is selected"
                    [disabled]="!canEditMappings">
          <mat-option *ngFor="let mode of supportedRenderServices" [value]="mode">{{mode}}</mat-option>
        </mat-select>
      </td>
      <td>
        <input class="number" matInput type="number" min="0" (blur)="updateItem(item)" [(ngModel)]="item.maxPageSize"
          [readonly]="!canEditTemplates">
      </td>
      <td><button mat-raised-button color="accent" (click)="editChartConfig(item)">Edit ({{ hasChartEnabled(item) ? "Enabled" : "Disabled" }})</button></td>
      <td>
        <button mat-raised-button color="accent" (click)="restore(item)" [disabled]="item.state === 'original'">Restore
        </button>
      </td>
      <td>
        <button mat-raised-button color="warn" (click)="deleteItem(item)">Delete</button>
      </td>
    </tr>
  </ng-container>
</table>
