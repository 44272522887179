import { Component, OnInit } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnrichmentResponse, EnrichmentRule } from '../utils/models/md-enrichment-rule-model';
import { ManualDraftingAdminEnrichmentRuleService } from '../utils/services/manual-drafting-enrichment-rules.service';

@Component({
  selector: 'app-administer-erichment-rules',
  templateUrl: './administer-enrichment-rules.component.html',
  styleUrls: ['./administer-enrichment-rules.component.scss'],
})
export class AdministerEnrichmentRulesComponent implements OnInit {
  allAdministerEnrichmentRule: EnrichmentRule[] = []
  displayAdminEnrichmentRules: EnrichmentRule[] = []
  deletedRule: EnrichmentRule[] = [];
  editRule: EnrichmentRule[] = []
  showDeleted = false;
  showAll = false
  orderByASC = true;
  sortAlpha = false;
  NewOrEditRule = ''


  constructor(
    private modalService: NgbModal,
    private administerEnrichmentRule: ManualDraftingAdminEnrichmentRuleService
  ) {

  }

  async ngOnInit() {
    this.administerEnrichmentRule.getAllEnrichmentRule().then(res => {
      const administerEnrichmentRules = res as any as EnrichmentRule[]
      administerEnrichmentRules['body'].map((rule: EnrichmentRule) => {
        rule.date_updated = rule.date_updated.replace('T', ' ');
        rule.date_updated = rule.date_updated.replace('.000Z', '');
        if (!rule.deleted) {
          this.allAdministerEnrichmentRule.push(rule);

        } else {
          this.deletedRule.push(rule)
        }
      })
      this.displayAdminEnrichmentRules = this.allAdministerEnrichmentRule
      this.keepUserFilter()


    })
  }

  openModalDialog(content, action: string, rule?: EnrichmentRule) {
    this.NewOrEditRule = 'Create New Rule'
    if (this.editRule.length) {
      this.editRule.length = 0;

    }
    if (action === 'Edit') {
      this.editRule.push(rule)
      this.NewOrEditRule = `Edit Rule ${rule.id}`
    }
    this.modalService.open(content, {
      windowClass: 'auto-transform-modal',
      scrollable: true,
      size: 'xl',
      centered: true,
      backdrop: 'static',
    }).result.then(res => {
      if (res === 'PASSED') {
        this.resetArray();
        this.ngOnInit();
      }
    })
  }

  displayRules(event: MouseEvent): void {
    const filteredRule = (event.target as Element).id.toUpperCase();
    if (filteredRule === 'DELETED') {
      this.showDeleted = !this.showDeleted
    } else if (filteredRule === 'ALL') {
      this.showAll = !this.showAll;
    } else {
      throw new Error(`Unexpected value for list: ${filteredRule}`);
    }
    this.filterData(filteredRule);
  }

  async softDeleteRule(id: string) {
    const softDelete = await this.administerEnrichmentRule.softDeleteRule(id)
    if (softDelete) {
      this.resetArray()
      this.ngOnInit()
    }
  }

  async restoreRule(id: string) {
    const restoreData = await this.administerEnrichmentRule.restoreRule(id)
    if (restoreData) {
      this.resetArray()
      this.ngOnInit()
    }
  }

  filterData(status: string) {
    status === 'DELETED' && this.showDeleted
      ? (this.displayAdminEnrichmentRules = [
        ...this.deletedRule,
      ])
      : status === 'ALL' && this.showAll ? (this.displayAdminEnrichmentRules = [
        ...this.deletedRule,
        ...this.allAdministerEnrichmentRule
      ]) : this.displayAdminEnrichmentRules = [...this.allAdministerEnrichmentRule]

    if (status === 'DELETED' && this.showAll) {
      this.showAll = false
    }
    if (status === 'ALL' && this.showDeleted) {
      this.showDeleted = false
    }
  }

  keepUserFilter() {
    if (this.showDeleted) {
      this.filterData('DELETED')
    }
    if (this.showAll) {
      this.filterData('ALL')
    }
  }

  orderByAscDec() {
    this.orderByASC = !this.orderByASC;

    if (this.orderByASC) {
      this.displayAdminEnrichmentRules.sort(
        (a, b) => a.date_updated < b.date_updated ? -1 : 1
      );
    } else {
      this.displayAdminEnrichmentRules.sort(
        (a, b) => a.date_updated < b.date_updated ? 1 : -1
      );
    }
  }

  sortAlphabetically() {
    this.sortAlpha = !this.sortAlpha;

    if (this.sortAlpha) {
      this.displayAdminEnrichmentRules.sort((a, b) =>
        a.description.localeCompare(b.description)
      );
    } else {
      return this.displayAdminEnrichmentRules.sort().reverse();
    }
  }

  resetArray() {
    if (this.allAdministerEnrichmentRule.length) {
      this.allAdministerEnrichmentRule.splice(0);
    }
    if (this.deletedRule.length) {
      this.deletedRule.splice(0);
    }
    if (this.displayAdminEnrichmentRules.length) {
      this.displayAdminEnrichmentRules.splice(0);
    }
  }
}
