import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {TestSuiteDefinition} from '../common/domain/tests'

export interface SaveTestDialogData {
  title: string
  hint?: string
  comment: string
  name: string
  isNew?: boolean
  suite?: string
}

@Component({
  selector: 'app-save-test-dialog',
  templateUrl: 'save-test-dialog.component.html',
  styleUrls: ['./save-test-dialog.component.scss'],
})
export class SaveTestDialogComponent {
  hint: string

  constructor(
    public dialogRef: MatDialogRef<SaveTestDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: SaveTestDialogData,
  ) {
    this.hint = data.hint ? data.hint : 'Used as key in S3'
  }

  cancel() {
    this.dialogRef.close(undefined)
  }

  setTestSuite(testSuiteDefinition?: TestSuiteDefinition) {
    this.data.suite = testSuiteDefinition?.name
  }
}
