<span (contextmenu)="this.actionRightClick($event, undefined)">

  <app-config-workspace-buttons></app-config-workspace-buttons>

  <h2>Maintain RuleSets</h2>

  <div class='mb-3 mt-n2'>
    <button class='btn btn-sm rounded-pill me-2' id='product'
      [ngClass]="this.chipProductSelected ? 'btn-primary' : 'btn-secondary'"
      (click)="toggleChip($event)">Product</button>
    <button class='btn btn-sm rounded-pill me-2' id='solo'
      [ngClass]="this.chipSoloSelected ? 'btn-primary' : 'btn-secondary'" (click)="toggleChip($event)">Solo</button>
    <button class='btn btn-sm rounded-pill me-2' id='notSupported'
      [ngClass]="this.chipNotSupportedSelected ? 'btn-primary' : 'btn-secondary'"
      (click)="toggleChip($event)">Not-Supported List</button>
    <button class='btn btn-sm rounded-pill me-2' id='validation'
      [ngClass]="this.chipValidationSelected ? 'btn-primary' : 'btn-secondary'" (click)="toggleChip($event)">Validation
      List</button>
    <button class='btn btn-sm rounded-pill me-2' id='blacklist'
      [ngClass]="this.chipBlacklistSelected ? 'btn-primary' : 'btn-secondary'"
      (click)="toggleChip($event)">Blacklist</button>
    <button class='btn btn-sm rounded-pill me-2' id='whitelist'
      [ngClass]="this.chipWhitelistSelected ? 'btn-primary' : 'btn-secondary'"
      (click)="toggleChip($event)">Whitelist</button>
  </div>

  <div class='square-buttons-div'>
    <button type="button" class="btn btn-success me-2" (click)="actionAddNewRuleset()" *ngIf="this.canAddRule">Add New
      Ruleset</button>
    <button type="button" class="btn btn-info me-2" (click)="this.actionDownloadRules('currentFilter')" title="Download all rules">
      All Rules for Current Filter (CSV) </button>
    <button type="button" class="btn btn-info me-2" (click)="this.actionDownloadRules('allNewFormat')"
      title="Download all rules (New Format)">All Rules (New Format)
    </button>
    <button type="button" class="btn btn-info me-2" (click)="this.actionDownloadErrorMessages()"
      title="Download all messages">All Errors (CSV) </button>
    <button type="button" class="btn btn-info me-2" (click)="this.actionDownloadIgnoredMnemonicsAsCsv()" title="Download all ignored rules">
      All Ignored Rules (CSV) </button>
    <button type="button" class="btn mr-2" [ngClass]="this.showDeletedRuleSets ? 'btn-warning' : 'btn-info'"
            (click)="this.showDeletedRulesets()">Deleted Rulesets</button>
  </div>

  <div *ngIf="!showDeletedRuleSets" class="tableFixHead scroll-inherit-important" [class.loading]="loading">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">RuleSet Mnemonic</th>
          <th scope="col">Name</th>
          <th scope="col"></th>
          <th scope="col">Type</th>
          <th scope="col">&nbsp;</th>
          <th scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let curRuleSet of this.ruleSetsToDisplay' [class.text-muted]="!curRuleSet.rule" class='hover-highlight'
        data-toggle="tooltip" data-placement="bottom" title="{{curRuleSet.entries.join('&#10;')}}"
          (contextmenu)="actionRightClick($event, curRuleSet.mnemonic)"
          (click)='curRuleSet.rule && this.actionViewRuleSetDialog($event, "edit", curRuleSet.mnemonic);'>
          <td scope="row">{{ curRuleSet.mnemonic }}</td>
          <td>{{ curRuleSet.name }}</td>
          <td>
            <span class="badge badge-info" *ngIf="this.newRuleSetMnemonics.includes(curRuleSet.mnemonic)">New</span>
            <span class="badge badge-info" *ngIf="this.modifiedRuleSetMnemonics.includes(curRuleSet.mnemonic)">Modified</span>
          </td>
          <td>{{ curRuleSet.stage + ' ' + curRuleSet.ruleType }}</td>
          <td><button *ngIf="curRuleSet.rule" class="btn btn-sm btn-info"
              (click)="this.actionDownloadRuleSetAsCsv($event, curRuleSet.mnemonic)">CSV</button></td>
          <td><button class="btn btn-sm btn-info"
                      (click)="this.showAuditHistory($event, curRuleSet.mnemonic)">Audit History</button></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="showDeletedRuleSets" class="tableFixHead scroll-inherit-important">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">RuleSet Mnemonic</th>
          <th scope="col">Name</th>
          <th scope="col"></th>
          <th scope="col">Type</th>
          <th scope="col">&nbsp;</th>
          <th scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let curRuleSet of this.deletedRuleSetsToDisplay' [class.text-muted]="!curRuleSet.rule" class='hover-highlight'
            data-toggle="tooltip" data-placement="bottom" title="{{curRuleSet.entries.join('&#10;')}}"
            (click)='curRuleSet.rule && this.actionViewRuleSetDialog($event, "view", curRuleSet.mnemonic, true)'>
          <td scope="row">{{ curRuleSet.mnemonic }}</td>
          <td>{{ curRuleSet.name }}</td>
          <td>
            <span class="badge badge-info" *ngIf="this.newRuleSetMnemonics.includes(curRuleSet.mnemonic)">New</span>
            <span class="badge badge-info" *ngIf="this.modifiedRuleSetMnemonics.includes(curRuleSet.mnemonic)">Modified</span>
          </td>
          <td>{{ curRuleSet.stage + ' ' + curRuleSet.ruleType }}</td>
          <td><button class="btn btn-sm btn-info"
                      (click)="this.showAuditHistory($event, curRuleSet.mnemonic)">Audit History</button></td>
        </tr>
      </tbody>
    </table>
  </div>

</span>

<!-- Right Click Context Menu -->
<!-- <div *ngIf='this.rightClickedRuleSetMnemonic !== undefined' [ngStyle]="locationCss"
  class="dropdown-menu dropdown-menu-sm basic-box-shadow hide-scroll" id="context-menu"
  (contextmenu)='this.showRuleSetContextMenu = false'>
  <a class="dropdown-item" id='ViewRuleSet' (click)='this.actionViewRuleSetDialog($event, "view")' href="#">View RuleSet {{
    this.rightClickedRuleSetMnemonic }}</a>
  <a class="dropdown-item" id='EditRuleSet' (click)='this.actionViewRuleSetDialog($event, "edit")' href="#">Edit RuleSet {{
    this.rightClickedRuleSetMnemonic }}</a> -->
  <!-- <a class="dropdown-item" href="#">Delete RuleSet {{ currentlySelectedRuleSet.mnemonic }}</a>
  <a class="dropdown-item" href="#">Rename RuleSet {{ currentlySelectedRuleSet.mnemonic }}</a> -->
<!-- </div> -->
