<ng-template #itemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand" let-onCheckedChange="onCheckedChange">
  <div class="form-inline row-item">
    <ng-container *ngIf="item.children">
      <fa-icon [icon]="item.collapsed ? 'caret-right' : 'caret-down'" (click)="onCollapseExpand()"></fa-icon>
    </ng-container>
    <div class="form-check">
      <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()" [disabled]="item.disabled"
             [indeterminate]="item.indeterminate" />
      <label class="form-check-label" (click)="item.checked = !item.checked; onCheckedChange()">
        {{item.text}}
      </label>
    </div>
  </div>
</ng-template>

<div class="row">
  <div class="col-12">
    <ngx-treeview [config]="config" [items]="items" [itemTemplate]="itemTemplate"  (selectedChange)="selectedConfigs.next($event)">
    </ngx-treeview>
  </div>
</div>
