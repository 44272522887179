import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http'
import {distinctUntilChanged, map, shareReplay, switchMap, take} from 'rxjs/operators'
import {TokenService} from './token.service'
import { combineLatest, Observable } from 'rxjs'
import {OPS_URL, valueReplaced} from '../constants'

const deepEqual = require('deep-equal')

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  hostname = window.location.hostname.includes('localhost') ? valueReplaced(OPS_URL) ? OPS_URL : 'ops.cs.inspire.direct' : window.location.hostname
  apiUrl = 'https://api.' + this.hostname + '/v1'

  constructor(
    private httpClient: HttpClient,
    private tokenService: TokenService) {
    console.info(`Using API: ${this.apiUrl}`)
  }

  headers(headers: { [p: string]: string } = {}) {
    return combineLatest([
        this.tokenService.oauthToken,
        this.tokenService.idToken,
      ],
    ).pipe(
      distinctUntilChanged(deepEqual),
      take(1),
      map(([oauth, id]) => {
        if (id && oauth) {
          return new HttpHeaders(
            Object.assign(
              {},
              headers,
              {
                Authorization: 'Bearer ' + oauth,
                ID_TOKEN: id,
              },
            ),
          )
        } else {
          return new HttpHeaders(
            Object.assign(
              {},
              headers,
            ),
          )
        }
      }),
      shareReplay(1),
    )
  }

  postBlob<R>(path: string, payload: R, options: {
    params?: { [key: string]: string },
    headers: { [key: string]: string }
  } = {headers: {}}): Observable<Blob> {
    const url = this.apiUrl + path
    return this.headers(options.headers)
      .pipe(
        switchMap(headers => {
          return this.httpClient.post(url, payload, {
            responseType: 'blob',
            params: options.params,
            headers: headers,
          }).pipe(
            map(response => {
              return response as Blob
            }),
          )
        }),
      )
  }

  postText<R>(path: string, payload: R, options: {
    params?: { [key: string]: string },
    headers: { [key: string]: string }
  } = {headers: {}}): Observable<string> {
    const url = this.apiUrl + path
    return this.headers(options.headers)
      .pipe(
        switchMap(headers => {
          return this.httpClient.post(url, payload, {
            responseType: 'text',
            params: options.params,
            headers: headers,
          }).pipe(
            map(response => {
              return response as string
            }),
          )
        }),
      )
  }

  post<R, T>(path: string, payload: R, options: {
    params?: { [key: string]: string },
    headers: { [key: string]: string }
  } = {headers: {}}): Observable<T> {
    const url = this.apiUrl + path
    return this.headers(options.headers)
      .pipe(
        switchMap(headers => {
          return this.httpClient.post<T>(url, payload, {
            params: options.params,
            headers: headers,
          })
        }),
      )
  }

  get<T>(path: string, params: { headers: { [key: string]: string }, apiUrlOverride?: string } = {headers: {}}): Observable<T> {
    const url = (params.apiUrlOverride ?? this.apiUrl) + path
    return this.headers(params.headers)
      .pipe(
        switchMap(headers => {
          return this.httpClient.get<T>(url, {
              headers: headers,
            },
          )
        }),
      )
  }

  delete<T>(path: string, params: { headers: { [key: string]: string } } = {headers: {}}): Observable<T> {
    const url = this.apiUrl + path
    return this.headers(params.headers)
      .pipe(
        switchMap(headers => {
          return this.httpClient.delete<T>(url, {
              headers: headers,
            },
          )
        }),
      )
  }

  handle(req: HttpRequest<any>) {
    return this.headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    })
      .pipe(
        switchMap(headers => {
          const r = req.clone({headers: headers})
          console.log('MAKING LOG REQUEST!!!')
          console.dir(r)
          return this.httpClient.request(r)
        }),
      )
  }
}
