import {Component, OnInit} from '@angular/core'

@Component({
  selector: 'app-docgen-config-approval',
  templateUrl: './docgen-config-approval.component.html',
  styleUrls: ['./docgen-config-approval.component.scss']
})
export class DocgenConfigApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
