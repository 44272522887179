<app-config-workspace-buttons></app-config-workspace-buttons>

<h2>Target System Mapping</h2>

<button mat-raised-button color="accent" (click)="downloadTemplateMapping()">Download CSV</button>

<mat-tab-group>
  <mat-tab label="Targets">
    <table class="table table-borderless">
      <thead>
        <tr>
          <th>Source</th>
          <th>Business Unit</th>
          <th>Payload</th>
          <th>Target System</th>
          <th>Supported</th>
          <th>Source Schema</th>
          <th>Transform</th>
          <th>Enrich</th>
          <th>Combine</th>
          <th>Encapsulate</th>
          <th>Format Transformation</th>
          <th>Target Schema</th>
          <th>Target</th>
          <th>Retrieve Schema</th>
          <th>Submit Schema</th>
          <th>Target Params</th>
          <th>2nd Target</th>
          <th></th>
        </tr>
      </thead>
      <tr *ngFor="let item of nonMultiTrigger(mappings)"
        [ngClass]="isReferencedByConfig(item) ? 'referenced' : isAvailableToConfig(item) ? '' : 'unreferenced'">
        <td>{{item.sourceSystem}}</td>
        <td>{{item.businessUnit}}</td>
        <td>{{item.payloadType}}</td>
        <td>{{item.targetSystem}}</td>
        <td>
          <mat-select [(value)]="item.supported" (valueChange)="updateItem(item)" [disabled]="!canEditSupportedStatus">
            <mat-option *ngFor="let mode of supportedConfigMode" [value]="mode">{{mode}}</mat-option>
          </mat-select>
        </td>
        <td>
          <mat-select class="schema" [(value)]="item.sparrowSchema" (valueChange)="updateItem(item)"
            [disabled]="!canEditMappings">
            <mat-option *ngFor="let mode of schemas" [value]="mode">{{mode}}</mat-option>
          </mat-select>
        </td>
        <td>
          <mat-select class="transform" [(value)]="item.transform" (valueChange)="updateItem(item)"
            [disabled]="!canEditMappings">
            <mat-option *ngFor="let mode of transforms" [value]="mode">{{mode}}</mat-option>
          </mat-select>
        </td>
        <td>
          <mat-select class="transform" [(value)]="item.enrich" (valueChange)="updateItem(item)"
            [disabled]="!canEditMappings">
            <mat-option *ngFor="let mode of transforms" [value]="mode">{{mode}}</mat-option>
          </mat-select>
        </td>
        <td>
          <mat-select class="transform" [(value)]="item.combine" (valueChange)="updateItem(item)"
            [disabled]="!canEditMappings">
            <mat-option *ngFor="let mode of transforms" [value]="mode">{{mode}}</mat-option>
          </mat-select>
        </td>
        <td>
          <mat-select class="transform" [(value)]="item.encapsulate" (valueChange)="updateItem(item)"
            [disabled]="!canEditMappings">
            <mat-option *ngFor="let mode of transforms" [value]="mode">{{mode}}</mat-option>
          </mat-select>
        </td>
        <td>
          <mat-select class="transform" [(value)]="item.formatTransformation" (valueChange)="updateItem(item)"
            [disabled]="!canEditMappings">
            <mat-option *ngFor="let format of supportedformatTransformations" [value]="format">{{format}}</mat-option>
          </mat-select>
        </td>
        <td>
          <mat-select class="schema" [(value)]="item.soloSchema" (valueChange)="updateItem(item)"
            [disabled]="!canEditMappings">
            <mat-option *ngFor="let mode of schemas" [value]="mode">{{mode}}</mat-option>
          </mat-select>
        </td>
        <td>
          <mat-select class="target" [(value)]="item.target" (valueChange)="updateItem(item)"
            [disabled]="!canEditTargetSystem">
            <mat-option *ngFor="let target of supportedTargets" [value]="target.value">{{target.display}}</mat-option>
          </mat-select>
        </td>
        <td>
          <input class="params" matInput type="text" (blur)="updateItem(item)"
            [(ngModel)]="item.regxchangeRetrieveSchema" [readonly]="!canEditTargetSystem">
        </td>
        <td>
          <input class="params" matInput type="text" (blur)="updateItem(item)" [(ngModel)]="item.regxchangeSubmitSchema"
            [readonly]="!canEditTargetSystem">
        </td>
        <td>
          <input class="params" matInput type="text" (blur)="updateItem(item)" [(ngModel)]="item.targetParams"
            [readonly]="!canEditTargetSystem">
        </td>
        <td>
          <mat-select class="target" [(value)]="item.secondTarget" (valueChange)="updateItem(item)"
            [disabled]="!canEditTargetSystem">
            <mat-option *ngFor="let target of supportedTargets" [value]="target.value">{{target.display}}</mat-option>
          </mat-select>
        </td>
        <td>
          <button mat-raised-button color="accent" (click)="restore(item)"
            [disabled]="item.state === 'original'">Restore
          </button>
        </td>
      </tr>
    </table>
  </mat-tab>
  <mat-tab label="Multi Targets">
    <table mat-table multiTemplateDataRows class="table table-borderless" [dataSource]="multiTriggerMappings">
      <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay; index as i;">
        <th *matHeaderCellDef> {{columnsNames[i]}} </th>
        <td mat-cell *matCellDef="let element"> {{element[column]}} </td>
      </ng-container>

      <ng-container matColumnDef="supported">
        <th *matHeaderCellDef>Supported</th>
        <td mat-cell *matCellDef="let element">
          <mat-select [(value)]="element.supported" (valueChange)="updateItem(element)"
            [disabled]="!canEditSupportedStatus">
            <mat-option *ngFor="let mode of supportedConfigMode" [value]="mode">{{mode}}</mat-option>
          </mat-select>
        </td>
      </ng-container>

      <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button aria-label="expand row"
            (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation();">
            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
          <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
            <div class="child-mappings-container">
              <ng-container *ngIf="element.childKeys.length == 0">
                <p>No child targets configured yet</p>
              </ng-container>
              <ng-container *ngIf="element.childKeys.length != 0">
                <p>Child targets</p>
              </ng-container>
              <ng-container *ngFor="let child of element.childKeys;index as i">
                <div class="child-mappings-form">
                  <input type="text" id="childSourceSystem" placeholder="Source System" (change)="updateItem(element)"
                    [(ngModel)]="child.sourceSystem" />
                  <input type="text" id="childSourceSystem" placeholder="Business Unit" (change)="updateItem(element)"
                    [(ngModel)]="child.businessUnit" />
                  <input type="text" id="childSourceSystem" placeholder="Payload Type" (change)="updateItem(element)"
                    [(ngModel)]="child.payloadType" />
                  <input type="text" id="childSourceSystem" placeholder="Target System" (change)="updateItem(element)"
                    [(ngModel)]="child.targetSystem" />
                  <mat-slide-toggle (change)="updateItem(element)" [(ngModel)]="child.includeInResponse">Include in
                    response</mat-slide-toggle>
                  <button mat-raised-button color="accent" (click)="deleteChildTarget(element, i)">Delete</button>
                </div>
              </ng-container>
              <button mat-raised-button color="accent" (click)="newChildTarget(element)">New child target</button>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
      <tr mat-row *matRowDef="let element; columns: columnsToDisplayWithExpand;" class="example-element-row"
        [class.example-expanded-row]="expandedElement === element"
        (click)="expandedElement = expandedElement === element ? null : element">
      </tr>
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
    </table>
  </mat-tab>
</mat-tab-group>