import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {TestManagerService} from '../services/test-manager.service'
import {StatusService} from '../services/status.service'
import {
  GetTestSuiteResponse,
  MigrateBatchResponse,
  MigrateTestResponse,
  MigrateTestSuiteResponse,
} from '../model/test-management-responses'
import {ConfirmDialogComponent} from '../dialogs/confirm-dialog.component'
import * as JSZip from 'jszip'
import {saveAs} from 'file-saver'
import {BehaviorSubject, Subscription} from 'rxjs'
import {ConfigDetail} from '../common/domain/config'
import {MatDialog} from '@angular/material/dialog'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {ToastrService} from 'ngx-toastr'
import {jsonViewerOptions, xmlEditorOptions} from '../util/editor-configurations'

const MIGRATE_BATCH_SIZE = 250

@Component({
  selector: 'app-test-migrator',
  templateUrl: './test-migrator.component.html',
  styleUrls: ['./test-migrator.component.scss'],
})
export class TestMigratorComponent implements OnInit, OnDestroy {
  @ViewChild('sourceSchemaEditor') sourceSchemaEditor;
  subs = new Subscription()

  selectedTestSuite: GetTestSuiteResponse | undefined
  testMode = true
  error = undefined
  toggleBehavior = new BehaviorSubject<boolean>(true)

  sourceSchema: string
  targetSchema: string
  transform: string
  results: string
  testResponses: MigrateTestResponse[] = []

  displayedColumns = ['name', 'validated', 'migrated', 'errors']

  xmlEditorConfig = xmlEditorOptions
  jsonReadonlyConfig = jsonViewerOptions

  constructor(
    private testManager: TestManagerService,
    private statusService: StatusService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private toast: ToastrService,
  ) {
  }

  ngOnInit() {
    this.testManager.setService('docgen')
    this.reset()
    this.testMode = this.toggleBehavior.getValue()
    console.log('Test mode is now ' + this.testMode)
    this.toggleBehavior.subscribe(testMode => {
      this.testMode = testMode
      console.log('Test mode is now ' + this.testMode)
    })
    this.subs.add(
      this.testManager.selectedTestSuite.subscribe(result => {
        this.reset()
        return this.selectedTestSuite = result
      }),
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  setSchema(config: ConfigDetail) {
    this.reset()
    this.sourceSchema = config.content
  }

  setTargetSchema(config: ConfigDetail) {
    this.reset()
    this.targetSchema = config.content
  }

  setTransform(config: ConfigDetail) {
    this.reset()
    this.transform = config.content
  }

  toggleTestMode(value: boolean) {
    this.reset()
    this.toggleBehavior.next(value)
    console.log('Toggling test mode to ' + value)
  }

  private reset() {
    this.results = undefined
    this.testResponses = []
    this.error = undefined
  }

  async migrate() {
    this.reset()
    if (this.selectedTestSuite) {
      const testSuite = this.selectedTestSuite.name
      if (!this.testMode) {
        const dialogRef = this.modalService.open(ConfirmDialogComponent, {
          size: 'lg',
          backdrop: false,
        })
        dialogRef.componentInstance.title = 'Please confirm'
        dialogRef.componentInstance.message = 'Are you sure you want to migrate ' + testSuite

        dialogRef.result.then(confirm => {
          this.reallyMigrateTestSuite()
            .then(res => {
              this.toast.success('Successfully migrated ' + testSuite)
            })
            .catch(err => {
              this.toast.error('Failed to migrate ' + testSuite)
            })
        }).catch(err => {
          this.toast.info('Cancelled', undefined, {timeOut: 1500})
        })
      } else {
        this.reallyMigrateTestSuite()
          .then(res => {
            this.toast.success('Successfully migrated ' + testSuite)
          })
          .catch(err => {
            this.toast.error('Failed to migrate ' + testSuite)
          })
      }
    }
  }

  async migrateBatch() {
    this.reset()
    if (!this.selectedTestSuite) {
      if (!this.testMode) {
        const dialogRef = this.modalService.open(ConfirmDialogComponent, {
          size: 'lg',
          backdrop: false,
        })
        dialogRef.componentInstance.title = 'Please confirm'
        dialogRef.componentInstance.message = 'Are you sure you want to migrate a batch of tests'

        dialogRef.result.then(confirm => {
          this.reallyMigrateBatch()
            .then(res => {
              this.toast.success('Successfully migrated  a batch of tests')
            })
            .catch(err => {
              this.toast.error('Failed to migrate a batch of tests')
            })
        }).catch(err => {
          this.toast.info('Cancelled', undefined, {timeOut: 1500})
        })
      } else {
        this.reallyMigrateBatch()
          .then(res => {
            this.toast.success('Successfully migrated  a batch of tests')
          })
          .catch(err => {
            this.toast.error('Failed to migrate  a batch of tests')
          })
      }
    }
  }

  private async reallyMigrateTestSuite() {
    const results: MigrateTestSuiteResponse = await this.testManager.migrate(
      this.selectedTestSuite.name,
      this.sourceSchema,
      this.transform,
      this.targetSchema,
      this.testMode) as MigrateTestSuiteResponse
    this.displayResults(results)
  }

  private async reallyMigrateBatch() {
    const results: MigrateBatchResponse = await this.testManager.migrateBatch(
      this.sourceSchema,
      this.transform,
      this.targetSchema,
      this.testMode,
      MIGRATE_BATCH_SIZE,
    ) as MigrateBatchResponse
    this.displayResults(results)
  }

  private displayResults(results: MigrateTestSuiteResponse | MigrateBatchResponse) {
    console.dir(results)
    this.results = JSON.stringify(results, undefined, 2)
    if (results.testResponses) {
      this.testResponses = results.testResponses
      this.error = this.testResponses.some(r => r.error || r.errors?.length > 0 ) ? 'There were errors during the migration' : undefined
    } else {
      this.error = 'There were no results from the migration'
    }
  }

  async downloadMigrationPack() {
    if (this.testResponses && this.testResponses.length > 0) {
      const zipId = this.statusService.start('Preparing Zip File')
      const jszip: JSZip = new JSZip()
      for (const result of this.testResponses) {
        jszip.file(result.name + '-migrated.xml', result.migrated)
      }

      await jszip.generateAsync({type: 'blob'}).then(zip => {
        const base = this.selectedTestSuite && this.selectedTestSuite.name || 'batch'
        saveAs(zip, base + '-migrated.zip')
      })
      this.statusService.complete(zipId)
    }
  }

  resizecomponents() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 200);
  }

  shown(e: Event) {
    console.log('shown')
    console.dir(e);
    this.resizecomponents()
  }

}
