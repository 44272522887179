import {Component, Input} from '@angular/core'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-rule-error-message-dialog',
  templateUrl: './rule-error-message-dialog.component.html',
  styleUrls: ['./rule-error-message-dialog.component.scss'],
})
export class RuleErrorMessageDialogComponent {
  @Input() ruleType: string
  @Input() ruleMnemonic: string
  @Input() rowMnemonic: string | undefined

  description: string
  help: string

  constructor(
    public activeModel: NgbActiveModal,
  ) {
  }

  cancel() {
    this.activeModel.dismiss()
  }

  confirm() {
    this.activeModel.close({
      ruleType: this.ruleType,
      ruleMnemonic: this.ruleMnemonic,
      rowMnemonic: this.rowMnemonic,
      description: this.description,
      help: this.help,
    })
  }
}
