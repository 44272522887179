<ng-container *ngIf="loading">Generating report</ng-container>

<div id="clientPdf" *ngIf="pdf">
  <ngx-extended-pdf-viewer [base64Src]="pdf"
                           [filenameForDownload]="pdfFilename"
                           useBrowserLocale="true"
                           delayFirstView="1000"
                           [showPresentationModeButton]="false"
                           [showOpenFileButton]="false"
                           [showPrintButton]="true"
                           [showBookmarkButton]="false"
                           [showRotateButton]="false"
                           minHeight="600px"
                           height="600px"></ngx-extended-pdf-viewer>
</div>
