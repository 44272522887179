import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {PermissionsService} from './permissions.service';
import {ToastrService} from 'ngx-toastr';
import {ApiSelector, RegxchangeGetRequest, RegxchangePostRequest} from '../model/test-management-requests';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {Environment, EnvironmentService} from './environment-service';

@Injectable({
  providedIn: 'root'
})
export class RegxchangeAdminService {

  private readOnly = true

  constructor(private apiService: ApiService,
              private permissionsService: PermissionsService,
              private toast: ToastrService,
              private environmentService: EnvironmentService,) {
    permissionsService.readonly.subscribe(permission => this.readOnly = permission)
    environmentService.sourceEnvironment
  }

  getPayloadFromRegxchange(globalIdentifier: string, schema: string, apiSelector: ApiSelector) {
    if (this.readOnly) {
      this.toast.warning('You do not have the permissions to perform this action')
      return
    }

    return this.environmentService.sourceEnvironment.pipe(
      take(1),
      switchMap((env: Environment) => {
        return this.environmentService.region.pipe(
          take(1),
          switchMap(region => {
            const regxchangeGetRequest: RegxchangeGetRequest = {
              action: 'regxchangeGetRequest',
              globalIdentifier: globalIdentifier,
              sourceEnvironment: env.id,
              region: region.id,
              schema: schema,
              apiSelector: apiSelector
            }

            const url = '/tests/'
            this.toast.info('Retrieving payload ' + regxchangeGetRequest.globalIdentifier + ' from regxchange in ' + env.id + ' environment')
            return this.apiService.post<RegxchangeGetRequest, any>(url, regxchangeGetRequest, {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
            }).pipe(
              tap(res => {
                console.dir(res)
              }),
              map(res => {
                const resObject = (typeof res === 'string') ? JSON.parse(res) : res
                return  resObject['body'] ? resObject['body'] : resObject
              }),
            )
          })
        )
      })
    )
  }

  postPayloadToRegxchange(payload: string, mimeType: string, schema: string, apiSelector: ApiSelector) {

    if (this.readOnly) {
      this.toast.warning('You do not have the permissions to perform this action')
      return
    }

    // even though this request targets an environment, using the source environment here because that includes prods
    return this.environmentService.sourceEnvironment.pipe(
      take(1),
      switchMap((env: Environment) => {
        return this.environmentService.region.pipe(
          take(1),
          switchMap(region => {
            const regxchangePostRequest: RegxchangePostRequest = {
              action: 'regxchangePostRequest',
              mimeType: mimeType,
              params: '',
              payload: payload,
              testOnly: false,
              targetEnvironment: env.id,
              region: region.id,
              schema: schema,
              apiSelector: apiSelector
            }

            const url = '/tests/'
            this.toast.info('Posting payload to regxchange in ' + env.id + ' environment')
            return this.apiService.post<RegxchangePostRequest, any>(url, regxchangePostRequest, {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
            }).pipe(
              tap(res => {
                console.dir(res)
              }),
              map(res => {
                const resObject = (typeof res === 'string') ? JSON.parse(res) : res
                return resObject['body'] ? resObject['body'] : resObject
              }),
            )
          })
        )
      })
    )
  }
}
