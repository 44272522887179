import {Component, OnDestroy, OnInit} from '@angular/core'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {ToastrService} from 'ngx-toastr'
import {combineLatest, Subscription} from 'rxjs'
import {DocgenConfigAndMetadataChangeDetails} from '../../common/domain/change'
import {Workspace} from '../../common/domain/workspaces'
import {ConfirmDialogComponent} from '../../dialogs/confirm-dialog.component'
import {PermissionsService} from '../../services/permissions.service'
import {WorkspaceService} from '../../services/workspace.service'

@Component({
  selector: 'app-delete-workspace',
  templateUrl: './delete-workspace.component.html',
  styleUrls: ['./delete-workspace.component.scss'],
})
export class DeleteWorkspaceComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  useWorkspaces = false
  workspace: Workspace
  configs: DocgenConfigAndMetadataChangeDetails[] = []
  canDelete = false

  constructor(
    private permissionsService: PermissionsService,
    private workspaceService: WorkspaceService,
    private toast: ToastrService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.sub.add(
      combineLatest([
        this.workspaceService.selectedWorkspace,
        this.permissionsService.workspaces])
        .subscribe(([workspace, useWorkspaces]) => {
          this.useWorkspaces = useWorkspaces
          this.workspace = workspace
          this.canDelete = workspace !== undefined
        }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  deleteWorkspace() {
    const confirmDialog = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })
    confirmDialog.componentInstance.title = 'Delete Workspace'
    confirmDialog.componentInstance.message = 'Are you sure you want to delete workspace: ' + this.workspace.name

    confirmDialog.result.then(confirmed => {
      this.workspaceService.deleteWorkspace(this.workspace.name)
        .toPromise()
        .then(res => {
          this.toast.success('Workspace deleted')
          this.workspaceService.selectWorkspace(undefined)
        })
        .catch(err => {
          this.toast.error('Failed to delete workspace')
        })
    }).catch(err => {
      this.toast.info('Cancelled', undefined, {timeOut: 1500})
    })
  }
}
