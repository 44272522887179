<div class="md-form form-inline form-group">
  <mat-form-field class="test-suite-selection">
    <mat-label>Test Suite Group</mat-label>
    <mat-select [(ngModel)]="selectedTestSuiteGroup" (selectionChange)="selectionChanged($event)" placeholder="Test Suite Group">
      <mat-option></mat-option>
      <mat-option *ngFor="let suite of testSuiteGroups" [value]="suite">{{suite.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  &nbsp;
  &nbsp;
  <button type="button" class="btn btn-secondary me-1" (click)=this.testSuiteGroupDialog(true)>
    <fa-icon icon="plus"></fa-icon>
  </button>
  <button type="button" class="btn btn-secondary me-1" [disabled]="!selectedTestSuiteGroup" (click)=this.testSuiteGroupDialog(false)>
    <fa-icon icon="edit"></fa-icon>
  </button>
  <button type="button" class="btn btn-danger me-1" [disabled]="!selectedTestSuiteGroup" (click)=this.deleteTestSuiteGroup()>
    <fa-icon icon="trash"></fa-icon>
  </button>
  <button type="button" class="btn btn-primary" [disabled]="!selectedTestSuiteGroup || this.testCount == 0" value="pdf" (click)="this.executeTestSuiteGroup('pdf')">
    Execute test suite group {{ selectedTestSuiteGroup && this.testCount > 0 ? '(' + this.testCount + ' tests)' : ''}}
  </button> &nbsp;
  <button type="button" class="btn btn-primary" [disabled]="!selectedTestSuiteGroup || this.testCount == 0" value="word" (click)="this.executeTestSuiteGroup('word')">
    Execute test suite group {{ selectedTestSuiteGroup && this.testCount > 0 ? '(' + this.testCount + ' tests)' : ''}}(word)
  </button>
  &nbsp;
  &nbsp;
</div>
