import {Component, OnInit} from '@angular/core';
import {DmExtractorFields, DynamicMapping} from '../../views/config-mappings-dynamic/config-mappings-dynamic.component';
import {MatDialogRef} from '@angular/material/dialog';
import {ChangeService} from '../../services/change.service';
import {WorkspaceAutoSaveService} from '../../services/workspace-auto-save.service';
import {UntypedFormControl} from '@angular/forms';
import {Subject} from "rxjs";

@Component({
  selector: 'app-create-dynamic-mappings-dialog',
  templateUrl: './create-dynamic-mappings-dialog.component.html',
  styleUrls: ['./create-dynamic-mappings-dialog.component.scss']
})
export class CreateDynamicMappingsDialogComponent implements OnInit {

  public canSave = false
  public newDynamicMapping: DynamicMapping
  myControl = new UntypedFormControl();
  payloadTypes: string[] = ['xml', 'json']
  private showAdditionalExtractorFieldsSubject = new Subject<boolean>()
  public addAdditionalExtractorFields = false
  public extractorFieldConditions: {value: string, newProductType: string}[] = []

  constructor(
    public dialogRef: MatDialogRef<CreateDynamicMappingsDialogComponent>,
    private changeService: ChangeService,
    protected workspaceAutoSaveService: WorkspaceAutoSaveService,
  ) {
    this.newDynamicMapping = {
      sourceSystem: undefined,
      documentType: undefined,
      productType: undefined,
      programme: undefined,
      payloadType: undefined,
      extractorFields: undefined
    } as DynamicMapping
  }

  ngOnInit(): void {
    console.log(this.newDynamicMapping)
    this.showAdditionalExtractorFieldsSubject.subscribe(value => {
      this.addAdditionalExtractorFields = value
    })
  }

  actionCancel() {
    this.dialogRef.close(undefined)
  }

  public async actionCreate() {
    console.log(this.newDynamicMapping)
    if (this.newDynamicMapping) {
      this.dialogRef.close(this.newDynamicMapping)
    }
  }

  public validateInput() {
    const canSave: boolean = this.newDynamicMapping.sourceSystem?.length > 0
      && this.newDynamicMapping.documentType?.length > 0
      && this.newDynamicMapping.productType?.length > 0
      && this.newDynamicMapping.programme?.length > 0
      && this.newDynamicMapping.payloadType?.length > 0

    this.canSave = canSave
  }

  public showAdditionalExtractorFields() {
    const newExtractorField: DmExtractorFields = {
      extractorPath: '',
      conditions: []
    }
    this.newDynamicMapping = {...this.newDynamicMapping,  extractorFields: newExtractorField}
    this.showAdditionalExtractorFieldsSubject.next(!this.addAdditionalExtractorFields)
    this.actionAddCondition()
  }

  trackByIndex(index: number): number {
    return index
  }

  actionAddCondition() {
    const currentConditions = [...this.newDynamicMapping.extractorFields.conditions]
    currentConditions.push({value: '', newProductType: ''})
    this.newDynamicMapping.extractorFields.conditions = currentConditions
  }

  removeCondition(condition) {
    let currentConditions = [...this.newDynamicMapping.extractorFields.conditions]
    currentConditions = currentConditions.filter(obj => obj !== condition);
    this.newDynamicMapping.extractorFields.conditions = currentConditions
  }
}
