<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add note</h4>
    <button type="button" class="close" aria-label="Close" (click)="actionCancel()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body mt-3" ngbAutofocus>
    <form [formGroup]="form" (ngSubmit)="submit()">
        <div *ngIf="isTransformConfig(); else elseBlock">
            <div class="form-group row">
                <label for="input-format" class="col-md-3 col-form-label">Input format:</label>
                <div class="col-md-8">
                    <input type="text" class="form-control" id="input-format" formControlName="inputFormat">
                </div>
            </div>
            <div class="form-group row">
                <label for="input-format-version" class="col-md-3 col-form-label">Input format version:</label>
                <div class="col-md-8">
                    <input type="text" class="form-control" id="input-format-version"
                        formControlName="inputFormatVersion">
                </div>
            </div>
            <div class="form-group row">
                <label for="output-format" class="col-md-3 col-form-label">Output format:</label>
                <div class="col-md-8">
                    <input type="text" class="form-control" id="output-format" formControlName="outputFormat">
                </div>
            </div>
            <div class="form-group row">
                <label for="output-format-version" class="col-md-3 col-form-label">Output format version:</label>
                <div class="col-md-8">
                    <input type="text" class="form-control" id="output-format-version"
                        formControlName="outputFormatVersion">
                </div>
            </div>
        </div>
        <ng-template #elseBlock>
            <div class="form-group row">
                <label for="sourceSystem" class="col-md-3 col-form-label">Source System:</label>
                <div class="col-md-8">
                    <select class="form-control" id="sourceSystem" formControlName="sourceSystem"
                        (change)="selectSourceSystem($event.target.value)">
                        <option *ngFor="let sourceSystem of sourceSystems" [value]="sourceSystem.value">
                            {{sourceSystem.display}}
                        </option>
                    </select>
                </div>
            </div>
        </ng-template>

        <div class="form-group row">
            <label for="description" class="col-md-3 col-form-label">Description:</label>
            <div class="col-md-8">
                <textarea class="form-control" id="description" formControlName="description" rows="3"></textarea>
            </div>
        </div>

        <div class="form-group row">
            <label for="version" class="col-md-3 col-form-label">Version:</label>
            <div class="col-md-8">
                <input type="text" class="form-control" id="version" formControlName="version" readonly>
            </div>
        </div>

        <div class="form-group row">
            <label for="date-updated" class="col-md-3 col-form-label">Date Updated:</label>
            <div class="col-md-8">
                <input type="text" class="form-control" id="date-updated" formControlName="dateUpdated" readonly>
            </div>
        </div>

        <div class="form-group row">
            <label for="user" class="col-md-3 col-form-label">User:</label>
            <div class="col-md-8">
                <input type="text" class="form-control" id="user" formControlName="user" readonly>
            </div>
        </div>

        <div class="form-row justify-content-end">
            <button class="btn btn-secondary me-2" type="button" (click)="actionCancel()">Cancel</button>
            <button class="btn btn-primary" type="submit">Create</button>
        </div>
    </form>
</div>
