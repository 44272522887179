<mat-form-field appearance="standard" floatLabel="always">
  <mat-label>Source System</mat-label>
  <mat-select [(value)]="activeConfig.sourceSystem"
              (selectionChange)="selectSourceSystem($event.value)"
              placeholder="select">
    <mat-option *ngFor="let sourceSystem of selections.sourceSystems"
                [value]="sourceSystem.value">{{sourceSystem.display}}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard" floatLabel="always" *ngIf="activeConfig.sourceSystem">
  <mat-label>Business Unit</mat-label>
  <mat-select [(value)]="activeConfig.businessUnit"
              (selectionChange)="selectProgramme($event.value)"
              placeholder="select">
    <mat-option *ngFor="let programme of selections.businessUnits"
                [value]="programme.value">{{programme.display}}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard" floatLabel="always" *ngIf="activeConfig.businessUnit">
  <mat-label>Payload Type</mat-label>
  <mat-select [(value)]="activeConfig.payloadType"
              (selectionChange)="selectProductType($event.value)"
              placeholder="select">
    <mat-option *ngFor="let productType of selections.payloadTypes"
                [value]="productType.value">{{productType.display}}</mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field appearance="standard" floatLabel="always" *ngIf="activeConfig.payloadType">
  <mat-label>Target System</mat-label>
  <mat-select [(value)]="activeConfig.targetSystem"
              (selectionChange)="selectDocumentType($event.value)"
              placeholder="select">
    <mat-option *ngFor="let documentType of selections.targetSystems"
                [value]="documentType.value">{{documentType.display}}</mat-option>
  </mat-select>
</mat-form-field>
