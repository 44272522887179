import {Injectable} from '@angular/core'
import {ChangeDetectingBehaviourSubject} from '../util/change-detecting-behaviour-subject'
import {BehaviorSubject} from 'rxjs'
import {distinctUntilChanged, shareReplay} from 'rxjs/operators'
import {DEV_ACCOUNT_ID, ENVIRONMENT, PROD_ACCOUNT_ID, STAGING_ACCOUNT_ID, UAT_ACCOUNT_ID, valueReplaced} from '../constants'

export interface Region {
  id: string
  display: string
}

export interface Environment {
  id: string
  display: string
  description: string
  regions: Region[]
  account: string
}

export const REGIONS: {
  London: Region,
  Frankfurt: Region
} = {
  London: {
    id: 'LONDON',
    display: 'London',
  },
  Frankfurt: {
    id: 'FRANKFURT',
    display: 'Frankfurt',
  },
}

export const ENVIRONMENTS: {
  TOOLS?: Environment
  STAGING?: Environment
  UAT?: Environment
  PROD?: Environment
} = {
  TOOLS: !valueReplaced(ENVIRONMENT) || ENVIRONMENT.includes('TOOLS') ? {
    id: 'TOOLS',
    display: 'Tools',
    description: 'Tools Environment using the Tools SmartDX Appliance/Tenancy',
    regions: [REGIONS.Frankfurt, REGIONS.London],
    account: valueReplaced(DEV_ACCOUNT_ID) ? DEV_ACCOUNT_ID : '227499747844',
  } : undefined,
  STAGING: !valueReplaced(ENVIRONMENT) || ENVIRONMENT.includes('DEV') ? {
    id: 'DEV',
    display: 'Development',
    description: 'Development Environment using the Dev SmartDX Appliance/Tenancy',
    regions: [REGIONS.Frankfurt, REGIONS.London],
    account: valueReplaced(STAGING_ACCOUNT_ID) ? STAGING_ACCOUNT_ID : '444573929409',
  } : undefined,
  UAT: !valueReplaced(ENVIRONMENT) || ENVIRONMENT.includes('UAT') ? {
    id: 'UAT',
    display: 'UAT',
    description: 'UAT Environment using the UAT SmartDX Appliance/Tenancy',
    regions: [REGIONS.Frankfurt, REGIONS.London],
    account: valueReplaced(UAT_ACCOUNT_ID) ? UAT_ACCOUNT_ID : '761471620907',
  } : undefined,
  PROD: !valueReplaced(ENVIRONMENT) || ENVIRONMENT.includes('PROD') ? {
    id: 'PROD',
    display: 'PROD',
    description: 'Production Environment using the PROD SmartDX Appliance/Tenancy',
    regions: [REGIONS.Frankfurt, REGIONS.London],
    account: valueReplaced(PROD_ACCOUNT_ID) ? PROD_ACCOUNT_ID : '310361076906',
  } : undefined,
}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private targetEnvironmentSubject = new BehaviorSubject<Environment>(ENVIRONMENTS.STAGING)
  targetEnvironment = this.targetEnvironmentSubject.asObservable()
    .pipe(
      distinctUntilChanged(),
      shareReplay(1),
    )

  private sourceEnvironmentSubject = new BehaviorSubject<Environment>(ENVIRONMENTS.STAGING)
  sourceEnvironment = this.sourceEnvironmentSubject.asObservable()
    .pipe(
      distinctUntilChanged(),
      shareReplay(1),
    )

  private regionSubject = new ChangeDetectingBehaviourSubject<Region>('Region', REGIONS.London)
  region = this.regionSubject.asObservable()

  availableTargetEnvironments = [ENVIRONMENTS.STAGING, ENVIRONMENTS.UAT].filter(env => !!env)
  availableSourceEnvironments = [ENVIRONMENTS.STAGING, ENVIRONMENTS.UAT, ENVIRONMENTS.PROD].filter(env => !!env)

  selectTargetEnvironment(environment: Environment) {
    if (environment) {
      if (environment.regions.length === 1) {
        this.selectTargetRegion(environment.regions[0])
      }
      const previousEnvironment = this.targetEnvironmentSubject.getValue()
      if (environment !== previousEnvironment) {
        this.targetEnvironmentSubject.next(environment)
        if (previousEnvironment.regions[0] !== environment.regions[0]) {
          this.selectTargetRegion(environment.regions[0])
        }
      }
    }
  }

  selectSourceEnvironment(environment: Environment) {
    this.sourceEnvironmentSubject.next(environment)
  }

  selectTargetRegion(region: Region) {
    if (region && region !== this.regionSubject.getValue()) {
      this.regionSubject.next(region)
    }
  }
}

