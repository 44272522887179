//TODO:
//listen to live changes in the local memory with subject/behav. subject

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';

import { OutputResponses, SendToSmartDxResponse } from '../utils/models/md-model';
import { ValidatePayloadResponse, ValidationErrorOrWarning } from '../utils/models/md-validation-models';
import { AutoEnrichmentResponse } from '../utils/models/md-enrichment-rule-model';
import { PayloadResponse } from '../utils/models/md-payload-model';
@Component({
  selector: 'app-output-display',
  templateUrl: './output-display.component.html',
  styleUrls: ['./output-display.component.scss']
})
export class OutputDisplayComponent implements OnInit, OnDestroy, OnChanges {
  @Input() outputResponsesProp: OutputResponses = null;
  localMemoryOutputResponses: OutputResponses = null;

  validatePayloadResponse: ValidatePayloadResponse = null;
  autoTransformResponse: AutoEnrichmentResponse = null;
  sendToSmartDxResponse: SendToSmartDxResponse = null;
  savePayloadResponse: PayloadResponse = null;

  dataToUse: any = null;
  schemaValidationErrorsOrWarnings: ValidationErrorOrWarning[] = [];

  isOpenedInSeparateWindow = window.location.pathname === '/manual-drafting/output-results';

  constructor() {
    this.getOutputResponsesFromLocalStorage();
  }

  ngOnInit(): void {
    this.setDataToUse();

  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.outputResponsesProp && changes.outputResponsesProp.currentValue) {
      this.setDataToUse();
    }
  }

  ngOnDestroy(): void {
    localStorage.removeItem('outputResponses');
  }

  getOutputResponsesFromLocalStorage(): void {
    const localMemoryOutputResponses = localStorage.getItem('outputResponses');
    if (localMemoryOutputResponses) {
      this.localMemoryOutputResponses = JSON.parse(localMemoryOutputResponses);
    }
  }

  setDataToUse(): void {
    const validData = this.outputResponsesProp ? this.outputResponsesProp : this.localMemoryOutputResponses;
    const { validationResponse, autoTransformResponse, sendToSmartDxResponse, savePayloadResponse } = validData;
    //assign to class variables to reset when data changes
    this.validatePayloadResponse = validationResponse;
    this.autoTransformResponse = autoTransformResponse;
    this.sendToSmartDxResponse = sendToSmartDxResponse;
    this.savePayloadResponse = savePayloadResponse;

    if (this.validatePayloadResponse !== null) {
      if (this.validatePayloadResponse.schemaValidation && this.validatePayloadResponse.schemaValidation.schemaValidationResults) {
        const { schemaValidationResults } = this.validatePayloadResponse.schemaValidation;
        if (schemaValidationResults.warnings) {
          schemaValidationResults.warnings.forEach(warning => this.schemaValidationErrorsOrWarnings.push({ ...warning, type: 'WARNING' } as ValidationErrorOrWarning));
          if (schemaValidationResults.errors) {
            schemaValidationResults.errors.forEach(error => {
              this.schemaValidationErrorsOrWarnings.push({ ...error, type: 'ERROR' } as ValidationErrorOrWarning);
            });
          }
        };
        if (schemaValidationResults.errors && !schemaValidationResults.warnings) {
          schemaValidationResults.errors.forEach(error => this.schemaValidationErrorsOrWarnings.push({ ...error, type: 'ERROR' } as ValidationErrorOrWarning));
        }
      }
      if (!this.validatePayloadResponse.schemaValidation) this.schemaValidationErrorsOrWarnings.splice(0, this.schemaValidationErrorsOrWarnings.length);
    }
  };

  closeWindow(): void {
    window.close();
  };

}
