import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {ToastrService} from 'ngx-toastr'
import {take} from 'rxjs/operators'
import {AnalyseRow, AnalyseRuleTable} from 'src/app/common/domain/analyse'
import {CommonConfig} from 'src/app/common/domain/config'
import {DocgenConfigMetadata} from 'src/app/common/domain/docgen-config'
import {NormalisedRuleSet} from 'src/app/model/normalised-ruleset'
import {ApprovalConfig, ApprovedMnemonics} from '../../common/domain/approval'
import {PushConfigRequest} from '../../model/config-requests'
import {
  GetConfigFileErrorResponse,
  GetConfigFileResponse,
  GetConfigFileSuccessResponse,
  PublishConfigResponse
} from '../../model/config-responses'
import {ApiService} from '../../services/api.service'
import {PermissionsService} from '../../services/permissions.service'
import {UserNameService} from '../../services/user-name.service'

export interface ResolvedConfig {
  approval: GetConfigFileResponse
  config: GetConfigFileResponse
  devConfig?: GetConfigFileResponse
}

@Component({
  selector: 'app-approve-rule',
  templateUrl: './approve-rule.component.html',
  styleUrls: ['./approve-rule.component.scss'],
})
export class ApproveRuleComponent implements OnInit {
  // Data about current user
  private _canApproveRule: boolean
  private _userName: string
  private _readOnly = true

  public fullMnemonic: string // Used by html
  public docgenConfigMetadata: DocgenConfigMetadata // Used by html
  private _commitHash: string

  public service: string // Used by html

  private _fastApprovalsConfig: ApprovalConfig
  private readonly _fullMnemonicsWithFastOrSlowApprovals: ApprovedMnemonics = new Array<string>()
  private _dataInjectedViaRouteResolve: ResolvedConfig
  public broken: boolean // Used by html
  public approving: boolean // Used by html

  public ruleTable: AnalyseRuleTable // Used by html
  public ruleRow: AnalyseRow // Used by html
  public warning: string // Used by html

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _api: ApiService,
    private _toast: ToastrService,
    private _permissionsService: PermissionsService,
    private _userNameService: UserNameService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    // console.log('Loaded Approve Rule Component')

    // Get this._canApproveRule, this._userName, this._readOnly, this.ruleSetMnemonic, this.service, this.docgenConfigMetadata, this._commitHash
    await this._getDataFromInjectedServices()

    // Get Fast Approvals (+verify they actually loaded from lambda-approvals)
    this._getFastApprovals()

    // If we failed to get a file for Fast Approvals then abort. Development will need to add a new file
    if (this.broken) {
      return
    }

    // Look for a Normalised RuleSet Definition (using the commit hash passed to this screen via the URL)
    const ruleSetMnemonic = this.fullMnemonic.split('-')[1]
    const ruleSetDefinition = await this._api.get<GetConfigFileResponse>(
      `/config/commit/${this._commitHash}/lambda-ingest-product/config/rulesets/${ruleSetMnemonic}.json`
    ).pipe(take(1)).toPromise()
    // console.info(`ruleSetDefinition:`); console.dir(ruleSetDefinition)

    // Did we locate a Normalised RuleSet Definition File?
    if ((ruleSetDefinition as any)?.code === undefined && !!(ruleSetDefinition as GetConfigFileSuccessResponse).data) {
      console.info(`RuleSet ${ruleSetMnemonic} has a RuleSet Definition File`)
      const getConfigFileSuccessResponse = ruleSetDefinition as GetConfigFileSuccessResponse
      const normalisedRuleSet = new NormalisedRuleSet(getConfigFileSuccessResponse.path)
      Object.assign(normalisedRuleSet, JSON.parse(getConfigFileSuccessResponse.data))
      // console.info(`normalisedRuleSet:`); console.dir(normalisedRuleSet)
      this._initialiseScreenFromNormalisedRuleSetDefinition(normalisedRuleSet)
    } else if ((ruleSetDefinition as any)?.code === 'FileDoesNotExistException') {
      // Use Original Code
      console.info('Assuming RuleSet exists in Config.json file (i.e. old RuleSet Format)')
      const commonConfig = JSON.parse((this._dataInjectedViaRouteResolve.config as GetConfigFileSuccessResponse).data) as CommonConfig
      const devConfig = JSON.parse((this._dataInjectedViaRouteResolve.devConfig as GetConfigFileSuccessResponse).data) as CommonConfig
      this._originalInitialisedScreenFromOldRulesetFormat(this.fullMnemonic, commonConfig, devConfig, this._fastApprovalsConfig)
    } else {
      console.error('An unhandled error occured. ruleSetDefinition follows')
      console.error(JSON.stringify(ruleSetDefinition, null, 2))
    }
  }

  private async _getDataFromInjectedServices() {
    // Promises
    const canApproveRulePromise = this._permissionsService.canApproveRule.pipe(take(1)).toPromise()
    const userNamePromise = this._userNameService.userName.pipe(take(1)).toPromise()
    const readonlyPromise = this._permissionsService.readonly.pipe(take(1)).toPromise()
    const activatedRouteDataPromise = this._activatedRoute.data.pipe(take(1)).toPromise()
    const activatedRouteParamMapPromise = this._activatedRoute.paramMap.pipe(take(1)).toPromise()

    // Wait for all Promises to resolve
    const [canApproveRule, userName, readOnly, dataInjectedViaRouteResolve, params]: [boolean, string, boolean, ResolvedConfig, ParamMap] =
      await Promise.all([canApproveRulePromise, userNamePromise, readonlyPromise, activatedRouteDataPromise as Promise<ResolvedConfig>, activatedRouteParamMapPromise])
    {
      this._canApproveRule = canApproveRule
      this._userName = userName
      this._readOnly = readOnly
      // console.info(`readOnly: ${readOnly}`); console.info(`canApproveRule: ${canApproveRule}`); console.info(`userName: ${userName}`)
      // console.log('Have params'); console.dir(params)
      // console.log('Have data'); console.dir(data)

      this._dataInjectedViaRouteResolve = dataInjectedViaRouteResolve
      if (!params || !dataInjectedViaRouteResolve) {
        console.error('Failed to find params and/or dataInjectedViaRouteResolve')
        this.broken = true
        return
      }

      this.fullMnemonic = params.get('mnemonic')
      const service = params.get('service')
      this.service = (service === 'lambda-ingest-product' ? 'Document Generation' : 'Routing')
      this.docgenConfigMetadata = {
        sourceSystem: params.get('sourceSystem'),
        programme: params.get('p1'),
        productType: params.get('p2'),
        documentType: params.get('p3')
      }
      this._commitHash = params.get('version')
      // console.info(`Commit Hash ${this._commitHash}`)
    }
  }

  private async _getFastApprovals() {
    // console.info(`this.dataInjectedViaRouteResolve.approval`)
    // console.dir(this._dataInjectedViaRouteResolve.approval)

    // It's possible a matching approval file in lambda-approvals does not exist
    if (this._dataInjectedViaRouteResolve.approval && (this._dataInjectedViaRouteResolve.approval as unknown as GetConfigFileErrorResponse).code === 'FileDoesNotExistException') {
      this.broken = true
      const errorMessage = `Failed to load approvals for ${this._dataInjectedViaRouteResolve.approval.path}`
      console.error(errorMessage)
      this._toast.error(errorMessage)
    } else {
      // Fast Approvals should be available
      this._fastApprovalsConfig = JSON.parse((this._dataInjectedViaRouteResolve.approval as GetConfigFileSuccessResponse).data) as ApprovalConfig
    }
  }

  private _initialiseScreenFromNormalisedRuleSetDefinition(normalisedRuleSet: NormalisedRuleSet) {
    // Set this.ruleTable, this.ruleRow, this.warning, this._approvedMnemonics
    // console.info(`_initialiseScreenFromNormalisedRuleSetDefinition()`)

    // Get this.ruleTable (only the fields are used from it)
    this.ruleTable = new AnalyseRuleTable()
    this.ruleTable.fields = normalisedRuleSet.fields

    // Get this.ruleRow
    const ruleMnemonic = this.fullMnemonic.split('-')[2]
    this.ruleRow = new AnalyseRow()
    this.ruleRow.description = normalisedRuleSet.description
    this.ruleRow.expected = normalisedRuleSet.rule?.find(x => x.mnemonic === ruleMnemonic).expected

    const slowApprovals = normalisedRuleSet.ruleApprovals.find(x =>
      x.sourceSystem === this.docgenConfigMetadata.sourceSystem &&
      x.programme === this.docgenConfigMetadata.programme &&
      x.documentType === this.docgenConfigMetadata.documentType &&
      x.productType === this.docgenConfigMetadata.productType
    )?.approvals

    const approvedMnemonicsPrefix = normalisedRuleSet.stage.substr(0, 1).toUpperCase() + normalisedRuleSet.ruleType.substr(0, 1).toUpperCase()
      + '-' + normalisedRuleSet.mnemonic.toUpperCase() + '-'
    if (slowApprovals) {
      slowApprovals.forEach(x => this._fullMnemonicsWithFastOrSlowApprovals.push(approvedMnemonicsPrefix + x.ruleMnemonic))
    }
    console.log('this._approvedMnemonics:')
    console.dir(this._fullMnemonicsWithFastOrSlowApprovals)
  }

  private _originalInitialisedScreenFromOldRulesetFormat(mnemonic: string, commonConfig: CommonConfig, devConfig: CommonConfig, approvalsConfig: ApprovalConfig) {
    // Set this.ruleTable, this.ruleRow, this.warning, this._approvedMnemonics
    // console.dir(this)

    // Injected Config.json 
    if (!!commonConfig) {

      console.log('Mnemonic: ' + mnemonic)
      if (mnemonic.startsWith('P')) {
        this.ruleTable = commonConfig.analysePayload.rules
          .filter(r => r.ruleType === 'whitelist')
          .find(r => r.mnemonic === mnemonic.split('-')[1])
      } else {
        this.ruleTable = commonConfig.analyseEnriched.rules
          .filter(r => r.ruleType === 'whitelist')
          .find(r => r.mnemonic === mnemonic.split('-')[1])
      }
      this.ruleRow = this.ruleTable?.rule
        .find(r => r.mnemonic === mnemonic.split('-')[2])

      if (!!devConfig) {
        let devRuleTable
        if (mnemonic.startsWith('P')) {
          devRuleTable = devConfig.analysePayload.rules
            .filter(r => r.ruleType === 'whitelist')
            .find(r => r.mnemonic === mnemonic.split('-')[1])
        } else {
          devRuleTable = devConfig.analyseEnriched.rules
            .filter(r => r.ruleType === 'whitelist')
            .find(r => r.mnemonic === mnemonic.split('-')[1])
        }
        if (!devRuleTable?.rule
          .find(r => r.mnemonic === mnemonic.split('-')[2])) {
          this.warning = 'This rule has changed in development and the development version should be approved separately!'
        }
      }
    }

    if (!!approvalsConfig) {
      // console.log('Data'); console.dir(approvalsConfig)
      // Add Slow approvals to this._approvedMnemonics
      this._fullMnemonicsWithFastOrSlowApprovals.push(...approvalsConfig.approvedMnemonics.map(v => v.mnemonic))
      console.log('this._approvedMnemonics:')
      console.dir(this._fullMnemonicsWithFastOrSlowApprovals)
    }
  }

  public async approve() {
    if (this._readOnly) {
      this._toast.warning('This would have fast track approved this mnemonic for use by sales')
      return
    }

    this.approving = true
    const updated = Object.assign({}, this._fastApprovalsConfig, {
      approvedMnemonics: this._fastApprovalsConfig.approvedMnemonics.concat({
        mnemonic: this.fullMnemonic,
        approvedBy: this._userName,
        approvedDate: new Date().toISOString(),
      }),
    })
    const updatedRule = JSON.stringify(updated, undefined, 2)

    const approvalPayload: PushConfigRequest = {
      commitMessage: `Approved ${this.fullMnemonic} in ${this.docgenConfigMetadata.sourceSystem}/${this.docgenConfigMetadata.programme}/${this.docgenConfigMetadata.productType}/${this.docgenConfigMetadata.documentType}`,
      data: updatedRule,
      parentCommit: this._dataInjectedViaRouteResolve.approval.branchVersion,
    }

    const publishConfigResponse = await this._api.post(
      `/config/latest/lambda-approvals/config/approvals/${this.docgenConfigMetadata.sourceSystem}/${this.docgenConfigMetadata.programme}/${this.docgenConfigMetadata.productType}/${this.docgenConfigMetadata.documentType}/Approvals.json`, approvalPayload
    ).pipe(take(1)).toPromise() as PublishConfigResponse

    if (publishConfigResponse.status === 'OK') {
      this._fastApprovalsConfig = JSON.parse(updatedRule)
      this._fullMnemonicsWithFastOrSlowApprovals.push(...this._fastApprovalsConfig.approvedMnemonics.map(v => v.mnemonic))
      this._toast.success('Approval submitted successfully')
    } else {
      this._toast.error('Approval Failed - Please reload and try again')
    }

    this.approving = false

    // TODO - Add the approval to the main config as well...
    //  But only after we have automated config reloading with intelligent merging to prevent users losing their changes with frequent approvals.
  }

  canApprove() {
    return !this.broken && this._canApproveRule && this._fastApprovalsConfig && this.fullMnemonic && this._userName !== 'Nobody' && this._fullMnemonicsWithFastOrSlowApprovals && !this._fullMnemonicsWithFastOrSlowApprovals.includes(this.fullMnemonic)
  }

  alreadyApproved() {
    return !this.broken
      && this._fastApprovalsConfig
      && this.fullMnemonic
      && this._fullMnemonicsWithFastOrSlowApprovals
      && this._fullMnemonicsWithFastOrSlowApprovals.includes(this.fullMnemonic)
  }

  approvedBy() {
    return this._fastApprovalsConfig
      && this.fullMnemonic
      && this._fastApprovalsConfig.approvedMnemonics.find(r => r.mnemonic === this.fullMnemonic)?.approvedBy?.replace(/\./g, ' ')
  }

  approvedDate() {
    const approvedDate = this._fastApprovalsConfig
      && this.fullMnemonic
      && this._fastApprovalsConfig.approvedMnemonics.find(r => r.mnemonic === this.fullMnemonic)?.approvedDate
    return approvedDate
  }
}
