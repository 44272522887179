<app-config-publish (onPublish)="published($event)"></app-config-publish>
<app-config-revert></app-config-revert>
<app-new-workspace></app-new-workspace>
<app-delete-workspace></app-delete-workspace>
<ng-container *ngIf="staleConfig">
  <button type="button" class="btn btn-primary" [disabled]="!staleConfig" (click)="reload()">Load Latest Config
  </button>
</ng-container>
<ng-container *ngIf="staleWorkspace">
  <div class="staleWorkspace">Workspace is out of date</div>
</ng-container>
