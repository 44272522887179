<div>
  <ng-container>
    <div>
      <h2>Administer payloads</h2>
    </div>
    &nbsp; &nbsp;
    <div class="mb-3 mt-n2">
      <button type="button" class="btn btn-sm rounded-pill mr-2 btn-primary top-btn"
        [ngClass]="this.showNew ? 'btn-primary' : 'btn-secondary'" (click)="togglePayload($event)" id="new">
        New
      </button>
      <button type="button" class="btn btn-sm rounded-pill mr-2 btn-primary top-btn"
        [ngClass]="this.showCompleted ? 'btn-primary' : 'btn-secondary'" (click)="this.togglePayload($event)"
        id="completed">
        Completed
      </button>
      <button type="button" class="btn btn-sm rounded-pill mr-2 btn-primary top-btn"
        [ngClass]="this.showDeleted ? 'btn-primary' : 'btn-secondary'" (click)="togglePayload($event)" id="deleted">
        Deleted
      </button>
      <button type="button" class="btn btn-sm rounded-pill mr-2 btn-primary top-btn"
        [ngClass]="this.showDraft ? 'btn-primary' : 'btn-secondary'" (click)="togglePayload($event)" id="draft">
        Draft
      </button>
      <button type="button" class="btn btn-sm rounded-pill mr-2 btn-primary top-btn"
        [ngClass]="this.showFailed ? 'btn-primary' : 'btn-secondary'" (click)="togglePayload($event)" id="failed">
        Failed
      </button>
      <button type="button" class="btn btn-sm rounded-pill mr-2 btn-primary top-btn"
        [ngClass]="this.showRestored ? 'btn-primary' : 'btn-secondary'" (click)="togglePayload($event)" id="restored">
        Restored
      </button>

    </div>
    &nbsp; &nbsp;
    <div>
      <button type="button" class="btn btn-primary" (click)="createNewPayLoadDialog()">
        New Payload
      </button>
    </div>
    &nbsp; &nbsp;
    <div>
      <table class="table table-striped">
        <tr>
          <th class="sort_column" (click)="this.sortByDate()" fa-icon icon="fa-sort" title="click to sort by ID">
            Reference</th>
          <!-- <th>Name</th> -->
          <th class="sort_column" (click)="this.sortAlphabetically('description')" fa-icon icon="fa-sort"
            title="click to sort alphabetically">Description</th>
          <th class="sort_column" (click)="this.sortByDate()" fa-icon icon="fa-sort" title="click to sort by date">
            Date</th>
          <th class="sort_column" (click)="this.sortAlphabetically('status')" fa-icon icon="fa-sort"
            title="click to sort alphabetically">Status</th>
          <th></th>
        </tr>
        <tbody>
          <tr *ngFor="let payload of displayAdminScreenData">
            <td>
              {{ payload.id }}
            </td>
            <!-- <td>Huston Flash</td> -->
            <td>{{ payload.description }}</td>
            <td>{{ payload.date_created }}</td>
            <td>{{ payload.status
              }}</td>
              <td>
                <div class="interaction-with-db">
                <button type="button" class="btn btn-primary table-btn" (click)="editPayload(payload.id)"
                  [disabled]="payload.status === 'DELETED'">
                  Edit
                </button>
                <button [disabled]="payload.status === 'DELETED'" type="button" class="btn btn-primary table-btn"
                   (click)="this.selectedPayload = payload;  openJsonEditor(content)">
                Enrich
              </button>
                <button type="button" class="btn btn-primary table-btn"
                        [disabled]="payload.status !== 'COMPLETED'" (click)="download(payload.id)">Download</button>
                <button [disabled]="payload.status === 'DELETED'" type="button" class="btn btn-danger table-btn"
                  (click)="this.softDeletePayload(payload.id)">Delete</button>
                <button *ngIf="this.showDeleted && payload.status === 'DELETED' " type="button" class="btn btn-primary table-btn"
                  (click)="this.restorePayload(payload.id)">Restore</button>
                </div>
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-container>
</div>

<!--JSON editor pop-up modal  -->

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Payload Editor ({{selectedPayload.id}})</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" ngbAutofocus>
    <app-payload-editor [modal]="modal" [selectedPayload]="this.selectedPayload"
                        [refresh]="this.refreshOnClosedPayloadEditorSubject" ></app-payload-editor>
  </div>
</ng-template>
