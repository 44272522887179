import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfigService} from '../../services/config.service';
import {AuditInfo} from '../../model/ruleset-audit';
import {GetConfigFileErrorResponse, GetConfigFileSuccessResponse} from '../../model/config-responses';
import {ActivatedRoute} from '@angular/router';
import {AuditHistoryDialogComponent} from '../../dialogs/audit-history-dialog/audit-history-dialog.component';
import {getAuditChanges} from '../../util/audit/audit-log';
import {AnalyseRow} from '../../common/domain/analyse';
import {NormalisedRuleSet} from '../../model/normalised-ruleset';
import {RuleApprovals} from '../../model/rule-approvals';
import {of, Subject} from 'rxjs';
import {mergeMap, tap, toArray, filter} from 'rxjs/operators';
import {saveAs} from 'file-saver'
export interface RulesetAuditHistoryData {
  ruleSetMnemonic: string
}
export interface AuditHistoryDisplay extends AuditInfo {
  auditDetails: string[];
  updatedConfig: string;
  originalConfig: string;
  noHistoryToShow?: boolean;
  commitNotFound?: boolean;
}

@Component({
  selector: 'app-ruleset-audit-history',
  templateUrl: './ruleset-audit-history.component.html',
  styleUrls: ['./ruleset-audit-history.component.scss']
})
export class RulesetAuditHistoryComponent implements OnInit {

  public rulesetMnemonic: string
  public auditHistory: AuditInfo[]
  public auditHistoryToDisplay: AuditHistoryDisplay[] = []
  public showAuditHistory: boolean

  private loadedDataChange: Subject<boolean> = new Subject<boolean>();
  private loadedData: boolean

  constructor(private configService: ConfigService,
              private _activatedRoute: ActivatedRoute,
              private dialog: MatDialog) {}

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(parameter => {
      this.rulesetMnemonic = parameter.mnemonic
    })

    this.loadedDataChange.subscribe(value => {
      this.loadedData = value
      if (this.loadedData) {
        this.auditHistoryToDisplay.map(audit => {
          const auditDetails = this.getAuditDetails(audit)
          audit.auditDetails = auditDetails
          this.showAuditHistory = true
        })
      }
    })
    this.getAuditHistoryForRuleset()
  }

  private getAuditHistoryForRuleset() {
    this.configService.getAuditFile('rulesets', this.rulesetMnemonic).subscribe(auditHistory => {
      this.auditHistory = auditHistory
      this.auditHistory.map(audit => {
        let newAudit: AuditHistoryDisplay
        newAudit = JSON.parse(JSON.stringify(audit))

        const commitArray = [{type: 'updatedConfig', commit: audit.commitId}, {type: 'originalCommit', commit: audit.parentCommitId}]

        of(...commitArray).pipe(
          mergeMap(commit => this.configService.getConfigFile('lambda-ingest-product', commit.commit, `config/rulesets/${this.rulesetMnemonic}.json`).pipe(
            tap(file => {
              const response = file as any;
              if(response.error) {
                newAudit.noHistoryToShow = (file as GetConfigFileErrorResponse).code === 'FileDoesNotExistException';
                newAudit.commitNotFound = (file as GetConfigFileErrorResponse).code === 'CommitDoesNotExistException';
                return;
              }

              commit.type === 'updatedConfig' 
                ? newAudit.updatedConfig = (file as GetConfigFileSuccessResponse).data 
                : newAudit.originalConfig = (file as GetConfigFileSuccessResponse).data;
            })
          )),
          toArray()
        ).subscribe(constructedAudit => {
          this.auditHistoryToDisplay.push(newAudit);
          this.auditHistoryToDisplay = this.auditHistoryToDisplay.sort((x, y) => {
            return new Date(x.timestamp) < new Date(y.timestamp) ? -1 : 1;
          });
          this.loadedDataChange.next(true);
        })
      })
    })
  }

  public showConfigForChange(selectedAudit: AuditHistoryDisplay) {
    const data = {
      updatedConfig: selectedAudit.updatedConfig,
      originalConfig: selectedAudit.originalConfig
    }
    this.dialog.open(AuditHistoryDialogComponent, {
      width: '1550px',
      height: '80vh',
      panelClass: 'mat-dialog-without-padding',
      data: data
    })
  }

  private getAuditDetails(audit: AuditHistoryDisplay): string[] {
    if(audit.commitNotFound) {
      return ['Audit details not found.'];
    }

    if(audit.noHistoryToShow) {
      return ['No history to show.'];
    }

    const { updatedConfig, originalConfig } = audit;

    const updatedRuleset = JSON.parse(updatedConfig) as NormalisedRuleSet
    const originalRuleset = originalConfig ? JSON.parse(originalConfig) as NormalisedRuleSet : ''
    const rulesetChanges = getAuditChanges(updatedRuleset, originalRuleset, 'Ruleset', this.rulesetMnemonic)

    const ruleChanges = updatedRuleset.rule.map((updatedRule, index) => {
      let originalRule: AnalyseRow
      if(originalRuleset && originalRuleset.rule) {
        originalRuleset.rule.map(oldRule => {
          if(oldRule.mnemonic === updatedRule.mnemonic) {
            originalRule = oldRule
          }
        })
      }
      return getAuditChanges(updatedRule, originalRule, 'Rule', updatedRule.mnemonic)
    })

    const ruleApprovalChanges = updatedRuleset.ruleApprovals.map(approval => {
      const updatedApprovalConfigKey = this.getConfigKey(approval.sourceSystem, approval.programme, approval.productType, approval.documentType)
      let originalApproval: RuleApprovals
      if(originalRuleset && originalRuleset.ruleApprovals) {
        originalRuleset.ruleApprovals.map(existingApproval => {
          const existingApprovalConfigKey = this.getConfigKey(existingApproval.sourceSystem, existingApproval.programme, existingApproval.productType, existingApproval.documentType)
          if(existingApprovalConfigKey === updatedApprovalConfigKey) {
            originalApproval = existingApproval
          }
        })
      }
      const rulesMnemonicsInUpdatedApproval = approval.approvals.map(rule => rule.ruleMnemonic)
      const rulesMnemonicsInExistingApproval = originalApproval && originalApproval.approvals ? originalApproval.approvals.map(rule => rule.ruleMnemonic) : []
      const addedRuleMnemonics = rulesMnemonicsInUpdatedApproval.filter(rule => !rulesMnemonicsInExistingApproval.includes(rule))
      return getAuditChanges(approval, originalApproval, 'Rule approval', addedRuleMnemonics.join())
    })

    const changes = rulesetChanges.concat(...ruleChanges, ...ruleApprovalChanges)
    return changes
  }

  private getConfigKey(sourceSystem: string, programme: string, productType: string, documentType: string) {
    return `${sourceSystem}/${programme}/${productType}/${documentType}`
  }

  downloadCSV() {
    const keys = Object.keys(this.auditHistoryToDisplay[0])
    const csvFileContent = keys.join('\t') +
      '\n' +
      this.auditHistoryToDisplay.map(audit => {
        return keys.map(k => {
          let cell = audit[k] === null || audit[k] === undefined ? '' : audit[k];
          cell = k === 'originalConfig' || k === 'updatedConfig' ? JSON.stringify(JSON.parse(cell)) : cell
          cell = cell instanceof Array ? cell.join('\t') : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join('\t');
      }).join('\n');

    const blob = new Blob([csvFileContent], {type: 'text/csv;charset=utf-8'})
    const filename = (this.rulesetMnemonic + '-' + new Date().toISOString() + '.csv')
    console.log('Saving: ' + filename)
    saveAs(blob, filename)
  }
}
