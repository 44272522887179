import {EventEmitter, Injectable, Output} from '@angular/core'
import {ConfigService} from './config.service'
import {ChangeService} from './change.service'
import {ConfigVersionService} from './config-version.service'
import {ChangeDetectingBehaviourSubject} from '../util/change-detecting-behaviour-subject'
import {combineLatest} from 'rxjs'
import {debounceTime, distinctUntilChanged} from 'rxjs/operators'
import deepEqual from 'deep-equal'
import {ToastrService} from 'ngx-toastr'
import {VersionSummary} from '../common/domain/version'
import {ChangeDetails} from '../common/domain/change'

@Injectable({
  providedIn: 'root',
})
export class ConfigMonitorService {
  @Output() configLoaded: EventEmitter<string> = new EventEmitter(true)
  @Output() configStale: EventEmitter<boolean> = new EventEmitter(true)

  private canSaveSubject = new ChangeDetectingBehaviourSubject<boolean>('Can Save Config Monitor', false)
  canSave = this.canSaveSubject.asObservable()

  private reloadEmitted = false

  constructor(
    private configService: ConfigService,
    private changeService: ChangeService,
    private configVersionService: ConfigVersionService,
    private toast: ToastrService,
  ) {
    combineLatest([
      configService.loadedVersions,
      configVersionService.deployedVersions,
      changeService.changes,
    ])
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(deepEqual),
      )
      .subscribe((
        [
          loadedVersions,
          availableVersions,
          changes,
        ]: [
          VersionSummary[],
          VersionSummary[],
          ChangeDetails[]
        ]) => {
        const versionsAreSame = loadedVersions.every(loaded => {
          return availableVersions.find(available => available.source === loaded.source && available.version == loaded.version) !== undefined
        }) && availableVersions.every(available => {
          return loadedVersions.find(loaded => available.source === loaded.source && available.version == loaded.version) !== undefined
        })
        if (this.reloadEmitted && versionsAreSame) {
          toast.success('New configs loaded')
          this.reloadEmitted = false
        }
        if (!versionsAreSame) {
          // console.log('Should Reload')
          // console.dir(loadedVersions)
          // console.dir(availableVersions)
          if (changes.some(c => c.state !== 'original')) {
            if (!this.reloadEmitted) {
              this.configStale.emit(true)
              this.reloadEmitted = true
            }
          } else {
            toast.info('Loading new config')
            this.configService.reload()
          }
        } else {
          // console.log('No Config Changes :)')
        }
        const canSave = !this.reloadEmitted && versionsAreSame
        this.canSaveSubject.next(canSave)
      })
  }
}
