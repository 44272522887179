import {Inject, Injectable} from '@angular/core'
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service'

import JSZip from 'jszip'

@Injectable({
  providedIn: 'root',
})
export class ZipStorageService {

  constructor(@Inject(LOCAL_STORAGE) private storageService: StorageService) {}

  get<T>(key: string, cacheIdentifier: string): Promise<T | undefined> {
    const updateKey = key + '/identifier'
    if (this.storageService.has(updateKey) && this.storageService.get(updateKey) === cacheIdentifier) {
      console.log('Using local storage since there is no change to the identifier')
      if (this.storageService.has(key)) {
        const b64 = this.storageService.get(key)
        const zip = new JSZip()
        return zip.loadAsync(b64, {base64: true})
          .then((res: JSZip) => {
            const zipData: JSZip.JSZipObject = res.file('data')
            return zipData.async('text')
          })
          .then((data: string) => {
            return JSON.parse(data)
          })
          .catch(e => {
            console.log('Failed to load data: ' + e)
            return undefined
          })
      }
    }
    return Promise.resolve(undefined)
  }

  set(key: string, cacheIdentifier: string, data: any): Promise<boolean> {
    const updateKey = key + '/identifier'
    const zip = new JSZip()
    zip.file('data', JSON.stringify(data))
    return zip.generateAsync({compression: 'DEFLATE', compressionOptions: {level: 9}, type: 'base64'})
      .then(data => {
        console.log('Saving ' + data.length + ' bytes to localStorage under key: ' + key)
        this.storageService.set(key, data)
        this.storageService.set(updateKey, cacheIdentifier)
        return true
      })
      .catch(e => {
        return false
      })
  }

  remove(key: string) {
    this.storageService.remove(key)
  }
}
