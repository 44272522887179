import {
  Component,
  Inject,
  OnInit,
  Optional,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { ManualDraftingAdminPayloadService } from "src/app/components/manual-drafting/utils/services/manual-drafting-administer-payload.service";
import { jsonEditorOptions} from "src/app/util/editor-configurations";
import {
  CreatePayloadRequest,
  MdPayload, UpdatePayloadRequest,
} from "../utils/models/md-payload-model";

@Component({
  selector: "app-new-payload-drafting",
  templateUrl: "./new-payload.component.html",
  styleUrls: ["./new-payload.component.scss"],
})
export class NewPayloadComponent implements OnInit {
  payloadEditorConfig = jsonEditorOptions;
  payloadValue = "";
  editPayload = false;
  payloadSubject = new BehaviorSubject<string>("");
  public newPayloadObj: MdPayload[] = [];
  public createPayloadRequest: CreatePayloadRequest[] = [];

  @Output() newPayload = new EventEmitter<MdPayload>();

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<NewPayloadComponent>,
    private administerPayloadService: ManualDraftingAdminPayloadService,

    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    console.log("this is data", this.data);
    if (this.data) {
      this.editPayload = true;
      this.newPayloadObj.push(this.data.payload[0]);
      this.payloadValue = this.newPayloadObj[0].draft_payload;
    }
  }
  refresh() {
    this.payloadSubject.next(this.payloadValue);
  }

  savePayLoad(uniqueReference?: string, description?: string) {
    if (this.newPayloadObj.length) {
      this.newPayloadObj.splice(0);
    }

    this.newPayloadObj.push({
      draft_payload: this.payloadValue,
      description: description,
      id: uniqueReference,
      status: "NEW",
    });
    if (!this.editPayload) {
      console.log("this is new payload");
      this.administerPayloadService.createNewDraftPayload(
        this.newPayloadObj[0].draft_payload,
        this.newPayloadObj[0].description
      );
    } else if (this.editPayload) {

      const request: UpdatePayloadRequest = {
        draft_payload: this.newPayloadObj[0].draft_payload,
        description: this.newPayloadObj[0].description
      }
      this.administerPayloadService.updatePayload(uniqueReference, request);
    }
    this.closeDialog();
  }
  closeDialog() {
    if (this.newPayloadObj.length) {
      this.newPayload.emit(this.newPayloadObj[0]);
      this.dialogRef.close('saved');
    } else {
      this.dialogRef.close(undefined);
    }
  }

  cancel() {
    this.dialogRef.close(undefined);
  }
}
