<div class="maintenance" *ngIf="maintenance"></div>
<ngx-spinner type="square-jelly-box"><p>Loading...</p></ngx-spinner>
<div *ngIf="!maintenance && !loading">
  <div class="mat-app-background">
    <ng-container *ngIf="!authService.isAuthenticated()">
      <mat-toolbar color="primary">
        <mat-toolbar-row>
          <button mat-icon-button>
            <fa-icon icon="user-lock"></fa-icon>
          </button>
          <span class="storm-title">{{ title }} :: {{ environment }}</span>
        </mat-toolbar-row>
      </mat-toolbar>

      <div class="password-warning">
        Password Requirements:
        <ul>
          <li>combination of symbol, uppercase, lowercase and number.</li>
        </ul>
        If you end up with a blank screen, it was a bad password - you can try
        again by refresh the screen and change your password again.
      </div>
      <div class="signin-area">
        <!-- <amplify-authenticator
          [usernameAttributes]="usernameAttributes"
          [hide]="['SignUp']"
        ></amplify-authenticator> -->
        <amplify-authenticator hideSignUp="true" [formFields]="formFields">
          <ng-template amplifySlot="sign-in-header">
            <h3
              class="amplify-heading logIn"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Log in
            </h3>
          </ng-template>
          <ng-template amplifySlot="setup-totp-header">
            <h3
              class="amplify-heading totp"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Setup authentication
            </h3>
            <p>Please scan the QR code with your authenticator app</p>
          </ng-template>
          <ng-template amplifySlot="confirm-sign-in-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Confirm authenticator code
            </h3>
            <p>Please enter the six digit code from your authenticator app</p>
          </ng-template>
          <ng-template amplifySlot="confirm-sign-up-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Please set a new password
            </h3>
          </ng-template>
          <ng-template amplifySlot="sign-up-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Please set a new password
            </h3>
          </ng-template>
          <ng-template amplifySlot="reset-password-header">
            <h3
              class="amplify-heading forgotPwd"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Password reset
            </h3>
            <p>
              Please enter your username and click 'Send Code' to reset your
              password
            </p>
          </ng-template>
          <ng-template amplifySlot="confirm-reset-password-header">
            <h3
              class="amplify-heading"
              style="
                padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
              "
            >
              Reset password
            </h3>
            <p>
              Please enter the code sent to your registered email address and set a new password
            </p>
          </ng-template>
        </amplify-authenticator>
      </div>
    </ng-container>

    <ng-container *ngIf="authService.isAuthenticated()">
      <mat-toolbar color="primary">
        <mat-toolbar-row>
          <ng-container *ngIf="!hideSkin">
            <button
              mat-icon-button
              (click)="leftsidenav.toggle(); rightsidenav.toggle()"
            >
              <ng-container *ngIf="theme === 'Storm'">
                <fa-icon
                  class="storm-icon"
                  size="lg"
                  icon="cloud-sun-rain"
                ></fa-icon>
              </ng-container>
              <ng-container *ngIf="theme === 'Aurora'">
                <fa-icon class="storm-icon" size="lg" icon="globe"></fa-icon>
              </ng-container>
              <ng-container *ngIf="theme === 'Clean'">
                <fa-icon
                  class="storm-icon"
                  size="lg"
                  icon="ellipsis-v"
                ></fa-icon>
              </ng-container>
            </button>
          </ng-container>
          <span class="storm-title">{{ title }}{{ environment }}</span>
          <span class="status-notification" *ngIf="status">
            <div
              [className]="status.type"
              fxLayout="row"
              fxLayoutAlign="left center"
              fxFill
            >
              {{ status.message }}
            </div>
            <mat-progress-bar
              mode="indeterminate"
              *ngIf="status.type === 'TASK'"
            ></mat-progress-bar>
          </span>
          <span class="toolbar-spacer"></span>
      
          <div class="amplify-greeting" *ngIf="signedIn">
            <div class="amplify-greeting-text" (click)="saveToken()">
              {{ user }}
            </div>
            <div class="amplify-greeting-flex-spacer"></div>
            <a
              class="amplify-form-link amplify-greeting-sign-out"
              (click)="authService.signOut()"
              >Sign Out</a
            >
          </div>
          <a (click)="changeTheme()" class="theme-select">
            <fa-icon size="xs" icon="palette"></fa-icon>
            {{ title }}</a
          >
        </mat-toolbar-row>
      </mat-toolbar>

      <ng-container *ngIf="!hideSkin && activeRoute">
        <mat-sidenav-container>
          <!--  Datasource Settings Sidenav -->
          <mat-sidenav
            id="rightsidenav"
            #rightsidenav
            fixedTopGap="68"
            class="right-sidenav"
            opened="true"
            mode="side"
            position="end"
            fixedInViewport="true"
            disableClose
          >
            <div>
              <ng-container *ngIf="showSourceEnvironmentSelection">
                <div class="right-sidenav-section">
                  <div class="right-header">Source Environment</div>
                  <app-source-environment-selector
                    [showStorm]="showStormInSourceEnvironment"
                  ></app-source-environment-selector>
                </div>
              </ng-container>

              <ng-container *ngIf="showTargetEnvironmentSelection">
                <div class="right-sidenav-section">
                  <div class="right-header">Target Environment</div>
                  <app-target-environment-selector></app-target-environment-selector>
                </div>
              </ng-container>

              <ng-container *ngIf="showAdminTargetEnvironmentSelection">
                <div class="right-sidenav-section">
                  <div class="right-header">Target Environment</div>
                  <app-source-environment-selector
                    [showStorm]="showStormInSourceEnvironment"
                  ></app-source-environment-selector>
                </div>
              </ng-container>

              <ng-container *ngIf="showWorkspaceSelection">
                <div class="right-sidenav-section">
                  <div class="right-header">Workspace</div>
                  <app-workspace-selector></app-workspace-selector>
                </div>
              </ng-container>

              <mat-divider
                *ngIf="
                  showWorkspaceSelection ||
                  showSourceEnvironmentSelection ||
                  showTargetEnvironmentSelection
                "
              ></mat-divider>

              <ng-container *ngIf="showTestSuiteSelection">
                <div class="right-sidenav-section">
                  <div class="right-header">Test Suite</div>
                  <app-test-suite-selector></app-test-suite-selector>
                </div>
                <mat-divider></mat-divider>
              </ng-container>

              <ng-container *ngIf="showDocgenConfigSelection">
                <div class="right-sidenav-section">
                  <div class="right-header">Configuration</div>
                  <app-docgen-config-selector
                    style="z-index: 1"
                  ></app-docgen-config-selector>
                </div>
              </ng-container>

              <ng-container *ngIf="showPublishConfigSelection">
                <div class="right-sidenav-section">
                  <div class="right-header">Routing</div>
                  <app-publish-config-selector
                    style="z-index: 1"
                  ></app-publish-config-selector>
                </div>
              </ng-container>

              <div
                class="app-version container-fluid"
                [ngStyle]="
                  activeRoute.toString().includes('generate') && {
                    position: 'relative',
                    bottom: 0
                  }
                "
              >
                <div class="row">
                  <div class="col-sm-12 app-version-header">Application</div>
                </div>
                <div class="row">
                  <div class="col-sm-4">Build:</div>
                  <div class="col-sm-8">{{ appBuildNumber }}</div>
                </div>
                <div class="row">
                  <div class="col-sm-4">Version:</div>
                  <div class="col-sm-8">{{ appVersion.substring(0, 10) }}</div>
                </div>
                <div class="row">
                  <div class="col-sm-4">Date:</div>
                  <div class="col-sm-8">{{ appBuildDateString }}</div>
                </div>
                <ng-container>
                  <div class="row">
                    <div class="col-sm-12 app-version-header">
                      Configuration
                    </div>
                  </div>
                  <ng-container *ngFor="let config of configVersions">
                    <div class="row">
                      <div class="col-sm-4">{{ config.name }}</div>
                      <div class="col-sm-3">
                        {{ config.loaded?.buildNumber }}
                      </div>
                      <div class="col-sm-5">
                        <div
                          class="pipeline-progress"
                          [ngClass]="'pipeline-' + config.pipeline?.Build.state"
                          [title]="config.pipeline?.Build.state"
                        ></div>
                        <div
                          class="pipeline-progress"
                          [ngClass]="'pipeline-' + config.pipeline?.Tools.state"
                          [title]="config.pipeline?.Tools.state"
                        ></div>
                        <div
                          class="pipeline-progress"
                          [ngClass]="'pipeline-' + config.pipeline?.Dev.state"
                          [title]="config.pipeline?.Dev.state"
                        ></div>
                        <div
                          class="pipeline-progress"
                          [ngClass]="'pipeline-' + config.pipeline?.Uat.state"
                          [title]="config.pipeline?.Uat.state"
                        ></div>
                        <div
                          class="pipeline-progress"
                          [ngClass]="'pipeline-' + config.pipeline?.Prod.state"
                          [title]="config.pipeline?.Prod.state"
                        ></div>
                      </div>
                    </div>
                  </ng-container>
                  <!--                <ng-container-->
                  <!--                  *ngIf="appConfigStatus === 'OK'">-->
                  <!--                  Version: {{appConfigVersion.substring(0, 10)}}<br/>-->
                  <!--                  Build: {{appConfigBuildNumber}}<br/>-->
                  <!--                </ng-container>-->
                  <!--                <ng-container *ngIf="appConfigStatus !== 'OK'">Status: {{appConfigStatus}}</ng-container>-->
                </ng-container>
              </div>
            </div>
          </mat-sidenav>

          <!-- Navigation Sidenav -->
          <mat-sidenav
            id="leftsidenav"
            #leftsidenav
            mode="side"
            opened="true"
            fixedTopGap="68"
            fixedInViewport="true"
            disableClose
          >
            <mat-nav-list>
              <ng-container *ngIf="!playgroundExcluded() && hasPlaygroundPermission">
                <a mat-list-item (click)="togglePlayground()">
                  <fa-icon icon="seedling"></fa-icon>
                  Playground
                </a>

                <ng-container *ngIf="playgroundExpanded">
                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/playground/listing"
                  >
                    <fa-icon icon="filter"></fa-icon>
                    Italian Listing
                  </a>

                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/playground/upload"
                  >
                    <fa-icon icon="filter"></fa-icon>
                    Italian Listing Upload
                  </a>

                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/playground/chart"
                  >
                    <fa-icon icon="filter"></fa-icon>
                    Chart Layout
                  </a>
                </ng-container>
                <mat-divider></mat-divider>
              </ng-container>

              <a mat-list-item routerLinkActive routerLink="/dashboard"  *ngIf="!dashboardExcluded() && hasDashboardPermission">
                <fa-icon icon="tachometer-alt"></fa-icon>
                Dashboard
              </a>

              <mat-divider></mat-divider>

              <ng-container *ngIf="!configExcluded() && hasConfigPermission">
                <a mat-list-item (click)="toggleConfig()">
                  <fa-icon icon="dice-d6"></fa-icon>
                  Config
                </a>

                <ng-container *ngIf="configExpanded">
                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/config/filter"
                  >
                    <fa-icon icon="filter"></fa-icon>
                    Filter
                  </a>

                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/config/schemas"
                  >
                    <fa-icon icon="clipboard-check"></fa-icon>
                    Schemas
                  </a>

                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/config/transforms"
                  >
                    <fa-icon icon="random"></fa-icon>
                    Transforms
                  </a>

                  <ng-container>
                    <a
                      mat-list-item
                      class="sub-menu"
                      (click)="toggleMappings()"
                    >
                      <fa-icon icon="map"></fa-icon>
                      Mappings
                    </a>
                    <ng-container *ngIf="configMappingsExpanded">
                      <ng-container>
                        <a
                          mat-list-item
                          class="mappings-sub-menu"
                          routerLinkActive
                          routerLink="/config/mappings/static"
                        >
                          <fa-icon icon="map"></fa-icon>
                          Static
                        </a>
                      </ng-container>

                      <ng-container *ngIf="canEditMappings">
                        <a
                          mat-list-item
                          class="mappings-sub-menu"
                          routerLinkActive
                          routerLink="/config/mappings/dynamic"
                        >
                          <fa-icon icon="map"></fa-icon>
                          Dynamic
                        </a>
                      </ng-container>
                    </ng-container>
                  </ng-container>

                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/config/targets"
                  >
                    <fa-icon icon="road"></fa-icon>
                    Targets
                  </a>

                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/config/samples"
                  >
                    <fa-icon icon="eye-dropper"></fa-icon>
                    Samples
                  </a>

                  <a
                    *ngIf="canEditStaticData || readOnly"
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/config/static"
                  >
                    <fa-icon icon="coins"></fa-icon>
                    Static Data
                  </a>

                  <!-- <a
                     mat-list-item
                     class="sub-menu"
                     routerLinkActive
                     routerLink="/config/rules">
                    <fa-icon icon="crosshairs"></fa-icon>
                    Rules
                  </a> -->

                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/config/rulesets"
                  >
                    <fa-icon icon="crosshairs"></fa-icon>
                    RuleSets
                  </a>
                  <ng-container *ngIf="canEditPreprocessorLogic">
                    <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/config/preprocessor"
                    >
                    <fa-icon icon="code"></fa-icon>
                    Preprocessor-Logic
                    </a>
                  </ng-container>

                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/config/approvals"
                  >
                    <fa-icon icon="thumbs-up"></fa-icon>
                    Approvals
                  </a>
                </ng-container>

                <mat-divider></mat-divider>
              </ng-container>

              <ng-container  *ngIf="!inspectExcluded() && hasInspectPermission">
                <a mat-list-item routerLinkActive routerLink="/inspect">
                  <fa-icon icon="binoculars"></fa-icon>
                  Inspect
                </a>
              </ng-container>

              <a mat-list-item routerLinkActive routerLink="/generate" *ngIf="!generateExcluded() && hasGeneratePermission">
                <fa-icon icon="play"></fa-icon>
                Generate
              </a>

              <a mat-list-item routerLinkActive routerLink="/publish" *ngIf="!publishExcluded() && hasPublishPermission">
                <fa-icon icon="paper-plane"></fa-icon>
                Publish
              </a>

              <!--            <ng-container>-->
              <!--              <a mat-list-item-->
              <!--                 routerLinkActive-->
              <!--                 routerLink="/product/governance">-->
              <!--                <fa-icon icon="ribbon"></fa-icon>-->
              <!--                Governance-->
              <!--              </a>-->
              <!--            </ng-container>-->

              <mat-divider></mat-divider>

              <a mat-list-item (click)="toggleTests()" *ngIf="!testsExcluded() && hasTestsPermission">
                <fa-icon icon="vial"></fa-icon>
                Tests
              </a>

              <ng-container *ngIf="testsExpanded && hasTestsPermission">
                <a
                  mat-list-item
                  class="sub-menu"
                  routerLinkActive
                  routerLink="/tests"
                >
                  <fa-icon icon="magic"></fa-icon>
                  Executions
                </a>

                <a
                  mat-list-item
                  class="sub-menu"
                  routerLinkActive
                  routerLink="/suites"
                >
                  <fa-icon icon="vials"></fa-icon>
                  Suites
                </a>

                <ng-container *ngIf="isTestManager || readOnly">
                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/migrate"
                  >
                    <fa-icon icon="dna"></fa-icon>
                    Migrate
                  </a>

                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/clone"
                  >
                    <fa-icon icon="clone"></fa-icon>
                    Clone
                  </a>

                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/tests/cleanup"
                  >
                    <fa-icon icon="broom"></fa-icon>
                    Cleanup
                  </a>


                  <a
                    mat-list-item
                    class="sub-menu"
                    routerLinkActive
                    routerLink="/archive-tests"
                  >
                    <fa-icon icon="archive"></fa-icon>
                    Archive Tests
                  </a>
                </ng-container>
              </ng-container>

              <mat-divider></mat-divider>

              <ng-container *ngIf="!toolsExcluded() && hasToolsPermission">
                <a mat-list-item (click)="toggleTools()">
                  <fa-icon icon="tools"></fa-icon>
                  Tools</a
                >

                <ng-container *ngIf="toolsExpanded">
                  <ng-container>
                    <a
                      mat-list-item
                      class="sub-menu"
                      routerLinkActive
                      routerLink="/tools/jmes"
                    >
                      <fa-icon icon="tasks"></fa-icon>
                      JMES Path Evaluator
                    </a>
                  </ng-container>
                </ng-container>
              </ng-container>

              <mat-divider></mat-divider>

              <!-- Manual drafting sub-menu -->

               <ng-container *ngIf="!manualDraftingExcluded() && hasManualDraftingPermission">
                <a mat-list-item (click)="toggleManualDrafting()">
                  <fa-icon icon="edit"></fa-icon>
                  Manual Drafting</a
                >

                <ng-container *ngIf="savePayloadExpanded">
                  <ng-container>
                    <a
                      mat-list-item
                      class="sub-menu"
                      routerLinkActive
                      routerLink="/manual-drafting/payloads"
                    >
                      <fa-icon icon="tasks"></fa-icon>
                      Payloads
                    </a>
                  </ng-container>

                 <ng-container>
                    <a
                      mat-list-item
                      class="sub-menu"
                      routerLinkActive
                      routerLink="/manual-drafting/validation"
                    >
                      <fa-icon icon="tasks"></fa-icon>
                      Validations
                    </a>
                  </ng-container>

                  <ng-container>
                    <a
                      mat-list-item
                      class="sub-menu"
                      routerLinkActive
                      routerLink="/manual-drafting/enrich"
                    >
                      <fa-icon icon="tasks"></fa-icon>
                      Transformations
                    </a>
                  </ng-container>
                </ng-container>
              </ng-container>

              <mat-divider></mat-divider>

              <ng-container *ngIf="!adminExcluded() && hasAdminPermission">
                <a mat-list-item (click)="toggleAdmin()">
                  <fa-icon icon="tools"></fa-icon>
                  Admin</a
                >

                <ng-container *ngIf="adminExpanded">
                  <ng-container>
                    <a
                      mat-list-item
                      class="sub-menu"
                      routerLinkActive
                      routerLink="/approvals/docgen"
                    >
                      <fa-icon icon="tasks"></fa-icon>
                      DocGen Approvals
                    </a>
                  </ng-container>

                  <ng-container>
                    <a
                      mat-list-item
                      class="sub-menu"
                      routerLinkActive
                      routerLink="/approvals/routing"
                    >
                      <fa-icon icon="road"></fa-icon>
                      Routing Approvals
                    </a>
                  </ng-container>

                  <ng-container>
                    <a
                      mat-list-item
                      class="sub-menu"
                      routerLinkActive
                      routerLink="/admin/mi"
                    >
                      <fa-icon icon="chart-line"></fa-icon>
                      MI
                    </a>
                  </ng-container>

                  <ng-container *ngIf="canRestartSmartDx || readOnly">
                    <a
                      mat-list-item
                      class="sub-menu"
                      routerLinkActive
                      routerLink="/admin/smartdx"
                    >
                      <fa-icon icon="file-pdf"></fa-icon>
                      Smart DX
                    </a>
                  </ng-container>

                  <ng-container>
                    <a
                      mat-list-item
                      class="sub-menu"
                      (click)="toggleRegxchange()"
                    >
                      <fa-icon icon="tools"></fa-icon>
                      Regxchange</a
                    >

                    <ng-container *ngIf="adminRegxchangeExpanded">
                      <ng-container>
                        <a
                          mat-list-item
                          class="regxchange-sub-menu"
                          routerLinkActive
                          routerLink="/admin/regxchange/post"
                        >
                          <fa-icon icon="file-pdf"></fa-icon>
                          Post to Regxchange
                        </a>
                      </ng-container>

                      <ng-container>
                        <a
                          mat-list-item
                          class="regxchange-sub-menu"
                          routerLinkActive
                          routerLink="/admin/regxchange/get"
                        >
                          <fa-icon icon="file-pdf"></fa-icon>
                          Get from Regxchange
                        </a>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="hasWallStreetDocsPermission">
                    <a
                      mat-list-item
                      class="sub-menu"
                      routerLinkActive
                      routerLink="/admin/wsd-manual-post"
                    >
                      <fa-icon icon="file-pdf"></fa-icon>
                      WallStreetDocs
                    </a>
                  </ng-container>
                </ng-container>
              </ng-container>
            </mat-nav-list>
          </mat-sidenav>

          <mat-sidenav-content>
            <div *ngIf="banner" class="container-fluid well-sm banner">
              <fa-icon icon="hat-cowboy-side"></fa-icon>
              {{ banner }}
            </div>
            <div id="main-content">
              <router-outlet></router-outlet>
            </div>
          </mat-sidenav-content>
        </mat-sidenav-container>
      </ng-container>

      <ng-container *ngIf="hideSkin && !navigating">
        <div class="container">
          <router-outlet></router-outlet>
        </div>
        <div class="app-version container-fluid">
          <div class="row">
            <div class="col-sm-12 app-version-header">Application</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Build:</div>
            <div class="col-sm-8">{{ appBuildNumber }}</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Version:</div>
            <div class="col-sm-8">{{ appVersion.substring(0, 10) }}</div>
          </div>
          <div class="row">
            <div class="col-sm-4">Date:</div>
            <div class="col-sm-8">{{ appBuildDateString }}</div>
          </div>
          <ng-container>
            <div class="row">
              <div class="col-sm-12 app-version-header">Configuration</div>
            </div>
            <ng-container *ngFor="let config of configVersions">
              <div class="row">
                <div class="col-sm-4">{{ config.name }}</div>
                <div class="col-sm-3">{{ config.loaded?.buildNumber }}</div>
                <div class="col-sm-5">
                  <div
                    class="pipeline-progress"
                    [ngClass]="'pipeline-' + config.pipeline?.Build.state"
                    [title]="config.pipeline?.Build.state"
                  ></div>
                  <div
                    class="pipeline-progress"
                    [ngClass]="'pipeline-' + config.pipeline?.Tools.state"
                    [title]="config.pipeline?.Tools.state"
                  ></div>
                  <div
                    class="pipeline-progress"
                    [ngClass]="'pipeline-' + config.pipeline?.Dev.state"
                    [title]="config.pipeline?.Dev.state"
                  ></div>
                  <div
                    class="pipeline-progress"
                    [ngClass]="'pipeline-' + config.pipeline?.Uat.state"
                    [title]="config.pipeline?.Uat.state"
                  ></div>
                  <div
                    class="pipeline-progress"
                    [ngClass]="'pipeline-' + config.pipeline?.Prod.state"
                    [title]="config.pipeline?.Prod.state"
                  ></div>
                </div>
              </div>
            </ng-container>
            <!--                <ng-container-->
            <!--                  *ngIf="appConfigStatus === 'OK'">-->
            <!--                  Version: {{appConfigVersion.substring(0, 10)}}<br/>-->
            <!--                  Build: {{appConfigBuildNumber}}<br/>-->
            <!--                </ng-container>-->
            <!--                <ng-container *ngIf="appConfigStatus !== 'OK'">Status: {{appConfigStatus}}</ng-container>-->
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
