<form [formGroup]="simpleRulesForm" (ngSubmit)="submit()">
  <!-- input fields section -->
  <fieldset class="form-group">
    <div *ngIf="simpleRule" class="form-group row">
      <label for="code" class="col-sm-3 col-form-label"> Code: </label>
      <div class="col-sm-4">
        <input
          formControlName="id"
          id="code"
          class="form-control form-control-sm "
          readonly
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="description" class="col-sm-3 col-form-label">
        Description:
      </label>
      <div class="col-sm-8">
        <input
          formControlName="description"
          id="description"
          class="form-control form-control-sm "
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="jmesPath" class="col-sm-3 col-form-label"> JMES Path: </label>
      <div class="col-sm-8">
        <input
                formControlName="jmespath"
                id="jmesPath"
                class="form-control form-control-sm "
              />
      </div>
      
    </div>

    <div class="form-group row">
      <label for="calculation" class="col-sm-3 col-form-label">
        Calculation:
      </label>
      <div class="col-sm-8">
        <input
          formControlName="calculation"
          id="calculation"
          class="form-control form-control-sm "
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="failureMessage" class="col-sm-3 col-form-label">
        Failure Message:
      </label>
      <div class="col-sm-8">
        <input
          formControlName="failmessage"
          id="failureMessage"
          class="form-control form-control-sm "
        />
      </div>
    </div>
  </fieldset>


  <!--action buttons section -->
  <div class="form-row justify-content-end">
    <button class="btn btn-secondary me-2" (click)="closeModal()">
      Close
    </button>

    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="!simpleRulesForm.valid"
    >
      Save
    </button>
  </div>
</form>
