import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {ToastrService} from 'ngx-toastr'
import {combineLatest, Subscription} from 'rxjs'
import {AlertDialogComponent} from 'src/app/dialogs/alert-dialog/alert-dialog.component'
import {PublishConfigFailedResponse, PublishConfigResponse} from 'src/app/model/config-responses'
import {PermissionsService} from 'src/app/services/permissions.service'
import {ChangeDetails} from '../../common/domain/change'
import {JiraIssueDialogComponent} from '../../dialogs/jira-issue-dialog/jira-issue-dialog.component'
import {ChangeService} from '../../services/change.service'
import {ConfigMonitorService} from '../../services/config-monitor.service'
import {ConfigVersionService} from '../../services/config-version.service'
import {ConfigService} from '../../services/config.service'
import {StatusService} from '../../services/status.service'

@Component({
  selector: 'app-config-publish',
  templateUrl: './config-publish.component.html',
  styleUrls: ['./config-publish.component.scss'],
})
export class ConfigPublishComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription()

  private readonly _PUBLISHING_STATUS_MESSAGE = 'Publishing configuration'
  canPublish = false
  private _isDeveloper = true // Set to correct value in ngOnInit

  @Output() onPublish: EventEmitter<ChangeDetails[]> = new EventEmitter()

  constructor(
    private statusService: StatusService,
    private toast: ToastrService,
    private configService: ConfigService,
    private configVersionService: ConfigVersionService,
    private configMonitorService: ConfigMonitorService,
    private permissionsService: PermissionsService,
    private changeService: ChangeService,
    private dialog: MatDialog,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.permissionsService.developer.subscribe(
      (permission: boolean) => {
        this._isDeveloper = permission
        console.info('_isDeveloper: ' + this._isDeveloper)
      })

    combineLatest([this.configMonitorService.canSave, this.changeService.changes])
      .subscribe(([canSave, changes]) => {
        this.canPublish = canSave && changes.some(c => c.state !== 'original')
      })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  publishChangesToRepository() {
    const editedConfigChange = this.changeService.getChanges()

    if (editedConfigChange.length === 0) {
      this.toast.warning('Nothing to publish...')
      return
    }

    this._processFilesToPublish(editedConfigChange)
  }

  private async _processFilesToPublish(changes: ChangeDetails[]) { // data: GitPublishDialogData) {
    if (changes.length === 0) {
      this.toast.warning('No files selected...')
      return
    }

    if (changes.filter(file => file.state !== 'toDelete').map(file => file.content).some(content => !content)) {
      this.toast.error('Each file must have content...')
      return
    }

    const statusId = this.statusService.start(this._PUBLISHING_STATUS_MESSAGE)
    const dialog = this.modalService.open(JiraIssueDialogComponent)
    dialog.result
      .then((res: {jiraIssue: string, description: string}) => {
        this._processJiraDialogsData(res.jiraIssue, res.description, changes, statusId)
      })
      .catch(_ => {
        this.toast.warning('Publish cancelled')
        this.statusService.clear(this._PUBLISHING_STATUS_MESSAGE)
      })
  }

  private async _processJiraDialogsData(jiraIssue: string, description: string, changes: ChangeDetails[], statusId: string) {
    // Ensure Developers cannot Publish
    if (this._isDeveloper) {
      console.info('Publish Disabled. You would have published:')
      console.dir(changes)
      this.statusService.clear(this._PUBLISHING_STATUS_MESSAGE)

      await this.dialog.open(AlertDialogComponent, {
        position: {top: '200px'},
        data: {
          title: '== Publish Disabled ==',
          message: 'Developer logins cannot publish. Browser Console Logs contains what you would have published.',
        },
      }).afterClosed().toPromise();

      return
    }

    this.configService.publishChangesToRepositories(jiraIssue + ' ' + description, changes)
      .subscribe((result: PublishConfigResponse[]) => {

        this.statusService.complete(statusId)
        this.configVersionService.reload()
        if (result.every(res => res.status === 'OK')) {
          // this.changeService.clearChanges()
          this.statusService.clear(this._PUBLISHING_STATUS_MESSAGE)
          this.statusService.start('Configuration published', 'ALERT', 5)
          this.toast.success('Files committed to Repository', 'Published Successfully')
          this.onPublish.emit(changes)
        } else {
          this.statusService.start('FAILED TO PUBLISH!', 'ALERT', 30)
          this.toast.error(result
            .filter(res => res.status !== 'OK' && res.message)
            .map((res: PublishConfigFailedResponse) => res.source + ': ' + res.message)
            .join(''), 'PUBLISH FAILED')
        }
      })
  }

}
