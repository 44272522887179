import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {
  DeleteSeriesResponse,
  DeleteTestResponse,
  DeleteTestsResponse,
  DeleteTestSuiteGroupResponse,
  ExecuteTestSuiteGroupResponse,
  ExecuteTestSuiteResponse,
  GetArchiveTestsResponseArray,
  GetRecommendedTestsResponse,
  GetSeriesResponse,
  GetTestHistoryResponse,
  GetTestResponse,
  GetTestsResponse,
  GetTestSuiteExecutionHistoryResponse,
  GetTestSuiteGroupResponse,
  GetTestSuiteGroupsResponse,
  GetTestSuiteResponse,
  GetTestSuitesResponse,
  GetTestsUpdatedResponse,
  SavedTestMetadata,
  SaveSeriesResponse,
  SaveTestResponse,
  SaveTestSuiteGroupResponse,
  SaveTestSuiteResponse,
  SeriesMetadata,
  TestExecutionDetailResponse,
  TestSuiteGroupMetadata,
  TestSuiteMetadata,
} from '../model/test-management-responses'
import {
  AssignBaselineRequest,
  CloneTestSuiteRequest,
  DeleteSeriesRequest,
  DeleteTestRequest,
  DeleteTestsRequest,
  DeleteTestSuiteExecutionRequest,
  DeleteTestSuiteGroupRequest,
  DeleteTestSuiteRequest,
  ExecuteTestSuiteGroupRequest,
  ExecuteTestSuiteRequest,
  GenerateIndexDisclaimerTestSuiteRequest,
  GetAllArchiveTestsRequest,
  GetRecommendedTestsRequest,
  GetSeriesRequest,
  GetTestExecutionDetailRequest,
  GetTestHistoryRequest,
  GetTestPackRequest,
  GetTestRequest,
  GetTestsRequest,
  GetTestSuiteExecutionHistoryRequest,
  GetTestSuiteExecutionStatusRequest,
  GetTestSuiteGroupsRequest,
  GetTestSuiteRequest,
  GetTestSuitesRequest,
  GetTestsUpdatedRequest,
  MigrateBatchRequest,
  MigrateTestSuiteRequest,
  RerunTestRequest,
  RestoreTestsFromArchiveRequest,
  SaveSeriesRequest,
  SaveTestRequest,
  SaveTestSuiteGroupRequest,
  SaveTestSuiteRequest,
} from '../model/test-management-requests'
import {StatusService} from './status.service'
import {Environment, EnvironmentService, Region} from './environment-service'
import {SeriesDefinition, TestDefinition, TestPackReference, TestSuiteDefinition, TestSuiteGroupDefinition} from '../common/domain/tests'
import {Index} from '../common/domain/static'
import {catchError, distinctUntilChanged, map, shareReplay, switchMap, tap} from 'rxjs/operators'
import {BehaviorSubject, combineLatest, merge, Observable, of, timer} from 'rxjs'
import deepEqual from 'deep-equal'
import {ToastrService} from 'ngx-toastr'
import {PermissionsService} from './permissions.service'
import {unzipIfNecessary} from '../util/trace-util'
import {ZipStorageService} from './zip-storage.service'

@Injectable({
  providedIn: 'root',
})
export class TestManagerService {
  private serviceSubject = new BehaviorSubject<string>(undefined)
  private testExecutionSubject = new BehaviorSubject<number>(undefined)
  private includeProdCloneSubject = new BehaviorSubject<boolean>(false)
  private reload$ = new BehaviorSubject(false)
  private refresh$ = timer(0, 30000)

  includeProdClone = this.includeProdCloneSubject.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  )

  service = this.serviceSubject.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  )
  recommendedTests: Observable<GetRecommendedTestsResponse[]> = this.reload$
    .pipe(
      switchMap(_ => {
        const request: GetRecommendedTestsRequest = {
          action: 'GetRecommendedTests',
          alternatives: true,
        }

        return this.apiService.post<GetRecommendedTestsRequest, GetRecommendedTestsResponse[]>('/tests/',
          request,
          {
            headers: {},
          })
          .toPromise()
      }),
      map(response => response),
    ).pipe(
      distinctUntilChanged(deepEqual),
      shareReplay(1),
    )

  testsUpdated = merge(this.refresh$, this.reload$).pipe(
    tap(_ => console.log('Checking if tests needs to be reloaded')),
    switchMap(_ => {
      return this.apiService.post<GetTestsUpdatedRequest, GetTestsUpdatedResponse>('/tests/', {
        action: 'GetTestsUpdated',
      })
    }),
    map(r => r.updated),
    distinctUntilChanged(deepEqual),
    tap(_ => console.log('Something changed - triggering reload of tests if necessary')),
    shareReplay(1),
  )

  tests: Observable<SavedTestMetadata[]> = combineLatest([
    this.includeProdClone,
    this.service,
    this.testsUpdated,
  ]).pipe(
    switchMap(([
                 prodclone,
                 service,
                 updated]) => {
      const cacheKey = 'storm/tests/' + service + '/' + prodclone
      try {
        return this.zipStorageService.get(cacheKey, updated)
          .then(existing => {
            return [prodclone, service, updated, existing]
          })
      } catch (e) {
        console.log('Failed to get tests from localStorage: ' + e)
      }
      return of([prodclone, service, updated, undefined])
    }),
    switchMap(([
                 prodclone,
                 service,
                 updated,
                 existing]) => {
      if (existing) {
        console.log('Returning tests from local storage')
        // we already loaded the tests
        this.toast.success('Loaded ' + existing.tests.length + ' tests.')
        return of(existing)
      }
      const request: GetTestsRequest = {
        action: 'GetTests',
        prodclone: prodclone,
        service: service,
      }
      this.statusService.start('Loading tests.')
      const res: Observable<GetTestsResponse> = this.apiService.post<GetTestsRequest, GetTestsResponse>('/tests/',
        request,
        {
          headers: {},
        })
        .pipe(
          tap(tests => {
            this.statusService.clear('Loading tests.')
            this.toast.success('Loaded ' + tests.tests.length + ' tests.')
            console.log('Saving tests to local storage')
            console.dir(tests)
            try {
              const cacheKey = 'storm/tests/' + service + '/' + prodclone
              this.zipStorageService.set(cacheKey, updated, tests)
                .then(response => {
                  console.log('Saved tests to local storage - ' + response)
                })
                .catch(e => {
                  console.log('Failed to save tests to local storage: ' + e)
                })
            } catch (e) {
              console.log('Failed to save tests to local storage: ' + e)
            }
          }),
        )
      return res
    }),
    tap(r => {
      console.log('Tests Have Been Loaded')
      console.dir(r)
    }),
    map(response => response.tests),
    catchError((error) => {
      this.statusService.alert('Failed to load tests.')
      console.dir(error)
      return of([])
    }),
  ).pipe(
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  private testSelectionClearedSubject = new BehaviorSubject<boolean>(true)
  testSelectionCleared = this.testSelectionClearedSubject.pipe(
    switchMap(cleared => {
      return of(cleared)
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  private selectedTestNameSubject = new BehaviorSubject<string>(undefined)
  selectedTestName = this.selectedTestNameSubject.pipe(
    switchMap(testName => {
      return testName ? of(testName) : of(undefined)
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  selectedTest: Observable<TestDefinition> = this.selectedTestName.pipe(
    switchMap(testName => {
      return testName ? this.getTest(testName) : of({})
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  selectedTestHistory: Observable<GetTestHistoryResponse> = this.selectedTestName.pipe(
    switchMap(testName => {
      return testName ? this.getTestHistory(testName) : of({})
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  testSuites: Observable<TestSuiteMetadata[]> = combineLatest([
    this.includeProdClone,
    this.reload$,
  ]).pipe(
    switchMap(([prodclone, reload]) => {
      const request: GetTestSuitesRequest = {
        action: 'GetTestSuites',
        bustCache: reload,
      }
      const res = this.apiService.post<GetTestSuitesRequest, GetTestSuitesResponse>('/tests/',
        request,
        {
          headers: {},
        })
      return res
    }),
    map(response => response.testSuites),
  ).pipe(
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  series: Observable<SeriesMetadata[]> = combineLatest([
    this.includeProdClone,
    this.reload$,
  ]).pipe(
    switchMap(([prodclone, reload]) => {
      const request: GetSeriesRequest = {
        action: 'GetSeries',
        bustCache: reload,
      }
      const res = this.apiService.post<GetSeriesRequest, GetSeriesResponse>('/tests/',
        request,
        {
          headers: {},
        })
      return res
    }),
    map(response => response.series),
  ).pipe(
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  testSuiteGroups: Observable<TestSuiteGroupMetadata[]> = combineLatest([
    this.includeProdClone,
    this.reload$,
  ]).pipe(
    switchMap(([prodclone, reload]) => {
      const request: GetTestSuiteGroupsRequest = {
        action: 'GetTestSuiteGroups',
        bustCache: reload,
      }
      const res = this.apiService.post<GetTestSuiteGroupsRequest, GetTestSuiteGroupsResponse>('/tests/',
        request,
        {
          headers: {},
        })
      return res
    }),
    map(response => response.testSuiteGroups),
  ).pipe(
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  testExecution: Observable<number> = this.testExecutionSubject.pipe(
    distinctUntilChanged(),
    shareReplay(1),
  )

  private selectedTestSuiteSubject = new BehaviorSubject<GetTestSuiteResponse>(undefined)
  selectedTestSuite: Observable<GetTestSuiteResponse> = this.selectedTestSuiteSubject.pipe(
    switchMap(testSuite => {
      return testSuite ? of(testSuite) : of(undefined)
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  private selectedTestSuiteGroupSubject = new BehaviorSubject<GetTestSuiteGroupResponse>(undefined)
  selectedTestSuiteGroup: Observable<GetTestSuiteGroupResponse> = this.selectedTestSuiteGroupSubject.pipe(
    switchMap(testSuite => {
      return testSuite ? of(testSuite) : of(undefined)
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  private selectedSeriesSubject = new BehaviorSubject<GetSeriesResponse>(undefined)
  selectedSeries: Observable<GetSeriesResponse> = this.selectedSeriesSubject.pipe(
    switchMap(series => {
      return series ? of(series) : of(undefined)
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  private environment: Environment
  private region: Region
  private readOnly = true

  constructor(
    private apiService: ApiService,
    private environmentService: EnvironmentService,
    private statusService: StatusService,
    private toast: ToastrService,
    private permissionsService: PermissionsService,
    private zipStorageService: ZipStorageService,
  ) {
    this.environmentService.targetEnvironment.subscribe(environment => this.environment = environment)
    this.environmentService.region.subscribe(region => this.region = region)
    permissionsService.readonly.subscribe(permission => this.readOnly = permission)
  }

  private reload() {
    this.reload$.next(true)
  }

  saveTest(test: TestDefinition): Promise<SaveTestResponse> {
    if (this.readOnly) {
      this.toast.info('Test would have been saved')
      return
    }
    const id = this.statusService.start('Saving test...')
    const request: SaveTestRequest = Object.assign({
      action: 'SaveTest',
    }, test) as SaveTestRequest
    return this.apiService.post('/tests/',
      request,
      {
        headers: {},
      })
      .toPromise()
      .then(res => {
        return res as SaveTestResponse
      }).finally(() => {
        this.statusService.complete(id)
        this.reload()
      })
  }

  saveTestSuite(testSuite: TestSuiteDefinition): Promise<SaveTestSuiteResponse> {
    if (this.readOnly) {
      this.toast.info('Test suite would have been saved')
      return
    }
    const id = this.statusService.start('Saving test...')
    const request: SaveTestSuiteRequest = Object.assign({
      action: 'SaveTestSuite',
    }, testSuite) as SaveTestSuiteRequest
    return this.apiService.post('/tests/',
      request,
      {
        headers: {},
      })
      .toPromise()
      .then(res => {
        return res as SaveTestSuiteResponse
      }).finally(() => {
        this.statusService.complete(id)
        this.reload()
      })
  }

  saveSeries(series: SeriesDefinition): Promise<SaveSeriesResponse> {
    if (this.readOnly) {
      this.toast.info('Series would have been saved')
      return
    }
    const id = this.statusService.start('Saving series...')
    const request: SaveSeriesRequest = Object.assign({
      action: 'SaveSeries',
    }, series) as SaveSeriesRequest
    return this.apiService.post('/tests/',
      request,
      {
        headers: {},
      })
      .toPromise()
      .then(res => {
        return res as SaveSeriesResponse
      }).finally(() => {
        this.statusService.complete(id)
        this.reload()
      })
  }

  saveTestSuiteGroup(testSuiteGroup: TestSuiteGroupDefinition): Promise<SaveTestSuiteGroupResponse> {
    if (this.readOnly) {
      this.toast.info('Test suite group would have been saved')
      return
    }
    const id = this.statusService.start('Saving test suite group...')
    const request: SaveTestSuiteGroupRequest = Object.assign({
      action: 'SaveTestSuiteGroup',
    }, testSuiteGroup) as SaveTestSuiteGroupRequest
    return this.apiService.post('/tests/',
      request,
      {
        headers: {},
      })
      .toPromise()
      .then(res => {
        return res as SaveTestSuiteGroupResponse
      }).finally(() => {
        this.statusService.complete(id)
        this.reload()
      })
  }

  deleteTestSuiteGroup(testSuiteGroup: TestSuiteGroupDefinition): Promise<DeleteTestSuiteGroupResponse> {
    if (this.readOnly) {
      this.toast.info('Test suite group would have been deleted')
      return
    }
    const id = this.statusService.start('Deleting test suite group...')
    const request: DeleteTestSuiteGroupRequest = Object.assign({
      action: 'DeleteTestSuiteGroup',
    }, testSuiteGroup) as DeleteTestSuiteGroupRequest
    return this.apiService.post('/tests/',
      request,
      {
        headers: {},
      })
      .toPromise()
      .then(res => {
        return res as DeleteTestSuiteGroupResponse
      }).finally(() => {
        this.statusService.complete(id)
        this.reload()
      })
  }

  deleteSeries(seriesId: string) {
    if (this.readOnly) {
      this.toast.warning('Would have deleted this series')
      return
    }

    if (seriesId) {
      const id = this.statusService.start('Deleting series')
      const request: DeleteSeriesRequest = {
        action: 'DeleteSeries',
        name: seriesId,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
        .then(res => {
          return res as DeleteSeriesResponse
        }).finally(() => {
          this.statusService.complete(id)
          this.reload()
        })
    }
  }

  getTest(name: string, version?: string) {
    const request: GetTestRequest = {
      action: 'GetTest',
      name: name.trim(),
      version,
    }
    return this.apiService.post<GetTestRequest, GetTestResponse>('/tests/',
      request,
      {
        headers: {},
      })
  }

  getTestHistory(name: string) {
    const request: GetTestHistoryRequest = {
      action: 'GetTestHistory',
      name: name.trim(),
    }
    return this.apiService.post<GetTestHistoryRequest, GetTestHistoryResponse>('/tests/',
      request,
      {
        headers: {},
      })
  }

  getTestSuite(name: string) {
    const request: GetTestSuiteRequest = {
      action: 'GetTestSuite',
      name,
    }
    return this.apiService.post('/tests/',
      request,
      {
        headers: {},
      })
      .toPromise()
      .then(res => {
        return res as GetTestSuiteResponse
      })
  }

  selectTest(testName: string, source: string) {
    console.log('Selected test ' + testName + ' - ' + source)
    this.selectedTestNameSubject.next(testName)
  }

  selectTestSuite(testSuiteName: string) {
    if (testSuiteName) {
      this.getTestSuite(testSuiteName).then(res => {
        res.name = testSuiteName
        this.selectedTestSuiteSubject.next(res)
      })
    } else {
      this.selectedTestSuiteSubject.next(undefined)
    }
  }

  setFilterCleared(clear: boolean) {
    this.testSelectionClearedSubject.next(clear);
  }

  deleteTest(testName: string) {
    if (this.readOnly) {
      this.toast.warning('Test would have been deleted')
      return
    }
    const id = this.statusService.start('Deleting test...')
    if (testName) {
      const request: DeleteTestRequest = {
        action: 'DeleteTest',
        name: testName,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
        .then(res => {
          this.statusService.complete(id)
          this.reload()
          return res as DeleteTestsResponse
        })
    }
  }

  executeTestSuiteGroup(testSuiteGroupName: string, workspace?: string, documentFormatType?: string) {

    if (this.readOnly) {
      this.toast.info('Would have executed the test suite group')
      return
    }
    const id = this.statusService.start('Executing test suite group...')
    if (testSuiteGroupName) {
      const request: ExecuteTestSuiteGroupRequest = {
        action: 'ExecuteTestSuiteGroup',
        name: testSuiteGroupName,
        region: this.region.id,
        environment: this.environment.id,
        workspace,
        format: documentFormatType,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
        .then(res => {
          return res as ExecuteTestSuiteGroupResponse
        }).finally(() => {
          this.testExecutionSubject.next(new Date().getTime())
          this.statusService.complete(id)
        })
    }
  }

  executeTestSuite(testSuiteName: string, workspace?: string, documentFormatType?: string) {
    if (this.readOnly) {
      this.toast.info('Would have executed the test suite')
      return
    }
    const id = this.statusService.start('Executing test suite...')
    if (testSuiteName) {
      const request: ExecuteTestSuiteRequest = {
        action: 'ExecuteTestSuite',
        name: testSuiteName,
        region: this.region.id,
        environment: this.environment.id,
        workspace,
        format: documentFormatType,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
        .then(res => {
          return res as ExecuteTestSuiteResponse
        }).finally(() => {
          this.testExecutionSubject.next(new Date().getTime())
          this.statusService.complete(id)
        })
    }
  }

  getTestSuiteExecutionHistory(bustCache: boolean = false, numberOfDays: number = 10) {
    const id = this.statusService.start('Loading execution history...')
    const request: GetTestSuiteExecutionHistoryRequest = {
      action: 'GetTestSuiteExecutionHistory',
      bustCache: bustCache,
      numberOfDays: numberOfDays,
    }
    return this.apiService.post('/tests/',
      request,
      {
        headers: {},
      })
      .toPromise()
      .then(res => {
        const filtered = res as GetTestSuiteExecutionHistoryResponse
        // filtered.executions = filtered.executions.filter(execution => {
        //   return this.useWorkspaces || (execution.workspace === undefined || execution.workspace === 'NONE')
        // })
        return filtered
      }).finally(() => {
        this.statusService.complete(id)
      })
  }

  refreshTestSuiteExecutionDetails(executionId: string) {
    if (executionId) {
      const request: GetTestSuiteExecutionStatusRequest = {
        action: 'GetTestSuiteExecutionStatus',
        executionId,
        bustCache: true,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
    }
  }

  getTestSuiteExecutionDetails(executionId: string) {
    if (executionId) {
      const request: GetTestSuiteExecutionStatusRequest = {
        action: 'GetTestSuiteExecutionStatus',
        executionId,
        bustCache: true,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
    }
  }

  getTestExecutionDetail(executionId: string, testName: string) {
    const request: GetTestExecutionDetailRequest = {
      action: 'GetTestExecutionDetail',
      executionId,
      name: testName,
    }
    return this.apiService.post<GetTestExecutionDetailRequest, TestExecutionDetailResponse>('/tests/',
      request,
      {
        headers: {},
      })
      .pipe(
        switchMap((testResponse: TestExecutionDetailResponse) => {
          return unzipIfNecessary(testResponse.response)
            .then(unzipped => {
              return {
                ...testResponse,
                response: unzipped,
              }
            })
        }),
      )
  }

  async cloneTestSuite(existingTestSuiteName: string, prefix: string, suffix: string, matchConfig: string, targetConfig: string, copyOtherTests: boolean, xpaths?: any) {
    console.log(xpaths)
    const id = this.statusService.start('creating new test suite.....')
    console.log(xpaths)
    const request: CloneTestSuiteRequest = {
      action: 'CloneTestSuite',
      existingTestSuiteName,
      prefix,
      suffix,
      matchConfig,
      targetConfig,
      copyOtherTests,
      xpaths,
    }
    const url = '/tests/'
    return await this.apiService.post<CloneTestSuiteRequest, any>(url, request, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).toPromise()
      .then(res => {
        return res as CloneTestSuiteRequest
      }).finally(() => {
        this.statusService.complete(id)
        this.reload()

      })
  }

  downloadTestPack(executionId: string) {
    const id = this.statusService.start('Downloading Test Pack...')
    if (executionId) {
      const request: GetTestPackRequest = {
        action: 'GetTestPack',
        executionId,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
        .then(res => {
          this.statusService.complete(id)
          return res as TestPackReference[]
        })
    }
  }

  enableProdClone(value: boolean) {
    this.includeProdCloneSubject.next(value)
  }

  deleteExecution(executionId: string) {
    if (this.readOnly) {
      this.toast.warning('Would have deleted this execution')
      return
    }
    if (executionId) {
      const request: DeleteTestSuiteExecutionRequest = {
        action: 'DeleteTestSuiteExecution',
        executionId,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
    }
  }

  rebaselineTests(executionId: string, comment: string) {
    if (this.readOnly) {
      this.toast.info('Would have rebaselined the tests')
      return
    }
    if (executionId) {
      const request: AssignBaselineRequest = {
        action: 'AssignBaseline',
        executionId,
        comment,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
    }
  }

  rerunTests(executionId: string, tests: string[]) {
    if (this.readOnly) {
      this.toast.info('Would have rerun these tests')
      return
    }
    if (executionId && tests && tests.length) {
      const request: RerunTestRequest = {
        action: 'RerunTest',
        executionId,
        tests,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
    }
  }

  deleteTestSuite(testSuiteName: string) {
    if (this.readOnly) {
      this.toast.warning('Would have deleted this test suite')
      return
    }
    if (testSuiteName) {
      const id = this.statusService.start('Deleting test suite')
      const request: DeleteTestSuiteRequest = {
        action: 'DeleteTestSuite',
        name: testSuiteName,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
        .finally(() => {
          this.statusService.complete(id)
          this.reload()
        })
    }

  }

  migrate(testSuiteName: string, sourceSchema: string, transform: string, targetSchema: string, testMode: boolean) {
    if (this.readOnly) {
      this.toast.warning('Would have migrated this suite of tests')
      return
    }
    if (testSuiteName) {
      const id = this.statusService.start('Migrating Tests' + (testMode ? ' - (Test Mode)' : ''))
      const request: MigrateTestSuiteRequest = {
        action: 'MigrateTestSuite',
        name: testSuiteName,
        sourceSchema,
        transform,
        targetSchema,
        trace: true,
        applyChanges: (testMode ? undefined : true),
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
        .finally(() => {
          this.statusService.complete(id)
        })
    }
  }

  migrateBatch(sourceSchema: string, transform: string, targetSchema: string, testMode: boolean, batchSize?: number) {
    if (this.readOnly) {
      this.toast.warning('Would have migrated the tests')
      return
    }
    const id = this.statusService.start('Migrating Tests' + (testMode ? ' - (Test Mode)' : ''))
    const request: MigrateBatchRequest = {
      action: 'MigrateBatch',
      sourceSchema,
      transform,
      targetSchema,
      trace: true,
      applyChanges: (testMode ? undefined : true),
      batchSize: batchSize ? batchSize : 250,
    }
    return this.apiService.post('/tests/',
      request,
      {
        headers: {},
      })
      .toPromise()
      .finally(() => {
        this.statusService.complete(id)
      })
  }

  generateIndexTestSuite(indexes: Index[], template?: string) {
    if (this.readOnly) {
      this.toast.warning('Would have updated this index disclaimer test suite')
      return
    }
    const id = this.statusService.start('Updating Index Disclaimer Tests')
    return this.apiService.post<GenerateIndexDisclaimerTestSuiteRequest, any>('/tests/', {
      action: 'GenerateIndexDisclaimerTestSuite',
      name: 'System - Index Disclaimers Test Suite',
      indexes,
      template,
    }).pipe(
      tap(_ => this.statusService.complete(id)),
    )
  }

  getSeries(): Observable<SeriesMetadata[]> {
    return this.series
  }

  setService(service: string) {
    this.serviceSubject.next(service)
  }

  deleteTests(tests: string[]) {
    if (this.readOnly) {
      this.toast.warning('Would have deleted this test suite')
      return
    }
    if (tests.length > 0) {
      const id = this.statusService.start('Deleting tests...')
      const request: DeleteTestsRequest = {
        action: 'DeleteTests',
        tests: tests,
      }
      return this.apiService.post('/tests/',
        request,
        {
          headers: {},
        })
        .toPromise()
        .then(res => {
          this.statusService.complete(id)
          this.reload()
          return res as DeleteTestsResponse
        })
    }
  }

  getArchivedTests(): Promise<GetArchiveTestsResponseArray> {
    const request: GetAllArchiveTestsRequest = Object.assign({
      action: 'GetAllArchivedTests',
    })
    return this.apiService
      .post('/tests/', request, {
        headers: {},
      })
      .toPromise()
      .then((res) => {
        // console.log(res);
        return res as GetArchiveTestsResponseArray
      })
  }

  restoreArchivedTests(tests: string[]): Promise<DeleteTestResponse> {
    const request: RestoreTestsFromArchiveRequest = Object.assign({
      action: 'RestoreTestsFromArchive',
      tests: tests,
    })
    return this.apiService
      .post('/tests/', request, {
        headers: {},
      })
      .toPromise()
      .then((res) => {
        return res as DeleteTestResponse
      })


  }
}

