<div class="container-fluid h-75">
  <div class="row">
    <div class="col-lg-9">
      <ng-container>
        <app-test-selector service="publish" allowDelete="true" (cleared)="testReset()"></app-test-selector>
        <div *ngIf="publishQueryParameters">Publish Parameters: {{publishQueryParameters}}</div>
        <div *ngIf="regxchangeRetrieveSchemaParameter">Retrieve with Schema: {{regxchangeRetrieveSchemaParameter}}</div>
        <div *ngIf="regxchangeSubmitSchemaParameter">Submit with Schema: {{regxchangeSubmitSchemaParameter}}</div>
      </ng-container>

      <ng-container *ngIf="loadedVersion">Loaded Version: {{loadedVersion}}</ng-container>
    </div>
    <div class="col-lg-3">
      <app-publish-graphic id="lambda-status-graphic-icon"
                           [width]="240"
                           [height]="100"
                           [trace]="trace"
                           [refresh]="refreshGraphic"
      ></app-publish-graphic>
    </div>
  </div>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="publishing"></mat-progress-bar>

<div class="container-fluid h-75">
  <ul ngbNav #publishNav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>Payload</a>
      <ng-template ngbNavContent>
        <div class="payload-input">
          <ngx-monaco-editor
            class="monaco-editor payload-editor"
            [options]="payloadEditorOptions"
            [(ngModel)]="payloadValue"
            (ngModelChange)="refresh()"
          >
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem>
      <a ngbNavLink>Overview</a>
      <ng-template ngbNavContent>
        <div class="lambda-graphic">
          <app-publish-graphic id="lambda-status-graphic"
                               [width]="1200"
                               [height]="560"
                               [trace]="trace"
                               [refresh]="refreshGraphic"
          ></app-publish-graphic>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem>
      <a ngbNavLink>Expectations</a>
      <ng-template ngbNavContent>
        <div class="container-fluid expectations-input" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="expectations-subtitle">Existing Data</div>
              <div class="retrieve-stub">
                <ngx-monaco-editor
                  class="monaco-editor retrieve-stub-input"
                  [options]="retrieveStubEditorOptions"
                  [(ngModel)]="retrieveStubValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <div class="expectations-subtitle">Will be Sent - <a (click)="getCurl()">Curl</a> </div>
              <div class="publish-expectation">
                <ngx-monaco-editor
                  class="monaco-editor publish-expectation-input"
                  [options]="publishExpectationEditorOptions"
                  [(ngModel)]="publishExpectationValue"
                >
                </ngx-monaco-editor>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="csSchemaValue !== undefined">
      <a ngbNavLink>CS Schema</a>
      <ng-template ngbNavContent>
        <div class="container-fluid" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="source-schema-input">
                <ngx-monaco-editor
                  class="monaco-editor source-schema-editor"
                  [options]="schemaEditorOptions"
                  [(ngModel)]="csSchemaValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="csValidation">
                <div class="source-validation-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonOptions"
                    [ngModel]="csValidation">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="transformValue !== undefined">
      <a ngbNavLink>Transform</a>
      <ng-template ngbNavContent>
        <div class="container-fluid" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="transform-input">
                <ngx-monaco-editor
                  class="monaco-editor transform-editor"
                  [options]="jsonataOptions"
                  [(ngModel)]="transformValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="transformed">
                <div class="transform-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonOptions"
                    [ngModel]="transformed">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="enrichValue !== undefined">
      <a ngbNavLink>Enrich</a>
      <ng-template ngbNavContent>
        <div class="container-fluid" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="enrich-input">
                <ngx-monaco-editor
                  class="monaco-editor enrich-editor"
                  [options]="jsonataOptions"
                  [(ngModel)]="enrichValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="enriched">
                <div class="enrich-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonOptions"
                    [ngModel]="enriched">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="combineValue !== undefined">
      <a ngbNavLink>Combine</a>
      <ng-template ngbNavContent>
        <div class="container-fluid" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="enrich-input">
                <ngx-monaco-editor
                  class="monaco-editor enrich-editor"
                  [options]="jsonataOptions"
                  [(ngModel)]="combineValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="combined">
                <div class="enrich-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonOptions"
                    [ngModel]="combined">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="encapsulateValue !== undefined">
      <a ngbNavLink>Encapsulate</a>
      <ng-template ngbNavContent>
        <div class="container-fluid" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="enrich-input">
                <ngx-monaco-editor
                  class="monaco-editor enrich-editor"
                  [options]="jsonataOptions"
                  [(ngModel)]="encapsulateValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="encapsulated">
                <div class="enrich-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonOptions"
                    [ngModel]="encapsulated">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="targetSchemaValue !== undefined">
      <a ngbNavLink>Target Schema</a>
      <ng-template ngbNavContent>
        <div class="container-fluid" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="target-schema-input">
                <ngx-monaco-editor
                  class="monaco-editor target-schema-editor"
                  [options]="schemaEditorOptions"
                  [(ngModel)]="targetSchemaValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="targetValidation">
                <div class="target-validation-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonOptions"
                    [ngModel]="targetValidation">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="before">
      <a ngbNavLink>Target Before</a>
      <ng-template ngbNavContent>
        <div class="target-response-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyJsonOptions"
            [ngModel]="before">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="published">
      <a ngbNavLink>Published</a>
      <ng-template ngbNavContent>
        <div class="published-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyJsonOptions"
            [ngModel]="published">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="targetResponse">
      <a ngbNavLink>Target Response</a>
      <ng-template ngbNavContent>
        <div class="target-response-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyJsonOptions"
            [ngModel]="targetResponse">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="after">
      <a ngbNavLink>Target After</a>
      <ng-template ngbNavContent>
        <div class="target-response-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyJsonOptions"
            [ngModel]="after">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="postConvertPayload">
      <a ngbNavLink>Post-Encapsulate</a>
      <ng-template ngbNavContent>
        <div class="target-response-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyXmlOptions"
            [ngModel]="postConvertPayload">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="expectedModel.code !== '' && publishedModel.code !== ''">
      <a ngbNavLink>Differences</a>
      <ng-template ngbNavContent>
        <div class="diff-output">
          <ngx-monaco-diff-editor
            class="monaco-editor diff-output-editor"
            [options]="jsonDiffOptions"
            [originalModel]="expectedModel"
            [modifiedModel]="publishedModel"
          >
          </ngx-monaco-diff-editor>
        </div>
      </ng-template>
    </li>


    <li ngbNavItem *ngIf="errors">
      <a ngbNavLink>API Error Response</a>
      <ng-template ngbNavContent>
        <div class="target-response-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyJsonOptions"
            [ngModel]="errors">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="testHistory">
      <a ngbNavLink>History</a>
      <ng-template ngbNavContent>
        <app-test-history-list [history]="testHistory"
                               (selectedVersion)="selectTestHistory($event)"></app-test-history-list>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="trace">
      <a ngbNavLink>Trace</a>
      <ng-template ngbNavContent>
        <div class="trace-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyJsonOptions"
            [ngModel]="trace">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

  </ul>

  <div [ngbNavOutlet]="publishNav"></div>
</div>

<div class="actions container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <button type="button" class="btn btn-primary" (click)="publish()" [disabled]="!publishConfig">Publish</button>
      <button type="button" class="btn btn-primary" (click)="test()" [disabled]="!canExecuteTest()">Test</button>
      <button type="button" class="btn btn-info" (click)="savePayload()" [disabled]="!publishConfig">Save as Example
      </button>
      <button type="button" class="btn btn-info" (click)="saveRegressionTest()" *ngIf="canSaveRegressionTest()">Save as
        Regression Test
      </button>
      <button type="button" class="btn btn-danger" (click)="executeTestSuite()">Execute Regression Tests
      </button>
    </div>
  </div>
</div>
