import {Component} from '@angular/core'
import {MatDialogRef} from '@angular/material/dialog'
import {DateTime} from 'luxon'
import {AnalyseField, AnalyseRow} from 'src/app/common/domain/analyse'
import {ConfigDetail} from 'src/app/common/domain/config'
import {NormalisedRuleSet} from 'src/app/model/normalised-ruleset'
import {ChangeService} from 'src/app/services/change.service'
import {WorkspaceAutoSaveService} from 'src/app/services/workspace-auto-save.service'
import {AddAnalysisRuleDetails} from '../analysis-add-rule-dialog.component'

@Component({
  templateUrl: './ruleset-2021-create-new-dialog.html',
  styleUrls: ['./ruleset-2021-create-new-dialog.scss'],
})
export class Ruleset2021CreateNewDialogComponent {

  public canSave = false
  public stages = ['product', 'solo']
  public ruleTypes = ['whitelist', 'blacklist', 'notSupported', 'validation', 'complex']
  public complexRule = false
  public newRuleSet: AddAnalysisRuleDetails
  showDelete = false;
  constructor(
    public _dialogRef: MatDialogRef<Ruleset2021CreateNewDialogComponent>,
    private _changeService: ChangeService,
    protected _workspaceAutoSaveService: WorkspaceAutoSaveService,

  ) {

    this.newRuleSet = {
      name: undefined,
      description: undefined,
      mnemonic: undefined,
      fields: new Array<string>(),
      placeholderValue: 'XPath 1.0 expression',
    } as AddAnalysisRuleDetails

    this.actionAddField()
  }

  public validateInput() {
    console.log('this is new ruletype',this.newRuleSet.ruleType)
    this.newRuleSet.ruleType === 'complex' ? this.complexRule = true : this.complexRule = false

    
    // Ensure Mnemonic is uppercase characters (+ permit optional numbers)
    if (!!this.newRuleSet.mnemonic) {
      this.newRuleSet.mnemonic = this.newRuleSet.mnemonic.toUpperCase()
      this.newRuleSet.mnemonic = this.newRuleSet.mnemonic.replace(/\W/g, '')
    }
   if(!this.complexRule) {
    let canSave = this.newRuleSet.mnemonic?.length > 1
      && this.newRuleSet.name?.length > 0
      && this.newRuleSet.description?.length > 0
      && this.newRuleSet.fields?.length > 0
      && this.newRuleSet.stage?.length > 0
      && this.newRuleSet.ruleType?.length > 0


    this.newRuleSet.fields.forEach(x => {
      if (!x || x.length < 2) {
        canSave = false
      }
    })
  

    this.canSave = canSave
  } else if(this.complexRule) {
    let canSave = this.newRuleSet.mnemonic?.length > 1
      && this.newRuleSet.name?.length > 0
      && this.newRuleSet.description?.length > 0
      && this.newRuleSet.stage?.length > 0
      && this.newRuleSet.ruleType?.length > 0
      this.canSave = canSave

  }
  }

  actionCancel() {
    this._dialogRef.close(undefined)
  }

  actionAddField() {
    const currentFields = [...this.newRuleSet.fields]
    currentFields.push('')
    this.newRuleSet.fields = currentFields
    this.validateInput();
    this.actionShowHideDelete();
  }

  trackByIndex(index: number): number {
    return index
  }

  actionDeleteField(index: number) {
    const currentFields = [...this.newRuleSet.fields]
    currentFields.splice(index,1)
    this.newRuleSet.fields = currentFields
    this.validateInput();
    this.actionShowHideDelete();
  }

  actionShowHideDelete() {
   this.showDelete = this.newRuleSet.fields.length > 1 ? true : false
  }
  
  public async actionCreate() {
    // Create and save the RuleSet to the Change Service
    const filepath = `rulesets/${this.newRuleSet.mnemonic}.json`
    const newNormalisedRuleSet = new NormalisedRuleSet(filepath)
    {
      newNormalisedRuleSet.mnemonic = this.newRuleSet.mnemonic
      newNormalisedRuleSet.name = this.newRuleSet.name
      newNormalisedRuleSet.description = this.newRuleSet.description
      newNormalisedRuleSet.help = this.newRuleSet.help
      newNormalisedRuleSet.ruleType = this.newRuleSet.ruleType
      newNormalisedRuleSet.rule = new Array<AnalyseRow>()
      newNormalisedRuleSet.stage = this.newRuleSet.stage
      newNormalisedRuleSet.lastUpdated = DateTime.utc().toISO()

      // Push the fields. Both are effectively string[], but am doing this to be safe
      if(newNormalisedRuleSet.ruleType !== 'complex') {
      const newAnalyseFields = new Array<AnalyseField>()
      this.newRuleSet.fields.forEach(curField => {
        const newAnalyseField: AnalyseField = curField as AnalyseField;
        newNormalisedRuleSet.fields.push(curField)
      })
    }
  }
    // ChangeDetails for the Change Service (Beware, there be Dragons)
    delete newNormalisedRuleSet.filePath // We don't save the filePath
    const newChangeDetails: ConfigDetail = {
      source: 'lambda-ingest-product',
      name: `${this.newRuleSet.mnemonic}.json`,
      content: JSON.stringify(newNormalisedRuleSet, null, 2),
      path: filepath,
      size: undefined,
    } as ConfigDetail
    // (newChangeDetails as any).state = 'new'
    // Save it
    this._changeService.upsertObject(newChangeDetails)
    // BasicHelper.delay(1000)
    this._workspaceAutoSaveService.saveWorkspace()

    this._dialogRef.close(this.newRuleSet.mnemonic)
  }

}
