<div class="modal-header">
  <h4 class="modal-title">Rule Error Messages</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-container *ngIf="rowMnemonic">
    <p>Enter the description and help when the {{ruleType}} / {{ruleMnemonic}} rule is triggered.</p>
  </ng-container>
  <ng-container *ngIf="!rowMnemonic">
    <p>Enter the description and help when the {{ruleType}} / {{ruleMnemonic}}-{{rowMnemonic}} rule is triggered.</p>
  </ng-container>
  <div class="container-fluid">
    <div class="form-group">
      <label for="ruleErrorDescriptionInput">Description</label>
      <input class="form-control" id="ruleErrorDescriptionInput" [(ngModel)]="description" placeholder="Description">
      <small class="form-text text-muted">Provide a description to the users of the error.</small>
    </div>
    <div class="form-group">
      <label for="ruleHelpDescriptionInput">Help</label>
      <input class="form-control" id="ruleHelpDescriptionInput" [(ngModel)]="help" placeholder="Help">
      <small class="form-text text-muted">Provide a hint to the user on how to resolve the error.</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-primary" ngbAutoFocus (click)="confirm()">OK</button>
</div>
