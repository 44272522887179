import {Injectable} from '@angular/core'
import {ConfigVersionService} from './config-version.service'
import {WorkspaceService} from './workspace.service'
import {ChangeDetectingBehaviourSubject} from '../util/change-detecting-behaviour-subject'

@Injectable({
  providedIn: 'root',
})
export class StaleWorkspaceMonitorService {
  private staleWorkspaceSubject = new ChangeDetectingBehaviourSubject<boolean>('Stale Workspace Monitor', false)
  staleWorkspace = this.staleWorkspaceSubject.asObservable()

  constructor(
    private workspaceService: WorkspaceService,
    private configVersionService: ConfigVersionService,
  ) {
    // TODO - XXX Fix stale workspace checks for multiple repos
    // combineLatest([configVersionService.configVersion, workspaceService.selectedWorkspace])
    //   .subscribe(([configVersion, workspace]) => {
    //     this.staleWorkspaceSubject.next(configVersion && workspace && workspace.parentCommit !== configVersion)
    //   })
  }
}
