import {Component, OnInit,Optional , Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { DiffEditorModel } from 'ngx-monaco-editor';
import { jsonataEditorOptions, jsonDiffViewerOptions } from 'src/app/util/editor-configurations';
import {ReleaseConfigDetails} from '../utils/release-changes';
import {Subscription} from 'rxjs';

interface UpdatedRuleset {
  rightHandSide: string,
  leftHandSide: string,
}
@Component({
    selector: 'app-docgen-release-changes-details',
    templateUrl: './docgen-release-changes-details.component.html',
    styleUrls: ['./docgen-release-changes-details.component.scss']
})

export class DocgenReleaseChangesDetailsComponent implements OnInit {

  existingconfigTitle: string
  newConfigTitle: string
  jsonDiffOptions = jsonDiffViewerOptions

  originalModel: DiffEditorModel = {
      language: 'application/json',
      code: '',
  }
  modifiedModel: DiffEditorModel = {
      language: 'application/json',
      code: '',
  }

    constructor(
      @Optional()@Inject(MAT_DIALOG_DATA) public data: UpdatedRuleset,
      private dialog: MatDialog,
    ) {
        if(data) {
          this.modifiedModel.code = data.rightHandSide
          this.originalModel.code = data.leftHandSide;
        }
      }

    ngOnInit() {}

    showDetails (existingData: ReleaseConfigDetails, newData: ReleaseConfigDetails) {
      this.originalModel.code = existingData.config;
      this.modifiedModel.code = newData.config;
      const existingConfig = existingData.config
      const newConfig = newData.config
      this.openDialog(existingConfig,newConfig)
    }

    openDialog(existingConfig: string,  newConfig: string) {
        this.originalModel.code = existingConfig;
        this.modifiedModel.code = newConfig;
        this.dialog.open(DocgenReleaseChangesDetailsComponent, {
          width: '90%',
          height: '90%',
          data: {
            rightHandSide: newConfig,
            leftHandSide: existingConfig,
          } as UpdatedRuleset

        })
    }

    closeDialog() {
      this.dialog.closeAll()
    }
}
