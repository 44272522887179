<div>
  <div class="audit-title">
    <h2>{{rulesetMnemonic}} Audit History</h2>
    <ng-container *ngIf="!showAuditHistory">
      <p>No audit trail for this ruleset</p>
    </ng-container>
  </div>
  <div class="download-audit-csv" *ngIf="showAuditHistory">
    <button class="btn btn-sm btn-info" (click)="downloadCSV()">Download CSV</button>
  </div>
</div>

<ng-container *ngIf="showAuditHistory">
  <div class="tableFixHead scroll-inherit-important">
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Description</th>
        <th scope="col">User</th>
        <th scope="col">Timestamp</th>
        <th scope="col">Audit Details</th>
        <th scope="col">config</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let audit of auditHistoryToDisplay">
        <td>{{audit.description}}</td>
        <td>{{audit.username}}</td>
        <td>{{audit.timestamp}}</td>
        <td>
          <li *ngFor="let detail of audit.auditDetails">{{detail}}</li>
        </td>
        <td *ngIf="!['Audit details not found.', 'No history to show.'].includes(audit.auditDetails[0])">
          <button class="btn btn-sm btn-info" (click)="showConfigForChange(audit)">Display Changes</button>
        </td>
        <td *ngIf="['Audit details not found.', 'No history to show.'].includes(audit.auditDetails[0])">
          <span>N/A</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</ng-container>

