import {SelectionModel} from '@angular/cdk/collections'
import {NestedTreeControl} from '@angular/cdk/tree'
import {ConfigKeyElement} from 'src/app/common/domain'
import {ConfigItem} from 'src/app/common/domain/config'

export class RuleSet2021ViewEditTreeViewLogic {

    private checklistSelection = new SelectionModel<ConfigKeyElement>(true)

    constructor(checklistSelection: SelectionModel<ConfigKeyElement>) {
        this.checklistSelection = checklistSelection
    }

    public static buildTree(configItems: ConfigItem[]): ConfigKeyElement[] {
        return configItems.reduce((acc: ConfigKeyElement[], item: ConfigItem) => {

            const path = item.path
            const match = path.match(/^.*\/(.+)\/(.+)\/(.+)\/(.+)\/Config.json$/)
            const sourceSystem = match[1]
            const programme = match[2]
            const productType = match[3]
            const documentType = match[4]

            // Add the sourceSystem if it is not already in there
            let ss = acc.find(x1 => x1.name === sourceSystem)
            if (!ss) {
                ss = {name: sourceSystem, level: 'sourceSystem', isSelected: false, hide: false, parent: undefined, children: []}
                acc.push(ss)
            }

            // Add the programme if it is not already in there
            if (!ss.children.find(x2 => x2.name === programme)) {
                ss.children.push({name: programme, level: 'programme', isSelected: false, hide: false, parent: ss, children: []})
            }

            // Add the productType if it is not already in there
            const prog = ss.children.find(x3 => x3.name === programme)
            if (!prog.children.find(x4 => x4.name === productType)) {
                prog.children.push({name: productType, level: 'productType', isSelected: false, hide: false, parent: prog, children: []})
            }

            // Add the documentType if it is not already in there
            const prodType = prog.children.find(x5 => x5.name === productType)
            if (!prodType.children.find(x6 => x6.name === documentType)) {
                prodType.children.push({name: documentType, level: 'documentType', isSelected: false, hide: false, parent: prodType, children: []})
            }

            return acc
        }, [])
    }

    public setAncestors(node: ConfigKeyElement, value: boolean): void {
        if (!!node && !!node.parent) {
            if (!value) {
                if (node.parent.children.every(child => !child.isSelected)) {
                    value ? this.checklistSelection.select(node.parent) : this.checklistSelection.deselect(node.parent)
                    node.parent.isSelected = value
                }
            } else {
                value ? this.checklistSelection.select(node.parent) : this.checklistSelection.deselect(node.parent)
                node.parent.isSelected = value
            }
            if (node.parent.parent) {
                if (!value) {
                    if (node.parent.parent.children.every(child => !child.isSelected)) {
                        value ? this.checklistSelection.select(node.parent.parent) : this.checklistSelection.deselect(node.parent.parent)
                        node.parent.parent.isSelected = value
                    }
                } else {
                    value ? this.checklistSelection.select(node.parent.parent) : this.checklistSelection.deselect(node.parent.parent)
                    node.parent.parent.isSelected = value
                }
                if (node.parent.parent.parent) {
                    if (!value) {
                        if (node.parent.parent.parent.children.every(child => !child.isSelected)) {
                            value ? this.checklistSelection.select(node.parent.parent.parent) : this.checklistSelection.deselect(node.parent.parent.parent)
                            node.parent.parent.parent.isSelected = value
                        }
                    } else {
                        value ? this.checklistSelection.select(node.parent.parent.parent) : this.checklistSelection.deselect(node.parent.parent.parent)
                        node.parent.parent.parent.isSelected = value
                    }
                }
            }
        }
    }

    public setDescendents(node: ConfigKeyElement, value: boolean): void {
        node.children.forEach(child => {
            value ? this.checklistSelection.select(child) : this.checklistSelection.deselect(child)
            child.isSelected = value
            this.setDescendents(child, value)
        })
    }

    public expandAllSelectedTreeViewNodes(treeControl: NestedTreeControl<ConfigKeyElement>) {
        this.checklistSelection.selected.forEach((curConfigKeyElement: ConfigKeyElement) => {

            if (curConfigKeyElement.isSelected) {
                treeControl.expand(curConfigKeyElement)
            }

            // this.checklistSelection.toggle(curConfigKeyElement)
            // curConfigKeyElement.isSelected = !curConfigKeyElement.isSelected
            // const treeViewLogic = new TreeViewLogic(this.checklistSelection)
            // treeViewLogic.setAncestors(curConfigKeyElement, curConfigKeyElement.isSelected)
            // treeViewLogic.setDescendents(curConfigKeyElement, curConfigKeyElement.isSelected)
        })

    }

}
