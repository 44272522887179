import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {ToastrService} from 'ngx-toastr'
import {combineLatest, Subscription} from 'rxjs'
import {take} from 'rxjs/operators'
import {adjectives, animals, colors, uniqueNamesGenerator} from 'unique-names-generator'
import {DocgenConfigAndMetadataChangeDetails} from '../../common/domain/change'
import {ConfigSource} from '../../common/domain/config'
import {NameDialogComponent} from '../../dialogs/name-dialog.component'
import {ChangeService} from '../../services/change.service'
import {ConfigMonitorService} from '../../services/config-monitor.service'
import {ConfigService} from '../../services/config.service'
import {PermissionsService} from '../../services/permissions.service'
import {StatusService} from '../../services/status.service'
import {WorkspaceAutoSaveService} from '../../services/workspace-auto-save.service'

@Component({
  selector: 'app-new-workspace',
  templateUrl: './new-workspace.component.html',
  styleUrls: ['./new-workspace.component.scss'],
})
export class NewWorkspaceComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  canSave = false
  useWorkspaces = false
  version: {source: ConfigSource, version: string}[]
  configs: DocgenConfigAndMetadataChangeDetails[] = []

  constructor(
    private statusService: StatusService,
    private toast: ToastrService,
    private configService: ConfigService,
    private configMonitorService: ConfigMonitorService,
    private permissionsService: PermissionsService,
    private changeService: ChangeService,
    private workspaceAutoSaveService: WorkspaceAutoSaveService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.sub.add(
      combineLatest([this.configMonitorService.canSave,
      this.changeService.getUserFilteredDocgenConfigs(),
      this.configService.loadedVersions,
      this.permissionsService.workspaces])
        .subscribe(([canSave, configs, versions, useWorkspaces]) => {
          this.useWorkspaces = useWorkspaces
          this.version = versions
          this.configs = configs
          this.canSave = canSave && versions && configs && configs.length > 0
        }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  public async saveChangesToNewWorkspace() {
    const randomName = uniqueNamesGenerator({
      dictionaries: [adjectives, colors, animals],
      separator: ' ',
      length: 3,
    })

    const nameDialogRef = this.dialog.open(NameDialogComponent, {
      width: '500px',
      data: {
        title: 'Create New Workspace',
        name: randomName,
        hint: 'Enter workspace name',
      },
      panelClass: 'dialogFormField500',
    })

    nameDialogRef.afterClosed()
      .subscribe(async (workspaceName: string) => {
        if (workspaceName) {
          if (workspaceName.toLowerCase() === 'none') {
            this.toast.error(`A workspace may not be named 'none'...`)
            return
          }

          // const scopeDialogRef = this.dialog.open(CreateWorkspaceDialogComponent, {
          //   width: '500px',
          //   data: {
          //     title: `Select Config Keys for Workspace: ${workspaceName}`,
          //     configItems: this.configs.map(c => c.settings),
          //   },
          //   panelClass: 'dialogFormField500',
          // })
          // scopeDialogRef.afterClosed().subscribe(async (selected: string[]) => {

          // Get the SourceSystem the current user is working on (first one we find in the user's filter)
          // plugh This assumes the user only has one SourceSystem selected. If not confusion and/or trouble  may occur when maintaining RuleSets
          // plugh Perhaps the Config Filter screen should only permit one SourceSystem to be selected
          const userFilteredDocgenConfigs = await this.changeService.getUserFilteredDocgenConfigs().pipe(take(1)).toPromise()
          const currentSourceSystem = userFilteredDocgenConfigs[0].metadata.sourceSystem

          // Get all the DocGen Configs (i.e. ignoring the user's filter)
          //  sort them and this becomes the 'selected' items for the new workspace
          let allDocgenConfigs = await this.changeService.getAllDocgenConfigs().pipe(take(1)).toPromise()
          allDocgenConfigs = allDocgenConfigs.filter(x => x.metadata.sourceSystem === currentSourceSystem)
          let selected = allDocgenConfigs.map(x => `settings/${x.metadata.sourceSystem}/${x.metadata.programme}/${x.metadata.productType}/${x.metadata.documentType}/Config.json`)
          selected = selected.sort()
          console.info(selected)
          console.info(JSON.stringify(selected))
          await this.workspaceAutoSaveService.newWorkspace(workspaceName, selected)
          // })
        }
      },
      )
  }

  public actionShowHelpForCreatingRules() {
    // Dialog looked awful + was going to take time. I (Paul Lockwood) opted to just open a static html page
    window.open('/assets/help-creating-rule-dialog.component.html')

    // this.dialog.open(HelpCreatingRuleDialogComponent, {
    //   position: {top: '200px'},
    // });
  }

}
