<ng-container *ngIf="loading">
  Loading...
</ng-container>
<ng-container *ngIf="!loading">
  <ng-container *ngIf="results.length === 0">
    <div>No Management Information Found</div>
  </ng-container>
  <ng-container *ngIf="results.length > 0">
    <form class="form-inline">
      <button type="button" class="btn btn-primary" (click)="downloadCsv()">Download</button>
      <div class="input-group">
        <input type="text" placeholder="Filter..." [formControl]="filter" class="form-control ml-2"/>
      </div>
    </form>
  </ng-container>
</ng-container>

<table mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Date</th>
    <td mat-cell *matCellDef="let el">{{el.date}}</td>
  </ng-container>
  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Time</th>
    <td mat-cell *matCellDef="let el">{{el.time}}</td>
  </ng-container>
  <ng-container matColumnDef="environment">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Environment</th>
    <td mat-cell *matCellDef="let el">{{el.environment}}</td>
  </ng-container>
  <ng-container matColumnDef="region">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Region</th>
    <td mat-cell *matCellDef="let el">{{el.region}}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
    <td mat-cell *matCellDef="let el">{{el.status}}</td>
  </ng-container>
  <ng-container matColumnDef="sparrowRef">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Sparrow Ref</th>
    <td mat-cell *matCellDef="let el">
      <a [href]="el.sparrowProductUrl">
        {{el.sparrowRef}}
      </a>
  </ng-container>
  <ng-container matColumnDef="sparrowTicket">
    <th mat-header-cell *matHeaderCellDef> mat-sort-header Sparrow Ticket</th>
    <td mat-cell *matCellDef="let el">
      <a [href]="el.sparrowTicketUrl">
        {{el.sparrowTicket}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="config">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Config Key</th>
    <td mat-cell *matCellDef="let el">{{el.config}}</td>
  </ng-container>
  <ng-container matColumnDef="latency">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Latency</th>
    <td mat-cell *matCellDef="let el">{{el.latency}}</td>
  </ng-container>
  <ng-container matColumnDef="validatePayload">
    <th mat-header-cell *matHeaderCellDef> CS Schema</th>
    <td mat-cell *matCellDef="let el">{{el.validatePayload}}</td>
  </ng-container>
  <ng-container matColumnDef="analysePayload">
    <th mat-header-cell *matHeaderCellDef> Analyse Payload</th>
    <td mat-cell *matCellDef="let el">{{el.analysePayload}}</td>
  </ng-container>
  <ng-container matColumnDef="transform">
    <th mat-header-cell *matHeaderCellDef> Transform</th>
    <td mat-cell *matCellDef="let el">{{el.transform}}</td>
  </ng-container>
  <ng-container matColumnDef="enrich">
    <th mat-header-cell *matHeaderCellDef> Enrichment</th>
    <td mat-cell *matCellDef="let el">{{el.enrich}}</td>
  </ng-container>
  <ng-container matColumnDef="analyseEnriched">
    <th mat-header-cell *matHeaderCellDef> Analysed Solo</th>
    <td mat-cell *matCellDef="let el">{{el.analyseEnriched}}</td>
  </ng-container>
  <ng-container matColumnDef="validateEnriched">
    <th mat-header-cell *matHeaderCellDef> Inspire Schema</th>
    <td mat-cell *matCellDef="let el">{{el.validateEnriched}}</td>
  </ng-container>
  <ng-container matColumnDef="renderDocument">
    <th mat-header-cell *matHeaderCellDef> Generated Document</th>
    <td mat-cell *matCellDef="let el">{{el.renderDocument}}</td>
  </ng-container>
  <ng-container matColumnDef="renderLatency">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> SmartDX Latency</th>
    <td mat-cell *matCellDef="let el">{{el.renderLatency}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" showFirstLastButtons></mat-paginator>
