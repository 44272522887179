<h2 mat-dialog-title>Save Failing Regression Test?</h2>

<div mat-dialog-content>
  <p>Unless you intended to save a test that is failing, click Cancel and then fix the test.</p>
  <p></p>
  <p>If you click OK this test will only pass in Test Executions if the failures occur.</p>
  <p></p>
  <ng-container>
    <ng-container>Document Decision: {{data.failures.decision}}</ng-container>
    <ng-container *ngIf="data.failures.analysePayloadResult !== 'OK'">
      <p>Payload Analysis</p>
      <ng-container *ngIf="data.failures.payloadWhitelistUnmatched.length > 0">
        <div>Unmatched Whitelist Rulesets:</div>
        <mat-list>
          <mat-list-item *ngFor="let wl of data.failures.payloadWhitelistUnmatched">{{wl}}</mat-list-item>
        </mat-list>
      </ng-container>
      <ng-container *ngIf="data.failures.payloadNotSupportedMatches.length > 0">
        <div>Failing Not-Supported Rules:</div>
        <mat-list>
          <mat-list-item *ngFor="let ns of data.failures.payloadNotSupportedMatches">{{ns}}</mat-list-item>
        </mat-list>
      </ng-container>
      <ng-container *ngIf="data.failures.payloadBlacklistMatches.length > 0">
        <div>Failing Blacklist Rules:</div>
        <mat-list>
          <mat-list-item *ngFor="let bl of data.failures.payloadBlacklistMatches">{{bl}}</mat-list-item>
        </mat-list>
      </ng-container>
      <ng-container *ngIf="data.failures.payloadValidationMatches.length > 0">
        <div>Failing Validation Rules:</div>
        <mat-list>
          <mat-list-item *ngFor="let vl of data.failures.payloadValidationMatches">{{vl}}</mat-list-item>
        </mat-list>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="data.failures.analyseSoloResult !== 'OK'">
      <p>Solo Analysis</p>
      <ng-container *ngIf="data.failures.soloWhitelistUnmatched.length > 0">
        <div>Unmatched Whitelist Rulesets:</div>
        <mat-list>
          <mat-list-item *ngFor="let wl of data.failures.soloWhitelistUnmatched">{{wl}}</mat-list-item>
        </mat-list>
      </ng-container>
      <ng-container *ngIf="data.failures.soloNotSupportedMatches.length > 0">
        <div>Failing Not-Supported Rules:</div>
        <mat-list>
          <mat-list-item *ngFor="let ns of data.failures.soloNotSupportedMatches">{{ns}}</mat-list-item>
        </mat-list>
      </ng-container>
      <ng-container *ngIf="data.failures.soloBlacklistMatches.length > 0">
        <div>Failing Blacklist Rules:</div>
        <mat-list>
          <mat-list-item *ngFor="let bl of data.failures.soloBlacklistMatches">{{bl}}</mat-list-item>
        </mat-list>
      </ng-container>
      <ng-container *ngIf="data.failures.soloValidationMatches.length > 0">
        <div>Failing Validation Rules:</div>
        <mat-list>
          <mat-list-item *ngFor="let vl of data.failures.soloValidationMatches">{{vl}}</mat-list-item>
        </mat-list>
      </ng-container>
    </ng-container>
  </ng-container>

</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-button>Cancel</button>
  <button mat-button [mat-dialog-close]="data">OK</button>
</div>
