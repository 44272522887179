import {Component, OnInit} from '@angular/core'
import {DocgenConfigMetadata} from '../../common/domain/docgen-config'
import {ChangeService} from '../../services/change.service'
import {ConfigService} from '../../services/config.service'
import {skipWhile, switchMap, take} from 'rxjs/operators'
import {UserPreferencesService} from '../../services/user-preferences.service'

@Component({
  selector: 'app-config-filter',
  templateUrl: './config-filter.component.html',
  styleUrls: ['./config-filter.component.scss'],
})
export class ConfigFilterComponent implements OnInit {
  loadedConfigs = false
  loadedSelection = false

  configs: DocgenConfigMetadata[] = []
  preselected: DocgenConfigMetadata[] = []

  constructor(
    private changeService: ChangeService,
    private configService: ConfigService,

    // plugh Ask Mark why this is not being accessed via the ChangeService
    private userPreferencesService: UserPreferencesService,
  ) {
  }

  ngOnInit(): void {
    this.loadedConfigs = false
    this.loadedSelection = false
    this.changeService.getAllDocgenConfigs()
      .pipe(
        skipWhile(configs => configs?.length === 0),
        take(1),
      )
      .subscribe(docgenConfigs => {
        this.configs = docgenConfigs.map(c => c.metadata)

        this.configs.sort((a, b) => (a.productType < b.productType ? -1 : 1))
                    .sort((a, b) => (a.programme < b.programme ? -1 : 1))
                    .sort((a, b) => (a.sourceSystem < b.sourceSystem ? -1 : 1))

        this.loadedConfigs = true
      })

    // plugh Ask Mark: what does this actually do?
    this.userPreferencesService.userPreferences
      .pipe(
        take(1),
        switchMap(() => this.configService.filteredDocgenConfigs
          .pipe(
            take(1),
          ),
        ),
      )
      .subscribe(configs => {
        this.preselected = configs
        this.loadedSelection = true
      })
  }

  ngOnDestroy(): void {
  }

  selectConfigs(configs: DocgenConfigMetadata[]) {
    if (this.loadedSelection && this.loadedConfigs) {
      this.configService.setFilteredDocgenConfigs(configs)
    }
  }
}
