<div class="container-fluid">
    <div class="header" *ngIf="this.isOpenedInSeparateWindow">
        <h1 class="h1 text-center">Output</h1>
    </div>
    <ng-container *ngIf="this.outputResponsesProp !== null || this.localMemoryOutputResponses !== null; else noOutputToDisplay">

        <!-- display CONDITIONS for rule and schema validation -->
        <ng-container *ngIf="this.validatePayloadResponse !== null">

            <ng-container *ngIf="this.validatePayloadResponse.ruleValidation && this.validatePayloadResponse.ruleValidation.status === 'FAILED'; then displayRuleValidationResults"></ng-container>
            <ng-container *ngIf="this.validatePayloadResponse.ruleValidation && this.validatePayloadResponse.ruleValidation.status === 'ERROR'; then displayRuleValidationError"></ng-container>

            <ng-container *ngIf="this.validatePayloadResponse.schemaValidation
              && this.validatePayloadResponse.schemaValidation.status === 'FAILED';
              then displaySchemaValidationFailed">
           </ng-container>

           <ng-container *ngIf="this.validatePayloadResponse.schemaValidation
             && this.validatePayloadResponse.schemaValidation.schemaValidationResults
             && this.validatePayloadResponse.schemaValidation.schemaValidationResults.error;
             then displaySchemaValidationError">
           </ng-container>

           <ng-container *ngIf="this.schemaValidationErrorsOrWarnings.length > 0 ">
             <h3 class="h3 text-center success">&#10003; Rule validation successfull!</h3>
             <h3 class="h3 text-center warning">&#9888; Schema validation failed!</h3>
           </ng-container>

           <ng-container *ngIf="this.schemaValidationErrorsOrWarnings.length > 0; then displaySchemaValidationErrorsOrWarnings"></ng-container>


          <!-- display TEMPLATES for rule and schema validation -->

          <ng-template #displayRuleValidationResults>
            <ng-container *ngTemplateOutlet="displaySimpleRuleValidationResults; context: {rules: this.validatePayloadResponse.ruleValidation.ruleResults.simpleRules, header: true}"></ng-container>
            <ng-container *ngTemplateOutlet="displayComboRuleValidationResults; context: {rules: this.validatePayloadResponse.ruleValidation.ruleResults.comboRules, header: true}"></ng-container>
            <ng-container *ngTemplateOutlet="displayComplexRuleValidationResults; context: {rules: this.validatePayloadResponse.ruleValidation.ruleResults.complexRules, header: true}"></ng-container>
          </ng-template>

            <ng-template class="rules-table" #displaySimpleRuleValidationResults let-simpleRules="rules" let-header="header" let-indent="indent">
                      <mat-expansion-panel *ngFor="let rule of simpleRules">
                        <mat-expansion-panel-header [class]="header ? 'rule-header' : ''" [ngStyle]="{'margin-left': !header ? indent + 'em' : ''}">
                          <mat-panel-title [class]="!header ? 'simple-rule-row' : ''">{{rule.ruleId}}</mat-panel-title>
                          <mat-panel-description [className]="rule.status === 'PASSED' ? 'analysis-rule-ok' : 'analysis-rule-error'">{{rule.status}}</mat-panel-description>
                        </mat-expansion-panel-header>
                        <table [ngStyle]="{'margin-left': indent ? indent + 2 + 'em' : '2em'}" class="rule-results table-responsive table-body">
                          <thead>
                            <th scope="col" class="col-5">Calculation</th>
                            <th scope="col" class="col-5">Fail Message</th>
                          </thead>
                          <tbody>
                            <td scope="col" class="col-5">{{rule.calculation}}</td>
                            <td scope="col" class="col-5">{{rule.failmessage}}</td>
                          </tbody>
                        </table>
                      </mat-expansion-panel>
            </ng-template>

            <ng-template class="rules-table" #displayComboRuleValidationResults let-comboRules="rules" let-header="header" let-indent="indent">
              <mat-expansion-panel *ngFor="let comboRule of comboRules">
                <mat-expansion-panel-header [class]="header ? 'rule-header' : 'combo-rule-row'" [ngStyle]="{'margin-left': !header ? '2em': ''}">
                  <mat-panel-title [class]="!header ? 'combo-rule-row' : ''">{{comboRule.ruleId}}</mat-panel-title>
                  <mat-panel-description [className]="comboRule.status === 'PASSED' ? 'analysis-rule-ok' : 'analysis-rule-error'">{{comboRule.status}}</mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngTemplateOutlet="displaySimpleRuleValidationResults; context: {rules: comboRule.simpleCodesResults, header: false, indent: indent ? 4 : 2}" ></ng-container>
              </mat-expansion-panel>
            </ng-template>

            <ng-template class="rules-table" #displayComplexRuleValidationResults let-complexRules="rules" let-header="header">
              <mat-expansion-panel *ngFor="let complexRule of complexRules">
                <mat-expansion-panel-header [class]="header ? 'rule-header' : ''">
                  <mat-panel-title>{{complexRule.ruleId}}</mat-panel-title>
                  <mat-panel-description [className]="complexRule.status === 'PASSED' ? 'analysis-rule-ok' : 'analysis-rule-error'">{{complexRule.status}}</mat-panel-description>
                </mat-expansion-panel-header>
                <ng-container *ngTemplateOutlet="displayComboRuleValidationResults; context: {rules: complexRule.comboCodesResults, header: false, indent: '2'}" ></ng-container>
              </mat-expansion-panel>
            </ng-template>


            <ng-template #displayRuleValidationError>
                <h3 class="h3 text-center error">&#10060; Error: {{this.validatePayloadResponse.ruleValidation.body}}</h3>
            </ng-template>

            <ng-template #displaySchemaValidationError>
                <h3 class="h3 text-center error">&#10060; Schema Validation Error: {{this.validatePayloadResponse.schemaValidation.schemaValidationResults.error}}</h3>
            </ng-template>

            <ng-template #displaySchemaValidationFailed>
                <h3 class="h3 text-center error">&#10060; Error: {{this.validatePayloadResponse.schemaValidation.body}}</h3>
            </ng-template>

            <ng-template #displaySchemaValidationErrorsOrWarnings>
                <table class="table table-striped mt-4">
                    <thead class="thead-dark">
                        <th scope="col" class="col-1">Line</th>
                        <th scope="col" class="col-1">Column</th>
                        <th scope="col" class="col-2">Type</th>
                        <th scope="col" class="col-8">Error Message</th>
                    </thead>
                    <tbody class="table-borderless">
                        <tr *ngFor="let element of this.schemaValidationErrorsOrWarnings">
                            <td scope="row" class="">{{element.line}}</td>
                            <td class="">{{element.column}}</td>
                            <td class="">{{element.type}}</td>
                            <td class="">{{element.message}}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-template>
        </ng-container>

        <!-- display CONDITIONS for auto-transform -->
        <ng-container *ngIf="this.autoTransformResponse !== null">
            <ng-container *ngIf="this.autoTransformResponse.error; then displayAutoTransformError"></ng-container>
            <ng-container *ngIf="this.autoTransformResponse.failedResponses; then displayAutoTransformFailedResponses"></ng-container>
            <!-- display TEMPLATES for auto-transform response -->
            <ng-template #displayAutoTransformError>
                <h3 class="h3 text-center error">&#10060; Error: {{this.autoTransformResponse.error}}</h3>
            </ng-template>
            <ng-template #displayAutoTransformFailedResponses>
                <table class="table table-striped mt-4">
                    <thead class="thead-dark">
                        <th scope="col" class="col-2">Reference</th>
                        <th scope="col" class="col-2">Status</th>
                        <th scope="col" class="col-8">Error Message</th>
                    </thead>
                    <tbody class="table-borderless">
                        <tr *ngFor="let rule of this.autoTransformResponse.failedResponses">
                            <th scope="row" class="">{{rule.code}}</th>
                            <td class="">{{rule.status}}</td>
                            <td class="">{{rule.message}}</td>
                        </tr>
                    </tbody>
                </table>
            </ng-template>
        </ng-container>

        <!-- display CONDITION and TEMPLATE for sendToSmartDx -->
        <ng-container *ngIf="this.sendToSmartDxResponse !== null; then displaySendToSmartDxError">
        </ng-container>
        <ng-template #displaySendToSmartDxError>
            <h3 class="h3 text-center error">&#10060; Send to SmartDX Error: {{this.sendToSmartDxResponse.body}}</h3>
        </ng-template>

        <!-- display CONDITION and TEMPLATE for save-payload -->
        <ng-container *ngIf="this.savePayloadResponse !== null; then displaySavePayloadError"></ng-container>
        <ng-template #displaySavePayloadError>
            <h3 class="h3 text-center error">&#10060; Save Payload Error: {{this.savePayloadResponse.body}}</h3>
        </ng-template>

    </ng-container>

    <ng-template #noOutputToDisplay>
        <h1 class="text-center m-4">No output available to display yet!</h1>
    </ng-template>

    <button class="btn btn-primary output-rules-close" (click)="closeWindow()" *ngIf="this.isOpenedInSeparateWindow">Close</button>
</div>
