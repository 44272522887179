import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router'
import {Observable} from 'rxjs'
import {TestManagerService} from '../services/test-manager.service'
import {TestExecutionDetailResponse} from '../model/test-management-responses'

@Injectable({providedIn: 'root'})
export class TestExecutionResolver implements Resolve<TestExecutionDetailResponse> {
  constructor(private testManagerService: TestManagerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TestExecutionDetailResponse> {
    return this.testManagerService.getTestExecutionDetail(route.paramMap.get('executionId'), route.paramMap.get('testId'))
  }
}
