<div class="form-inline form-group">
  <input id="testFilter" type="text" class="form-control form-control-sm" (keyup)="setFilter($event.target.value)" placeholder="test filter"/>
</div>

<div class="operations">
  <button type="button" class="btn btn-primary" [disabled]="!canDownload" (click)="downloadSubmissionCsv()">Download Submission CSV</button>
  <button type="button" class="btn btn-primary" [disabled]="!canDownload" (click)="downloadCertCsv()">Download Cert CSV</button>
</div>

<div class="form-inline form-group">
  <section>
    <input id="typeahead-focus" class="form-control form-control-sm"
           [(ngModel)]="model"
           [ngbTypeahead]="search"
           (ngModelChange)="selectSeries($event)"
           [editable]='false'
           (focus)="focus$.next($any($event).target.value)"
           (click)="click$.next($any($event).target.value)"
           placeholder="Search Series"
           #instance="ngbTypeahead"/>
  </section>
</div>

<div class="series-operations">
  <button *ngIf="canSave" mat-button mat-raised-button color="accent" (click)="saveSeries()">Save Series</button>
  <button mat-button mat-raised-button color="warn" *ngIf="selectedSeries" (click)="deleteSeries()">
    Delete Series
  </button>
</div>

<div fxLayout="row" fxLayoutAlign="space-between">
  <div fxFlex class="tests-container">
    <div class="tests-header">Available</div>
    <div id="availableTests" dragula="DRAGULA_TESTS" [(dragulaModel)]="unselectedTests" class="tests-list">
      <div *ngFor='let test of unselectedTests; trackBy: name'>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="6px" fxFlexFill *ngIf="test.visible" class="test">
          <div class="test-name">
            {{test.isin}} - {{test.ref}}
          </div>
          <div class="select-test">
            <a (click)="addTestToSuite(test)"><fa-icon icon="chevron-circle-right"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex class="tests-container">
    <div class="tests-header">Selected Tests</div>
    <div id="selectedTests" dragula="DRAGULA_TESTS" [(dragulaModel)]="selectedTests" class="tests-list">
      <div *ngFor='let test of selectedTests; trackBy: name'>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="6px" fxFlexFill *ngIf="test.visible" class="test">
          <div class="remove-test">
            <a (click)="removeTestFromSuite(test)"><fa-icon icon="chevron-circle-left"></fa-icon></a>
          </div>
          <div class="test-name">
            {{test.isin}} - {{test.ref}}
          </div>
        </div>
      </div>
    </div>
    <div class="tests-header">Selected Series</div>
    <div *ngIf="selectedSeries != null">
      {{ selectedSeries.seriesId }} - {{ selectedSeries.isins }}
    </div>
  </div>
</div>
