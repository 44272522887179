import {DetailsResult, ErrorStageResult, FailedStageResult, SkippedStageResult, SuccessStageResult, WarningStageResult} from './lambdas'

export type AnalyseField = string

export type AnalyseFields = AnalyseField[]
export type AnalyseMatch = string | RegExp | object

export interface Deletion {
  user: string
  timestamp: string // ISO 8601 datetime in UTC timezone eg 1997-07-16T19:20:30.450Z
  comment: string // Jira and reason
}

export interface Replacement {
  user: string
  timestamp: string // ISO 8601 datetime in UTC timezone eg 1997-07-16T19:20:30.450Z
  comment: string // Jira and reason
  newMnemonic: string
}

export interface Approved {
  user: string
  timestamp: string // ISO 8601 datetime in UTC timezone eg 1997-07-16T19:20:30.450Z
  comment: string // Jira and reason
}

export class AnalyseRow {
  mnemonic: string
  expected: AnalyseMatch[]
  test?: string
  description?: string
  help?: string
  deletion?: Deletion
  replacedBy?: Replacement
  approved?: Approved
}

export type RuleType = 'blacklist' | 'whitelist' | 'validation' | 'notSupported' | 'governance' | 'complex'

export class AnalyseRuleTable {
  ruleType: RuleType
  name: string
  mnemonic: string
  fields: AnalyseFields = []
  rule: AnalyseRow[]
  description?: string
  help?: string
  deletion?: Deletion
  approvalRequired?: boolean
}
export type MatchResult = {
  value: AnalyseMatch
  matched: boolean
}
export type AnalyseMatchResult = MatchResult

export interface AnalyseRowResult extends AnalyseRow {
  matched: boolean
  expected: AnalyseMatchResult[]
}

export interface AnalyseRuleTableResult extends AnalyseRuleTable {
  rule: AnalyseRowResult[]
  actual: {
    value: string;
    values: string[]
  }[]
  matched: boolean
  linkedRuleResults?: ComplexRuleResults[]
  ignored?: boolean
}

export interface ComplexRuleResults {
  combination: string[],
  matched: boolean
  combinationResults: AnalyseRuleTableResult[]
}

export type RuleResults = {
  rules: AnalyseRuleTableResult[]
}

export type FailedRuleStatus = 'ERROR' | 'WARNING' | 'UNAPPROVED'

export type FailedRule = {
  status: FailedRuleStatus
  ruleType: RuleType
  ruleKey: string
  name: string
  description: string
  help?: string
}

export type UnapprovedRule = FailedRule & {
  status: 'UNAPPROVED'
  ruleType: 'whitelist'
}

export type FailedRuleResults = {
  failed: FailedRule[]
}

export type UnapprovedRuleResults = {
  unapproved: UnapprovedRule[]
}

export type AnalyseStage = 'analysePayload' | 'analyseEnriched'
export type AnalyseStageNames = {
  name: AnalyseStage
}

export interface ApprovalRequiredResults {
  approvalRequiredMnemonics: string[]
}

export type SuccessAnalyseResult = SuccessStageResult & DetailsResult & RuleResults & AnalyseStageNames
export type ApprovalRequiredAnalyseResult = SuccessStageResult & DetailsResult & RuleResults & AnalyseStageNames & ApprovalRequiredResults
export type WarningAnalyseResult = WarningStageResult & DetailsResult & RuleResults & AnalyseStageNames & FailedRuleResults
export type ApprovalRequiredWarningResult = WarningStageResult & DetailsResult & RuleResults & AnalyseStageNames & UnapprovedRuleResults & ApprovalRequiredResults
export type FailedAnalyseResult = FailedStageResult & DetailsResult & RuleResults & AnalyseStageNames & FailedRuleResults
export type SkippedAnalyseResult = SkippedStageResult & AnalyseStageNames
export type ErrorAnalyseResult = ErrorStageResult & AnalyseStageNames
export type AnalyseResult =
  SkippedAnalyseResult
  | WarningAnalyseResult
  | FailedAnalyseResult
  | ErrorAnalyseResult
  | SuccessAnalyseResult
  | ApprovalRequiredAnalyseResult
  | ApprovalRequiredWarningResult


export const isFailedAnalyseResult = (response: AnalyseResult): response is FailedAnalyseResult => {
  const failedResponse = response as FailedAnalyseResult
  return failedResponse
    && failedResponse.result === 'ERROR'
    && failedResponse.failed !== undefined
    && failedResponse.failed.length > 0
}

export const isWarningAnalyseResult = (response: AnalyseResult): response is WarningAnalyseResult => {
  const failedResponse = response as WarningAnalyseResult
  return failedResponse
    && failedResponse.result === 'WARNING'
    && failedResponse.failed !== undefined
    && failedResponse.failed.length > 0
}

export const isApprovalRequiredAnalyseResult = (response: AnalyseResult): response is ApprovalRequiredAnalyseResult => {
  const analyseResponse = response as ApprovalRequiredAnalyseResult
  return analyseResponse
    && analyseResponse.result === 'OK'
    && analyseResponse.approvalRequiredMnemonics !== undefined
    && analyseResponse.approvalRequiredMnemonics.length > 0
}
