import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';
import {IngestProductDocgenTraceResponse} from '../model/public-api-response';
import {Observable} from 'rxjs';

const uuid = require('uuid')

@Injectable({
  providedIn: 'root'
})
export class PlaygroundService {

  constructor(private apiService: ApiService) { }

  postToDocGen(xmlDoc: string): Promise<Observable<IngestProductDocgenTraceResponse>> {
    const url = `/gendoc/BorsaListing/en/all/Unused/all/${uuid.v4()}/Ignored`

    return new Promise<Observable<IngestProductDocgenTraceResponse>>(resolve => {
      const response: Observable<IngestProductDocgenTraceResponse> = this.apiService.post<string, IngestProductDocgenTraceResponse>(url, xmlDoc, {
        params: {
          testOnly: 'false',
          forceEvents: 'false',
          privateConfig: 'false',
          checkOnly: 'true',
          trace: 'true',
          forceGenerate: 'true',
        },
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/xml',
          source_system: 'sparrow',
        },
      });
      resolve(response);
    })
  }

}
