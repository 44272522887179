import {Component} from '@angular/core'
import {ConfigService} from '../services/config.service'
import {PublishConfigAndMetadata} from '../common/domain/publish-config'

interface SelectItem {
  value: string
  display: string
}

@Component({
  selector: 'app-publish-config-selector',
  templateUrl: './publish-config-selector.component.html',
  styleUrls: ['./publish-config-selector.component.scss'],
})
export class PublishConfigSelectorComponent {
  allConfigs: PublishConfigAndMetadata[]
  filteredConfigs: PublishConfigAndMetadata[]

  activeConfig = {
    sourceSystem: undefined,
    businessUnit: undefined,
    payloadType: undefined,
    targetSystem: undefined,
  }

  selections: {
    sourceSystems: SelectItem[],
    businessUnits: SelectItem[],
    payloadTypes: SelectItem[],
    targetSystems: SelectItem[],
  } = {
    sourceSystems: [],
    businessUnits: [],
    payloadTypes: [],
    targetSystems: [],
  }

  constructor(private configService: ConfigService) {
    this.configService.publishConfigs.subscribe(configs => {
      if (configs) {
        this.selections.sourceSystems = []
        this.selections.businessUnits = []
        this.selections.payloadTypes = []
        this.selections.targetSystems = []
        this.allConfigs = configs
        configs.forEach(config => {
          this.selections.sourceSystems = PublishConfigSelectorComponent.addToSelection(this.selections.sourceSystems, config.metadata.sourceSystem)
          this.selections.businessUnits = PublishConfigSelectorComponent.addToSelection(this.selections.businessUnits, config.metadata.businessUnit)
          this.selections.payloadTypes = PublishConfigSelectorComponent.addToSelection(this.selections.payloadTypes, config.metadata.payloadType)
          this.selections.targetSystems = PublishConfigSelectorComponent.addToSelection(this.selections.targetSystems, config.metadata.targetSystem)
        })
      }
      this.configService.selectedPublishConfig.subscribe(config => {
        if (config && config.metadata) {
          this.filteredConfigs = this.allConfigs
          this.activeConfig.sourceSystem = config && config.metadata && config.metadata.sourceSystem
          this.activeConfig.businessUnit = config && config.metadata && config.metadata.businessUnit
          this.activeConfig.payloadType = config && config.metadata && config.metadata.payloadType
          this.activeConfig.targetSystem = config && config.metadata && config.metadata.targetSystem
        }
      })
    })
  }

  static addToSelection = (selection: SelectItem[], value: string): SelectItem[] => {
    if (selection.find(s => s.value === value)) {
      return selection
    }

    const display = value
      // put a space between any lower-case letter and the upper-case letter following it
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      // put a space between any letter and the number following it
      .replace(/([a-zA-Z])(\d)/g, '$1 $2')
      // ensure the first letter is upper-cased
      .replace(/^[a-z]/, str => str.toUpperCase())
      // remove any white space left around the name
      .trim()

    return selection.concat({value, display})
  }

  selectSourceSystem(sourceSystem: string) {
    this.activeConfig.sourceSystem = sourceSystem
    this.activeConfig.businessUnit = undefined
    this.activeConfig.payloadType = undefined
    this.activeConfig.targetSystem = undefined
    this.filteredConfigs = this.allConfigs.filter(f => f.metadata.sourceSystem === sourceSystem)
    this.selections.businessUnits = this.filteredConfigs.reduce((p, c) => {
      return PublishConfigSelectorComponent.addToSelection(p, c.metadata.businessUnit)
    }, [])
    if (this.selections.businessUnits.length === 1) {
      this.selectProgramme(this.selections.businessUnits[0].value)
    }
  }

  selectProgramme(businessUnit: string) {
    this.activeConfig.businessUnit = businessUnit
    this.activeConfig.payloadType = undefined
    this.activeConfig.targetSystem = undefined
    this.filteredConfigs = this.allConfigs.filter(f => {
      return f.metadata.businessUnit === businessUnit
        && f.metadata.sourceSystem === this.activeConfig.sourceSystem
    })
    this.selections.payloadTypes = this.filteredConfigs.reduce((p, c) => {
      return PublishConfigSelectorComponent.addToSelection(p, c.metadata.payloadType)
    }, [])
    if (this.selections.payloadTypes.length === 1) {
      this.selectProductType(this.selections.payloadTypes[0].value)
    }
  }

  selectProductType(payloadType: string) {
    this.activeConfig.payloadType = payloadType
    this.activeConfig.targetSystem = undefined
    this.filteredConfigs = this.allConfigs.filter(f => {
      return f.metadata.payloadType === payloadType
        && f.metadata.businessUnit === this.activeConfig.businessUnit
        && f.metadata.sourceSystem === this.activeConfig.sourceSystem
    })
    this.selections.targetSystems = this.filteredConfigs.reduce((p, c) => {
      return PublishConfigSelectorComponent.addToSelection(p, c.metadata.targetSystem)
    }, [])
    if (this.selections.targetSystems.length === 1) {
      this.selectDocumentType(this.selections.targetSystems[0].value)
    }
  }

  selectDocumentType(targetSystem: string) {
    this.activeConfig.targetSystem = targetSystem
    this.configService.selectPublishConfig(this.activeConfig)
  }
}
