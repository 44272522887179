<ng-container *ngIf="loading">
  Loading...
</ng-container>

<ng-container *ngIf="!loading">
  <ng-container *ngIf="results.length === 0">
    <div>No Management Information Found</div>
  </ng-container>
  <ng-container *ngIf="results.length > 0">
    <button type="button" class="btn btn-primary" (click)="downloadCsv()">Download</button>
  </ng-container>
</ng-container>

<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date</th>
    <td mat-cell *matCellDef="let el">{{el.date}}</td>
  </ng-container>
  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef> Time</th>
    <td mat-cell *matCellDef="let el">{{el.time}}</td>
  </ng-container>
  <ng-container matColumnDef="environment">
    <th mat-header-cell *matHeaderCellDef> Environment</th>
    <td mat-cell *matCellDef="let el">{{el.environment}}</td>
  </ng-container>
  <ng-container matColumnDef="region">
    <th mat-header-cell *matHeaderCellDef> Region</th>
    <td mat-cell *matCellDef="let el">{{el.region}}</td>
  </ng-container>
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status</th>
    <td mat-cell *matCellDef="let el">{{el.status}}</td>
  </ng-container>
  <ng-container matColumnDef="jira">
    <th mat-header-cell *matHeaderCellDef> Jira</th>
    <td mat-cell *matCellDef="let el">
      <a [href]="el.jiraUrl">
        {{el.jira}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="issue">
    <th mat-header-cell *matHeaderCellDef> Issue Type</th>
    <td mat-cell *matCellDef="let el">{{el.issue}}</td>
  </ng-container>
  <ng-container matColumnDef="sparrowRef">
    <th mat-header-cell *matHeaderCellDef> Sparrow Ref</th>
    <td mat-cell *matCellDef="let el">
      <a [href]="el.sparrowProductUrl">
        {{el.sparrowRef}}
      </a>
  </ng-container>
  <ng-container matColumnDef="sparrowTicket">
    <th mat-header-cell *matHeaderCellDef> Sparrow Ticket</th>
    <td mat-cell *matCellDef="let el">
      <a [href]="el.sparrowTicketUrl">
        {{el.sparrowTicket}}
      </a>
    </td>
  </ng-container>
  <ng-container matColumnDef="config">
    <th mat-header-cell *matHeaderCellDef> Config Key</th>
    <td mat-cell *matCellDef="let el">{{el.config}}</td>
  </ng-container>
  <ng-container matColumnDef="missingWhitelist">
    <th mat-header-cell *matHeaderCellDef> Missing Whitelist</th>
    <td mat-cell *matCellDef="let el">{{el.missingWhitelist}}</td>
  </ng-container>
  <ng-container matColumnDef="blacklist">
    <th mat-header-cell *matHeaderCellDef> Blacklist</th>
    <td mat-cell *matCellDef="let el">{{el.blacklist}}</td>
  </ng-container>
  <ng-container matColumnDef="notSupportedYet">
    <th mat-header-cell *matHeaderCellDef> Not Supported Yet</th>
    <td mat-cell *matCellDef="let el">{{el.notSupportedYet}}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" showFirstLastButtons></mat-paginator>
