import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core'
import {DateTime} from 'luxon'
import {ENVIRONMENTS, REGIONS} from '../../../services/environment-service'
import {Subject} from 'rxjs'
import {debounceTime, distinctUntilChanged, switchMap, takeUntil, tap} from 'rxjs/operators'
import {MatTableDataSource} from '@angular/material/table'
import {ManagementInformationService, MiEvent} from '../../../services/management-information.service'
// import {stringify} from 'csv/lib/sync'
import {MatPaginator} from '@angular/material/paginator'
import { unparse } from 'papaparse'
import saveAs from 'file-saver'
const equals = require('fast-deep-equal')

interface ApprovalsMiEvent extends MiEvent {
  source: 'inspire.approvals-management'
  detail: {
    approvals: [
      {
        pipelineName: string
        pipelineExecutionId: string
        pipelineApprovalStage: string
        pipelineApprovalAction: string
        pipelineApprovalToken: string
      }
    ],
    jira: string
    comment: string
    username: string
    email: string
  }
}

interface FlattenedApprovalsMiEvent {
  timestamp: string
  date: string
  time: string
  user: string
  jira: string
  jiraUrl: string
  comment: string
  environment: string
}

interface Request {
  fromDate: string
  toDate: string
}

@Component({
  selector: 'app-approvals-management-information',
  templateUrl: './approvals-management-information.component.html',
  styleUrls: ['./approvals-management-information.component.scss'],
})
export class ApprovalsManagementInformationComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  loading = true
  cancelRequests = new Subject<void>()

  @Input() fromDate: string
  @Input() toDate: string

  results: FlattenedApprovalsMiEvent[] = []
  columns: string[] = [
    'date',
    'time',
    'environment',
    'user',
    'jira',
    'comment',
  ]
  dataSource = new MatTableDataSource<FlattenedApprovalsMiEvent>()
  requests = new Subject<Request>()

  constructor(private managementInformationService: ManagementInformationService) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
  }

  ngOnInit(): void {
    // this.loading = true
    this.requests
      .pipe(
        takeUntil(this.cancelRequests),
        debounceTime(1000),
        distinctUntilChanged(equals),
        tap(_ => this.loading = true),
        tap(request => {
          console.log('Processing: ')
          console.dir(request)
        }),
        switchMap((request: Request) => {
          if (request.fromDate) {
            const dateRange = request.fromDate + (request.toDate ? '/' + request.toDate : '')
            return this.managementInformationService.getManagementInformation<ApprovalsMiEvent[]>(
              ENVIRONMENTS.STAGING.id,
              REGIONS.London.id,
              'approvals-management',
              dateRange)
              .pipe(
                tap(results => {
                  console.log('Fetched Mi Data')
                  console.dir(results)
                }),
                tap((results: ApprovalsMiEvent[]) => {
                  console.log('Raw Mi Data')
                  console.dir(results)
                }),
                tap(_ => this.loading = false),
              )
          } else {
            return []
          }
        }),
      )
      .subscribe((events: ApprovalsMiEvent[]) => {
        const flattened = events.map(e => this.format(e))
        flattened.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
        this.results = flattened
        this.dataSource.data = this.results
      })
    this.ngOnChanges(undefined)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.requests.next({
      fromDate: this.fromDate,
      toDate: this.toDate,
    })
  }

  ngOnDestroy(): void {
    this.cancelRequests.next()
  }

  format(e: ApprovalsMiEvent): FlattenedApprovalsMiEvent {
    const detail = e.detail
    const target = e.detail.approvals[0].pipelineApprovalStage
      .replace('DeployTo', '')
    return {
      timestamp: e.time,
      date: DateTime.fromISO(e.time).toFormat('dd/MM/yyyy'),
      time: DateTime.fromISO(e.time).toFormat('HH:mm:ss'),
      environment: target,
      user: detail.email,
      jira: detail.jira,
      jiraUrl: 'https://deltacapitams.atlassian.net/browse/' + detail.jira,
      comment: detail.comment,
    }
  }

  downloadCsv() {
    const csv = unparse(this.results, {
      header: true,
    })
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
    const filename = ('mi-approvals-' + this.fromDate + (this.toDate ? '-to-' + this.toDate : '') + '.csv')
    console.log('Saving: ' + filename)
    saveAs(blob, filename)
  }
}
