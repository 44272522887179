<h1 mat-dialog-title>{{data.title}}</h1>
<button mat-button (click)="toggleSelectAllOrNone()">Toggle Select All</button>
<div mat-dialog-content>
  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <li class="mat-tree-node">
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        <mat-checkbox class="checklist-leaf-node"
                      [checked]="isSelected(node)"
                      (change)="toggleSelection(node)">{{node.name}}</mat-checkbox>
      </li>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
      <li>
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + node.name">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-checkbox class="checklist-leaf-node"
                        [checked]="isSelected(node)"
                        (change)="toggleSelection(node)">{{node.name}}</mat-checkbox>
        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button (click)="ok()" cdkFocusInitial *ngIf="okEnabled">OK</button>
</div>
