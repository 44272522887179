<div class="container-fluid mb-2">
  <app-test-suite-selector></app-test-suite-selector>
  <div class="form-inline">
    <app-schema-selector label="Source Schema" (schema)="setSchema($event)"></app-schema-selector>
    <app-transform-selector (transform)="setTransform($event)"></app-transform-selector>
    <app-schema-selector label="Target Schema" (schema)="setTargetSchema($event)"></app-schema-selector>
  </div>
  <mat-slide-toggle [checked]="testMode" (change)="toggleTestMode($event.checked)">Test Run (don't apply changes)
</mat-slide-toggle>
</div>



<ng-container *ngIf="error">
  <div class="error">{{error}}</div>
</ng-container>

<div class="container-fluid h-75">
  <ul ngbNav #migrateTestNav="ngbNav" class="nav-tabs" (shown)="shown($event)">
    <li ngbNavItem>
      <a ngbNavLink>Source Schema</a>
      <ng-template ngbNavContent>
        <div class="source-schema-input">
          <ngx-monaco-editor
            class="monaco-editor source-schema-editor"
            [options]="xmlEditorConfig"
            [(ngModel)]="sourceSchema"
          >
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem>
      <a ngbNavLink>Transform</a>
      <ng-template ngbNavContent>
        <div class="transform-input">
          <ngx-monaco-editor
            class="monaco-editor transform-editor"
            [options]="xmlEditorConfig"
            [(ngModel)]="transform"
          >
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem>
      <a ngbNavLink>Target Schema</a>
      <ng-template ngbNavContent>
        <div class="target-schema-input">
          <ngx-monaco-editor
            class="monaco-editor target-schema-editor"
            [options]="xmlEditorConfig"
            [(ngModel)]="targetSchema"
          >
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="testResponses.length > 0">
      <a ngbNavLink>Summary Response</a>
      <ng-template ngbNavContent>
        <div class="container-fluid">
          <table mat-table [dataSource]="testResponses">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name</th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="validated">
              <th mat-header-cell *matHeaderCellDef> Source Validated</th>
              <td mat-cell *matCellDef="let element"> {{element.validateSource}} </td>
            </ng-container>
            <ng-container matColumnDef="migrated">
              <th mat-header-cell *matHeaderCellDef> Migrated Successfully</th>
              <td mat-cell *matCellDef="let element"> {{element.validateMigrated}} </td>
            </ng-container>
            <ng-container matColumnDef="errors">
              <th mat-header-cell *matHeaderCellDef> Errors</th>
              <td mat-cell *matCellDef="let element">
                {{element.error}}
                <ng-container *ngIf="element.errors && element.errors.length > 0">
                  <ul>
                    <li *ngFor="let e of element.errors">
                      {{e.line}}:{{e.column}} {{e.message}}
                    </li>
                  </ul>
                </ng-container>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="results">
      <a ngbNavLink>Detailed JSON Response</a>
      <ng-template ngbNavContent>
        <div class="container-fluid">
          <ngx-monaco-editor
            class="monaco-editor target-schema-editor"
            [options]="jsonReadonlyConfig"
            [(ngModel)]="results"
          >
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="migrateTestNav"></div>
</div>

<ng-container class="actions">
  <button type="button" class="btn btn-primary mt-2 me-2" (click)="migrate()"
          [disabled]="!(selectedTestSuite && sourceSchema && transform && targetSchema)">Migrate
  </button>
  <button type="button" class="btn btn-primary mt-2 me-2" (click)="migrateBatch()"
          [disabled]="selectedTestSuite || !(sourceSchema && transform && targetSchema)">Migrate Batch
  </button>
  <button type="button" class="btn btn-info mt-2" (click)="downloadMigrationPack()"
          [disabled]="!(testResponses && testResponses.length > 0)">Download Migration Pack
  </button>
</ng-container>
