import {MatDialog} from '@angular/material/dialog'
// import * as stringify from 'csv-stringify/lib/sync'
import {combineLatest} from 'rxjs'
import {take} from 'rxjs/operators'
import {ApprovalConfig} from 'src/app/common/domain/approval'
import {ChangeDetails, DocgenConfigAndMetadataChangeDetails} from 'src/app/common/domain/change'
import {DocgenConfig} from 'src/app/common/domain/docgen-config'
import {ChangeService} from 'src/app/services/change.service'
import {AnalyseRuleTable} from '../../../common/domain/analyse'
import {NewConfigRulesComponent} from '../config-rules.component'
import {DisplayableRuleTable} from './interfaces'
import { unparse } from 'papaparse'
import saveAs from 'file-saver'
export class UserActionDownloads {

  private parentComponent: NewConfigRulesComponent
  private dialog: MatDialog
  private changeService: ChangeService

  constructor(
    dialog: MatDialog,
    changeService: ChangeService,
    parentControl: NewConfigRulesComponent,
  ) {
    this.dialog = dialog
    this.changeService = changeService
    this.parentComponent = parentControl
  }

  public downloadRulesForSelectedConfig() {
    return this.downloadUniqueRules(this.parentComponent.selectedConfigPath.replace('settings/', '').replace('/Config.json', ''))
  }

  public downloadRulesAsCsv(rule: DisplayableRuleTable) {
    combineLatest([
      this.changeService.getObjectsOfType('approvals')
        .pipe(
          take(1),
        ),
      this.changeService.getUserFilteredDocgenConfigs()
        .pipe(
          take(1),
        ),
    ]).subscribe(([approvals, configs]: [ChangeDetails[], DocgenConfigAndMetadataChangeDetails[]]) => {
      // console.dir(approvals)
      const csvData = rule.rule
        .reduce((rows, rulerow, index) => {
          rulerow.configKeys.forEach((configKey) => {
            const parts = configKey.split('/')
            const sourceSystem = parts[0]
            const programme = parts[1]
            const productType = parts[2]
            const documentType = parts[3]
            const usage = rulerow.usedBy[configKey]
            const configData = configs.find(c => {
              return c.metadata.programme === programme
                && c.metadata.sourceSystem === sourceSystem
                && c.metadata.productType === productType
                && c.metadata.documentType === documentType
            })
            const usedConfig = JSON.parse(configData.settings.content) as DocgenConfig
            const usedAnalysis = usage.stage === 'product' ? usedConfig.analysePayload : usedConfig.analyseEnriched
            const usedRules = usedAnalysis.rules.find(r => r.mnemonic === rule.mnemonic)
            const usedRule = usedRules.rule.find(r => r.mnemonic === rulerow.mnemonic)

            let prefix = 'UK'
            if (usage.stage === 'product') {
              if (usage.ruleType === 'whitelist') {
                prefix = 'PW'
              } else if (usage.ruleType === 'blacklist') {
                prefix = 'PB'
              } else if (usage.ruleType === 'notSupported') {
                prefix = 'PU'
              } else if (usage.ruleType === 'validation') {
                prefix = 'PV'
              }
            } else if (usage.stage === 'solo') {
              if (usage.ruleType === 'whitelist') {
                prefix = 'SW'
              } else if (usage.ruleType === 'blacklist') {
                prefix = 'SB'
              } else if (usage.ruleType === 'notSupported') {
                prefix = 'SU'
              } else if (usage.ruleType === 'validation') {
                prefix = 'SV'
              }
            }
            const ruleRowMnemonic = `${prefix}-${rule.mnemonic}-${rulerow.mnemonic}`
            const approvalRequired = usedRules.approvalRequired ? 'YES' : ''

            const result = {
              sourceSystem: sourceSystem,
              programme: programme,
              productType: productType,
              documentType: documentType,
              stage: usage.stage,
              ruleType: usage.ruleType,
              ruleMnemonic: ruleRowMnemonic,
              approvalRequired: approvalRequired,
              approvedBy: usedRule.approved?.user || '',
              approvedDate: usedRule.approved?.timestamp || '',
            }
            rule.fields.forEach((f, i) => {
              result[f] = result[f]
                ? result[f] !== rulerow.expected[i]
                  ? result[f] + ' AND ' + rulerow.expected[i]
                  : rulerow.expected[i]
                : rulerow.expected[i]
            })
            if (usedRules.approvalRequired) {
              const approvalData = approvals.find(a => a.path === `approvals/${sourceSystem}/${programme}/${productType}/${documentType}/Approvals.json`)
              if (approvalData) {
                const approvalConfig = JSON.parse(approvalData.content) as ApprovalConfig
                const approval = approvalConfig.approvedMnemonics.find(a => a.mnemonic === ruleRowMnemonic)
                if (approval) {
                  result.approvedBy = approval.approvedBy
                  result.approvedDate = approval.approvedDate
                }
              }
            }

            rows.push(Object.assign(result, {
              ruleDescription: usage.description,
              ruleHelp: usage.help,
              // plugh Temporarily do not include test in the CSV (make Binary Equal Easier)              test: usage.test,
            }))
          })
          return rows
        }, [])

      const allCsv = unparse(csvData, {
        header: true,
      })

      const blob = new Blob([allCsv], {type: 'text/csv;charset=utf-8'})
      saveAs(blob, `rule-${rule.ruleType}-${rule.mnemonic}-${new Date().toISOString()}.csv`)
    })
  }

  public downloadSelectedRules() {
    const ruleArray = this.parentComponent.displayedRules
    this.downloadRules(ruleArray)
  }

  public downloadAllRules() {
    return this.downloadUniqueRules(undefined)
  }

  public downloadAllRulesNewFormat() {
    function asCsvRows(ruleset: AnalyseRuleTable, ruleStage: string, ruleStagePrefix: string, originalTemplate: {fastApprovedBy: string; fastApprovedDate: string; documentType: string; sourceSystem: string; targetMnemonic: string; ruleStage: string; description: string; slowApprovedDate: string; deletedBy: string; help: string; deletedDate: string; approvalRequired: string; ruleType: string; name: string; mnemonic: string; slowApprovedBy: string; programme: string; productType: string; emptyRuleset: string}, approvedMnemonics: any[]) {
      const template = Object.assign({}, originalTemplate)
      template.ruleStage = ruleStage
      template.ruleType = ruleset.ruleType
      template.deletedBy = ruleset.deletion?.user || ''
      template.deletedDate = ruleset.deletion?.timestamp || ''
      template.approvalRequired = ruleset.approvalRequired ? 'YES' : ''
      Array.from(Array(12).keys()).forEach(i => {
        template['field' + i] = ruleset.fields.length > (i - 1) ? ruleset.fields[i] : ''
        template['value' + i] = ''
      })
      let prefix = 'UK'
      if (ruleset.ruleType === 'whitelist') {
        prefix = ruleStagePrefix + 'W'
      } else if (ruleset.ruleType === 'blacklist') {
        prefix = ruleStagePrefix + 'B'
      } else if (ruleset.ruleType === 'notSupported') {
        prefix = ruleStagePrefix + 'U'
      } else if (ruleset.ruleType === 'validation') {
        prefix = ruleStagePrefix + 'V'
      }
      if (ruleset.rule?.length > 0) {
        return ruleset.rule.map(rule => {
          const mnemonic = prefix + '-' + ruleset.mnemonic + '-' + rule.mnemonic
          const approval = approvedMnemonics.find(a => a.mnemonic === mnemonic)
          const row = Object.assign({}, template, {
            name: ruleset.name || '',
            description: ruleset.description || '',
            help: ruleset.help || '',
            mnemonic: mnemonic,
            deletedBy: rule.deletion?.user || ruleset.deletion?.user || '',
            deletedDate: rule.deletion?.timestamp || ruleset.deletion?.timestamp || '',
            slowApprovedBy: rule.approved?.user || '',
            slowApprovedDate: rule.approved?.timestamp || '',
            fastApprovedBy: approval?.approvedBy || '',
            fastApprovedDate: approval?.approvedDate || '',
          })
          Array.from(Array(12).keys()).forEach(i => {
            row['value' + i] = rule.expected.length > (i - 1) ? rule.expected[i] : ''
          })

          return row
        })
      } else {
        return [Object.assign({}, template, {
          name: ruleset.name || '',
          description: ruleset.description || '',
          help: ruleset.help || '',
          mnemonic: prefix + '-' + ruleset.mnemonic,
          emptyRuleset: 'YES'
        })]
      }
    }

    combineLatest([
      this.changeService.getObjectsOfType('approvals')
        .pipe(
          take(1),
        ),
      this.changeService.getAllDocgenConfigs()
        .pipe(
          take(1),
        ),
    ]).subscribe(([approvals, configs]: [ChangeDetails[], DocgenConfigAndMetadataChangeDetails[]]) => {
      const csvData = configs.reduce((allRows, config) => {
        const configObject = JSON.parse(config.settings.content) as DocgenConfig
        const parts = config.settings.path.split('/')
        const sourceSystem = parts[1]
        const programme = parts[2]
        const productType = parts[3]
        const documentType = parts[4]

        const approvalData = approvals.find(a => a.path === `approvals/${sourceSystem}/${programme}/${productType}/${documentType}/Approvals.json`)
        let approvedMnemonics = []
        if (approvalData) {
          const approvalConfig = JSON.parse(approvalData.content) as ApprovalConfig
          approvedMnemonics = approvalConfig.approvedMnemonics
        }

        const template = {
          sourceSystem: sourceSystem,
          programme: programme,
          productType: productType,
          documentType: documentType,
          name: '',
          description: '',
          help: '',
          ruleStage: '',
          ruleType: '',
          mnemonic: '',
          targetMnemonic: '',
          emptyRuleset: '',
          approvalRequired: '',
          fastApprovedBy: '',
          fastApprovedDate: '',
          slowApprovedBy: '',
          slowApprovedDate: '',
          deletedBy: '',
          deletedDate: '',
        }
        Array.from(Array(12).keys()).forEach(i => {
          template['field' + i] = ''
          template['value' + i] = ''
        })
        const analysePayloadRows = configObject.analysePayload.rules.reduce((acc, ruleset) => {
          const csvRows = asCsvRows(ruleset, 'Payload', 'P', template, approvedMnemonics)
          return acc.concat(...csvRows)
        }, [])
        const analyseEnrichedRows = configObject.analyseEnriched.rules.reduce((acc, ruleset) => {
          const csvRows = asCsvRows(ruleset, 'Solo', 'S', template, approvedMnemonics)
          return acc.concat(...csvRows)
        }, [])
        return allRows.concat(...analysePayloadRows).concat(...analyseEnrichedRows)
      }, [])

      const allCsv = unparse(csvData, {
        header: true,
      })

      const blob = new Blob([allCsv], {type: 'text/csv;charset=utf-8'})
      saveAs(blob, `all-rules-${new Date().toISOString()}.csv`)
    })
  }

  public downloadAllErrorMessages() {
    const csvData = []

    this.changeService.getUserFilteredDocgenConfigs()
      .pipe(
        take(1),
      )
      .subscribe(docgens => {
        docgens.forEach(config => {
          const dc = JSON.parse(config.settings.content) as DocgenConfig
          dc.analysePayload.rules
            .filter(r => r.ruleType === 'whitelist')
            .forEach(rule => {
              csvData.push(Object.assign({
                menmonic: 'PW-' + rule.mnemonic,
                name: rule.name,
                type: rule.ruleType,
                description: rule.description || rule.name,
                help: rule.help || '',
              }, config.metadata))
            })
          dc.analysePayload.rules
            .filter(r => r.ruleType === 'blacklist')
            .forEach(rule => {
              csvData.push(Object.assign({
                menmonic: 'PB-' + rule.mnemonic,
                name: rule.name,
                type: rule.ruleType,
                description: rule.description || rule.name,
                help: rule.help || '',
              }, config.metadata))
              rule.rule.forEach(row => {
                csvData.push(Object.assign({
                  menmonic: 'PB-' + rule.mnemonic + '-' + row.mnemonic,
                  name: '',
                  type: rule.ruleType,
                  description: row.description || rule.description || rule.name,
                  help: row.help || rule.help,
                }, config.metadata))
              })
            })
          dc.analysePayload.rules
            .filter(r => r.ruleType === 'notSupported')
            .forEach(rule => {
              csvData.push(Object.assign({
                menmonic: 'PU-' + rule.mnemonic,
                name: rule.name,
                type: rule.ruleType,
                description: rule.description || rule.name,
                help: rule.help || '',
              }, config.metadata))
              rule.rule.forEach(row => {
                csvData.push(Object.assign({
                  menmonic: 'PU-' + rule.mnemonic + '-' + row.mnemonic,
                  name: '',
                  type: rule.ruleType,
                  description: row.description || rule.description || rule.name,
                  help: row.help || rule.help,
                }, config.metadata))
              })
            })
          dc.analyseEnriched.rules
            .filter(r => r.ruleType === 'whitelist')
            .forEach(rule => {
              csvData.push(Object.assign({
                menmonic: 'SW-' + rule.mnemonic,
                name: rule.name,
                type: rule.ruleType,
                description: rule.description || rule.name,
                help: rule.help || '',
              }, config.metadata))
            })
          dc.analyseEnriched.rules
            .filter(r => r.ruleType === 'blacklist')
            .forEach(rule => {
              csvData.push(Object.assign({
                menmonic: 'SB-' + rule.mnemonic,
                name: rule.name,
                type: rule.ruleType,
                description: rule.description || rule.name,
                help: rule.help || '',
              }, config.metadata))
              rule.rule.forEach(row => {
                csvData.push(Object.assign({
                  menmonic: 'SB-' + rule.mnemonic + '-' + row.mnemonic,
                  name: '',
                  type: rule.ruleType,
                  description: row.description || rule.description || rule.name,
                  help: row.help || rule.help,
                }, config.metadata))
              })
            })
          dc.analyseEnriched.rules
            .filter(r => r.ruleType === 'notSupported')
            .forEach(rule => {
              csvData.push(Object.assign({
                menmonic: 'SU-' + rule.mnemonic,
                name: rule.name,
                type: rule.ruleType,
                description: rule.description || rule.name,
                help: rule.help || '',
              }, config.metadata))
              rule.rule.forEach(row => {
                csvData.push(Object.assign({
                  menmonic: 'SU-' + rule.mnemonic + '-' + row.mnemonic,
                  name: '',
                  type: rule.ruleType,
                  description: row.description || rule.description || rule.name,
                  help: row.help || rule.help,
                }, config.metadata))
              })
            })
        })
      })

    const allCsv = unparse(csvData, {
      header: true,
    })

    const blob = new Blob([allCsv], {type: 'text/csv;charset=utf-8'})
    saveAs(blob, 'errorMessages.csv')

  }

  private downloadUniqueRules(configKey?: string, useOnlyRationlised = false) {
    const ruleArray = this.parentComponent.uniqueRules
    this.downloadRules(ruleArray, configKey, useOnlyRationlised)
  }

  private downloadRules(ruleArray: DisplayableRuleTable[], configKey?: string, useOnlyRationlised = false) {
    let allCsv = ''

    ruleArray.filter(r => {
      // console.log('' + configKey + ' in ' + r.configKeys.join(','))
      return configKey ? r.configKeys.includes(configKey) : true
    })
      .forEach(ruleset => {
        allCsv = allCsv + ruleset.mnemonic + ' - ' + ruleset.name + '\n'
        const csvData = ruleset.rule
          .reduce((prev, rulerow, index) => {
            rulerow.configKeys.forEach((ck) => {
              const usage = rulerow.usedBy[ck]
              let prefix = 'UK'
              if (usage.stage === 'product') {
                if (usage.ruleType === 'whitelist') {
                  prefix = 'PW'
                } else if (usage.ruleType === 'blacklist') {
                  prefix = 'PB'
                } else if (usage.ruleType === 'notSupported') {
                  prefix = 'PU'
                } else if (usage.ruleType === 'validation') {
                  prefix = 'PV'
                }
              } else if (usage.stage === 'solo') {
                if (usage.ruleType === 'whitelist') {
                  prefix = 'SW'
                } else if (usage.ruleType === 'blacklist') {
                  prefix = 'SB'
                } else if (usage.ruleType === 'notSupported') {
                  prefix = 'SU'
                } else if (usage.ruleType === 'validation') {
                  prefix = 'SV'
                }
              }

              const result = {
                config: ck,
                stage: usage.stage,
                ruleType: usage.ruleType,
                ruleMnemonic: prefix + '-' + ruleset.mnemonic + '-' + rulerow.mnemonic,
                ruleDescription: usage.description,
                ruleHelp: usage.help,
                test: usage.test,
              }
              ruleset.fields.forEach((f, i) => {
                result[f] = result[f]
                  ? result[f] !== rulerow.expected[i]
                    ? result[f] + ' AND ' + rulerow.expected[i]
                    : rulerow.expected[i]
                  : rulerow.expected[i]
              })
              if (configKey === undefined || configKey === ck) {
                prev.push(result)
              }
            })
            return prev
          }, [])
        allCsv = allCsv + unparse(csvData, {
          header: true,
        })
        allCsv = allCsv + '\n'
      })

    const blob = new Blob([allCsv], {type: 'text/csv;charset=utf-8'})
    saveAs(blob, (configKey ? configKey.replace(/\//g, '-') : 'all') + '-rules.csv')
  }

  // public downloadCsvForRationlisedRule(selectedRule: RationalisedRule | null) {
  //     let rationalisedRulesToSave = new Array<RationalisedRule>();

  //     // Verify this is a Rationalised Rule
  //     if (this.parentComponent.protectedRules.findIndex(x => x.mnemonic == selectedRule.mnemonic) === -1) {
  //         console.error(selectedRule.mnemonic + ' is not a Rationalised Rule')
  //         return
  //     }

  //     const rule = this.parentComponent.allRules.find(x => x.mnemonic == selectedRule.mnemonic)
  //     this.downloadRulesAsCsv(rule)
  // }

  actionDownloadCsvForAllRationlisedRules() {
    // The orignal method downloadRules was tweaked to handle only Rationalised Rules
    this.downloadUniqueRules(undefined, true)
  }

}
