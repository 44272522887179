import {BehaviorSubject} from 'rxjs'

const deepEqual = require('deep-equal')

export class ChangeDetectingBehaviourSubject<T> extends BehaviorSubject<T> {
  private name: string
  private debug: boolean
  private callback: (t: T) => void

  constructor(name: string, initialValue: T, debug: boolean = false, callback?: (t: T) => void | string) {
    super(initialValue)
    this.name = name
    this.debug = debug
    this.callback = callback || (debug ? (t) => {
      console.dir(t)
    } : (t) => {
      return t
    })
  }

  next(value: T): void {
    const currentValue = this.getValue()
    if (!value && currentValue === undefined) {
      return
    }
    if (!deepEqual(value, currentValue, true)) {
      if (this.debug) {
        if (this.callback) {
          console.log('Updating ' + this.name)
          console.log('Old Value:')
          this.callback(currentValue)
          console.log('New Value:')
          this.callback(value)
        } else {
          console.log('Updating ' + this.name + ' from ' + this.callback(currentValue) + ' to ' + this.callback(value))
        }
      }
      super.next(value)
    }
  }

  forcedNext(value: T): void {
    super.next(value)
  }
}
