<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p>{{message}}</p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-primary" ngbAutoFocus (click)="confirm()">{{action}}</button>
</div>
