import {Component, Input, OnInit, Output} from '@angular/core'
import {TestHistory} from '../../model/test-management-responses'
import {Subject} from 'rxjs'

@Component({
  selector: 'app-test-history-list',
  templateUrl: './test-history-list.component.html',
  styleUrls: ['./test-history-list.component.scss'],
})
export class TestHistoryListComponent implements OnInit {
  @Input() history: TestHistory[] = []
  @Output() selectedVersion = new Subject<TestHistory>()

  constructor() {
  }

  ngOnInit(): void {
  }

  selectVersion(history: TestHistory) {
    const value = Object.assign({}, history, {
      date: typeof history.date === 'string' ? new Date(history.date) : history.date
    })
    this.selectedVersion.next(value)
  }
}
