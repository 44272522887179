<div class="outer-container d-flex flex-column">
  <div class="header">
    <h1 class="display-6">Administer Validation Rules</h1>
  </div>
  <!-- filter buttons section -->
  <div class="actions d-flex">
    <button class="btn btn-dark"    (click)="filterData('active')">All</button>
    <button class="btn btn-info"    (click)="filterData('simple')">Simple</button>
    <button class="btn btn-primary" (click)="filterData('complex')">Complex</button>
    <button class="btn btn-success" (click)="filterData('combo')">Combo</button>
    <button class="btn btn-danger"  (click)="filterData('deleted')">Deleted</button>
  </div>
  <!-- new rules buttons section -->
  <div class="new-rules-btns d-flex">
    <button class="btn btn-info m-2" (click)="openSimpleRuleDialog(content, undefined)">
      New Simple Rule
    </button>
    <button class="btn btn-primary m-2" (click)="openComplexRuleDialog(content, undefined)">
      New Complex/Combo Rule
    </button>
  </div>
  <!-- rules table -->
  <ng-container>
    <table class="table table-striped m-2 mt-3">
      <thead class="thead-dark"> 
        <tr>
          <th scope="col" class="col-1 p-1">
          <div class="pointer" (click)="sortTableData('id')">Reference</div>
          </th>
          <th scope="col" class="col-6">
            <div class="pointer" (click)="sortTableData('description')">Description</div>
          </th>
          <th scope="col" class="col-2 text-center" >
           <div class="pointer" (click)="sortTableData('date_updated')">Date Updated</div>  
          </th>
          <th scope="col" class="col-2 text-center">Actions</th>
        </tr>
      </thead>
      <tbody class="table-borderless">
        <tr *ngFor="let rule of this.rulesDisplayed">
          <th class="p-1" scope="row">{{rule.id}}</th>
          <td class="p-2">{{rule.description}}</td>
          <th class="text-center" scope="row" >{{rule.date_updated | amLocal | amDateFormat: 'DD/MM/YYYY HH:mm'}}</th>
          <td class="d-flex justify-content-center">
            <button class="btn btn-primary col-4 mr-2" (click)="editRule(content, rule)" *ngIf="!rule.deleted">Edit</button>
            <button class="btn btn-danger col-4" (click)="deleteRule(rule.id)" *ngIf="!rule.deleted">Delete</button>
            <button class="btn btn-info col-4" (click)="restoreRule(rule.id)" *ngIf="rule.deleted">Restore</button>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <!-- Pop-up modal  -->

  <ng-template #content let-modal>
    <div class="modal-header">
      <ng-container *ngIf="simpleRule; else complexRule">
        <h4 class="modal-title" id="modal-basic-title">{{editMode ? 'Edit ' : 'New '}} Simple Rule</h4>
      </ng-container>
      <ng-template #complexRule>
        <h4 class="modal-title" id="modal-basic-title">
          {{editMode ? 'Edit ' : 'New '}} Complex/Combo Rule
        </h4>
      </ng-template>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="this.editMode = false; modal.dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" ngbAutofocus>
      <ng-container *ngIf="simpleRule; else complexRuleModal">
        <app-simple-rule [modal]="modal" [simpleRule]="this.selectedRule" [(isEditMode)]="this.editMode"></app-simple-rule>
      </ng-container>
      <ng-template #complexRuleModal>
        <app-complex-rules [modal]="modal" [selectedRule]="this.selectedRule" [activeRules]="this.activeRules" [(isEditMode)]="this.editMode"></app-complex-rules>
      </ng-template>
    </div>
    <div class="modal-footer"></div>
  </ng-template>

  <!-- <button class="btn btn-lg btn-outline-primary" (click)="open(content)">
    Launch demo modal
  </button>

  <hr /> -->
</div>
