<div
  class="flex-content-container fix-mat-dialog-borders"
  [class.disable-mouse]="this.CurrentlyExecuting"
>
  <div class="flex-row">
    <span class="two-x-font">Edit RuleSet: {{ this.dialogData.ruleSetMnemonic }}</span
    >
  </div>

  <div class="flex-column">
    <label for="ruleSetDescription">Description:</label>
    <input
      id="ruleSetDescription"
      type="text"
      class="form-control form-control-sm"
      maxlength="80"
      style="width: 30rem"
      [ngModel]="this.dialogData.ruleSetDescription"
      (ngModelChange)="this.actionDescriptionChanged($event)"
      required
    />
  </div>

  <div class="flex-content-row flex-grow-one">
    <div class="flex-grid-left">
      <div mat-dialog-content>
        <mat-tree
          [dataSource]="dataSource"
          [treeControl]="treeControl"
          class="example-tree"
        >
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node
            *matTreeNodeDef="let node"
            matTreeNodeToggle
            matTreeNodePadding
          >
            <li class="mat-tree-node">
              <!-- use a disabled button to provide padding for tree leaf -->
              <button mat-icon-button disabled></button>
              <mat-checkbox
                class="checklist-leaf-node"
                [checked]="isSelected(node)"
                (change)="actionToggleSelection(node)"
                >{{ node.name }}</mat-checkbox
              >
            </li>
          </mat-tree-node>

          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node
            *matTreeNodeDef="let node; when: hasChild"
            matTreeNodePadding
          >
            <li>
              <div class="mat-tree-node">
                <button
                  mat-icon-button
                  matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + node.name"
                >
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{
                      treeControl.isExpanded(node)
                        ? "expand_more"
                        : "chevron_right"
                    }}
                  </mat-icon>
                </button>
                <mat-checkbox
                  class="checklist-leaf-node"
                  [checked]="isSelected(node)"
                  (change)="actionToggleSelection(node)"
                  >{{ node.name }}</mat-checkbox
                >
              </div>
              <ul
                [class.example-tree-invisible]="!treeControl.isExpanded(node)"
              >
                <ng-container matTreeNodeOutlet></ng-container>
              </ul>
            </li>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
    </div>
    <!-- class='flex-grid-left'> -->

    <div class="flex-grid-right"></div>
  </div>
  <!-- flex-content-container -->

  <div class="flex-row">
    <button type="button" class="btn btn-primary" (click)="this.actionCancel()">
      Cancel
    </button>
    &nbsp;&nbsp;&nbsp;
    <button
      type="button"
      class="btn btn-primary"
      (click)="this.actionSave()"
      [disabled]="this.isDirty == false"
    >
      Save
    </button>
  </div>
</div>
<!-- class='flex-content-container' -->
