<h1>Payload Inspector</h1>

<app-test-suite-selector></app-test-suite-selector>

<div class="md-form form-inline form-group">
  <mat-form-field class="test-suite-selection">
    <mat-label>Path Selection</mat-label>
    <mat-select (selectionChange)="selectPaths($event)" [value]="selectedXpaths" placeholder="Paths">
      <mat-option *ngFor="let paths of xpathSelections" [value]="paths">{{paths.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-raised-button color="primary" *ngIf="selectedTestSuite"
          (click)="inspect()">Analyse Test Suite</button>

  <button mat-raised-button color="primary" *ngIf="!selectedTestSuite && availableTests && availableTests.length > 0"
          (click)="inspectAll()">Analyse All Tests</button>

  <mat-slide-toggle *ngIf="selectedTestSuite" [checked]="originals"
                    (change)="toggleOriginals($event.checked)">Originals
  </mat-slide-toggle>
</div>

<div class="card">
  <div class="card-body">
    <div id="table" class="table-editable">
      <table class="table table-bordered table-responsive-md table-striped text-center">
        <tr>
          <th class="text-center">Count</th>
          <th class="text-center">Name</th>
          <th class="text-center">XPath</th>
          <th class="text-center">Actions</th>
        </tr>
        <tr *ngFor="let xpath of xpaths; let id = index">
          <td>
            <mat-checkbox [checked]="xpath.count" (click)="toggleValue(id, 'count')"></mat-checkbox>
          </td>
          <td>
            <span (keyup)="changeValue(id, 'name', $event)" (blur)="updateList(id, 'name', $event)"
                  contenteditable="true">{{xpath.name}}</span>
          </td>
          <td>
            <span contenteditable="true" (keyup)="changeValue(id, 'xpath', $event)"
                  (blur)="updateList(id, 'xpath', $event)">{{xpath.xpath}}</span>
          </td>
          <td>
            <span class="table-remove">
              <button mat-raised-button color="warn" (click)="remove(id)">Remove</button>
            </span>
          </td>
        </tr>
      </table>
      <button mat-raised-button color="primary" (click)="add()">Add</button>
    </div>
  </div>
</div>
