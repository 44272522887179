import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ManualDraftingAdminEnrichmentRuleService } from '../../utils/services/manual-drafting-enrichment-rules.service';

@Component({
  selector: 'app-enrichment-rule',
  templateUrl: './enrichment-rule-dialog.component.html',
  styleUrls: ['./enrichment-rule-dialog.component.scss'],
})
export class EnrichmentRuleDialogComponent implements OnInit {
  @Input() modal: any;
  @Input() editRule: any;

  status = '';
  deleted = false;
  notDeleted = true;

  enrichRuleForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private administerEnrichmentRuleService: ManualDraftingAdminEnrichmentRuleService,
  ) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.enrichRuleForm = this.formBuilder.group({
      code: this.editRule.length ? [this.editRule[0].id, Validators.required] : [''],
      description: this.editRule.length ? [this.editRule[0].description, Validators.required] : ['', Validators.required],
      inboundJsonPath: this.editRule.length ? [this.editRule[0].inbound, Validators.required] : ['', Validators.required],
      soloXmlToSmartDx: this.editRule.length ? [this.editRule[0].outbound, Validators.required] : ['', Validators.required],
    });
  }

  async submit(): Promise<void> {
    const description = [this.enrichRuleForm.value.description]
    const id = [this.enrichRuleForm.value.code]
    const inbound = [this.enrichRuleForm.value.inboundJsonPath]
    const soloXmlToSmartDx = [this.enrichRuleForm.value.soloXmlToSmartDx]

    !this.editRule.length ? await this.createNewEnrichmentRule(description, inbound, soloXmlToSmartDx).then(async res => {
      this.modal.close(this.status);

    }) : await this.updateEnrichmentRule(id, description, inbound, soloXmlToSmartDx).then(async res => {
      this.modal.close(this.status);
    })
  }

  async createNewEnrichmentRule(description, inbound, outbound) {

    await this.administerEnrichmentRuleService.createNewEnrichmentRule(description, inbound, outbound)
      .then(res => {
        status = res.status
        this.status = res.status
      })
    return status
  }

  async updateEnrichmentRule(id, description, inbound, outbound) {

    await this.administerEnrichmentRuleService.updateEnrichmentRule(id, description, inbound, outbound)
      .then(res => {
        status = res.status
        this.status = res.status
      })
    return status
  }
}
