import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { OverlayContainer } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogModule,
} from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import {
//   AmplifyAngularModule,
//   AmplifyService,
// } from '@flowaccount/aws-amplify-angular';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faArchive,
  faBaby,
  faBan,
  faBezierCurve,
  faBinoculars,
  faBolt,
  faBroom,
  faBug,
  faCalendar,
  faCalendarTimes,
  faCarCrash,
  faCaretDown,
  faCaretRight,
  faChartLine,
  faCheckCircle,
  faCheckDouble,
  faChevronCircleLeft,
  faChevronCircleRight,
  faClipboardCheck,
  faClone,
  faCloudSunRain,
  faCode,
  faCodeBranch,
  faCoins,
  faCommentSlash,
  faCompress,
  faCrosshairs,
  faDatabase,
  faDiceD6,
  faDna,
  faEdit,
  faEllipsisV,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeDropper,
  faFileImport,
  faFilePdf,
  faFilter,
  faGem,
  faGlobe,
  faHatCowboy,
  faHatCowboySide,
  faHistory,
  faLink,
  faListAlt,
  faMagic,
  faMap,
  faMapSigns,
  faMusic,
  faNetworkWired,
  faPalette,
  faPaperPlane,
  faPlay,
  faPlus,
  faProjectDiagram,
  faRadiation,
  faRadiationAlt,
  faRandom,
  faRibbon,
  faRoad,
  faSeedling,
  faSignature,
  faSignInAlt,
  faSignOutAlt,
  faTable,
  faTachometerAlt,
  faTasks,
  faTerminal,
  faThumbsUp,
  faToolbox,
  faTools,
  faTrash,
  faUserLock,
  faUsers,
  faVial,
  faVials,
  faVolumeMute,
  faYinYang,
} from '@fortawesome/free-solid-svg-icons';
import { NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import Amplify from 'aws-amplify';
import { ChartsModule } from 'ng2-charts';
import { DragulaModule } from 'ng2-dragula';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { MomentModule } from 'ngx-moment';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { TreeviewModule } from 'ngx-treeview';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnalysisConfigComponent } from './components/analysis-config.component';
import { AnalysisViewComponent } from './components/analysis-view.component';
import { ConfigApprovalComponent } from './components/config-approval/config-approval.component';
import { ConfigPublishComponent } from './components/config-workspace-buttons/config-publish.component';
import { ConfigRevertComponent } from './components/config-workspace-buttons/config-revert.component';
import { ConfigWorkspaceButtonsComponent } from './components/config-workspace-buttons/config-workspace-buttons.component';
import { DeleteWorkspaceComponent } from './components/config-workspace-buttons/delete-workspace.component';
import { NewWorkspaceComponent } from './components/config-workspace-buttons/new-workspace.component';
import { ConfigsSelectorComponent } from './components/configs-selector/configs-selector.component';
import { DocgenConfigSelectorComponent } from './components/docgen-config-selector.component';
import { DocgenGraphicComponent } from './components/docgen-graphic.component';
import { ProductGovernanceStatusComponent } from './components/product-governance-status.component';
import { PublishConfigSelectorComponent } from './components/publish-config-selector.component';
import { PublishGraphicComponent } from './components/publish-graphic.component';
import { Ruleset2021IgnoreComponent } from './components/ruleset-2021-ignore/ruleset-2021-ignore.component';
import { SchemaSelectorComponent } from './components/schema-selector.component';
import { SourceEnvironmentSelectorComponent } from './components/source-environment-selector.component';
import { TargetEnvironmentSelectorComponent } from './components/target-environment-selector.component';
import { TestHistoryListComponent } from './components/test-history-list/test-history-list.component';
import { TestSelectorComponent } from './components/test-selector.component';
import { TestSuiteGroupSelectorComponent } from './components/test-suite-group-selector/test-suite-group-selector.component';
import { TestSuiteSelectorComponent } from './components/test-suite-selector.component';
import { TransformSelectorComponent } from './components/transform-selector.component';
import { WorkspaceSelectorComponent } from './components/workspace-selector.component';
import { AnalysisAddRowDialogComponent } from './dialogs/analysis-add-row-dialog.component';
import { AnalysisAddRuleDialogComponent } from './dialogs/analysis-add-rule-dialog.component';
import { AnalysisCopyRuleDialogComponent } from './dialogs/analysis-copy-rule-dialog.component';
import { AnalysisCreateConfigDialogComponent } from './dialogs/analysis-create-config-dialog.component';
import { AnalysisDeleteRuleDialogComponent } from './dialogs/analysis-delete-rule-dialog.component';
import { AnalysisListAddEditRowDialogComponent } from './dialogs/analysis-list-add-edit-dialog.component';
import { ShowRuleDeletionsDialogComponent } from './dialogs/analysis-show-rule-deletions-dialog.component';
import { ApprovalDialogComponent } from './dialogs/approval-dialog.component';
import { ApproveRuleDialogComponent } from './dialogs/approve-rule-dialog/approve-rule-dialog.component';
import { ConfigDialogComponent } from './dialogs/config-dialog.component';
import { ConfirmCommentDialogComponent } from './dialogs/confirm-comment-dialog.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog.component';
import { CreateWorkspaceDialogComponent } from './dialogs/create-workspace-dialog.component';
import { GitPublishDialogComponent } from './dialogs/git-publish-dialog.component';
import { JiraIssueDialogComponent } from './dialogs/jira-issue-dialog/jira-issue-dialog.component';
import { NameDialogComponent } from './dialogs/name-dialog.component';
import { RepositoryAndNameDialogComponent } from './dialogs/repository-and-name-dialog.component';
import { RuleErrorMessageDialogComponent } from './dialogs/rule-error-message-dialog/rule-error-message-dialog.component';
import { Ruleset2021CreateNewDialogComponent } from './dialogs/ruleset-2021-create-new-dialog/ruleset-2021-create-new-dialog';
import { RuleSet2021ViewEditDialogComponent } from './dialogs/ruleset-2021-view-edit-dialog/ruleset-2021-view-edit-dialog.component';
import { RuleSetEditDialogComponent } from './dialogs/ruleset-edit-dialog/ruleset-edit-dialog.component';
import { SaveNegativeTestDialogComponent } from './dialogs/save-negative-test-dialog.component';
import { SaveTestDialogComponent } from './dialogs/save-test-dialog.component';
import { SmartDxRestartDialogComponent } from './dialogs/smart-dx-restart-dialog.component';
import { TestSuiteGroupEditDialogComponent } from './dialogs/test-suite-group-edit-dialog/test-suite-group-edit-dialog.component';
import { ChartPlaygroundComponent } from './playground/chart-playground/chart-playground.component';
import { ListingPayloadComponent } from './playground/listing-payload/listing-payload.component';
import { PlaygroundComponent } from './playground/playground.component';
import { ApiService } from './services/api.service';
import { ApprovalsService } from './services/approvals.service';
import { ConfigMonitorService } from './services/config-monitor.service';
import { ConfigVersionService } from './services/config-version.service';
import { ConfigService } from './services/config.service';
import { DashboardMetricsService } from './services/dashboard-metrics.service';
import { EnvironmentService } from './services/environment-service';
import { ExperimentsService } from './services/experiments.service';
import { LambdaMetricsService } from './services/lambda-metrics.service';
import { ManagementInformationService } from './services/management-information.service';
import { PayloadInspectorService } from './services/payload-inspector.service';
import { PermissionsService } from './services/permissions.service';
import { PublishPayloadService } from './services/publish-payload.service';
import { Settings, SettingsService } from './services/settings.service';
import { StaleWorkspaceMonitorService } from './services/stale-workspace-monitor.service';
import { StatusService } from './services/status.service';
import { TestManagerService } from './services/test-manager.service';
import { TokenService } from './services/token.service';
import { UserNameService } from './services/user-name.service';
import { UserPreferencesService } from './services/user-preferences.service';
import { WorkspaceAutoSaveService } from './services/workspace-auto-save.service';
import { registerJsonataEditor } from './util/register-jsonata-editor';
import { AdminRegxchangeGetComponent } from './views/admin-regxchange-get/admin-regxchange-get.component';
import { AdminRegxchangePostComponent } from './views/admin-regxchange-post/admin-regxchange-post.component';
import { AdminSmartDxComponent } from './views/admin-smartdx/admin-smartdx.component';
import { ApprovalsComponent } from './views/approvals.component';
import { ApproveRuleComponent } from './views/approve-rule/approve-rule.component';
import { ConfigApprovalsComponent } from './views/config-approvals/config-approvals.component';
import { ConfigEditComponent } from './views/config-edit/config-edit.component';
import { ConfigFilterComponent } from './views/config-filter/config-filter.component';
import { ConfigListComponent } from './views/config-list/config-list.component';
import { ConfigMappingComponent } from './views/config-mapping/config-mapping.component';
import { NewConfigRulesComponent } from './views/config-rules/config-rules.component';
import { ConfigStaticComponent } from './views/config-static/config-static.component';
import { ConfigTargetsComponent } from './views/config-targets/config-targets.component';
import { ConfigTemplatesComponent } from './views/config-templates/config-templates.component';
import { DashboardComponent } from './views/dashboard.component';
import { DocgenConfigApprovalComponent } from './views/docgen-config-approval/docgen-config-approval.component';
import { DocumentGeneratorComponent } from './views/document-generator.component';
import { InfrastructureComponent } from './views/infrastructure.component';
import { InspectComponent } from './views/inspect.component';
import { Maintain2021RulesetComponent } from './views/maintain-2021-ruleset-component/maintain-2021-ruleset-component';
import { ManagementInformationReportComponent } from './views/mi-report/management-information-report.component';
import { ProductGovernanceComponent } from './views/product-governance.component';
import { PublishConfigApprovalComponent } from './views/publish-config-approval/publish-config-approval.component';
import { PublishComponent } from './views/publish/publish.component';
import { TemplatesApprovalComponent } from './views/templates-approval/templates-approval.component';
import { TestMigratorComponent } from './views/test-migrator.component';
import { TestSuiteExecutionsComponent } from './views/test-suite-executions.component';
import { TestSuiteManagerComponent } from './views/test-suite-manager.component';
import { TestSuiteCloneComponent } from './views/test-suite-clone.component';
import { FilterPipe } from './util/filter-pipe';
import { ConfigMappingsDynamicComponent } from './views/config-mappings-dynamic/config-mappings-dynamic.component';
import { ConfigSamplesEditComponent } from './views/config-samples/config-samples-component';
import { DynamicMappingService } from './services/dynamic-mapping.service';
import { CreateDynamicMappingsDialogComponent } from './dialogs/create-dynamic-mappings-dialog/create-dynamic-mappings-dialog.component';
import { ConfigReleaseChangesComponent } from './components/docgen-release-changes/configReleaseChanges/config-release-changes.component';
import { DocgenReleaseChangesComponent } from './components/docgen-release-changes/docgen-release-changes.component';
import { DocgenReleaseChangesDetailsComponent } from './components/docgen-release-changes/configReleaseChangesDetails/docgen-release-changes-details.component';
import { JmesPathEvaluatorDialogComponent } from './dialogs/jmes-path-evaluator/jmes-path-evaluator-dialog.component';
import { JmesPathEvaluatorComponent } from './components/jmes-path-evaluator/jmes-path-evaluator.component';
import { JmesPathViewComponent } from './views/jmes-path-view/jmes-path-view.component';
import { TestSuiteRecomendationsComponent } from './views/test-recommendation.component';
import { RulesetAuditHistoryComponent } from './components/ruleset-audit-history/ruleset-audit-history.component';
import { AuditHistoryDialogComponent } from './dialogs/audit-history-dialog/audit-history-dialog.component';
import { TestCleanupComponent } from './views/test-cleanup/test-cleanup.component';
import { DocgenManagementInformationComponent } from './views/mi-report/docgen-management-information/docgen-management-information.component';
import { ConnectivityManagementInformationComponent } from './views/mi-report/connectivity-management-information/connectivity-management-information.component';
import { JiraManagementInformationComponent } from './views/mi-report/jira-management-information/jira-management-information.component';
import { ApprovalsManagementInformationComponent } from './views/mi-report/approvals-management-information/approvals-management-information.component';
import { ClientSummaryReportComponent } from './views/mi-report/client-summary-report/client-summary-report.component';
import { ClientServiceLevelsComponent } from './views/mi-report/client-service-levels/client-service-levels.component';
import { SmartDxRequestsComponent } from './views/mi-report/smart-dx-requests/smart-dx-requests.component';
import { SmartDxMonitoringComponent } from './views/mi-report/smart-dx-monitoring/smart-dx-monitoring.component';
import { SmartDxMaintenanceComponent } from './views/mi-report/smart-dx-maintenance/smart-dx-maintenance.component';
import { NewPayloadComponent } from './components/manual-drafting/newPayloadDialog/new-payload.component';
import { SimpleRuleComponent } from './components/manual-drafting/simple-rule/simple-rule.component';
import { AdministerValidationRulesComponent } from './components/manual-drafting/administer-validation-rules/administer-validation-rules.component';
import { ComplexRulesComponent } from './components/manual-drafting/complex-rules/complex-rules.component';
import { AdminPayLoadComponent } from './components/manual-drafting/administer-payloads/admin-payloads.component';
import { PayloadEditorComponent } from './components/manual-drafting/payload-editor/payload-editor.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { AdministerEnrichmentRulesComponent } from './components/manual-drafting/administer-erichment-rules/administer-enrichment-rules.component';
import { EnrichmentRuleDialogComponent } from './components/manual-drafting/administer-erichment-rules/enrichment-rule/enrichment-rule-dialog.component';
import { EditDynamicMappingsDialogComponent } from './dialogs/edit-dynamic-mappings-dialog/edit-dynamic-mappings-dialog.component';
import { ManualDraftingAdminPayloadService } from './components/manual-drafting/utils/services/manual-drafting-administer-payload.service';
import { ManualDraftingAdminEnrichmentRuleService } from './components/manual-drafting/utils/services/manual-drafting-enrichment-rules.service';
import { OutputDisplayComponent } from './components/manual-drafting/output-display/output-display.component';
import { DisplayTestArchiveComponent } from './views/display-test-archive/display-test-archive.component';
import { PreprocessorMappingComponent } from './views/preprocessor-mapping/preprocessor-mapping.component';
import { WsdDocAutoRequestsComponent } from './views/mi-report/wsd-docauto-requests/wsd-docauto-requests.component';
import { WsdPriipcloudRequestsComponent } from './views/mi-report/wsd-priipcloud-requests/wsd-priipcloud-requests.component';
import { AdminWsdManualPostComponent } from './views/admin-wsd-manual-post/admin-wsd-manual-post.component';
import { ValidationReportComponent } from './views/mi-report/validation-report/validation-report.component';
import { EditChartOptionsDialogComponent } from './dialogs/test-suite-group-edit-dialog/edit-chart-options-dialog/edit-chart-options-dialog.component';
import { RegexEvaluatorDialogComponent } from './dialogs/regex-evaluator-dialog/regex-evaluator-dialog.component';
import { AuthService } from './services/auth.service';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { DcMonacoStandaloneComponent } from './components/dc-monaco-standalone/dc-monaco-standalone.component';
import { DcMonacoDiffComponent } from './components/dc-monaco-diff/dc-monaco-diff.component';
import { CreateConfigNote } from './dialogs/note-dialog/create-config-note';

export const configureAuth =
  (httpClient: HttpClient, settingsService: SettingsService) => async () =>
    await AuthService.configure(httpClient, settingsService);
const configureAmplify = async (
  httpClient: HttpClient,
  settingsService: SettingsService
) => {
  const settings = (await settingsService.settings()) as Settings;
  const redirectBase = window.location.hostname.includes('localhost')
    ? 'http://localhost:4200'
    : 'https://' + window.location.hostname;
  const amplifyConfig = {
    Auth: {
      region: settings.region,
      userPoolId: settings.userPoolId,
      userPoolWebClientId: settings.webClientId,
      oauth: {
        domain: 'auth.' + settings.hostname,
        scope: ['api/generate', 'api/admin', 'aws.cognito.signin.user.admin'],
        redirectSignIn: redirectBase + '/auth/code',
        redirectSignOut: redirectBase + '/logout',
        responseType: 'code',
      },
    },
    Analytics: {
      disabled: true,
    },
  };
  Amplify.configure(amplifyConfig);
};

export function configureAmplifyProvider(
  httpClient: HttpClient,
  settingsService: SettingsService
) {
  return () => configureAmplify(httpClient, settingsService);
}

const monacoConfig: NgxMonacoEditorConfig = {
  defaultOptions: {
    scrollBeyondLastLine: true,
  },
  onMonacoLoad: () => {
    console.log((window as any).monaco);
    registerJsonataEditor((window as any).monaco);
  },
};

// @ts-ignore
@NgModule({
    declarations: [
        AppComponent,
        AdminSmartDxComponent,
        AdminRegxchangePostComponent,
        AdminRegxchangeGetComponent,
        DocumentGeneratorComponent,
        InfrastructureComponent,
        SmartDxRestartDialogComponent,
        ApprovalsComponent,
        DashboardComponent,
        ConfigTemplatesComponent,
        SchemaSelectorComponent,
        TransformSelectorComponent,
        TestSelectorComponent,
        TestSuiteSelectorComponent,
        GitPublishDialogComponent,
        AnalysisListAddEditRowDialogComponent,
        AnalysisAddRuleDialogComponent,
        AnalysisDeleteRuleDialogComponent,
        ShowRuleDeletionsDialogComponent,
        AnalysisAddRowDialogComponent,
        AnalysisCopyRuleDialogComponent,
        AnalysisCreateConfigDialogComponent,
        NameDialogComponent,
        CreateWorkspaceDialogComponent,
        ConfigDialogComponent,
        ConfirmDialogComponent,
        ConfirmCommentDialogComponent,
        DocgenGraphicComponent,
        TestSuiteExecutionsComponent,
        DocgenConfigSelectorComponent,
        AnalysisViewComponent,
        TargetEnvironmentSelectorComponent,
        WorkspaceSelectorComponent,
        ApprovalDialogComponent,
        SaveNegativeTestDialogComponent,
        AnalysisConfigComponent,
        InspectComponent,
        TestMigratorComponent,
        TestSuiteManagerComponent,
        TestSuiteCloneComponent,
        ProductGovernanceComponent,
        ProductGovernanceStatusComponent,
        ConfigListComponent,
        ConfigEditComponent,
        ConfigMappingComponent,
        NewConfigRulesComponent,
        Maintain2021RulesetComponent,
        Ruleset2021CreateNewDialogComponent,
        RuleSetEditDialogComponent,
        ConfigPublishComponent,
        NewWorkspaceComponent,
        ConfigRevertComponent,
        ConfigWorkspaceButtonsComponent,
        DeleteWorkspaceComponent,
        SaveTestDialogComponent,
        ManagementInformationReportComponent,
        ConfigStaticComponent,
        ConfigTargetsComponent,
        PublishComponent,
        PublishConfigSelectorComponent,
        PublishGraphicComponent,
        SourceEnvironmentSelectorComponent,
        ConfigsSelectorComponent,
        PlaygroundComponent,
        TestHistoryListComponent,
        ConfigFilterComponent,
        RuleErrorMessageDialogComponent,
        ApproveRuleDialogComponent,
        ApproveRuleComponent,
        ConfigApprovalComponent,
        DocgenConfigApprovalComponent,
        PublishConfigApprovalComponent,
        TemplatesApprovalComponent,
        JiraIssueDialogComponent,
        ConfigApprovalsComponent,
        RepositoryAndNameDialogComponent,
        TestSuiteGroupEditDialogComponent,
        TestSuiteGroupSelectorComponent,
        ChartPlaygroundComponent,
        ListingPayloadComponent,
        RuleSet2021ViewEditDialogComponent,
        Ruleset2021IgnoreComponent,
        FilterPipe,
        ConfigMappingsDynamicComponent,
        ConfigSamplesEditComponent,
        CreateDynamicMappingsDialogComponent,
        ConfigReleaseChangesComponent,
        DocgenReleaseChangesComponent,
        DocgenReleaseChangesDetailsComponent,
        JmesPathEvaluatorDialogComponent,
        JmesPathEvaluatorComponent,
        JmesPathViewComponent,
        TestSuiteRecomendationsComponent,
        AuditHistoryDialogComponent,
        RulesetAuditHistoryComponent,
        TestCleanupComponent,
        DocgenManagementInformationComponent,
        ConnectivityManagementInformationComponent,
        JiraManagementInformationComponent,
        ApprovalsManagementInformationComponent,
        ClientSummaryReportComponent,
        ClientServiceLevelsComponent,
        SmartDxRequestsComponent,
        SmartDxMonitoringComponent,
        SmartDxMaintenanceComponent,
        NewPayloadComponent,
        AdminPayLoadComponent,
        AdministerValidationRulesComponent,
        SimpleRuleComponent,
        ComplexRulesComponent,
        PayloadEditorComponent,
        AdministerEnrichmentRulesComponent,
        EnrichmentRuleDialogComponent,
        EditDynamicMappingsDialogComponent,
        OutputDisplayComponent,
        DisplayTestArchiveComponent,
        PreprocessorMappingComponent,
        WsdDocAutoRequestsComponent,
        WsdPriipcloudRequestsComponent,
        AdminWsdManualPostComponent,
        ValidationReportComponent,
        EditChartOptionsDialogComponent,
        RegexEvaluatorDialogComponent,
        DcMonacoStandaloneComponent,
        DcMonacoDiffComponent,
        CreateConfigNote
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        //AmplifyAngularModule,
        AmplifyAuthenticatorModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MomentModule,
        FlexLayoutModule,
        FontAwesomeModule,
        NgxExtendedPdfViewerModule,
        NgxFileDropModule,
        NgxSpinnerModule,
        CommonModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        MatBadgeModule,
        ChartsModule,
        ClipboardModule,
        DragulaModule.forRoot(),
        ScrollingModule,
        NgbModule,
        NgbDatepickerModule,
        NgxSliderModule,
        MonacoEditorModule.forRoot(monacoConfig),
        TreeviewModule.forRoot(),
        ToastrModule.forRoot({
            closeButton: true,
            maxOpened: 4,
            timeOut: 3000,
            autoDismiss: true,
            countDuplicates: true,
            resetTimeoutOnDuplicate: true,
            preventDuplicates: true,
            positionClass: 'toast-bottom-right',
        }),
        LoggerModule.forRoot({
            enableSourceMaps: true,
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.ERROR,
        }),
        NgJsonEditorModule,
    ],
    providers: [
        // {
        //   provide: ErrorHandler,
        //   useClass: GlobalErrorHandlerService,
        // },
        SettingsService,
        StatusService,
        DocgenReleaseChangesDetailsComponent,
        NewPayloadComponent,
        // {
        //   provide: APP_INITIALIZER,
        //   deps: [HttpClient, SettingsService],
        //   useFactory: configureAmplifyProvider,
        //   multi: true,
        // },
        SettingsService,
        {
            provide: APP_INITIALIZER,
            deps: [HttpClient, SettingsService],
            useFactory: configureAuth,
            multi: true,
        },
        AuthService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { float: 'always' },
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: false,
            },
        },
        // AmplifyService,
        UserNameService,
        ApiService,
        ConfigService,
        ConfigVersionService,
        TokenService,
        TestManagerService,
        LambdaMetricsService,
        UserPreferencesService,
        DashboardMetricsService,
        EnvironmentService,
        ApprovalsService,
        PermissionsService,
        PayloadInspectorService,
        ExperimentsService,
        ConfigMonitorService,
        WorkspaceAutoSaveService,
        StaleWorkspaceMonitorService,
        ManagementInformationService,
        PublishPayloadService,
        DynamicMappingService,
        ManualDraftingAdminPayloadService,
        ManualDraftingAdminEnrichmentRuleService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(overlayContainer: OverlayContainer, library: FaIconLibrary) {
    overlayContainer.getContainerElement().classList.add('storm-dark-theme');
    library.addIcons(
      faArchive,
      faBaby,
      faBan,
      faBezierCurve,
      faBinoculars,
      faBan,
      faBolt,
      faBroom,
      faBug,
      faCalendar,
      faCalendarTimes,
      faCarCrash,
      faCaretDown,
      faCaretRight,
      faCheckDouble,
      faChevronCircleLeft,
      faChevronCircleRight,
      faChartLine,
      faCheckCircle,
      faClipboardCheck,
      faClone,
      faCloudSunRain,
      faCrosshairs,
      faCode,
      faCodeBranch,
      faCoins,
      faCommentSlash,
      faCompress,
      faDatabase,
      faDiceD6,
      faDna,
      faEdit,
      faEllipsisV,
      faExclamation,
      faExclamationCircle,
      faExclamationTriangle,
      faEye,
      faEyeDropper,
      faFileImport,
      faFilePdf,
      faFilter,
      faGem,
      faGlobe,
      faHatCowboy,
      faHatCowboySide,
      faHistory,
      faLink,
      faListAlt,
      faMagic,
      faMap,
      faMapSigns,
      faMusic,
      faNetworkWired,
      faPalette,
      faPaperPlane,
      faPlay,
      faPlus,
      faProjectDiagram,
      faRadiation,
      faRadiationAlt,
      faRandom,
      faRibbon,
      faRoad,
      faSeedling,
      faSignature,
      faSignInAlt,
      faSignOutAlt,
      faTable,
      faTachometerAlt,
      faTasks,
      faTerminal,
      faThumbsUp,
      faTools,
      faToolbox,
      faTrash,
      faUserLock,
      faUsers,
      faVial,
      faVials,
      faVolumeMute,
      faYinYang
    );
  }
}
