import {Component, Inject} from '@angular/core'
import {MatTableDataSource} from '@angular/material/table'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {RuleType} from '../common/domain/analyse'

export interface FieldValue {
  fieldName: string
  fieldValue: string
}

export interface AddAnalysisRowDetails {
  title: string
  ruleName: string
  ruleMnemonic: string
  fieldValues: FieldValue[]
  stage: 'product' | 'solo'
  ruleType: RuleType
}


@Component({
  templateUrl: 'analysis-add-row-dialog.component.html',
  styleUrls: ['./analysis-add-row-dialog.component.scss'],
})
export class AnalysisAddRowDialogComponent {

  stages = ['product', 'solo']
  ruleTypes = ['whitelist', 'blacklist', 'notSupported', 'validation', 'complex']

  displayedColumns = ['fieldName', 'fieldValue']
  dataSource: MatTableDataSource<FieldValue>

  constructor(public dialogRef: MatDialogRef<AnalysisAddRowDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: AddAnalysisRowDetails) {
    this.dataSource = new MatTableDataSource<FieldValue>(data.fieldValues)
  }

  cancel() {
    this.dialogRef.close(undefined)
  }

  trimFieldValue(index: any) {
    this.data.fieldValues[index].fieldValue = this.data.fieldValues[index].fieldValue.trim()
  }
}
