<h2 mat-dialog-title>{{data.title}}</h2>
<h2>Ruleset : {{data.ruleName}} ({{data.ruleMnemonic}})</h2>

<div mat-dialog-content>
  <table>
    <tr>
      <td>Analysis Stage*</td>
      <td>
        <mat-form-field>
          <mat-select [(value)]="data.stage">
            <mat-option *ngFor="let stage of stages"
                        [value]="stage">{{stage}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </tr>
    <tr>
      <td>Ruleset*</td>
      <td>
        <mat-form-field>
          <mat-select [(value)]="data.ruleType">
            <mat-option *ngFor="let list of ruleTypes"
                        [value]="list">{{list}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </tr>
  </table>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="fieldName">
      <th mat-header-cell *matHeaderCellDef>Field</th>
      <td mat-cell *matCellDef="let row"> {{row.fieldName}} </td>
    </ng-container>
    <ng-container matColumnDef="fieldValue">
      <th mat-header-cell *matHeaderCellDef>Value</th>
      <td mat-cell *matCellDef="let row; let i = index">
        <mat-form-field>
          <input matInput (change)="trimFieldValue(i)" [(ngModel)]="row.fieldValue">
        </mat-form-field>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-button>Cancel</button>
  <button mat-button [mat-dialog-close]="data">Next</button>
</div>
