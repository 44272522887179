<ng-container *ngIf="loading">
    Loading...
  </ng-container>
  
  <ng-container *ngIf="!loading">
    <ng-container *ngIf="results.length === 0">
      <div>No Management Information Found</div>
    </ng-container>
    <ng-container *ngIf="results.length > 0">
      <button type="button" class="btn btn-primary" (click)="downloadCsv()">Download</button>
    </ng-container>
  </ng-container>
  
  
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="totalCount">
      <th mat-header-cell *matHeaderCellDef>Total Validations Count</th>
      <td mat-cell *matCellDef="let el">{{el.totalCount}}</td>
    </ng-container>
    <ng-container matColumnDef="passedCount">
      <th mat-header-cell *matHeaderCellDef>Passed</th>
      <td mat-cell *matCellDef="let el">{{el.passedCount}}</td>
    </ng-container>
    <ng-container matColumnDef="unapprovedCount">
      <th mat-header-cell *matHeaderCellDef>Unapproved</th>
      <td mat-cell *matCellDef="let el">{{el.unapprovedCount}}</td>
    </ng-container>
    <ng-container matColumnDef="unsupportedCount">
      <th mat-header-cell *matHeaderCellDef>Unsupported</th>
      <td mat-cell *matCellDef="let el">{{el.unsupportedCount}}</td>
    </ng-container>
    <ng-container matColumnDef="technicalCount">
      <th mat-header-cell *matHeaderCellDef>Technical</th>
      <td mat-cell *matCellDef="let el">{{el.technicalCount}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 50, 100, 500]" showFirstLastButtons></mat-paginator>
  