import {CommonConfig, ConfigDetail} from './config'

export interface SmartDxTemplateSelector {
  templateProjectId: string
  templateResourceSelector: string
}

export class SmartDxConfig implements SmartDxTemplateSelector {
  templateProjectId: string
  templateResourceSelector: string
  wordTemplateResourceSelector?: string
  service?:string
}

export class DocgenConfig extends CommonConfig {
  renderDocument: SmartDxConfig

  private constructor() {
    super()
    this.renderDocument = new SmartDxConfig()
  }

  public static create(): DocgenConfig {
    return new DocgenConfig()
  }
}

export interface DocgenConfigMetadata {
  sourceSystem: string
  programme: string
  productType: string
  documentType: string
}

export interface DocgenConfigAndMetadata {
  metadata: DocgenConfigMetadata
  settings: ConfigDetail
  schemas: ConfigDetail[]
  transforms: ConfigDetail[]
  rulesets: ConfigDetail[]
}

export enum DocgenConfigKeyIndex {
  SOURCE_SYSTEM = 1,
  PROGRAMME = 2,
  PRODUCT_TYPE = 3,
  DOCUMENT_TYPE = 4,
}
