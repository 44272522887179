import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core'
import {DateTime} from 'luxon'
import {Environment, ENVIRONMENTS, Region, REGIONS} from '../../../services/environment-service'
import {of, Subject} from 'rxjs'
import {debounceTime, distinctUntilChanged, map, mergeMap, switchMap, takeUntil, tap, toArray} from 'rxjs/operators'
import {MatTableDataSource} from '@angular/material/table'
import {ManagementInformationService, MiEvent} from '../../../services/management-information.service'
// import {stringify} from 'csv/lib/sync'
import {MatPaginator} from '@angular/material/paginator'
import { unparse } from 'papaparse'
import saveAs from 'file-saver'
const equals = require('fast-deep-equal')

const environmentLookup = (account) => [ENVIRONMENTS.TOOLS,ENVIRONMENTS.STAGING, ENVIRONMENTS.UAT, ENVIRONMENTS.PROD].filter(env => !!env).find((v) => v && (v.account === account))

interface ValidationsRequestEvent extends MiEvent {
  source: 'inspire.validation-report'
  totalCount: string
  passedCount: string
  unapprovedCount: string
  unsupportedCount: string
  technicalCount: string
}

interface FlattenedValidationsRequestEvent {
  timestamp: string
  totalCount: string
  passedCount: string
  unapprovedCount: string
  unsupportedCount: string
  technicalCount: string
}

interface Request {
  environment: Environment
  fromDate: string
  toDate: string
}

@Component({
  selector: 'app-validation-report',
  templateUrl: './validation-report.component.html',
  styleUrls: ['./validation-report.component.scss']
})

export class ValidationReportComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  loading = true
  cancelRequests = new Subject<void>()

  @Input() environment: Environment
  @Input() fromDate: string
  @Input() toDate: string

  results: FlattenedValidationsRequestEvent[] = []
  columns: string[] = [
    'totalCount',
    'passedCount',
    'unapprovedCount',
    'unsupportedCount',
    'technicalCount'
  ]
  dataSource = new MatTableDataSource<FlattenedValidationsRequestEvent>()
  requests = new Subject<Request>()

  constructor(private managementInformationService: ManagementInformationService) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.requests
      .pipe(
        takeUntil(this.cancelRequests),
        debounceTime(1000),
        distinctUntilChanged(equals),
        tap(_ => this.loading = true),
        tap(request => {
          console.log('Processing: ')
          console.dir(request)
        }),
        switchMap((request: Request) => {
          if (request.environment && request.fromDate) {
            const dateRange = request.fromDate + (request.toDate ? '/' + request.toDate : '')
            return of(...request.environment.regions)
              .pipe(
                mergeMap((region: Region) => {
                  console.log('Fetching data for ' + region.display)
                  return this.managementInformationService.getValidationsReport(
                    request.environment.id,
                    region.id,
                    dateRange)
                    .pipe(
                      tap(results => {
                        console.log('Fetched Validations Report Data For ' + request.environment.display + ' in ' + region.display)
                        console.dir(results)
                      }),
                    )
                }),
                toArray(),
                tap((results: ValidationsRequestEvent[][]) => {
                  console.log('Raw Validations Report Data')
                  console.dir(results)
                }),
                tap((results: any[]) => {
                  console.log('Combined Validations Report Data')
                  console.dir(results)
                }),
                tap(_ => this.loading = false),
              )

          } else {
            return []
          }
        }),
      )
      .subscribe((events: ValidationsRequestEvent[]) => {
        const flattened = events.map(e => this.format(e))
        flattened.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
        this.results = flattened
        this.dataSource.data = this.results
      })
    this.ngOnChanges(undefined)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.requests.next({
      environment: this.environment,
      fromDate: this.fromDate,
      toDate: this.toDate,
    })
  }

  ngOnDestroy(): void {
    this.cancelRequests.next()
  }

  format(e: ValidationsRequestEvent): FlattenedValidationsRequestEvent {
    return {
      totalCount: e.totalCount,
      passedCount: e.passedCount,
      unapprovedCount: e.unapprovedCount,
      unsupportedCount: e.unsupportedCount,
      technicalCount: e.technicalCount
    } as FlattenedValidationsRequestEvent
  }

  downloadCsv() {
    const csv = unparse(this.results, {
      header: true,
      columns: [
        { key: 'totalCount', header: 'Total Count' },
        { key: 'passedCount', header: 'Passed' },
        { key: 'unapprovedCount', header: 'Unapproved' },
        { key: 'unsupportedCount', header: 'Unsupported' },
        { key: 'technicalCount', header: 'Technical' },
      ]
    })
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
    const filename = ('validations-report-' + this.fromDate + (this.toDate ? '-to-' + this.toDate : '') + '.csv')
    console.log('Saving: ' + filename)
    saveAs(blob, filename)
  }
}

