import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TestManagerService} from '../services/test-manager.service';
import {debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from 'rxjs/operators';
import {SavedTestMetadata} from '../model/test-management-responses';
import {DocgenPayloadInformation, SeriesDefinition} from '../common/domain/tests';
import {BehaviorSubject, merge, Observable, Subject, Subscription} from 'rxjs';
import {PayloadInspectorService} from '../services/payload-inspector.service';
import {saveAs} from 'file-saver';
import {PlaygroundService} from './playground.service';
import {NgbModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {CsvMappingsTemplate, ItalianListingXpaths, ProductPayload, SeriesModel, TestInfo} from './playground-model';
import {NameDialogComponent} from '../dialogs/name-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {ConfirmDialogComponent} from '../dialogs/confirm-dialog.component';
import {IngestProductDocgenTraceResponse} from '../model/public-api-response';
import {SuccessTransformResult} from '../common/domain/transform';
import {ChangeService} from '../services/change.service';
import {ErrorApiResponse} from '../common/domain/ingest';
import { DOMParser } from '@xmldom/xmldom'
const xpath = require('xpath');
const dom = DOMParser;

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss'],
})
export class PlaygroundComponent implements OnInit, OnDestroy {

  constructor(
    private testManagerService: TestManagerService,
    private payloadInspectorService: PayloadInspectorService,
    private playgroundService: PlaygroundService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private toast: ToastrService,
    private changeService: ChangeService
  ) {
  }
  sub = new Subscription()

  unselectedTests: TestInfo[] = []
  selectedTests: TestInfo[] = []
  selectedTestsSubject = new BehaviorSubject<TestInfo[]>([])
  canSave = false
  canDownload = false
  selectedProductPayloads: ProductPayload[] = [];
  seriesList: SeriesModel[] = [];
  selectedSeries: {seriesId: string, isins: string[], payload: ProductPayload[]};
  seriesIdList: string[] = [];
  documentErrorResponse: boolean;
  model: any;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  @ViewChild('instance', {static: true}) instance: NgbTypeahead;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.seriesIdList
        : this.seriesIdList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }

  ngOnInit(): void {
    this.sub.add(this.getSeriesList());
    this.testManagerService.setService('italianlisting')
    this.sub.add(
      this.testManagerService.tests
        .pipe(
          map(tests => {
            return tests.reduce((a: TestInfo[], t: SavedTestMetadata & { product: DocgenPayloadInformation }) => {
              const meta = t.product
              if (meta.isin && meta.sourceRef) {
                if (!a.find(x => x.isin === meta.isin && x.ref === meta.sourceRef)) {
                  return a.concat({
                    isin: meta.isin,
                    ref: meta.sourceRef,
                    test: t,
                    name: t.name,
                    visible: true,
                  })
                }
              }
              return a
            }, [])
          }),
        )
        .subscribe(tests => {
          this.unselectedTests = tests
        }),
    )
    this.sub.add(
      this.selectedTestsSubject
        .pipe(
          tap(_ => this.canDownload = false),
          switchMap((tests: TestInfo[]) => {
            const testRows: ProductPayload[] = tests.map((test, index) => {
              return {
                row: index,
                test: test
              }
            })
            this.selectedProductPayloads = testRows;
            return testRows;
          }),
        )
        .subscribe(testRows => {
          console.log('Output of analysis')
          this.canDownload = this.selectedProductPayloads.length > 0
        }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  setFilter(value: string) {
    const terms = value.toLowerCase().split(' ')
    this.selectedTests.forEach(test => {
      const ts = (`${test.isin} ${test.ref} ${test.test.product.programme} ${test.test.product.productType}`).toLowerCase()
      test.visible = terms.every(term => ts.includes(term))
    })
    this.unselectedTests.forEach(test => {
      const ts = (`${test.isin} ${test.ref} ${test.test.product.programme} ${test.test.product.productType}`).toLowerCase()
      test.visible = terms.every(term => ts.includes(term))
    })
    this.canSave = this.selectedTests.length > 0
  }

  downloadSubmissionCsv() {
    const transform = this.changeService.getObject('transforms/SubmissionHeaderFieldsToXPath.json');
    const jsonMappings = JSON.parse(transform.content);
    const template: CsvMappingsTemplate = {
      headers: jsonMappings.headers,
      fields: Object.entries(jsonMappings.fields).map(([key, value]) => {
        return {
          name: key,
          xpath: value,
        } as ItalianListingXpaths;
      })
    }
   return this.generateDoc(template, 'submission');
  }

  downloadCertCsv() {
    const transform = this.changeService.getObject('transforms/CertXHeaderFieldsToXPath.json');
    const jsonMappings = JSON.parse(transform.content);
    const template: CsvMappingsTemplate = {
      headers: jsonMappings.headers,
      fields: Object.entries(jsonMappings.fields).map(([key, value]) => {
        return {
          name: key,
          xpath: value,
        } as ItalianListingXpaths;
      })
    }
    return this.generateDoc(template, 'cert');
  }

  async generateDoc(template: CsvMappingsTemplate, docType: string): Promise<string> {
    this.documentErrorResponse = false;
    const csvBody = [];
    let errorResponsePayload = [];
    const payloads = this.selectedProductPayloads;
    payloads.sort((a,b) => (a.row > b.row) ? 1 : -1)
    let counter = 1;
    for (const payload of payloads) {
      const payloadString = await this.getPayloadString(payload.test.isin + '-' + payload.test.ref)
      const xmlDoc = this.checkForSpecialCharacters(payloadString);
      await this.playgroundService.postToDocGen(xmlDoc).then(async response => {
          await response.toPromise().then((documentResults: IngestProductDocgenTraceResponse) => {
            const transformResponse = documentResults.transform as SuccessTransformResult;
            const transformedXml = transformResponse.payload;
            errorResponsePayload = this.checkForErrorResponseString(documentResults.errorResponse, errorResponsePayload);
            const row = this.mapPayloadToXpath(template.fields, transformedXml, counter);
            csvBody.push(row);
          })
        }
      );
      counter++;
    }
    const finalCsv = this.convertToCsvString(csvBody, errorResponsePayload, template.headers);
    const filenamePrefix = this.documentErrorResponse ?  docType + '-ERROR' + '-' : docType + '-';
    const blob = new Blob([finalCsv], {type: 'text/csv;charset=utf-8'});
    saveAs(blob, filenamePrefix + new Date().toISOString() + '.csv');
    return finalCsv;
  }

  getPayloadString(name: string): Promise<string> {
    let productPayload: string;
    return new Promise<string>(resolve => {
      this.testManagerService.getTest(name).subscribe(t => {
        productPayload = t.payload;
        resolve(productPayload)
      });
    });
  }

  checkForErrorResponseString(errorResults: ErrorApiResponse, errorResponsePayload: string []): string[] {
    let errorString = '';
    if (errorResults) {
      this.documentErrorResponse = true;
      errorString += errorResults.status + ':';
      errorResults.errors.map(errors => errorString += ' type: ' + errors.type + ' code: ' + errors.code + ' description: ' + errors.description)
      errorResponsePayload.push(errorString);
    } else {
      errorResponsePayload.push('')
    }
    return errorResponsePayload;
  }

  mapPayloadToXpath(xpathHeaderTemplate: ItalianListingXpaths[], xmlDoc: string, counter: number): string[][] {
    const doc = new dom().parseFromString(xmlDoc)
    const output: {headers: string[], values: string[][]}= {headers: [], values: []};
    xpathHeaderTemplate.map(x => {
      const xPathResultValues = []
      if (x.xpath === '$index') {
        xPathResultValues.push(counter);
      } else {
        const xPathResult = xpath.select(`${x.xpath}`, doc)
        xPathResult.map(result => {
          xPathResultValues.push(result.childNodes[0] === undefined ? '' : result.childNodes[0].data)
        })
      }
      output.headers.push(x.name);
      output.values.push(xPathResultValues);
    })
    return output.values;
  }

  convertToCsvString(payloadValues: string[][][], errorResponsePayload: string[], templateHeaders: string[]) {
    let body = '';
    let headers = '';
    if (this.documentErrorResponse) {
      headers += 'Api Error Response,' + templateHeaders + '\n';
      payloadValues.map((outputValues, index) => {
        for (let i = 0; i < outputValues[1].length; i++){
          const valueArray = [];
          outputValues.map(arr => valueArray.push(arr[i]))
          const rowString = errorResponsePayload[index] + ',' + valueArray.toString() + '\n';
          body += rowString;
        }
      })
    } else {
      headers += templateHeaders + '\n';
      payloadValues.map(outputValues => {
        for (let i = 0; i < outputValues[1].length; i++){
          const valueArray = [];
          outputValues.map(arr => valueArray.push(arr[i]));
          body += valueArray.toString() + '\n';
        }
      })
    }
    return headers + body;
  }

  checkForSpecialCharacters(xmlDoc: string) {
    let editedXmlDoc: string;
    if (xmlDoc.includes('&')) {
      editedXmlDoc = xmlDoc.replace(/\&/g, '&amp;')
      return editedXmlDoc
    }
    return xmlDoc;
  }

  addTestToSuite(test: TestInfo) {
    this.selectedTests.push(test)
    const index = this.unselectedTests.indexOf(test)
    this.unselectedTests.splice(index, 1)
    this.canSave = this.selectedTests.length > 0
    this.selectedTestsSubject.next(this.selectedTests)
  }

  removeTestFromSuite(test: TestInfo) {
    this.unselectedTests.push(test)
    const index = this.selectedTests.indexOf(test)
    this.selectedTests.splice(index, 1)
    this.canSave = this.selectedTests.length > 0
    this.selectedTestsSubject.next(this.selectedTests)
  }

  name(test?: TestInfo) {
    return test && test.name
  }

  getSeriesList() {
    this.testManagerService.getSeries()
      .subscribe(series => {
      const seriesList: SeriesModel[] = series.map(s => {
        return {
          seriesId: s.name,
          isins: s.isins as any
        }
      })
      this.seriesList = seriesList;
      this.seriesIdList = this.seriesList.map(s => s.seriesId);
    });
  }

  selectSeries($event: string) {
    if ($event === undefined) {
      this.selectedSeries = null
    }
    for (let i = this.selectedTests.length -1; i >= 0; --i) {
      this.removeTestFromSuite(this.selectedTests[i]);
    }

    if($event !== undefined) {
      this.populateSelectedSeries($event);
    }
  }

  async saveSeries() {
    const isins: string[] = [];

    for (const row of this.selectedProductPayloads) {
      isins.push(row.test.name)
    }

    const dialogRef = this.dialog.open(NameDialogComponent, {
      width: '500px',
      data: {
        title: 'Series',
        name: this.selectedSeries ? this.selectedSeries.seriesId : '',
      },
      panelClass: 'dialogFormField500',
    })

    dialogRef.afterClosed().subscribe(async seriesId => {
      await this.postSavedSeries(seriesId, isins)
    });
  }

  async postSavedSeries(seriesId: string, isins: string[]) {
    const series: SeriesDefinition = {
      name: seriesId,
      isins: isins
    }
    await this.testManagerService.saveSeries(series)
      .then(res => {
        this.toast.success('Saved Series')
        this.getSeriesList();
        if (this.selectedSeries !== undefined ? this.selectedSeries.seriesId === seriesId : false) {
          this.populateSelectedSeries(seriesId)
        }
      })
  }

  populateSelectedSeries(seriesId: string) {
    for (const series of this.seriesList) {
      if (series.seriesId === seriesId) {
        this.selectedSeries = {seriesId: series.seriesId, isins: series.isins, payload: []};
        for (let i = this.unselectedTests.length -1; i >= 0; --i) {
          const product = this.unselectedTests[i];
          const productId = product.isin + '-' + product.ref;
          if (this.selectedSeries.isins.includes(productId)) {
            this.addTestToSuite(product);
          }
        }
        this.selectedSeries.payload = this.selectedProductPayloads;
      }
    }
  }

  removeSelectedSeries() {
    this.selectedSeries = null;
  }

  async deleteSeries() {
    const confirmDialog = this.modalService.open(ConfirmDialogComponent, {
      backdrop: false,
    })
    confirmDialog.componentInstance.title = 'Delete Series Group'
    confirmDialog.componentInstance.message = 'Are you sure you want to delete this series: ' + this.selectedSeries.seriesId

    confirmDialog.result.then(confirmed => {
      if (confirmed) {
        this.testManagerService.deleteSeries(this.selectedSeries.seriesId)
          .then(res => {
            this.toast.success('Series deleted')
          })
        for (let i = this.selectedTests.length -1; i >= 0; --i) {
          this.removeTestFromSuite(this.selectedTests[i]);
        }
        this.selectedSeries = null
        return this.getSeriesList();
      }
    }).catch(err => {})
  }

}
