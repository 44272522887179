import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import cloneDeep from 'clone-deep';
import {saveAs} from 'file-saver';
import {ToastrService} from 'ngx-toastr';
import {merge, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {map, mergeMap, shareReplay, switchMap, take, takeUntil, tap, toArray} from 'rxjs/operators';
import {AlertDialogComponent} from 'src/app/dialogs/alert-dialog/alert-dialog.component';
import {Approval} from 'src/app/model/approval';
import {NormalisedRuleSet} from 'src/app/model/normalised-ruleset';
import {RuleApprovals} from 'src/app/model/rule-approvals';
import {WorkspaceAutoSaveService} from 'src/app/services/workspace-auto-save.service';
import {ApprovalConfig} from '../../common/domain/approval';
import {ChangeDetails, DocgenConfigAndMetadataChangeDetails} from '../../common/domain/change';
import {DocgenConfig} from '../../common/domain/docgen-config';
import {ConfirmDialogComponent} from '../../dialogs/confirm-dialog.component';
import {JiraIssueDialogComponent} from '../../dialogs/jira-issue-dialog/jira-issue-dialog.component';
import {PushConfigRequest} from '../../model/config-requests';
import {GetConfigFileSuccessResponse, PublishConfigResponse} from '../../model/config-responses';
import {ApiService} from '../../services/api.service';
import {ChangeService} from '../../services/change.service';
import {PermissionsService} from '../../services/permissions.service';
import {UserNameService} from '../../services/user-name.service';
import { unparse } from 'papaparse'
// const stringify = require('csv-stringify/lib/sync')

interface ApprovalRule {
  sourceSystem: string
  programme: string
  productType: string
  documentType: string
  mnemonic: string
  approvedBy?: string
  approvedDate?: string
}

@Component({
  selector: 'app-config-approvals',
  templateUrl: './config-approvals.component.html',
  styleUrls: ['./config-approvals.component.scss'],
})
export class ConfigApprovalsComponent implements OnInit, OnDestroy {
  public displayedColumns: string[] = [
    'programme', 'productType', 'documentType', 'mnemonic', 'approvedBy', 'approvedDate', 'actions'
  ];
  public dataSource = new MatTableDataSource<ApprovalRule>(new Array<ApprovalRule>());
  public filterTableData = ''

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  destroy$ = new Subject()
  timer$ = timer(0, 60000)
  reload$ = new Subject()
  refresh$ = merge(this.timer$, this.reload$)
  canApproveRule = false
  public rules: ApprovalRule[] = []
  public clonedRules: ApprovalRule[] = []
  user: string = undefined
  private _normalisedRuleSets: NormalisedRuleSet[] = new Array<NormalisedRuleSet>()
  private readOnly = true
  private _isDeveloper = true // Set to correct value in ngOnInit
  private _apiRuleSetsSubscription: Subscription

  constructor(
    private changeService: ChangeService,
    private permissionsService: PermissionsService,
    private userNameService: UserNameService,
    private api: ApiService,
    private toast: ToastrService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private _workspaceAutoSaveService: WorkspaceAutoSaveService
  ) {
    permissionsService.readonly.subscribe(permission => this.readOnly = permission)
    this.permissionsService.canApproveRule.subscribe(permission => this.canApproveRule = permission)
    this.userNameService.userName.subscribe(user => this.user = user)
  }

  ngOnInit(): void {
    this.permissionsService.developer.subscribe(
      (permission: boolean) => {
        this._isDeveloper = permission
        console.info('_isDeveloper: ' + this._isDeveloper)
      })

    const apiRuleSets$ = this.changeService.getObjectsOfType('rulesets')
    this._apiRuleSetsSubscription = apiRuleSets$.subscribe(
      (changes: ChangeDetails[]) => {
        // console.info(`this.changeService.getObjectsOfType('rulesets') ${changes.length}`)
        // console.info(JSON.stringify(changes))
        if (changes.length > 0) {
          this.getDataFromChangeService()
        }
      })

    this.changeService.getUserFilteredDocgenConfigs()
      .pipe(
        takeUntil(this.destroy$),
        map((configs: DocgenConfigAndMetadataChangeDetails[]) => {
          return configs.reduce((allApprovalRuleTables: ApprovalRule[], configAndMetadata: DocgenConfigAndMetadataChangeDetails) => {
            const config = JSON.parse(configAndMetadata.settings.content) as DocgenConfig
            const payloadApprovalRules: ApprovalRule[] = config.analysePayload.rules
              .filter(r => r.ruleType === 'whitelist' && !r.deletion && r.approvalRequired)
              .reduce((combinedApprovalRules: ApprovalRule[], ruleTable) => {
                const approvalRules = ruleTable.rule
                  .filter(rule => !rule.deletion)
                  .map(rule => {
                    return {
                      sourceSystem: configAndMetadata.metadata.sourceSystem,
                      programme: configAndMetadata.metadata.programme,
                      productType: configAndMetadata.metadata.productType,
                      documentType: configAndMetadata.metadata.documentType,
                      mnemonic: 'PW-' + ruleTable.mnemonic + '-' + rule.mnemonic,
                      approvedBy: rule.approved?.user,
                      approvedDate: rule.approved?.timestamp,
                    }
                  })
                return combinedApprovalRules.concat(...approvalRules)
              }, [])
            const soloApprovalRules: ApprovalRule[] = config.analyseEnriched.rules
              .filter(r => r.ruleType === 'whitelist' && !r.deletion && r.approvalRequired)
              .reduce((combinedApprovalRules: ApprovalRule[], ruleTable) => {
                const approvalRules = ruleTable.rule
                  .filter(rule => !rule.deletion)
                  .map(rule => {
                    return {
                      sourceSystem: configAndMetadata.metadata.sourceSystem,
                      programme: configAndMetadata.metadata.programme,
                      productType: configAndMetadata.metadata.productType,
                      documentType: configAndMetadata.metadata.documentType,
                      mnemonic: 'SW-' + ruleTable.mnemonic + '-' + rule.mnemonic,
                      approvedBy: rule.approved?.user,
                      approvedDate: rule.approved?.timestamp,
                    }
                  })
                return combinedApprovalRules.concat(...approvalRules)
              }, [])

            return allApprovalRuleTables.concat(...payloadApprovalRules).concat(...soloApprovalRules)
          }, [])
        }),
        tap(rules => {
          console.log('Rules before approval check')
          // console.dir(rules)
        }),
        switchMap(rules => {
          // we have the list of all rules that require approval, so monitor the fast approval pipes for changes
          return this.refresh$
            .pipe(
              switchMap(_ => {
                const cache: {[key: string]: Observable<ApprovalConfig>} = {}

                return of(...rules)
                  .pipe(
                    mergeMap(rule => {
                      const path = `/config/latest/cs-lambda-approvals-config/config/approvals/${rule.sourceSystem}/${rule.programme}/${rule.productType}/${rule.documentType}/Approvals.json`
                      if (!cache[path]) {
                        cache[path] = this.api.get<GetConfigFileSuccessResponse>(path)
                          .pipe(
                            take(1),
                            map(res => {
                              if (!res.data) {
                                // it waas an error - so return an empty approvals response
                                return {approvedMnemonics: []}
                              }
                              return JSON.parse(res.data) as ApprovalConfig
                            }),
                            // tap(rules => {
                            //   console.log('Approvals for ' + path)
                            //   console.dir(rules)
                            // }),
                            shareReplay(1),
                          )
                      }
                      const updated = cache[path]
                        .pipe(
                          map(config => {
                            const approval = config.approvedMnemonics.find(a => a.mnemonic === rule.mnemonic)
                            return Object.assign({}, rule, {
                              approvedBy: rule.approvedBy || approval?.approvedBy,
                              approvedDate: rule.approvedDate || approval?.approvedDate,
                            })
                          }),
                        )

                      return updated
                    }),
                    toArray(),
                  )
              }),
            )
        }),
        tap(rules => {
          console.log('Rules after approval check')
          // console.dir(rules)
        }),
      )
      .subscribe(rules => {
        console.log('Approved Rules after approval check')
        // console.dir(rules.filter(r => r.approvedBy))
        this._syncChangeServiceChangesWithClonedRules()
        rules.sort((a, b) => a.mnemonic.localeCompare(b.mnemonic))
        this.rules = rules.sort((a, b) => a.programme.localeCompare(b.programme)
          || a.productType.localeCompare(b.productType)
          || a.documentType.localeCompare(b.documentType)
          || a.mnemonic.localeCompare(b.mnemonic)
        )
        // console.info('Before Clone')
        // Clone of all Sparrow takes 66ms on a developer machine (7th gen i5 Intel NUC)
        this.clonedRules = cloneDeep(rules)
        // console.info('After Clone')

        this.displayTableData()
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  public async displayTableData() {
    // console.info(this.filterTableData)

    // console.info('Before Filter')
    const rulesToDisplay = this.filterTableData == '' ? this.clonedRules : this.clonedRules.filter(x => x.mnemonic.includes(this.filterTableData))
    // console.info('After Filter')

    this.dataSource = new MatTableDataSource<ApprovalRule>(rulesToDisplay);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  // Ensure this.clonedRules contains any update to NormalisedRuleSets sitting in the change service
  //  Takes less than 100ms for the whole of sparrow displayed on screen. Under 1ms for data filtered to single RuleSet/ Rule
  private async _syncChangeServiceChangesWithClonedRules() {
    // console.info('Before looking for changes that need applying')
    const configServiceChanges = await this.changeService.getAllChanges()
    // console.info('configServiceChanges follow')
    // console.dir(configServiceChanges)
    const configServiceChangesForRuleSetDefinitions = configServiceChanges.filter(x => x.source === 'lambda-ingest-product' && x.path.startsWith('rulesets/'))
    // console.info('configServiceChangesForRuleSetDefinitions follow')
    // console.info(JSON.stringify(configServiceChangesForRuleSetDefinitions, null, 2))

    // Loop through the changes for RuleSetDefinitions 
    configServiceChangesForRuleSetDefinitions.forEach((curChangeDetails: ChangeDetails) => {

      // Instatiate the current NormalisedRuleSet
      const curNormalisedRuleSet = new NormalisedRuleSet(curChangeDetails.path)
      Object.assign(curNormalisedRuleSet, JSON.parse(curChangeDetails.content))
      // Get Full Mnemonic Prefic. e.g. 'PW-LULIP-'
      let curFullMnemonicPrefix = curNormalisedRuleSet.stage == 'solo' ? 'S' : 'P'
      curFullMnemonicPrefix += (curNormalisedRuleSet.ruleType + '').substring(0, 1).toUpperCase() + '-' + curNormalisedRuleSet.mnemonic + '-'
      console.info(`fullMnemonicPrefix: ${curFullMnemonicPrefix}`)

      // Update this.clonedRules with the approvals on the current NormalisedRuleSet
      //  This takes under 1ms on my development machine
      curNormalisedRuleSet.ruleApprovals.forEach((curRuleApprovals: RuleApprovals) => {
        // Get Source System etc. We use this later to lookup entries
        const curSourceSystem = curRuleApprovals.sourceSystem
        const curProgramme = curRuleApprovals.programme
        const curProductType = curRuleApprovals.productType
        const curDocumentType = curRuleApprovals.documentType
        // console.info(`${curSourceSystem}-${curProgramme}-${curProductType}-${curDocumentType}`)

        // Filer this.clonedRules to sourceSystem, programme, productType and documentType
        const clonedRulesOfInterest = this.clonedRules.filter((curClonedApprovalRule: ApprovalRule) =>
          curClonedApprovalRule.sourceSystem === curSourceSystem &&
          curClonedApprovalRule.programme === curProgramme
          && curClonedApprovalRule.productType === curProductType
          && curClonedApprovalRule.documentType === curDocumentType
          // && curClonedApprovalRule.mnemonic === curFullMnemonic
        )

        // Loop through Approvals in current NormalisedRuleSet RuleApprovals. I appreciate this method may be confusing by now
        //  Each RuleApprovals array entry maps to one sourceSystem, programme, productType and documentType combination
        curRuleApprovals.approvals.forEach((curApproval: Approval) => {

          const curFullMnemonic = curFullMnemonicPrefix + curApproval.ruleMnemonic // e.g. PW-LUL-1002. Here we just suffixed the curent Rule Number (which we also call a mnemonic)
          // console.info(`curFullMnemonic: ${curFullMnemonic}`)

          // Does this Source System etc. exist in this.clonedRules
          const locatedClonedApprovalRule = clonedRulesOfInterest.find((curClonedApprovalRule: ApprovalRule) => curClonedApprovalRule.mnemonic === curFullMnemonic)
          // console.info(`locatedClonedApprovalRule: ${locatedClonedApprovalRule}`)

          // If located and it does not have an Approval then copy the Change Service Approval (which could be from a Workspace) to this.clonedRules (data this screen's table will display)
          //  Did you follow all this? Simple eh. It honestly will seem simple eventually. There are far more involved pieces of similar logic in Storm + lamba-ingest-product (mostly related to iterating through DocgenConfigs)
          if (!!locatedClonedApprovalRule && !!locatedClonedApprovalRule.approvedBy) {
            // console.info(`${curFullMnemonic} on ${curSourceSystem}-${curProgramme}-${curProductType}-${curDocumentType} is already approvedBy: ${locatedClonedApprovalRule.approvedBy}`)
          }
          // If located, but not already Approved then copy in the approval
          if (!!locatedClonedApprovalRule && !locatedClonedApprovalRule.approvedBy) {
            locatedClonedApprovalRule.approvedBy = curApproval.user
            locatedClonedApprovalRule.approvedDate = curApproval.timestamp
          }
        })

      })
    })
    // console.info('After looking for changes that need applying')

  }

  public async getDataFromChangeService() {
    // console.info('getDataFromChangeService()')

    // Hack to know when data is ready. Stop its subscription
    if (!!this._apiRuleSetsSubscription) {
      this._apiRuleSetsSubscription.unsubscribe()
    }

    const normalisedRuleSets$ = this.changeService.getObjectsOfType('rulesets/')

    const normalisedRuleSetsChangeDetails = await normalisedRuleSets$.pipe(take(1)).toPromise()

    const normalisedRuleSets = normalisedRuleSetsChangeDetails.map(x => {
      const newNormalisedRuleSet = new NormalisedRuleSet(x.path)
      const deserialised: NormalisedRuleSet = JSON.parse(x.content)
      Object.assign(newNormalisedRuleSet, deserialised)
      return newNormalisedRuleSet
    })
    this._normalisedRuleSets = normalisedRuleSets
  }


  public async slowApprove(approvalRule: ApprovalRule) {
    console.log('slowApprove: ' + JSON.stringify(approvalRule))

    const ruleSetMnemonic = approvalRule.mnemonic.split('-')[1]

    // Ensure Developers cannot Approve
    if (this._isDeveloper) {
      console.info('Approve Disabled.')
      console.dir(approvalRule)

      await this.dialog.open(AlertDialogComponent, {
        position: {top: '200px'},
        data: {
          title: '== Approve Disabled ==',
          message: 'Developer logins cannot approve. Browser Console Logs contains what you would have approved.',
        },
      }).afterClosed().toPromise()
      return
    }

    // User is permitted to Slow Approve the Rule
    {
      if (this._normalisedRuleSets.findIndex(x => x.mnemonic === ruleSetMnemonic) > -1) {
        await this._slowApproveNewFormat(approvalRule)
      } else {
        await this._slowApproveOldFormat(approvalRule)
      }
    }

    this._workspaceAutoSaveService.saveWorkspace()
  }

  private async _slowApproveOldFormat(approvalRule: ApprovalRule) {
    console.log('_slowApproveOldFormat')
    const userFilteredDocgenConfigs = await this.changeService.getUserFilteredDocgenConfigs()
      .pipe(take(1))
      .toPromise()

    // this.changeService.getUserFilteredDocgenConfigs()
    //   .pipe(
    //     take(1),
    //   )
    //   .subscribe(configs => {
    const configAndMetadata = userFilteredDocgenConfigs.find(c => {
      return c.metadata.sourceSystem === approvalRule.sourceSystem
        && c.metadata.programme === approvalRule.programme
        && c.metadata.productType === approvalRule.productType
        && c.metadata.documentType === approvalRule.documentType
    })
    const config = JSON.parse(configAndMetadata.settings.content) as DocgenConfig
    const analysisConfig = approvalRule.mnemonic.startsWith('P') ? config.analysePayload : config.analyseEnriched
    const ruleTableMnemonic = approvalRule.mnemonic.split('-')[1]
    const ruleMnemonic = approvalRule.mnemonic.split('-')[2]
    const ruleTable = analysisConfig.rules.find(r => !r.deletion && r.mnemonic === ruleTableMnemonic)
    const ruleRow = ruleTable.rule.find(r => !r.deletion && r.mnemonic === ruleMnemonic)
    ruleRow.approved = {
      comment: 'Approved in storm',
      user: this.user,
      timestamp: new Date().toISOString(),
    }
    this.changeService.addChange(Object.assign(
      {},
      configAndMetadata.settings,
      {
        content: JSON.stringify(config, undefined, 2),
      }),
    )
    // })
  }

  private async _slowApproveNewFormat(approvalRule: ApprovalRule) {
    console.log('_slowApproveNewFormat')
    const ruleSetMnemonic = approvalRule.mnemonic.split('-')[1]
    const ruleMnemonic = approvalRule.mnemonic.split('-')[2]

    // Get the Rule Definition File
    const ruleSetDefinitionChangeDetails = this.changeService.getObject('rulesets/' + ruleSetMnemonic.toUpperCase() + '.json')
    // console.info('ruleSetDefinitionChangeDetails')
    // console.dir(ruleSetDefinitionChangeDetails)
    const ruleSetDefinition = JSON.parse(ruleSetDefinitionChangeDetails.content) as NormalisedRuleSet
    {
      let approvals = ruleSetDefinition.ruleApprovals.find(x =>
        x.documentType === approvalRule.documentType
        && x.productType === approvalRule.productType
        && x.programme === approvalRule.programme
        && x.sourceSystem === approvalRule.sourceSystem
      )
      // Create entry if it does not exist
      if (!approvals) {
        approvals = new RuleApprovals()

        approvals.documentType = approvalRule.documentType
        approvals.productType = approvalRule.productType
        approvals.programme = approvalRule.programme
        approvals.sourceSystem = approvalRule.sourceSystem
        approvals.approvals = new Array<Approval>()
        ruleSetDefinition.ruleApprovals.push(approvals)
      }

      // If there is an existing Rule Approval then remove it (we will overwrite)
      {
        const existingRuleApproval = approvals.approvals.find(x => x.ruleMnemonic === ruleMnemonic)
        if (!!existingRuleApproval) {
          approvals.approvals = approvals.approvals.filter(x => x.ruleMnemonic !== ruleMnemonic)
        }
      }

      // Add a new Rule Approval
      {
        const newApproval = new Approval()
        newApproval.ruleMnemonic = ruleMnemonic
        newApproval.comment = 'Approved via UI'
        newApproval.user = this.user
        newApproval.timestamp = new Date().toISOString()
        approvals.approvals.push(newApproval)
      }

      ruleSetDefinitionChangeDetails.content = JSON.stringify(ruleSetDefinition, null, 2)
      await this.changeService.addChange(ruleSetDefinitionChangeDetails)
      // Opted to comment this out and leave the other call to display the change. Otherwise the user might think it already time they can click to approve another
      // await this._syncChangeServiceChangesWithClonedRules()
    }
  }

  public async approveNow(rule: ApprovalRule) {

    // Ensure Developers cannot Approve
    if (this._isDeveloper) {
      console.info('Approve Now Disabled.')
      console.dir(rule)

      await this.dialog.open(AlertDialogComponent, {
        position: {top: '200px'},
        data: {
          title: '== Approve Now Disabled ==',
          message: 'Developer logins cannot Approve Now. Browser Console Logs contains what you would have approved.',
        },
      }).afterClosed().toPromise()
    } else {
      this._approveNow(rule)
    }

  }

  private _approveNow(rule: ApprovalRule) {
    const configPath = `/config/latest/lambda-approvals/config/approvals/${rule.sourceSystem}/${rule.programme}/${rule.productType}/${rule.documentType}/Approvals.json`
    const confirmDialog = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })
    confirmDialog.componentInstance.title = 'Approve Immediately'
    confirmDialog.componentInstance.message = 'Are you sure you want to approve ' + rule.mnemonic + ' immediately in production?'

    if (this.readOnly) {
      this.toast.warning('This would have approved this rule immediately')
      return
    }

    confirmDialog.result
      .then(_ => this.modalService.open(JiraIssueDialogComponent).result)
      .then((res: {jiraIssue: string, description: string}) => {
        this.api.get<GetConfigFileSuccessResponse>(configPath)
          .subscribe(latest => {
            // @ts-ignore
            if (latest.code === 'FileDoesNotExistException') {
              // Coerce missing approvals at the specific version into a new approvals object
              latest.data = JSON.stringify({approvedMnemonics: []})
            }
            const approvalConfig = JSON.parse(latest.data) as ApprovalConfig
            if (!approvalConfig.approvedMnemonics.find(m => m.mnemonic === rule.mnemonic)) {
              const updated = Object.assign({}, approvalConfig, {
                approvedMnemonics: approvalConfig.approvedMnemonics.concat({
                  mnemonic: rule.mnemonic,
                  approvedBy: this.user,
                  approvedDate: new Date().toISOString(),
                }),
              })
              const updatedRule = JSON.stringify(updated, undefined, 2)
              const approvalPayload: PushConfigRequest = {
                commitMessage: `${res.jiraIssue} - ${res.description} - Approved ${rule.mnemonic} in ${rule.sourceSystem}/${rule.programme}/${rule.productType}/${rule.documentType} via Storm`,
                data: updatedRule,
                parentCommit: latest.branchVersion,
              }
              this.api.post(configPath, approvalPayload)
                .subscribe((res: PublishConfigResponse) => {
                  if (res.status === 'OK') {
                    this.reload$.next()
                    this.toast.success('Approval submitted successfully')
                  } else {
                    this.toast.error('Approval Failed - Please reload and try again')
                  }
                })
            }
          })
      })
      .catch(_ => {
        this.toast.warning('Approval cancelled')
      })
  }

  downloadApprovals() {
    const allCsv = unparse(this.rules, {
      header: true,
    })

    const blob = new Blob([allCsv], {type: 'text/csv;charset=utf-8'})
    saveAs(blob, `rule-approvals-${new Date().toISOString()}.csv`)
  }

}
