import {Component, Inject, OnInit} from '@angular/core';
import {DmExtractorFields, DynamicMapping} from "../../views/config-mappings-dynamic/config-mappings-dynamic.component";
import {UntypedFormControl} from "@angular/forms";
import {Subject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ChangeService} from "../../services/change.service";
import {WorkspaceAutoSaveService} from "../../services/workspace-auto-save.service";

@Component({
  selector: 'app-edit-dynamic-mappings-dialog',
  templateUrl: './edit-dynamic-mappings-dialog.component.html',
  styleUrls: ['./edit-dynamic-mappings-dialog.component.scss']
})
export class EditDynamicMappingsDialogComponent implements OnInit {

  public canSave = false
  public newDynamicMapping: DynamicMapping
  myControl = new UntypedFormControl();
  payloadTypes: string[] = ['xml', 'json']
  private showAdditionalExtractorFieldsSubject = new Subject<boolean>()
  public addAdditionalExtractorFields = false
  public extractorFieldConditions: {value: string, newProductType: string}[] = []

  constructor(
    public dialogRef: MatDialogRef<EditDynamicMappingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    protected workspaceAutoSaveService: WorkspaceAutoSaveService,
  ) {
    const currentMapping = data.editedMapping
    this.newDynamicMapping = {
      sourceSystem: currentMapping.sourceSystem,
      documentType: currentMapping.documentType,
      productType: currentMapping.productType,
      programme: currentMapping.programme,
      payloadType: currentMapping.payloadType,
      extractorFields: currentMapping.extractorFields
    } as DynamicMapping
  }

  ngOnInit(): void {
    console.log(this.newDynamicMapping)
    this.showAdditionalExtractorFieldsSubject.subscribe(value => {
      this.addAdditionalExtractorFields = value
    })

  }

  actionCancel() {
    this.dialogRef.close(undefined)
  }

  public async actionCreate() {
    console.log(this.newDynamicMapping)
    if (this.newDynamicMapping) {
      this.dialogRef.close(this.newDynamicMapping)
    }
  }

  public validateInput() {
    const canSave: boolean = this.newDynamicMapping.sourceSystem?.length > 0
      && this.newDynamicMapping.documentType?.length > 0
      && this.newDynamicMapping.productType?.length > 0
      && this.newDynamicMapping.programme?.length > 0
      && this.newDynamicMapping.payloadType?.length > 0

    this.canSave = canSave
  }

  public showAdditionalExtractorFields() {
    const newExtractorField: DmExtractorFields = {
      extractorPath: '',
      conditions: []
    }
    this.newDynamicMapping = {...this.newDynamicMapping,  extractorFields: newExtractorField}
    this.showAdditionalExtractorFieldsSubject.next(!this.addAdditionalExtractorFields)
    this.actionAddCondition()
  }

  trackByIndex(index: number): number {
    return index
  }

  actionAddCondition() {
    const currentConditions = [...this.newDynamicMapping.extractorFields.conditions]
    currentConditions.push({value: '', newProductType: ''})
    this.newDynamicMapping.extractorFields.conditions = currentConditions
  }

  removeCondition(condition) {
    let currentConditions = [...this.newDynamicMapping.extractorFields.conditions]
    currentConditions = currentConditions.filter(obj => obj !== condition);
    this.newDynamicMapping.extractorFields.conditions = currentConditions
  }

}
