import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router'
import {combineLatest, Observable, of} from 'rxjs'
import {PermissionsService} from '../services/permissions.service'
import {Injectable} from '@angular/core'
import {filter, switchMap} from 'rxjs/operators'
import {UserNameService} from '../services/user-name.service'

@Injectable({
  providedIn: 'root',
})
export class PlaygroundGuard implements CanActivate {

  constructor(
    private permissionsService: PermissionsService,
    private userNameService: UserNameService
  ) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.permissionsService.isReady.pipe(
      filter(ready => ready),
      switchMap(() => combineLatest([this.permissionsService.canViewPlayground, this.userNameService.userName])),
      switchMap(([canView, userName]) => {
        return of(canView)
      }),
    )
  }
}
