import {isZippedTraceResponse, ZippedTraceResponse} from '../model/public-api-response'
import * as JSZip from 'jszip'

export const unzipIfNecessary = async <T>(trace: T | ZippedTraceResponse): Promise<T> => {
  if (isZippedTraceResponse(trace)) {
    const zipObject = await new JSZip().loadAsync(trace.zip, {base64: true})
    const data = await zipObject.file('trace').async('text')
    return JSON.parse(data) as T
  }
  return trace
}
