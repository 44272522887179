import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core'
import {TestManagerService} from '../../services/test-manager.service'
import {map, switchMap, takeUntil} from 'rxjs/operators'
import {ConfigService} from '../../services/config.service'
import {DocgenPayloadInformation, TestMetadata} from '../../common/domain/tests'
// import * as stringify from 'csv-stringify/lib/sync'
import {DateTime} from 'luxon'
import { unparse } from 'papaparse'
import saveAs from 'file-saver'
interface ProposedTestAction {
  name: string
  sourceSystem: string
  programme: string
  productType: string
  documentType: string
  validConfig: 'Valid Config' | 'No Config'
  prodClone: 'Prod Clone' | 'Normal Test'
  inSuite: 'In Suite' | 'Orphaned'
  proposal: 'LEAVE' | 'DELETE'
}

interface ProposedSummary {
  totalTests: number
  totalRemaining: number
  totalDeleted: number
  deleteOrphanedProdClone: number
  deleteInvalidConfigs: number
}

@Component({
  selector: 'app-test-cleanup',
  templateUrl: './test-cleanup.component.html',
  styleUrls: ['./test-cleanup.component.scss'],
})
export class TestCleanupComponent implements OnInit, OnDestroy {
  $destory = new EventEmitter()
  loaded = false
  canDelete = false
  orphanedProdClone = true
  oldConfiguration = true

  data: { tests: TestMetadata[]; configs: string[]; suiteTests: string[] } = {
    tests: [],
    configs: [],
    suiteTests: [],
  }
  result: ProposedTestAction[] = []
  summary: ProposedSummary

  constructor(
    private configService: ConfigService,
    private testManagerService: TestManagerService,
  ) {
  }

  ngOnInit(): void {
    this.loaded = false
    this.canDelete = false
    this.testManagerService.setService('docgen')
    this.testManagerService.enableProdClone(true)
    this.configService.allDocgenConfigs
      .pipe(
        map(configs => {
          return configs
            .filter(c => c.path.startsWith('settings/'))
            .map(c => c.path.replace(/^settings\/(.*)\/Config\.json$/, '$1'))
        }),
        switchMap(config => {
          return this.testManagerService.tests.pipe(
            map(tests => {
              return {config: config, tests: tests}
            }),
          )
        }),
        switchMap(data => {
          return this.testManagerService.testSuites.pipe(
            map(suites => {
              return {suites: suites, ...data}
            }),
          )
        }),
      )
      .pipe(
        takeUntil(this.$destory),
      )
      .subscribe(data => {
        const suiteTests = data.suites.reduce((tests, suite) => {
          return tests.concat(suite.tests)
        }, []).filter((v, i, a) => a.indexOf(v) === i)
        this.data = {
          tests: data.tests,
          configs: data.config,
          suiteTests: suiteTests,
        }
        this.loaded = true
        this.updateSummary()
      })
  }

  ngOnDestroy(): void {
    this.$destory.emit()
  }

  updateSummary() {
    this.summary = undefined
    this.result = this.data.tests.map(test => {
      const def = test.product as DocgenPayloadInformation
      const testConfigKey = `${def.sourceSystem}/${def.programme}/${def.productType}/${def.documentType}`
      const validConfig = this.data.configs.includes(testConfigKey)
      const prodClone = test.name.startsWith('TEST - ')
      const inSuite = this.data.suiteTests.includes(test.name)
      let toDelete = false
      if (this.orphanedProdClone && prodClone && !inSuite) {
        toDelete = true
      }
      if (this.oldConfiguration && !validConfig) {
        toDelete = true
      }
      return {
        name: test.name,
        sourceSystem: def.sourceSystem,
        programme: def.programme,
        productType: def.productType,
        documentType: def.documentType,
        validConfig: validConfig ? 'Valid Config' : 'No Config',
        prodClone: prodClone ? 'Prod Clone' : 'Normal Test',
        inSuite: inSuite ? 'In Suite' : 'Orphaned',
        proposal: toDelete ? 'DELETE' : 'LEAVE',
      }
    })

    const totalTests = this.result.length
    const totalDeleted = this.result.filter(t => t.proposal === 'DELETE')
    const totalRemaining = this.result.filter(t => t.proposal === 'LEAVE')
    const deleteInvalidConfigs = this.result.filter(t => t.proposal === 'DELETE' && t.validConfig === 'No Config')
    const deleteOrphanedProdClone = this.result.filter(t => t.proposal === 'DELETE' && t.prodClone === 'Prod Clone' && t.inSuite === 'Orphaned')
    this.summary = {
      totalTests: totalTests,
      totalDeleted: totalDeleted.length,
      totalRemaining: totalRemaining.length,
      deleteInvalidConfigs: deleteInvalidConfigs.length,
      deleteOrphanedProdClone: deleteOrphanedProdClone.length,
    }
    this.canDelete = totalDeleted.length > 0
  }

  downloadProposedChanges() {
    const csv = unparse(this.result, {header: true})
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
    saveAs(blob, 'rule-cleanup-proposal-' + DateTime.utc().toFormat('yyyyLLddhhmmss') + '.csv')
  }

  cleanupTests() {
    this.testManagerService.deleteTests(this.result.filter(t => t.proposal === 'DELETE').map(t => t.name))
      .then(response => {
        const result = [];
        const csvTemplateObject = {Test: null, 'Copied to Archive and Deleted': 'N/A', 'Not Copied nor Deleted': 'N/A', 'Copied but Not Deleted': 'N/A', Message: 'N/A'};

        response.testsDeleted.forEach(test => { result.push({...csvTemplateObject, Test: test, 'Copied to Archive and Deleted': 'TRUE'})});
        response.testsNotCopiedNorDeleted.forEach(el => { result.push({...csvTemplateObject, Test: el.key, 'Not Copied nor Deleted': 'TRUE', Message: el.message})});
        response.testsNotDeleted.forEach(el => { result.push({...csvTemplateObject, Test: el.key, 'Copied but Not Deleted' : 'TRUE', Message: el.message})});

        const csv = unparse(result, {header: true})
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
        saveAs(blob, 'deleted-rules-response-' + DateTime.utc().toFormat('yyyyLLddhhmmss') + '.csv')
      })
  }
}
