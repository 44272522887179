import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { ChartPlaygroundComponent } from './playground/chart-playground/chart-playground.component';
import { ListingPayloadComponent } from './playground/listing-payload/listing-payload.component';
import { PlaygroundComponent } from './playground/playground.component';
import { LatestApprovalResolver } from './util/latest-approval-resolver';
import { LatestConfigResolver } from './util/latest-config-resolver';
import { TestExecutionResolver } from './util/test-execution-resolver';
import { TestHistoryResolver } from './util/test-history-resolver';
import { TestResolver } from './util/test-resolver';
import { VersionedConfigResolver } from './util/versioned-config-resolver';
import { AdminRegxchangeGetComponent } from './views/admin-regxchange-get/admin-regxchange-get.component';
import { AdminRegxchangePostComponent } from './views/admin-regxchange-post/admin-regxchange-post.component';
import { AdminSmartDxComponent } from './views/admin-smartdx/admin-smartdx.component';
import { ApproveRuleComponent } from './views/approve-rule/approve-rule.component';
import { ConfigApprovalsComponent } from './views/config-approvals/config-approvals.component';
import { ConfigEditComponent } from './views/config-edit/config-edit.component';
import { ConfigFilterComponent } from './views/config-filter/config-filter.component';
import { ConfigListComponent } from './views/config-list/config-list.component';
import { ConfigMappingComponent } from './views/config-mapping/config-mapping.component';
import { NewConfigRulesComponent } from './views/config-rules/config-rules.component';
import { ConfigStaticComponent } from './views/config-static/config-static.component';
import { ConfigTargetsComponent } from './views/config-targets/config-targets.component';
import { ConfigTemplatesComponent } from './views/config-templates/config-templates.component';
import { DashboardComponent } from './views/dashboard.component';
import { DocgenConfigApprovalComponent } from './views/docgen-config-approval/docgen-config-approval.component';
import { DocumentGeneratorComponent } from './views/document-generator.component';
import { InfrastructureComponent } from './views/infrastructure.component';
import { InspectComponent } from './views/inspect.component';
import { Maintain2021RulesetComponent } from './views/maintain-2021-ruleset-component/maintain-2021-ruleset-component';
import { ManagementInformationReportComponent } from './views/mi-report/management-information-report.component';
import { ProductGovernanceComponent } from './views/product-governance.component';
import { PublishConfigApprovalComponent } from './views/publish-config-approval/publish-config-approval.component';
import { PublishComponent } from './views/publish/publish.component';
import { TemplatesApprovalComponent } from './views/templates-approval/templates-approval.component';
import { TestMigratorComponent } from './views/test-migrator.component';
import { TestSuiteExecutionsComponent } from './views/test-suite-executions.component';
import { TestSuiteManagerComponent } from './views/test-suite-manager.component';
import { TestSuiteCloneComponent } from './views/test-suite-clone.component';
import { ConfigMappingsDynamicComponent } from './views/config-mappings-dynamic/config-mappings-dynamic.component';
import { ConfigSamplesEditComponent } from './views/config-samples/config-samples-component';
import { DocgenReleaseChangesComponent } from './components/docgen-release-changes/docgen-release-changes.component';
import { JmesPathViewComponent } from './views/jmes-path-view/jmes-path-view.component';
import { RulesetAuditHistoryComponent } from './components/ruleset-audit-history/ruleset-audit-history.component';
import { TestCleanupComponent } from './views/test-cleanup/test-cleanup.component';
import { AdminPayLoadComponent } from './components/manual-drafting/administer-payloads/admin-payloads.component';
import { AdministerValidationRulesComponent } from './components/manual-drafting/administer-validation-rules/administer-validation-rules.component';
import { AdministerEnrichmentRulesComponent } from './components/manual-drafting/administer-erichment-rules/administer-enrichment-rules.component';
import { OutputDisplayComponent } from './components/manual-drafting/output-display/output-display.component';
import {PlaygroundGuard} from './guards/playground-guard.service'
import {ConfigGuard} from './guards/config-guard.service'
import {GenerateGuard} from './guards/generate-guard.service'
import {TestsGuard} from './guards/tests-guard.service'
import {PublishGuard} from './guards/publish-guard.service'
import {InspectGuard} from './guards/inspect-guard.service'
import {ManualDraftingGuard} from './guards/manual-drafting-guard.service'
import {AdminGuard} from './guards/admin-guard.service'
import { DisplayTestArchiveComponent } from './views/display-test-archive/display-test-archive.component';
import { PreprocessorMappingComponent } from './views/preprocessor-mapping/preprocessor-mapping.component';
import { PreprocessorGuard } from './guards/preprocessor-logic-guard.service';
import { DynamicMappingGuard } from './guards/dynamic-mapping-guard.service';
import { AdminWsdManualPostComponent } from './views/admin-wsd-manual-post/admin-wsd-manual-post.component';
export type Selections = {
  showStormInSourceEnvironment?: boolean;
  showSourceEnvironmentSelection?: boolean;
  showTargetEnvironmentSelection?: boolean;
  showAdminTargetEnvironmentSelection?: boolean;
  showWorkspaceSelection?: boolean;
  showTestSuiteSelection?: boolean;
  showDocgenConfigSelection?: boolean;
  showPublishConfigSelection?: boolean;
  hideSkin?: boolean;
};

export type RoutePlus = Route & Selections;
export type RoutesPlus = RoutePlus[];

export const routes: RoutesPlus = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
    showSourceEnvironmentSelection: true,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    showSourceEnvironmentSelection: true,
  },
  {
    path: 'playground/listing',
    component: PlaygroundComponent,
    // showSourceEnvironmentSelection: true,
    // showTargetEnvironmentSelection: true,
    // showWorkspaceSelection: true,
    // showDocgenConfigSelection: true,
    // showPublishConfigSelection: true,
    canActivate: [PlaygroundGuard],
  },
  {
    path: 'playground/upload',
    component: ListingPayloadComponent,
    canActivate: [PlaygroundGuard],
  },
  {
    path: 'playground/chart',
    component: ChartPlaygroundComponent,
    canActivate: [PlaygroundGuard],
  },
  {
    path: 'config/static',
    component: ConfigStaticComponent,
    canActivate: [ConfigGuard],
  },
  {
    path: 'config/filter',
    component: ConfigFilterComponent,
    canActivate: [ConfigGuard],
  },
  {
    path: 'config/templates',
    component: ConfigTemplatesComponent,
    canActivate: [ConfigGuard],
  },
  {
    path: 'config/rules',
    component: NewConfigRulesComponent,
    showWorkspaceSelection: true,
    showDocgenConfigSelection: true,
    canActivate: [ConfigGuard],
  },
  {
    path: 'config/rulesets',
    component: Maintain2021RulesetComponent,
    showWorkspaceSelection: true,
    showDocgenConfigSelection: true,
    canActivate: [ConfigGuard],
  },
  {
    path: 'config/preprocessor',
    component: PreprocessorMappingComponent,
    canActivate: [PreprocessorGuard]
  },
  {
    path: 'config/rulesets/audit/:mnemonic',
    component: RulesetAuditHistoryComponent,
    canActivate: [ConfigGuard],
  },
  {
    path: 'config/approvals',
    component: ConfigApprovalsComponent,
    showWorkspaceSelection: true,
    canActivate: [ConfigGuard],
  },
  {
    path: 'config/mappings/static',
    component: ConfigMappingComponent,
    showWorkspaceSelection: true,
    showDocgenConfigSelection: true,
    canActivate: [ConfigGuard],
  },
  {
    path: 'config/mappings/dynamic',
    component: ConfigMappingsDynamicComponent,
    showWorkspaceSelection: true,
    canActivate: [DynamicMappingGuard],
  },
  {
    path: 'config/targets',
    component: ConfigTargetsComponent,
    showWorkspaceSelection: true,
    showPublishConfigSelection: true,
    canActivate: [ConfigGuard],
  },
  {
    path: 'config/samples',
    component: ConfigSamplesEditComponent,
    showWorkspaceSelection: true,
    canActivate: [ConfigGuard],
  },
  // {
  //   path: 'config/:repository/samples/edit',
  //   component: ConfigSamplesEditComponent,
  //   showWorkspaceSelection: true,
  //   canActivate: [ConfigGuard],
  // },
  // {
  //   path: 'config/samples',
  //   component: ConfigListComponent,
  //   // showSourceEnvironmentSelection: true,
  //   showWorkspaceSelection: true,
  //   showDocgenConfigSelection: true,
  //   // showPublishConfigSelection: true,
  //   canActivate: [ConfigGuard],
  // },
  {
    path: 'config/:repository/:type/edit',
    component: ConfigEditComponent,
    showWorkspaceSelection: true,
    canActivate: [ConfigGuard],
  },
  {
    path: 'config/:type',
    component: ConfigListComponent,
    // showSourceEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showDocgenConfigSelection: true,
    // showPublishConfigSelection: true,
    canActivate: [ConfigGuard],
  },
  {
    path: 'product/governance',
    component: ProductGovernanceComponent,
    showTestSuiteSelection: true,
    showDocgenConfigSelection: true,
  },
  {
    path: 'product/governance/:testId',
    component: ProductGovernanceComponent,
    showTestSuiteSelection: true,
    showDocgenConfigSelection: true,
    resolve: {
      testDefinition: TestResolver,
      testHistory: TestHistoryResolver,
    },
  },
  {
    path: 'generate',
    component: DocumentGeneratorComponent,
    // showSourceEnvironmentSelection: true,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showTestSuiteSelection: true,
    showDocgenConfigSelection: true,
    canActivate: [GenerateGuard],
  },
  {
    path: 'generate/:testId',
    component: DocumentGeneratorComponent,
    // showSourceEnvironmentSelection: true,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showTestSuiteSelection: true,
    showDocgenConfigSelection: true,
    resolve: {
      testDefinition: TestResolver,
      testHistory: TestHistoryResolver,
    },
    canActivate: [GenerateGuard],
  },
  {
    path: 'generate/:testId/:version',
    component: DocumentGeneratorComponent,
    // showSourceEnvironmentSelection: true,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showTestSuiteSelection: true,
    showDocgenConfigSelection: true,
    resolve: {
      testDefinition: TestResolver,
      testHistory: TestHistoryResolver,
    },
    canActivate: [GenerateGuard],
  },
  {
    path: 'tests/execution/:executionId/:testId',
    component: DocumentGeneratorComponent,
    // showSourceEnvironmentSelection: true,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showTestSuiteSelection: true,
    showDocgenConfigSelection: true,
    resolve: {
      testDefinition: TestResolver,
      testHistory: TestHistoryResolver,
      testExecution: TestExecutionResolver,
    },
    canActivate: [TestsGuard],
  },
  {
    path: 'tests/cleanup',
    component: TestCleanupComponent,
    canActivate: [TestsGuard],
  },
  {
    path: 'publish',
    component: PublishComponent,
    // showSourceEnvironmentSelection: true,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showPublishConfigSelection: true,
    canActivate: [PublishGuard],
  },
  {
    path: 'publish/:testId',
    component: PublishComponent,
    // showSourceEnvironmentSelection: true,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showPublishConfigSelection: true,
    resolve: {
      testDefinition: TestResolver,
      testHistory: TestHistoryResolver,
    },
    canActivate: [PublishGuard],
  },
  {
    path: 'publish/:executionId/:testId',
    component: PublishComponent,
    // showSourceEnvironmentSelection: true,
    showTargetEnvironmentSelection: true,
    // showWorkspaceSelection: true,
    showPublishConfigSelection: true,
    canActivate: [PublishGuard],
  },
  {
    path: 'inspect',
    component: InspectComponent,
    showTestSuiteSelection: true,
    canActivate: [InspectGuard],
  },
  {
    path: 'tests',
    component: TestSuiteExecutionsComponent,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showTestSuiteSelection: true,
    canActivate: [TestsGuard],
  },
  {
    path: 'suites',
    component: TestSuiteManagerComponent,
    // showTargetEnvironmentSelection: true,
    showTestSuiteSelection: true,
    canActivate: [TestsGuard],
  },
  {
    path: 'migrate',
    component: TestMigratorComponent,
    showTestSuiteSelection: true,
    canActivate: [TestsGuard],
  },
  {
    path: 'clone',
    component: TestSuiteCloneComponent,
    showTestSuiteSelection: true,
    canActivate: [TestsGuard],
  },
  {
    path: 'archive-tests',
    component: DisplayTestArchiveComponent,
    canActivate: [TestsGuard],
  },
  {
    path: 'metrics',
    component: InfrastructureComponent,
    showSourceEnvironmentSelection: true,
  },
  // {
  //   path: 'approvals',
  //   component: ApprovalsComponent,
  // },
  {
    path: 'approvals/docgen',
    component: DocgenConfigApprovalComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'approvals/docgen/details/:env',
    component: DocgenReleaseChangesComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'approvals/templates',
    component: TemplatesApprovalComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'approvals/routing',
    component: PublishConfigApprovalComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/smartdx',
    component: AdminSmartDxComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'tools/jmes',
    component: JmesPathViewComponent,
  },
  {
    path: 'admin/regxchange/post',
    component: AdminRegxchangePostComponent,
    showStormInSourceEnvironment: true,
    showAdminTargetEnvironmentSelection: true,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/regxchange/get',
    component: AdminRegxchangeGetComponent,
    showStormInSourceEnvironment: true,
    showSourceEnvironmentSelection: true,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/mi',
    component: ManagementInformationReportComponent,
    showSourceEnvironmentSelection: true,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/wsd-manual-post',
    component: AdminWsdManualPostComponent,
    showStormInSourceEnvironment: true,
    showAdminTargetEnvironmentSelection: true,
    showDocgenConfigSelection: true,
    canActivate: [AdminGuard],
  },
  {
    path: 'webhook/approve/:service/:sourceSystem/:p1/:p2/:p3/:mnemonic',
    component: ApproveRuleComponent,
    hideSkin: true,
    resolve: {
      // config: ConfigResolver,
      approval: LatestApprovalResolver,
      config: LatestConfigResolver,
    },
  },
  {
    path: 'webhook/approve/:service/:sourceSystem/:p1/:p2/:p3/:mnemonic/:version',
    component: ApproveRuleComponent,
    hideSkin: true,
    resolve: {
      // config: ConfigResolver,
      approval: LatestApprovalResolver,
      config: VersionedConfigResolver,
      devConfig: LatestConfigResolver,
    },
  },
  {
    path: 'manual-drafting/validation',
    component: AdministerValidationRulesComponent,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showTestSuiteSelection: true,
    showDocgenConfigSelection: true,
    canActivate: [ManualDraftingGuard],
  },
  {
    path: 'manual-drafting/output-results',
    component: OutputDisplayComponent,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showTestSuiteSelection: true,
    showDocgenConfigSelection: true,
    canActivate: [ManualDraftingGuard],
  },
  {
    path: 'manual-drafting/payloads',
    component: AdminPayLoadComponent,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showTestSuiteSelection: true,
    showDocgenConfigSelection: true,
    canActivate: [ManualDraftingGuard],
  },
  {
    path: 'manual-drafting/enrich',
    component: AdministerEnrichmentRulesComponent,
    showTargetEnvironmentSelection: true,
    showWorkspaceSelection: true,
    showTestSuiteSelection: true,
    showDocgenConfigSelection: true,
    canActivate: [ManualDraftingGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
