import {DocumentFormat, SourceFormat} from '../common/domain/ingest'
import {DocgenConfigAndMetadata} from '../common/domain/docgen-config'

export class GenerateDocumentRequest {
  payload: string
  productIdentifier: string
  documentFormat: DocumentFormat
  documentLanguage: string
  documentType: string
  productState: string
  productType: string
  productWrapper: string
  programme: string
  sourceFormat: SourceFormat
  sourceSystem: string
  country?: string
  language?: string
  jurisdiction?: string
  ignorePatterns?: string[]
  private config: DocgenConfigAndMetadata

  setConfig(config: DocgenConfigAndMetadata) {
    this.config = config

    if (config && config.metadata) {
      this.sourceSystem = config.metadata.sourceSystem
      this.programme = config.metadata.programme
      this.productType = config.metadata.productType
      this.documentType = config.metadata.documentType
      this.documentLanguage = 'en'
      this.productWrapper = 'Unused'
      this.productState = 'Ignored'
    }
  }
}
