import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {StaleWorkspaceMonitorService} from '../../services/stale-workspace-monitor.service'
import {StatusService} from '../../services/status.service'
import {Subscription} from 'rxjs'
import {ChangeDetails} from '../../common/domain/change'
import {TestManagerService} from '../../services/test-manager.service'
import {Index} from '../../common/domain/static'
import {ChangeService} from '../../services/change.service'
import {ConfigService} from '../../services/config.service'
import {ConfigMonitorService} from '../../services/config-monitor.service'
import {ToastrService} from 'ngx-toastr'

@Component({
  selector: 'app-config-workspace-buttons',
  templateUrl: './config-workspace-buttons.component.html',
  styleUrls: ['./config-workspace-buttons.component.scss'],
})
export class ConfigWorkspaceButtonsComponent implements OnInit, OnDestroy {
  sub = new Subscription()
  staleWorkspace = false
  staleConfig = false

  @Output() onPublish: EventEmitter<ChangeDetails[]> = new EventEmitter()

  constructor(private staleWorkspaceMonitorService: StaleWorkspaceMonitorService,
              private statusService: StatusService,
              private testManagerService: TestManagerService,
              private changeService: ChangeService,
              private configService: ConfigService,
              private configMonitorService: ConfigMonitorService,
              private toast: ToastrService) {
  }

  ngOnInit() {
    this.sub.add(
      this.staleWorkspaceMonitorService.staleWorkspace.subscribe(stale => {
        this.staleWorkspace = stale
        if (stale) {
          this.statusService.start('Workspace is Out of Date', 'ALERT', 30)
        } else {
          this.statusService.clear('Workspace is Out of Date')
        }
      }),
    )
    this.sub.add(
      this.configMonitorService.configStale.subscribe(stale => {
        this.staleConfig = stale
      }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  // CSDOC-541 Capturing here rather than in the static data view to ensure this is triggered regardless of which view is used to puvblish the config.
  published(changes: ChangeDetails[]) {
    this.onPublish.emit(changes)
    // Allow the index tests to be updated if there is a change to either the template or the list of indexes.
    const updatedIndexes = changes.find(c => c.path === 'static/indexes.json')
    const updatedTemplate = changes.find(c => c.path === 'samples/index-disclaimer-test-payload.xml')
    if (updatedIndexes || updatedTemplate) {
      // And make sure we are using the latest version of both.
      const latestIndexes = updatedIndexes || this.changeService.getObject('static/indexes.json')
      const latestTemplate = updatedTemplate || this.changeService.getObject('samples/index-disclaimer-test-payload.xml')
      const indexes = JSON.parse(latestIndexes.content) as Index[]
      const template = latestTemplate.content
      this.testManagerService.generateIndexTestSuite(indexes, template)
        .subscribe(() => {
          this.toast.success('Generated index disclaimer test suite.')
        })
    }
  }

  reload() {
    this.configService.reload$.next()
  }
}
