import {Component, Input, OnInit} from '@angular/core'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'

export enum ConfirmStatus {
  CANCELLED,
  CONFIRMED
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title: string
  @Input() message: string
  @Input() action = 'OK'

  constructor(
    public activeModel: NgbActiveModal,
  ) {
  }

  ngOnInit() {
    (document.getElementsByTagName('ngb-modal-window')[0] as any).style.zIndex = 999999
  }


  cancel() {
    this.activeModel.dismiss(ConfirmStatus.CANCELLED)
  }

  confirm() {
    this.activeModel.close(ConfirmStatus.CONFIRMED)
  }
}
