import {MatDialog} from '@angular/material/dialog'
import saveAs from 'file-saver'
// import * as stringify from 'csv-stringify/lib/sync'
import {combineLatest} from 'rxjs'
import {take} from 'rxjs/operators'
import {AnalyseRuleTable} from 'src/app/common/domain/analyse'
import {ApprovalConfig} from 'src/app/common/domain/approval'
import {ChangeDetails, DocgenConfigAndMetadataChangeDetails} from 'src/app/common/domain/change'
import {DocgenConfig} from 'src/app/common/domain/docgen-config'
import {ChangeService} from 'src/app/services/change.service'
import {Maintain2021RulesetComponent} from 'src/app/views/maintain-2021-ruleset-component/maintain-2021-ruleset-component'
import { unparse } from 'papaparse'
// 100% a Copy/ Paste of the old code
//  I do not like this code, but it appears to produce exactly the same output as the old code does
function asCsvRows(ruleset: AnalyseRuleTable, ruleStage: string, ruleStagePrefix: string, originalTemplate: {fastApprovedBy: string; fastApprovedDate: string; documentType: string; sourceSystem: string; targetMnemonic: string; ruleStage: string; description: string; slowApprovedDate: string; deletedBy: string; help: string; deletedDate: string; approvalRequired: string; ruleType: string; name: string; mnemonic: string; slowApprovedBy: string; programme: string; productType: string; emptyRuleset: string}, approvedMnemonics: any[]) {
    const template = Object.assign({}, originalTemplate)
    template.ruleStage = ruleStage
    template.ruleType = ruleset.ruleType
    template.deletedBy = ruleset.deletion?.user || ''
    template.deletedDate = ruleset.deletion?.timestamp || ''
    template.approvalRequired = ruleset.approvalRequired ? 'YES' : ''
    Array.from(Array(12).keys()).forEach(i => {
        template['field' + i] = ruleset.fields.length > (i - 1) ? ruleset.fields[i] : ''
        template['value' + i] = ''
    })
    let prefix = 'UK'
    if (ruleset.ruleType === 'whitelist') {
        prefix = ruleStagePrefix + 'W'
    } else if (ruleset.ruleType === 'blacklist') {
        prefix = ruleStagePrefix + 'B'
    } else if (ruleset.ruleType === 'notSupported') {
        prefix = ruleStagePrefix + 'U'
    } else if (ruleset.ruleType === 'validation') {
        prefix = ruleStagePrefix + 'V'
    }
    if (ruleset.rule?.length > 0) {
        return ruleset.rule.map(rule => {
            const mnemonic = prefix + '-' + ruleset.mnemonic + '-' + rule.mnemonic
            const approval = approvedMnemonics.find(a => a.mnemonic === mnemonic)
            const row = Object.assign({}, template, {
                name: ruleset.name || '',
                description: ruleset.description || '',
                help: ruleset.help || '',
                mnemonic: mnemonic,
                deletedBy: rule.deletion?.user || ruleset.deletion?.user || '',
                deletedDate: rule.deletion?.timestamp || ruleset.deletion?.timestamp || '',
                slowApprovedBy: rule.approved?.user || '',
                slowApprovedDate: rule.approved?.timestamp || '',
                fastApprovedBy: approval?.approvedBy || '',
                fastApprovedDate: approval?.approvedDate || '',
            })
            Array.from(Array(12).keys()).forEach(i => {
                row['value' + i] = rule.expected.length > (i - 1) ? rule.expected[i] : ''
            })

            return row
        })
    } else {
        return [Object.assign({}, template, {
            name: ruleset.name || '',
            description: ruleset.description || '',
            help: ruleset.help || '',
            mnemonic: prefix + '-' + ruleset.mnemonic,
            emptyRuleset: 'YES'
        })]
    }
}

export class UserActionDownloadsNewFormat {

    constructor(
        private dialog: MatDialog,
        private _changeService: ChangeService,
        private parentComponent: Maintain2021RulesetComponent,
    ) {
    }

    // 100% a Copy/ Paste of the old code
    //  I do not like this code, but it appears to produce exactly the same output as the old code does
    public downloadAllRulesNewFormat() {

        combineLatest([
            this._changeService.getObjectsOfType('approvals')
                .pipe(
                    take(1),
                ),
            this._changeService.getAllDocgenConfigs()
                .pipe(
                    take(1),
                ),
        ]).subscribe(([approvals, configs]: [ChangeDetails[], DocgenConfigAndMetadataChangeDetails[]]) => {
            const csvData = configs.reduce((allRows, config) => {
                const configObject = JSON.parse(config.settings.content) as DocgenConfig
                const parts = config.settings.path.split('/')
                const sourceSystem = parts[1]
                const programme = parts[2]
                const productType = parts[3]
                const documentType = parts[4]

                const approvalData = approvals.find(a => a.path === `approvals/${sourceSystem}/${programme}/${productType}/${documentType}/Approvals.json`)
                let approvedMnemonics = []
                if (approvalData) {
                    const approvalConfig = JSON.parse(approvalData.content) as ApprovalConfig
                    approvedMnemonics = approvalConfig.approvedMnemonics
                }

                const template = {
                    sourceSystem: sourceSystem,
                    programme: programme,
                    productType: productType,
                    documentType: documentType,
                    name: '',
                    description: '',
                    help: '',
                    ruleStage: '',
                    ruleType: '',
                    mnemonic: '',
                    targetMnemonic: '',
                    emptyRuleset: '',
                    approvalRequired: '',
                    fastApprovedBy: '',
                    fastApprovedDate: '',
                    slowApprovedBy: '',
                    slowApprovedDate: '',
                    deletedBy: '',
                    deletedDate: '',
                }
                Array.from(Array(12).keys()).forEach(i => {
                    template['field' + i] = ''
                    template['value' + i] = ''
                })
                const analysePayloadRows = configObject.analysePayload.rules.reduce((acc, ruleset) => {
                    const csvRows = asCsvRows(ruleset, 'Payload', 'P', template, approvedMnemonics)
                    return acc.concat(...csvRows)
                }, [])
                const analyseEnrichedRows = configObject.analyseEnriched.rules.reduce((acc, ruleset) => {
                    const csvRows = asCsvRows(ruleset, 'Solo', 'S', template, approvedMnemonics)
                    return acc.concat(...csvRows)
                }, [])
                return allRows.concat(...analysePayloadRows).concat(...analyseEnrichedRows)
            }, [])

            const allCsv = unparse(csvData, {
                header: true,
            })

            const blob = new Blob([allCsv], {type: 'text/csv;charset=utf-8'})
            saveAs(blob, `all-rules-${new Date().toISOString()}.csv`)
        })
    }

}