import {Component, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core'
import {TreeviewConfig, TreeviewItem} from 'ngx-treeview'
import {DocgenConfigMetadata} from '../../common/domain/docgen-config'
import {BehaviorSubject} from 'rxjs'
import deepEqual from 'deep-equal'

@Component({
  selector: 'app-configs-selector',
  templateUrl: './configs-selector.component.html',
  styleUrls: ['./configs-selector.component.scss'],
})
export class ConfigsSelectorComponent implements OnInit, OnChanges {
  config = TreeviewConfig.create({
    hasAllCheckBox: false,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 1000,
  })

  items: TreeviewItem[] = []
  l1: TreeviewItem[] = []
  l2: TreeviewItem[] = []
  l3: TreeviewItem[] = []
  leafs: TreeviewItem[] = []
  values: number[] = []

  @Input() configs: DocgenConfigMetadata[] = []
  @Input() preselected: DocgenConfigMetadata[] = []
  @Output() selectedConfigs = new BehaviorSubject<DocgenConfigMetadata[]>([])

  constructor() {
  }

  ngOnInit(): void {
    this.selectedConfigs.subscribe(c => {
      console.log('selected')
      console.dir(c)
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['configs']) {
      this.items = this.configs.reduce((items: TreeviewItem[], config: DocgenConfigMetadata) => {
        let sourceSystemItem: TreeviewItem = items.find(v => v.value === config.sourceSystem)
        if (!sourceSystemItem) {
          sourceSystemItem = new TreeviewItem({
            text: config.sourceSystem,
            value: config.sourceSystem,
            checked: false,
          })
          items.push(sourceSystemItem)
          this.l1.push(sourceSystemItem)
        }

        let programmeItem: TreeviewItem = sourceSystemItem.children?.find(v => v.value === config.programme)
        if (!programmeItem) {
          programmeItem = new TreeviewItem({
            children: [],
            text: config.programme,
            value: config.programme,
            checked: true,
          })
          if (!sourceSystemItem.children) {
            sourceSystemItem.children = [programmeItem]
          } else {
            sourceSystemItem.children.push(programmeItem)
          }
          this.l2.push(programmeItem)
        }

        let productTypeItem: TreeviewItem = programmeItem.children?.find(v => v.value === config.productType)
        if (!productTypeItem) {
          productTypeItem = new TreeviewItem({
            children: [],
            text: config.productType,
            value: config.productType,
            checked: false,
            collapsed: true,
          })
          if (!programmeItem.children) {
            programmeItem.children = [productTypeItem]
          } else {
            programmeItem.children.push(productTypeItem)
          }
          this.l3.push(productTypeItem)
        }


        let documentTypeItem: TreeviewItem = productTypeItem.children?.find(v => v.value === config.documentType)
        if (!documentTypeItem) {
          documentTypeItem = new TreeviewItem({
            children: [],
            text: config.documentType,
            value: config,
            checked: false,
          })
          if (!productTypeItem.children) {
            productTypeItem.children = [documentTypeItem]
          } else {
            productTypeItem.children.push(documentTypeItem)
          }
          this.leafs.push(documentTypeItem)
        }

        return items
      }, [])
    }
    this.leafs.forEach(leaf => {
      leaf.checked = this.preselected.find(c => deepEqual(c, leaf.value)) !== undefined
      leaf.correctChecked()
    })
    this.l3.forEach(node => {
      node.correctChecked()
      node.collapsed = this.leafs.find(leaf => leaf.checked && leaf.value['productType'] === node.value) === undefined
    })
    this.l2.forEach(node => {
      node.correctChecked()
      node.collapsed = this.leafs.find(leaf => leaf.checked && leaf.value['programme'] === node.value) === undefined
    })
    this.l1.forEach(node => {
      node.correctChecked()
      node.collapsed = this.leafs.find(leaf => leaf.checked && leaf.value['sourceSystem'] === node.value) === undefined
    })
  }

  selected(c: DocgenConfigMetadata[]) {
    this.selectedConfigs.next(c)
  }
}
