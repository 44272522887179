<form [formGroup]="enrichRuleForm" (ngSubmit)="submit()">
  <!-- input fields section -->
  <fieldset class="form-group">
    <div class="formg-group row">
      <label for="code" class="col-md-3 col-form-label">Code:</label>
      <div class="col-md-4">
        <input
        type="text"
        formControlName="code"
        id="code"
        class="form-control form-control-sm"
        disabled
        placeholder="Unique Reference"
      />
      </div>
      
    </div>

    <div class="formg-group row">
      <label for="description" class="col-md-3 col-form-label"
        >Description:</label
      >
      <div class="col-md-8">
        <input
        type="text"
        formControlName="description"
        id="description"
        class="form-control form-control-sm"
        maxlength="200"/>
      </div>
    </div>

    <div class="formg-group row">
      <label for="jsonPath" class="col-md-3 col-form-label"
        >Inbound Json Path:</label
      >
      <div class="col-md-8">
        <input
        type="text"
        formControlName="inboundJsonPath"
        id="jsonPath"
        maxlength="500"
        class="form-control form-control-sm"/>
      </div>
    </div>

    <div class="formg-group row">
      <label for="soloXml" class="col-md-3 col-form-label pr-0"
        >SOLO XML to SmartDx:</label
      >
      <div class="col-md-8">
        <input
        type="text"
        formControlName="soloXmlToSmartDx"
        id="soloXml"
        maxlength="500"
        class="form-control form-control-sm"/>
      </div>
    </div>
  </fieldset>
  <div class="form-row justify-content-end">
    <button class="btn btn-secondary me-2" (click)="modal.close()">
      Close
    </button>

    <button
      class="btn btn-primary"
      type="submit"
      [disabled]="!enrichRuleForm.valid"
    >
      Save
    </button>
  </div>
</form>
