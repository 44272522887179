import {Component, Inject} from '@angular/core'
import {UntypedFormControl} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

export interface AnalysisCreateConfigDetails {
  title: string
  notes: string
  sourceSystem: string
  programme: string
  productType: string
  documentType: string
  sourceSystems: string[]
  programmes: string[]
  productTypes: string[]
  documentTypes: string[]
}

@Component({
  templateUrl: 'analysis-create-config-dialog.component.html',
  styleUrls: ['./analysis-create-config-dialog.component.scss'],
})
export class AnalysisCreateConfigDialogComponent {

  myControl = new UntypedFormControl();

  sourceSystem = 'sparrow'
  programme: string
  productType: string
  documentType: string

  constructor(public dialogRef: MatDialogRef<AnalysisCreateConfigDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: AnalysisCreateConfigDetails) {
    data.sourceSystems = data.sourceSystems || ['sparrow', 'elf', 'secnet', 'fimble', 'test']
  }

  cancel() {
    this.dialogRef.close(undefined)
  }

  ok() {
    this.data.sourceSystem = this.sourceSystem
    this.data.programme = this.programme
    this.data.productType = this.productType
    this.data.documentType = this.documentType
    this.dialogRef.close(this.data)
  }


}
