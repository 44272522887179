import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core'
import {MatSelectChange} from '@angular/material/select'
import {Subscription} from 'rxjs'
import {TestSuiteDefinition, TestSuiteMetadata} from '../common/domain/tests'
import {TestManagerService} from '../services/test-manager.service'
import { saveAs } from 'file-saver';
//const csvStringify = require('csv-stringify/lib/sync')
import { unparse } from 'papaparse'
@Component({
  selector: 'app-test-suite-selector',
  templateUrl: './test-suite-selector.component.html',
  styleUrls: ['./test-suite-selector.component.scss'],
})
export class TestSuiteSelectorComponent implements OnInit, OnDestroy {

  constructor(private testManagerService: TestManagerService) {
  }
  subs = new Subscription()

  selectedTestSuite: string
  @Input() selectedSuite = ''

  @Output() testSuite = new EventEmitter<TestSuiteDefinition>()
  @Input() displayProdClone = true

  testSuites: TestSuiteMetadata[]
  includeProdClone: boolean

  private static _downloadCsv(csvData: CsvData[], fileNamePrefix: string) {
    const csv = unparse(csvData, {
      header: true,
    })

    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
    const filename = (fileNamePrefix + new Date().toISOString() + '.csv')
    console.log('Saving: ' + filename)
    saveAs(blob, filename)
  }

  ngOnInit() {
    this.subs.add(
      this.testManagerService.includeProdClone.subscribe(value => this.includeProdClone = value),
    )
    this.subs.add(
      this.testManagerService.testSuites.subscribe(results => {
        this.testSuites = results
      }),
    )
    this.subs.add(
      this.testSuite.subscribe(value => {
        this.selectedTestSuite = value && value.name
      }),
    )
    this.subs.add(
      this.testManagerService.selectedTestSuite.subscribe(result => {
        if (result) {
          this.testSuite.emit({
            name: result.name,
            tests: result.tests.map(test => test.name),
          })
        } else {
          this.testSuite.emit(undefined)
        }
      }),
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  selectTestSuite($event: MatSelectChange) {
    this.testManagerService.selectTestSuite($event.value)
  }

  toggleIncludeProdClone(checked: boolean) {
    this.testManagerService.enableProdClone(checked)
  }

  public actionDownloadTestSummaryCsv() {
    // console.info('actionDownloadTestSummaryCsv()')
    console.info(JSON.stringify(this.testSuites)) // testSuiteMetadata))

    const csvData = new Array<CsvData>()

    this.testSuites.forEach((curTestSuite: TestSuiteMetadata) => {
      const numberOfTests = (curTestSuite as any).tests.length
      csvData.push(new CsvData(curTestSuite.name, numberOfTests, curTestSuite.username, curTestSuite.email))
    })

    TestSuiteSelectorComponent._downloadCsv(csvData, 'test-suite-summary-')
  }

  public actionDownloadTestDetailsCsv() {
    // console.info('actionDownloadTestDetailsCsv()')

    const csvData = new Array<CsvData>()

    this.testSuites.forEach((curTestSuite: TestSuiteMetadata) => {
      (curTestSuite as any).tests.forEach((curTestName: string) => {
        csvData.push(new CsvData(curTestSuite.name, -1, curTestSuite.username, curTestSuite.email, curTestName))
      })
    })

    TestSuiteSelectorComponent._downloadCsv(csvData, 'test-suite-details-')
  }

}

class CsvData {
  public Test_Suite_Name: string
  public Test_Count: number | undefined
  public Username: string
  public Email: string
  public Test_Name: string | undefined

  constructor(testSuiteName: string, count: number = -1, username: string, email: string, testName?: string) {
    this.Test_Suite_Name = testSuiteName
    // We may with to leave Test_Count as undefined so it's not saved
    if (count > -1)
      this.Test_Count = count
    this.Username = username
    this.Email = email
    // We may with to leave Test_Name as undefined so it's not saved
    if (!!testName) {
      this.Test_Name = testName
    }
  }
}
