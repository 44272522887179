import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from './api.service';
import { PermissionsService } from './permissions.service';
import { ToastrService } from 'ngx-toastr';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Environment, EnvironmentService, Region } from './environment-service';
import { DocAutoParams, PriipCloudParams, WsdServiceType, WallStreetDocsPostRequest, DocumentFormat } from '../common/domain/wallstreetdocs';
import xmlParser from 'fast-xml-parser';
import { ChangeService } from './change.service';
import { Subscription, of } from 'rxjs';
import { DocgenConfigAndMetadataChangeDetails } from '../common/domain/change';

@Injectable({
	providedIn: 'root'
})

export class WsdAdminPostService implements OnInit, OnDestroy {
  sub = new Subscription();

	constructor(private apiService: ApiService,
		private toast: ToastrService) {}

  ngOnInit(): void {}

	postPayloadToWallStreetDocs(
    payload: string,
    serviceType: WsdServiceType,
    customParams: PriipCloudParams | DocAutoParams,
    selectedConfig: DocgenConfigAndMetadataChangeDetails,
    environment: Environment,
    region: Region,
    format: DocumentFormat
    ) {
    if(!payload) {
      this.toast.error('A payload is mandatory');
			return of({});
    }
    
		if (!(xmlParser.validate(payload) === true)) {
      this.toast.error('Error parsing XML payload. Please verify the payload.');
      return of({});
		}

    const postRequest: WallStreetDocsPostRequest = {
      payload: {
        content: payload,
        type: 'application/xml'
      },
      action: 'wallStreetDocsPostRequest',
      service: serviceType,
      configKey: selectedConfig.settings.path.replace('/Config.json', '').replace('settings/', '') || '',
      docAutoParams: customParams as DocAutoParams,
      targetEnvironment: environment.id,
      region: region.id,
      documentFormat: format
    }

    const url = '/tests/';
    this.toast.info('Posting to Wall Street Docs in ' + environment.id + ' environment');

    try {
      const response = this.apiService.post<WallStreetDocsPostRequest, any>(url, postRequest, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
      }).pipe(
        tap(res => {
          console.dir(res)
        }),
        map(res => {
          const resObject = (typeof res === 'string') ? JSON.parse(res) : res
          return resObject['body'] ? resObject['body'] : resObject
        }),
      )
      return response;
    } catch (error) {
      return of(error);
    }
	}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}