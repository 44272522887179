<div class="modal-header">
  <h4 class="modal-title">Ignore Patterns</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-row">
    <div class="col-6">RegEx Pattern</div>
    <div class="col-4">
      Flags
      <button
        type="button"
        class="btn help-text"
        [ngbPopover]="popContent"
        triggers="mouseenter:mouseleave"
        popoverTitle="Flags Help"
        container="body"
      >
        i</button
      ><ng-template #popContent>
        <div class="regexFlags">
          <div class="flag">
            <strong>g</strong>lobal
            <div>Don't return after first match</div>
          </div>

          <div class="flag">
            <strong>m</strong>ulti line
            <div>^ and $ match start/end of line</div>
          </div>
          <div class="flag">
            <strong>i</strong>nsensitive
            <div>Case insensitive match</div>
          </div>
          <div class="flag">
            e<strong>x</strong>tended
            <div>Ignore whitespace</div>
          </div>
          <div class="flag">
            <strong>s</strong>ingle line
            <div>Dot matches newline</div>
          </div>
          <div class="flag">
            <strong>u</strong>nicode
            <div>Match with full unicode</div>
          </div>
          <div class="flag">
            <strong>U</strong>ngreedy
            <div>Make quantifiers lazy</div>
          </div>
          <div class="flag">
            <strong>A</strong>nchored
            <div>
              Anchor to start of pattern, or at the end of the most recent match
            </div>
          </div>
          <div class="flag">
            <strong>J</strong>changed
            <div>Allow duplicate subpattern names</div>
          </div>
          <div class="flag">
            <strong>D</strong>ollar end only
            <div>$ matches only end of pattern</div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="col-2"></div>
  </div>
  <form class="add-lessons-form" [formGroup]="form">
    <ng-container formArrayName="ignorePatterns">
      <ng-container
        *ngFor="let ignorePattern of ignorePatterns.controls; let i = index"
      >
        <div class="lesson-form-row form-row mb-2" [formGroupName]="i">
          <div class="col-6">
            <input
              type="text"
              class="form-control"
              formControlName="regexPattern"
              placeholder="Ignore Pattern"
            />
            <div class="invalid-feedback" [class.d-block]="form.get('ignorePatterns')['controls'][i]['controls']['regexPattern'].errors?.regex">{{  form.get('ignorePatterns')['controls'][i]['controls']['regexPattern'].errors
            ?.message }}</div>
          </div>
          <div class="col-4">
            <input
              type="text"
              class="form-control"
              formControlName="flags"
              placeholder="Ignore Flags"
            />
          </div>
          <div class="col-2">
            <button
              type="button"
              class="btn btn-danger"
              (click)="deleteRegEx(i)"
            >
              Remove
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <button type="button" class="btn btn-primary" (click)="addRegEx()">
      Add Ignore Pattern
    </button>
  </form>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    (click)="savePayload()"
    [disabled]="form.invalid"
  >
    OK
  </button>
  <button type="button" class="btn btn-secondary" (click)="cancel()">
    Cancel
  </button>
</div>
