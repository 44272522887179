<app-config-workspace-buttons></app-config-workspace-buttons>

<h2>Indexes</h2>

<ng-container>
  <button mat-raised-button color="primary" (click)="addItem()" class="me-2">Add</button>
  <button mat-raised-button color="accent" (click)="downloadIndexes()">Download CSV</button>
</ng-container>

<table class="table table-borderless">
  <thead>
  <tr>
    <th colspan="2"></th>
    <th colspan="2">English</th>
    <th colspan="2">German</th>
    <th colspan="2">French</th>

    <th></th>
  </tr>
  <tr>
    <th class="static-code">Code</th>
    <th class="static-name">Name</th>
    <th class="static-checkbox">Disclaimer</th>
    <th class="static-checkbox">Description</th>
    <th class="static-checkbox">Disclaimer</th>
    <th class="static-checkbox">Description</th>
    <th class="static-checkbox">Disclaimer</th>
    <th class="static-checkbox">Description</th>
    <th class="static-action"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let item of indexes">
    <td>
      <input matInput type="text" (blur)="saveChanges()" [(ngModel)]="item.code">
    </td>
    <td>
      <input matInput type="text" (blur)="saveChanges()" [(ngModel)]="item.name">
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="item.enDisclaimer"></mat-checkbox>
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="item.enDescription"></mat-checkbox>
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="item.deDisclaimer"></mat-checkbox>
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="item.deDescription"></mat-checkbox>
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="item.frDisclaimer"></mat-checkbox>
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="item.frDescription"></mat-checkbox>
    </td>
    <td>
      <button mat-raised-button color="warn" (click)="deleteItem(item.code)">DELETE</button>
    </td>
  </tr>
  <tr *ngIf="newIndex">
    <td>
      <input matInput type="text" (blur)="addItem()" [(ngModel)]="newIndex.code">
    </td>
    <td>
      <input matInput type="text" (blur)="addItem()" [(ngModel)]="newIndex.name">
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="newIndex.enDisclaimer"></mat-checkbox>
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="newIndex.enDescription"></mat-checkbox>
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="newIndex.deDisclaimer"></mat-checkbox>
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="newIndex.deDescription"></mat-checkbox>
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()"[(ngModel)]="newIndex.frDisclaimer"></mat-checkbox>
    </td>
    <td>
      <mat-checkbox (change)="saveChanges()" [(ngModel)]="newIndex.frDescription"></mat-checkbox>
    </td>
    <td></td>
  </tr>
  </tbody>
</table>
