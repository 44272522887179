import {Component, OnDestroy, OnInit} from '@angular/core'
import {ConfigDetail} from '../../common/domain/config'
import {saveAs} from 'file-saver'
import {ChangeService} from '../../services/change.service'
// import {stringify} from 'csv/lib/sync'
import {combineLatest, of, Subscription} from 'rxjs'
import {ChangeDetails, ChangedState} from '../../common/domain/change'
import {PermissionsService} from '../../services/permissions.service'
import {DocgenConfig, DocgenConfigAndMetadata, DocgenConfigKeyIndex} from '../../common/domain/docgen-config'
import {distinctUntilChanged, switchMap, take} from 'rxjs/operators'
import {AnalysisCreateConfigDetails, AnalysisCreateConfigDialogComponent} from '../../dialogs/analysis-create-config-dialog.component'
import {ToastrService} from 'ngx-toastr'
import {MatDialog} from '@angular/material/dialog'
import deepEqual from 'deep-equal'
import { EditChartOptionsDialogComponent, EditChartOptionsDetails } from 'src/app/dialogs/test-suite-group-edit-dialog/edit-chart-options-dialog/edit-chart-options-dialog.component'
import { WorkspaceAutoSaveService } from 'src/app/services/workspace-auto-save.service'
import { unparse } from 'papaparse'
interface TemplateMapping {
  state: ChangedState
  configDetail: ConfigDetail
  sourceSystem: string
  programme: string
  productType: string
  documentType: string
  sparrowSchema: string
  soloSchema: string
  transform1: string
  transform2: string
  enrich1: string
  enrich2: string
  projectId: string
  templateSelectorId: string
  wordTemplateSelectorId: string
  renderService?: string
  maxPageSize: number
  supported: 'NO' | 'MIGRATING' | 'UAT' | 'YES'
}

@Component({
  selector: 'app-template-map',
  templateUrl: './config-mapping.component.html',
  styleUrls: ['./config-mapping.component.scss'],
})
export class ConfigMappingComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  supportedConfigMode = ['NO', 'MIGRATING', 'UAT', 'YES']
  supportedRenderServices = ['PRIIP_CLOUD', 'SMART_DX', 'DOC_AUTO', 'WSD_WORKFLOW']
  public templateMappings: TemplateMapping[] // used by HTML
  selectedConfig: DocgenConfigAndMetadata
  configs: DocgenConfigAndMetadata[]

  canCreateConfig = false
  canEditMappings = false
  canEditTemplates = false
  canEditSupportedStatus = false

  schemas: string[] = []
  transforms: string[] = []

  constructor(
    // Injected Services:
    // private statusService: StatusService,
    private changeService: ChangeService,
    private permissionsService: PermissionsService,
    private toast: ToastrService,
    private dialog: MatDialog,
    private workspaceAutoSaveService: WorkspaceAutoSaveService
  ) {
  }

  identify(index: number, item: TemplateMapping) {
    return `${item.sourceSystem}/${item.programme}/${item.productType}/${item.documentType}`
  }

  hasChartEnabled(item: TemplateMapping): boolean {
    const content = JSON.parse(item.configDetail.content);
    if(!content.chartSetupDetails || !content.chartSetupDetails?.enabled){
      return false
    }

    return true
  }

  ngOnInit() {

    const changeServiceSettingsConfigs$ = this.changeService.getObjectsOfType('settings')
      .pipe(
        switchMap((configs: ChangeDetails[]) =>  {
          return of(configs.filter(config => config.source === 'lambda-ingest-product'))
        }),
        distinctUntilChanged(deepEqual)
      )

    const configServiceDocgenConfigs$ = this.changeService.getUserFilteredDocgenConfigs()
      .pipe(distinctUntilChanged(deepEqual))

    // xyzzy Mark says to look line 203 in approvals.component.ts
    // i.e. use takeUntil(this.onDestroy$) technique
    // Unsubscribe when component is destroyed
    // this.sub.add(changeServiceSettingsConfigs$.subscribe())
    // this.sub.add(configServiceDocgenConfigs$)

    // Wait on the two Observables
    combineLatest([changeServiceSettingsConfigs$, configServiceDocgenConfigs$]).subscribe(
      ([changeServiceSettingsConfigs, userPreferences]) => {

        // console.log('Changes')
        // console.dir(changeServiceSettingsConfigs)
        // console.dir(userPreferences)

        // Do not continue until both observables have returned data
        if (changeServiceSettingsConfigs.length === 0 || userPreferences.length === 0) {
          return
        }

        // Convert ChangeDetails[] to TemplateMapping[]
        const templateMappings = changeServiceSettingsConfigs.map(
          configDetail => {

            // Get Values
            const config = JSON.parse(configDetail.content) as DocgenConfig
            const match = configDetail.path.match(/^.*\/([^\/]+)\/([^\/]+)\/([^\/]+)\/([^\/]+)\/Config\.json$/)
            const sourceSystem = match[DocgenConfigKeyIndex.SOURCE_SYSTEM]
            const programme = match[DocgenConfigKeyIndex.PROGRAMME]
            const productType = match[DocgenConfigKeyIndex.PRODUCT_TYPE]
            const documentType = match[DocgenConfigKeyIndex.DOCUMENT_TYPE]

            // Create a TemplateMapping (used by the html to render this screen)
            const newTemplateMapping: TemplateMapping = {
              configDetail: configDetail,
              state: configDetail.state,
              sourceSystem: sourceSystem,
              programme: programme,
              productType: productType,
              documentType: documentType,
              sparrowSchema: (config.validatePayload.schema && config.validatePayload.schema.replace('schemas/', '')) || '',
              soloSchema: (config.validateEnriched.schema && config.validateEnriched.schema.replace('schemas/', '')) || '',
              transform1: (config.transform.transforms && config.transform.transforms.length >= 1 && (config.transform.transforms[0].replace('transforms/', ''))) || '',
              transform2: (config.transform.transforms && config.transform.transforms.length >= 2 && (config.transform.transforms[1].replace('transforms/', ''))) || '',
              enrich1: (config.enrich.transforms && config.enrich.transforms.length >= 1 && (config.enrich.transforms[0].replace('transforms/', ''))) || '',
              enrich2: (config.enrich.transforms && config.enrich.transforms.length >= 2 && (config.enrich.transforms[1].replace('transforms/', ''))) || '',
              projectId: config.renderDocument.templateProjectId,
              templateSelectorId: config.renderDocument.templateResourceSelector,
              wordTemplateSelectorId: config.renderDocument.wordTemplateResourceSelector || '',
              renderService: config.renderDocument.service || '',
              supported: config.ingest.supported,
              maxPageSize: config.maxPageSize || 0,
            }

            return newTemplateMapping
          })

        /// Filter to what was selected in Config Filter
        this.templateMappings = templateMappings.filter(a => userPreferences.find((b) => {
          return (
            a.sourceSystem === b.metadata.sourceSystem &&
            a.programme === b.metadata.programme &&
            a.productType === b.metadata.productType &&
            a.documentType === b.metadata.documentType
          )
        }))
      })

    this.sub.add(
      this.changeService.selectedDocgenConfig.subscribe(config => {
        this.selectedConfig = config
      }),
    )

    this.sub.add(
      this.changeService.getObjectsOfType('schemas')
        .pipe(
          switchMap((configs: ChangeDetails[]) => of(configs.filter(config => config.source === 'lambda-ingest-product'))),
        )
        .subscribe(schemas => {
          this.schemas = [''].concat(schemas
            // .filter(s => s.name.endsWith('.xsd'))
            .map(schema => {
              return schema.name
            }),
          )
        }),
    )

    this.sub.add(
      this.changeService.getObjectsOfType('transforms')
        .pipe(
          switchMap((configs: ChangeDetails[]) => of(configs.filter(config => config.source === 'lambda-ingest-product'))),
        )
        .subscribe(transforms => {
          this.transforms = [''].concat(transforms
            // .filter(t => t.name.endsWith('.xslt'))
            .map(transform => {
              return transform.name
            }),
          )
        }),
    )

    this.permissionsService.editTemplates.subscribe((permission: boolean) => this.canEditTemplates = permission)
    this.permissionsService.editSupportedStatus.subscribe((permission: boolean) => this.canEditSupportedStatus = permission)
    this.permissionsService.editMappings.subscribe((permission: boolean) => this.canEditMappings = permission)
    this.permissionsService.createConfig.subscribe((permission: boolean) => this.canCreateConfig = permission)
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  validChartInfo(chartInfo: any): boolean {
    if(chartInfo.smartDXXMLContentPath === undefined || typeof(chartInfo.smartDXXMLContentPath) !== "string") {
      //console.error("smartDXXMLContentPath wrong")
      return false
    }

    if(!chartInfo.chartDataJSONPath === undefined || typeof(chartInfo.chartDataJSONPath) !== "string") {
      //console.error("chartDataJSONPath wrong")
      return false
    }

    if(!chartInfo.style === undefined || (typeof(chartInfo.style) !== "string" && chartInfo.style !== null)) {
      //console.error("Style wrong")
      return false
    }

    return true
  }

  validChartMetadata(rawChartMetaData: any): boolean {
    // console.log({rawChartMetaData})
    if(Array.isArray(rawChartMetaData)) {
      for(const chartInfo of rawChartMetaData){
        if(!this.validChartInfo(chartInfo)) return false
      }

      return true
    }

    if(!this.validChartInfo(rawChartMetaData)) return false

    return true
  }

  downloadTemplateMapping() {
    const csvData = this.templateMappings.filter((x: TemplateMapping) => x.sourceSystem.toLowerCase() !== 'development')
      .map(x => {
        const result = Object.assign({}, x)
        delete result['configDetail']
        return result
      })

    const foo = unparse(csvData, {
      header: true,
    })

    const blob = new Blob([foo], {type: 'text/csv;charset=utf-8'})
    saveAs(blob, 'templateMappings.csv')
  }

  editChartConfig(item: TemplateMapping) {
    const openConfig = JSON.parse(item.configDetail.content)
    let useChartRendering = openConfig?.chartSetupDetails?.enabled ?? false
    let useLegacyMode = openConfig?.chartSetupDetails?.legacy ?? false
    let chartMetadata = openConfig?.chartSetupDetails?.chartMetadata ?? []

    const createConfigKeyDialog = this.dialog.open(EditChartOptionsDialogComponent, {
      width: '93%',
      data: {
        configMap: item.configDetail.path,
        useChartRendering,
        useLegacyMode,
        chartMetadata
      } as EditChartOptionsDetails,
      panelClass: 'mat-dialog-without-padding',
    })

    createConfigKeyDialog.afterClosed().subscribe(async (data: EditChartOptionsDetails) => {
      if (data) {
        // console.log({data})
        if (data.useChartRendering === undefined || !data.chartMetadata || data.useLegacyMode === undefined) {
          this.toast.error(`All configuration key elements must be supplied`)
          return
        }
        const key = `settings/${item.sourceSystem}/${item.programme}/${item.productType}/${item.documentType}/Config.json`
        const originalObject = this.changeService.getObject(key);

        let config = JSON.parse(originalObject.content);
        if(!config.chartSetupDetails){
          config.chartSetupDetails = {
            enabled: false,
            legacy: false,
            chartMetadata: null
          }
        }

        try {
          if(!this.validChartMetadata(data.chartMetadata)){
            throw "Invalid metadata settings. Please use the correct schema format."
          }

          config.chartSetupDetails.enabled = data.useChartRendering
          config.chartSetupDetails.legacy = data.useLegacyMode
          config.chartSetupDetails.chartMetadata = data.chartMetadata

          this.saveChangedConfig('Config.json', key, config)
        } catch(error) {
          this.toast.error(error)
          return
        }

      }
    })
  }

  updateItem(item: TemplateMapping) {
    // console.log('Update: ')
    // console.dir(item)
    const updated = JSON.parse(item.configDetail.content) as DocgenConfig
    updated.ingest.supported = item.supported
    updated.renderDocument.templateProjectId = item.projectId
    updated.renderDocument.templateResourceSelector = item.templateSelectorId
    updated.maxPageSize = item.maxPageSize
    updated.renderDocument.wordTemplateResourceSelector = item.wordTemplateSelectorId !== '' ? item.wordTemplateSelectorId : undefined
    updated.renderDocument.service = item.renderService !== ''? item.renderService : undefined
    updated.validatePayload.schema = item.sparrowSchema === '' ? '' : 'schemas/' + item.sparrowSchema
    updated.validateEnriched.schema = item.soloSchema === '' ? '' : 'schemas/' + item.soloSchema
    const transforms = []
    if(item.transform1 !== '') {
      transforms.push('transforms/' + item.transform1)
    }
    if(item.transform2 !== '') {
      transforms.push('transforms/' + item.transform2)
    }
    updated.transform.transforms = transforms
    const enrichTransforms = []
    if (item.enrich1 !== '') {
      enrichTransforms.push('transforms/' + item.enrich1)
    }
    if (item.enrich2 !== '') {
      enrichTransforms.push('transforms/' + item.enrich2)
    }
    updated.enrich.transforms = enrichTransforms

    updated.validatePayload.skip = item.sparrowSchema === ''
    updated.validateEnriched.skip = item.soloSchema === ''
    updated.transform.skip = updated.transform.transforms.length === 0
    updated.enrich.skip = updated.enrich.transforms.length === 0

    const configString = JSON.stringify(JSON.parse(JSON.stringify(updated, undefined, 2)) as DocgenConfig, undefined, 2)

    this.changeService.addChange(Object.assign({},
      item.configDetail,
      {
        hash: undefined,
        size: undefined,
        content: configString,
      },
    ))
  }

  deleteItem(item: TemplateMapping) {
    console.log('Deleting: ')
    console.dir(item)
    const config = JSON.parse(item.configDetail.content)

    config.deleted = true;

    const key = `settings/${item.sourceSystem}/${item.programme}/${item.productType}/${item.documentType}/Config.json`
    this.toast.info(`Deleting "${key}"...`);
    this.saveChangedConfig('Config.json', key, config)
  }

  isItemDeleted(item: TemplateMapping) {
    const content = JSON.parse(item.configDetail.content);
    return !!content.deleted
  }

  // isAvailableToConfig(item: TemplateMapping) {
  //   if (this.configs && this.configs.length > 0) {
  //     return this.configs.some(c => c.settings.path === item.configDetail.path)
  //   }
  //   return true
  // }

  // isReferencedByConfig(item: TemplateMapping) {
  //   return this.selectedConfig && this.selectedConfig.settings.path === item.configDetail.path
  // }

  restore(item: TemplateMapping) {
    this.changeService.revertChange(item.configDetail)
  }

  createConfig(): void {
    this.changeService.getObjectsOfType('settings')
      .pipe(
        take(1),
        switchMap((configs: ChangeDetails[]) => of(configs.filter(config => config.source === 'lambda-ingest-product'))),
      )
      .subscribe(configs => {
        const programmes: string[] = []
        const productTypes: string[] = []
        const documentTypes: string[] = []
        const sourceSystems: string[] = []

        configs
          .forEach(config => {
            const match = config.path.match(/^settings\/(.+)\/(.+)\/(.+)\/(.+)\/Config.json$/)
            const sourceSystem = match[1]
            const program = match[2]
            const productType = match[3]
            const documentType = match[4]
            if (program !== 'system') {
              if (!programmes.includes(program)) {
                programmes.push(program)
              }
              if (!productTypes.includes(productType)) {
                productTypes.push(productType)
              }
              if (!documentTypes.includes(documentType)) {
                documentTypes.push(documentType)
              }
              if(!sourceSystems.includes(sourceSystem)){
                sourceSystems.push(sourceSystem)
              }
            }
          })
        const createConfigKeyDialog = this.dialog.open(AnalysisCreateConfigDialogComponent, {
          width: '750px',
          data: {
            title: `Create New Configuration`,
            notes: `Enter a new value (or select from existing values) for each element.  The values are case-sensitive, must match those sent by CS, and the combination must not already exist`,
            programmes: programmes.sort((a,b) => (a < b ? -1 : 1)),
            productTypes: productTypes.sort((a,b) => (a < b ? -1 : 1)),
            documentTypes: documentTypes.sort((a,b) => (a < b ? -1 : 1)),
            sourceSystems: sourceSystems.sort((a,b) => (a < b ? -1 : 1)),
          } as AnalysisCreateConfigDetails,
          panelClass: 'dialogFormField500',
        })

        createConfigKeyDialog.afterClosed().subscribe(async (data: AnalysisCreateConfigDetails) => {
          if (data) {
            if (!data.sourceSystem || !data.programme || !data.productType || !data.documentType) {
              this.toast.error(`All configuration key elements must be supplied`)
              return
            }
            const key = `settings/${data.sourceSystem}/${data.programme}/${data.productType}/${data.documentType}/Config.json`
            try {
              if (this.changeService.getObject(key) !== undefined) {
                this.toast.error(`The configuration key ${data.sourceSystem} :: ${data.programme} :: ${data.productType} :: ${data.documentType} already exists`)
                return
              }
            } catch (e) {
              // OOH - this is nasty!
            }

            const config = DocgenConfig.create()
            config.validatePayload.skip = false
            config.validateEnriched.skip = false
            this.saveChangedConfig('Config.json', key, config)
          }
        })
      })
  }

  private saveChangedConfig(filename: string, path: string, config: DocgenConfig): void {
    this.changeService.addChange({
      source: 'lambda-ingest-product',
      name: filename,
      path: path,
      content: JSON.stringify(config, undefined, 2),
      size: undefined,
      hash: undefined,
      modified: undefined,
      state: undefined,
    } as ConfigDetail)

    this.workspaceAutoSaveService.saveWorkspace();
  }

}
