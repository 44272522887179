<ng-container *ngIf="areChanges">
  <div class="tableFixHead scroll-inherit-important">
    <table class="table table-striped">
      <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Change</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let config of specifiedConfigChanges">
          <td>{{config.name}}</td>
          <td>{{config.change}}</td>
          <td *ngIf="specifiedConfigName !== 'Template'">
            <button mat-button mat-raised-button color="accent" (click)="showDetails(config.name)">Details</button>
          </td>
          <td *ngIf="specifiedConfigName == 'Template'">
            <button mat-button mat-raised-button color="accent" (click)="downloadTemplates(config)">Download Templates</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="!areChanges">
  <p>No changes to {{specifiedConfigName}}</p>
</ng-container>


