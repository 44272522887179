import { AbstractControl } from '@angular/forms';
export function regexValidator(control: AbstractControl): { [key: string]: boolean } | null {

    if (control.value !== null || control.value !== undefined || control.value !== '') {
        try {
            const val = new RegExp(control.value);
            return null;
        } catch (e) {
            // Handle the case where the regular expression is invalid
            return { regex: true, message: e.message };
        }
    }

    return null;
}