<div>
  <mat-form-field>
    <input matInput (keyup)="setFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>

<app-test-suite-selector></app-test-suite-selector>

<div class="suite-operations">
  <button *ngIf="canSaveOrDelete" mat-button mat-raised-button color="accent" (click)="saveTestSuite()">Save as Test
    Suite
  </button>
  <button mat-button mat-raised-button color="warn" *ngIf="selectedTestSuite && isManager" (click)="deleteTestSuite()">
    Delete Test Suite 
  </button>

  <button class="deleteTests" mat-button mat-raised-button color="warn" *ngIf="canSaveOrDelete" (click)="deleteTests(selectedTests)">
    Delete Tests ( {{selectedTests.length}} )
  </button>
</div>

<div fxLayout="row" fxLayoutAlign="space-between">
  <div fxFlex class="tests-container">
    <div class="tests-header">Available</div>
    <div id="availableTests" dragula="DRAGULA_TESTS" [(dragulaModel)]="unselectedTests" class="tests-list">
      <div *ngFor='let test of unselectedTests; trackBy: testName'>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="6px" fxFlexFill *ngIf="test.visible" class="test">
          <div class="test-name">
            {{test.name}}
          </div>
          <div class="select-test">
            <a (click)="addTestToSuite(test)"><fa-icon icon="chevron-circle-right"></fa-icon></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex class="tests-container">
    <div class="tests-header">Selected</div>
    <div id="selectedTests" dragula="DRAGULA_TESTS" [(dragulaModel)]="selectedTests" class="tests-list">
      <div *ngFor='let test of selectedTests; trackBy: testName'>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="6px" fxFlexFill *ngIf="test.visible" class="test">
          <div class="remove-test">
            <a (click)="removeTestFromSuite(test)"><fa-icon icon="chevron-circle-left"></fa-icon></a>
          </div>
          <div class="test-name">
            {{test.name}}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>