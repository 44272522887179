import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {PipelineVersionInfo, VersionSummary} from '../common/domain/version'
import {merge, Observable, of, Subject, timer} from 'rxjs'
import {distinctUntilChanged, map, mergeMap, shareReplay, switchMap, toArray} from 'rxjs/operators'
import {ConfigVersion} from '../model/infra-config-response'
import {ConfigService} from './config.service'

const deepEqual = require('deep-equal')


@Injectable({
  providedIn: 'root',
})
export class ConfigVersionService {
  timer$ = timer(0, 60000)
  reload$ = new Subject()
  refresh = merge(this.timer$, this.reload$)

  deployedVersions: Observable<VersionSummary[]> = this.refresh.pipe(
    switchMap(_ => {
      return of('docgen', 'publish', 'templates', 'approvals')
        .pipe(
          // tap(res => {
          //   console.log('Loading deployed version for ' + res)
          // }),
          mergeMap(source => {
            return this.api.get<ConfigVersion>('/config/version/' + source)
            // .pipe(
            //   tap(res => {
            //     console.log('Loaded deployed versions response for ' + source)
            //     console.dir(res)
            //   }),
            // )
          }),
          map((res: ConfigVersion) => {
            return {
              source: res.source,
              version: res.version.version,
              buildTime: res.version.buildTime,
              buildNumber: res.version.buildNumber
            }
          }),
          toArray(),
          map(res => {
            return res.sort((x, y) => x.source.localeCompare(y.source))
          }),
          // tap(res => {
          //   console.log('Sorted deployed versions')
          //   console.dir(res)
          // }),
        )
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
    // tap(res => {
    //   console.log('Final deployed versions')
    //   console.dir(res)
    // }),
  )

  configPipelines = this.refresh.pipe(
    switchMap(_ => {
      return of('docgen', 'publish', 'templates', 'approvals')
        .pipe(
          // tap(res => {
          //   console.log('Loading pipeline info for ' + res)
          // }),
          mergeMap(source => {
            return this.api.get<PipelineVersionInfo>('/config/pipeline/' + source)
              .pipe(
                // tap(res => {
                //   console.log('Loaded configs pipeline response for ' + source)
                //   console.dir(res)
                // }),
              )
          }),
          toArray(),
          // tap(res => {
          //   console.log('Final loaded config pipelines')
          //   console.dir(res)
          // }),
        )
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  loadedVersions = this.configService.loadedVersions

  constructor(private api: ApiService,
              private configService: ConfigService) {
  }

  reload() {
    this.reload$.next()
  }
}
