import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {
  GetChangesResponse,
  GetDeployingResponse,
  GetPendingApprovalsResponse,
  GetPipelinesResponse,
  GetSuccessfulApprovalsResponse,
  PostApprovalsResponse,
} from '../model/approval-responses'
import {PendingApproval} from '../common/domain/approvals'
import {ApproveRequest} from '../model/approval-requests'
import {Observable, of, throwError, timer} from 'rxjs'
import {delay, distinctUntilChanged, mergeMap, retryWhen, shareReplay, switchMap, tap} from 'rxjs/operators'
import {StatusService} from './status.service'
import {PermissionsService} from './permissions.service'
import {ToastrService} from 'ngx-toastr'

const deepEqual = require('deep-equal')

const MAX_RETRY = 3
const DELAY = 1000
const BACKOFF = 1000

const retryWithBackoff = (delayMs: number = DELAY, maxRetries: number = MAX_RETRY, backoff: number = BACKOFF) => {
  let retry = 0
  return (src: Observable<any>) => src.pipe(
    retryWhen((errors) => {
      return errors.pipe(
        mergeMap(error => {
          console.dir('Retry: ' + retry + ' - ' + error)
          return retry++ < maxRetries
            ? of(error)
              .pipe(
                delay(delayMs + (retry * backoff)),
              )
            : throwError('Failed to load.')
        }),
      )
    }),
  )
}

@Injectable({
  providedIn: 'root',
})
export class ApprovalsService {

  constructor(
    private api: ApiService,
    private statusService: StatusService,
    private permissionsService: PermissionsService,
    private toast: ToastrService,
  ) {
    permissionsService.readonly.subscribe(permission => this.readOnly = permission)
  }

  static count = 0
  private readOnly = true

  timer = timer(0, 60000)
  fastTimer = timer(0, 30000)
  cache: { [key: string]: Observable<GetChangesResponse> } = {}

  pipelines = this.fastTimer.pipe(
    // tap(_ => {
    //   ApprovalsService.count = ApprovalsService.count + 1
    // console.log('Timer: ' + ApprovalsService.count + ': ' + new Date().toISOString())
    // }),
    switchMap(_ => {
      this.statusService.start('Loading Pipelines...')
      // console.log('SwitchMap: ' + ApprovalsService.count + ': ' + new Date().toISOString())
      return this.api.get<GetPipelinesResponse>('/approvals/pipelines/')
        .pipe(
          retryWithBackoff(),
        )
    }),
    // tap(_ => {
    //   console.log('After SwitchMap: ' + ApprovalsService.count + ': ' + new Date().toISOString())
    // }),
    tap(_ => this.statusService.clear('Loading Pipelines...')),
    distinctUntilChanged((x, y) => deepEqual(x, y)),
    // tap(_ => {
    //   console.log('After Distinct: ' + ApprovalsService.count + ': ' + new Date().toISOString())
    // }),
    shareReplay(1),
    // tap(_ => {
    //   console.log('After ShareReplay: ' + ApprovalsService.count + ': ' + new Date().toISOString())
    // }),
  )

  pendingApprovals = this.timer.pipe(
    switchMap(_ => {
      this.statusService.start('Loading Pending Approvals...')
      return this.api.get<GetPendingApprovalsResponse>('/approvals/pending')
        .pipe(
          retryWithBackoff(),
        )
    }),
    tap(_ => this.statusService.clear('Loading Pending Approvals...')),
    distinctUntilChanged((x, y) => deepEqual(x, y)),
    shareReplay(1),
  )

  latestSuccessfulApprovals = this.timer.pipe(
    switchMap(_ => {
      this.statusService.start('Loading Latest Approvals...')
      return this.api.get<GetSuccessfulApprovalsResponse>('/approvals/successful')
        .pipe(
          retryWithBackoff(),
        )
    }),
    tap(_ => this.statusService.clear('Loading Latest Approvals...')),
    distinctUntilChanged((x, y) => deepEqual(x, y)),
    shareReplay(1),
  )

  deploying = this.timer.pipe(
    switchMap(_ => {
      this.statusService.start('Loading Current Deployments...')
      return this.api.get<GetDeployingResponse>('/approvals/deploying')
        .pipe(
          retryWithBackoff(),
        )
    }),
    tap(_ => this.statusService.clear('Loading Current Deployments...')),
    distinctUntilChanged((x, y) => deepEqual(x, y)),
    shareReplay(1),
  )

  getPipelines = () => this.pipelines

  getPendingApprovals() {
    return this.pendingApprovals
  }

  getLatestSuccessfulApprovals() {
    return this.latestSuccessfulApprovals
  }

  getDeploying() {
    return this.deploying
  }

  getChanges(pipelineName: string, previousExecutionId: string, latestExecutionId: string) {
    const key = `${pipelineName}/${previousExecutionId}/${latestExecutionId}`
    this.cache[key] = this.cache[key] || this.api.get<GetChangesResponse>(`/approvals/changes/${pipelineName}/${previousExecutionId}/${latestExecutionId}`)
      .pipe(
        retryWithBackoff(),
        shareReplay(1),
      )
    return this.cache[key]
  }

  approve(toApprove: PendingApproval[], jira: string, comment: string) {
    if (this.readOnly) {
      this.toast.info('Would have approved the changes')
      return
    }
    return this.api.post<ApproveRequest, PostApprovalsResponse>('/approvals/approve', {
      approvals: toApprove.map(p => {
        const x = Object.assign({}, p) as PendingApproval
        delete x['changes']
        return x
      }),
      jira: jira,
      comment: comment,
    })
  }
}
