import {MatDialog} from '@angular/material/dialog'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import cloneDeep from 'clone-deep'
import {DateTime as LuxonDateTime} from 'luxon'
import {ToastrService} from 'ngx-toastr'
import {AnalyseRow, Deletion, RuleType} from 'src/app/common/domain/analyse'
import {ChangeDetails} from 'src/app/common/domain/change'
import {AnalyseConfig} from 'src/app/common/domain/config'
import {DocgenConfig} from 'src/app/common/domain/docgen-config'
import {AnalysisDeleteRuleDialogComponent, DeleteAnalysisRuleDetails} from 'src/app/dialogs/analysis-delete-rule-dialog.component'
import {ChangeService} from 'src/app/services/change.service'
import {NewConfigRulesComponent} from '../config-rules.component'
import {DisplayableRuleTable, RowUsage, Stage} from './interfaces'
import {UserActionCommon} from './user-action-common'

export class UserActionDeleteRuleSet {

    private parentComponent: NewConfigRulesComponent
    private dialog: MatDialog
    private changeService: ChangeService
    private toast: ToastrService
    private modalService: NgbModal

    constructor(
        parentControl: NewConfigRulesComponent,
        dialog: MatDialog,
        changeService: ChangeService,
        toast: ToastrService,
        modalService: NgbModal,
    ) {
        this.parentComponent = parentControl
        this.dialog = dialog
        this.changeService = changeService
        this.toast = toast
        this.modalService = modalService
    }

    public async deleteRuleSet(rulesetToDelete: DisplayableRuleTable): Promise<void> {
        // const deletionAuditingDialog =
        const dataFromUserDialog = await this.dialog.open(AnalysisDeleteRuleDialogComponent, {
            width: '500px',
            data: {
                title: `Ruleset Deletion`,
            } as DeleteAnalysisRuleDetails,
            panelClass: 'dialogFormField500',
        }).afterClosed().toPromise()

        // deletionAuditingDialog.afterClosed().subscribe(async (data: DeleteAnalysisRuleDetails) => {
        if (!dataFromUserDialog || !dataFromUserDialog.comment) {
            this.toast.error(`A Jira and description is required`)
            return
        }

        // Find and Delete RuleSet protection File
        const protectedRuleSetDefinitionFile: ChangeDetails = this.changeService.getObject('protected-rulesets/' + rulesetToDelete.mnemonic.toUpperCase() + '.json')
        protectedRuleSetDefinitionFile.source = 'lambda-ingest-product'
        this.changeService.deleteObject(protectedRuleSetDefinitionFile)

        let configPathsUsingThisRuleset = new Array<string>()
        const usedBy: RowUsage = rulesetToDelete.usedBy
        {
            const configKeysUsingThisRuleset: string[] = Object.keys(usedBy)
            configPathsUsingThisRuleset = configKeysUsingThisRuleset.map((configKey: string) => UserActionCommon.getConfigPathFromKey(configKey))
            const configsToDisplay: ChangeDetails[] = this.parentComponent.allConfigs
                .filter((config: ChangeDetails) => !/^settings\/system\/.+$/.test(config.path))
                .filter((config: ChangeDetails) => configPathsUsingThisRuleset.indexOf(config.path) > -1)
                .filter((config: ChangeDetails) => !usedBy[this.parentComponent.getConfigKeyFromPath(config.path)].deletion)
        }

        // plugh Remove the commented code soon (leaving here for now just in case we need it again...)
        // const configSelectorDialog = this.dialog.open(CreateWorkspaceDialogComponent, {
        //     width: '500px',
        //     data: {
        //         title: `Select configuration(s) to delete ruleset from:`,
        //         configItems: configsToDisplay,
        //         initialSelections: undefined,
        //     } as ConfigSelectorDialogData,
        //     panelClass: 'dialogFormField500',
        // })



        // All RuleSet are Protected so use all the configs (skipping the Selection Dialog)
        // const selectedConfigs = configsToDisplay.map(x => x.path)
        //                configSelectorDialog.afterClosed().subscribe(async (selectedConfigs: string[]) => {

        const currentTimestamp = LuxonDateTime.utc().toISO() // {suppressMilliseconds: true}
        const deletion = {
            user: this.parentComponent.username,
            timestamp: currentTimestamp,
            comment: dataFromUserDialog.comment,
        } as Deletion

        if (configPathsUsingThisRuleset && configPathsUsingThisRuleset.length > 0) {
            configPathsUsingThisRuleset.forEach((configPath: string) => {
                const configKey = this.parentComponent.getConfigKeyFromPath(configPath)
                const list = usedBy[configKey].ruleType
                const stage = usedBy[configKey].stage
                this.deleteRulesetFromConfig(configPath, stage, list, rulesetToDelete, deletion)
            })
        }
        //                })
        // })

    }

    public deleteRulesetFromConfig(
        configPath: string, stage: Stage, ruleType: RuleType,
        ruleset: DisplayableRuleTable, deletion: Deletion): void {

        const configKey = this.parentComponent.getConfigKeyFromPath(configPath)
        if (ruleset.usedBy[configKey].deletion) {
            this.toast.error(`Ruleset in ${configKey} is already deleted...`)
            return
        }

        const configObject = this.changeService.getObject(configPath)
        const config = JSON.parse(configObject.content) as DocgenConfig
        const analyseConfig: AnalyseConfig = (stage === 'product') ? config.analysePayload : config.analyseEnriched
        const existingList = analyseConfig.rules
        const existingRuleset = this.parentComponent.findRulesetInList(existingList.filter(r => r.ruleType === ruleType), ruleset)

        // Soft Delete only...decorate the ruleset and each of its rules with a deletion
        existingRuleset.deletion = deletion
        const deletionRuleSet = cloneDeep(deletion) as Deletion
        deletionRuleSet.comment = 'Auto-Deletion due to Ruleset Deletion: ' + deletion.comment
        const deletionBecauseEmptyRuleSet = deletionRuleSet
        existingRuleset.rule.forEach((rule: AnalyseRow) => {
            if (!rule.deletion) {
                rule.deletion = deletionBecauseEmptyRuleSet
            }
        })
        // plugh Change to use upsert?
        this.parentComponent.saveChangedConfig(configObject.name, configObject.path, config)
    }

}
