import {Injectable} from '@angular/core'
import {ToastrService} from 'ngx-toastr'
import {map, tap} from 'rxjs/operators'
import {Workspace, WorkspaceName} from '../common/domain/workspaces'
import {GetWorkspaceResponse, ListWorkspacesResponse} from '../model/workspace-responses'
import {ChangeDetectingBehaviourSubject} from '../util/change-detecting-behaviour-subject'
import {ApiService} from './api.service'
import {PermissionsService} from './permissions.service'

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  private workspaceNamesSubject = new ChangeDetectingBehaviourSubject<WorkspaceName[]>('Available Workspaces', undefined)
  workspaceNames = this.workspaceNamesSubject.asObservable()

  private selectedWorkspaceSubject = new ChangeDetectingBehaviourSubject<Workspace>('Selected Workspace', undefined)
  selectedWorkspace = this.selectedWorkspaceSubject.asObservable()

  private readOnly = true

  constructor(private api: ApiService,
    private permissionsService: PermissionsService,
    private toast: ToastrService,
  ) {
    permissionsService.readonly.subscribe(permission => this.readOnly = permission)
    this.loadWorkspaces()
  }

  getWorkspace(name: string) {
    return this.api.get<GetWorkspaceResponse>('/workspaces/' + name)
  }

  listWorkspaces() {
    return this.api.get('/workspaces/list')
      .pipe(
        map((res: ListWorkspacesResponse) => {
          return {
            names: res.names.map(n => n.replace('workspaces/', '')),
          }
        },
        ),
      )
  }

  saveWorkspace(name: WorkspaceName, workspace: Workspace) {
    if (this.readOnly) {
      this.toast.info('workspace would have been saved')
      return
    }
    return this.api.post('/workspaces/' + name, workspace)
      .pipe(
        tap(_ => {
          return this.loadWorkspaces()
        }),
      )
  }

  deleteWorkspace(name: string) {
    if (this.readOnly) {
      this.toast.info('workspace would have been deleted')
      return
    }
    return this.api.delete('/workspaces/' + name)
      .pipe(
        tap(_ => {
          return this.loadWorkspaces()
        }),
      )
  }

  selectWorkspace(workspaceName: WorkspaceName) {
    if (workspaceName) {
      this.getWorkspace(workspaceName)
        .subscribe(ws => {
          this.selectedWorkspaceSubject.next(ws)
        })
    } else {
      this.selectedWorkspaceSubject.next(undefined)
    }
  }

  private loadWorkspaces() {
    this.listWorkspaces()
      .subscribe(res => {
        this.workspaceNamesSubject.next(res.names)
      })
  }
}
