import {SelectionModel} from '@angular/cdk/collections'
import {NestedTreeControl} from '@angular/cdk/tree'
import {Component, Inject} from '@angular/core'
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {MatTreeNestedDataSource} from '@angular/material/tree'
import {ConfigKeyElement} from '../common/domain'
import {ConfigItem} from '../common/domain/config'
import {ConfigSelectorDialogData} from './config-selector-dialog-data'
import {SelectableConfigItem} from './selectable-config-item'
@Component({
  selector: 'app-create-workspace-dialog',
  templateUrl: 'create-workspace-dialog.component.html',
  styleUrls: ['./create-workspace-dialog.component.scss'],
})
export class CreateWorkspaceDialogComponent {

  okEnabled: boolean

  treeControl = new NestedTreeControl<ConfigKeyElement>(node => node.children)
  dataSource = new MatTreeNestedDataSource<ConfigKeyElement>()
  checklistSelection = new SelectionModel<ConfigKeyElement>(true)

  constructor(public dialogRef: MatDialogRef<CreateWorkspaceDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfigSelectorDialogData) {
    const treeview = this.buildTree(data.configItems)

    if (data.initialSelections) {
      data.initialSelections.forEach((configItem: SelectableConfigItem) => {
        const isSelected = configItem.isSelected
        const match = configItem.path.match(/^settings\/(.+)\/(.+)\/(.+)\/(.+)\/Config.json$/)
        if (match && match.length >= 5) {
          const sourceSystem = match[1]
          const programme = match[2]
          const productType = match[3]
          const documentType = match[4]
          const sourceSystemNode = treeview ? treeview.find((node: ConfigKeyElement) => node.name === sourceSystem) : undefined
          const programmeNode = sourceSystemNode ? sourceSystemNode.children.find((node: ConfigKeyElement) => node.name === programme) : undefined
          const productTypeNode = programmeNode ? programmeNode.children.find(node => node.name === productType) : undefined
          const documentTypeNode = productTypeNode ? productTypeNode.children.find(node => node.name === documentType) : undefined

          if (documentTypeNode) {
            if (isSelected) {
              documentTypeNode.isSelected = true
              this.checklistSelection.select(documentTypeNode)
            } else {
              documentTypeNode.isSelected = false
              this.checklistSelection.deselect(documentTypeNode)
            }
            this.setAncestors(documentTypeNode, isSelected)
          }
        }
      })
    }

    this.dataSource.data = treeview
    this.determineOKButtonStatus()
  }

  ok() {
    const keys: string[] = this.checklistSelection.selected
      .filter(selection => selection.level === 'documentType')
      .map(documentType => {
        const productType = documentType.parent
        const program = productType.parent
        const sourceSystem = program.parent
        return `settings/${sourceSystem.name}/${program.name}/${productType.name}/${documentType.name}/Config.json`
      })
    this.dialogRef.close(keys)
  }

  cancel() {
    this.dialogRef.close()
  }

  toggleSelectAllOrNone() {
    const isNoneSelected: boolean = this.checklistSelection.selected.length === 0
    const valueToSet = isNoneSelected
    this.dataSource.data.forEach(item => {
      item.isSelected = valueToSet
      valueToSet ? this.checklistSelection.select(item) : this.checklistSelection.deselect(item)
      this.setDescendents(item, valueToSet)
    })
    this.determineOKButtonStatus()
  }

  hasChild = (_: number, node: ConfigKeyElement) => !!node.children && node.children.length > 0

  toggleSelection(node: ConfigKeyElement): void {
    this.checklistSelection.toggle(node)
    node.isSelected = !node.isSelected
    this.setAncestors(node, node.isSelected)
    this.setDescendents(node, node.isSelected)
    this.determineOKButtonStatus()
  }

  isSelected(node: ConfigKeyElement): boolean {
    return this.checklistSelection.isSelected(node)
  }

  determineOKButtonStatus(): void {
    this.okEnabled = this.data.forceOKEnabled || this.checklistSelection.selected.length > 0
  }

  private setAncestors(node: ConfigKeyElement, value: boolean): void {
    if (node.parent) {
      if (!value) {
        if (node.parent.children.every(child => !child.isSelected)) {
          value ? this.checklistSelection.select(node.parent) : this.checklistSelection.deselect(node.parent)
          node.parent.isSelected = value
        }
      } else {
        value ? this.checklistSelection.select(node.parent) : this.checklistSelection.deselect(node.parent)
        node.parent.isSelected = value
      }
      if (node.parent.parent) {
        if (!value) {
          if (node.parent.parent.children.every(child => !child.isSelected)) {
            value ? this.checklistSelection.select(node.parent.parent) : this.checklistSelection.deselect(node.parent.parent)
            node.parent.parent.isSelected = value
          }
        } else {
          value ? this.checklistSelection.select(node.parent.parent) : this.checklistSelection.deselect(node.parent.parent)
          node.parent.parent.isSelected = value
        }
        if (node.parent.parent.parent) {
          if (!value) {
            if (node.parent.parent.parent.children.every(child => !child.isSelected)) {
              value ? this.checklistSelection.select(node.parent.parent.parent) : this.checklistSelection.deselect(node.parent.parent.parent)
              node.parent.parent.parent.isSelected = value
            }
          } else {
            value ? this.checklistSelection.select(node.parent.parent.parent) : this.checklistSelection.deselect(node.parent.parent.parent)
            node.parent.parent.parent.isSelected = value
          }
        }
      }
    }
  }

  private setDescendents(node: ConfigKeyElement, value: boolean): void {
    node.children.forEach(child => {
      value ? this.checklistSelection.select(child) : this.checklistSelection.deselect(child)
      child.isSelected = value
      this.setDescendents(child, value)
    })
  }

  private buildTree(configItems: ConfigItem[]): ConfigKeyElement[] {

    return configItems.reduce((acc: ConfigKeyElement[], item: ConfigItem) => {

      const path = item.path
      const match = path.match(/^.*\/(.+)\/(.+)\/(.+)\/(.+)\/Config.json$/)
      const sourceSystem = match[1]
      const programme = match[2]
      const productType = match[3]
      const documentType = match[4]

      // Add the sourceSystem if it is not already in there
      let ss = acc.find(item => item.name === sourceSystem)
      if (!ss) {
        ss = {name: sourceSystem, level: 'sourceSystem', isSelected: false, hide: false, parent: undefined, children: []}
        acc.push(ss)
      }

      // Add the programme if it is not already in there
      if (!ss.children.find(item => item.name === programme)) {
        ss.children.push({name: programme, level: 'programme', isSelected: false, hide: false, parent: ss, children: []})
      }

      // Add the productType if it is not already in there
      const prog = ss.children.find(item => item.name === programme)
      if (!prog.children.find(item => item.name === productType)) {
        prog.children.push({name: productType, level: 'productType', isSelected: false, hide: false, parent: prog, children: []})
      }

      // Add the documentType if it is not already in there
      const prodType = prog.children.find(item => item.name === productType)
      if (!prodType.children.find(item => item.name === documentType)) {
        prodType.children.push({name: documentType, level: 'documentType', isSelected: false, hide: false, parent: prodType, children: []})
      }

      return acc
    }, [])
  }
}
