import {Component, OnInit} from '@angular/core'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'

@Component({
  selector: 'app-jira-issue-dialog',
  templateUrl: './jira-issue-dialog.component.html',
  styleUrls: ['./jira-issue-dialog.component.scss'],
})
export class JiraIssueDialogComponent implements OnInit {
  jiraIssue: string
  description: string

  constructor(
    public activeModel: NgbActiveModal,
  ) {
  }

  ngOnInit(): void {
  }

  cancel() {
    this.activeModel.dismiss()
  }

  confirm() {
    this.activeModel.close({
      jiraIssue: this.jiraIssue,
      description: this.description,
    })
  }

  validJira() {
    return this.jiraIssue?.match(/^[A-Z]+\-[\d]+$/)
  }
}
