import {OverlayContainer} from '@angular/cdk/overlay'
import {Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {
  ActivationEnd,
  ActivationStart,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router'
//import {AmplifyService} from '@flowaccount/aws-amplify-angular'
import {DateTime as LuxonDateTime} from 'luxon'
import {ClipboardService} from 'ngx-clipboard'
import {NgxSpinnerService} from 'ngx-spinner'
import {ToastrService} from 'ngx-toastr'
import {combineLatest, Subscription} from 'rxjs'
import {routes} from './app-routing.module'
import {PipelineVersionInfo, VersionSummary} from './common/domain/version'
import {BANNER, MAINTENANCE} from './maintenance'
import {ApprovalsService} from './services/approvals.service'
import {ConfigMonitorService} from './services/config-monitor.service'
import {ConfigVersionService} from './services/config-version.service'
import {PermissionsService} from './services/permissions.service'
import {StatusMessage, StatusService} from './services/status.service'
import {Theme, ThemeService} from './services/theme.service'
import {TokenService} from './services/token.service'
import {UserPreferencesService} from './services/user-preferences.service'
import {MENU_ITEM_EXCLUSIONS, PROJECT_NAME, valueReplaced} from './constants'
import { AuthService } from './services/auth.service'
import { AuthenticatorService } from '@aws-amplify/ui-angular'
export interface ConfigVersionOverview {
  name: string
  loaded?: VersionSummary
  deployed?: VersionSummary
  pipeline?: PipelineVersionInfo
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('rightsidenav') rightsidenav:any;
  @ViewChild('leftsidenav') leftsidenav:any;
  maintenance = MAINTENANCE
  banner = BANNER

  sub = new Subscription()

  playgroundExpanded = false
  configExpanded = false
  adminExpanded = false
  testsExpanded = false
  adminRegxchangeExpanded = false
  configMappingsExpanded = false
  toolsExpanded = false
  savePayloadExpanded = false

  signedIn: boolean
  user: any
  greeting: string
  usernameAttributes = 'username'
  title: string
  theme: string
  status?: StatusMessage
  isDeveloper = false
  viewPlayground = false
  isApprover = false
  useWorkspaces = false
  isTestManager = false
  isManagement = false
  canRestartSmartDx = false
  hasRegxchangeCSPRODSupportPermission = false
  hasRegxchangeCSUATSupportPermission = false
  hasPlaygroundPermission = false
  hasDashboardPermission = false
  hasConfigPermission = false
  hasInspectPermission = false
  hasGeneratePermission = false
  hasPublishPermission = false
  hasTestsPermission = false
  hasToolsPermission = false
  hasManualDraftingPermission = false
  hasAdminPermission = false
  hasWallStreetDocsPermission = false
  canEditMappings = false
  canEditStaticData = false
  canApproveRules = false
  canEditPreprocessorLogic = false
  readOnly = true
  menuItemExclusions = []

  appVersion = 'APP_VERSION_PLACEHOLDER'
  appBuildNumber = 'APP_BUILD_NUMBER_PLACEHOLDER'
  appBuildTime = parseInt('APP_BUILD_TIME_PLACEHOLDER', 10) || 1575466827095
  appBuildDateString: string

  accessToken: string
  idToken: string
  environment = window.location.hostname.includes('localhost')
    ? ' (Dev)'
    : ` (${valueReplaced(PROJECT_NAME) ? PROJECT_NAME : 'CS'})`

  @HostBinding('class') componentCssClass

  navigating = false
  loading = true
  activeRoute = undefined

  showDocgenConfigSelection = false
  showPublishConfigSelection = false
  showTestSuiteSelection = false
  showStormInSourceEnvironment = false
  showSourceEnvironmentSelection = false
  showTargetEnvironmentSelection = false
  showAdminTargetEnvironmentSelection = false
  showWorkspaceSelection = false
  showSelectors = false
  hideSkin = false
  configVersions: ConfigVersionOverview[] = []

  constructor(
    private spinner: NgxSpinnerService,
   // private amplify: AmplifyService,
    private themeService: ThemeService,
    public overlayContainer: OverlayContainer,
    private userPreferences: UserPreferencesService,
    private statusService: StatusService,
    private configVersionService: ConfigVersionService,
    private tokenService: TokenService,
    private clipboardService: ClipboardService,
    private permissionsService: PermissionsService,
    private approvalsService: ApprovalsService,
    private configMonitorService: ConfigMonitorService,
    private toastr: ToastrService,
    private router: Router,
    public authService: AuthService,
    public authenticator: AuthenticatorService
  ) {
    if (this.appVersion.startsWith('APP_')) {
      this.appVersion = 'DEV-BUILD'
    }
    if (this.appBuildNumber.startsWith('APP_')) {
      this.appBuildNumber = 'DEV'
    }
    this.appBuildDateString = LuxonDateTime.fromMillis(this.appBuildTime).toFormat('dd.MM HH:mm')
    // const orgMomentString = moment(this.appBuildTime).format('DD.MM HH:mm')
    // if (orgMomentString !== this.appBuildDateString) {
    //   throw Error('Date formats are not the same')
    // }

    // moment(this.appBuildTime).format('DD.MM HH:mm')

    if (valueReplaced(MENU_ITEM_EXCLUSIONS)) {
      this.menuItemExclusions = MENU_ITEM_EXCLUSIONS.split(',')
    }

    combineLatest([
      this.configVersionService.loadedVersions,
      this.configVersionService.deployedVersions,
      this.configVersionService.configPipelines,
    ]).subscribe(([
      loadedVersions,
      deployedVersions,
      pipelines,
    ]: [VersionSummary[], VersionSummary[], PipelineVersionInfo[]]) => {
      this.configVersions = [
        {
          name: 'DOCS',
          loaded: loadedVersions.find(c => c.source === 'lambda-ingest-product'),
          deployed: deployedVersions.find(c => c.source === 'lambda-ingest-product'),
          pipeline: pipelines.find(p => p.source === 'lambda-ingest-product'),
        },
        {
          name: 'Templates',
          loaded: loadedVersions.find(c => c.source === 'auto-deploy-templates'),
          deployed: deployedVersions.find(c => c.source === 'auto-deploy-templates'),
          pipeline: pipelines.find(p => p.source === 'auto-deploy-templates'),
        },
        {
          name: 'Routing',
          loaded: loadedVersions.find(c => c.source === 'api-publish'),
          deployed: deployedVersions.find(c => c.source === 'api-publish'),
          pipeline: pipelines.find(p => p.source === 'api-publish'),
        },
        {
          name: 'Approvals',
          loaded: loadedVersions.find(c => c.source === 'lambda-approvals'),
          deployed: deployedVersions.find(c => c.source === 'lambda-approvals'),
          pipeline: pipelines.find(p => p.source === 'lambda-approvals'),
        },
      ]
    })
    this.themeService.theme.subscribe(theme => {
      if (this.theme) {
        document.getElementById('themeTag').classList.remove(this.theme)
        // this.overlayContainer.getContainerElement().classList.remove(this.title)
      }
      this.theme = theme
      document.getElementById('themeTag').classList.add(this.theme)
      // this.overlayContainer.getContainerElement().classList.add(this.title)
      this.componentCssClass = theme
      if (theme === 'Clean') {
        this.title = 'NotDocBox'
      }
      if (theme === 'Aurora') {
        this.title = 'Aurora'
      }
      if (theme === 'Storm') {
        this.title = 'Storm'
      }
    })
    this.statusService.topTask.subscribe(status => this.status = status)
    this.tokenService.oauthToken.subscribe(token => this.accessToken = token)
    this.tokenService.idToken.subscribe(token => this.idToken = token)
    this.permissionsService.developer.subscribe(developer => this.isDeveloper = developer)
    this.permissionsService.workspaces.subscribe(workspaces => this.useWorkspaces = workspaces)
    this.permissionsService.testManager.subscribe(testManager => this.isTestManager = testManager)
    this.permissionsService.management.subscribe(managementUser => this.isManagement = managementUser)
    this.permissionsService.restartSmartDx.subscribe(permission => this.canRestartSmartDx = permission)
    this.permissionsService.editMappings.subscribe(permission => this.canEditMappings = permission)
    this.permissionsService.approver.subscribe(approver => this.isApprover = approver)
    this.permissionsService.editStaticData.subscribe(permission => this.canEditStaticData = permission)
    this.permissionsService.canViewPlayground.subscribe(permission => this.viewPlayground = this.viewPlayground || permission)
    this.permissionsService.canApproveRule.subscribe(permission => this.canApproveRules = permission)
    this.permissionsService.readonly.subscribe(permission => this.readOnly = permission)
    this.permissionsService.regxchangeCSPRODSupport.subscribe(permission => this.hasRegxchangeCSPRODSupportPermission = permission)
    this.permissionsService.regxchangeCSUATSupport.subscribe(permission => this.hasRegxchangeCSUATSupportPermission = permission)
    this.permissionsService.canViewPlayground.subscribe(permission => this.hasPlaygroundPermission = permission)
    this.permissionsService.canViewDashboard.subscribe(permission => this.hasDashboardPermission = permission)
    this.permissionsService.canViewConfig.subscribe(permission => this.hasConfigPermission = permission)
    this.permissionsService.canViewInspect.subscribe(permission => this.hasInspectPermission = permission)
    this.permissionsService.canViewGenerate.subscribe(permission => this.hasGeneratePermission = permission)
    this.permissionsService.canViewPublish.subscribe(permission => this.hasPublishPermission = permission)
    this.permissionsService.canViewTests.subscribe(permission => this.hasTestsPermission = permission)
    this.permissionsService.canViewTools.subscribe(permission => this.hasToolsPermission = permission)
    this.permissionsService.canViewManualDrafting.subscribe(permission => this.hasManualDraftingPermission = permission)
    this.permissionsService.canViewAdmin.subscribe(permission => this.hasAdminPermission = permission)
    this.permissionsService.canViewWallStreetDocsPostRequest.subscribe(permission => this.hasWallStreetDocsPermission = permission)
    this.permissionsService.canViewPreprocessorLogic.subscribe( permission => this.canEditPreprocessorLogic = permission)
    this.configMonitorService.configLoaded.subscribe(version => {
      this.statusService.start('New config loaded', 'ALERT', 10)
    })
    this.configMonitorService.configStale.subscribe(stale => {
      if (stale) {
        this.statusService.start('New config available - Please reload', 'ALERT')
      }
    })
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })
this.authService.onUserSession.subscribe((session) => {
  this.signedIn = session?.isValid();
  this.user =
    session?.isValid() && session.idToken.payload['cognito:username'];
//  this.email = session?.isValid() && session.idToken.payload.email;
});
  }

  changeTheme() {
    this.themeService.nextTheme()
  }

  setTheme(theme: Theme) {
    console.log('Setting theme: ' + theme)
    this.themeService.setTheme(theme)
  }

  ngOnInit() {
    this.spinner.show()
   // this.signedIn = true;
    // this.amplify.authStateChange$
    //   .subscribe(authState => {
    //     this.signedIn = authState.state === 'signedIn'
    //     if (!authState.user) {
    //       this.user = null
    //     } else {
    //       this.user = authState.user
    //       this.greeting = this.user?.username?.replace(/\./g, ' ') || 'Nobody'
    //     }
    //   })






    // this.sub.add(
    //   this.permissionsService.developer.pipe(
    //     switchMap(approver => {
    //       if (approver) {
    //         return this.approvalsService.getPipelines()
    //       } else {
    //         return of(undefined)
    //       }
    //     }),
    //   ).subscribe(pipelines => {
    //     if (pipelines) {
    //       const failed = pipelines.failed || []
    //       if (failed.length > 0) {
    //         this.statusService.alert('Pipeline Failures...')
    //       } else {
    //         this.statusService.clear('Pipeline Failures...')
    //       }
    //     }
    //   }),
    // )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  signOut() {
    console.info('Signing out')
   // this.amplify.auth().signOut()
  }

  saveToken() {
    if (this.isDeveloper && this.accessToken && this.idToken) {
      const copiedText = `ACCESS_TOKEN=${this.accessToken}
ID_TOKEN=${this.idToken}`
      this.clipboardService.copyFromContent(copiedText)
      this.toastr.success('Token copied')
    }
  }

  togglePlayground() {
    this.playgroundExpanded = !this.playgroundExpanded
  }

  toggleConfig() {
    this.configExpanded = !this.configExpanded
  }

  toggleMappings() {
    this.configMappingsExpanded = !this.configMappingsExpanded
  }

  toggleAdmin() {
    this.adminExpanded = !this.adminExpanded
  }

  toggleTools() {
    this.toolsExpanded = !this.toolsExpanded
  }

  toggleRegxchange() {
    this.adminRegxchangeExpanded = !this.adminRegxchangeExpanded
  }

  toggleTests() {
    this.testsExpanded = !this.testsExpanded
  }

  toggleManualDrafting() {
    this.savePayloadExpanded = !this.savePayloadExpanded
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof ActivationStart) {
      this.spinner.show()
    }
    if (event instanceof NavigationStart) {
      this.spinner.show()
      this.navigating = true
      this.activeRoute = undefined
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      this.navigating = false
    }
    if (event instanceof ActivationEnd) {
      const activeRoute = event.snapshot.routeConfig.path

      const found = routes.find(r => r.path === activeRoute)
      if (found) {
        this.showWorkspaceSelection = found.showWorkspaceSelection || false
        this.showSourceEnvironmentSelection = found.showSourceEnvironmentSelection || false
        this.showTargetEnvironmentSelection = found.showTargetEnvironmentSelection || false
        this.showAdminTargetEnvironmentSelection = found.showAdminTargetEnvironmentSelection || false
        this.showTestSuiteSelection = found.showTestSuiteSelection || false
        this.showDocgenConfigSelection = found.showDocgenConfigSelection || false
        this.showPublishConfigSelection = found.showPublishConfigSelection || false
        this.showStormInSourceEnvironment = found.showStormInSourceEnvironment || false
        this.hideSkin = found.hideSkin || false
      } else {
        this.showWorkspaceSelection = false
        this.showSourceEnvironmentSelection = false
        this.showTargetEnvironmentSelection = false
        this.showAdminTargetEnvironmentSelection = false
        this.showTestSuiteSelection = false
        this.showDocgenConfigSelection = false
        this.showPublishConfigSelection = false
        this.showStormInSourceEnvironment = false
        this.hideSkin = found.hideSkin || false
      }

      this.showSelectors = this.showPublishConfigSelection || this.showDocgenConfigSelection || this.showSourceEnvironmentSelection || this.showTestSuiteSelection

      this.activeRoute = activeRoute
      this.loading = false
      this.spinner.hide()
    }
  }

  menuItemExcluded(menuItem: string) {
    return this.menuItemExclusions.includes(menuItem)
  }

  playgroundExcluded(): boolean {
    return this.menuItemExcluded('Playground')
  }

  dashboardExcluded(): boolean {
    return this.menuItemExcluded('Dashboard')
  }

  configExcluded(): boolean {
    return this.menuItemExcluded('Config')
  }

  inspectExcluded(): boolean {
    return this.menuItemExcluded('Inspect')
  }

  generateExcluded(): boolean {
    return this.menuItemExcluded('Generate')
  }

  publishExcluded(): boolean {
    return this.menuItemExcluded('Publish')
  }

  testsExcluded(): boolean {
    return this.menuItemExcluded('Tests')
  }

  toolsExcluded(): boolean {
    return this.menuItemExcluded('Tools')
  }

  manualDraftingExcluded(): boolean {
    return this.menuItemExcluded('Manual Drafting')
  }

  adminExcluded(): boolean {
    return this.menuItemExcluded('Admin')
  }
}
