import {Injectable} from '@angular/core'
import {GenerateDocumentRequest} from '../model/generate-document-request'
import {ApiService} from './api.service'
import {IngestProductDocgenTraceResponse, isZippedTraceResponse} from '../model/public-api-response'
import {Environment, ENVIRONMENTS, EnvironmentService, Region} from './environment-service'
import {map, switchMap, take} from 'rxjs/operators'
import {PerformTraceRequest} from '../model/test-management-requests'
import {Observable} from 'rxjs'
import {unzipIfNecessary} from '../util/trace-util'

@Injectable({
  providedIn: 'root',
})
export class GenerateDocumentService {

  constructor(private apiService: ApiService,
              private environmentService: EnvironmentService) {
  }

  generateDocument(request: GenerateDocumentRequest, privateConfig: boolean, includeHeadersAndFooters: boolean = false, forceEvents?: boolean, checkOnly?: boolean, isDynamicMapping?: boolean, isPreprocessorMapping?: boolean): Observable<IngestProductDocgenTraceResponse> {
    console.log(`privateConfig = ${privateConfig}`)

    return this.environmentService.targetEnvironment
      .pipe(
        take(1),
        switchMap((env: Environment) => {
            console.log(`Env is:` + JSON.stringify(env))

            if (privateConfig) {
              const url = this.getRequestUrl(request, isDynamicMapping, isPreprocessorMapping)
              return this.apiService.post<string, IngestProductDocgenTraceResponse>(url, request.payload, {
                params: {
                  documentType: request.documentType,
                  programme: request.programme,
                  productType: request.productType,
                  testOnly: (includeHeadersAndFooters ? 'false' : 'true'),
                  forceEvents: (forceEvents ? 'true' : 'false'),
                  privateConfig: (privateConfig ? 'true' : 'false'),
                  checkOnly: (checkOnly ? 'true' : 'false'),
                  trace: 'true',
                  // checkOnly: 'true',
                  forceGenerate: 'true',
                  country: request.country ? request.country : '',
                  jurisdiction: request.jurisdiction ? request.jurisdiction : '',
                  language: request.language ? request.language : '',
                  // use stormRequest flag to determine if it's an internal request or not
                  stormRequest: 'true',
                },
                headers: {
                  Accept: request.documentFormat === 'word' ?
                    'application/msword' :
                    'application/pdf',
                  'Content-Type': privateConfig ? 'application/json' : request.sourceFormat === 'xml' ? 'application/xml' : 'application/json',
                  source_system: request.sourceSystem,
                  preprocessorMapping: isPreprocessorMapping ? 'true' : 'false',
                  dynamicMapping: isDynamicMapping ? 'true' : 'false'

                },
              })
            } else {

            return this.environmentService.region.pipe(
              take(1),
              switchMap((region: Region) => {
                const performTraceRequest: PerformTraceRequest = {
                  action: 'PerformTrace',
                  environment: env.id,
                  region: region.id,
                  payload: request.payload,
                  requestIdentifier: request.productIdentifier,
                  product: {
                    service: 'docgen',
                    sourceSystem: request.sourceSystem,
                    sourceFormat: request.sourceFormat,
                    programme: isDynamicMapping || isPreprocessorMapping? '' : request.programme,
                    productType: isDynamicMapping || isPreprocessorMapping? '' : request.productType,
                    documentType: request.documentType,
                    documentFormat: request.documentFormat,
                    dynamicMapping: isDynamicMapping ? 'true' : '',
                    preprocessorMapping: isPreprocessorMapping ? 'true' : '',

                  },
                  // use stormRequest field to determine if it's an internal request or not
                  stormRequest: 'true',
                  service: 'docgen',
                  testOnly: !includeHeadersAndFooters,
                  forceEvents: forceEvents,
                  checkOnly: checkOnly,
                  word: request.documentFormat === 'word',
                  country: request.country ? request.country : '',
                  jurisdiction: request.jurisdiction ? request.jurisdiction : '',
                  language: request.language ? request.language : '',
                }
                const url = '/tests/'
                return this.apiService.post<PerformTraceRequest, IngestProductDocgenTraceResponse>(url, performTraceRequest, {
                  headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    source_system: request.sourceSystem,
                  },
                })
              }),
            )
            }
        }),
      )
      .pipe(
        switchMap((response: IngestProductDocgenTraceResponse) => unzipIfNecessary(response))
      )
  }

  private getRequestUrl(request: GenerateDocumentRequest, isDynamicMapping?: boolean, isPreprocessorMapping?: boolean) {
    // return `/check/${request.programme}/${request.productType}/${request.documentType}/${request.productIdentifier}`
    // return `/generate/${request.programme}/${request.productType}/${request.documentType}/${request.productIdentifier}`
      if(isDynamicMapping || isPreprocessorMapping) {
      return `/generate/${request.documentType}`
    }
    return `/gendoc/${request.documentType}/${request.documentLanguage}/${request.programme}/${request.productWrapper}/${request.productType}/${request.productIdentifier}/${request.productState}`
  }
}
