import {Component, OnDestroy, OnInit} from '@angular/core'
import {Environment, EnvironmentService} from '../../services/environment-service'
import {ManagementInformationService} from '../../services/management-information.service'
import {UntypedFormControl, Validators} from '@angular/forms'
import {Subject} from 'rxjs'
import {takeUntil} from 'rxjs/operators'
import {DateTime} from 'luxon'

interface MiReportMetadata {
  id: string
  display: string
  maxDays: number
  environmental: boolean
}

interface DateRange {
  display: string
  fromDate: string
  toDate?: string
}

@Component({
  selector: 'app-mi-report',
  templateUrl: './management-information-report.component.html',
  styleUrls: ['./management-information-report.component.scss'],
})
export class ManagementInformationReportComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>()
  fetch = new Subject<boolean>()

  reports: MiReportMetadata[] = [
    {
      id: 'document-generation',
      display: 'Document Generation',
      maxDays: 365 * 2,
      environmental: true,
    },
    {
      id: 'connectivity',
      display: 'Connectivity (RegXchange)',
      maxDays: 7,
      environmental: true,
    },
    {
      id: 'client-summary',
      display: 'Client Summary',
      maxDays: 365 * 2,
      environmental: false,
    },
    {
      id: 'client-sla',
      display: 'Client SLA',
      maxDays: 365 * 2,
      environmental: false,
    },
    {
      id: 'jira-issues',
      display: 'Jira Issues',
      maxDays: 60,
      environmental: true,
    },
    {
      id: 'approvals',
      display: 'Release Approvals',
      maxDays: 365 * 2,
      environmental: false,
    },
    {
      id: 'smartdx-maintenance',
      display: 'SmartDX Maintenance',
      maxDays: 365,
      environmental: true,
    },
    {
      id: 'smartdx-monitoring',
      display: 'SmartDX Monitoring',
      maxDays: 60,
      environmental: true,
    },
    {
      id: 'smartdx-requests',
      display: 'SmartDX Requests',
      maxDays: 60,
      environmental: true,
    },
    {
      id: 'wsd-priipcloud-requests',
      display: 'PriipCloud Requests',
      maxDays: 60,
      environmental: true,
    },
    {
      id: 'wsd-docauto-requests',
      display: 'DocAuto Requests',
      maxDays: 60,
      environmental: true,
    },
    {
      id: 'validation-report',
      display: 'Validation Report',
      maxDays: 60,
      environmental: true,
    },
  ]

  quickDateRanges: DateRange[]
  quickDates: DateRange[]

  selectedReportControl = new UntypedFormControl('', Validators.required)
  fromDateControl = new UntypedFormControl('', Validators.required)
  toDateControl = new UntypedFormControl('')

  allowEnvironmentSelection = false
  singleDateSelection = false
  dateRangeSelection = false

  report: MiReportMetadata
  environment: Environment
  fromDate: string
  toDate: string

  constructor(
    private managementInformationService: ManagementInformationService,
    private environmentService: EnvironmentService,
  ) {
  }

  ngOnInit() {
    this.selectedReportControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((value: MiReportMetadata | undefined) => {
        this.fetch.next(false)
        this.report = value
        this.singleDateSelection = value?.maxDays === 1
        this.dateRangeSelection = value?.maxDays > 1
        this.allowEnvironmentSelection = value?.environmental
      })
    this.fromDateControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((value: string) => {
        this.fetch.next(false)
        if (value?.match(/^\d\d\d\d-\d\d-\d\d/)) {
          this.fromDate = value
        } else {
          this.fromDate = ''
        }
      })
    this.toDateControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((value: string) => {
        this.fetch.next(false)
        console.log('Checking: ' + value)
        if (value?.match(/^\d\d\d\d-\d\d-\d\d/)) {
          this.toDate = value
        } else {
          this.toDate = ''
        }
      })
    this.environmentService.sourceEnvironment
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(environment => {
        this.fetch.next(false)
        this.environment = environment
      })

    const today = DateTime.local()
    const yesterday = today.minus({days: 1})

    this.fromDate = yesterday.toFormat('yyyy-MM-dd')
    this.fromDateControl.patchValue(this.fromDate)
    this.toDateControl.patchValue('')
    this.quickDates = [
      {
        display: 'Today',
        fromDate: today.toFormat('yyyy-MM-dd'),
      },
      {
        display: 'Yesterday',
        fromDate: yesterday.toFormat('yyyy-MM-dd'),
      },
      {
        display: 'Two Days Ago',
        fromDate: today.minus({days: 2}).toFormat('yyyy-MM-dd'),
      },
      {
        display: 'Three Days Ago',
        fromDate: today.minus({days: 3}).toFormat('yyyy-MM-dd'),
      },
    ]
    const startOfWeek = today.startOf('week')
    const startOfMonth = today.startOf('month')
    const startOfQuarter = today.startOf('quarter')
    const startOfLastWeek = startOfWeek.minus({weeks: 1})
    const endOfLastWeek = startOfWeek.minus({days: 1})
    const startOfLastMonth = startOfMonth.minus({months: 1})
    const endOfLastMonth = startOfMonth.minus({days: 1})
    const startOfLastQuarter = startOfQuarter.minus({quarters: 1})
    const endOfLastQuarter = startOfQuarter.minus({days: 1})
    this.quickDateRanges = [
      {
        display: 'Today',
        fromDate: today.toFormat('yyyy-MM-dd'),
      },
      {
        display: 'Yesterday',
        fromDate: yesterday.toFormat('yyyy-MM-dd'),
      },
      {
        display: 'Week To Date',
        fromDate: startOfWeek.toFormat('yyyy-MM-dd'),
        toDate: yesterday.toFormat('yyyy-MM-dd'),
      },
      {
        display: 'Last Week',
        fromDate: startOfLastWeek.toFormat('yyyy-MM-dd'),
        toDate: endOfLastWeek.toFormat('yyyy-MM-dd'),
      },
      {
        display: 'Month To Date',
        fromDate: startOfMonth.toFormat('yyyy-MM-dd'),
        toDate: yesterday.toFormat('yyyy-MM-dd'),
      },
      {
        display: 'Last Month',
        fromDate: startOfLastMonth.toFormat('yyyy-MM-dd'),
        toDate: endOfLastMonth.toFormat('yyyy-MM-dd'),
      },
      {
        display: 'Quarter To Date',
        fromDate: startOfQuarter.toFormat('yyyy-MM-dd'),
        toDate: yesterday.toFormat('yyyy-MM-dd'),
      },
      {
        display: 'Last Quarter',
        fromDate: startOfLastQuarter.toFormat('yyyy-MM-dd'),
        toDate: endOfLastQuarter.toFormat('yyyy-MM-dd'),
      },
    ]
  }

  ngOnDestroy(): void {
    this.destroy$.next()
  }

  setDateRange(dateRange: DateRange) {
    console.log('Setting Quick Date')
    console.dir(dateRange)
    this.fromDateControl.patchValue(dateRange.fromDate, {emitModelToViewChange: true, emitViewToModelChange: true, emitEvent: true})
    this.toDateControl.patchValue(dateRange.toDate || '', {emitModelToViewChange: true, emitViewToModelChange: true, emitEvent: true})
  }

  download() {
    this.fetch.next(true)
  }
}
