import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from "rxjs";
import { ApiService } from "../../../../services/api.service";
import { StatusService } from "../../../../services/status.service";
import { AutoEnrichmentResponse, AutoEnrichments, CreateEnrichmentRuleRequest, EnrichmentResponse, UpdateEnrichmentRuleRequest } from "../models/md-enrichment-rule-model";

@Injectable({
    providedIn: 'root',
})

export class ManualDraftingAdminEnrichmentRuleService {
    private reload$ = new BehaviorSubject(false);
    private BASEPATH = '/md-enrich'

  public autoTransformSuccesResponseSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public autoTransformFailureResponseSubject: BehaviorSubject<AutoEnrichmentResponse> = new BehaviorSubject<AutoEnrichmentResponse>(undefined);
    constructor(
        private api: ApiService,
        private statusService: StatusService,
        private toast: ToastrService,

    ) { }

    private reload() {
        this.reload$.next(true)
    }

    async getAllEnrichmentRule() {
        const id = this.statusService.start('Loading Enrichment Rule List');
        const getEnrichmentRuleResponse = this.api.get<EnrichmentResponse>(`${this.BASEPATH}/enrichments`).toPromise()
            .then(res => {
                if(res.status === 'ERROR') {
                    console.log(res.body)
                }
                return res;
            }).finally(() => {
                this.statusService.complete(id);
                this.reload()
            })
        return getEnrichmentRuleResponse;
    }

    async createNewEnrichmentRule(description: string, inbound: string, outbound: string, deleted?: boolean) {
        const id = this.statusService.start('creating new rule.....')
        const request: CreateEnrichmentRuleRequest = {
            description,
            inbound,
            outbound
        }
        return await this.api.post<CreateEnrichmentRuleRequest, EnrichmentResponse>(`${this.BASEPATH}/enriched/rule`, request, {
            headers: {},

        }).toPromise()
            .then(res => {
                if(res.status === 'ERROR') {
                    console.log(res.body)
                }
                return res as EnrichmentResponse
            }).finally(() => {
                this.statusService.complete(id)
                this.reload()
            })
    }

    async updateEnrichmentRule(reference: string, description: string, inbound: string, outbound: string) {
        const id = this.statusService.start(`updating rule ${reference}.....`)
        const request: UpdateEnrichmentRuleRequest = {
            id: reference,
            description,
            inbound,
            outbound,
        }
        return await this.api.post<UpdateEnrichmentRuleRequest, EnrichmentResponse>(`${this.BASEPATH}/enriched/rule/${reference}`, request, {
            headers: {},

        }).toPromise()
            .then(res => {
                if(res.status === 'ERROR') {
                    console.log(res.body)
                }
                return res as EnrichmentResponse
            }).finally(() => {
                this.statusService.complete(id)
                this.reload()
            })
    }

    async softDeleteRule(id: string) {

        const identification = this.statusService.start(`Deleting rule ${id}.....`)
        return await this.api.delete<EnrichmentResponse>(`${this.BASEPATH}/enriched/rule/${id}`).toPromise()
            .then(res => {
                if(res.status === 'ERROR') {
                    console.log(res.body)
                }
                return res as EnrichmentResponse
            }).finally(() => {
                this.statusService.complete(identification)
                this.reload()
            })
    }

    async restoreRule(id: string) {

        const identification = this.statusService.start(` Restoring rule with Reference ${id} .....`)
        return await this.api.get<EnrichmentResponse>(`${this.BASEPATH}/enriched/rule/restore/${id}`).toPromise()
            .then(res => {
                if(res.status === 'ERROR') {
                    console.log(res.body)
                }
                return res as EnrichmentResponse
            }).finally(() => {
                this.statusService.complete(identification)
                this.reload()
            })
    }

    async autoTransformations(id: string, draft_payload: string, smartdx_payload: string) {

        const identification = this.statusService.start(`updating payload ${id} .....`)
        this.toast.info(`starting auto enrichment of payload ${id}.`)
        const request: AutoEnrichments = {
            id: id,
            draft_payload,
            smartdx_payload
        }
        return await this.api.post<AutoEnrichments, AutoEnrichmentResponse>(`${this.BASEPATH}/auto/enrichments`, request, {
            headers: {},

        }).toPromise()
            .then(res => {
                if (res.status === 'PASSED') {
                    this.toast.info('auto Transformation completed successfully!')
                    this.autoTransformSuccesResponseSubject.next(request.id)
                } else {
                    this.toast.info(`auto Transformation complete with ${res.failedResponses.length} errors shown in the table below.`)
                    this.autoTransformFailureResponseSubject.next(res)
                }
                return res as AutoEnrichmentResponse
            }).finally(() => {
                this.statusService.complete(identification)
                this.reload()
            })
    }
}