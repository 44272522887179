import {Injectable} from '@angular/core'
import {ApiService} from './api.service'

export interface LambdaDurations {
  Timestamp: string
  Average: number
  Minimum: number
  Maximum: number
}

export interface LambdaInvocations {
  Timestamp: string
  Sum: number
}

export interface LambdaMetrics {
  lambdaName: string
  durations: LambdaDurations[]
  invocations: LambdaInvocations[]
}

export interface LambdaMetricsResponse {
  metrics: LambdaMetrics[]
}

@Injectable({
  providedIn: 'root',
})
export class LambdaMetricsService {

  constructor(private api: ApiService) {
  }

  getLambdaMetrics() {
    return this.api.get<LambdaMetricsResponse>('/metrics/Tools')
  }
}
