import {Component, Input, OnInit} from '@angular/core';
import {ReleaseChanges} from '../docgen-release-changes.component';
import {ConfigDetail} from '../../../common/domain/config';
import { DocgenReleaseChangesDetailsComponent } from '../configReleaseChangesDetails/docgen-release-changes-details.component';
import {ReleaseConfigChanges, ReleaseConfigDetails} from '../utils/release-changes';
import {ConfigService} from '../../../services/config.service';
import {saveAs} from 'file-saver';
import { GetConfigFileErrorResponse, GetConfigFileSuccessResponse } from 'src/app/model/config-responses';

@Component({
  selector: 'app-config-release-changes',
  templateUrl: './config-release-changes.component.html',
  styleUrls: ['./config-release-changes.component.scss']
})
export class ConfigReleaseChangesComponent implements OnInit {

  @Input() specifiedConfig: ReleaseChanges[]
  @Input() specifiedConfigName: string

  public lowerEnvironment: string
  specifiedConfigChanges: ReleaseConfigChanges[] = []
  existingConfig: ReleaseChanges
  newConfig: ReleaseChanges
  areChanges: boolean

  constructor(
    private docgenReleaseChanges: DocgenReleaseChangesDetailsComponent,
    private configService: ConfigService
  ) {}

  ngOnInit(): void {
    this.specifiedConfig.map(envConfig => {
      if(envConfig.lowerEnv) {
        this.newConfig = envConfig
      } else {
        this.existingConfig = envConfig
      }
    })
    this.getChanges()
    this.areChanges = this.specifiedConfigChanges.length > 0
  }

  getChanges() {
    const existingConfigDetails = this.existingConfig.config.configs as ConfigDetail[]
    const existingConfigPaths = existingConfigDetails.map(config => config.path)
    const newConfigDetails = this.newConfig.config.configs as ConfigDetail[]
    const newConfigPaths = newConfigDetails.map(config => config.path)

    const newAndUpdatedConfigChanges = newConfigDetails.map(newConfig => {
      const lastUpdatedTimestamp = this.getLastModified(newConfig)
      const configName = this.getFileName(newConfig)
      if (!existingConfigPaths.includes(newConfig.path)) {
        return this.createConfigChangeInfo(configName, `New file Added ${lastUpdatedTimestamp}`, newConfig.content, '')
      }
      else if (existingConfigPaths.includes(newConfig.path)) {
        const index = existingConfigDetails.findIndex(existingConfig => existingConfig.path === newConfig.path);
        if(newConfig.hash !== existingConfigDetails[index].hash) {
          const existingConfigContent = existingConfigDetails[index].content;
          const newConfigContent = newConfig.content;
         return this.createConfigChangeInfo(configName, `File has been modified ${lastUpdatedTimestamp}` , newConfigContent, existingConfigContent)
        }
      }
    }).filter(change => change)

    const deletedConfigChanges = existingConfigDetails.map(config => {
      const configName = this.getFileName(config)
      if(!newConfigPaths.includes(config.path)) {
        return this.createConfigChangeInfo(configName, `File has been deleted`, '', config.content)
      }
    }).filter(change => change)

    this.specifiedConfigChanges = newAndUpdatedConfigChanges.concat(deletedConfigChanges)
  }

  showDetails(configName: string) {
    let existingConfig: ReleaseConfigDetails
    let newConfig: ReleaseConfigDetails
    this.specifiedConfigChanges.map(config => {
      if (config.name === configName) {
        newConfig = config.newConfig
        existingConfig = config.existingConfig

      }
    })
    if (this.specifiedConfigName === 'Configuration') {
      newConfig.config = newConfig.config ? JSON.stringify(JSON.parse(newConfig.config), null, 2) : ''
      existingConfig.config = existingConfig.config ? JSON.stringify(JSON.parse(existingConfig.config), null, 2) : ''
    }
    this.docgenReleaseChanges.showDetails(existingConfig, newConfig)
  }

  createConfigChangeInfo(name: string, change: string, newConfig: string, existingConfig: string) {
      return {
        name: name,
        change: change,
        newConfig: {
          environmentName: this.newConfig.environment,
          config:  newConfig,
        },
        existingConfig: {
          environmentName: this.existingConfig.environment,
          config: existingConfig,
        }

      } as ReleaseConfigChanges
  }

  getFileName(config: ConfigDetail) {
    let configName = config.name
    if (this.specifiedConfigName === 'Configuration') {
      configName = config.path.split('settings/').slice(1).join('.')
    }
    return configName.split('.').slice(0, -1).join('.');
  }

  getLastModified(config: ConfigDetail): string {
    if (this.specifiedConfigName === 'Ruleset') {
      return 'at ' + JSON.parse(config.content)['lastUpdated'] + ' (UTC)'
    }
    return ''
  }

  downloadTemplates(currentTemplate: ReleaseConfigChanges) {
    const currentTemplateInEnvs = [currentTemplate.newConfig, currentTemplate.existingConfig]
    currentTemplateInEnvs.map(template => {
      this.specifiedConfig.map(config => {
        if (config.environment === template.environmentName) {
          const configdetail = config.config.configs as ConfigDetail[]
          configdetail.map(details => {
            if (details.name === currentTemplate.name + '.zip') {
              this.configService.getConfigFile('auto-deploy-templates', config.config.version.version, 'config/' + details.path).subscribe(file => {
                if((file as GetConfigFileErrorResponse).error) {
                  console.error(`Unable to get config blob: ${(file as GetConfigFileErrorResponse).error}`);
                  return;
                }

                this.configService.getConfigBlob('templates', (file as GetConfigFileSuccessResponse).blobId).subscribe(configBlob => {
                  const buffer = this._base64ToArrayBuffer(configBlob.data)
                  const blob = new Blob([buffer], {type: 'application/zip'});
                  const envName = template.environmentName
                  saveAs(blob, envName.toUpperCase() + '-' + currentTemplate.name + '.zip');
                })
              })
            }
          })
        }
      })
    })
  }

  _base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

}
