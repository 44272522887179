import {Component, Inject} from '@angular/core'
import {Region} from '../services/environment-service'
import {Environment} from '../services/smart-dx-admin.service'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

export interface SmartDxRestartDetails {
  jiraId: string
  jiraDescription: string
  env: Environment
  region: Region
  alternativeTitle?: string
}


@Component({
  templateUrl: 'smart-dx-restart-dialog.component.html',
  styleUrls: ['./smart-dx-restart-dialog.component.scss'],
})
export class SmartDxRestartDialogComponent {

  constructor(public dialogRef: MatDialogRef<SmartDxRestartDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: SmartDxRestartDetails) {
  }

  cancel() {
    this.dialogRef.close(undefined)
  }
}
