<div>
  <mat-form-field>
    <input
      matInput
      placeholder="Filter"
      (keyup)="setFilter($event.target.value)"
    />
  </mat-form-field>
</div>

<div class="archive-operations">
  <div>
    <button
      *ngIf="checkedTests.length > 0"
      mat-button
      mat-raised-button
      color="warn"
      class="checked"
      (click)="addTestToSelected(undefined, checkedTests)"
    >
      Move {{ checkedTests.length }} Tests to Selected Tests
    </button>
    <button
      *ngIf="canRestore"
      mat-button
      mat-raised-button
      color="warn"
      class="restore"
      (click)="restoreFromArchive(selectedTests)"
    >
      Restore {{ selectedTests.length }} Tests From Archive
    </button>
  </div>
  <div class="checked-header">
    <mat-checkbox
      labelPosition="before"
      [checked]="allChecked"
      (change)="checkOrUncheckAll($event.checked)"
      >Select all archived tests (
      {{
        filterOn.trigger
          ? filterOn.filterTests.length
          : condensedArchivedTests.length
      }}
      )</mat-checkbox
    >
  </div>
</div>

<div fxLayout="row" fxLayoutAlign="space-between">
  <div fxFlex class="tests-container">
    <div class="tests-header">Archived Tests</div>
    <div id="archivedTests" class="tests-list">
      <div *ngFor="let test of condensedArchivedTests">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between start"
          fxLayoutGap="6px"
          fxFlexFill
          *ngIf="test.visible"
          class="test"
        >
          <mat-checkbox
            [checked]="test.checked"
            (change)="onEventChange(test, $event.checked)"
          ></mat-checkbox>
          <div class="test-name">{{ test.name }}</div>
          <div class="select-test">
            <a (click)="addTestToSelected(test)">
              <fa-icon icon="chevron-circle-right"></fa-icon
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxFlex class="tests-container">
    <div class="tests-header">Selected</div>
    <div id="selectedTests" class="tests-list">
      <div *ngFor="let test of selectedTests">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between start"
          fxLayoutGap="6px"
          fxFlexFill
          *ngIf="test.visible"
          class="test"
        >
          <div class="remove-test">
            <a (click)="removeTestFromSelected(test)"
              ><fa-icon icon="chevron-circle-left"></fa-icon
            ></a>
          </div>
          <div class="test-name">{{ test.name }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- </div> -->
