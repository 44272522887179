import {Component, OnDestroy, OnInit} from '@angular/core'
import {Environment, EnvironmentService, Region, REGIONS} from '../services/environment-service'
import {Subscription} from 'rxjs'
import {MatSelectChange} from '@angular/material/select'

@Component({
  selector: 'app-target-environment-selector',
  templateUrl: './target-environment-selector.component.html',
  styleUrls: ['./target-environment-selector.component.scss'],
})
export class TargetEnvironmentSelectorComponent implements OnInit, OnDestroy {
  subs = new Subscription()

  environments: Environment[]
  regions = [REGIONS.London]

  selectedEnvironment: Environment
  selectedRegion: Region

  constructor(private environmentService: EnvironmentService) {
    this.environments = environmentService.availableTargetEnvironments
  }

  selectEnvironment(event: MatSelectChange) {
    this.environmentService.selectTargetEnvironment(event.value)
  }

  selectRegion(event: MatSelectChange) {
    this.environmentService.selectTargetRegion(event.value)
  }

  ngOnInit(): void {
    this.subs.add(
      this.environmentService.targetEnvironment.subscribe(env => {
        this.selectedEnvironment = env
        this.regions = env.regions
      }),
    )
    this.subs.add(
      this.environmentService.region.subscribe(region => {
        this.selectedRegion = region
      }),
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
