import {Component, Input} from '@angular/core'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'

export interface RepositoryAndName {
  repository: string
  type: string
  name: string
}

@Component({
  selector: 'app-repository-and-name-dialog',
  templateUrl: 'repository-and-name-dialog.component.html',
  styleUrls: ['./repository-and-name-dialog.component.scss'],
})
export class RepositoryAndNameDialogComponent {
  @Input() title: string
  @Input() name: string
  @Input() type: string

  repository = 'lambda-ingest-product'

  constructor(
    public activeModel: NgbActiveModal) {
    if (this.type === 'samples') {
      this.repository = 'auto-deploy-templates'
    } else if (this.repository === 'auto-deploy-templates') {
      this.repository = 'lambda-ingest-product'
    }
  }

  cancel() {
    this.activeModel.dismiss(undefined)
  }

  confirm() {
    this.activeModel.close({
      repository: this.type === 'samples' ? 'auto-deploy-templates' : this.repository,
      type: this.type,
      name: this.name,
    })
  }
}
