import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'
import {TestManagerService} from '../../services/test-manager.service'
import {Subscription} from 'rxjs'
import {TestSuiteMetadata} from '../../common/domain/tests'

@Component({
  selector: 'app-test-suite-group-edit-dialog',
  templateUrl: 'test-suite-group-edit-dialog.component.html',
  styleUrls: ['./test-suite-group-edit-dialog.component.scss'],
})
export class TestSuiteGroupEditDialogComponent implements OnInit, OnDestroy {
  subs = new Subscription()

  @Input() title: string
  @Input() name: string
  @Input() selectedTestSuites: string[] = []

  testSuites: TestSuiteMetadata[]

  constructor(
    public activeModel: NgbActiveModal,
    private testManagerService: TestManagerService,
  ) {
  }

  ngOnInit(): void {
    this.subs.add(
      this.testManagerService.testSuites.subscribe(results => {
        this.testSuites = results
      }),
    )
  }

  cancel() {
    this.activeModel.dismiss(undefined)
  }

  confirm() {
    this.activeModel.close({
      name: this.name,
      testSuites: this.selectedTestSuites,
    })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }
}
