import {Component, Inject} from '@angular/core'
import {FormControl} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import { jsonataEditorOptions, preProcessorEditorOptions } from 'src/app/util/editor-configurations'

export type ChartMetadata = {
  smartDXXMLContentPath: string
  chartDataJSONPath: string
  style: string | null
  customStyleSettings?: string
}

export interface EditChartOptionsDetails {
  useLegacyMode: boolean 
  useChartRendering: boolean
  chartMetadata: ChartMetadata[]
  configMap: string
}

@Component({
  templateUrl: 'edit-chart-options-dialog.component.html',
  styleUrls: ['./edit-chart-options-dialog.component.scss'],
})
export class EditChartOptionsDialogComponent {

  useChartRendering: string
  useLegacyMode: string
  chartMetadata: string
  configMap: string
  options = jsonataEditorOptions

  ngOnInit() {
  }

  constructor(
    public dialogRef: MatDialogRef<EditChartOptionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditChartOptionsDetails
  ) {
    this.useChartRendering = typeof data.useChartRendering === 'boolean' 
      ? data.useChartRendering.toString() 
      : data.useChartRendering

    this.useLegacyMode = typeof data.useLegacyMode === 'boolean' 
      ? data.useLegacyMode.toString() 
      : data.useLegacyMode

    this.chartMetadata = JSON.stringify(data.chartMetadata, null, 2)

    this.configMap = data.configMap
  }

  cancel() {
    this.dialogRef.close(undefined)
  }

  ok() {
    this.data.useChartRendering = this.useChartRendering === 'true'
    this.data.useLegacyMode = this.useLegacyMode === 'true'
    this.data.chartMetadata = JSON.parse(this.chartMetadata)
    this.dialogRef.close(this.data)
  }

  getTypeOf(value: any){
    return typeof value
  }


}
