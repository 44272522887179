import {Component, OnDestroy, OnInit} from '@angular/core';
import {PermissionsService} from '../../services/permissions.service';
import {RegxchangeAdminService} from '../../services/regxchange-admin.service';
import {Subscription} from 'rxjs';
import {jsonEditorOptions} from '../../util/editor-configurations';
import {Environment, ENVIRONMENTS, EnvironmentService} from '../../services/environment-service';
import {ToastrService} from 'ngx-toastr';
import { ApiSelectorOption } from 'src/app/model/test-management-requests';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-admin-regxchange-get',
  templateUrl: './admin-regxchange-get.component.html',
  styleUrls: ['./admin-regxchange-get.component.scss']
})
export class AdminRegxchangeGetComponent implements OnInit, OnDestroy {

  constructor(private permissionsService: PermissionsService,
              private regxchangeAdminService: RegxchangeAdminService,
              private environmentService: EnvironmentService,
              private toast: ToastrService) { }

  private subscriptions = new Subscription()
  public payloadEditorConfig = jsonEditorOptions;
  public payloadValueBody = '';
  public hasRegxchangeCSPRODSupportPermission: boolean
  public hasRegxchangeCSUATSupportPermission: boolean
  public validPayload = true // Todo check valid payload
  public globalIdentifier = ''
  public regxchangeSchema = ''
  private sourceEnvironment: Environment

  regxchangeApiOptions: ApiSelectorOption[] = [{
    id: 'EMT',
    display: 'EMT (default)',
    description: 'EMT'
  },
  {
    id: 'EET',
    display: 'EET',
    description: 'EET'
  }]

  public selectedRegxchangeApiOption = this.regxchangeApiOptions[0];

  selectRegxApi(event: MatSelectChange) {
    this.selectedRegxchangeApiOption = event.value;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.permissionsService.regxchangeCSPRODSupport.subscribe(permission => this.hasRegxchangeCSPRODSupportPermission = permission),
    )
    this.subscriptions.add(
      this.permissionsService.regxchangeCSUATSupport.subscribe(permission => this.hasRegxchangeCSUATSupportPermission = permission),
    )
    this.subscriptions.add(
      this.environmentService.sourceEnvironment.subscribe(env => this.sourceEnvironment = env)
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  getFromRegxchange() {
    if (this.sourceEnvironment === ENVIRONMENTS.PROD ) {
      if (!this.hasRegxchangeCSPRODSupportPermission) {
        this.toast.warning('This action requires regxchange production support permissions')
        return
      }
    }
    if (this.sourceEnvironment === ENVIRONMENTS.UAT ) {
      if (!this.hasRegxchangeCSUATSupportPermission) {
        this.toast.warning('This action requires regxchange UAT support permissions')
        return
      }
    }

    this.regxchangeAdminService.getPayloadFromRegxchange(this.globalIdentifier, this.regxchangeSchema, this.selectedRegxchangeApiOption.id).subscribe((response: string) => {
      const responseObj = (typeof response === 'string') ? JSON.parse(response) : response
      this.payloadValueBody = responseObj['data'] ? JSON.stringify(responseObj['data'], undefined, 2) :
        JSON.stringify(responseObj, undefined, 2);
    })
  }

  refresh() {}

}
