import { Component, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ToastrService } from 'ngx-toastr';
import { of, Subscription } from 'rxjs';
import { RenderedDocument } from 'src/app/common/domain/render';
import { DocAutoParams, DocumentFormat, PriipCloudParams, WsdServiceType } from 'src/app/common/domain/wallstreetdocs';
import { ChangeService } from 'src/app/services/change.service';
import { EnvironmentService } from 'src/app/services/environment-service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { WsdAdminPostService } from 'src/app/services/wsd-admin-post.service';
import { jsonViewerOptions, xmlEditorOptions } from '../../util/editor-configurations';
import saveAs from 'file-saver';

const base64js = require('base64-js')

interface ServiceTypeOption {
  id: WsdServiceType;
  display: string;
  description: string;
}

@Component({
  selector: 'app-admin-wsd-manual-post',
  templateUrl: './admin-wsd-manual-post.component.html',
  styleUrls: ['./admin-wsd-manual-post.component.scss']
})

export class AdminWsdManualPostComponent implements OnInit {

  constructor(
    private wsdAdminPostService: WsdAdminPostService,
    private permissionsService: PermissionsService,
    private environmentService: EnvironmentService,
    private changeService: ChangeService,
    private toast: ToastrService
  ) { }

  private subscriptions = new Subscription();

  serviceTypes: ServiceTypeOption[] = [{
    id: 'PRIIP_CLOUD',
    display: 'PRIIP CLOUD',
    description: 'PRIIP_CLOUD service type'
  },
  {
    id: 'DOC_AUTO',
    display: 'DOC AUTO',
    description: 'DOC_AUTO service type'
  }]

  selectedServiceType: ServiceTypeOption = {
    id: undefined,
    display: undefined,
    description: undefined
  }

  payloadValueBody: string = '';

  priipAuthUrl: string;
  priipDocUrl: string;

  docAutoDomain: string;
  docAutoDealUrl: string;
  docAutoGenUrl: string;
  docAutoStructureId: string;
  docAutoTermsheetId: string;
  docAutoFinalTermsId: string;
  configKey: string;

  payloadEditorConfig = xmlEditorOptions
  responseViewerConfig = jsonViewerOptions

  public responseBody: string;
  public pdfDocument: string;
  public wordDocument: string;
  public requesting: boolean = false;

  public hasWallStreetDocsPermission: boolean = false;

  public selectedConfig;
  public environment;
  public region;

  selectServiceType(event: MatSelectChange) {
    this.selectedServiceType = event.value;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.permissionsService.canViewWallStreetDocsPostRequest.subscribe(
        permission => this.hasWallStreetDocsPermission = permission
      ),
    )

    this.subscriptions.add(this.changeService.selectedDocgenConfig.subscribe(config => {
      this.selectedConfig = config;
    }));

    this.subscriptions.add(this.environmentService.sourceEnvironment.subscribe(env => {
      this.environment = env;
    }));

    this.subscriptions.add(this.environmentService.region.subscribe(region => {
      this.region = region;
    }));
  }

  getCustomParams(): PriipCloudParams | DocAutoParams {
    if (this.selectedServiceType.id === 'DOC_AUTO') {
      return {
        docAutoFinalTermsId: this.docAutoFinalTermsId,
        docAutoTermsheetId: this.docAutoTermsheetId,
        docAutoStructureId: this.docAutoStructureId,
      }
    } else {
      return {
        priipAuthUrl: this.priipAuthUrl,
        priipDocUrl: this.priipDocUrl
      }
    }
  }

  postToWallStreetDocs(format: DocumentFormat) {
    if (!this.selectedConfig || !this.environment || !this.region) {
      this.toast.error('Please select the configuration');
      return;
    }

    this.resetResponse();
    this.requesting = true;

    this.wsdAdminPostService.postPayloadToWallStreetDocs(
      this.payloadValueBody,
      this.selectedServiceType.id,
      this.getCustomParams(),
      this.selectedConfig,
      this.environment,
      this.region,
      format
    ).subscribe(response => {
      const responseObj: RenderedDocument = (typeof response === 'string') ? JSON.parse(response) : response
      if (responseObj.result === 'OK') {
        this.responseBody = responseObj['data'] || '';

        if (responseObj.mimeType === "application/pdf")
          this.pdfDocument = base64js.toByteArray(responseObj.data);

        if (responseObj.mimeType === "application/msword")
          this.wordDocument = base64js.toByteArray(responseObj.data);
      }

      this.responseBody = JSON.stringify(responseObj, undefined, 2);
      this.requesting = false;
    })
  }

  async downloadDoc() {
    const blob = new Blob([this.wordDocument], {type: 'application/msword'})
    const filename = 'stormDocument.doc'
    saveAs(blob, filename)
  }

  resetResponse() {
    this.responseBody = undefined;
    this.pdfDocument = undefined;
    this.wordDocument = undefined;
  }
}