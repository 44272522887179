<div class="containerGrid" [class.notes]="notes">
  <div *ngIf="config" class="configContainer">
    <div class="titleContainer">
      <h3 class="m-0 p-0">{{ config.name }}</h3>
    </div>
    <div class="editorContainer">
      <ngx-monaco-editor
        class="monaco-editor"
        [options]="editorOptions"
        [(ngModel)]="editorValue"
        (ngModelChange)="getLineCount('edit')"
      >
      </ngx-monaco-editor>
    </div>
    <div class="btnContainer">
      <button type="button" class="btn btn-warning me-2" (click)="cancel()">
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="save(config, editorValue)"
      >
        Save</button
      ><br />
      <h6 *ngIf="this.originalLineCount" class="orignal">
        Original line Count: {{ originalLineCount }}
      </h6>
      <h6
        *ngIf="
          this.newLinesCount !== this.originalLineCount && this.newLinesCount
        "
        class="modified"
      >
        Modified lines Count: {{ newLinesCount }}
      </h6>
    </div>
  </div>
  <div *ngIf="notes" class="notesContainer">
    <h3>{{ notes.name }}</h3>

    <div>
      <ngx-monaco-editor
        class="notes-editor"
        [options]="notes.editorOptions"
        [(ngModel)]="notes.editorValue"
      >
      </ngx-monaco-editor>
    </div>
  </div>
</div>
