import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {
  CreatePayloadRequest, GetPayloadsResponse,
  MdPayload,
  PayloadResponse,
  UpdatePayloadRequest, WordDocResponse
} from "../models/md-payload-model";
import {ApiService} from "../../../../services/api.service";
import {StatusService} from "../../../../services/status.service";
import {ToastrService} from "ngx-toastr";

@Injectable({
  providedIn: 'root',
})

export class ManualDraftingAdminPayloadService {
  private reload$ = new BehaviorSubject(false);
  private BASEPATH = '/md-payload'

  constructor(
    private api: ApiService,
    private statusService: StatusService,
    private toast: ToastrService,
  ) { }

  getWordDoc(id) {
    return this.api.get<WordDocResponse>(`${this.BASEPATH}/download/${id}`)
  }

  async getPayloadsLists () {
        const id = this.statusService.start('Loading payload list.....')

      const getPayloadsResponse = this.api.get<GetPayloadsResponse>(`${this.BASEPATH}/payloads`).toPromise()
        .then(res => {
          return res.body
        }).finally(() => {
          this.statusService.complete(id)
          this.reload()
        })
      return getPayloadsResponse

  }

  private reload() {
    this.reload$.next(true)
  }

  async createNewDraftPayload(draftPayload: string, description: string) {
    const id = this.statusService.start('creating new payload.....')
    const request: CreatePayloadRequest = {
      draft_payload: draftPayload,
      description
    }
    return await this.api.post<CreatePayloadRequest, PayloadResponse>(`${this.BASEPATH}/payload`, request, {
      headers: {},

    }).toPromise()
      .then(res => {
        return res as PayloadResponse
      }).finally(() => {
        this.statusService.complete(id)
        this.reload()

      })
  }

  async softDeletePayload(id: string) {

    const identification = this.statusService.start(`Deleting payload ${id} payload.....`)
    return await this.api.delete<PayloadResponse>(`${this.BASEPATH}/payload/${id}`).toPromise()
      .then(res => {
        return res as PayloadResponse
      }).finally(() => {
        this.statusService.complete(identification)
        this.reload()
      })
  }

  async restorePayload(id: string) {

    const identification = this.statusService.start(` Restoring payload ${id} new draft payload.....`)
    return await this.api.get<PayloadResponse>(`${this.BASEPATH}/payload/restore/${id}`).toPromise()
      .then(res => {
        return res as PayloadResponse
      }).finally(() => {
        this.statusService.complete(identification)
        this.reload()
      })
  }


  async updatePayload(payloadId: string, request: UpdatePayloadRequest) {
    this.toast.info('Saving updated payload.')
    const identification = this.statusService.start(`updating payload with ID ${payloadId}.....`)
    return await this.api.post<UpdatePayloadRequest, PayloadResponse>(`${this.BASEPATH}/payload/${payloadId}`, request).toPromise()
      .then(res => {
        return res as PayloadResponse
      }).finally(() => {
        this.statusService.complete(identification)
        this.reload()

      })
  }
}
