<h2>Document Generation Metrics for the Previous 7 Days</h2>

<div>
  <canvas baseChart width="400" height="100"
          [datasets]="chartData"
          [labels]="chartLabels"
          [options]="chartOptions"
          [colors]="chartColors"
          [legend]="chartLegend"
          [chartType]="chartType"
          [plugins]="chartPlugins">
  </canvas>
</div>

