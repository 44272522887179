import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core'
import {DateTime} from 'luxon'
import {Environment, ENVIRONMENTS, Region} from '../../../services/environment-service'
import {of, Subject} from 'rxjs'
import {debounceTime, distinctUntilChanged, map, mergeMap, switchMap, takeUntil, tap, toArray} from 'rxjs/operators'
import {MatTableDataSource} from '@angular/material/table'
import {ManagementInformationService, MiEvent} from '../../../services/management-information.service'
import {CommonEvent} from '../../../common/domain/events'
// import {stringify} from 'csv/lib/sync'
import {MatPaginator} from '@angular/material/paginator'
import { unparse } from 'papaparse'
import saveAs from 'file-saver'
const equals = require('fast-deep-equal')

const environmentLookup = (account) => [ENVIRONMENTS.TOOLS,ENVIRONMENTS.STAGING, ENVIRONMENTS.UAT, ENVIRONMENTS.PROD].filter(env => !!env).find((v) => v && (v.account === account))

interface JiraMiEvent extends MiEvent {
  source: 'inspire.raise-jira-ticket'
  detail: {
    issueType: {
      name: string
      code: string
    },
    event?: CommonEvent,
    jira: {
      id: string
      key: string
      self: string
    }
  }
}

interface FlattenedJiraMiEvent {
  timestamp: string
  date: string
  time: string
  environment: string
  region: string
  status: string
  issue: string
  jira: string
  sparrowRef: string
  sparrowTicket: string
  config: string
  missingWhitelist: string
  blacklist: string
  notSupportedYet: string
  jiraUrl: string
  // sparrowHost: string
  sparrowTicketUrl: string
  sparrowProductUrl: string
}

interface Request {
  environment: Environment
  fromDate: string
  toDate: string
}

@Component({
  selector: 'app-jira-management-information',
  templateUrl: './jira-management-information.component.html',
  styleUrls: ['./jira-management-information.component.scss'],
})
export class JiraManagementInformationComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  loading = true
  cancelRequests = new Subject<void>()

  @Input() environment: Environment
  @Input() fromDate: string
  @Input() toDate: string

  results: FlattenedJiraMiEvent[] = []
  columns: string[] = [
    'date',
    'time',
    'environment',
    'region',
    'status',
    'jira',
    'issue',
    'sparrowRef',
    'sparrowTicket',
    'config',
    'missingWhitelist',
    'blacklist',
    'notSupportedYet',
  ]
  dataSource = new MatTableDataSource<FlattenedJiraMiEvent>()
  requests = new Subject<Request>()

  constructor(private managementInformationService: ManagementInformationService) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    // this.loading = true
    this.requests
      .pipe(
        takeUntil(this.cancelRequests),
        debounceTime(1000),
        distinctUntilChanged(equals),
        tap(_ => this.loading = true),
        tap(request => {
          console.log('Processing: ')
          console.dir(request)
        }),
        switchMap((request: Request) => {
          if (request.environment && request.fromDate) {
            return of(...request.environment.regions)
              .pipe(
                mergeMap((region: Region) => {
                  console.log('Fetching data for ' + region.display)
                  const dateRange = request.fromDate + (request.toDate ? '/' + request.toDate : '')
                  return this.managementInformationService.getManagementInformation<JiraMiEvent[]>(
                    request.environment.id,
                    region.id,
                    'raise-jira-ticket',
                    dateRange)
                    .pipe(
                      tap(results => {
                        console.log('Fetched Mi Data For ' + request.environment.display + ' in ' + region.display)
                        console.dir(results)
                      }),
                    )
                }),
                toArray(),
                tap((results: JiraMiEvent[][]) => {
                  console.log('Raw Mi Data')
                  console.dir(results)
                }),
                map((events: JiraMiEvent[][]): JiraMiEvent[] => {
                  return events.reduce((r, a) => r.concat(...a), <JiraMiEvent[]> [])
                  // fileName: `${request.report}-${request.environment.display}-${request.fromDate}${request.toDate ? '-' + request.toDate : ''}.csv`
                  // }
                }),
                tap((results: any[]) => {
                  console.log('Combined Mi Data')
                  console.dir(results)
                }),
                tap(_ => this.loading = false),
              )

          } else {
            return []
          }
        }),
      )
      .subscribe((events: JiraMiEvent[]) => {
        const flattened = events.map(e => this.format(e))
        flattened.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
        this.results = flattened
        this.dataSource.data = this.results
      })
    this.ngOnChanges(undefined)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.requests.next({
      environment: this.environment,
      fromDate: this.fromDate,
      toDate: this.toDate,
    })
  }

  ngOnDestroy(): void {
    this.cancelRequests.next()
  }

  format(e: JiraMiEvent): FlattenedJiraMiEvent {
    const environment = environmentLookup(e.account).display
    const region = e.region === 'eu-central-1' ? 'Frankfurt' : 'London'
    const detail = e.detail
    const request = detail.event
    const jiraKey = detail.jira && detail.jira.key
    const sparrowRef = request && request.productIdentifier
    const sparrowTicket = request && request.requestId

    const sparrowHost = environment === 'PROD' ? 'sparrow.app.hedani.net' : environment === 'UAT' ? 'sparrow-uat.app.hedani.net' : 'sparrow-dev.app.hedani.net'
    const jiraUrl = jiraKey ? 'https://deltacapitams.atlassian.net/browse/' + jiraKey : ''
    const sparrowTicketUrl = sparrowTicket ? `https://${sparrowHost}/spw/pages/ticket/viewTicket?ref=${sparrowTicket}` : ''
    const sparrowProductUrl = sparrowRef ? `https://${sparrowHost}/product/summary.action?ref=${sparrowRef}` : ''
    const errorResponse = request?.errorResponse?.errors || []
    const missingWhitelist = errorResponse.filter(e => e.type === 'WHITELIST').map(e => e.code).join(', ')
    const blacklist = errorResponse.filter(e => e.type === 'BLACKLIST').map(e => e.code).join(', ')
    const notSupportedYet = errorResponse.filter(e => e.type === 'NOT_SUPPORTED').map(e => e.code).join(', ')

    return {
      timestamp: e.time,
      date: DateTime.fromISO(e.time).toFormat('dd/MM/yyyy'),
      time: DateTime.fromISO(e.time).toFormat('HH:mm:ss'),
      environment: environment,
      region: region,
      status: e['detail-type'],
      issue: detail.issueType && detail.issueType.name,
      jira: jiraKey,
      sparrowRef: sparrowRef,
      sparrowTicket: sparrowTicket,
      config: request && request.configPrefix,
      missingWhitelist: missingWhitelist,
      blacklist: blacklist,
      notSupportedYet: notSupportedYet,
      jiraUrl: jiraUrl,
      sparrowTicketUrl: sparrowTicketUrl,
      sparrowProductUrl: sparrowProductUrl,
    }
  }

  downloadCsv() {
    const csv = unparse(this.results, {
      header: true,
    })
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
    const filename = ('mi-issues-' + this.environment.display.toLowerCase() + '-' + this.fromDate + (this.toDate ? '-to-' + this.toDate : '') + '.csv')
    console.log('Saving: ' + filename)
    saveAs(blob, filename)
  }
}
