import {Component, OnDestroy, OnInit} from '@angular/core'
import {ChangeService} from '../../services/change.service'
import {PublishConfig} from '../../common/domain/publish-config'
import {BehaviorSubject, combineLatest, of, Subject, Subscription} from 'rxjs'
import {debounceTime, distinctUntilChanged, filter, map, mergeMap, switchMap, take, takeWhile, tap, toArray} from 'rxjs/operators'
import {ApiService} from '../../services/api.service';

import Ajv from 'ajv'
import jsonata from 'jsonata'
import deepEqual from 'deep-equal'
import {PublishPayloadService} from '../../services/publish-payload.service'
import {PublishPayloadRequest} from '../../model/publish-payload-request'
import {PublishConfigAndMetadataChangeDetails} from '../../common/domain/change'
import {ConfigService} from '../../services/config.service'
import {IngestProductPublishTraceResponse} from '../../model/public-api-response'
import {PayloadInformation, PublishPayloadInformation, TestDefinition} from '../../common/domain/tests'
import {SaveTestDialogComponent, SaveTestDialogData} from '../../dialogs/save-test-dialog.component'
import {MatDialog} from '@angular/material/dialog'
import {ToastrService} from 'ngx-toastr'
import {TestManagerService} from '../../services/test-manager.service'
import {ActivatedRoute, Router} from '@angular/router'
import {SavedTestMetadata, TestHistory} from '../../model/test-management-responses'
import {Environment, ENVIRONMENTS, EnvironmentService} from '../../services/environment-service'
import deepmerge from 'deepmerge'
import {
  jsonataEditorOptions,
  jsonDiffViewerOptions,
  jsonEditorOptions,
  jsonSchemaEditorOptions,
  jsonViewerOptions,
  xmlEditorOptions,
  xmlViewerOptions,
} from '../../util/editor-configurations'
import {DiffEditorModel} from 'ngx-monaco-editor'
import {StageResult} from '../../common/domain/lambdas'
// import {stringify} from 'csv/lib/sync'
import {saveAs} from 'file-saver'
import {ClipboardService} from 'ngx-clipboard'
import { PublishResult } from 'src/app/common/domain/publish'
import { jsonToXml } from 'src/app/util/publish-util'
import { DocumentGeneratorComponent } from '../document-generator.component'
import { SourceFormat } from 'src/app/common/domain/ingest'
import { unparse } from 'papaparse'
const xmlParser = require('fast-xml-parser')

const validator = new Ajv({schemaId: 'auto'})
validator.addMetaSchema(require('ajv/lib/refs/json-schema-draft-06.json'))
validator.addMetaSchema(require('ajv/lib/refs/json-schema-draft-04.json'))

const mergeArrayFn = (target: any[], source: any[]): any[] => {
  if (target.every(t => hasGlobalIdentifier(t)) && source.every(s => hasGlobalIdentifier(s))) {
    const res: any[] = []
    for (const t of target) {
      const toUpdate = source.find(s => hasGlobalIdentifier(s) && getGlobalIdentifier(s) === getGlobalIdentifier(t))
      if (toUpdate) {
        const merged = deepmerge(t, toUpdate, {
          arrayMerge: mergeArrayFn,
          clone: true,
        })
        res.push(merged)
      } else {
        res.push(t)
      }
    }
    for (const s of source) {
      if (s && hasGlobalIdentifier(s)) {
        const alreadyMerged = res.find(r => hasGlobalIdentifier(r) && getGlobalIdentifier(r) === getGlobalIdentifier(s))
        if (!alreadyMerged) {
          res.push(s)
        }
      }
    }
    return res

  } else {
    return source && source.length > 0 ? source : target
  }
}

const hasGlobalIdentifier = (payload) => {
  return payload.hasOwnProperty('globalIdentifier') || (payload.hasOwnProperty('properties') && payload.properties.hasOwnProperty('globalIdentifier'))
}

const getGlobalIdentifier = (payload) => {
  if (payload['properties'] && payload['properties']['globalIdentifier']) {
    return payload['properties']['globalIdentifier']
  }
  return payload['globalIdentifier']
}

export const merge = (a: object, b: object): object => {
  return deepmerge(a, b, {
    arrayMerge: mergeArrayFn,
    clone: true,
  })
}

const removeEmpty = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') {
      removeEmpty(obj[key])
    } else if (obj[key] === null) {
      delete obj[key]
    }
  })
}

interface ResolvedTest {
  testDefinition?: TestDefinition
  testHistory?: TestHistory[]
}

export interface PublishOutcome extends IngestProductPublishTraceResponse {
  difference: StageResult
}

@Component({
  selector: 'app-publish',
  templateUrl: './publish.component.html',
  styleUrls: ['./publish.component.scss'],
})
export class PublishComponent implements OnInit, OnDestroy {

  constructor(
    private configService: ConfigService,
    private changeService: ChangeService,
    private publishPayloadService: PublishPayloadService,
    private testManager: TestManagerService,
    private environmentService: EnvironmentService,
    private dialog: MatDialog,
    private toast: ToastrService,
    private clipboardService: ClipboardService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService
  ) {
    this.activatedRoute.data
      .pipe(
        distinctUntilChanged(deepEqual),
      )
      .subscribe((data: ResolvedTest | undefined) => {
        console.dir(data)
        const test = data?.testDefinition
        if (test?.product?.service === 'publish') {
          this.testDefinition = test
          this.loadedVersion = undefined
          this.testHistory = data?.testHistory
          this.testManager.selectTest(test.name, 'Publish route update')
          this.configService.selectPublishConfig(test.product as PublishPayloadInformation)
          this.payloadValue = test.payload
          this.retrieveStubValue = test.stubbedRetrieve
          this.publishExpectationValue = test.expectedPublish
          this.$refresh.next()
        }
      })
  }
  private sub = new Subscription()

  publishing = false

  $refresh = new Subject<string>()

  csValidation: string = undefined
  transformed: string = undefined
  enriched: string = undefined
  combined: string = undefined
  encapsulated: string = undefined
  targetValidation: string = undefined
  published: string = undefined
  trace: string = undefined

  before: string
  after: string
  targetResponse: string
  errors: string
  postConvertPayload: string

  publishConfig: PublishConfig = undefined
  config: PublishConfigAndMetadataChangeDetails

  refreshGraphic = ''
  testDefinition: TestDefinition

  testHistory: TestHistory[]
  loadedVersion: string = undefined

  payloadEditorOptions = jsonEditorOptions
  retrieveStubEditorOptions = jsonEditorOptions
  publishExpectationEditorOptions = jsonEditorOptions
  schemaEditorOptions = jsonSchemaEditorOptions
  readOnlyJsonOptions = jsonViewerOptions
  readOnlyXmlOptions = xmlViewerOptions
  jsonataOptions = jsonataEditorOptions
  jsonDiffOptions = jsonDiffViewerOptions
  validSource: boolean
  payloadFormat: 'xml' | 'json'

  expectedModel: DiffEditorModel = {
    language: 'application/json',
    code: '',
  }

  publishedModel: DiffEditorModel = {
    language: 'application/json',
    code: '',
  }

  payloadSubject = new BehaviorSubject<string>('')
  payload = this.payloadSubject.pipe(
    debounceTime(250),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Payload Changed: ' + r)
    // }),
  )
  globalIdentifier = this.payload.pipe(
    map(payload => {
        try {
          return JSON.parse(payload).globalIdentifier as string
        } catch (e) {
        }
        return undefined
      },
    ),
    // tap(g => console.log(g)),
  )

  retrieveStubSubject = new BehaviorSubject<string>('')
  retrieveStub = this.retrieveStubSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('CS Schema Changed: ' + r)
    // }),
  )

  publishExpectationSubject = new BehaviorSubject<string>('')
  publishExpectation = this.publishExpectationSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('CS Schema Changed: ' + r)
    // }),
  )

  csSchemaSubject = new BehaviorSubject<string>('')
  csSchema = this.csSchemaSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('CS Schema Changed: ' + r)
    // }),
  )

  transformSubject = new BehaviorSubject<string>('')
  transform = this.transformSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Transform Changed: ' + r)
    // }),
  )

  enrichSubject = new BehaviorSubject<string>('')
  enrich = this.enrichSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Enrich Changed: ' + r)
    // }),
  )

  combineSubject = new BehaviorSubject<string>('')
  combine = this.combineSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Combine Changed: ' + r)
    // }),
  )

  encapsulateSubject = new BehaviorSubject<string>('')
  encapsulate = this.encapsulateSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Encapsulate Changed: ' + r)
    // }),
  )

  targetSchemaSubject = new BehaviorSubject<string>('')
  targetSchema = this.targetSchemaSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Target Schema Changed: ' + r)
    // }),
  )

  formatSubject = new BehaviorSubject<SourceFormat>(undefined)
  format = this.formatSubject.pipe(
    distinctUntilChanged(),
  )

  targetSource = this.environmentService.targetEnvironment
    .pipe(
      switchMap(_ => {
        return this.globalIdentifier
      }),
    )
    .pipe(
      switchMap((globalIdentifier) => {
        if (globalIdentifier && this.config && this.payloadValue) {
          const request: PublishPayloadRequest = new PublishPayloadRequest()
          request.setConfig(this.config)
          request.payload = this.payloadValue
          request.productIdentifier = globalIdentifier

          return this.publishPayloadService.getPublished(request)
        } else {
          return of('{}')
        }
      }),
      tap(x => {
        console.log('Loaded target Source')
        console.dir(x)
      }),
      map(r => {
        return (r && typeof r === 'string') ? JSON.parse(r) : r
      }),
      tap(x => {
        console.log('Processing target Source')
        console.dir(x)
      }),
      map(r => {
        return r.body || r
      }),
      map(r => {
        return (r && typeof r === 'string') ? JSON.parse(r) : r
      }),
      map(r => {
        return r.data || r
      }),
      tap(x => {
        console.log('Processed target Source')
        console.dir(x)
      }),
      map(r => {
        return r.code === 404 ? {} : r
      }),
    )

  payloadValue = ''
  retrieveStubValue = ''
  publishExpectationValue = ''
  csSchemaValue: string
  transformValue: string
  enrichValue: string
  combineValue: string
  encapsulateValue: string
  targetSchemaValue: string
  targetEnvironment: Environment

  canExecuteTest = this.canSaveRegressionTest
  publishQueryParameters: string = undefined
  regxchangeRetrieveSchemaParameter: string = undefined
  regxchangeSubmitSchemaParameter: string = undefined

  ngOnInit(): void {
    this.testManager.setService('publish')
    this.sub.add(
      this.changeService.selectedPublishConfig.subscribe(config => {
        this.config = config
        this.publishQueryParameters = ''
        this.regxchangeRetrieveSchemaParameter = ''
        if (config) {
          const publishConfig: PublishConfig = Object.assign(PublishConfig.create(), JSON.parse(config.settings.content))
          console.log('Loaded publishing config')
          console.dir(publishConfig)
          this.publishQueryParameters = publishConfig.publish.queryParams
          this.regxchangeRetrieveSchemaParameter = publishConfig.publish.regxchangeRetrieveSchema
          this.regxchangeSubmitSchemaParameter = publishConfig.publish.regxchangeSubmitSchema
          if (publishConfig.validatePayload.skip) {
            this.csSchemaValue = ''
          } else {
            const actualSchema = config.schemas.find(s => s.path === publishConfig.validatePayload.schema)
            this.csSchemaValue = actualSchema && actualSchema.content || ''
          }
          if (publishConfig.validateEnriched.skip) {
            this.targetSchemaValue = ''
          } else {
            const actualSchema = config.schemas.find(s => s.path === publishConfig.validateEnriched.schema)
            this.targetSchemaValue = actualSchema && actualSchema.content || ''
          }
          if (publishConfig.transform.skip) {
            this.transformValue = ''
          } else {
            const actualTransform = config.transforms.find(t => t.path === publishConfig.transform.transforms[0])
            this.transformValue = actualTransform && actualTransform.content || ''
          }
          if (publishConfig.enrich.skip) {
            this.enrichValue = ''
          } else {
            const actualTransform = config.transforms.find(t => t.path === publishConfig.enrich.transforms[0])
            this.enrichValue = actualTransform && actualTransform.content || ''
          }
          if (publishConfig.combine.skip) {
            this.combineValue = ''
          } else {
            const actualTransform = publishConfig.combine.transforms?.length > 0 && config.transforms.find(t => t.path === publishConfig.combine.transforms[0])
            this.combineValue = actualTransform && actualTransform.content || ''
          }
          if (publishConfig.encapsulate.skip) {
            this.encapsulateValue = ''
          } else {
            const actualTransform = publishConfig.encapsulate.transforms?.length > 0 && config.transforms.find(t => t.path === publishConfig.encapsulate.transforms[0])
            this.encapsulateValue = actualTransform && actualTransform.content || ''
          }
          this.publishConfig = publishConfig
        } else {
          this.publishConfig = undefined
        }
        this.refresh()
      }),
    )
    this.sub.add(
      this.environmentService.targetEnvironment.subscribe(env => this.targetEnvironment = env)
    )
    this.sub.add(
      this.testManager.selectedTestName.subscribe(testName => {
        if (testName) {
          this.router.navigate(['publish', testName])
        } else {
          this.router.navigate(['publish'])
        }
      }),
    )
    this.sub.add(
      combineLatest([
        this.payload,
        this.csSchema,
        this.transform,
        this.enrich,
        this.combine,
        this.encapsulate,
        this.targetSchema,
      ])
        .pipe(
          debounceTime(250),
          distinctUntilChanged(deepEqual),
        ).subscribe(() => {
        this.trace = undefined
        this.publishing = false
        this.csValidation = undefined
        this.transformed = undefined
        this.enriched = undefined
        this.combined = undefined
        this.encapsulated = undefined
        this.targetValidation = undefined
        this.published = undefined
        this.expectedModel.code = ''
        this.publishedModel.code = ''

        this.refreshGraphic = '' + new Date().valueOf()
        this.$refresh.next('' + new Date().valueOf())
      }),
    )
    this.sub.add(
      combineLatest([
        this.globalIdentifier,
        this.payload,
        this.retrieveStub,
        this.publishExpectation,
        this.csSchema,
        this.transform,
        this.enrich,
        this.targetSource,
        this.combine,
        this.encapsulate,
        this.targetSchema,
        this.$refresh,
      ])
        .pipe(
          distinctUntilChanged(deepEqual),
          switchMap(([
                       globalIdentifier,
                       payload,
                       retrieveStub,
                       publishExpectation,
                       csSchema,
                       transform,
                       enrich,
                       targetSource,
                       combine,
                       encapsulate,
                       targetSchema,
                       refresh,
                     ]) => {
            try {
              const payloadObject = payload && JSON.parse(payload)
              const csSchemaObject = csSchema && JSON.parse(csSchema)
              const schemaType = targetSchema.startsWith('<') && xmlParser.validate(targetSchema) ? 'XML' : 'JSON'
              const targetSchemaObject = {
                format: schemaType,
                content: schemaType === 'XML' ? targetSchema : targetSchema && JSON.parse(targetSchema)
              }

              return of([
                globalIdentifier,
                payloadObject,
                retrieveStub,
                publishExpectation,
                csSchemaObject,
                transform,
                enrich,
                targetSource,
                combine,
                encapsulate,
                targetSchemaObject,
              ])
            } catch (e) {
              return of([
                globalIdentifier,
                undefined,
                undefined,
                undefined,
                undefined,
                transform,
                enrich,
                targetSource,
                combine,
                encapsulate,
                undefined,
              ])
            }
          }),
        )
        .subscribe(([
                      globalIdentifier,
                      payload,
                      retrieveStub,
                      publishExpectation,
                      csSchema,
                      transform,
                      enrich,
                      targetSource,
                      combine,
                      encapsulate,
                      targetSchema,
                    ]) => {
          this.before = undefined
          this.trace = undefined
          this.after = undefined
          this.targetResponse = undefined
          this.errors = undefined
          if (payload) {
            const start = new Date().valueOf()
            const value: PublishOutcome = {
              config: {
                ingest: {
                  supported: this.publishConfig?.ingest?.supported || 'NO',
                },
              },
              validatePayload: {result: 'UNKNOWN'},
              analysePayload: {result: 'SKIPPED'},
              transform: {result: 'UNKNOWN'},
              enrich: {result: 'UNKNOWN'},
              retrieve: {result: 'UNKNOWN'},
              combine: {result: 'UNKNOWN'},
              encapsulate: {result: 'UNKNOWN'},
              validateEnriched: {result: 'UNKNOWN'},
              analyseEnriched: {result: 'SKIPPED'},
              publish: {result: 'UNKNOWN'},
              difference: {result: 'UNKNOWN'},
            } as PublishOutcome

            if (csSchema) {
              try {
                const valid = validator.validate(csSchema, payload)
                if (valid) {
                  this.csValidation = JSON.stringify({status: 'OK'}, undefined, 2)
                  value.validatePayload.result = 'OK'
                } else {
                  this.csValidation = JSON.stringify({
                    status: 'ERROR',
                    errors: validator.errorsText(validator.errors),
                  }, undefined, 2)
                  value.validatePayload.result = 'ERROR'
                }
              } catch (e) {
                this.csValidation = JSON.stringify(e, undefined, 2)
                value.validatePayload.result = 'ERROR'
              }
            } else {
              this.csValidation = ''
            }
            let latest = payload
            if (transform !== '') {
              value.transform.result = 'UNKNOWN'
              value.enrich.result = 'SKIPPED'
              try {
                const transformer = jsonata(transform)
                const transformResult = transformer.evaluate(payload)
                this.transformed = JSON.stringify(transformResult, undefined, 2)
                value.transform.result = 'OK'
                latest = transformResult
              } catch (e) {
                this.transformed = JSON.stringify(e, undefined, 2)
                value.transform.result = 'ERROR'
                latest = undefined
              }
            } else {
              this.transformed = ''
              if (latest) {
                value.transform.result = 'SKIPPED'
              }
            }
            if (enrich !== '' && latest) {
              value.enrich.result = 'UNKNOWN'
              try {
                const transformer = jsonata(enrich)
                const transformResult = transformer.evaluate(latest)
                latest = transformResult
                this.enriched = JSON.stringify(transformResult, undefined, 2)
                value.enrich.result = 'OK'
              } catch (e) {
                this.enriched = JSON.stringify(e, undefined, 2)
                value.enrich.result = 'ERROR'
                latest = undefined
              }
            } else {
              this.enriched = ''
              if (latest) {
                value.enrich.result = 'SKIPPED'
              }
            }
            if (combine !== '' && latest) {
              value.combine.result = 'UNKNOWN'
              try {
                const transformer = jsonata(combine)
                const combineSource = {
                  merged: {},
                  current: {},
                  new: latest,
                }
                try {
                  if (retrieveStub && retrieveStub !== '') {
                    const json = JSON.parse(retrieveStub)
                    combineSource.current = json.data || ( json['priip'] ? json : {} )
                    value.retrieve.result = 'SKIPPED'
                  } else if (typeof targetSource === 'string') {
                    console.log('Parsing: ' + targetSource)
                    const json = JSON.parse(targetSource)
                    const current = json.data || json
                    combineSource.current = current.code === 404 ? {} : current
                    value.retrieve.result = 'OK'
                  } else {
                    combineSource.current = targetSource
                    value.retrieve.result = 'OK'
                  }
                } catch (e) {
                  console.dir(e)
                  value.retrieve.result = 'ERROR'
                  // @ts-ignore
                  value.retrieve.error = e
                }
                if (combineSource.current) {
                  removeEmpty(combineSource.current)
                  combineSource.merged = merge(combineSource.merged, combineSource.current)
                  combineSource.merged = merge(combineSource.merged, latest)
                }

                const transformResult = transformer.evaluate(combineSource)
                latest = transformResult
                this.combined = JSON.stringify(transformResult, undefined, 2)
                value.combine.result = 'OK'
              } catch (e) {
                this.combined = JSON.stringify(e, undefined, 2)
                value.combine.result = 'ERROR'
                latest = undefined
              }
            } else {
              this.combined = ''
              if (latest) {
                value.combine.result = 'SKIPPED'
              }
            }
            if (encapsulate !== '' && latest) {
              value.encapsulate.result = 'UNKNOWN'
              try {
                const transformer = jsonata(encapsulate)
                // console.log('Calling encapsulate with')
                // console.dir(latest)
                const transformResult = transformer.evaluate(latest)
                latest = transformResult
                this.encapsulated = JSON.stringify(transformResult, undefined, 2)
                value.encapsulate.result = 'OK'
              } catch (e) {
                this.encapsulated = JSON.stringify(e, undefined, 2)
                value.encapsulate.result = 'ERROR'
                latest = undefined
              }
            } else {
              this.encapsulated = ''
              if (latest) {
                value.encapsulate.result = 'SKIPPED'
              }
            }

            if (latest) {
              this.published = JSON.stringify(latest, undefined, 2)
              value.publish.result = 'OK'
              value.published = true
            } else {
              this.published = undefined
              value.publish.result = 'SKIPPED'
            }

            if (targetSchema.content && latest) {
              try {
                if(targetSchema.format === 'JSON') {
                  const valid = validator.validate(targetSchema.content, latest)
                  if (valid) {
                    this.targetValidation = JSON.stringify({status: 'OK'}, undefined, 2)
                    value.validateEnriched.result = 'OK'
                    value.publish.result = 'OK'
                  } else {
                    this.targetValidation = JSON.stringify({
                      status: 'ERROR',
                      errors: validator.errorsText(validator.errors),
                    }, undefined, 2)
                    value.validateEnriched.result = 'ERROR'
                    value.publish.result = 'SKIPPED'
                    value.published = false
                  }
                } else if (targetSchema.format === 'XML') {
                  this.schemaEditorOptions = xmlViewerOptions;
                  const latestXml = jsonToXml(JSON.stringify(latest));

                  this.apiService.post('/validate', {
                    xml: latestXml,
                    schema: targetSchema.content
                  }).toPromise()
                    .then(res => {
                      if (res["status"] === 'OK') {
                        this.targetValidation = JSON.stringify({status: 'OK'}, undefined, 2)
                        value.validateEnriched.result = 'OK'
                        value.publish.result = 'OK'
                      } else {
                        this.targetValidation = JSON.stringify({
                          status: 'ERROR',
                          errors: res["errors"]
                        }, undefined, 2)
                        value.validateEnriched.result = 'ERROR'
                        value.publish.result = 'SKIPPED'
                        value.published = false
                      }
                    })
                } else {
                  throw 'Target Schema could not be identified as XML or JSON';
                }
              } catch (e) {
                this.targetValidation = JSON.stringify(e, undefined, 2)
                value.validateEnriched.result = 'ERROR'
                value.publish.result = 'SKIPPED'
                value.published = false
              }
            } else {
              this.targetValidation = undefined
            }
            if (publishExpectation && this.published) {
              console.log('Checking expectations')
              this.expectedModel.code = publishExpectation
              this.publishedModel.code = this.published
              try {
                const expected = JSON.parse(publishExpectation)
                const actual = JSON.parse(this.published)
                if (deepEqual(actual, expected, {strict: true})) {
                  value.difference.result = 'OK'
                } else {
                  value.difference.result = 'ERROR'
                }
              } catch (e) {
                console.log('Comparison Failed')
                console.dir(e)
                value.difference.result = 'ERROR'
              }
            } else {
              this.expectedModel.code = publishExpectation
              this.publishedModel.code = ''
              value.difference.result = 'SKIPPED'
            }
            value.latency = new Date().valueOf() - start
            this.trace = JSON.stringify(value, undefined, 2)
          } else {
            this.csValidation = undefined
            this.transformed = undefined
            this.enriched = undefined
            this.combined = undefined
            this.encapsulated = undefined
            this.targetValidation = undefined
            this.published = undefined
            this.expectedModel.code = ''
            this.publishedModel.code = ''
          }
        }),
    )

    this.sub.add(
      this.format.subscribe(format => {
        this.validSource = format !== undefined
        this.payloadFormat = format
        if (format === 'xml') {
          this.payloadEditorOptions = xmlEditorOptions
        } else {
          this.payloadEditorOptions = jsonEditorOptions
        }
      }),
    )

    this.sub.add(
      this.payload
        .pipe(
          debounceTime(DocumentGeneratorComponent.DEBOUNCE_TIME),
          distinctUntilChanged(),
        )
        .subscribe(payload => {
          if (this.payloadValue !== payload) {
            console.log('Payload has been updated')
            this.payloadValue = payload
          }
          let newFormat: SourceFormat
          try {
            if (payload.startsWith('{')) {
              JSON.parse(payload)
              newFormat = 'json'
            } else if (payload.startsWith('<')) {
              if (xmlParser.validate(payload) === true) {
                newFormat = 'xml'
              }
            }
          } catch (e) {
          }
          this.formatSubject.next(newFormat)
        }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  refresh() {
    this.payloadSubject.next(this.payloadValue || '')
    this.csSchemaSubject.next(this.csSchemaValue || '')
    this.transformSubject.next(this.transformValue || '')
    this.enrichSubject.next(this.enrichValue || '')
    this.combineSubject.next(this.combineValue || '')
    this.encapsulateSubject.next(this.encapsulateValue || '')
    this.targetSchemaSubject.next(this.targetSchemaValue || '')
    this.retrieveStubSubject.next(this.retrieveStubValue || '')
    this.publishExpectationSubject.next(this.publishExpectationValue || '')
  }

  savePayload() {
    const product: PayloadInformation = {
      service: 'publish',
      sourceSystem: this.config.metadata.sourceSystem,
      businessUnit: this.config.metadata.businessUnit,
      payloadType: this.config.metadata.payloadType,
      targetSystem: this.config.metadata.targetSystem,
    }
    const test: TestDefinition = {
      name: '',
      testType: 'Payload',
      payload: this.payloadValue,
      product,
      expectedOutcome: 'Allow',
    } as TestDefinition

    const dialogRef = this.dialog.open(SaveTestDialogComponent, {
      width: '500px',
      data: {
        title: 'Save As Example Payload',
        name: this.testDefinition?.name || 'Publish Example Name',
        isNew: !this.testDefinition,
        comment: '',
      },
      panelClass: 'dialogFormField500',
    })
    dialogRef.afterClosed().subscribe((result: SaveTestDialogData) => {
      if (result) {
        test.name = result.name
        test.comment = result.comment
        this.reallySaveTest(test, result.name)
      }
    })
  }

  saveRegressionTest() {
    const product: PayloadInformation = {
      service: 'publish',
      sourceSystem: this.config.metadata.sourceSystem,
      businessUnit: this.config.metadata.businessUnit,
      payloadType: this.config.metadata.payloadType,
      targetSystem: this.config.metadata.targetSystem,
    }
    const test: TestDefinition = {
      name: '',
      testType: 'Regression',
      payload: this.payloadValue,
      product,
      expectedOutcome: 'Allow',
      stubbedRetrieve: this.retrieveStubValue,
      expectedPublish: this.publishExpectationValue,
    } as TestDefinition

    const dialogRef = this.dialog.open(SaveTestDialogComponent, {
      width: '500px',
      data: {
        title: 'Save As Regression Test',
        name: this.testDefinition?.name || 'Publish Regression Test Name',
        isNew: !this.testDefinition,
        comment: '',
      },
      panelClass: 'dialogFormField500',
    })
    dialogRef.afterClosed().subscribe((result: SaveTestDialogData) => {
      if (result) {
        test.name = result.name
        test.comment = result.comment
        this.reallySaveTest(test, result.name, 'Regression Test')
      }
    })
  }

  private reallySaveTest(test: TestDefinition, name: string, type: string = 'Example Payload'): void {
    console.log('Saving ' + type + ': ' + name)
    this.testManager.saveTest(test)
      .then(res => {
        console.log('Saved ' + type + ': ', res)
        this.toast.success('Saved ' + type + ' ' + name)
      })
  }

  testReset() {
    this.payloadSubject.next('')
  }

  selectTestHistory(history: TestHistory) {
    this.testManager.getTest(this.testDefinition.name, history.version)
      .subscribe((test: TestDefinition) => {
        this.testDefinition = test
        const date = history.date
        this.loadedVersion = date.toLocaleDateString()
        this.toast.info('Loaded version from ' + date.toLocaleDateString())
      })
  }

  canSaveRegressionTest() {
    return this.publishConfig && this.payloadValue !== '' && this.retrieveStubValue !== '' && this.publishExpectationValue !== ''
  }

  publish() {
    this.reallyPublish(false)
  }

  test() {
    this.reallyPublish(true)
  }

  reallyPublish(testMode: boolean) {
    const payload = this.payloadValue
    if (payload && this.config) {
      this.environmentService.targetEnvironment
        .pipe(
          take(1)
        ).subscribe(env => {
        if (env === ENVIRONMENTS.STAGING && this.config.state !== 'original') {
          this.toast.error('Using Private Configuration')
        } else {
          this.toast.info('Using Deployed Configuration')
        }
      })
      // this.tabGroup.selectedIndex = 1
      this.refreshGraphic = '' + new Date().valueOf()
      this.before = undefined
      this.trace = undefined
      this.after = undefined
      this.targetResponse = undefined
      this.errors = undefined
      this.published = undefined
      this.expectedModel.code = ''
      this.publishedModel.code = ''
      this.postConvertPayload = undefined
      this.publishing = true
      const expectedResponse = this.publishExpectationValue
      const request: PublishPayloadRequest = new PublishPayloadRequest()
      if (testMode) {
        request.stubbedRetrieveResponse = this.retrieveStubValue
      }
      request.setConfig(this.config)
      request.payload = payload

      if (this.payloadFormat === 'json') {
        request.productIdentifier = JSON.parse(payload)['globalIdentifier'];
        request.contentType = 'application/json';
      } else {
        const xmlParser = new DOMParser();
        const payloadXmlParsed = xmlParser.parseFromString(request.payload, "text/xml");
        const globalIdentifier = payloadXmlParsed.getElementsByTagName('globalIdentifier').item(0).textContent;
        request.productIdentifier = globalIdentifier;
        request.contentType = 'application/xml';
      }

      const getPublished = (request) => of(testMode)
        .pipe(
          switchMap(testMode => {
            if (testMode) {
              return of(undefined)
            } else {
              return this.publishPayloadService.getPublished(request)
            }
          }),
        )

      getPublished(request)
        .pipe(
          takeWhile(() => this.publishing),
          switchMap(before => {
            return this.publishPayloadService.publishPayload(request, this.config.state !== 'original', testMode)
              .pipe(
                takeWhile(() => this.publishing),
                map(response => {
                  return {
                    before: before,
                    trace: response,
                  }
                }),
                switchMap(published => {
                  return getPublished(request)
                    .pipe(
                      map(after => {
                        return {
                          before: published.before,
                          trace: published.trace,
                          after: after,
                        }
                      }),
                    )
                }),
              )
          }),
        )
        .subscribe(response => {
          console.log('Received response')
          console.dir(response)
          if (response.before) {
            if (typeof response.before === 'string') {
              this.before = JSON.stringify(JSON.parse(response.before), undefined, 2)
            } else {
              this.before = JSON.stringify(response.before, undefined, 2)
            }
          }
          if (response.after) {
            if (typeof response.after === 'string') {
              this.after = JSON.stringify(JSON.parse(response.after), undefined, 2)
            } else {
              this.after = JSON.stringify(response.after, undefined, 2)
            }
            this.retrieveStubValue = this.retrieveStubValue || this.before
            if (this.retrieveStubValue !== '') {
              try {
                const rs = JSON.parse(this.retrieveStubValue)
                if (rs.data && rs.statusCode) {
                  this.retrieveStubValue = JSON.stringify(rs.data, undefined, 2)
                }
              } catch (e) {
                // do nothing - we just want to remove any statusCodes
              }
            }
            const latest = Object.assign({data: {}}, JSON.parse(this.after))
            removeEmpty(latest)
            this.publishExpectationValue = this.publishExpectationValue || JSON.stringify(latest.data, undefined, 2)
          }

          if (testMode) {
            response.trace.publish.result = response.trace.stageSummary.publish.result
            if (response.trace.publish['details']) {
              try {
                const actual = JSON.parse(response.trace.publish['details'])
                const expected = JSON.parse(expectedResponse)
                this.publishedModel.code = JSON.stringify(actual, undefined, 2)
                this.expectedModel.code = JSON.stringify(expected, undefined, 2)
                if (deepEqual(actual, expected, {strict: true})) {
                  response.trace['difference'] = {
                    result: 'OK',
                  }
                } else {
                  response.trace['difference'] = {
                    result: 'ERROR',
                  }
                }
              } catch (e) {
                console.log('Comparison Failed')
                console.dir(e)
                response.trace['difference'] = {
                  result: 'ERROR',
                }
              }
            } else {
              response.trace['difference'] = {
                result: 'SKIPPED',
              }
            }
          }

          if (response.trace.formatTransform !== "None") {
            this.postConvertPayload = response.trace.encapsulate['payload'];
          }

          this.trace = JSON.stringify(response.trace, undefined, 2)
          if (!testMode) {
            this.targetResponse = response.trace.publish && JSON.stringify(response.trace.publish, undefined, 2)
          }
          this.errors = response.trace.errorResponse && JSON.stringify(response.trace.errorResponse, undefined, 2)
          this.publishing = false
          this.refreshGraphic = '' + new Date().valueOf()
        })
    }
  }

  executeTestSuite() {
    this.testManager.tests.pipe(
      map(tests => tests.filter(test => test.product.service === 'publish')),
      take(1),
      switchMap((tests: SavedTestMetadata[]) => of(...tests)),
      mergeMap((test: SavedTestMetadata) => {
        return this.testManager.getTest(test.name)
      }),
      filter((test: TestDefinition) => test.expectedPublish !== undefined && test.stubbedRetrieve !== undefined),
      mergeMap((test: TestDefinition) => {
        const request: PublishPayloadRequest = new PublishPayloadRequest()
        const metadata = test.product as PublishPayloadInformation
        request.stubbedRetrieveResponse = test.stubbedRetrieve
        request.sourceSystem = metadata.sourceSystem
        request.businessUnit = metadata.businessUnit
        request.payloadType = metadata.payloadType
        request.targetSystem = metadata.targetSystem
        request.payload = test.payload
        request.productIdentifier = JSON.parse(request.payload)['globalIdentifier']

        console.log('Executing: ' + JSON.stringify(request))
        return this.publishPayloadService.publishPayload(request, false, true)
          .pipe(
            map(response => {
              if (response.stageSummary) {
                response.publish.result = response.stageSummary.publish.result
              }
              if (response.publish && response.publish['details']) {
                try {
                  const actual = JSON.parse(response.publish['details'])
                  const expected = JSON.parse(test.expectedPublish)
                  this.publishedModel.code = JSON.stringify(actual, undefined, 2)
                  this.expectedModel.code = JSON.stringify(expected, undefined, 2)
                  if (deepEqual(actual, expected, {strict: true})) {
                    response['difference'] = {
                      result: 'OK',
                    }
                  } else {
                    response['difference'] = {
                      result: 'ERROR',
                    }
                  }
                } catch (e) {
                  response['difference'] = {
                    result: 'ERROR',
                  }
                }
              } else {
                response['difference'] = {
                  result: 'SKIPPED',
                }
              }
              return {
                test: test,
                response: response,
              }
            }),
          )
      }),
      toArray(),
      tap(executionResults => {
        this.toast.info('Executed ' + executionResults.length + ' Regression Tests')
        console.log('Got Regression Tests')
        console.dir(executionResults)
        const csvData = []
        executionResults.forEach(result => {
          csvData.push({
            name: result.test.name,
            decision: result.response.decision,
            events: result.response.eventsRaised?.join(','),
            errors: result.response.errors?.map(e => e.type + ':' + e.code).join(','),
            validatePayload: result.response.validatePayload?.result,
            transform: result.response.transform?.result,
            enrich: result.response.enrich?.result,
            retrieve: result.response.retrieve?.result,
            combine: result.response.combine?.result,
            encapsulate: result.response.encapsulate?.result,
            validateEncapsulated: result.response.validateEnriched?.result,
            publish: result.response.publish?.result,
            // @ts-ignore
            difference: result.response.difference?.result,
          })
        })
        const csv = unparse(csvData, {
          header: true,
        })
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
        const filename = ('publish-regression-tests-' + new Date().toISOString() + '.csv')
        console.log('Saving: ' + filename)
        saveAs(blob, filename)
      }),
    ).subscribe()
  }

  getCurl() {
    const baseUrl = this.targetEnvironment === ENVIRONMENTS.UAT ? 'https://finaluat-fe.regxchange.com' : 'https://uat-fe.regxchange.com'
    const curl = 'curl --location --request POST \'' + baseUrl + '/kid-portal/priips\' \\\n' +
      '--header \'Authorization: Bearer <TOKEN>\' \\\n' +
      '--header \'Content-Type: application/json\' \\\n' +
      '--data-raw \'' + this.publishExpectationValue + '\''
    this.clipboardService.copyFromContent(curl)
    this.toast.success('Curl command copied to clipboard')
  }
}
