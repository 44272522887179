import {Component, EventEmitter, OnInit} from '@angular/core'
import {ChartDataSets, ChartOptions} from 'chart.js'
import {Color} from 'ng2-charts'
import {DateTime, DurationObject, Interval} from 'luxon'
import {ToastrService} from 'ngx-toastr'
import {ClipboardService} from 'ngx-clipboard'
import {debounceTime} from 'rxjs/operators'

const PLACEHOLDER_WIDTH = 338
const PLACEHOLDER_HEIGHT = 225

interface ExcludeStartDateMonths {
  start: number
  end: number
}

const excludedStartDates: ExcludeStartDateMonths[] = [
  {
    start: 12,
    end: 13,
  },
  {
    start: 15,
    end: 17,
  },
  {
    start: 18,
    end: 20,
  },
  {
    start: 21,
    end: 22,
  },
  {
    start: 24,
    end: 27,
  },
  {
    start: 30,
    end: 33,
  },
  {
    start: 36,
    end: 39,
  },
  {
    start: 48,
    end: 52,
  },
  {
    start: 72,
    end: 78,
  },
  {
    start: 96,
    end: 104,
  },
  {
    start: 120,
    end: 128,
  },
  {
    start: 180,
    end: 196,
  },
  {
    start: 240,
    end: 260,
  },
  {
    start: 300,
    end: 330,
  },
]

@Component({
  selector: 'app-chart-playground',
  templateUrl: './chart-playground.component.html',
  styleUrls: ['./chart-playground.component.scss'],
})
export class ChartPlaygroundComponent implements OnInit {

  constructor(
    private clipboardService: ClipboardService,
    private toast: ToastrService,
  ) {
  }

  width = PLACEHOLDER_WIDTH * 2
  height = PLACEHOLDER_HEIGHT * 2

  years = 1
  maxYears = 7
  yearOptions = {
    floor: 0,
    ceil: this.maxYears,
  }

  months = 12
  monthOptions = {
    floor: 0,
    ceil: this.maxYears * 12,
  }

  days = 365
  dayOptions = {
    floor: 1,
    ceil: this.maxYears * 366,
  }

  lowRange = 50
  highRange = 170
  rangeOptions = {
    floor: 1,
    ceil: 1000,
  }

  format = 'DD\nMMM\nYYYY'
  absoluteStrike = 98.6
  absoluteTriggerBarrier = 167.2

  chartDatasets: ChartDataSets[] = [{data: []}]
  chartOptions: (ChartOptions) = {
    responsive: false,
    animation: {
      duration: 1000,
    },
    legend: {
      position: 'bottom',
      align: 'start',
      display: false,
    },
    elements: {
      line: {
        // tension: 0, // disables bezier curves
      },
    },
    defaultColor: 'black',
    scales: {
      xAxes: [
        {
          type: 'time',
          ticks: {
            source: 'labels',
            fontSize: 14,
            fontColor: 'black',
            maxTicksLimit: 9,
            autoSkipPadding: 20,
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            padding: 6,
            // @ts-ignore
            callback: (value: string, index: number, values: any[]) => {
              if (/\n/.test(value)) {
                return value.split(/\n/)
              }
              return value
            },
          },
          gridLines: {
            drawBorder: true,
            drawTicks: true,
            tickMarkLength: 3,
            // color: 'black',
            zeroLineWidth: 0.5,
            lineWidth: 1,
          },
          bounds: 'ticks',
          distribution: 'linear',
          time: {
            unit: 'day',
            minUnit: 'day',
            displayFormats: {
              day: 'DD[\n]MMM[\n]YYYY',
              week: 'DD[\n]MMM[\n]YYYY',
              month: 'DD[\n]MMM[\n]YYYY',
              quarter: 'DD[\n]MMM[\n]YYYY',
              year: 'DD[\n]MMM[\n]YYYY',
            },
          },
        },
        {
          position: 'top',
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
            drawTicks: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            source: 'auto',
            // min: 0,
            fontColor: 'black',
            maxTicksLimit: 8,
            fontSize: 16,
            labelOffset: -2,
            padding: 6,
          },
          gridLines: {
            drawBorder: true,
            drawTicks: false,
          },
          bounds: 'auto',
          position: 'left',
          id: 'priceScale',
          scaleLabel: {
            labelString: '',
            fontColor: 'black',
            fontSize: 16,
          },
        },
        {
          position: 'right',
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
            drawTicks: false,
          },
        },
      ],
    },
  }
  chartColours: Color[] = [{}, {}, {}]

  $update = new EventEmitter()
  direction = 1
  multiplier = 1.0

  ngOnInit(): void {
    this.$update
      .pipe(
        debounceTime(200),
      )
      .subscribe(_ => this.updateChart())
  }

  updateChart() {
    try {
      const endDate = DateTime.utc().startOf('day')
      const startDate = endDate.minus({days: this.days})
      const interval = Interval.fromDateTimes(startDate, endDate)
      const years = interval.toDuration('years').years
      const months = interval.toDuration('months').months
      const days = interval.toDuration('days').days
      console.dir(years)
      console.dir(months)
      console.dir(days)
      console.log(startDate.toISODate() + ' to ' + endDate.toISODate())

      interface HoustonPayload {
        InstrumentDefinition: {
          Participation: {
            Strike: number
            AbsoluteStrike: number
          }[]
          Redemption: {
            AbsoluteTriggerBarrier: number
          }
        }
        AssetInformation: {
          History: {
            Date: string
            Value: number
          }[]
        }[]
      }

      const houston: HoustonPayload = {
        InstrumentDefinition: {
          Participation: [
            {
              Strike: 12.3,
              AbsoluteStrike: this.absoluteStrike,
            },
          ],
          Redemption: {
            AbsoluteTriggerBarrier: this.absoluteTriggerBarrier,
          },
        },
        AssetInformation: [
          {
            History: [],
          },
        ],
      }
      const history = houston.AssetInformation[0].History
      let currentValue = this.randomValue()
      let currentDate = startDate
      const finalDate = endDate.minus({days: 1})
      while (currentDate.valueOf() < finalDate.valueOf()) {
        history.push(
          {
            Date: currentDate.toISODate(),
            Value: currentValue,
          },
        )
        const daysToAdd = (Math.random() > 0.7) ? 3 : 1
        currentDate = currentDate.plus({days: daysToAdd})
        currentValue = this.randomWalk(currentValue)
      }
      history.push(
        {
          Date: finalDate.toISODate(),
          Value: currentValue,
        },
      )
      console.dir(houston)


      // tslint:disable-next-line:no-non-null-assertion
      const xAxis = this.chartOptions.scales.xAxes[0]!
      // tslint:disable-next-line:no-non-null-assertion
      xAxis.ticks!.min = startDate.minus({day: 1}).valueOf()
      // tslint:disable-next-line:no-non-null-assertion
      xAxis.ticks!.max = endDate.plus({day: 1}).valueOf()

      const labels = []
      let skip: DurationObject | undefined

      if (months > 120) {
        skip = {year: 5}
      } else if (months > 60) {
        skip = {year: 2}
      } else if (months > 42.0) {
        skip = {year: 1}
      } else if (months > 21.0) {
        skip = {months: 6}
      } else if (months > 12.0) {
        skip = {months: 3}
      } else if (months > 5.0) {
        skip = {months: 2}
      }

      if (skip) {
        console.log('Using skip backwards')
        console.dir(skip)
        xAxis.ticks.source = 'labels'
        xAxis.ticks.autoSkip = true
        let tickDate = finalDate
        while (tickDate.valueOf() >= startDate.valueOf()) {
          labels.push(tickDate.toISODate())
          tickDate = tickDate.minus(skip)
        }
        if (!labels.includes(startDate.toISODate())) {
          if (!excludedStartDates.find(ex => {
            return months > ex.start && months < ex.end
          })) {
            labels.push(startDate.toISODate())
          }
        }
        xAxis.labels = labels.reverse()
        console.dir(xAxis)
      } else {
        xAxis.labels = []
        xAxis.ticks.source = 'auto'
        xAxis.ticks.autoSkip = true
      }

      this.chartOptions = Object.assign({}, this.chartOptions)

      this.chartDatasets = [
        {
          data: history.map(h => {
            return {
              t: h.Date,
              y: h.Value,
            }
          }),
          type: 'line',
          label: 'Performance',
          yAxisID: 'priceScale',
          fill: false,
          borderColor: '#4573c4',
          backgroundColor: '#4573c4',
          borderWidth: 3,
          pointRadius: 0,
        },
        {
          data: [
            {
              t: startDate.valueOf(),
              y: houston.InstrumentDefinition.Participation[0].AbsoluteStrike,
            },
            {
              t: endDate.valueOf(),
              y: houston.InstrumentDefinition.Participation[0].AbsoluteStrike,
            },
          ],
          type: 'line',
          label: 'Forward Price',
          yAxisID: 'priceScale',
          fill: false,
          borderColor: '#8fabdb',
          backgroundColor: '#8fabdb',
          borderWidth: 3,
          pointRadius: 0,
        },
        {
          data: [
            {
              t: startDate.valueOf(),
              y: houston.InstrumentDefinition.Redemption.AbsoluteTriggerBarrier,
            },
            {
              t: endDate.valueOf(),
              y: houston.InstrumentDefinition.Redemption.AbsoluteTriggerBarrier,
            },
          ],
          type: 'line',
          label: 'Knock Out Price',
          yAxisID: 'priceScale',
          fill: false,
          borderColor: '#262626',
          backgroundColor: '#262626',
          borderWidth: 3,
          pointRadius: 0,
          borderDash: [12, 12],
        },
      ]
    } catch (e) {
      console.dir(e)
    }
  }

  saveChartConfiguration() {
    const options = this.chartOptions
    delete this.chartOptions.scales.xAxes[0].ticks.callback
    const chartConfiguration = {
      type: 'line',
      data: {
        datasets: this.chartDatasets.map(data => {
          const r = Object.assign({}, data)
          delete r['_meta']
          return r
        }),
      },
      options: options,
    }
    console.dir(chartConfiguration)
    this.clipboardService.copyFromContent(JSON.stringify(chartConfiguration, undefined, 2))
    // @ts-ignore
    this.chartOptions.scales.xAxes[0].ticks.callback = (value: string) => {
      if (/\n/.test(value)) {
        return value.split(/\n/)
      }
      return value
    }
    this.toast.success('Configuration saved to clipboard')
  }

  updateYears() {
    const today = DateTime.utc()
    const start = today.minus({years: this.years})
    const interval = Interval.fromDateTimes(start, today)
    this.years = interval.toDuration('years').years
    this.months = interval.toDuration('months').months
    this.days = interval.toDuration('days').days
    this.$update.emit(DateTime.utc())
  }

  updateMonths() {
    const today = DateTime.utc()
    const start = today.minus({month: this.months})
    const interval = Interval.fromDateTimes(start, today)
    this.years = interval.toDuration('years').years
    this.months = interval.toDuration('months').months
    this.days = interval.toDuration('days').days
    this.$update.emit(DateTime.utc())
  }

  updateDays() {
    const today = DateTime.utc()
    const start = today.minus({days: this.days})
    const interval = Interval.fromDateTimes(start, today)
    this.years = interval.toDuration('years').years
    this.months = interval.toDuration('months').months
    this.days = interval.toDuration('days').days
    this.$update.emit(DateTime.utc())
  }

  updateValues() {
    if (this.highRange === this.lowRange) {
      this.highRange = Math.min(this.highRange + 1, this.rangeOptions.ceil)
      this.lowRange = Math.max(this.lowRange - 1, this.rangeOptions.floor)
    }
    this.absoluteStrike = this.randomValue()
    this.absoluteTriggerBarrier = this.randomValue()

    this.$update.emit(DateTime.utc())
  }

  private randomValue() {
    const diff = this.highRange - this.lowRange
    const movement = Math.random() * 0.5 * diff
    const randomNumber = movement + (this.lowRange + (diff * 0.25))
    return randomNumber
  }

  private randomWalk(current: number) {
    const diff = (this.highRange - this.lowRange) / 75
    const movement = Math.random() * diff
    if (Math.random() > 0.75) {
      this.direction = this.direction * -1
      this.multiplier = (Math.random() * 0.5) + 0.5
    } else {
      this.multiplier = this.multiplier * 1.1
    }
    let newValue = current + (movement * this.direction * this.multiplier)
    if (newValue < this.lowRange) {
      newValue = this.lowRange + movement
    }
    if (newValue > this.highRange) {
      newValue = this.highRange - movement
    }
    return newValue
  }
}
