import {Component, Inject} from '@angular/core'
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {RowUsage} from '../views/config-rules/logic/interfaces'

interface Deletion {
  configKey: string
  username: string
  timestamp: string
  comment: string
}

export interface ShowRuleDeletions {
  title: string
  deletions: RowUsage
}

@Component({
  templateUrl: 'analysis-show-rule-deletions-dialog.component.html',
  styleUrls: ['./analysis-show-rule-deletions-dialog.component.scss'],
})
export class ShowRuleDeletionsDialogComponent {

  deletions: Deletion[]

  constructor(public dialogRef: MatDialogRef<ShowRuleDeletionsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ShowRuleDeletions) {
    this.deletions = []
    Object.keys(data.deletions).forEach((configKey: string) => {
      this.deletions.push(
        {
          configKey: configKey,
          username: data.deletions[configKey].deletion.user,
          timestamp: data.deletions[configKey].deletion.timestamp,
          comment: data.deletions[configKey].deletion.comment
        } as Deletion
      )
    })
  }

  cancel() {
    this.dialogRef.close(undefined)
  }
}
