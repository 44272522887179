import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'

const uuid = require('uuid').v4

export type StatusMessageType = 'TASK' | 'ALERT'

export interface StatusMessage {
  id: string
  type: StatusMessageType
  message: string
  autoClose?: number
}

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  activeTasks = new BehaviorSubject<StatusMessage[]>([])

  topTask = new BehaviorSubject<StatusMessage>(undefined)

  constructor() {
    this.activeTasks.subscribe(x => {
      this.topTask.next(x && x.length >= 1 && x[x.length - 1])
    })
  }

  start(message: string, type: StatusMessageType = 'TASK', autoClose ?: number): string {
    const id = uuid()
    const current = this.activeTasks.getValue()
    this.activeTasks.next(current.concat(
      {
        id: id,
        type: type,
        message: message,
        autoClose: autoClose,
      }))
    if (autoClose) {
      setTimeout(() => {
        this.complete(id)
      }, autoClose * 1000)
    }
    return id
  }

  alert(message: string) {
    if (!this.activeTasks.getValue().find(a => a.message === message)) {
      this.start(message, 'ALERT')
    }
  }

  clear(message: string) {
    const current = this.activeTasks.getValue().filter(x => x.message !== message)
    this.activeTasks.next(current)
  }

  complete(id: string) {
    const current = this.activeTasks.getValue().filter(x => x.id !== id)
    this.activeTasks.next(current)
  }
}

