<div class="modal-header">
  <h4 class="modal-title">Jira Issue</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="form-group">
      <label for="jiraIssueInput">Jira Issue</label>
      <input class="form-control" id="jiraIssueInput" [(ngModel)]="jiraIssue" placeholder="Jira Issue">
      <small class="form-text text-muted">Provide enter jira issue without url.</small>
    </div>
    <div class="form-group">
      <label for="descriptionInput">Help</label>
      <input class="form-control" id="descriptionInput" [(ngModel)]="description" placeholder="Description">
      <small class="form-text text-muted">Provide a description of the change.</small>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-primary" [disabled]="!validJira() || !this.description" ngbAutoFocus
          (click)="confirm()">OK
  </button>
</div>
