//left editor configuration options
export const leftOptions: any = {
  mode: "view",
  // modes: ["code", "text", "tree", "view", "form"],
  onModeChange: function (newMode: any, oldMode: any) {
    console.log("Mode switched from", oldMode, "to", newMode);
  },
  onCreateMenu: function (items: any, node: any) {
    const path = node.path;
    console.log("items : ", items);
    console.log("nodes : ", node);
    return items;
  },
  onSelectionChange: function (start: any, end: any) {
    console.log("start of node: ", start);
    console.log("end of node: ", end);
  },
  autocomplete: {
    getOptions: function () {
      return ["apple", "cranberry"];
    },
  },
  templates: [
    {
      text: "Person",
      title: "Insert a Person Node",
      className: "jsoneditor-type-object",
      field: "PersonTemplate",
      value: {
        firstName: "John",
        lastName: "Do",
        age: 28,
      },
    },
  ],
};

//right editor configuration options
export const rightOptions: any = {
  mode: "tree",
  templates: [],
};
