<h2>Test Cleanup</h2>

<div *ngIf="!loaded">
  <div class="description">Please wait - loading config and tests</div>
</div>

<div *ngIf="loaded">
  <div class="description">Please select which tests you would like to delete.</div>

  <div class="options">
    <div class="option">
      <mat-slide-toggle [checked]="oldConfiguration" (change)="oldConfiguration = $event.checked; updateSummary()">Tests
        With Old
        Configuration
      </mat-slide-toggle>
    </div>
    <div class="option">
      <mat-slide-toggle [checked]="orphanedProdClone" (change)="orphanedProdClone = $event.checked; updateSummary()">
        Orphaned Prod Clone
        Tests
      </mat-slide-toggle>
    </div>
  </div>

  <div class="summary" *ngIf="summary">
    <h3>Summary</h3>
    <div>There are currently {{summary.totalTests}} in the system.</div>
    <div>{{summary.totalDeleted}} tests will be deleted, leaving {{summary.totalRemaining}} tests.</div>
    <div>What will be deleted:</div>
    <ul>
      <li>{{summary.deleteInvalidConfigs}} have invalid configs</li>
      <li>{{summary.deleteOrphanedProdClone}} are orphaned prod clone tests</li>
    </ul>
  </div>

  <div class="actions" *ngIf="canDelete">
    <button class="btn btn-success me-2" (click)="downloadProposedChanges()">Download Proposed Changes</button>
    <button class="btn btn-danger" (click)="cleanupTests()">Perform Test Cleanup</button>
  </div>
</div>
