import { Injectable } from '@angular/core';
import {ApiService} from '../../../../services/api.service';
import {
  GetRulesResponse,
  SaveRuleRequest,
  ValidatePayloadRequest, ValidatePayloadResponse,
  ValidationResponse
} from "../models/md-validation-models";

import {StatusService} from "../../../../services/status.service";
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MdValidateService {

  refreshSubject: Subject<any> = new Subject();

  constructor(private api: ApiService, private statusService: StatusService) { }

  getValidationRules(): Observable<GetRulesResponse> {
    return this.api.get<GetRulesResponse>('/md-validate/rules');
  }

  saveValidationRule(request: SaveRuleRequest) {
    const status = this.statusService.start('Creating new validation rule')
    this.api.post<SaveRuleRequest, ValidationResponse>('/md-validate/rule', request).subscribe(res => {
      if (res.status === 'ERROR') console.error('There has been an error trying to create/edit rule: ', res.body);
      this.statusService.complete(status)
      this.refreshSubject.next();
    })
  }

  softDeleteValidationRule(ruleId) {
    const status = this.statusService.start('Deleting validation rule')
    this.api.delete<ValidationResponse>(`/md-validate/rule/${ruleId}`).subscribe(res => {
      if (res.status === 'ERROR') console.error('There has been an error trying to delete rule: ', res.body);
      if(res.status === 'PASSED') {
        this.statusService.complete(status)
        this.refreshSubject.next();
      }
    })
  }

  restoreRule(ruleId) {
    const status = this.statusService.start('Restoring validation rule')
    this.api.get<ValidationResponse>(`/md-validate/rule/restore/${ruleId}`).subscribe(res => {
      if (res.status === 'ERROR') console.error('There has been an error trying to restore rule: ', res.body);
      if(res.status === 'PASSED') {
        this.statusService.complete(status)
        this.refreshSubject.next();
      }
    })
  }

  validatePayload(payload: JSON) {
    const request: ValidatePayloadRequest = {
      payload
    }
    return this.api.post<ValidatePayloadRequest, ValidatePayloadResponse>(`/md-validate/validate`, request)
  }
 }
