import {Component} from '@angular/core'
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'
import {ConfirmStatus} from '../confirm-dialog.component'

@Component({
  selector: 'app-jmes-path-evaluator-dialog',
  templateUrl: './jmes-path-evaluator-dialog.component.html',
  styleUrls: ['./jmes-path-evaluator-dialog.component.scss'],
})
export class JmesPathEvaluatorDialogComponent {
  payload = {
    foo: [
      {
        bar: 'baz'
      },
      {
        baz: 'bar'
      }
    ]}

  constructor(
    public activeModel: NgbActiveModal,
  ) {
  }

  cancel() {
    this.activeModel.dismiss(ConfirmStatus.CANCELLED)
  }
}
