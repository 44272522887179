<h1 mat-dialog-title>Please enter a comment.</h1>
<div mat-dialog-content>
  <mat-form-field color="primary" *ngIf="!data.isNew">
    <input class="dialog-form-field" matInput [(ngModel)]="data.comment" maxlength="128"
           placeholder="Comment">
    <mat-hint align="end">{{data.comment.length}} / 128</mat-hint>
  </mat-form-field>
  <mat-form-field color="primary">
    <input  *ngIf="data.title === 'Save As Example Payload'" class="dialog-form-field" matInput [(ngModel)]="data.name" maxlength="64" placeholder="{{data.title}}">
<!--    <input  *ngIf="data.title !== 'Save As Example Payload'" class="dialog-form-field" matInput [(ngModel)]="data.name" maxlength="64" placeholder="{{data.title}}" (change)=changedValue()> changeValueFunction not defined   -->
    <input  *ngIf="data.title !== 'Save As Example Payload'" class="dialog-form-field" matInput [(ngModel)]="data.name" maxlength="64" placeholder="{{data.title}}">
    <mat-hint align="start"><strong>{{hint}}</strong></mat-hint>
    <mat-hint align="end">{{data.name.length}} / 64</mat-hint>
  </mat-form-field>
  <div *ngIf="data.title !== 'Save As Example Payload'">
  <app-test-suite-selector [displayProdClone]="false" (testSuite)="setTestSuite($event)"></app-test-suite-selector>
</div>

</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="cancel()" cdkFocusInitial>Cancel</button>
  <button mat-raised-button color="warn" [disabled]="!data.comment && !data.name" [mat-dialog-close]="data">OK</button>
</div>
