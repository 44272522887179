import {Injectable} from '@angular/core'
import { Amplify } from 'aws-amplify';
//import {AmplifyService} from '@flowaccount/aws-amplify-angular'
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import {distinctUntilChanged, map, shareReplay} from 'rxjs/operators'
import { of } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserNameService {
  userName  = this.authService.onUserSession.pipe(map(user => user.idToken.payload['cognito:username']), distinctUntilChanged(), shareReplay(1))
  constructor(
    private amplify: AuthenticatorService, private authService: AuthService
  ) {
  }
}
