import {Component, OnDestroy, OnInit} from '@angular/core'
import {GenerateDocumentRequest} from '../model/generate-document-request'
import {ExpectedDocument, GenerateDocumentStatus} from '../model/generate-document-status'
import {GenerateDocumentService} from '../services/generate-document.service'
import {BehaviorSubject, of, Subscription} from 'rxjs'

import {debounceTime, distinctUntilChanged, map, switchMap, take, tap} from 'rxjs/operators'
import {DiffResponse, DiffService} from '../services/diff.service'
import {TestManagerService} from '../services/test-manager.service'
import {ConfigDetail} from '../common/domain/config'
import {GetTestSuiteResponse, TestExecutionDetailResponse, TestHistory} from '../model/test-management-responses'
import {saveAs} from 'file-saver'
import {WorkspaceService} from '../services/workspace.service'
import {PayloadInformation, TestDefinition, TestSuiteDefinition, TestType} from '../common/domain/tests'
import {PermissionsService} from '../services/permissions.service'
import {ActivatedRoute, Router} from '@angular/router'
import {ChangeService} from '../services/change.service'
import {ConfigService} from '../services/config.service'
import {ChangeDetails, DocgenConfigAndMetadataChangeDetails} from '../common/domain/change'
import {WorkspaceAutoSaveService} from '../services/workspace-auto-save.service'
import {SaveTestDialogComponent, SaveTestDialogData} from '../dialogs/save-test-dialog.component'
import {SaveNegativeTestDetails, SaveNegativeTestDialogComponent} from '../dialogs/save-negative-test-dialog.component'
import {MatDialog} from '@angular/material/dialog'
import {SourceFormat} from '../common/domain/ingest'
import {DocgenConfig} from '../common/domain/docgen-config'
import {IngestProductDocgenTraceResponse} from '../model/public-api-response'
import {SuccessRenderDocumentResult} from '../common/domain/render'
import {SuccessTransformResult} from '../common/domain/transform'
import {SuccessValidateResult} from '../common/domain/validate'
import {AnalyseRuleTable, SuccessAnalyseResult} from '../common/domain/analyse'
import {ToastrService} from 'ngx-toastr'
import deepEqual from 'deep-equal'
import {jsonEditorOptions, jsonViewerOptions, xmlEditorOptions, xmlViewerOptions} from '../util/editor-configurations'
import {NgbModal, NgbNavChangeEvent} from '@ng-bootstrap/ng-bootstrap'
import {ChartDataSets, ChartOptions} from 'chart.js'
import {Color} from 'ng2-charts'
import {DateTime, Interval} from 'luxon'
import {ClipboardService} from 'ngx-clipboard'
import {DynamicMappingService} from '../services/dynamic-mapping.service'
import { PreprocessorMappingService } from '../services/preprocessor-mapping-service'
import {JmesPathEvaluatorDialogComponent} from '../dialogs/jmes-path-evaluator/jmes-path-evaluator-dialog.component'
import { RegexEvaluatorDialogComponent } from '../dialogs/regex-evaluator-dialog/regex-evaluator-dialog.component'

const base64js = require('base64-js')
const uuid = require('uuid')
const xmlParser = require('fast-xml-parser')
const deepEquals = require('deep-equal')

export interface TestFailureSummary {
  decision: string
  analysePayloadResult: string
  payloadNotSupportedMatches: string[]
  payloadBlacklistMatches: string[]
  payloadValidationMatches: string[]
  payloadWhitelistUnmatched: string[]
  analyseSoloResult: string
  soloNotSupportedMatches: string[]
  soloBlacklistMatches: string[]
  soloValidationMatches: string[]
  soloWhitelistUnmatched: string[]
}

export class PayloadDetails {
  payload: string | object
  mimetype: string
}

interface ResolvedTest {
  testDefinition?: TestDefinition
  testHistory?: TestHistory[]
  testExecution?: TestExecutionDetailResponse
}

@Component({
  selector: 'app-document-generator',
  templateUrl: './document-generator.component.html',
  styleUrls: ['./document-generator.component.scss'],
})
export class DocumentGeneratorComponent implements OnInit, OnDestroy {
  static DEBOUNCE_TIME = 500

  sub = new Subscription()

  requestParams = new GenerateDocumentRequest()
  documentStatus = new GenerateDocumentStatus()
  expectedDocument?: ExpectedDocument

  validSource: boolean
  isDeveloper = false
  isJson = false

  configKey: string

  pdfDiffResponse?: DiffResponse
  pdfVisualDiffResponse?: string
  xmlDiffResponse?: DiffResponse
  testDefinition: TestDefinition
  testHistory: TestHistory[]
  config: DocgenConfigAndMetadataChangeDetails

  renderDocumentService: string

  testDetail?: TestExecutionDetailResponse

  canSaveNegativeTests: boolean

  loadedVersion: string

  isDynamicMapping: boolean
  isVisualDiffMapping: boolean
  isPreprocessorMapping: boolean
  payloadSubject = new BehaviorSubject<string>('')
  payload = this.payloadSubject.pipe(
    debounceTime(250),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Payload Changed: ' + r)
    // }),
  )

  formatSubject = new BehaviorSubject<SourceFormat>(undefined)
  format = this.formatSubject.pipe(
    distinctUntilChanged(),
  )

  csSchemaSubject = new BehaviorSubject<string>(undefined)
  csSchema = this.csSchemaSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('CS Schema Changed: ' + r)
    // }),
  )

  transform1Subject = new BehaviorSubject<string>(undefined)
  transform1 = this.transform1Subject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Transform Changed: ' + r)
    // }),
  )

  transform2Subject = new BehaviorSubject<string>(undefined)
  transform2 = this.transform2Subject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Transform Changed: ' + r)
    // }),
  )

  enrich1Subject = new BehaviorSubject<string>(undefined)
  enrich1 = this.enrich1Subject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Enrich1 Changed: ' + r)
    // }),
  )

  enrich2Subject = new BehaviorSubject<string>(undefined)
  enrich2 = this.enrich2Subject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Enrich2 Changed: ' + r)
    // }),
  )

  targetSchemaSubject = new BehaviorSubject<string>(undefined)
  targetSchema = this.targetSchemaSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Target Schema Changed: ' + r)
    // }),
  )

  payloadCountryValueSubject = new BehaviorSubject<string>(undefined)
  payloadCountryValue: string
  payloadLanguageValueSubject = new BehaviorSubject<string>(undefined)
  payloadLanguageValue: string
  payloadLanguageOptions = [
    "None",
    "BG",
    "CS",
    "DA",
    "DE",
    "EL",
    "EN",
    "ES",
    "FI",
    "FR",
    "HR",
    "HU",
    "IT",
    "LT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RU",
    "SE",
    "SK",
    "SV",
  ];

  payloadJurisdictionValueSubject = new BehaviorSubject<string>(undefined)
  payloadJurisdictionValue: string

  payloadValue = ''
  csSchemaPath: string
  csSchemaValue: string
  transform1Value: string
  transform2Value: string
  enrich1Value: string
  enrich2Value: string
  targetSchemaValue: string
  wordAvailable = false

  refreshGraphic = ''
  readOnlyJsonConfig = jsonViewerOptions
  readonlyXmlConfig = xmlViewerOptions
  transformEditorConfig = xmlEditorOptions
  sourceSchemaEditorConfig = xmlEditorOptions
  targetSchemaEditorConfig = xmlEditorOptions
  payloadEditorConfig = xmlEditorOptions
  activeTab: string
  displayPDFDiffPayload: boolean
  constructor(
    private workspaceService: WorkspaceService,
    private workspaceAutoSaveService: WorkspaceAutoSaveService,
    private generateDocumentService: GenerateDocumentService,
    private diffService: DiffService,
    private configService: ConfigService,
    private testManager: TestManagerService,
    private toast: ToastrService,
    private permissionsService: PermissionsService,
    private activatedRoute: ActivatedRoute,
    private changeService: ChangeService,
    private router: Router,
    private clipboardService: ClipboardService,
    public dialog: MatDialog,
    private dynamicMappingService: DynamicMappingService,
    private preprocessorMappingService: PreprocessorMappingService,
    private ngbModal: NgbModal) {

    this.requestParams.sourceFormat = 'xml'
    this.requestParams.documentFormat = 'pdf'
    this.requestParams.productIdentifier = uuid.v4()
    this.requestParams.payload = ''
    this.validSource = true

    this.permissionsService.developer.subscribe(developer => this.isDeveloper = developer)
    this.permissionsService.canSaveNegativeTests.subscribe(permission => this.canSaveNegativeTests = permission)
  }

  async ngOnInit() {
    this.testManager.setService('docgen')
    await this.workspaceAutoSaveService.saveWorkspace()
    this.sub.add(
      this.format.subscribe(format => {
        this.validSource = format !== undefined
        this.requestParams.sourceFormat = format
        if (format === 'xml') {
          this.isJson = false
          this.payloadEditorConfig = xmlEditorOptions
        } else {
          this.isJson = true
          this.payloadEditorConfig = jsonEditorOptions
        }
      }),
    )

    this.sub.add(
      this.configService.loadedConfig
        .pipe(
          switchMap(x => this.activatedRoute.url),
        )
        .pipe(
          // tap(d => console.dir(d)),
          switchMap(urls => {
            if (urls[0].path === 'generate') {
              return this.testManager.selectedTestName
                .pipe(
                  map(testName => {
                    return {test: testName}
                  }),
                )
            } else {
              return of(undefined)
            }
          }),
          // tap(d => console.dir(d)),
        )
        .subscribe((test) => {
          console.log('Switching to test')
          console.dir(test)
          this.loadedVersion = undefined
          if (test) {
            if (test['test']) {
              this.router.navigate(['generate', test['test']])
            } else {
              this.router.navigate(['generate'])
            }
          }
        }),
    )
    this.sub.add(
      this.changeService.selectedDocgenConfig.subscribe(config => {
        this.config = config
        if (config && config.metadata) {
          const sourceSystem = config.metadata.sourceSystem
          const programme = config.metadata.programme
          const productType = config.metadata.productType
          const documentType = config.metadata.documentType
          this.configKey = `${sourceSystem}/${programme}/${productType}/${documentType}`
          this.requestParams.setConfig(config)
          this.documentStatus.setConfig(config)
          console.log('Config selected: ' + this.configKey)


          const docgenConfig = JSON.parse(config.settings.content) as DocgenConfig
          const wordTemplateResourceSelector = docgenConfig.renderDocument?.wordTemplateResourceSelector || ''
          console.log('Got Word: ' + wordTemplateResourceSelector)
          this.wordAvailable = wordTemplateResourceSelector !== ''
          if (docgenConfig.validatePayload.skip) {
            this.csSchemaValue = undefined
            this.csSchemaPath = undefined
            this.sourceSchemaEditorConfig = xmlEditorOptions
          } else {
            const actualSchema = config.schemas.find(s => s.path === docgenConfig.validatePayload.schema)
            this.csSchemaValue = actualSchema && actualSchema.content
            this.csSchemaPath = docgenConfig.validatePayload.schema
            if (this.csSchemaPath.endsWith('.json')) {
              this.sourceSchemaEditorConfig = jsonEditorOptions
            } else {
              this.sourceSchemaEditorConfig = xmlEditorOptions
            }
          }
          if (docgenConfig.validateEnriched.skip) {
            this.targetSchemaValue = undefined
          } else {
            const actualSchema = config.schemas.find(s => s.path === docgenConfig.validateEnriched.schema)
            this.targetSchemaValue = actualSchema && actualSchema.content
          }
          if (docgenConfig.transform.skip) {
            this.transform1Value = undefined
            this.transform2Value = undefined
          } else {
            const actualTransform1 = docgenConfig.transform.transforms.length > 0 && config.transforms.find(t => t.path === docgenConfig.transform.transforms[0])
            const actualTransform2 = docgenConfig.transform.transforms.length > 1 && config.transforms.find(t => t.path === docgenConfig.transform.transforms[1])
            this.transform1Value = actualTransform1 && actualTransform1.content
            this.transform2Value = actualTransform2 && actualTransform2.content
          }
          if (docgenConfig.enrich.skip) {
            this.enrich1Value = undefined
            this.enrich2Value = undefined
          } else {
            const actualEnrich1 = docgenConfig.enrich.transforms.length > 0 && config.transforms.find(t => t.path === docgenConfig.enrich.transforms[0])
            const actualEnrich2 = docgenConfig.enrich.transforms.length > 1 && config.transforms.find(t => t.path === docgenConfig.enrich.transforms[1])
            this.enrich1Value = actualEnrich1 && actualEnrich1.content
            this.enrich2Value = actualEnrich2 && actualEnrich2.content
          }
        } else {
          this.configKey = undefined
          this.requestParams.setConfig(undefined)
          this.documentStatus.setConfig(undefined)
          console.log('No config selected')
        }
        this.refresh()
      }),
    )

    this.sub.add(
      this.payload
        .pipe(
          debounceTime(DocumentGeneratorComponent.DEBOUNCE_TIME),
          distinctUntilChanged(),
        )
        .subscribe(payload => {
          // console.log('Checking updated payload: ' + payload)
          if (this.requestParams.payload !== payload) {
            console.log('Payload has been updated')
            this.requestParams.payload = payload
            this.resetDocumentStatus('payload updated')
          }
          let newFormat: SourceFormat
          try {
            if (payload.startsWith('{')) {
              JSON.parse(payload)
              newFormat = 'json'
            } else if (payload.startsWith('<')) {
              if (xmlParser.validate(payload) === true) {
                newFormat = 'xml'
              }
            }
          } catch (e) {
          }
          this.formatSubject.next(newFormat)
        }),
    )

    this.sub.add(this.payloadCountryValueSubject.subscribe(value => {
      this.payloadCountryValue = value || 'None'
    }))

    this.sub.add(this.payloadLanguageValueSubject.subscribe(value => {
      this.payloadLanguageValue = value || 'None'
    }))

    this.sub.add(this.payloadJurisdictionValueSubject.subscribe(value => {
      this.payloadJurisdictionValue = value || 'None'
    }))

    this.sub.add(
      this.activatedRoute.data
        .pipe(
          distinctUntilChanged(deepEqual),
        )
        .subscribe((data: ResolvedTest | undefined) => {
          console.dir(data)
          const test = data?.testDefinition
          if (test?.product?.service === 'docgen') {
            this.assignTestDefinition(data.testDefinition)
            this.testManager.selectTest(test.name, 'Docgen route update')
            this.assignTestDetail(data.testExecution)
            this.testHistory = data.testHistory
            // if (this.tabGroup) {
            //   this.tabGroup.selectedIndex = data.testExecution ? 1 : 0
            // }
          }
        }),
    )

    this.sub.add(
      this.configService.selectedDocgenConfig.pipe(
        switchMap(config => {
          return this.payload.pipe(
            map(payload => {
              return {
                payload: payload,
                config: config,
              }
            }),
          )
        }),
      ).subscribe(r => {
        this.displayChart = false
        if (r.payload && r.config?.metadata.sourceSystem === 'houston' && r.config?.metadata.documentType === 'Flash') {
          interface HoustonPayload {
            InstrumentDefinition: {
              Participation: {
                Strike: number
                AbsoluteStrike: number
              }[]
              Redemption: {
                AbsoluteTriggerBarrier: number
              }
            }
            AssetInformation: {
              History: {
                Date: string
                Value: number
              }[]
            }[]
          }

          try {
            const houston = JSON.parse(r.payload) as HoustonPayload
            const history = houston.AssetInformation[0].History
            const startDate = DateTime.fromISO(history[0].Date)
            const endDate = DateTime.fromISO(history[history.length - 1].Date)
            const interval = Interval.fromDateTimes(startDate, endDate)
            const yearDuration = interval.toDuration('year')
            const monthDuration = interval.toDuration('month')
            const dayDuration = interval.toDuration('day')
            const years = yearDuration.get('year')
            const months = monthDuration.get('month')
            const days = dayDuration.get('day')
            console.dir(years)
            console.dir(months)
            console.dir(days)
            console.dir(history.length)

            const xAxis = this.chartOptions.scales.xAxes[0]
            // @ts-ignore
            // tslint:disable-next-line:no-non-null-assertion
            xAxis.ticks!.min = startDate.valueOf()
            // @ts-ignore
            // tslint:disable-next-line:no-non-null-assertion
            xAxis.ticks!.max = endDate.valueOf()

            if (months > 59.9 && months < 60.1) {
              // @ts-ignore
              xAxis.ticks.source = 'labels'
              xAxis.ticks.autoSkip = false
              const labels = []
              let currentDate = endDate
              while (currentDate.valueOf() >= startDate.valueOf()) {
                labels.push(currentDate.toISODate())
                currentDate = currentDate.minus({year: 1})
              }
              if (!labels.includes(startDate.toISODate())) {
                labels.push(startDate.toISODate())
              }
              xAxis.labels = labels.reverse()
              // tslint:disable-next-line:no-non-null-assertion
              xAxis.time!.displayFormats.year = 'DD\nMMM\nYYYY'
              //   xAxis.ticks.callback = (value: string, index: number, values: { label: string, value: number, major: boolean }[]) => {
              //     if (index === 0 || index === values.length - 1 || index % 1000 === 0) {
              //       console.dir({
              //         value: value,
              //         index: index,
              //         values: values
              //       })
              //     }
              //     if (index === values.length - 1) {
              //       values[index].major = true
              //     }
              //     values[0] = {
              //       label: 'start',
              //       value: startDate.valueOf(),
              //       major: true
              //     }
              //     if (/\n/.test(value)) {
              //       return value.split(/\n/)
              //     }
              //     // if (index === 0) {
              //     //   values[index] = {
              //     //       label: 'start',
              //     //       value: startDate.valueOf(),
              //     //       major: true
              //     //   }
              //     // }
              //     return value
              //   }
            }

            this.chartDatasets = [
              {
                data: history.map(h => {
                  return {
                    t: h.Date,
                    y: h.Value,
                  }
                }),
                type: 'line',
                label: 'Performance',
                yAxisID: 'priceScale',
                fill: false,
                borderColor: '#4573c4',
                backgroundColor: '#4573c4',
                borderWidth: 3,
                pointRadius: 0,
              },
              {
                data: [
                  {
                    t: startDate.valueOf(),
                    y: houston.InstrumentDefinition.Participation[0].AbsoluteStrike,
                  },
                  {
                    t: endDate.valueOf(),
                    y: houston.InstrumentDefinition.Participation[0].AbsoluteStrike,
                  },
                ],
                type: 'line',
                label: 'Forward Price',
                yAxisID: 'priceScale',
                fill: false,
                borderColor: '#8fabdb',
                backgroundColor: '#8fabdb',
                borderWidth: 3,
                pointRadius: 0,
              },
              {
                data: [
                  {
                    t: startDate.valueOf(),
                    y: houston.InstrumentDefinition.Redemption.AbsoluteTriggerBarrier,
                  },
                  {
                    t: endDate.valueOf(),
                    y: houston.InstrumentDefinition.Redemption.AbsoluteTriggerBarrier,
                  },
                ],
                type: 'line',
                label: 'Knock Out Price',
                yAxisID: 'priceScale',
                fill: false,
                borderColor: '#262626',
                backgroundColor: '#262626',
                borderWidth: 3,
                pointRadius: 0,
                borderDash: [12, 12],
              },
            ]
            this.displayChart = true
          } catch (e) {
            console.dir(e)
          }
        }
      }),
    )
    this.sub.add(this.preprocessorMappingService.showPreprocessorMappingConfigChange.subscribe(value => {
      this.isPreprocessorMapping = value
    }))
    this.sub.add(this.dynamicMappingService.showDynamicMappingConfigChange.subscribe(value => {
      this.isDynamicMapping = value
    }))

    this.sub.add(this.diffService.showVisualDiffConfigChange.subscribe(value => {
      this.isVisualDiffMapping = value
    }))
  }

  displayChart = false
  chartDatasets: ChartDataSets[] = [{data: []}]
  chartOptions: (ChartOptions) = {
    responsive: false,
    legend: {
      position: 'bottom',
      align: 'start',
      display: false,
    },
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    defaultColor: 'black',
    scales: {
      xAxes: [
        {
          type: 'time',
          ticks: {
            source: 'auto',
            fontSize: 16,
            fontColor: 'black',
            maxTicksLimit: 6,
            autoSkipPadding: 20,
            maxRotation: 0,
            minRotation: 0,
            padding: 6,
            // @ts-ignore
            callback: function(value: string, index: number, values: { label: string, value: number, major: boolean }[]) {
              if (index === 0 || index === values.length - 1 || index % 1000 === 0) {
                console.dir({
                  value: value,
                  index: index,
                  values: values,
                })
              }
              if (index === values.length - 1) {
                values[index].major = true
              }
              if (/\n/.test(value)) {
                return value.split(/\n/)
              }
              return value
            },
          },
          gridLines: {
            drawBorder: true,
            drawTicks: true,
            tickMarkLength: 3,
            // color: 'black',
            zeroLineWidth: 0.5,
            lineWidth: 1,
          },
          bounds: 'ticks',
          distribution: 'linear',
          time: {
            // unit: 'day',
            minUnit: 'day',
            displayFormats: {
              day: 'DD[\n]MMM[\n]YYYY',
              week: 'DD[\n]MMM[\n]YYYY',
              month: 'DD[\n]MMM[\n]YYYY',
              quarter: 'DD[\n]MMM[\n]YYYY',
              year: 'DD[\n]MMM[\n]YYYY',
              // quarter: '[Q]Q YYYY',
              // year: 'YYYY',
            },
          },
        },
        {
          position: 'top',
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
            drawTicks: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            source: 'auto',
            // min: 0,
            fontColor: 'black',
            maxTicksLimit: 8,
            fontSize: 16,
            labelOffset: -2,
            padding: 6,
          },
          gridLines: {
            drawBorder: true,
            drawTicks: false,
          },
          bounds: 'auto',
          position: 'left',
          id: 'priceScale',
          scaleLabel: {
            labelString: '',
            fontColor: 'black',
            fontSize: 16,
          },
        },
        {
          position: 'right',
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
            drawTicks: false,
          },
        },
      ],
    },
  }
  chartColours: Color[] = [{}, {}, {}]

  ngOnDestroy(): void {
    this.sub.unsubscribe()
    this.testDetail = undefined
    this.testDefinition = undefined
  }

  assignTestDetail(testDetail) {
    this.testDetail = testDetail
    if (testDetail) {
      this.assignTestDefinition(testDetail.request)
      console.log('Displaying test detail')
      console.dir(testDetail)
      this.testDetail = testDetail
      const status = this.documentStatus
      this.displayDocumentResults(testDetail.response as IngestProductDocgenTraceResponse, false, status)
      this.displayXmlDiffResponse({
        reports: {
          // @ts-ignore
          xml: testDetail.diffs && testDetail.diffs.xml,
        },
      } as unknown as DiffResponse, status)
      this.displayPdfDiff({
        reports: {
          // @ts-ignore
          text: testDetail.diffs && testDetail.diffs.text,
        },
      } as unknown as DiffResponse, status)
    }
  }

  assignTestDefinition(testDefinition) {
    this.loadedVersion = undefined
    console.log('Displaying test definition')
    console.dir(testDefinition)
    this.testDefinition = testDefinition
    if (testDefinition) {
      this.switchTestSuiteIfNecessary(testDefinition.name)
      this.requestParams.payload = testDefinition.payload
      this.payloadValue = testDefinition.payload
      if (testDefinition.document) {
        this.expectedDocument = new ExpectedDocument()
        this.expectedDocument.base64 = testDefinition.document
        this.expectedDocument.bytes = base64js.toByteArray(testDefinition.document)
        this.expectedDocument.file = {name: 'From the test case'} as File
      }

      // country
      this.requestParams.country = this.testDefinition.country
      this.payloadCountryValueSubject.next(this.requestParams.country)

      // jurisdiction
      this.requestParams.jurisdiction = this.testDefinition.jurisdiction
      this.payloadJurisdictionValueSubject.next(this.requestParams.jurisdiction)

      // language
      if (this.testDefinition.language) {
        this.requestParams.language = this.testDefinition.language
      }
      this.payloadLanguageValueSubject.next(this.requestParams.language)
      if (testDefinition.product.preprocessorMapping && testDefinition.product.preprocessorMapping === 'true') {
        if (!this.isPreprocessorMapping) {
          this.preprocessorMappingService.toggleShowPreprocessorMapping(true)
        }
        this.isPreprocessorMapping = true
      } else if (this.isPreprocessorMapping) {
        this.preprocessorMappingService.toggleShowPreprocessorMapping(false)
      }
      if (testDefinition.product.dynamicMapping && testDefinition.product.dynamicMapping === 'true') {
        if (!this.isDynamicMapping) {
          this.dynamicMappingService.toggleShowDynamicMapping(true)
        }
        this.isDynamicMapping = true
      } else if (this.isDynamicMapping) {
        this.dynamicMappingService.toggleShowDynamicMapping(false)
      }

      if(testDefinition.visualDiff !== undefined) {
        this.diffService.toggleVisualDiff(testDefinition.visualDiff)
      }
      this.configService.selectDocgenConfig(testDefinition.product)
    }
  }

  resetDocumentStatus = (reason: string) => {
    console.log('Reset Status: ' + reason)
    this.documentStatus.reset()
    this.pdfDiffResponse = undefined
    this.xmlDiffResponse = undefined
    this.refreshGraphic = '' + new Date().valueOf()
  }

  async downloadDoc() {
    const blob = new Blob([this.documentStatus.doc], {type: 'application/msword'})
    const filename = 'stormDocument.doc'
    saveAs(blob, filename)
  }

  generateDocument = async (headers: boolean, word?: boolean, events ?: boolean, checkOnly?: boolean) => {

    this.configService.selectedDocgenConfig.subscribe(config => {
      console.log(config, 'current assigned config')
      if (!config) {
        this.toast.error('No Config key was found on the selected test, unable to generate document - Please choose a config from the dropdown boxes.')
      }
      else {
        let privateConfig = false

        const configString = this.documentStatus.configString

        this.resetDocumentStatus('generating document')
        this.documentStatus.generating = true

        this.refreshGraphic = '' + new Date().valueOf()

        const localRequestParams: GenerateDocumentRequest = Object.assign({}, this.requestParams)
        localRequestParams.documentFormat = word ? 'word' : 'pdf'
        localRequestParams.country = this.payloadCountryValue && this.payloadCountryValue !== 'None' ? this.payloadCountryValue : undefined
        localRequestParams.language = this.payloadLanguageValue && this.payloadLanguageValue !== 'None' ? this.payloadLanguageValue : undefined
        localRequestParams.jurisdiction = this.payloadJurisdictionValue && this.payloadJurisdictionValue !== 'None' ? this.payloadJurisdictionValue : undefined


        console.log('Making request using the following parameters')
        console.dir({
          documentFormat: localRequestParams.documentFormat,
          language: localRequestParams.language,
          country: localRequestParams.country,
          jurisdiction: localRequestParams.jurisdiction
        })

        if (configString && this.config && this.hasConfigChanged(configString, this.config.settings.content)) {
          privateConfig = true

          try {
            const config = JSON.parse(configString)
            console.log('Using config')
            console.dir(config)

            const configSchemas = []
              .concat(config.validatePayload.schema)
              .concat(config.validateEnriched.schema)
              .filter(x => x)
              .map((name: string) => this.changeService.getObject(name))
              .map(this.asConfigDetail)
            const configTransforms = []
              .concat(config.transform.transforms)
              .concat(config.enrich.transforms)
              .filter(x => x)
              .map((name: string) => this.changeService.getObject(name))
              .map(this.asConfigDetail)
            const configRulesets = []
              .concat(config.rulesets)
              .filter(x => x)
              .map((name: string) => this.changeService.getObject(name))

            const privateConfigPayload = {
              payload: this.requestParams.payload,
              mimeType: this.requestParams.sourceFormat === 'xml' ? 'application/xml' : 'application/json',
              config: config,
              schemas: configSchemas,
              transforms: configTransforms,
              rulesets: configRulesets,
            }

            localRequestParams.payload = JSON.stringify(privateConfigPayload)

            this.toast.info(`Generating using locally edited configuration`)
          } catch (e) {
            this.toast.error('Error parsing the configuration')
            return
          }
        } else if (this.isConfigModified(this.config)) {
          console.log('Config Object')
          console.dir(this.config)

          privateConfig = true
          const config: DocgenConfig = JSON.parse(this.config.settings.content)

          localRequestParams.payload = JSON.stringify(
            {
              payload: this.requestParams.payload,
              mimeType: this.requestParams.sourceFormat === 'xml' ? 'application/xml' : 'application/json',
              config: config,
              schemas: this.config.schemas.map(this.asConfigDetail),
              transforms: this.config.transforms.map(this.asConfigDetail),
              rulesets: this.config.rulesets?.map(this.asConfigDetail),
            },
          )

          this.toast.info(`Generating using local changes`)
        }

        const status = this.documentStatus
        this.generateDocumentService.generateDocument(localRequestParams, privateConfig, headers, events, checkOnly, this.isDynamicMapping, this.isPreprocessorMapping)
          .subscribe(
            (documentResults: IngestProductDocgenTraceResponse) => {
              this.displayDocumentResults(documentResults, true, status)
            },
            (errorResponse) => {
              this.displayDocumentResults(errorResponse.error, false, status)
            }
          )
      }
    })
  }

  /***
   * Returns true if either the config or any of the rules have been modified
   * @param config The config to evaluate
   * @private
   */
  private isConfigModified(config: DocgenConfigAndMetadataChangeDetails) {
    return config.state === 'modified' || config.rulesets.some(x => x.state !== 'original')
  }

  private hasConfigChanged(currentConfig: string, originalConfig: string) {
    const actual = JSON.parse(currentConfig)
    const expected = JSON.parse(originalConfig)
    return !deepEquals(actual, expected)
  }

  displayDocumentResults = (documentResults: IngestProductDocgenTraceResponse, generateDiff: boolean, documentStatus: GenerateDocumentStatus) => {
    console.log('Displaying document details')
    console.dir(documentResults)
    // if (documentResults.config) {
    //   documentStatus.config = {
    //     settings: {
    //       content: JSON.stringify(documentResults.config, undefined, 2),
    //     } as ConfigDetail,
    //     metadata: this.config?.metadata,
    //     schemas: this.config?.schemas,
    //     transforms: this.config?.transforms,
    //   }
    //   documentStatus.configString = documentStatus.config.settings.content
    // }


    documentStatus.errorResponse = documentResults.status  ? JSON.stringify(documentResults.errors, undefined, 2) : undefined
    if(documentStatus.errorResponse){
      documentStatus.generating = false
    }
    const renderDocument = documentResults.renderDocument as SuccessRenderDocumentResult
    let base64Document: string;
    if (documentResults.config.renderDocument.service !== 'WSD_WORKFLOW') {
      base64Document = this.safeString({payload: renderDocument?.data, mimetype: 'application/pdf'})
    }

    const docMimeType = renderDocument.mimeType
    if(documentResults.config.renderDocument.service == 'PRIIP_CLOUD' || documentResults.config.renderDocument.service == 'DOC_AUTO'
      ||documentResults.config.renderDocument.service == 'WSD_WORKFLOW') {
      this.renderDocumentService = 'WSD';
    } else {
      this.renderDocumentService = 'SmartDX';
    }

    const transform = documentResults.transform as SuccessTransformResult
    if (transform.intermediatePayloads?.length === 2) {
      documentStatus.transformed1 = this.safeXml({
        payload: transform.intermediatePayloads[0].payload,
        mimetype: transform.intermediatePayloads[0].mimeType,
      })
      documentStatus.transformed2 = this.safeXml({
        payload: transform.intermediatePayloads[1].payload,
        mimetype: transform.intermediatePayloads[1].mimeType,
      })
    } else if (transform.intermediatePayloads?.length === 1) {
      documentStatus.transformed1 = this.safeXml({
        payload: transform.intermediatePayloads[0].payload,
        mimetype: transform.intermediatePayloads[0].mimeType,
      })
      documentStatus.transformed2 = undefined
    } else {
      documentStatus.transformed1 = undefined
      documentStatus.transformed2 = undefined
    }

    documentStatus.transformed = this.safeXml({payload: transform.payload, mimetype: 'application/xml'})

    const enrich = documentResults.enrich as SuccessTransformResult
    if (enrich.intermediatePayloads?.length === 2) {
      documentStatus.enriched1 = this.safeXml({
        payload: enrich.intermediatePayloads[0].payload,
        mimetype: enrich.intermediatePayloads[0].mimeType,
      })
      documentStatus.enriched2 = this.safeXml({
        payload: enrich.intermediatePayloads[1].payload,
        mimetype: enrich.intermediatePayloads[1].mimeType,
      })
    } else if (enrich.intermediatePayloads?.length === 1) {
      documentStatus.enriched1 = this.safeXml({
        payload: enrich.intermediatePayloads[0].payload,
        mimetype: enrich.intermediatePayloads[0].mimeType,
      })
      documentStatus.enriched2 = undefined
    } else {
      documentStatus.enriched1 = undefined
      documentStatus.enriched2 = undefined
    }

    documentStatus.rawResponse = documentResults
    documentStatus.enriched = this.safeXml({payload: enrich.payload, mimetype: 'application/xml'})
    const validatePayload = documentResults.validatePayload as SuccessValidateResult
    documentStatus.validated = this.safeJson({payload: validatePayload.details, mimetype: 'application/json'})

    documentStatus.smartDxMessages = renderDocument.messages
    if (documentResults.renderDocument.error) {
      documentStatus.smartDxMessages = documentStatus.smartDxMessages || []
      documentStatus.smartDxMessages.push({
        msgType: 'E',
        msgText: documentResults.renderDocument.error,
      })
    }

    documentStatus.errorResponse = documentResults.errorResponse ? JSON.stringify(documentResults.errorResponse, undefined, 2) : undefined
    documentStatus.approvalResponse = documentResults.approval ? JSON.stringify(documentResults.approval, undefined, 2) : undefined

    const analysePayload = documentResults.analysePayload as SuccessAnalyseResult
    documentStatus.analysed = this.safeJson({
      payload: analysePayload,
      mimetype: 'application/json',
    })
    const validateEnriched = documentResults.validateEnriched as SuccessValidateResult
    documentStatus.enrichedValidated = this.safeJson({
      payload: validateEnriched.details,
      mimetype: 'application/json',
    })
    const analyseEnriched = documentResults.analyseEnriched as SuccessAnalyseResult
    documentStatus.enrichedAnalysed = this.safeJson({
      payload: analyseEnriched,
      mimetype: 'application/json',
    })
    if (base64Document && base64Document.length > 2) {
      documentStatus.base64Document = base64Document
      if (base64Document && base64Document.length > 0) {
        const actual = base64js.toByteArray(base64Document)
        if (docMimeType === 'application/pdf') {
          documentStatus.pdf = actual
          if (generateDiff) {
            this.generateDiffReport(this.requestParams, this.documentStatus)
          }
        } else {
          this.generateDiffReport(this.requestParams, this.documentStatus)
          documentStatus.doc = actual
        }
      }
    } else {
      documentStatus.base64Document = undefined
    }

    const statusTabContents = this.isDeveloper ? {
      documentDecision: documentResults.decision,
      traceResponse: documentResults,
    } : {
      documentDecision: documentResults.decision,
    }
    documentStatus.status = JSON.stringify(statusTabContents, undefined, 2)

    documentStatus.generating = false
    console.log('Document Status:')
    console.dir(documentStatus)
    // if (this.tabGroup) {
    //   this.tabGroup.selectedIndex = 1
    // }
    this.refreshGraphic = '' + new Date().valueOf()

    if (documentResults.ignoredRules) {
      if (documentResults.ignoredRules.analysisPayload) {
        const ignoredAnalysePayload = documentResults.ignoredRules.analysisPayload as AnalyseRuleTable[]
        documentStatus.ignoredAnalysed = this.safeJson({
          payload: ignoredAnalysePayload,
          mimetype: 'application/json',
        })
      }
      if (documentResults.ignoredRules.analysisEnriched) {
        const ignoredAnalyseEnriched = documentResults.ignoredRules.analysisEnriched as AnalyseRuleTable[]
        documentStatus.ignoredEnrichedAnalysed = this.safeJson({
          payload: ignoredAnalyseEnriched,
          mimetype: 'application/json',
        })
      }
    }
  }

  safeJson(payload ?: PayloadDetails) {
    if (!payload || !payload.payload) {
      return undefined
    }
    if (typeof payload.payload === 'string') {
      return JSON.stringify(JSON.parse(payload.payload), undefined, 2)
    }
    if (typeof payload === 'object') {
      return JSON.stringify(payload.payload, undefined, 2)
    }
  }

  safeString(payload ?: PayloadDetails) {
    return payload && payload.payload as string
  }

  safeXml(payload ?: PayloadDetails) {
    return payload && payload.payload as string
  }

  generateDiffReport = (request: GenerateDocumentRequest, response: GenerateDocumentStatus) => {
    if (this.expectedDocument && this.expectedDocument.bytes && !response.generatingDiff) {
      response.generatingDiff = true

      if (!response.htmlDiff && response.pdf) {
        response.hasXmlDiff = false
        response.hasPdfDiff = false
        if (this.testDefinition && this.testDefinition.enriched && response.enriched) {
          this.diffService.generateXmlDiffReport(this.testDefinition.enriched, response.enriched)
            .subscribe(result => {
              const reports = result as DiffResponse
              this.displayXmlDiffResponse(reports, response)
            })
        }
        const ignorePatterns = this.testDefinition?.ignoreRegex ?? undefined
        // break the diff start
        // this.expectedDocument.bytes = base64js.toByteArray('')
        // break diff end
        this.diffService.generatePdfDiffReport(this.expectedDocument.bytes, response.pdf, false, ignorePatterns)
          .subscribe(result => {
            response.rawDiff = JSON.stringify(result, undefined, 2)
            const reports = result as DiffResponse
            this.displayPdfDiff(reports, response) 
            response.generatingDiff = false
            if(result.reports.text.error) {
                this.toast.error('Error generating pdf diff - ' + result.reports.text.error)
            }
          }, error => {
            this.toast.error('Error generating pdf diff - ' + error.message)
            // add error to documentStatus.errorResponse
            const err = JSON.parse(this.documentStatus.errorResponse);
            //console.log(Object.assign(err, error))
            const newErr = {
              type: 'TECHNICAL',
              code: 'DIFF',
              description: error.message,
              help: 'Please retry the request and if the problem persists contact support'
            }
            if(err.errors){
              err.errors.push(newErr)
            } else {
              err.errors = []
              err.errors.push(newErr)
            }
            this.documentStatus.errorResponse = JSON.stringify(err, undefined, 2)
            response.generatingDiff = false
          })
      } else {
        response.hasDocDiff = false
        this.diffService.generateWordReport(this.expectedDocument.bytes, response.base64Document, false)
          .subscribe(result => {
            this.displayWordDiff(result as DiffResponse, response);
            response.generatingDiff = false
          })
      }
    }
  }

  displayPdfDiff = (reports: DiffResponse, response: GenerateDocumentStatus) => {
    this.pdfDiffResponse = reports
    if (reports.reports && reports.reports.text && reports.reports.text.payload) {
      const payload: string[] = reports.reports.text.payload as string[]

      console.log('display',this.displayPDFDiffPayload)
      const htmlDiff = '<pre>' + payload.join('') + '</pre>'
      this.pdfDiffResponse.removedCount = reports.reports.text.deleted
      this.pdfDiffResponse.addedCount = reports.reports.text.added
      response.hasPdfDiff = reports.reports.text.payload ? true : false;
      response.htmlDiff = htmlDiff;
      this.pdfDiffResponse.ignoreTotal = reports.reports.text.totalDifferencesCount;
      this.pdfDiffResponse.ignoreCount = reports.reports.text.ignoredDifferencesCount;
    }

    this.pdfVisualDiffResponse = null;
    if (reports.reports && reports.reports.pixel && reports.reports.pixel.differences) {
      const pdfVisualDiff = JSON.parse(response.rawDiff).reports.pixel;
      let innerHtml = JSON.parse(pdfVisualDiff.payload).map(x => `<li>${x.message}${x.diffPNGBase64 ? `: </li><ul><li>Detected ${x.diffPercentage} difference between this page and the expected page (differences highlighted in neon green)</li><li><img src="data:image/png;base64,${x.diffPNGBase64}" /></li></ul>` : "</li>" }`);
      let fullHtml = `<ul>${innerHtml.join("")}</ul>`;
      this.pdfVisualDiffResponse = fullHtml;
      response.hasPdfDiff = true
    }

    response.hasFontDiff = reports.reports?.font?.differences

    this.refreshGraphic = '' + new Date().valueOf()
  }


  displayXmlDiffResponse = (reports: DiffResponse, response: GenerateDocumentStatus) => {
    this.xmlDiffResponse = reports
    if (reports.reports && reports.reports.xml && reports.reports.xml.payload) {
      response.xmlDiff = JSON.parse(reports.reports.xml.payload as string)
      response.rawXmlDiff = JSON.stringify(response.xmlDiff, undefined, 2)
      response.hasXmlDiff = (response.xmlDiff.length > 0)

      this.xmlDiffResponse.addedCount = reports.reports.xml.added
      this.xmlDiffResponse.changedCount = reports.reports.xml.changed
      this.xmlDiffResponse.removedCount = reports.reports.xml.deleted
    }
    this.refreshGraphic = '' + new Date().valueOf()
  }

  displayWordDiff = (reports: DiffResponse, response: GenerateDocumentStatus) => {
    this.pdfDiffResponse = reports
    this.documentStatus.hasDocDiff = true;
    this.documentStatus.draftableUrl = response.draftableUrl;
  }

  onNavChange(event: NgbNavChangeEvent<string>) {
    if (event.nextId === 'pdf') {
      if (this.expectedDocument) {
        this.expectedDocument.displayPdf = false
      }
      setTimeout(() => {
        console.log('Displaying pdf')
        this.documentStatus.displayPdf = true
      }, 250)
    } else if (event.nextId === 'expected') {
      this.documentStatus.displayPdf = false
      setTimeout(() => {
        console.log('Displaying expected pdf')
        this.expectedDocument.displayPdf = true
      }, 250)
    } else {
      if (this.expectedDocument) {
        this.expectedDocument.displayPdf = false
      }
      this.documentStatus.displayPdf = false
    }
  }

// downloadPdf() {
//   FileSaver.saveAs(this.documentStatus.pdf, 'preview.pdf')
// }
//
// downloadDoc() {
//   FileSaver.saveAs(this.documentStatus.doc, 'preview.docx')
// }

  resetExpected() {
    this.expectedDocument = undefined
  }

  saveAcceptanceTest() {
    this.switchTestSuiteIfNecessary(this.testDefinition && this.testDefinition.name)
    this.saveTestCase(this.expectedDocument.base64, 'Acceptance')
  }

  saveRegressionTest() {
    this.switchTestSuiteIfNecessary(this.testDefinition && this.testDefinition.name)
    this.saveTestCase(this.documentStatus.base64Document, 'Regression')
  }


  saveTestPayload() {
    this.configService.selectedDocgenConfig.subscribe(config => {
      console.log(config, 'current assigned config')
      if (!config) {
        this.toast.error('No Config key was found on the the selected test, unable to save as test payload - Please choose a config from the dropdown boxes.')
      } else {
    this.saveTestCase(undefined, 'Payload')
      }
    })
  }

  switchTestSuiteIfNecessary(testName?: string) {
    // This will...
    //   Check the currently selected test suite and if it contains this test - do nothing
    // Otherwise
    //   Check all of the test suites and for the first one it finds (if there is one) which contains this test
    //   Mark that as the selected test suite...
    // Or else - does nothing whatsoever and leaves any currently selected test suite as is...
    if (testName) {
      this.testManager.selectedTestSuite.pipe(
        tap(s => console.log('Checking the current tes suite - ' + s?.name)),
        take(1),
      ).toPromise()
        .then(currentTestSuite => {
          if (currentTestSuite && currentTestSuite.tests.some(t => t.name === testName)) {
            // It is already in the current suite
            console.log('Currently selected test suite contains the test we are saving')
            return undefined
          } else {
            console.log('Need to go through the testsuites to see if any have this test - "' + testName + '"')
            return this.testManager.testSuites.pipe(
              take(1),
            ).toPromise()
          }
        })
        .then(allTestSuites => {
          // find the first test suite which includes this test
          // console.dir(allTestSuites)
          const firstTestSuite = allTestSuites.find(suite => suite.tests.some(t => t === testName))
          // console.dir(firstTestSuite)
          // we have a test suite which includes this test :)
          if (firstTestSuite) {
            // Found a test suite, so select it
            console.log('Selecting the test suite - HOORAY')
            this.testManager.selectTestSuite(firstTestSuite.name)
          }
          else if (!firstTestSuite) {
            this.testManager.selectTestSuite(undefined)
          }
        })
    }
  }


  saveTestCase(document, testType: TestType) {
    const product: PayloadInformation = {
      service: 'docgen',
      documentFormat: this.requestParams.documentFormat,
      documentType: this.requestParams.documentType,
      productType: this.requestParams.productType,
      programme: this.requestParams.programme,
      sourceSystem: this.requestParams.sourceSystem,
      sourceFormat: this.requestParams.sourceFormat,
    }

    if (this.isDynamicMapping) {
      product.dynamicMapping = 'true'
      product.productType = undefined
      product.programme = undefined
    }else if(this.isPreprocessorMapping) {
      product.preprocessorMapping = 'true'
      product.productType = undefined
      product.programme = undefined
    }

    const test: TestDefinition = {
      name: '',
      testType,
      document,
      enriched: this.documentStatus && this.documentStatus.enriched,
      payload: this.requestParams.payload,
      product,
      migrated: this.testDefinition && this.testDefinition.migrated,
      config: undefined,
      expectedOutcome: 'Allow',
      schemas: {},
      transforms: {},
      ignoreRegex: this.testDefinition?.ignoreRegex,
      visualDiff: this.isVisualDiffMapping
    }
    test.language = this.payloadLanguageValue !== 'None' ? this.payloadLanguageValue : undefined
    test.country = this.payloadCountryValue !== 'None' ? this.payloadCountryValue : undefined
    test.jurisdiction = this.payloadJurisdictionValue !== 'None' ? this.payloadJurisdictionValue : undefined
    const testFailureSummary = this.getTestFailureSummary(this.documentStatus.status)

    if (testType === 'Regression' && this.hasFailures(testFailureSummary)) {
      const negativeTestDialog = this.dialog.open(SaveNegativeTestDialogComponent, {
        width: '500px',
        data: {
          failures: testFailureSummary,
        } as SaveNegativeTestDetails,
        panelClass: 'dialogFormField500',
      })
      negativeTestDialog.afterClosed()
        .subscribe(async (data: SaveNegativeTestDetails) => {
          if (data) {
            const dialogRef = this.dialog.open(SaveTestDialogComponent, {
              width: '500px',
              data: {
                title: 'Test Name',
                name: this.testDefinition && this.testDefinition.name || 'A Test',
                isNew: this.testDefinition === undefined,
                comment: '',
              },
              panelClass: 'dialogFormField500',
            })
            dialogRef.afterClosed().subscribe(async (result: SaveTestDialogData) => {
              if (result) {
                this.addExpectedFailuresToTestDefinition(test, testFailureSummary)
                test.name = result.name
                test.comment = result.comment
                this.reallySaveTest(test, result.name, result.suite)
              }
            })
          }
        })
    } else {
      const dialogRef = this.dialog.open(SaveTestDialogComponent, {
        width: '500px',
        data: {
          title: 'Test Name',
          name: this.testDefinition && this.testDefinition.name || 'A Test',
          isNew: this.testDefinition === undefined,
          comment: '',
        },
        panelClass: 'dialogFormField500',
      })
      dialogRef.afterClosed().subscribe((result: SaveTestDialogData) => {
        if (result) {
          test.name = result.name
          test.comment = result.comment
          this.reallySaveTest(test, result.name, result.suite)
        }
      })
    }
  }

  private hasFailures(summary: TestFailureSummary): boolean {
    if (!summary) {
      return false
    }
    return summary.decision === 'Deny'
    // || summary.payloadNotSupportedMatches.length > 0
    // || summary.payloadBlacklistMatches.length > 0
    // || summary.payloadValidationMatches.length > 0
    // || summary.payloadWhitelistUnmatched.length > 0
    // || summary.soloNotSupportedMatches.length > 0
    // || summary.soloBlacklistMatches.length > 0
    // || summary.soloValidationMatches.length > 0
    // || summary.soloWhitelistUnmatched.length > 0
  }

  private getTestFailureSummary(documentStatus: string): TestFailureSummary {
    if (documentStatus) {
      const status = JSON.parse(documentStatus) as IngestProductDocgenTraceResponse
      const decision = status.decision
      const analysePayload = status.analysePayload
      const analyseSolo = status.analyseEnriched

      return {
        decision: decision,
        analysePayloadResult: analysePayload ? analysePayload.result : undefined,
        // TODO - FIX THIS!!!
        // payloadNotSupportedMatches: analysePayload ? analysePayload.notSupported.matchedRows : [],
        // payloadBlacklistMatches: analysePayload ? analysePayload.ruleMatchResults.blacklist.matchedRows : [],
        // payloadValidationMatches: analysePayload ? analysePayload.ruleMatchResults.validation.matchedRows : [],
        // payloadWhitelistUnmatched: analysePayload ? analysePayload.ruleMatchResults.whitelist.unmatchedRules : [],
        analyseSoloResult: analyseSolo ? analyseSolo.result : undefined,
        // soloNotSupportedMatches: analyseSolo ? analyseSolo.ruleMatchResults.notSupported.matchedRows : [],
        // soloBlacklistMatches: analyseSolo ? analyseSolo.ruleMatchResults.blacklist.matchedRows : [],
        // soloValidationMatches: analyseSolo ? analyseSolo.ruleMatchResults.validation.matchedRows : [],
        // soloWhitelistUnmatched: analyseSolo ? analyseSolo.ruleMatchResults.whitelist.unmatchedRules : [],
      } as TestFailureSummary
    } else {
      return undefined
    }
  }

  private addExpectedFailuresToTestDefinition(test: TestDefinition, failures: TestFailureSummary): void {

    test.expectedOutcome = failures.decision === 'Deny' ? 'Deny' : 'Allow'

    if (failures.analysePayloadResult === 'ERROR') {
      if (test.expectedFailChecks === undefined) {
        test.expectedFailChecks = []
      }
      test.expectedFailChecks.push('analyse-payload')
    }
    if (failures.analyseSoloResult === 'ERROR') {
      if (test.expectedFailChecks === undefined) {
        test.expectedFailChecks = []
      }
      test.expectedFailChecks.push('analyse-solo')
    }

    // NOT SUPPORTED
    if (failures.payloadNotSupportedMatches.length > 0) {
      if (test.expectedFailChecks === undefined) {
        test.expectedFailChecks = []
      }
      test.expectedFailChecks.push('not-supported-rules')
      if (test.expectedFailMnemonics === undefined) {
        test.expectedFailMnemonics = []
      }
      test.expectedFailMnemonics.push(...failures.payloadNotSupportedMatches.map(mnem => 'PU-' + mnem))
    }
    if (failures.soloNotSupportedMatches.length > 0) {
      if (test.expectedFailChecks === undefined) {
        test.expectedFailChecks = []
      }
      test.expectedFailChecks.push('not-supported-rules')
      if (test.expectedFailMnemonics === undefined) {
        test.expectedFailMnemonics = []
      }
      test.expectedFailMnemonics.push(...failures.soloNotSupportedMatches.map(mnem => 'SU-' + mnem))
    }

    // BLACKLIST
    if (failures.payloadBlacklistMatches.length > 0) {
      if (test.expectedFailChecks === undefined) {
        test.expectedFailChecks = []
      }
      test.expectedFailChecks.push('blacklist-rules')
      if (test.expectedFailMnemonics === undefined) {
        test.expectedFailMnemonics = []
      }
      test.expectedFailMnemonics.push(...failures.payloadBlacklistMatches.map(mnem => 'PB-' + mnem))
    }
    if (failures.soloBlacklistMatches.length > 0) {
      if (test.expectedFailChecks === undefined) {
        test.expectedFailChecks = []
      }
      test.expectedFailChecks.push('blacklist-rules')
      if (test.expectedFailMnemonics === undefined) {
        test.expectedFailMnemonics = []
      }
      test.expectedFailMnemonics.push(...failures.soloBlacklistMatches.map(mnem => 'SB-' + mnem))
    }

    // VALIDATION
    if (failures.payloadValidationMatches.length > 0) {
      if (test.expectedFailChecks === undefined) {
        test.expectedFailChecks = []
      }
      test.expectedFailChecks.push('validation-rules')
      if (test.expectedFailMnemonics === undefined) {
        test.expectedFailMnemonics = []
      }
      test.expectedFailMnemonics.push(...failures.payloadValidationMatches.map(mnem => 'PV-' + mnem))
    }
    if (failures.soloValidationMatches.length > 0) {
      if (test.expectedFailChecks === undefined) {
        test.expectedFailChecks = []
      }
      test.expectedFailChecks.push('validation-rules')
      if (test.expectedFailMnemonics === undefined) {
        test.expectedFailMnemonics = []
      }
      test.expectedFailMnemonics.push(...failures.soloValidationMatches.map(mnem => 'SV-' + mnem))
    }

    // WHITELIST
    if (failures.payloadWhitelistUnmatched.length > 0) {
      if (test.expectedFailChecks === undefined) {
        test.expectedFailChecks = []
      }
      test.expectedFailChecks.push('whitelist-rules')
      if (test.expectedFailMnemonics === undefined) {
        test.expectedFailMnemonics = []
      }
      test.expectedFailMnemonics.push(...failures.payloadWhitelistUnmatched.map(mnem => 'PW-' + mnem))
    }
    if (failures.soloWhitelistUnmatched.length > 0) {
      if (test.expectedFailChecks === undefined) {
        test.expectedFailChecks = []
      }
      test.expectedFailChecks.push('whitelist-rules')
      if (test.expectedFailMnemonics === undefined) {
        test.expectedFailMnemonics = []
      }
      test.expectedFailMnemonics.push(...failures.soloWhitelistUnmatched.map(mnem => 'SW-' + mnem))
    }

    if (test.expectedFailChecks !== undefined) {
      test.expectedFailChecks = Array.from(new Set(test.expectedFailChecks))
    }
  }

  private reallySaveTest(test: TestDefinition, name: string, suite: string | undefined): void {
    console.log('Saving test: ' + name)
    this.testManager.saveTest(test)
      .then(res => {
        console.log('Saved Test: ', res)
        if (suite) {
          return this.testManager.getTestSuite(suite)
        } else {
          return undefined
        }
      })
      .then((currentSuite: GetTestSuiteResponse) => {
        if (currentSuite) {
          if (!currentSuite.tests.some(t => t.name === name)) {
            console.log('Will add the test to the suites')
            const updatedTestSuite: TestSuiteDefinition = {
              ...currentSuite,
              tests: [...currentSuite.tests.map(t => t.name), name],
            }
            this.testManager.saveTestSuite(updatedTestSuite)
              .then(res => {
                this.toast.success('Added test to test suite')
              })
          }
        }
      })
  }

  uploadExpectedFile(event: Event) {
    // @ts-ignore
    if (event.target.files && event.target.files.length) {
      // @ts-ignore
      const [file] = event.target.files
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        new Response(file).arrayBuffer().then(buffer => {
          this.expectedDocument = new ExpectedDocument()
          this.expectedDocument.file = file
          this.expectedDocument.bytes = new Uint8Array(buffer)
          this.expectedDocument.base64 = base64js.fromByteArray(this.expectedDocument.bytes)
          this.generateDiffReport(this.requestParams, this.documentStatus)
        })
      }
    }
  }

  draftableReport(preview: boolean = false) {
    const response = this.documentStatus
    console.log('Generating Draftable Diff...')
    if (this.expectedDocument && this.expectedDocument.bytes && !response.generatingDiff && !response.draftableUrl) {
      if(response.pdf) {
        response.generatingDiff = true
        this.diffService.generatePdfDiffReport(this.expectedDocument.bytes, response.pdf, true)
          .subscribe(result => {
            console.log('Got response: ' + JSON.stringify(result, undefined, 2))
            response.draftableUrl = result.draftableUrl
            response.generatingDiff = false
            const url = result.draftableUrl.replace('/viewer/', '/viewer/' + (preview ? '3.5.12/' : ''))
            window.open(url, 'blank')
          })
      } else {
        response.generatingDiff = true
        this.diffService.generateWordReport(this.expectedDocument.bytes, response.base64Document, true)
          .subscribe(result => {
            console.log('Got response: ' + JSON.stringify(result, undefined, 2))
            response.draftableUrl = result.draftableUrl
            response.generatingDiff = false
            const url = result.draftableUrl.replace('/viewer/', '/viewer/' + (preview ? '3.5.12/' : ''))
            window.open(url, 'blank')
          })
      }
    }
  }

  viewDraftableReport(preview: boolean = false) {
    const url = this.documentStatus.draftableUrl.replace('/viewer/', '/viewer/' + (preview ? '3.5.12/' : ''))
    window.open(url, 'blank')
  }

  private asConfigDetail(item: ChangeDetails) {
    return {
      name: item.name,
      path: item.path,
      content: item.content,
    } as ConfigDetail
  }

  testReset() {
    console.log('Reset Test')
    this.resetDocumentStatus('test reset')
    this.router.navigate(['/generate'])
  }

  selectTestHistory(history: TestHistory) {
    this.testManager.getTest(this.testDefinition.name, history.version)
      .subscribe((res: TestDefinition) => {
        this.assignTestDefinition(res)
        const date = history.date
        this.loadedVersion = date.toLocaleDateString()
        this.toast.info('Loaded version from ' + date.toLocaleDateString())
      })
  }

  openJmesPathDialog() {
    try {
      const json = JSON.parse(this.payloadValue)
      const dialog = this.ngbModal.open(JmesPathEvaluatorDialogComponent, {
        scrollable: true,
        size: 'xl',
      })
      dialog.componentInstance.payload = json
    } catch (e) {
    }
  }

  openRegExDialog(){
    const dialog = this.ngbModal.open(RegexEvaluatorDialogComponent, {
      scrollable: true,
      size: 'lg',
      'backdrop':'static'
    })
    dialog.componentInstance.payload = this.testDefinition

    dialog.result.then((result) => {
      if (result) {
       if(result =='close'){
        return;
       }
       this.testDefinition = result
       console.log('result',result)
      }
    } )

  }


  refresh() {
    this.payloadSubject.next(this.payloadValue)
    this.csSchemaSubject.next(this.csSchemaValue)
    this.transform1Subject.next(this.transform1Value)
    this.transform2Subject.next(this.transform2Value)
    this.enrich1Subject.next(this.enrich1Value)
    this.enrich2Subject.next(this.enrich2Value)
    this.targetSchemaSubject.next(this.targetSchemaValue)
  }

  saveChartConfiguration() {
    const options = this.chartOptions
    delete this.chartOptions.scales.xAxes[0].ticks.callback
    const chartConfiguration = {
      type: 'line',
      data: {
        datasets: this.chartDatasets.map(data => {
          const r = Object.assign({}, data)
          delete r['_meta']
          return r
        }),
      },
      options: options,
    }
    console.dir(chartConfiguration)
    this.clipboardService.copyFromContent(JSON.stringify(chartConfiguration, undefined, 2))
    // @ts-ignore
    this.chartOptions.scales.xAxes[0].ticks.callback = (value: string, index: number, values: { label: string, value: number, major: boolean }[]) => {
      if (index === 0 || index === values.length - 1 || index % 1000 === 0) {
        console.dir({
          value: value,
          index: index,
          values: values,
        })
      }
      if (index === values.length - 1) {
        values[index].major = true
      }
      if (/\n/.test(value)) {
        return value.split(/\n/)
      }
      return value
    }
  }
}
