import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router'
import {TestDefinition} from '../common/domain/tests'
import {Observable} from 'rxjs'
import {TestManagerService} from '../services/test-manager.service'

@Injectable({providedIn: 'root'})
export class TestResolver implements Resolve<TestDefinition> {
  constructor(private testManagerService: TestManagerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TestDefinition> {
    return this.testManagerService.getTest(route.paramMap.get('testId'), route.paramMap.get('version'))
  }
}
