<div class="md-form form-inline form-group" *ngIf="useWorkspace">
  <mat-form-field class="rule-type-input">
    <mat-label>Workspace</mat-label>
    <mat-select [disabled]="disabled" (selectionChange)="selectWorkspace($event)" [(value)]="selectedWorkspace" placeholder="Select">
      <mat-option></mat-option>
      <mat-option *ngFor="let workspace of workspaces" [value]="workspace"
                  [title]="workspace">{{workspace}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
