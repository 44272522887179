<form [formGroup]="complexRulesForm" (ngSubmit)="submit()">
  <fieldset class="form-group">
    <div class="form-group row">
      <label for="typeSelect" class="col-form-label col-sm-3">Type:</label>
      <div class="col-sm-3">
        <select
        formControlName="type"
        id="typeSelect"
        class="form-control form-control-sm "
      >
        <option disabled selected readonly>Select type</option>
        <option value="Complex" >Complex</option>
        <option value="Combo" >Combo</option>
      </select>
      </div>
      
    </div>

    <div class="form-group row" *ngIf="selectedRule">
      <label for="code" class="col-sm-3 col-form-label"> Code: </label>
      <div class="col-sm-3">
        <input
        formControlName="id"
        id="code"
        class="form-control form-control-sm"
      />
        </div>
    </div>

    <div class="form-group row">
      <label for="description" class="col-sm-3 col-form-label">
        Description:
      </label>
      <div class="col-sm-8">
        <input
          formControlName="description"
          id="description"
          class="form-control form-control-sm "
        />
      </div>
    </div>

    <div class="form-group row">
      <label for="ruleSelect" class="col-form-label col-sm-3">Pick Rule:</label>
      <div class="col-sm-6">
        <select
        formControlName="rule"
        id="ruleSelect"
        class="form-control form-control-sm selectpicker"
      >
        <option [ngValue]="null" [disabled]="true" class="placeholder">Rules</option>
        <option *ngFor="let rule of availableRules" [ngValue] = "rule" >{{rule.id + '     ' + rule.description}}</option>
      </select>
      </div>
      
      <div class="col-sm-2 pr-0">
        <button
          class="btn btn-info btn-sm col-sm-8 float-right"
          type="button"
          (click)="addRule()"
          [disabled] = 'isRuleTypeSelected === false &&  selectedRule === undefined ? true : false' 
        >
          Add
        </button>
      </div>
    </div>
  </fieldset>

  <div class="form-group table-container table-responsive mr-4">
    <table class="table table-borderless table-hover mb-0">
      <thead>
        <tr>
          <th scope="col" class="col-sm-2">Rule</th>
          <th scope="col" class="col-sm-7">Description</th>
          <th scope="col" class="col-sm-2 text-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rule of pickedRules">
          <td>{{rule.id}}</td>
          <td>{{rule.description}}</td>
          <td>
            <button class="btn btn-danger btn-sm float-right" (click)="removeRule(rule)">Remove</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- action buttons section -->
  <div class="form-row justify-content-end mt-4">
    <button class="btn btn-secondary me-2"  (click)="closeModal()">
      Close
    </button>

    <button class="btn btn-primary me-4" type="submit" [disabled]="!complexRulesForm.valid || pickedRules.length < 1">
      Save
    </button>
  </div>
</form>
