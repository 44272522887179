<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="form-group">
      <label for="repositoryInput">Service</label>
      <select id="repositoryInput" class="form-control" (change)="repository = $event.target['value']">
        <ng-container *ngIf="type === 'samples'">
          <option value="auto-deploy-templates">Templates</option>
        </ng-container>
        <ng-container *ngIf="type !== 'samples'">
          <option value="lambda-ingest-product">Document Generation</option>
          <option value="api-publish">Publishing (RegXchange)</option>
        </ng-container>
      </select>
    </div>
    <div class="form-group">
      <label for="nameInput">Name</label>
      <input class="form-control" id="nameInput" [(ngModel)]="name" placeholder="Name">
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
  <button type="button" class="btn btn-primary" [disabled]="!repository || !name" ngbAutoFocus (click)="confirm()">OK</button>
</div>
