
<div class='flex-page-container local-dialog-container-padding' [class.loading]="loading">

  <div class="flex-row inspire-surround-header-flex">
    <span class="h2">{{ this.screenTitle }}</span><span>
      <div>&nbsp;&nbsp;({{ this.camelCaseToWords(this.dialogData.normalisedRuleSet?.ruleType) }}&nbsp;RuleSet for {{
        this.dialogData.normalisedRuleSet.stage }}&nbsp;stage)</div>
    </span>
  </div>

  <div class='flex-content-container' [class.disable-mouse]='this.CurrentlyExecuting'>

    <div class='flex-right-main-content'>

      <div class='flex-row align-items-end section-tab-layout inspire-surround-label-flex'>

        <div class='flex-row-grow-one'>
          <nav>
            <span *ngFor="let curTabObj of this.allPossibleTabs"
              [class.nav-selected]="this.selectedTabEnumName === curTabObj.name"
              (click)='this.actionSelectTab(curTabObj.name)'>
              {{ this.camelCaseToWords(curTabObj.name) }}
            </span>
          </nav>
        </div>

        <div class='flex'>
          <ng-container *ngIf='!this.viewOnly'>
            Enable Delete&nbsp;
            <mat-slide-toggle [checked]="this.deleteEnabled" (change)="this.actionToggleDeleteEnabled($event)">
            </mat-slide-toggle>
          </ng-container>
        </div>

      </div> <!-- inspire-surround-flex -->

      <div class='inspire-surround-flex'>
        
        <!-- Tab 1 Basics -->
        <div id='Basics1' *ngIf="this.selectedTabEnumName === 'Basics' && !!this.normalisedRuleSet"
          [class.disable-mouse]=this.viewOnly class='small-margins'>

          <!-- Fields applicable to entire RuleSet -->

          <div *ngIf=this.deleteEnabled>
            <button type="button" class="btn btn-danger" (click)='this.actionDeleteRuleSet()'>Delete RuleSet</button>
          </div>
          <div>
          <label for='ruleSetName'  class='inspire-surround-label-flex'>Name:</label>           
        </div>
          <div class="ruleSetName">
            <input type="text" class="rem40w" [(ngModel)]="this.normalisedRuleSet.name"
              required (input)=this.validateTabs() />
            </div>

          <label for='description' class='inspire-surround-label-flex'>Description:</label>
          <div class="inspire-surround-flex flex-column-grow-one">
            <input id='description'  type="text" class='rem40w' [(ngModel)]="this.normalisedRuleSet.description"
              required (input)=this.validateTabs() />
          </div>

          <label for='help' class='inspire-surround-label-flex'>Help:</label>
          <div class="inspire-surround-flex flex-column-grow-one">
            <input id='help' type="text" class='rem40w' [(ngModel)]="this.normalisedRuleSet.help"
              (change)=this.validateTabs() />
          </div>
          <!-- 
          <label for='test' class='inspire-surround-label-flex'>Test:</label>
          <div class="inspire-surround-flex flex-column-grow-one">
            <input id='test' type="text" class='rem30w' [(ngModel)]="this.normalisedRuleSet.test" maxlength="50"
              (input)=this.validateTabs() />
          </div> -->

          <label for='documentFormatType' class='inspire-surround-label-flex'>Document Format Type this RuleSet applies to:</label>
          <div>
            <select class="form-control mt-2" class='rem5w' id="documentFormatType" name="Document Format Type"
              [(ngModel)]="this.chosenDocFormatType" (input)=this.validateTabs()>
              <option *ngFor="let formatType of documentFormatTypes">{{formatType}}</option>
            </select>
          </div>

          <label for='approvalRequiredRegEx' class='inspire-surround-label-flex'>Approval Required Regular Expression:
            &nbsp;&nbsp;
            <mat-slide-toggle [checked]="this.normalisedRuleSet.approvalRequired" (change)="toggleApprovalRequiredRegEx()">
            </mat-slide-toggle>
          </label>
          
          <div class="inspire-surround-flex flex-column-grow-one">
            <input  *ngIf="this.normalisedRuleSet.approvalRequired" id='approvalRequiredRegEx' class='rem40w' type="text" [(ngModel)]="this.normalisedRuleSet.approvalRequiredRegEx"
              (input)=this.validateTabs() />
          </div>
          <br>
        </div>
        <div class="options-container"
            *ngIf="this.selectedTabEnumName === 'Basics' && this.normalisedRuleSet?.ruleType === 'complex'">
            <label class="rulesetLabel">Rules:</label>
            <div class="optionset-div" for='documentFormatType' multiple>
                <input type="text" (keyup)="setFilter($event.target.value)" placeholder="Filter" class="mat-input-placeholder">
                <div class="optionsetWidth" *ngFor="let ruleSet of this.listOfWhiteListRules;trackBy: mnemonic"
                    id="documentFormatType">
                    <div *ngIf="ruleSet.visible" class="whiteListRule">{{ruleSet.mnemonic}} - {{ruleSet.name}}
                        <div *ngFor="let mnemonic of ruleSet.rule; trackBy: mnemonic">
                            <div>
                                <mat-option class="optionsetWidth"
                                    (click)="this.isSelectedRule(ruleSet.mnemonic,mnemonic.mnemonic)"
                                    [value]="ruleSet.mnemonic+'-'+mnemonic.mnemonic"><input type="checkbox" id="checked-Rule"
                                        [checked]="mnemonic.isSelected" type="checkbox">
                                    &nbsp;&nbsp;{{ruleSet.mnemonic}}-{{mnemonic.mnemonic}}</mat-option>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button id="clear-all" class="btn btn-danger" (click)="this.clearAllOptions()">Clear</button>
            <button type="button" class="btn btn-info" id="new-btn" title="Add new Rule combination"
                (click)=this.actionAddNewRuleCombination() [disabled]="!this.addedNewRuleCombination">New</button><br>
            <button type="button" class="btn btn-info" id="update-btn" title="Add new Rule combination"
                [disabled]="!this.updateRuleCombination || this.displayRuleCombination.length > 1 && this.displayRuleCombination[0].length-1 !== this.combinations.length"
                (click)="this.actionUpdateSelectedRulesCombination()">Update</button><br>
        </div>
        <div id="combination-container"*ngIf="this.selectedTabEnumName === 'Basics' && this.normalisedRuleSet?.ruleType === 'complex'">
            <hr class="hr-ruler">
            <label class='inspire-surround-label-flex combo-label'>Combinations:</label>
            <div id="combinationList">
                <ul>
                    <li *ngFor="let comb of this.displayRuleCombination" class="inside-input">
                        <div (click)="this.actionRulecombinationSelected($event)" class="comb-input-div"><input type="text"
                                value="{{comb}}" class="inside-input" #updatedValue
                                (click)="this.actionRulecombinationSelected($event)"
                                (input)="this.actionCombinationInputValue($event)"required></div>
                        <button id="update-combination" type="button" class="btn btn-info"
                            (click)="this.actionSaveUpdateCombinationInputValue(comb)"
                            [disabled]="!this.isInputUpdate ||
                             comb.toString() !== this.updatedCombination.toString()">Update</button>
                        <button id="delete-combination" type="button" class="btn btn-danger"
                            (click)="this.deleteSelectedRulesCombination(comb)">Delete</button>
                    </li>
                </ul>
            </div>
        </div>
        <!-- End: Tab 1 Basics-->
{{loading}}
        <!-- Tab 1 Rules for each Field -->
        <div id='Basics2' *ngIf="this.selectedTabEnumName === 'Basics' && this.normalisedRuleSet?.ruleType !== 'complex'">

          <table class="table table-responsive align-middle" [class.disabled-opacity]=this.viewOnly>

            <thead>
              <tr>
                <!-- xyzzy Move this to editable fields above the Rules -->
                <!-- <th>
                  <ng-container *ngIf="rule.ruleType !== 'validation'">
                    <fa-icon icon="car-crash"
                      (click)="setErrorMessage(rule.ruleType, rule.mnemonic, undefined, rule.description, rule.help)">
                    </fa-icon>
                  </ng-container>
                </th> -->
                <th *ngIf="deleteEnabled"></th>
                <th style="max-width:100px">Rule Mnemonic</th>
                <th *ngFor="let curField of this.normalisedRuleSet?.fields" align="left" style="overflow-wrap: break-word;max-width: 380px;"><div>{{curField}}</div></th>
                <!-- Plugh soon <th>actions</th> -->
                <!-- xyzzy Relocate to its own tab -->
                <!-- <th>ignore rule in</th> -->
                <th>Test</th>
                <th></th>
              </tr>

            </thead>

            <tbody>
              <!-- AnalyseRow[] --> 
              <tr *ngFor="let curAnalyseRow of this.filteredNormalisedRulesetRules">
                <td *ngIf="deleteEnabled">
                  <button type="button" class="btn btn-danger"
                    (click)='this.actionDeleteRule(curAnalyseRow.mnemonic)'>Delete</button>
                </td>
                <td>{{ curAnalyseRow.mnemonic }}</td>
                <td *ngFor="let curField of curAnalyseRow.expected" align="left">{{curField}}</td>
                  <td>
                    {{curAnalyseRow?.test}}</td>
                  <td>
                    <button title="Assign or Relace test for rule" class="btn btn-info" (click)=this.getRecomendedTest(curAnalyseRow.mnemonic,curAnalyseRow.test)
                    [disabled]="this.viewOnly" type="button">Assign Test
                    </button>
                  </td>
              </tr>
              <tr>
                <td *ngIf="!addingNewRule">
                
                  <button type="button" class="btn btn-info" data-toggle="tooltip" data-placement="top"
                    title="Add new Rule to RuleSet" [disabled]="this.viewOnly" (click)=this.actionOpenAddNewRule()>Add New Rule</button>
            
              </td>
                <ng-container *ngIf="addingNewRule">
                 
                  <td *ngIf="deleteEnabled"></td>
                  <td>{{ nextRuleMnemonicInSequence }}</td>
                  <td *ngFor="let curAnalyseRow of this.normalisedRuleSet?.fields; let i = index" align="left">
                    <div class="input-group">
                    <select class="form-select" style="min-width: 175px;max-width: 175px" id="newOperators" name="newOperators"
                    [(ngModel)]="newOperators[i]" (change)="validateTabs()" required>
                    <option value="" disabled>Choose operator</option>
                    <option *ngFor="let operatorType of operatorTypeItems" [ngValue]="operatorType">{{operatorType.id}}</option>
                  </select>
                    <input id='test' type="text" class="form-control" [(ngModel)]="newFieldValues[i]"
                      (input)="validateTabs()" [attr.required]="newOperators[i]?.id == 'ignored' || newOperators[i]?.id == 'present' || newOperators[i]?.id == 'isempty' ? undefined : true" [disabled]="newOperators[i]?.id == 'ignored' || newOperators[i]?.id == 'present' || newOperators[i]?.id == 'isempty' ? true : false"  />
                  </div>
                    </td>
                <td></td>
                <td></td>
                </ng-container>

              </tr>
            </tbody>
          </table>

        </div>
        <!-- End: Tab 1 Rules for each Field -->

        <!-- Tab 2 ProductDocumentApplications -->
        <div id='ProductDocumentApplications' *ngIf="this.selectedTabEnumName === 'ProductDocumentApplications'"
          [class.disabled-opacity]=this.viewOnly>
          <div class="flex-row max-height-hack">
            <mat-tree [dataSource]="treeViewDataSource" [treeControl]="treeControl" class="example-tree">
              <!-- This is the tree node template for leaf nodes -->
              <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
                <li *ngIf=!node.hide class="mat-tree-node" [class.disable-pointer-events]=this.viewOnly>
                  <!-- use a disabled button to provide padding for tree leaf -->
                  <button mat-icon-button disabled></button>
                  <mat-checkbox class="checklist-leaf-node" [checked]="isSelected(node)"
                    (change)="actionToggleSelection(node)">{{ node.name }}</mat-checkbox>
                </li>
              </mat-tree-node>

              <!-- This is the tree node template for expandable nodes -->
              <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
                (input)=this.validateTabs()>
                <li [class.disable-pointer-events]=this.viewOnly>
                  <div class="mat-tree-node">
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                      <mat-icon class="mat-icon-rtl-mirror">
                        {{
                        treeControl.isExpanded(node)
                        ? "expand_more"
                        : "chevron_right"
                        }}
                      </mat-icon>
                    </button>
                    <mat-checkbox class="checklist-leaf-node" [checked]="isSelected(node)"
                      (change)="actionToggleSelection(node)">{{ node.name }}</mat-checkbox>
                  </div>
                  <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                  </ul>
                </li>
              </mat-nested-tree-node>
            </mat-tree>
            <!-- class='flex-grid-left'> -->

          </div>

        </div>

        <!-- Tab 3 Ignore RuleSets or just individual Rules-->
        <div id='IgnoreRuleOrEntireRuleSets' *ngIf="this.selectedTabEnumName === 'IgnoreRuleOrEntireRuleSets'"
          [class.disable-mouse]=this.viewOnly>

          <app-ruleset-2021-ignore (onRuleIgnored)="this.validateTabs()" [ruleSetMnemonic]="this.dialogData.normalisedRuleSet.mnemonic">
          </app-ruleset-2021-ignore>

        </div>

        <!-- Tab 4 Deleted Rules-->
        <div id='DeletedRules' *ngIf="this.selectedTabEnumName === 'DeletedRules'" [class.disable-mouse]=this.viewOnly>
          <table class="table table-responsive table-borderless text-nowrap" [class.disabled-opacity]=this.viewOnly>

            <thead>
              <tr>
                <th>Restore</th>
                <th>Rule Mnemonic</th>
                <th *ngFor="let curField of this.normalisedRuleSet?.fields" align="left">{{curField}}</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let curAnalyseRow of this.deletedNormalisedRulesetRules">
                <td>
                  <button type="button" class="btn btn-danger"
                          (click)='this.actionRestoreRule(curAnalyseRow.mnemonic)'>Restore</button>
                </td>
                <td>{{ curAnalyseRow.mnemonic }}</td>
                <td *ngFor="let curField of curAnalyseRow.expected" align="left">{{curField}}</td>
              </tr>
            </tbody>
          </table>
        </div>
       
      </div> <!-- inspire-surround-flex -->

      <div>
        <div class='flex-row-grow-one inspire-surround-flex'>
          <button *ngIf=!this.viewOnly type="button" class="btn btn-primary me-2" (click)="this.actionSave()"
            [disabled]=!this.isReadyToSave>Save</button>
          <button *ngIf=this.canRestore type="button" class="btn btn-primary me-2" (click)="this.actionRestoreRuleset()">
            Restore</button>
          <button type="button" class="btn btn-secondary" (click)="this.actionCancel()">{{ this.isDirty ? 'Cancel' :
            'Close' }} </button>
        </div>
      </div>

    </div>

  </div>

</div>
<!-- flex-page-container -->
