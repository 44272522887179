import {ChangeService} from '../services/change.service';

export class BasicHelper {

    public static delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    public static async WaitForChangeServiceConstructorToComplete(changeService: ChangeService) {
        let isChangeServiceReady = changeService.IsConstructorComplete
        while (!isChangeServiceReady) {
            //   console.log('Waiting for Change Service constructor to complete')
            await BasicHelper.delay(100) // wait 100ms
            isChangeServiceReady = changeService.IsConstructorComplete
        }
        // console.log('Change Service constructor completed')
    }

}
