<div *ngIf="tables && analysisReport">
  <ng-container *ngIf="!governanceMode">

    <div class="analysis-header">Analysis Result: <span
      [className]="analysisStatus === 'WARNING' ? 'analysis-rule-warning' : analysisStatus === 'ERROR' ? 'analysis-rule-error' : 'analysis-rule-ok'">{{analysisStatus}}</span>
    </div>

    <mat-accordion *ngIf="tables.length > 0" hideToggle="true">
      <mat-expansion-panel *ngFor="let rule of tables">
        <mat-expansion-panel-header>
          <mat-panel-title>{{rule.ruleType}} {{rule.name}} {{rule.mnemonic}}</mat-panel-title>
          <mat-panel-description
            [className]="rule.status === 'ERROR' ? 'analysis-rule-error' : rule.status === 'IGNORED' ? 'analysis-rule-ignored' : 'analysis-rule-ok'">{{rule.status}}</mat-panel-description>
        </mat-expansion-panel-header>
        <!-- {{ rule | json }} -->
        <ng-container *ngIf="rule.ruleType !== 'complex'" >
          <table class="rule-results table-responsive">
            <thead>
            <tr class="analysis-rule-path">
              <th *ngFor="let field of rule.fields"
                  align="left">{{field}}</th>
              <th>code</th>
              <th></th>
            </tr>
            <tr class="analysis-rule-value">
              <th *ngFor="let value of rule.actual" align="left">{{value.value}}</th>
              <th></th>
              <th>
              </th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor=" let row of rule.rule" [className]="row.matched ? 'matched-rule-' + rule.ruleType : 'unmatched-rule'">
                <td *ngFor="let result of row.expected"
                    [className]="result.matched ? 'analysis-rule-matched' : 'analysis-rule-not-matched'">{{result.value}}<!--{{result | json}}--></td>
                <td>{{rule.mnemonic}}-{{row.mnemonic}}</td>
                <td>
                  <span *ngIf="row.ignored" class='ignored-row-mnemonic'>mnemonic ignored</span>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="rule.ruleType === 'complex'">
          <mat-expansion-panel *ngFor="let complexRule of rule.linkedRuleResults">
            <mat-expansion-panel-header>
              <mat-panel-title [class]="complexRule.matched ? 'analysis-rule-matched' : 'analysis-rule-not-matched'">{{complexRule.combination}}</mat-panel-title>
              <mat-panel-description
                [className]="complexRule['status'] === 'OK' ? 'analysis-rule-ok' : 'analysis-rule-not-matched'">{{complexRule['status']}}</mat-panel-description>
            </mat-expansion-panel-header>
            <mat-expansion-panel *ngFor="let rule of complexRule.combinationResults">
              <mat-expansion-panel-header>
                <mat-panel-title [class]="rule.matched ? 'analysis-rule-matched' : 'analysis-rule-not-matched'">{{rule.mnemonic}}</mat-panel-title>
                <mat-panel-description
                  [className]="rule['status'] === 'OK' ? 'analysis-rule-ok' : 'analysis-rule-not-matched'">{{rule['status']}}</mat-panel-description>
              </mat-expansion-panel-header>
              <table class="rule-results table-responsive">
                <thead>
                <tr class="analysis-rule-path">
                  <th *ngFor="let field of rule.fields"
                      align="left">{{field}}</th>
                  <th>code</th>
                  <th></th>
                </tr>
                <tr class="analysis-rule-value">
                  <th *ngFor="let value of rule.actual" align="left">{{value.value}}</th>
                  <th></th>
                  <th>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor=" let row of rule.rule" [className]="row.matched ? 'matched-rule-' + rule.ruleType : 'unmatched-rule'">
                  <td *ngFor="let result of row.expected"
                      [className]="result.matched ? 'analysis-rule-matched' : 'analysis-rule-not-matched'">{{result.value}}<!--{{result | json}}--></td>
                  <td>{{rule.mnemonic}}-{{row.mnemonic}}</td>
                </tr>
                </tbody>
              </table>
            </mat-expansion-panel>
          </mat-expansion-panel>
        </ng-container>

      </mat-expansion-panel>
      <div *ngIf="ignoredRulesTable && ignoredRulesTable.length > 0">
        <mat-expansion-panel *ngFor="let ignoredRule of ignoredRulesTable">
          <mat-expansion-panel-header>
            <mat-panel-title>{{ignoredRule.ruleType}} {{ignoredRule.name}} {{ignoredRule.mnemonic}}</mat-panel-title>
            <mat-panel-description class="analysis-rule-ignored">{{ignoredRule.status}}</mat-panel-description>
          </mat-expansion-panel-header>
        </mat-expansion-panel>
      </div>
    </mat-accordion>
  </ng-container>

  <!--  <ng-container *ngIf="governanceMode">-->
  <!--    <div class="analysis-header">Product Governance Result: <span-->
  <!--      [className]="analysisReport.status === 'WARNING' ? 'analysis-rule-warning' : analysisReport.status === 'ERROR' ? 'analysis-rule-error' : 'analysis-rule-ok'">{{analysisReport.status === 'ERROR' ? 'Not Approved' : 'Approved'}}</span>-->
  <!--    </div>-->
  <!--    <ng-container *ngFor="let result of tables">-->
  <!--      <table class="rule-results table-responsive">-->
  <!--        <thead>-->
  <!--        <tr class="analysis-rule-name">-->
  <!--          <th [colSpan]="result.fields.length + 1" align="left">{{result.name}} : <span-->
  <!--            [className]="result.status === 'ERROR' ? 'analysis-rule-error' : 'analysis-rule-ok'">{{result.status === 'ERROR' ? 'Not Approved' : 'Approved'}}</span>-->
  <!--          </th>-->
  <!--        </tr>-->
  <!--        <tr class="analysis-rule-path">-->
  <!--          <th *ngFor="let field of result.fields"-->
  <!--              align="left">{{field}}</th>-->
  <!--          <th>code</th>-->
  <!--        </tr>-->
  <!--        <tr class="analysis-rule-value">-->
  <!--          <th *ngFor="let value of result.values" align="left">{{value}}</th>-->
  <!--          <th></th>-->
  <!--        </tr>-->
  <!--        </thead>-->
  <!--        <tbody>-->
  <!--        <tr *ngFor=" let row of result.results" [className]="row.match ? 'matched-rule' : 'unmatched-rule'">-->
  <!--          <td *ngFor="let cell of row.cellResults"-->
  <!--              [className]="cell.match ? 'analysis-rule-matched' : 'analysis-rule-not-matched'">{{cell.expected}}</td>-->
  <!--          <td>{{result.mnemonic}}-{{row.mnemonic}}</td>-->
  <!--        </tr>-->
  <!--        </tbody>-->
  <!--      </table>-->
  <!--    </ng-container>-->
  <!--  </ng-container>-->
</div>
