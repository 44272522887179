<div class="md-form form-inline form-group">
  <mat-form-field class="test-suite-selection">
    <mat-label>Test Suite</mat-label>
    <mat-select (selectionChange)="selectTestSuite($event)" [value]="selectedSuite || selectedTestSuite" placeholder="Test Suite">
      <mat-option></mat-option>
      <mat-option *ngFor="let suite of testSuites" [value]="suite.name">{{suite.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-slide-toggle *ngIf="displayProdClone" [checked]="includeProdClone"
                    (change)="toggleIncludeProdClone($event.checked)">Include Prod Clone
  </mat-slide-toggle>
  &nbsp;
  &nbsp;
  <button *ngIf="!displayProdClone" type="button" class="btn btn-info" (click)=this.actionDownloadTestSummaryCsv() [disabled]=!this.testSuites>
    Test Suite Summary CSV
  </button>
  &nbsp;
  &nbsp;
  <button *ngIf="!displayProdClone" type="button" class="btn btn-info" (click)=this.actionDownloadTestDetailsCsv() [disabled]=!this.testSuites>
    Test Suite Details CSV
  </button>
</div>
