//will be of type Jurisdiction
const jurisdiction: any = {
  code: "",
  name: "",
};

//Will be of type Exchange which has at least one type (Jurisdiction) within
const exchange: any = {
  country: {
    code: "",
    name: "",
  },
  name: "",
  regulatedMarket: "",
};

//Will be of type Observations which has at least types Percentage, ObservationsDerivedData and PaymentDates within
const observations: any = {
  autocallableDate: "",
  barrier: {
    actualPercentage: "",
    indicative: "",
    indicativePercentage: "",
    maximumPercentage: "",
    minimumPercentage: "",
  },
  barrierDirection: "",
  barrierPerformance: {
    actualPercentage: "",
    indicative: "",
    indicativePercentage: "",
    maximumPercentage: "",
    minimumPercentage: "",
  },
  callableDate: "",
  dates: "",
  disruptionRollBasis: "",
  endDate: "",
  frequency: "",
  nonScheduledTradingDaysRollBasis: "",
  numberOfTradingDays: "",
  observationsDerivedData: {
    couponAmount: "",
    couponAmountIndicative: "",
    couponAmountMinimum: "",
    couponPeriodFinalEndDate: "",
    cumulativeCouponAmount: "",
    endDatePlus1: "",
    frequencyDE: "",
    kIBarrierMinusFivePercTimesDenomPlusOneCouponAm: "",
    kIBFivePercDenomPlusOneCoupAmMinusFees: "",
    nominalPlusCouponAmount: "",
    nominalPlusCouponAmountMinusFeesAndCosts: "",
    onePlusAggregateCoupon: "",
    percKIBFivePercDenomPlusOneCoupAmMinusFees: "",
    percentageNominalPlusCouponAmountMinusFeesNCosts: "",
    recordDate: "",
    redemptionBarrierLevel: "",
    triggerAmount: "",
    triggerAmountIndicative: "",
    triggerAmountLevel: "",
    triggerAmountLevelIndicative: "",
    triggerAmountLevelMinimum: "",
    triggerAmountMinimum: "",
    triggerAmountPayout: "",
    triggerAmountPayoutIndicative: "",
    triggerAmountPayoutMinimum: "",
    triggerAmountPayoutminusFeesAndCostsAmount: "",
    triggerAmountPayoutminusFeesAndCostsPercentage: "",
    triggerBarrierLevel: "",
  },
  paymentDates: {
    paymentDate: "",
    paymentLag: "",
  },
  rate: {
    actualPercentage: "",
    indicative: "",
    indicativePercentage: "",
    maximumPercentage: "",
    minimumPercentage: "",
  },
  startDate: "",
  startLagMonths: "",
  unadjustedDates: "",
  unadjustedStartDate: "",
};

//Will be of type Underlyings which also includes at least types Currency, Equity, EquityIndex, ETF, Fund, FX, etc.

const underlying: any = {
  adviser: "",
  basketWeightType: "",
  benchmarkAdministrator: "",
  benchmarkStatus: "",
  benchmarkEuStatus: "",
  benchmarkUkStatus: "",
  bloombergCode: "",
  bloombergKeyCode: "",
  category: "",
  currency: {
    code: "",
    name: "",
  },
  equity: "",
  equityData: {
    creditRatings: {
      fitch: "",
      moody: "",
      standardPoor: "",
    },
    equityType: "",
  },
  equityIndex: "",
  equityIndexData: {
    decrementIndex: "",
    deliverableUnderlying: {
      adviser: "",
      basketWeightType: "",
      benchmarkAdministrator: "",
      benchmarkStatus: "",
      benchmarkEuStatus: "",
      benchmarkUkStatus: "",
      bloombergCode: "",
      bloombergKeyCode: "",
      category: "",
      currency: {
        code: "",
        name: "",
      },
      equity: "",
      equityData: {
        creditRatings: {
          fitch: "",
          moody: "",
          standardPoor: "",
        },
        equityType: "",
      },
      equityIndex: "",
      equityIndexData: {
        decrementIndex: "",
        multiExchange: "",
        priceIndex: "",
      },
      etf: "",
      etfData: {
        referenceIndex: "",
      },
      exchange: "",
      fund: "",
      fundData: "",
      fx: "",
      fxData: "",
      indicativePrice: "",
      initialPrice: "",
      initialSettingDate: "",
      isin: "",
      isQuanto: "",
      jurisdictionalEvent: "",
      jurisdictions: "",
      name: "",
      nameDE: "",
      quantoFXBloombergTicker: "",
      relatedExchange: "",
      ric: "",
      sponsor: "",
      underlyingDerivedData: {
        couponBarrierLevel: "",
        dailyNumberOfShares: "",
        decimalNumberofETFsForIndPrice: "",
        decimalNumberofETFsForIndPriceForETF10Pc: "",
        decimalNumberofETFsForIndStrike: "",
        decimalNumberofETFsForIndStrikeForETF10Pc: "",
        denomAt10PercentBeloIndicativeStrikeTimesDenom: "",
        denomAt20PercentBeloIndicativeStrikeTimesDenom: "",
        denominationDividedByIndicativePrice: "",
        denominationDividedByIndicativePriceTimesKI: "",
        denominationDividedByIndicativePriceTimesStrike: "",
        denominationDividedByInitialPrice: "",
        denominationDividedByStrikeLevel: "",
        ETFhalfKnockInBarrier: "",
        ETFhalfKnockInBarrierForETF10Pc: "",
        ETFhalfPlusHalfKnockInBarrier: "",
        ETFhalfPlusHalfKnockInBarrierForETF10Pc: "",
        ETFkIBMinusFivePerc: "",
        ETFkIBMinusFivePercForETF10Pc: "",
        ETFStrikeHalf: "",
        ETFStrikeHalfForETF10Pc: "",
        ETFStrikeMinusFivePercent: "",
        ETFStrikeMinusFivePercentForETF10Pc: "",
        ETFStrikeMinus10Percent: "",
        ETFStrikeMinus10PercentForETF10Pc: "",
        ETFStrikeMinus20Percent: "",
        ETFStrikeMinus20PercentForETF10Pc: "",
        ETFthreeQuarterKnockInBarrier: "",
        ETFthreeQuarterKnockInBarrierForETF10Pc: "",
        forwardPrice: "",
        fractCashETFthreeQuarterKIOfIndicPriceForETF10Pc: "",
        fractionalCashAt10PercentBelowIndicativeStrike: "",
        fractionalCashAt20PercentBelowIndicativeStrike: "",
        fractionalCashAt5PercBelowCouponBarrier: "",
        fractionalCashAt5PercentBelowIndicativeKI: "",
        fractionalCashAt5PercentBelowIndicativeStrike: "",
        fractionalCashAtHalfBelowIndicativeStrike: "",
        fractionalCashAtHalfKnockInOfIndicativePrice: "",
        fractionalCashAtHalfPlusHalfKIOfIndicativePrice: "",
        fractionalCashAtThreeQuarterKnockInOfIndicPrice: "",
        fractionalCashETFAt10PercBelowIndStrike: "",
        fractionalCashETFAt10PercBelowIndStrikeForETF10Pc: "",
        fractionalCashETFAt20PercBelowIndStrike: "",
        fractionalCashETFAt20PercBelowIndStrikeForETF10Pc: "",
        fractionalCashETFhalfKIOfIndicPrice: "",
        fractionalCashETFhalfKIOfIndicPriceForETF10Pc: "",
        fractionalCashETFhalfPlusHalfKIOfIndicPrice: "",
        fractionalCashETFhalfPlusHalfKIOfIndicPriceForETF10Pc: "",
        fractionalCashETFkIBMinusFivePerc: "",
        fractionalCashETFkIBMinusFivePercForETF10Pc: "",
        fractionalCashETFStrikeHalf: "",
        fractionalCashETFStrikeHalfForETF10Pc: "",
        fractionalCashETFStrikeMinusFivePercent: "",
        fractionalCashETFStrikeMinusFivePercentForETF10Pc: "",
        fractionalCashETFthreeQuarterKIOfIndicPrice: "",
        halfKnockInBarrierTimesIndPrice: "",
        halfPlusHalfKnockInBarrierTimesIndicPrice: "",
        historyPerformance: [
          {
            date: "",
            forwardPrice: "",
            knockOutPrice: "",
            price: "",
          },
          {
            date: "",
            forwardPrice: "",
            knockOutPrice: "",
            price: "",
          },
          {
            date: "",
            forwardPrice: "",
            knockOutPrice: "",
            price: "",
          },
        ],
        indicativePricePlus20Percent: "",
        indicativePriceTimesCouponBarrierMinus5Percent: "",
        indicativePriceTimesKI: "",
        indicativePriceTimesKnockInBarrierMinus5Percent: "",
        indicativePriceTimesStrike: "",
        indicativePriceTimesStrikeHalf: "",
        indicativePriceTimesStrikeMinus10Percent: "",
        indicativePriceTimesStrikeMinus10PTotalMinusCosts: "",
        indicativePriceTimesStrikeMinus10PTotalRedemption: "",
        indicativePriceTimesStrikeMinus20Percent: "",
        indicativePriceTimesStrikeMinus20PTotalMinusCosts: "",
        indicativePriceTimesStrikeMinus20PTotalRedemption: "",
        indicativePriceTimesStrikeMinus5Percent: "",
        isDepositaryReceipt: "",
        kIBMinusFivePercTimesIndPrice: "",
        knockInBarrierLevel: "",
        knockOutPrice: "",
        leveragedDailyNumberOfShares: "",
        leveragedMaximumNumberOfShares: "",
        maximumNumberOfShares: "",
        numberOfETFsForIndicativePrice: "",
        numberOfETFsForIndicativePriceForETF10Pc: "",
        numberOfETFsForIndicativeStrike: "",
        numberOfETFsForIndicativeStrikeForETF10Pc: "",
        numberOfSharesForIndicativeKI: "",
        numberOfSharesForIndicativePrice: "",
        numberOfSharesForIndicativeStrike: "",
        percentDenomAt10PercentBelowIndicativeStrike: "",
        percentDenomAt20PercentBelowIndicativeStrike: "",
        singleFXQuantoCurrency: "",
        strikeLevel: "",
        threeQuarterKnockInBarrierTimesIndPrice: "",
        totalPurchasePrice: "",
      },
      underlyingDescriptionDE: "",
      website: "",
      weight: {
        actualPercentage: "",
        indicative: "",
        indicativePercentage: "",
        maximumPercentage: "",
        minimumPercentage: "",
      },
      wkn: "",
    },
    multiExchange: "",
    priceIndex: "",
  },
  etf: "",
  etfData: {
    referenceIndex: "",
  },
  exchange: "",
  fund: "",
  fundData: "",
  fx: "",
  fxData: "",
  indicativePrice: "",
  initialPrice: "",
  initialSettingDate: "",
  isin: "",
  isQuanto: "",
  jurisdictionalEvent: "",
  jurisdictions: "",
  name: "",
  nameDE: "",
  quantoFXBloombergTicker: "",
  relatedExchange: "",
  ric: "",
  sponsor: "",
  underlyingDerivedData: {
    couponBarrierLevel: "",
    dailyNumberOfShares: "",
    decimalNumberofETFsForIndPrice: "",
    decimalNumberofETFsForIndPriceForETF10Pc: "",
    decimalNumberofETFsForIndStrike: "",
    decimalNumberofETFsForIndStrikeForETF10Pc: "",
    denomAt10PercentBeloIndicativeStrikeTimesDenom: "",
    denomAt20PercentBeloIndicativeStrikeTimesDenom: "",
    denominationDividedByIndicativePrice: "",
    denominationDividedByIndicativePriceTimesKI: "",
    denominationDividedByIndicativePriceTimesStrike: "",
    denominationDividedByInitialPrice: "",
    denominationDividedByStrikeLevel: "",
    ETFhalfKnockInBarrier: "",
    ETFhalfKnockInBarrierForETF10Pc: "",
    ETFhalfPlusHalfKnockInBarrier: "",
    ETFhalfPlusHalfKnockInBarrierForETF10Pc: "",
    ETFkIBMinusFivePerc: "",
    ETFkIBMinusFivePercForETF10Pc: "",
    ETFStrikeHalf: "",
    ETFStrikeHalfForETF10Pc: "",
    ETFStrikeMinusFivePercent: "",
    ETFStrikeMinusFivePercentForETF10Pc: "",
    ETFStrikeMinus10Percent: "",
    ETFStrikeMinus10PercentForETF10Pc: "",
    ETFStrikeMinus20Percent: "",
    ETFStrikeMinus20PercentForETF10Pc: "",
    ETFthreeQuarterKnockInBarrier: "",
    ETFthreeQuarterKnockInBarrierForETF10Pc: "",
    forwardPrice: "",
    fractCashETFthreeQuarterKIOfIndicPriceForETF10Pc: "",
    fractionalCashAt10PercentBelowIndicativeStrike: "",
    fractionalCashAt20PercentBelowIndicativeStrike: "",
    fractionalCashAt5PercBelowCouponBarrier: "",
    fractionalCashAt5PercentBelowIndicativeKI: "",
    fractionalCashAt5PercentBelowIndicativeStrike: "",
    fractionalCashAtHalfBelowIndicativeStrike: "",
    fractionalCashAtHalfKnockInOfIndicativePrice: "",
    fractionalCashAtHalfPlusHalfKIOfIndicativePrice: "",
    fractionalCashAtThreeQuarterKnockInOfIndicPrice: "",
    fractionalCashETFAt10PercBelowIndStrike: "",
    fractionalCashETFAt10PercBelowIndStrikeForETF10Pc: "",
    fractionalCashETFAt20PercBelowIndStrike: "",
    fractionalCashETFAt20PercBelowIndStrikeForETF10Pc: "",
    fractionalCashETFhalfKIOfIndicPrice: "",
    fractionalCashETFhalfKIOfIndicPriceForETF10Pc: "",
    fractionalCashETFhalfPlusHalfKIOfIndicPrice: "",
    fractionalCashETFhalfPlusHalfKIOfIndicPriceForETF10Pc: "",
    fractionalCashETFkIBMinusFivePerc: "",
    fractionalCashETFkIBMinusFivePercForETF10Pc: "",
    fractionalCashETFStrikeHalf: "",
    fractionalCashETFStrikeHalfForETF10Pc: "",
    fractionalCashETFStrikeMinusFivePercent: "",
    fractionalCashETFStrikeMinusFivePercentForETF10Pc: "",
    fractionalCashETFthreeQuarterKIOfIndicPrice: "",
    halfKnockInBarrierTimesIndPrice: "",
    halfPlusHalfKnockInBarrierTimesIndicPrice: "",
    historyPerformance: [
      {
        date: "",
        forwardPrice: "",
        knockOutPrice: "",
        price: "",
      },
      {
        date: "",
        forwardPrice: "",
        knockOutPrice: "",
        price: "",
      },
      {
        date: "",
        forwardPrice: "",
        knockOutPrice: "",
        price: "",
      },
    ],
    indicativePricePlus20Percent: "",
    indicativePriceTimesCouponBarrierMinus5Percent: "",
    indicativePriceTimesKI: "",
    indicativePriceTimesKnockInBarrierMinus5Percent: "",
    indicativePriceTimesStrike: "",
    indicativePriceTimesStrikeHalf: "",
    indicativePriceTimesStrikeMinus10Percent: "",
    indicativePriceTimesStrikeMinus10PTotalMinusCosts: "",
    indicativePriceTimesStrikeMinus10PTotalRedemption: "",
    indicativePriceTimesStrikeMinus20Percent: "",
    indicativePriceTimesStrikeMinus20PTotalMinusCosts: "",
    indicativePriceTimesStrikeMinus20PTotalRedemption: "",
    indicativePriceTimesStrikeMinus5Percent: "",
    isDepositaryReceipt: "",
    kIBMinusFivePercTimesIndPrice: "",
    knockInBarrierLevel: "",
    knockOutPrice: "",
    leveragedDailyNumberOfShares: "",
    leveragedMaximumNumberOfShares: "",
    maximumNumberOfShares: "",
    numberOfETFsForIndicativePrice: "",
    numberOfETFsForIndicativePriceForETF10Pc: "",
    numberOfETFsForIndicativeStrike: "",
    numberOfETFsForIndicativeStrikeForETF10Pc: "",
    numberOfSharesForIndicativeKI: "",
    numberOfSharesForIndicativePrice: "",
    numberOfSharesForIndicativeStrike: "",
    percentDenomAt10PercentBelowIndicativeStrike: "",
    percentDenomAt20PercentBelowIndicativeStrike: "",
    singleFXQuantoCurrency: "",
    strikeLevel: "",
    threeQuarterKnockInBarrierTimesIndPrice: "",
    totalPurchasePrice: "",
  },
  underlyingDescriptionDE: "",
  website: "",
  weight: {
    actualPercentage: "",
    indicative: "",
    indicativePercentage: "",
    maximumPercentage: "",
    minimumPercentage: "",
  },
  wkn: "",
};

// export const multiplicityObjects: any[] = [
//   exchange,
//   jurisdiction,
//   observations,
//   underlying,
// ];

export const multiplicityObjects: any = {
  exchange: exchange,
  jurisdiction: jurisdiction,
  observations: observations,
  underlying: underlying,
};
