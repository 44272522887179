<div id="test-suite-group-edit">
  <div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cancel()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="form-group">
        <label for="nameInput">Test Suite Group Name</label>
        <input class="form-control" id="nameInput" [(ngModel)]="name" placeholder="Test Suite Group Name">
      </div>
      <div class="form-group">
        <mat-form-field class="test-suite-selection">
          <mat-label>Test Suite</mat-label>
          <mat-select [(ngModel)]="selectedTestSuites" placeholder="Test Suite" multiple>
            <mat-option *ngFor="let suite of testSuites" [value]="suite.name">{{suite.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!name || selectedTestSuites.length == 0" ngbAutoFocus
            (click)="confirm()">OK
    </button>
  </div>
</div>
