import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {TestManagerService} from '../services/test-manager.service'
import {ConfirmDialogComponent} from '../dialogs/confirm-dialog.component'
import {merge, Observable, of, Subject, Subscription} from 'rxjs'
import {MatDialog} from '@angular/material/dialog'
import {ToastrService} from 'ngx-toastr'
import {NgbModal, NgbTypeahead} from '@ng-bootstrap/ng-bootstrap'
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, take, tap} from 'rxjs/operators'
import {ChangeService} from '../services/change.service'
import {DocgenConfigAndMetadataChangeDetails} from '../common/domain/change'
import {DocgenPayloadInformation} from '../common/domain/tests'
import {DynamicMappingService} from '../services/dynamic-mapping.service';

@Component({
  selector: 'app-test-selector',
  templateUrl: './test-selector.component.html',
  styleUrls: ['./test-selector.component.scss'],
})
export class TestSelectorComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  @Output() cleared = new EventEmitter()

  @Input() allowDelete = false
  @Input() service = 'docgen'

  model: string
  searching = false
  searchFailed = false
  public userConfigFilters: DocgenConfigAndMetadataChangeDetails[] = null // used by HTML

  @ViewChild('instance', {static: true}) instance: NgbTypeahead
  focus$ = new Subject<string>()
  click$ = new Subject<string>()

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged())
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()))
    const inputFocus$ = this.focus$

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.testManagerService.tests
          .pipe(
            tap(() => this.searchFailed = false),

            // Convert SavedTestMetadata[] to Array<string>
            map(tests => {
              const terms = term.toLowerCase().split(' ')
              return tests
                // Ask Mark: Presumbably this filters out undefined/ null results?
                .filter(test => test)

                .filter(test => test.product.service === this.service)

                // Filter by Config Filter
                .filter((curTest) => {
                  if (this.userConfigFilters.length === 0 || this.service !== 'docgen') {
                    return true
                  }

                  let showThisTest = false
                  // Search the User's Config Filters
                  this.userConfigFilters.forEach((curUserFilter) => {
                    const product = curTest.product as DocgenPayloadInformation

                    // plugh Ask Mark: Is a .toLowerCase() match better?
                    showThisTest = showThisTest ||
                      product.documentType === curUserFilter.metadata.documentType &&
                      product.productType === curUserFilter.metadata.productType &&
                      product.programme === curUserFilter.metadata.programme
                  })
                  return showThisTest
                })

                .map(t => t.name)
                .filter((name: string) => {
                  const ts = name.toLowerCase()
                  return terms.every(searchTerm => ts.includes(searchTerm))
                })
                .slice(0, 100)
            }),
            catchError(() => {
              this.searchFailed = true
              return of([])
            })),
      ),
      tap(() => this.searching = false),
    )
  }


  constructor(
    private testManagerService: TestManagerService,
    private _changeService: ChangeService,
    // private workspaceService: WorkspaceService,
    private toast: ToastrService,
    private modalService: NgbModal,
    public dialog: MatDialog,
    private dynamicMappingService: DynamicMappingService
  ) {
  }

  ngOnInit() {
    // console.info('TestSelectorComponent.ngOnInit()')

    // Get the config filters, and end the Observable [ended via take(1)]
    this._changeService.getUserFilteredDocgenConfigs().pipe(
      take(1),
    ).subscribe(observableResult => {
      this.userConfigFilters = observableResult
      // console.info('userConfigFilters:')
      // console.dir(this.userConfigFilters)
    })

    this.sub.add(
      this.testManagerService.selectedTestName
        .subscribe(selectedTestName => {
          this.model = selectedTestName
        }),
    )

    this.sub.add(
      this.testManagerService.testSelectionCleared
        .subscribe(cleared => {
          if(cleared) this.userConfigFilters = []
        })
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  reset() {
    this.clearTestSelection()
  }

  clearTestSelection() {
    this.testManagerService.selectTest(undefined, 'test selector reset')
    this.dynamicMappingService.toggleShowDynamicMapping(false)
    this.testManagerService.setFilterCleared(true);
    this.cleared.emit()
  }

  deleteTest(test: string) {
    const dialogRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })

    dialogRef.componentInstance.title = 'Please confirm'
    dialogRef.componentInstance.message = 'Are you sure you want to delete' + test

    dialogRef.result
      .then(confirm => {
        this.testManagerService.deleteTest(test)
          .then(res => {
            this.toast.success('Deleted test')
          })
          .catch(err => {
            console.log('Failed to Delete Test: ', test)
            console.dir(err)
            this.toast.error('Failed to delete test')
          })
      }).catch(err => {
      this.toast.info('Cancelled', undefined, {
        timeOut: 1500,
      })
      console.dir(err)
    })
  }

  select($event: string) {
    if ($event) {
      this.testManagerService.selectTest($event, 'test selector value change')
    }
  }
}
