<h2 mat-dialog-title>Create New Dynamic Mapping</h2>
<h4 mat-dialog-title>Enter a new value (or select from existing values) for each element. The values are case-sensitive, must match those sent by CS, and the combination must not already exist
</h4>
<p></p>
<div mat-dialog-content>
  <table>

    <tr>
      <td>Source System</td>
      <td>
        <mat-form-field class="example-full-width"><input matInput [(ngModel)]="newDynamicMapping.sourceSystem" (input)=this.validateInput()></mat-form-field>
      </td>
    </tr>

    <tr>
      <td>Document Type</td>
      <td>
        <mat-form-field class="example-full-width"><input matInput [(ngModel)]="newDynamicMapping.documentType" (input)=this.validateInput()></mat-form-field>
      </td>
    </tr>

    <tr>
      <td>Program/Region Extractor</td>
      <td>
        <mat-form-field class="example-full-width"><input matInput [(ngModel)]="newDynamicMapping.programme" (input)=this.validateInput()></mat-form-field>
      </td>
    </tr>

    <tr>
      <td>Product Type/ Product Family Extractor</td>
      <td>
        <mat-form-field class="example-full-width"><input matInput [(ngModel)]="newDynamicMapping.productType" (input)=this.validateInput()></mat-form-field>
      </td>
    </tr>

    <tr>
      <td>Payload Type</td>
      <td>
        <mat-form-field class="example-full-width">
          <mat-select class="transform" [(value)]="newDynamicMapping.payloadType" (valueChange)=this.validateInput()>
            <mat-option *ngFor="let type of payloadTypes" [value]="type">{{type}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </tr>


    <button id="additionalExtractorFieldsHeader" class='btn rounded-pill' (click)="showAdditionalExtractorFields()">Add Additional Extractor Fields +</button>
    <ng-container *ngIf="addAdditionalExtractorFields">
      <tr class="extractorFieldRow">
        <td class="extractorFieldRowHeader">Extractor Field Xpath</td>
        <td>
          <mat-form-field class="example-full-width">
            <input matInput [(ngModel)]="newDynamicMapping.extractorFields.extractorPath" (input)=this.validateInput()>
          </mat-form-field>
        </td>
      </tr>

      <ng-container *ngFor="let _ of newDynamicMapping.extractorFields.conditions; let i = index; trackBy:trackByIndex;">
        <div>
          <h6 class="extractorFieldRowHeader">Condition {{i+1}}</h6>
        </div>
        <tr class="extractorFieldRow">
          <td class="extractorFieldConditionHeader">Is Equal To</td>
          <td>
            <mat-form-field class="example-full-width"><input matInput [(ngModel)]="newDynamicMapping.extractorFields.conditions[i].value" (input)=this.validateInput()></mat-form-field>
          </td>
        </tr>
        <tr class="extractorFieldRow">
          <td class="extractorFieldConditionHeader">New Product Type</td>
          <td>
            <mat-form-field class="example-full-width"><input matInput [(ngModel)]="newDynamicMapping.extractorFields.conditions[i].newProductType" (input)=this.validateInput()></mat-form-field>
          </td>
        </tr>
        <tr>
          <button id="removeCondition" class='btn rounded-pill' (click)="removeCondition(newDynamicMapping.extractorFields.conditions[i])">Remove</button>
        </tr>
      </ng-container>

      <div style="padding: 5px 0px 5px 5px">
        <button (click)="this.actionAddCondition()" mat-raised-button color="primary">Add Condition</button>
      </div>
    </ng-container>

  </table>

</div>

<div mat-dialog-actions>
  <button mat-raised-button color="primary" [disabled]="!this.canSave" (click)="actionCreate()" class="me-2">Create</button>
  <button type="button" class="btn btn-secondary" (click)="actionCancel()">Cancel</button>
</div>
