<div class="container-fluid h-75">
  <mat-dialog-content >Differences
    <ul ngbNav #showDataChange="ngbNav" class="nav-tabs">
      <li ngbNavItem >
        <a ngbNavLink></a>
        <ng-template ngbNavContent>
          <div class="diff-output showChangesToBeCommit" id="showChangesToBeCommitted">
            <ngx-monaco-diff-editor
              class="monaco-editor diff-output-editor"
              [options]="jsonDiffOptions"
              [originalModel]="originalModel"
              [modifiedModel]="modifiedModel"
            >
            </ngx-monaco-diff-editor>
          </div>
        </ng-template>
      </li>
    </ul>
  </mat-dialog-content>
  <div [ngbNavOutlet]="showDataChange"></div>
  <div mat-dialog-actions>
    <button type="button" class="btn btn-secondary" (click)="close()">Close</button>
  </div>
</div>
