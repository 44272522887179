import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class DynamicMappingService {

  isDynamicMapping = false
  showDynamicMappingConfigChange: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.showDynamicMappingConfigChange.subscribe((value) => {
      this.isDynamicMapping = value
    })
  }

  toggleShowDynamicMapping(show?: boolean) {
    if(show !== undefined) {
      this.showDynamicMappingConfigChange.next(show)
    } else {
      this.showDynamicMappingConfigChange.next(!this.isDynamicMapping)
    }
    console.log('toggle isDynamicMapping to ', this.isDynamicMapping)
  }

  getIsDynamicMapping() {
    return this.isDynamicMapping
  }

}
