export const getAuditChanges = (updatedObject, originalObject, comparisonObject: string, objectName: string) => {
  const auditLog: string[] = []
  let changes: boolean
  const configKey = comparisonObject === 'Rule approval' ? `${updatedObject.sourceSystem}/${updatedObject.programme}/${updatedObject.productType}/${updatedObject.documentType}` : '';
  if(!originalObject) {
    if (comparisonObject === 'Rule approval'){
      return [`Rule ${objectName} approved for ${configKey}`]
    }
    return [`${comparisonObject} ${objectName} has been added`]
  }
  checkForDeletions(originalObject, updatedObject, auditLog, comparisonObject)
  changes = checkForModifications(originalObject, updatedObject, comparisonObject)
  if(changes) {
    const changeString = comparisonObject === 'Rule approval' ? `Rule ${objectName} approved for ${configKey}` : `${comparisonObject} ${objectName} has been modified`
    auditLog.push(changeString)
  }
  return auditLog
}

function checkForModifications(originalObject, updatedObject, comparisonObject: string): boolean {
  const originalObjectCopy = filterKeysToIgnore(JSON.parse(JSON.stringify(originalObject)), comparisonObject)
  const updatedObjectCopy = filterKeysToIgnore(JSON.parse(JSON.stringify(updatedObject)), comparisonObject)
  let hasBeenModified = false
  if(JSON.stringify(updatedObjectCopy) !== JSON.stringify(originalObjectCopy)) {
    hasBeenModified = true
  }
  return hasBeenModified
}

function checkForDeletions(originalObject, updatedObject, auditLog, comparisonObject: string) {
  if(updatedObject.deletion && !originalObject.deletion) {
    auditLog.push(`${comparisonObject} ${updatedObject.mnemonic} has been soft deleted`)
  } else if (originalObject.deletion && !updatedObject.deletion) {
    auditLog.push(`${comparisonObject} ${updatedObject.mnemonic} has been restored`)
  }
}

function filterKeysToIgnore(objectToFilter, comparisonObject: string) {
  delete objectToFilter.deletion
  if(comparisonObject === 'Ruleset') {
    delete objectToFilter.filePath
    delete objectToFilter.rule
    delete objectToFilter.ruleApprovals
    delete objectToFilter.lastUpdated
  }
  return objectToFilter
}
