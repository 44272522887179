import {Component, OnInit} from '@angular/core'

@Component({
  selector: 'app-templates-approval',
  templateUrl: './templates-approval.component.html',
  styleUrls: ['./templates-approval.component.scss']
})
export class TemplatesApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
