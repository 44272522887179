const instrumentMultiplicities: any = {
  optionsGroup: "Instrument",
  multiplicities: [
    {
      name: "countriesOfOffer",
      path: "InSPireMessage.Instrument.countriesOfOffer",
      objectRef: "jurisdiction",
    },
    {
      name: "listingExchange",
      path: "InSPireMessage.Instrument.listingExchange",
      objectRef: "exchange",
    },
  ],
};

const interestMultiplicities: any = {
  optionsGroup: "Interest",
  multiplicities: [
    {
      name: "contingentCoupon observations",
      path: "InSPireMessage.Interest.conditionalCouponAmount.contingentCoupon.observations",
      objectRef: "observations",
    },
    {
      name: "fixedRateCoupon observations",
      path: "InSPireMessage.Interest.guaranteedCouponAmount.fixedRateCoupon.observations",
      objectRef: "observations",
    },
  ],
};

const redemptionMultiplicities: any = {
  optionsGroup: "Redemption",
  multiplicities: [
    {
      name: "triggerRedemption observations",
      path: "InSPireMessage.Redemption.autocallableRedemption.triggerRedemption.observations",
      objectRef: "observations",
    },
  ],
};

const underlyingsMultiplicities: any = {
  optionsGroup: "Underlyings",
  multiplicities: [
    {
      name: "underlying",
      path: "InSPireMessage.Underlyings.underlying",
      objectRef: "underlying",
    },
  ],
};

const valuationDatesMultiplicities: any = {
  optionsGroup: "ValuationDates",
  multiplicities: [
    {
      name: "observationDates",
      path: "InSPireMessage.ValuationDates.observationDates",
      objectRef: "observations",
    },
  ],
};

export const availableMultiplicities: any[] = [
  instrumentMultiplicities,
  interestMultiplicities,
  redemptionMultiplicities,
  underlyingsMultiplicities,
  valuationDatesMultiplicities,
];
