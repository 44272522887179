import {Component, OnDestroy, OnInit} from '@angular/core'
import {ExperimentsService} from '../services/experiments.service'
import {TestManagerService} from '../services/test-manager.service'
import {BehaviorSubject, combineLatest, Subject, Subscription} from 'rxjs'
import {GovernanceResponse} from '../model/governance-responses'
import {DocgenConfigAndMetadataChangeDetails} from '../common/domain/change'
import {DocgenPayloadInformation, TestDefinition} from '../common/domain/tests'
import {TestHistory} from '../model/test-management-responses'
import {jsonViewerOptions, xmlEditorOptions} from '../util/editor-configurations'
import {debounceTime, distinctUntilChanged} from 'rxjs/operators'
import {ConfigService} from '../services/config.service'
import {ChangeService} from '../services/change.service'
import {PublishPayloadService} from '../services/publish-payload.service'
import {MatDialog} from '@angular/material/dialog'
import {ToastrService} from 'ngx-toastr'
import {ActivatedRoute, Router} from '@angular/router'
import deepEqual from 'deep-equal'
import {DocgenConfig} from '../common/domain/docgen-config'

interface ResolvedTest {
  testDefinition?: TestDefinition
  testHistory?: TestHistory[]
}


@Component({
  selector: 'app-product-governance',
  templateUrl: './product-governance.component.html',
  styleUrls: ['./product-governance.component.scss'],
})
export class ProductGovernanceComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  checking: boolean

  governanceResponse: GovernanceResponse

  $refresh = new Subject<string>()

  csValidation: string = undefined
  transformed: string = undefined
  targetValidation: string = undefined
  trace: string = undefined

  docgenConfig: DocgenConfig = undefined
  config: DocgenConfigAndMetadataChangeDetails

  refreshGraphic = ''
  testDefinition: TestDefinition

  testHistory: TestHistory[]
  loadedVersion: string = undefined

  payloadEditorOptions = xmlEditorOptions
  schemaEditorOptions = xmlEditorOptions
  readOnlyJsonOptions = jsonViewerOptions
  transformEditorOptions = xmlEditorOptions

  payloadSubject = new BehaviorSubject<string>('')
  payload = this.payloadSubject.pipe(
    debounceTime(250),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Payload Changed: ' + r)
    // }),
  )

  csSchemaSubject = new BehaviorSubject<string>(undefined)
  csSchema = this.csSchemaSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('CS Schema Changed: ' + r)
    // }),
  )

  transformSubject = new BehaviorSubject<string>(undefined)
  transform = this.transformSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Transform Changed: ' + r)
    // }),
  )

  targetSchemaSubject = new BehaviorSubject<string>(undefined)
  targetSchema = this.targetSchemaSubject.pipe(
    // debounceTime(100),
    distinctUntilChanged(),
    // tap(r => {
    //   console.log('Target Schema Changed: ' + r)
    // }),
  )

  payloadValue = ''
  csSchemaValue: string
  transformValue: string
  targetSchemaValue: string
  governance: string

  constructor(
    private configService: ConfigService,
    private changeService: ChangeService,
    private publishPayloadService: PublishPayloadService,
    private testManager: TestManagerService,
    private dialog: MatDialog,
    private toast: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private experimentsService: ExperimentsService,
  ) {
    this.activatedRoute.data
      .pipe(
        distinctUntilChanged(deepEqual),
      )
      .subscribe((data: ResolvedTest | undefined) => {
        console.dir(data)
        const test = data?.testDefinition
        if (test?.product?.service === 'docgen') {
          this.testDefinition = test
          this.loadedVersion = undefined
          this.testHistory = data?.testHistory
          this.testManager.selectTest(test.name, 'Governance route update')
          this.configService.selectDocgenConfig(test.product as DocgenPayloadInformation)
          this.payloadValue = test.payload
          this.$refresh.next()
        }
      })
  }

  ngOnInit(): void {
    this.sub.add(
      this.changeService.selectedDocgenConfig.subscribe(config => {
        this.config = config
        if (config) {
          // Todo - GovernanceConfig /
          const docgenConfig = JSON.parse(config.settings.content) as DocgenConfig
          if (docgenConfig.validatePayload.skip) {
            this.csSchemaValue = undefined
          } else {
            const actualSchema = config.schemas.find(s => s.path === docgenConfig.validatePayload.schema)
            this.csSchemaValue = actualSchema && actualSchema.content
          }
          if (docgenConfig.validateEnriched.skip) {
            this.targetSchemaValue = undefined
          } else {
            const actualSchema = config.schemas.find(s => s.path === docgenConfig.validateEnriched.schema)
            this.targetSchemaValue = actualSchema && actualSchema.content
          }
          if (docgenConfig.transform.skip) {
            this.transformValue = undefined
          } else {
            const actualTransform = config.transforms.find(t => t.path === docgenConfig.transform.transforms[0])
            this.transformValue = actualTransform && actualTransform.content
          }
          this.docgenConfig = docgenConfig
        } else {
          this.docgenConfig = undefined
        }
        this.refresh()
      }),
    )
    this.sub.add(
      this.testManager.selectedTestName.subscribe(testName => {
        if (testName) {
          this.router.navigate(['product/governance', testName])
        } else {
          this.router.navigate(['product/governance'])
        }
      }),
    )
    this.sub.add(
      combineLatest([
        this.payload,
        this.csSchema,
        this.transform,
        this.targetSchema,
      ])
        .pipe(
          debounceTime(250),
          distinctUntilChanged(deepEqual),
        ).subscribe(() => {
        this.trace = undefined
        this.checking = false
        this.csValidation = undefined
        this.transformed = undefined
        this.targetValidation = undefined

        this.refreshGraphic = '' + new Date().valueOf()
        this.$refresh.next('' + new Date().valueOf())
      }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  refresh() {
    this.payloadSubject.next(this.payloadValue)
    this.csSchemaSubject.next(this.csSchemaValue)
    this.transformSubject.next(this.transformValue)
    this.targetSchemaSubject.next(this.targetSchemaValue)
  }

  performGovernanceCheck() {
    this.checking = true
    this.experimentsService.governanceCheck(this.config.metadata.sourceSystem, this.payloadValue)
      .subscribe(res => {
        console.dir(res)
        this.checking = false
        this.governanceResponse = res
        this.trace = JSON.stringify(res, undefined, 2)
        this.transformed = res.transformed
        this.governance = res.governance
        this.csValidation = res.validatePayload
        this.targetValidation = res.validateTransformed
      })
  }

  selectTestHistory(history: TestHistory) {
    this.testManager.getTest(this.testDefinition.name, history.version)
      .subscribe((test: TestDefinition) => {
        this.testDefinition = test
        const date = history.date
        this.loadedVersion = date.toLocaleDateString()
        this.toast.info('Loaded version from ' + date.toLocaleDateString())
      })
  }

  testReset() {
    this.payloadSubject.next('')
  }
}
