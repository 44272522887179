<h2 mat-dialog-title>{{data.title}}</h2>

<div mat-dialog-content>
  <table>
    <tr>
      <td>Name*</td>
      <td>
        <mat-form-field><input matInput [(ngModel)]="data.name"></mat-form-field>
      </td>
    </tr>
    <tr>
      <td>Description</td>
      <td>
        <mat-form-field><input matInput [(ngModel)]="data.description"></mat-form-field>
      </td>
    </tr>
    <tr>
      <td>Mnemonic*</td>
      <td>
        <mat-form-field><input matInput [(ngModel)]="data.mnemonic"></mat-form-field>
      </td>
    </tr>
    <ng-container *ngIf="data.requireStageAndList">
      <tr>
        <td>Analysis Stage*</td>
        <td>
          <mat-form-field>
            <mat-select [(value)]="data.stage">
              <mat-option *ngFor="let stage of stages"
                          [value]="stage">{{stage}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
      <tr>
        <td>Ruleset*</td>
        <td>
          <mat-form-field>
            <mat-select [(value)]="data.ruleType">
              <mat-option *ngFor="let ruleType of ruleTypes"
                          [value]="ruleType">{{ruleType}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngFor="let _ of data.fields;let i = index; trackBy:trackByIndex;">
      <tr>
        <td>Field*</td>
        <td>
          <mat-form-field><input matInput [(ngModel)]="data.fields[i]"></mat-form-field>
        </td>
      </tr>
    </ng-container>
  </table>

  <button (click)="addField()" mat-button color="primary">Add Field</button>


</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-button color="primary">Cancel</button>
  <button mat-button [mat-dialog-close]="data" color="warn">{{showNextButton ? 'Next' : 'OK'}}</button>
</div>
