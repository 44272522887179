import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BasicHelper } from 'src/app/common/basic-helper';
import { ChangeDetails } from 'src/app/common/domain/change';
import { ChangeService } from 'src/app/services/change.service';
import { preProcessorEditorOptions } from '../../util/editor-configurations'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-replace-dynamic-mapping',
  templateUrl: './preprocessor-mapping.component.html',
  styleUrls: ['./preprocessor-mapping.component.scss']
})
export class PreprocessorMappingComponent implements OnInit {
  private _subscriptions = new Array<Subscription>()
  preProcessorLogic: ChangeDetails
  errors = []
  newCodeValue:string 
  options = preProcessorEditorOptions
  preProcessorModel: string 
  constructor(
    private changeService: ChangeService,
    private toast: ToastrService,
  ) { }
    
  async ngOnInit(): Promise<void> {
    // Unsubcribe any existing subscriptions
    this._subscriptions.forEach(x => x.unsubscribe())
    await BasicHelper.WaitForChangeServiceConstructorToComplete(this.changeService)
    const getObjectRes: ChangeDetails = await this.changeService.getObject('preprocessor-logic/preprocessor-logic.txt')
    this.preProcessorModel = getObjectRes.content
    this.preProcessorLogic = getObjectRes
  }
  ngOnDestroy(): void {
    this._subscriptions.forEach(x => x.unsubscribe())
  }
  getValue(value) {
   this.newCodeValue = value; 
  }
  async clickOfBtn(){
    this.errors = []
    // monaco.editor.getModelMarkers({}).map( prob => { 
    //   if(prob.severity === 8) this.errors.push(prob)
    // })
    const newCode = this.newCodeValue.replace(/\s+/g, '')
    const oldCode = this.preProcessorLogic.content.replace(/\s+/g, '')
    if(newCode !== oldCode && this.errors.length <= 0){
      this.preProcessorLogic.content = this.newCodeValue
      this.changeService.addChange(this.preProcessorLogic)
    }else if(newCode === oldCode) {
      this.toast.warning('No changes detected')
    }
  }
}
