import {Component, Inject} from '@angular/core'
import {TestFailureSummary} from '../views/document-generator.component'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

export interface SaveNegativeTestDetails {
  failures: TestFailureSummary
}

@Component({
  templateUrl: 'save-negative-test-dialog.component.html',
  styleUrls: ['./save-negative-test-dialog.component.scss'],
})
export class SaveNegativeTestDialogComponent {

  constructor(public dialogRef: MatDialogRef<SaveNegativeTestDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: SaveNegativeTestDetails) {
  }

  cancel() {
    this.dialogRef.close(undefined)
  }
}
