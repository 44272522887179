<app-test-suite-group-selector></app-test-suite-group-selector>
<app-test-suite-selector [displayProdClone]="false"></app-test-suite-selector>
<div class="suite-operations">
<!--  <app-target-environment-selector *ngIf="selectedTestSuite && canExecute"></app-target-environment-selector>-->
  <button [color]="testCount > 100 ? 'warn': 'primary'" *ngIf="selectedTestSuite && canExecute" mat-button
           mat-raised-button value="pdf" (click)="executeTests('pdf')" class="me-2">Execute Test Suite ( {{testCount}} Tests )
  </button>
  <button [color]="testCount > 100 ? 'warn': 'primary'" *ngIf="selectedTestSuite && canExecute" mat-button
          mat-raised-button value="word" (click)="executeTests('word')">Execute Test Suite ( {{testCount}} Tests )(word)

  </button>
</div>
&nbsp;
<mat-form-field appearance="standard" floatLabel="always">
  <mat-label>History (days)</mat-label>
  <mat-select [(value)]="numberOfDays"
              (selectionChange)="setNumberOfDays($event.value)"
              placeholder="select">
    <mat-option *ngFor="let history of histories"
                [value]="history">{{history}}</mat-option>
  </mat-select>
</mat-form-field>

<mat-tab-group [(selectedIndex)]="selectedTab" (selectedTabChange)="selectTab($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <span (click)="tabClick()">Test Suite Executions</span>
    </ng-template>
    <table mat-table [dataSource]="testSuiteExecutionsDataSource">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name</th>
        <td mat-cell *matCellDef="let execution"
            class="clickable"
            (click)="viewExecutionDetails(execution)"
        >
          {{execution.testsuitename}}
        </td>
      </ng-container>
      <ng-container matColumnDef="group">
        <th mat-header-cell *matHeaderCellDef> Group</th>
        <td mat-cell *matCellDef="let execution"
            class="clickable"
            (click)="viewGroupExecutionDetails(execution)"
        >
          {{execution.groupname}}
        </td>
      </ng-container>
      <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef> Date</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"
        > {{execution.modified | amTimeAgo}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"
            [matTooltip]="execution.status === 'UNKNOWN' ? 'Needs to be baselined.' : null"
            matTooltipPosition="after"
        > {{execution.status}} </td>
      </ng-container>
      <ng-container matColumnDef="environment">
        <th mat-header-cell *matHeaderCellDef> Env</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"
        > {{execution.environment}} </td>
      </ng-container>
      <ng-container matColumnDef="region">
        <th mat-header-cell *matHeaderCellDef> Region</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"
        > {{execution.region}} </td>
      </ng-container>
      <ng-container matColumnDef="tests">
        <th mat-header-cell *matHeaderCellDef> Tests</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"
        > {{execution.tests}} </td>
      </ng-container>
      <ng-container matColumnDef="pending">
        <th mat-header-cell *matHeaderCellDef> Pending</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"
        > {{execution.pending}} </td>
      </ng-container>
      <ng-container matColumnDef="failed">
        <th mat-header-cell *matHeaderCellDef> Failed</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"
        > {{execution.failed}} </td>
      </ng-container>
      <ng-container matColumnDef="errors">
        <th mat-header-cell *matHeaderCellDef> Error</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"
        > {{execution.errors}} </td>
      </ng-container>
      <ng-container matColumnDef="passed">
        <th mat-header-cell *matHeaderCellDef> Passed</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.tests !== execution.passed), 'passed': (execution.tests && execution.tests === execution.passed)}"
        > {{execution.passed}} </td>
      </ng-container>
      <ng-container matColumnDef="passedwhitelistcount">
        <th mat-header-cell *matHeaderCellDef> Passed Whitelist</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.tests !== execution.passed), 'passed': (execution.tests && execution.tests === execution.passed)}"
        > {{execution.passedwhitelistcount}} </td>
      </ng-container>

      <ng-container matColumnDef="validatedPayload">
        <th mat-header-cell *matHeaderCellDef> CS Schema</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.tests !== execution.validatedpayload), 'passed': (execution.tests && execution.tests === execution.validatedpayload)}"
        > {{execution.validatedpayload}} </td>
      </ng-container>

      <ng-container matColumnDef="transformed">
        <th mat-header-cell *matHeaderCellDef> Transform</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.tests !== execution.transformed), 'passed': (execution.tests && execution.tests === execution.transformed)}"
        > {{execution.transformed}} </td>
      </ng-container>

      <ng-container matColumnDef="enriched">
        <th mat-header-cell *matHeaderCellDef> Enrichment</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.tests !== execution.enriched), 'passed': (execution.tests && execution.tests === execution.enriched)}"
        > {{execution.enriched}} </td>
      </ng-container>

      <ng-container matColumnDef="validatedEnriched">
        <th mat-header-cell *matHeaderCellDef> Inspire Schema</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.tests !== execution.validatedenriched), 'passed': (execution.tests && execution.tests === execution.validatedenriched)}"
        > {{execution.validatedenriched}} </td>
      </ng-container>

      <ng-container matColumnDef="analysedPayload">
        <th mat-header-cell *matHeaderCellDef> Analysed Payload</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.tests !== execution.analysedpayload), 'passed': (execution.tests && execution.tests === execution.analysedpayload)}"
        > {{execution.analysedpayload}} </td>
      </ng-container>
      <ng-container matColumnDef="analysedTransformed">
        <th mat-header-cell *matHeaderCellDef> Analysed Solo</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.tests !== execution.analysedtransformed), 'passed': (execution.tests && execution.tests === execution.analysedtransformed)}"
        > {{execution.analysedtransformed}} </td>
      </ng-container>

      <ng-container matColumnDef="generatedDocument">
        <th mat-header-cell *matHeaderCellDef> Generated Document</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.tests !== execution.generateddocuments), 'passed': (execution.tests && execution.tests === execution.generateddocuments)}"
        > {{execution.generateddocuments}} </td>
      </ng-container>


      <ng-container matColumnDef="documentFormatType">
        <th mat-header-cell *matHeaderCellDef>Document Format</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.status !== 'PASSED'), 'passed': (execution.status && execution.status === 'PASSED')}"
        >   {{execution.format}} </td>

      </ng-container>

      <ng-container matColumnDef="pdfdiffs">
        <th mat-header-cell *matHeaderCellDef> No Diffs</th>
        <td mat-cell *matCellDef="let execution" class="clickable"
            (click)="viewExecutionDetails(execution)"

            [ngClass]="{'failed': (execution.tests !== execution.pdfdiffs), 'passed': (execution.tests && execution.tests === execution.pdfdiffs)}"
        > {{execution.pdfdiffs}} </td>
      </ng-container>

      <ng-container matColumnDef="documentsReturned">
        <th mat-header-cell *matHeaderCellDef> Documents Returned</th>
        <td mat-cell *matCellDef="let execution"
            class="clickable"
            (click)="viewExecutionDetails(execution)"
            [ngClass]="{'failed': (execution.tests !== execution.documentsreturned), 'passed': (execution.tests && execution.tests === execution.documentsreturned)}"
        > {{execution.documentsreturned}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let execution">
          <div class="d-flex">
          <button *ngIf="execution.status==='PENDING' && (execution.tests > 0 || execution.pending > 0)" mat-button
                  mat-raised-button
                  color="accent"
                  (click)="refreshExecutionStatus(execution)">Refresh
          </button>
          <button *ngIf="execution.tests > 0" mat-button mat-raised-button color="primary" class="me-2"
                  (click)="viewExecutionDetails(execution)">Details
          </button>
          <a mat-button mat-raised-button color="accent" [routerLink]="['/tests', {executionId: execution.executionId}]"
             target="_blank" class="me-2">Details
            <mat-icon class="small-icon">launch</mat-icon>
          </a>
          <button mat-button mat-raised-button color="warn" *ngIf="execution.status !== 'PASSED'"
                  (click)="deleteExecution(execution)">Delete
          </button>
        </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="testSuiteExecutionDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: testSuiteExecutionDisplayedColumns;"
          [ngClass]="{'failed': (row.status === 'FAILED'), 'success': (row.status === 'PASSED'), 'unknown': (row.status === 'UNKNOWN')}"
      ></tr>
    </table>
  </mat-tab>
  <mat-tab
    label="Results"
    [disabled]="!executionId">
    <button mat-raised-button color="primary" class="me-2" (click)="downloadCsv()">Download CSV</button>
    <button mat-raised-button color="accent" class="me-2" *ngIf="canDownloadTestPack" (click)="downloadTestPack(false)">Download Test
      Pack (CS)
    </button>
    <button mat-raised-button color="accent" class="me-2" *ngIf="canDownloadTestPack" (click)="downloadTestPack(true)">Download Test
      Pack (Update)
    </button>
    <button mat-raised-button color="warn" class="me-2" [title]="rationaliseError" [disabled]="!canRationalise" (click)="rationalise()">Rationalise</button>
    <button mat-button mat-raised-button color="warn" class="me-2" *ngIf="canRebaseline && baselinePossible()"
            (click)="rebaselineTests(executionId)">BASELINE!
    </button>
    <button mat-button mat-raised-button color="warn" class="me-2" *ngIf="canRerunAll"
            (click)="rerunAll()">Rerun Failed Tests
    </button>
    <button mat-button mat-raised-button color="warn" class="me-2" *ngIf="canRerunErrors"
            (click)="retryErrors()">Retry Errors
    </button>
    <table mat-table [dataSource]="testSuiteExecutionsDetailsDataSource" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
        <td mat-cell *matCellDef="let test"> {{test.name}} </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status</th>
        <td mat-cell *matCellDef="let test"> {{test.status}} </td>
      </ng-container>

      <ng-container matColumnDef="differences">
        <th mat-header-cell *matHeaderCellDef> Differences</th>
        <td mat-cell *matCellDef="let test"> {{test.differences}} </td>
      </ng-container>
      <ng-container matColumnDef="whitelist">
        <th mat-header-cell *matHeaderCellDef> Whitelist</th>
        <td mat-cell *matCellDef="let test"> {{test.whitelistrules}} </td>
      </ng-container>
      <ng-container matColumnDef="blacklist">
        <th mat-header-cell *matHeaderCellDef> Blacklist</th>
        <td mat-cell *matCellDef="let test"> {{test.blacklistrules}} </td>
      </ng-container>
      <ng-container matColumnDef="notsupported">
        <th mat-header-cell *matHeaderCellDef> Not Supported</th>
        <td mat-cell *matCellDef="let test"> {{test.notsupportedyetrules}} </td>
      </ng-container>
      <ng-container matColumnDef="validation">
        <th mat-header-cell *matHeaderCellDef> Validation </th>
        <td mat-cell *matCellDef="let test"> {{test.validationrules}} </td>
      </ng-container>
      <ng-container matColumnDef="failurepoints">
        <th mat-header-cell *matHeaderCellDef> Failures</th>
        <td mat-cell *matCellDef="let test"> {{test.failedchecks}} </td>
      </ng-container>
      <ng-container matColumnDef="documenterrors">
        <th mat-header-cell *matHeaderCellDef> SmartDX Errors</th>
        <td mat-cell *matCellDef="let test"> {{test.documenterrors}} </td>
      </ng-container>
      <ng-container matColumnDef="documentwarnings">
        <th mat-header-cell *matHeaderCellDef> SmartDX Warnings</th>
        <td mat-cell *matCellDef="let test"> {{test.documentwarnings}} </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let test">
          <div class="d-flex">
          <button *ngIf="canViewDetails(test)" mat-button mat-raised-button color="primary" class="me-2"
                  (click)="viewTestExecutionDetails(test)">Details
          </button>
          <a mat-button mat-raised-button color="accent" class="me-2" *ngIf="canViewDetails(test)"
             [routerLink]="['/tests', 'execution', executionId, test.name]" target="_blank">Details
            <mat-icon class="small-icon">launch</mat-icon>
          </a>
          <button *ngIf="test.status === 'FAILED' || test.status === 'ERROR'" mat-button mat-raised-button color="warn" class="me-2"
                  (click)="rerun(test.name)">Rerun
          </button>
        </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="testSuiteExecutionDetailsDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: testSuiteExecutionDetailsDisplayedColumns;"></tr>
    </table>
  </mat-tab>
  <ng-container *ngIf="assignments && assignments.length > 0">
    <mat-tab label="Rule Allocation">
      <table class="table table-borderless">
        <thead>
        <th>Config Key</th>
        <th>Rule</th>
        <th>Assigned Test</th>
        </thead>
        <tr *ngFor="let assignment of assignments">
          <td>{{assignment.configKey}}</td>
          <td>{{assignment.mnemonic}}</td>
          <td
            [ngClass]="{'new-assignment': (assignment.reused === false), 'reuse-new-assignment': (assignment.reused === true)}"
          >{{assignment.test}}</td>
        </tr>
      </table>
    </mat-tab>
  </ng-container>
</mat-tab-group>
