import {Component, OnInit} from '@angular/core'
import {ChartDataSets, ChartOptions, ChartPoint} from 'chart.js'
import {DateTime as LuxonDateTime} from 'luxon'
import {Color, Label} from 'ng2-charts'
import {LambdaMetrics, LambdaMetricsResponse, LambdaMetricsService} from '../services/lambda-metrics.service'
// import moment from 'moment'
@Component({
  selector: 'app-pipelines',
  templateUrl: './infrastructure.component.html',
  styleUrls: ['./infrastructure.component.scss'],
})

export class InfrastructureComponent implements OnInit {
  public chartData: ChartDataSets[] = [{data: []}]
  public chartLabels: Label[] = []
  public chartOptions: (ChartOptions & {annotation: any}) = {
    responsive: true,
    annotation: {},
    scales: {
      xAxes: [
        {
          type: 'time',
          ticks: {
            source: 'auto',
          },
          bounds: 'ticks',
          distribution: 'linear',
          time: {
            unit: 'hour',
            displayFormats: {
              hour: 'DD/MM HH:mm',
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            source: 'auto',
            min: 0,
            fontColor: 'white',
          },
          bounds: 'auto',
          position: 'left',
          id: 'latencyScale',
          scaleLabel: {
            labelString: 'Latency',
            fontColor: 'rgba(255,255,255,1.0)',
            fontSize: 14,
          },
        },
        {
          bounds: 'auto',
          position: 'right',
          id: 'invocationScale',
        },
      ],
    },
  }

  public chartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(0,255,0,1.0)',
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,255,255,0.3)',
    },
  ]
  public chartLegend = true
  public chartType = 'bar'
  public chartPlugins = []


  private metrics: LambdaMetrics[]

  constructor(
    private lambdaMetricsService: LambdaMetricsService,
  ) {
  }

  getAverageDurationDataSeries(lambdaName): ChartPoint[] {
    const metric = this.metrics.find(metric => metric.lambdaName === lambdaName)
    return metric.durations
      .map(duration => {
        // const momentTime = moment(duration.Timestamp).utc().startOf('hour')
        const luxonTime = LuxonDateTime.fromISO(duration.Timestamp).toUTC().startOf('hour')
        // if (momentTime.toDate().getTime() !== luxonTime.toJSDate().getTime()) {
        //   throw Error('Times do not match')
        // }

        return {
          // t: time,
          t: luxonTime,
          y: duration.Average / 1000,
        }
      }).sort((a, b) => a.t.valueOf() - b.t.valueOf())
      .map(dataPoint => {
        return {
          // t: dataPoint.t.toDate(),
          t: dataPoint.t.toJSDate(),
          y: dataPoint.y,
        }
      })
  }

  // No longer referenced so I commented it out
  // getInvocationCountDataSeries(lambdaName): ChartPoint[] {
  //   const metric = this.metrics.find(metric => metric.lambdaName === lambdaName)
  //   return metric.invocations
  //     .map(invocation => {
  //       const time: Moment = moment(invocation.Timestamp).utc().startOf('hour')
  //       return {
  //         t: time,
  //         y: invocation.Sum,
  //       }
  //     }).sort((a, b) => a.t.valueOf() - b.t.valueOf())
  //     .map(dataPoint => {
  //       return {
  //         t: dataPoint.t.toDate(),
  //         y: -dataPoint.y,
  //       }
  //     })
  // }

  updateGraphs() {
    const data: ChartDataSets[] = [
      {
        data: this.getAverageDurationDataSeries('lambda-ingest-product'),
        type: 'bar',
        label: 'Latency',
        yAxisID: 'latencyScale',
      },
      // {
      //   data: this.getInvocationCountDataSeries('lambda-ingest-product'),
      //   type: 'bar',
      //   label: 'Invocations',
      //   yAxisID: 'invocationScale',
      // },
    ]
    this.chartData = data
  }

  ngOnInit() {
    // None of the below values are used so I commented them out
    // const currentPeriod = moment().startOf('hour')
    // const range = n => Array.from({length: n}, (value, key) => key)
    // const numberOfHoursEarlier = range(7 * 24).reverse()

    // const startPeriods = numberOfHoursEarlier.map(s => currentPeriod.clone().subtract(s, 'hours'))

    // this.chartLabels = startPeriods

    this.lambdaMetricsService.getLambdaMetrics()
      .subscribe((result: LambdaMetricsResponse) => {
        this.metrics = result.metrics
        this.updateGraphs()
      })
  }
}
