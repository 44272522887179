import {MatDialog} from '@angular/material/dialog'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {DateTime} from 'luxon'
import {ToastrService} from 'ngx-toastr'
import {AnalyseRuleTable} from 'src/app/common/domain/analyse'
import {ChangeDetails} from 'src/app/common/domain/change'
import {AnalyseConfig, ConfigDetail} from 'src/app/common/domain/config'
import {DocgenConfig} from 'src/app/common/domain/docgen-config'
import {
  AddAnalysisRuleDetails,
  AnalysisAddRuleDialogComponent
} from 'src/app/dialogs/analysis-add-rule-dialog.component'
import {ConfigSelectorDialogData} from 'src/app/dialogs/config-selector-dialog-data'
import {CreateWorkspaceDialogComponent} from 'src/app/dialogs/create-workspace-dialog.component'
import {ProtectedRuleSet} from 'src/app/model/rationalised-rule'
import {ChangeService} from 'src/app/services/change.service'
import {NewConfigRulesComponent} from '../config-rules.component'
import {DisplayableRuleTable} from './interfaces'

export class UserActionAddNewRuleset {

    private parentComponent: NewConfigRulesComponent
    private dialog: MatDialog
    private changeService: ChangeService
    private toast: ToastrService
    private modalService: NgbModal

    constructor(
        parentControl: NewConfigRulesComponent,
        dialog: MatDialog,
        changeService: ChangeService,
        toast: ToastrService,
        modalService: NgbModal,
    ) {
        this.parentComponent = parentControl
        this.dialog = dialog
        this.changeService = changeService
        this.toast = toast
        this.modalService = modalService
    }

    /** Add a new ruleset to one or more configs.  The ruleset will have fields, but no data rules. */
    public async addNewRuleset(): Promise<void> {
        // Dialog One: Get RuleSet Name, Desc, Mnemonic, Analysis Stage, RuleSet type, Fields
        const userInputAnalysisRuleDetails: AddAnalysisRuleDetails | undefined = await this._openAndWaitOnDialogOneResult()

        const userInputOk = this._validateDialogOne(userInputAnalysisRuleDetails)
        if (!userInputOk) {
            return
        }

        // Dialog Two: Get product/ document combinations
        const userInputSelectedConfigs: string[] | undefined = await this._openAndWaitForDialogTwoResult(userInputAnalysisRuleDetails)

        if (userInputSelectedConfigs && userInputSelectedConfigs.length > 0) {
            this._saveNewRuleset(userInputAnalysisRuleDetails, userInputSelectedConfigs)
        }
    }

    private async _openAndWaitOnDialogOneResult(): Promise<AddAnalysisRuleDetails | undefined> {
        const ruleDefinitionDialog = this.dialog.open(AnalysisAddRuleDialogComponent, {
            width: '750px',
            data: {
                title: 'Create New Analysis Ruleset',
                name: undefined,
                description: undefined,
                help: undefined,
                mnemonic: undefined,
                requireStageAndList: true,
                stage: undefined,
                list: undefined,
                fields: undefined, // no fields, initially...
                placeholderValue: 'Replace with XPath 1.0 expression...',
                showNextButton: true,
            } as AddAnalysisRuleDetails,
            panelClass: 'dialogFormField500',
        })

        const ruleDefinition: AddAnalysisRuleDetails | undefined = await ruleDefinitionDialog.afterClosed().toPromise()

        // Ensure mnemonic is uppercase
        if (ruleDefinition !== undefined) {
            ruleDefinition.mnemonic = ruleDefinition.mnemonic.trim().toUpperCase()
        }

        return ruleDefinition
    }

    private async _openAndWaitForDialogTwoResult(userInputAnalysisRuleDetails: AddAnalysisRuleDetails): Promise<string[] | undefined> {
        const allowedConfigs = this.parentComponent.allConfigs
            .filter((config: ChangeDetails) => !/^settings\/system\/.+$/.test(config.path))
            .filter((config: ChangeDetails) => {
                return !this.parentComponent.configHasRule(
                    config.path,
                    {
                        name: userInputAnalysisRuleDetails.name,
                        mnemonic: userInputAnalysisRuleDetails.mnemonic,
                        fields: userInputAnalysisRuleDetails.fields,
                    } as DisplayableRuleTable,
                    userInputAnalysisRuleDetails.stage,
                    userInputAnalysisRuleDetails.ruleType,
                )
            })

        const configSelectorDialog = this.dialog.open(CreateWorkspaceDialogComponent, {
            width: '500px',
            data: {
                title: `Select configuration(s) for new ruleset:`,
                configItems: allowedConfigs,
                initialSelections: this.parentComponent.getSelectedConfigAsInitialSelection(),
            } as ConfigSelectorDialogData,
            panelClass: 'dialogFormField500',
        })

        const userInputSelectedConfigs: string[] | undefined = await configSelectorDialog.afterClosed().toPromise()

        return userInputSelectedConfigs
    }

    private _validateDialogOne(userInputAnalysisRuleDetails: AddAnalysisRuleDetails | undefined) {
        let inputOK = true

        // Validate Result of Dialog One
        // Did the user press Cancel?
        if (userInputAnalysisRuleDetails === undefined) {
            inputOK = false
        }

        // todo how much globally-unique validation is required?
        if (inputOK && !userInputAnalysisRuleDetails.name) {
            this.toast.error(`Ruleset name must be supplied`)
            inputOK = false
        }
        if (inputOK && !userInputAnalysisRuleDetails.ruleType) {
            this.toast.error(`Ruleset ruleType must be supplied`)
            inputOK = false
        }
        if (inputOK && !userInputAnalysisRuleDetails.mnemonic) {
            this.toast.error(`Ruleset mnemonic must be supplied`)
            inputOK = false
        }

        // Verify Mnemonic is not already in use
        if (inputOK) {
            // Select configuration(s) for new ruleset:
            const justTheConfigs: string[] = this.parentComponent.allConfigs
                .filter((config: ChangeDetails) => !/^settings\/system\/.+$/.test(config.path))
                .map((value: ChangeDetails) => value.content)
            const mnemonicInUse: boolean = justTheConfigs.some((configString: string) => {
                const config = JSON.parse(configString) as DocgenConfig

                const productAnalyseConfig: AnalyseConfig = config.analysePayload
                const soloAnalyseConfig: AnalyseConfig = config.analyseEnriched
                const productHasMnemomnic = productAnalyseConfig.rules
                    .filter(r => r.ruleType === userInputAnalysisRuleDetails.ruleType).some((ruleset: AnalyseRuleTable) => ruleset.mnemonic === userInputAnalysisRuleDetails.mnemonic)
                const soloHasMnemonic = soloAnalyseConfig.rules
                    .filter(r => r.ruleType === userInputAnalysisRuleDetails.ruleType).some((ruleset: AnalyseRuleTable) => ruleset.mnemonic === userInputAnalysisRuleDetails.mnemonic)
                return (productHasMnemomnic || soloHasMnemonic)
            })

            if (mnemonicInUse) {
                this.toast.error(`Ruleset mnemonic must be unique`)
                inputOK = false
            }
        }

        if (inputOK && !userInputAnalysisRuleDetails.stage) {
            this.toast.error(`Analysis processing stage must be supplied`)
            inputOK = false
        }
        const fields = userInputAnalysisRuleDetails.fields ? userInputAnalysisRuleDetails.fields.filter((field: string) => field) : []
        if (inputOK && fields.length < 1) {
            this.toast.error(`At least one field xpath must be supplied`)
            inputOK = false
        }

        if (inputOK && !fields.every((field: string) => field.length > 0)) {
            this.toast.error(`A field cannot be blank`)
            inputOK = false
        }

        return inputOK
    }

    private _saveNewRuleset(userInputAnalysisRuleDetails: AddAnalysisRuleDetails, userInputSelectedConfigs: string[]) {
        // Add New RuleSet to config files
        userInputSelectedConfigs.forEach((configPath: string) => {
            const configObject = this.changeService.getObject(configPath)
            const docGenConfig = JSON.parse(configObject.content) as DocgenConfig
            const analyseConfig: AnalyseConfig = (userInputAnalysisRuleDetails.stage === 'product') ? docGenConfig.analysePayload : docGenConfig.analyseEnriched

            const newRule = {
                ruleType: userInputAnalysisRuleDetails.ruleType,
                name: userInputAnalysisRuleDetails.name,
                description: userInputAnalysisRuleDetails.description || undefined,
                help: userInputAnalysisRuleDetails.help || undefined,
                mnemonic: userInputAnalysisRuleDetails.mnemonic,
                fields: [...userInputAnalysisRuleDetails.fields],
                rule: [],
            } as AnalyseRuleTable

            // Add New RuleSet to config file
            analyseConfig.rules.push(newRule)
            configObject.content = JSON.stringify(docGenConfig, null, 2)
            this.changeService.upsertObject(configObject)
        })

        // Create New Protected RuleSet file + register with change service
        {
            const newProtectedRuleSet = new ProtectedRuleSet();
            {
                newProtectedRuleSet.mnemonic = userInputAnalysisRuleDetails.mnemonic
                newProtectedRuleSet.stage = userInputAnalysisRuleDetails.stage
                // For filename remove 'settings/' from beginning and '/Config.json' from end
                newProtectedRuleSet.entries = userInputSelectedConfigs.map(x => x.substring(9, x.length - 12))
                newProtectedRuleSet.lastUpdated = DateTime.utc().toISO() // {suppressMilliseconds: true}
            }

            const fileName = `${userInputAnalysisRuleDetails.mnemonic}.json`
            const path = `protected-rulesets/${fileName}`

            const protectedRuleSetFile: ConfigDetail = {
                source: 'lambda-ingest-product',
                name: `${fileName}`,
                path: path,
                size: undefined,
                content: JSON.stringify(newProtectedRuleSet, null, 2),
            } as ConfigDetail

            (protectedRuleSetFile as any).state = 'new'

            this.changeService.addChange(protectedRuleSetFile)
        }
    }
}
