// import * as stringify from 'csv-stringify/lib/sync'
import { take } from 'rxjs/operators'
import { ApprovalConfig } from 'src/app/common/domain/approval'
import { ChangeDetails, DocgenConfigAndMetadataChangeDetails } from 'src/app/common/domain/change'
import { DocgenConfig, DocgenConfigMetadata } from 'src/app/common/domain/docgen-config'
import { NormalisedRuleSet } from 'src/app/model/normalised-ruleset'
import { ChangeService } from 'src/app/services/change.service'
import { unparse } from 'papaparse'
import saveAs from 'file-saver'
import { RuleExclusion } from 'src/app/model/rule-exclusion'
export class UserActionDownloadCsvFiles {

    constructor(
        private _changeService: ChangeService,
    ) {
    }

    // Essentially a Copy/ Paste/ Tweak of the old code
    //  I do not like this code, but it appears to produce exactly the same output as the old code does
    public async downloadRuleSetAsCsv(ruleSetMnemonic) {
        const filePath = `rulesets/${ruleSetMnemonic}.json`
        const normalisedRuleSetChangeDetails: ChangeDetails = await this._changeService.getObject(filePath)
        const normalisedRuleSet: NormalisedRuleSet = new NormalisedRuleSet(filePath)
        Object.assign(normalisedRuleSet, JSON.parse(normalisedRuleSetChangeDetails.content))

        const configs: DocgenConfigAndMetadataChangeDetails[] = await this._changeService.getAllDocgenConfigs().pipe(take(1)).toPromise()
        const fastApprovals: ChangeDetails[] = await this._changeService.getObjectsOfType('approvals').pipe(take(1)).toPromise()

        const rule = normalisedRuleSet.rule

        // console.dir(approvals)
        const csvData = normalisedRuleSet.rule
            .reduce((rows, rulerow, index) => {
                //rulerow.configKeys.forEach((configKey) => {
                normalisedRuleSet.entries.forEach((configKey: string) => {
                    const parts = configKey.split('/')
                    const sourceSystem = parts[0]
                    const programme = parts[1]
                    const productType = parts[2]
                    const documentType = parts[3]
                    // const usage = rulerow.usedBy[configKey]
                    const usage = {
                        "stage": normalisedRuleSet.stage,
                        "ruleType": normalisedRuleSet.ruleType,
                        "description": '', // normalisedRuleSet.description, plugh Defect in old code left description blank 
                        "help": normalisedRuleSet.help,
                        "test": normalisedRuleSet.test
                    }

                    const configData = configs.find(c => {
                        return c.metadata.programme === programme
                            && c.metadata.sourceSystem === sourceSystem
                            && c.metadata.productType === productType
                            && c.metadata.documentType === documentType
                    })
                    const usedConfig = JSON.parse(configData.settings.content) as DocgenConfig
                    const usedAnalysis = usage.stage === 'product' ? usedConfig.analysePayload : usedConfig.analyseEnriched
                    const usedRules = usedAnalysis.rules.find(r => r.mnemonic === normalisedRuleSet.mnemonic)
                    const usedRule = usedRules.rule.find(r => r.mnemonic === rulerow.mnemonic)

                    let prefix = 'UK'
                    if (usage.stage === 'product') {
                        if (usage.ruleType === 'whitelist') {
                            prefix = 'PW'
                        } else if (usage.ruleType === 'blacklist') {
                            prefix = 'PB'
                        } else if (usage.ruleType === 'notSupported') {
                            prefix = 'PU'
                        } else if (usage.ruleType === 'validation') {
                            prefix = 'PV'
                        }
                    } else if (usage.stage === 'solo') {
                        if (usage.ruleType === 'whitelist') {
                            prefix = 'SW'
                        } else if (usage.ruleType === 'blacklist') {
                            prefix = 'SB'
                        } else if (usage.ruleType === 'notSupported') {
                            prefix = 'SU'
                        } else if (usage.ruleType === 'validation') {
                            prefix = 'SV'
                        }
                    }
                    const ruleRowMnemonic = `${prefix}-${normalisedRuleSet.mnemonic}-${rulerow.mnemonic}`
                    const approvalRequired = usedRules.approvalRequired ? 'YES' : ''

                    const result = {
                        sourceSystem: sourceSystem,
                        programme: programme,
                        productType: productType,
                        documentType: documentType,
                        stage: usage.stage,
                        ruleType: usage.ruleType,
                        ruleMnemonic: ruleRowMnemonic,
                        approvalRequired: approvalRequired,
                        approvedBy: usedRule.approved?.user || '',
                        approvedDate: usedRule.approved?.timestamp || '',
                    }
                    normalisedRuleSet.fields.forEach((f, i) => {
                        result[f] = result[f]
                            ? result[f] !== rulerow.expected[i]
                                ? result[f] + ' AND ' + rulerow.expected[i]
                                : rulerow.expected[i]
                            : rulerow.expected[i]
                    })
                    if (usedRules.approvalRequired) {
                        const approvalData = fastApprovals.find(a => a.path === `approvals/${sourceSystem}/${programme}/${productType}/${documentType}/Approvals.json`)
                        if (approvalData) {
                            const approvalConfig = JSON.parse(approvalData.content) as ApprovalConfig
                            const approval = approvalConfig.approvedMnemonics.find(a => a.mnemonic === ruleRowMnemonic)
                            if (approval) {
                                result.approvedBy = approval.approvedBy
                                result.approvedDate = approval.approvedDate
                            }
                        }
                    }

                    rows.push(Object.assign(result, {
                        ruleDescription: usage.description,
                        ruleHelp: '' // plugh Temporarily save as empty to match the old screen(make Binary Equal Easier)   ruleHelp: usage.help,
                        // plugh Temporarily do not include test in the CSV (make Binary Equal Easier)                        test: usage.test,
                    }))
                })
                return rows
            }, [])

        const allCsv = unparse(csvData, {
            header: true,
        })

        const blob = new Blob([allCsv], { type: 'text/csv;charset=utf-8' })
        saveAs(blob, `rule-${normalisedRuleSet.ruleType}-${normalisedRuleSet.mnemonic}-${new Date().toISOString()}.csv`)
    }

    downloadIgnoredMnemonicsAsCsv(ruleExclusion: RuleExclusion) {
        const csvData = [['env', 'mnemonic', 'updatedBy', 'updatedDate']]

        const groupedData = {};

        for (const env in ruleExclusion) {
            const ignoredMnemonics = ruleExclusion[env].ignoredMnemonics;
            for (const mnemonicObj of ignoredMnemonics) {
                if (!groupedData[env]) {
                    groupedData[env] = [];
                }
                groupedData[env].push([env, mnemonicObj.mnemonic, mnemonicObj.updatedBy, mnemonicObj.updatedDate]);
            }
        }

        const sortedEnvs = Object.keys(groupedData).sort();

        for (const env of sortedEnvs) {
            groupedData[env].sort((a, b) => {
                if (a[1] < b[1]) return -1;
                if (a[1] > b[1]) return 1;
                return 0;
            });
            csvData.push(...groupedData[env]);
        }

        const dataForCsv = unparse(csvData)

        const blob = new Blob([dataForCsv], { type: 'text/csv;charset=utf-8' })
        saveAs(blob, `ignored-rules-${new Date().toISOString()}.csv`)
    }

    public downloadSelectedRules(filteredRuleSets: NormalisedRuleSet[]) {
        console.info(`filteredRuleSets length: ` + filteredRuleSets.length)
        this._downloadRules(filteredRuleSets)
    }

    // Essentially a Copy/ Paste/ Tweak of the old code
    //  I do not like this code, but it appears to produce exactly the same output as the old code does
    private async _downloadRules(ruleSetArray: NormalisedRuleSet[], configKey?: string) {
        let allCsv = ''
        const filteredConfig = await this._changeService.getUserFilteredDocgenConfigs().pipe(take(1)).toPromise() as DocgenConfigAndMetadataChangeDetails[]
        const getConfigMetaData: DocgenConfigMetadata[] = filteredConfig.map(x => x.metadata)
        const arrayOfConfigKeys = getConfigMetaData.map(x => {
            return `${x.sourceSystem}/${x.programme}/${x.productType}/${x.documentType}`

        })

        ruleSetArray.forEach((normalisedRuleSet: NormalisedRuleSet) => {
            allCsv = allCsv + normalisedRuleSet.mnemonic + ' - ' + normalisedRuleSet.name + '\n'
            const csvData = normalisedRuleSet.rule
                .reduce((prev, rulerow, index) => {
                    //rulerow.configKeys.forEach((configKey) => {
                    normalisedRuleSet.entries.filter(x => arrayOfConfigKeys.includes(x)).forEach((ck: string) => {
                        const parts = ck.split('/')
                        const sourceSystem = parts[0]
                        const programme = parts[1]
                        const productType = parts[2]
                        const documentType = parts[3]
                        // const usage = rulerow.usedBy[configKey]
                        const usage = {
                            "stage": normalisedRuleSet.stage,
                            "ruleType": normalisedRuleSet.ruleType,
                            "description": '', // normalisedRuleSet.description, plugh Defect in old code left description blank 
                            "help": normalisedRuleSet.help,
                            "test": normalisedRuleSet.test
                        }

                        let prefix = 'UK'
                        if (usage.stage === 'product') {
                            if (usage.ruleType === 'whitelist') {
                                prefix = 'PW'
                            } else if (usage.ruleType === 'blacklist') {
                                prefix = 'PB'
                            } else if (usage.ruleType === 'notSupported') {
                                prefix = 'PU'
                            } else if (usage.ruleType === 'validation') {
                                prefix = 'PV'
                            }
                        } else if (usage.stage === 'solo') {
                            if (usage.ruleType === 'whitelist') {
                                prefix = 'SW'
                            } else if (usage.ruleType === 'blacklist') {
                                prefix = 'SB'
                            } else if (usage.ruleType === 'notSupported') {
                                prefix = 'SU'
                            } else if (usage.ruleType === 'validation') {
                                prefix = 'SV'
                            }
                        }

                        const result = {
                            config: ck, // ck,
                            stage: usage.stage,
                            ruleType: usage.ruleType,
                            ruleMnemonic: prefix + '-' + normalisedRuleSet.mnemonic + '-' + rulerow.mnemonic,
                            ruleDescription: usage.description,
                            ruleHelp: usage.help,
                            test: usage.test,
                        }
                        normalisedRuleSet.fields.forEach((f, i) => {
                            result[f] = result[f]
                                ? result[f] !== rulerow.expected[i]
                                    ? result[f] + ' AND ' + rulerow.expected[i]
                                    : rulerow.expected[i]
                                : rulerow.expected[i]
                        })
                        if (configKey === undefined || configKey === ck) {
                            prev.push(result)
                        }
                    })
                    return prev
                }, [])
            allCsv = allCsv + unparse(csvData, {
                header: true,
            })
            allCsv = allCsv + '\n'
        })

        const blob = new Blob([allCsv], { type: 'text/csv;charset=utf-8' })
        saveAs(blob, (configKey ? configKey.replace(/\//g, '-') : 'all') + '-rules.csv')
    }
}
