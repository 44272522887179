import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

export interface NameDialogData {
  title: string
  name: string
  hint?: string
}

@Component({
  selector: 'app-name-dialog',
  templateUrl: 'name-dialog.component.html',
  styleUrls: ['./name-dialog.component.scss'],
})
export class NameDialogComponent {
  hint: string

  constructor(
    public dialogRef: MatDialogRef<NameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NameDialogData) {
    this.hint = data.hint ? data.hint : 'Used as key in S3'
  }

  cancel() {
    this.dialogRef.close()
  }
}
