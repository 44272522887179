import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { switchMap } from 'rxjs/operators'
import { ChangeService } from '../../services/change.service'
import { ObservableInput, of } from 'rxjs'
import { ConfigDetail } from '../../common/domain/config'
import { ToastrService } from 'ngx-toastr'
import { jsonataEditorOptions, jsonEditorOptions, jsonSchemaEditorOptions, xmlEditorOptions } from '../../util/editor-configurations'
import { CONFIG_NOTE_FILE_NAME_SUFFIX, CreateConfigNote, CreateConfigNoteDetails } from '../../dialogs/note-dialog/create-config-note'
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'app-config-edit',
  templateUrl: './config-edit.component.html',
  styleUrls: ['./config-edit.component.scss'],
})
export class ConfigEditComponent implements OnInit, OnDestroy {
  config: ConfigDetail

  private repository: string
  private type: string
  editorOptions: any
  editorValue: any
  originalLineCount: any;
  newLinesCount: any;
  notes: {
    name: string,
    editorOptions: any,
    editorValue: string,
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private changeService: ChangeService,
    private toast: ToastrService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap): ObservableInput<ConfigDetail> => {
        this.repository = params.get('repository')
        this.type = params.get('type')
        const path = params.get('path')
        const name = path.replace(/^.*\/(.*?)$/, '$1')
        const changed = this.changeService.getChange(path)
        const original = this.changeService.getOriginal(path)
        return of(changed || original || {
          source: this.repository,
          name: name,
          path: path,
          content: '',
          size: 0,
        } as ConfigDetail)
      },
      ),
    ).subscribe(config => {
      this.config = config
      this.editorValue = config.content
      if (this.isTransformConfig() && config.name.endsWith('.json')) {
        this.editorOptions = jsonataEditorOptions
      } else if (this.isSchemaConfig() && config.name.endsWith('.json')) {
        this.editorOptions = jsonSchemaEditorOptions
      } else if (config.name.endsWith('.xsd') || config.name.endsWith('.xslt') || config.name.endsWith('.xml')) {
        this.editorOptions = xmlEditorOptions
      } else {
        this.editorOptions = jsonEditorOptions
      }

      const notesContent = this.supportsNotesFeature() && this.getNotesContent(config)

      this.notes = notesContent ? {
        name: renameAsNoteConfig(config.name),
        editorOptions: { ...jsonEditorOptions, readOnly: true },
        editorValue: JSON.stringify(JSON.parse(notesContent), null, 2)
      } : undefined
    })
    if (this.editorValue) {
      this.getLineCount('original');
    }
  }

  ngOnDestroy(): void {
  }

  cancel() {
    this.toast.warning('Config editing cancelled.')
    this.router.navigate(['config', this.type])
  }

  async save(config: ConfigDetail, editorContents: string) {
    if (this.supportsNotesFeature()) {
      const noteItem = await this.createNewNote(config)

      if (noteItem) {
        this.changeService.addChange(noteItem)
      }
    }

    const item = Object.assign({}, config, { content: editorContents })
    this.changeService.addChange(item)
    this.toast.info('Config updated.')
    this.router.navigate(['config', this.type])
  }

  supportsNotesFeature() {
    return this.isTransformConfig() || this.isSchemaConfig()
  }

  isTransformConfig() {
    return this.config.path.includes('transforms/')
  }

  isSchemaConfig() {
    return this.config.path.includes('schemas/')
  }

  getNotesContent(config: ConfigDetail) {
    const path = renameAsNoteConfig(config.path)
    return this.changeService.getChange(path)?.content ?? this.changeService.getOriginal(path)?.content
  }

  createNewNote = async (config: ConfigDetail): Promise<ConfigDetail | undefined> => {
    const notesContent = this.getNotesContent(config)

    let version = '1.0'
    let notes: { version: string }[] = []

    if (notesContent) {
      notes = JSON.parse(notesContent)
      version = incrementMinor([...notes].reverse()[0].version)
    }

    return new Promise((resolve) => {
      const dialogRef = this.dialog.open(CreateConfigNote, {
        width: '750px',
        data: {
          version,
          configType: this.isTransformConfig() ? 'TRANSFORM' : 'SCHEMA',
        } as CreateConfigNoteDetails
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          notes.push(result)
        }

        resolve(result && {
          name: renameAsNoteConfig(config.name),
          path: renameAsNoteConfig(config.path),
          source: config.source,
          content: JSON.stringify(notes),
          size: 0,
        } as ConfigDetail);
      })
    })
  }

  getLineCount(value?: string) {
    // setTimeout(() => {
    //   monaco.editor.getModels().filter(v => {
    //     if (value === 'original') {
    //       this.originalLineCount = v.getLineCount()
    //     }
    //     if (value === 'edit') {
    //       const getLatestCount = v.getLineCount()
    //       return this.newLinesCount = getLatestCount
    //     }
    //   })
    // }, 250)
  }
}

function renameAsNoteConfig(fileName: string) {
  return fileName.replace(/\.[^.]*$/, CONFIG_NOTE_FILE_NAME_SUFFIX)
}

function incrementMinor(version: string) {
  const parts = version.split('.')
  if (parts.length < 2) {
    throw new Error('Invalid version format')
  }
  parts[1] = String(parseInt(parts[1], 10) + 1)
  return parts.slice(0, 2).join('.')
}
