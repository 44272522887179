import {Component, OnInit} from '@angular/core'
import {ChangeDetails} from '../../common/domain/change'
import {ChangeService} from '../../services/change.service'
import {Index} from '../../common/domain/static'
// import {stringify} from 'csv/lib/sync'
import {saveAs} from 'file-saver'
import { unparse } from 'papaparse'
@Component({
  selector: 'app-config-indexes',
  templateUrl: './config-static.component.html',
  styleUrls: ['./config-static.component.scss'],
})
export class ConfigStaticComponent implements OnInit {
  indexes: Index[] = []
  newIndex: Index = undefined
  configObject: ChangeDetails

  constructor(private changeService: ChangeService) {
  }

  ngOnInit() {
    this.changeService.getObjectsOfType('static')
      .subscribe(changes => {
        this.configObject = changes.find(c => c.name === 'indexes.json')
        this.indexes = (this.configObject ? JSON.parse(this.configObject.content) as Index[] : [])
          .map(i => {
            i.enDisclaimer = i.enDisclaimer || false
            i.enDescription = i.enDescription || false
            i.deDisclaimer = i.deDisclaimer || false
            i.deDescription = i.deDescription || false
            i.frDisclaimer = i.frDisclaimer || false
            i.frDescription = i.frDescription || false
            return i
          })
      })
  }

  saveChanges() {
    this.changeService.addChange(Object.assign({}, this.configObject, {
      content: JSON.stringify(this.indexes),
    }))
  }

  addItem() {
    if (!this.newIndex) {
      this.newIndex = {
        code: 'code',
        name: 'name',
        enDisclaimer: true,
        enDescription: true,
        deDisclaimer: true,
        deDescription: true,
        frDisclaimer: true,
        frDescription: true,
      }
    } else if (this.newIndex.code.length > 0
      && this.newIndex.name.length > 0
      && this.newIndex.code !== 'code'
      && this.newIndex.name !== 'name'
    ) {
      this.indexes.push(this.newIndex)
      this.newIndex = undefined
      this.saveChanges()
    }
  }

  deleteItem(code: string) {
    this.indexes = this.indexes.filter(i => i.code !== code)
    this.saveChanges()
  }

  downloadIndexes() {
    const csvData = this.indexes
      .map(x => {
        const result = Object.assign({}, x)
        return result
      })

    const foo = unparse(csvData, {
      header: true,
    })

    const blob = new Blob([foo], {type: 'text/csv;charset=utf-8'})
    saveAs(blob, 'indexes.csv')
  }

}
