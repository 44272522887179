import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {StatusService} from './status.service'
import {switchMap, tap} from 'rxjs/operators'
import {timer} from 'rxjs'

export interface DashboardDatapoints {
  Timestamp: string
  Average?: number
  Sum?: number
}

export type DashboardMetricName =
  'testsExecuted'
  | 'renderTime'
  | 'analysisTime'
  | 'validationTime'
  | 'transformationTime'
  | 'apiLatency'
  | 'documentsGenerated'

export interface DashboardMetrics {
  metricName: DashboardMetricName
  values: DashboardDatapoints[]
}

export interface DashboardMetricsResponse {
  metrics: DashboardMetrics[]
}

@Injectable({
  providedIn: 'root',
})
export class DashboardMetricsService {

  constructor(private api: ApiService,
              private statusService: StatusService) {
  }

  timer = timer(0, 300000)

  getDashboardMetrics() {
    return this.timer.pipe(
      // tap(_ => {
      //   this.statusService.start('Updating Dashboard...')
      // }),
      switchMap(_ => {
        return this.api.get<DashboardMetricsResponse>('/metrics/dashboard', {headers: {}})
      }),
      // tap(_ => {
      //   this.statusService.clear('Updating Dashboard...')
      // }),
      // shareReplay(1),
    )
  }
}
