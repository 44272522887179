<mat-slide-toggle class = "preprocessor" [(ngModel)]="showPreprocessorMappingConfig" *ngIf="!showDynamicMappingsConfig"  (change)="togglePreprocessorMappingsConfig()">
  PreProcessor Mappings Config
</mat-slide-toggle>

<mat-slide-toggle [(ngModel)]="showVisualDiff" (change)="toggleVisualDiff()">
  Visual Diff
</mat-slide-toggle>


<div *ngIf="!showDynamicMappingsConfig && !showPreprocessorMappingConfig">
  <mat-form-field appearance="standard" floatLabel="always">
    <mat-label>Source System</mat-label>
    <mat-select [(value)]="activeConfig.sourceSystem"
                (selectionChange)="selectSourceSystem($event.value)"
                placeholder="select">
      <mat-option *ngFor="let sourceSystem of selections.sourceSystems"
                  [value]="sourceSystem.value">{{sourceSystem.display}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="standard" floatLabel="always" *ngIf="activeConfig.sourceSystem">
    <mat-label>Programme</mat-label>
    <mat-select [(value)]="activeConfig.programme"
                (selectionChange)="selectProgramme($event.value)"
                placeholder="select">
      <mat-option *ngFor="let programme of selections.programmes"
                  [value]="programme.value">{{programme.display}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="standard" floatLabel="always" *ngIf="activeConfig.programme">
    <mat-label>Product Type</mat-label>
    <mat-select [(value)]="activeConfig.productType"
                (selectionChange)="selectProductType($event.value)"
                placeholder="select">
      <mat-option *ngFor="let productType of selections.productTypes"
                  [value]="productType.value">{{productType.display}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="standard" floatLabel="always" *ngIf="activeConfig.productType">
    <mat-label>Document Type</mat-label>
    <mat-select [(value)]="activeConfig.documentType"
                (selectionChange)="selectDocumentType($event.value)"
                placeholder="select">
      <mat-option *ngFor="let documentType of selections.documentTypes"
                  [value]="documentType.value">{{documentType.display}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="showDynamicMappingsConfig">
  <mat-form-field appearance="standard" floatLabel="always">
    <mat-label>Source System</mat-label>
    <mat-select [(value)]="activeConfig.sourceSystem"
                (selectionChange)="selectSourceSystem($event.value)"
                placeholder="select">
      <mat-option *ngFor="let sourceSystem of selections.sourceSystems"
                  [value]="sourceSystem.value">{{sourceSystem.display}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="standard" floatLabel="always" *ngIf="activeConfig.sourceSystem">
    <mat-label>Document Type</mat-label>
    <mat-select [(value)]="activeConfig.documentType"
                (selectionChange)="selectDocumentType($event.value)"
                placeholder="select">
      <mat-option *ngFor="let documentType of selections.documentTypes"
                  [value]="documentType.value">{{documentType.display}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
