import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {GovernanceResponse} from '../model/governance-responses'

@Injectable({
  providedIn: 'root',
})
export class ExperimentsService {

  constructor(private apiService: ApiService) {
  }

  governanceCheck(sourceSystem: string, payload: string) {
    return this.apiService.post<string, GovernanceResponse>('/experiments/governance/' + sourceSystem, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/xml',
      },
    })
  }
}
