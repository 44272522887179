import { Component, OnInit, OnDestroy } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import { MdValidateService } from "../utils/services/md-validate.service";
import { addTypeToRule, sortStringArray } from '../utils/helpers/md-helper-functions';


import { ValidationRule, SimpleRule, ComplexRule, ComboRule } from '../utils/models/md-validation-models';

@Component({
  selector: 'app-administer-validation-rules',
  templateUrl: './administer-validation-rules.component.html',
  styleUrls: ['./administer-validation-rules.component.scss'],
})
export class AdministerValidationRulesComponent implements OnInit, OnDestroy {
  sortAscendig: boolean = false;
  sortAlphabetically: boolean = false;
  simpleRule: boolean = false;
  editMode: boolean = false;
  currentState: string = 'active';
  allRules: ValidationRule[] = [];
  simpleRules: SimpleRule[] = [];
  complexRules: ComplexRule[] = [];
  comboRules: ComboRule[] = [];
  activeRules: ValidationRule[] = [];
  deletedRules: ValidationRule[] = [];
  rulesDisplayed: ValidationRule[] = [];
  selectedRule: ValidationRule;

  constructor(private modalService: NgbModal, private validateService: MdValidateService) { }

  ngOnInit(): void {
    this.getAllRules();
    this.refreshData();
  }

  ngOnDestroy(): void {
    this.validateService.refreshSubject.unsubscribe();
  }


  openModalDialog(content) {
    this.modalService.open(content, {
      scrollable: true,
      size: 'xl',
      centered: true,
      backdrop: 'static',
    });
  }

  openSimpleRuleDialog(content, rule?): void {
    this.selectedRule = rule
    this.simpleRule = true;
    this.openModalDialog(content);
  }

  openComplexRuleDialog(content, rule?): void {
    this.selectedRule = rule
    this.simpleRule = false;
    this.openModalDialog(content);
  }

  getAllRules(): void {
    this.validateService.getValidationRules().subscribe((rules) => {
      const { simpleRules, complexRules, comboRules } = rules.body;
      this.simpleRules = addTypeToRule(simpleRules, 'Simple') as SimpleRule[];
      this.complexRules = addTypeToRule(complexRules, 'Complex') as ComplexRule[];
      this.comboRules = addTypeToRule(comboRules, 'Combo') as ComboRule[];

      this.allRules = this.simpleRules.concat(this.comboRules as any, this.complexRules as any);
      this.allRules.forEach(el => {
        el.deleted ? this.deletedRules.push(el) : this.activeRules.push(el);
      })
      this.filterData(this.currentState);
    })
  }

  editRule(content, rule: ValidationRule) {
    this.editMode = true;
    if (rule.id.includes('SR')) {
      this.openSimpleRuleDialog(content, rule)
    } else {
      this.openComplexRuleDialog(content, rule)
    }
  }

  deleteRule(ruleId: string): void {
    this.validateService.softDeleteValidationRule(ruleId);
  }

  restoreRule(ruleId: string): void {
    this.validateService.restoreRule(ruleId);
  }

  filterData(condition: string): void {
    condition = condition.toLowerCase();
    this.currentState = condition;

    switch (condition) {
      case "active":
        this.rulesDisplayed = this.activeRules;
        break;
      case "simple":
        this.rulesDisplayed = this.simpleRules.filter(el => !el.deleted);
        break;
      case "complex":
        this.rulesDisplayed = this.complexRules.filter(el => !el.deleted);
        break;
      case "combo":
        this.rulesDisplayed = this.comboRules.filter(el => !el.deleted);
        break;
      case "deleted":
        this.rulesDisplayed = this.deletedRules;
        break;
      default:
        this.rulesDisplayed = this.activeRules;
        break;
    }
  }

  refreshData(): void {
    this.validateService.refreshSubject.subscribe(() => {
      this.allRules = [];
      this.rulesDisplayed = [];
      this.deletedRules = [];
      this.activeRules = [];
      this.simpleRules = [];
      this.complexRules = [];
      this.comboRules = [];
      this.getAllRules();
    })
  }

  sortTableData(value: string): void {
    this.sortAlphabetically = !this.sortAlphabetically;
    sortStringArray(this.rulesDisplayed, this.sortAlphabetically, value);
  }

}
