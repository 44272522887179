<ng-container class="main" *ngIf="isSelected">
  <span class="h2">{{testName}}</span>
  <span>
    &nbsp;&nbsp;{{currentRule}}
  </span>
  <br>
  <br>
  <mat-label>Recomended Test for <strong>{{rule}}</strong> Rule</mat-label>
  <br>
  <br>

  <ng-template #rt let-r="result" let-t="term">
    {{ r.testName}}
  </ng-template>

  <div class="form-inline form-group">
    <input id="testSelector" type="text" class="form-control form-control-sm" type="search" #instance="ngbTypeahead"
      aria-label="Search" [value]="selectedRecomendedTest" [ngbTypeahead]="search" [resultTemplate]="rt"
      [inputFormatter]="formatter" placeholder="Test or Example" (focus)="focus$.next($event.target.value)"
      (click)="click$.next($event.target.value)">
  </div>
  <br>
  <br>
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="saveSelectedTest()">Select</button>
    <button type="button" class="btn btn-secondary" (click)="closeDialog()">Cancel</button>
  </div>
</ng-container>