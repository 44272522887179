<h2 mat-dialog-title>Add New RuleSet</h2>

<div mat-dialog-content>

  <table>

    <tr>
      <td>RuleSet Mnemonic *</td>
      <td>
        <mat-form-field class="entry-full-width"><input matInput [(ngModel)]="newRuleSet.mnemonic" required (input)=this.validateInput()>
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td>Name *</td>
      <td>
        <mat-form-field  class="entry-full-width"><input matInput [(ngModel)]="newRuleSet.name" required (input)=this.validateInput()>
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td>Description *</td>
      <td>
        <mat-form-field class="entry-full-width"><input matInput [(ngModel)]="newRuleSet.description" required (input)=this.validateInput()>
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td>Analysis Stage *</td>
      <td>
        <mat-form-field>
          <mat-select [(value)]="newRuleSet.stage" (selectionChange)=this.validateInput() required>
            <mat-option *ngFor="let stage of this.stages" [value]="stage">{{stage}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </tr>

    <tr>
      <td>Type *</td>
      <td>
        <mat-form-field>
          <mat-select [(value)]="newRuleSet.ruleType" required (selectionChange)=this.validateInput()>
            <mat-option *ngFor="let ruleType of this.ruleTypes" [value]="ruleType">{{ruleType}}</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </tr>

    <ng-container  *ngFor="let _ of newRuleSet.fields;let i = index; trackBy:trackByIndex;">
      <tr  *ngIf="!complexRule">
        <td >XPath Field {{i+1}} *</td>
        <td>
          <mat-form-field class="entry-full-width"><input matInput placeholder="XPath 1.0 expression" [(ngModel)]="newRuleSet.fields[i]"
              (input)=this.validateInput() required></mat-form-field>
        </td>
        <button class="delete-field-btn" *ngIf="showDelete" (click)="this.actionDeleteField(i)" mat-raised-button color="primary">Delete Field</button>
      </tr>
    </ng-container>
  </table>

  <div *ngIf="!complexRule" style="padding: 5px 0px 5px 5px">
    <button (click)="this.actionAddField()" mat-raised-button color="primary">Add Field</button>
  </div>

</div>

<div mat-dialog-actions style="padding: 5px 0px 5px 5px">
  <button mat-raised-button color="primary" [disabled]="!this.canSave" (click)=this.actionCreate()>Create</button>
  <button type="button" class="btn btn-secondary" (click)="actionCancel()">Cancel</button>
</div>
