<div class='flex-page-container local-dialog-container-padding smaller-modal'>
  <div class="flex-row inspire-surround-header-flex header-span-center">
    <span class="h4" *ngIf="!data.alternativeTitle"><strong>(DANGER! Only use in emergencies!)</strong> <br/>Confirm Smart DX Clean Restart for {{data.env}} in {{data.region.display}}</span>
    <span class="h4" *ngIf="data.alternativeTitle"><strong>(DANGER! Only use in emergencies!)</strong> <br/>{{data.alternativeTitle}} for {{data.env}} in {{data.region.display}}</span>
  </div>
  <div class='flex-content-container'>
    <div class='flex-right-main-content'>
      <div class='inspire-surround-flex'>
        <div class="inspire-surround-flex flex-column-grow-one">
          <h5>Jira ID</h5>
          <input [(ngModel)]="data.jiraId" placeholder="Enter ID..." required>
        </div>
      </div>
      <div class='inspire-surround-flex'>
        <div class="inspire-surround-flex flex-column-grow-one">
          <h5>Jira Description</h5>
          <textarea [(ngModel)]="data.jiraDescription" placeholder="Enter description..." maxlength="128" required></textarea>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class='flex-row-grow-one inspire-surround-flex align-right'>
      <button type="button" class="btn btn-secondary" (click)="cancel()">Cancel</button>
      <button type="button" class="btn btn-primary mr-2" [mat-dialog-close]="data">OK</button>
    </div>
  </div>
</div>
