import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Subscription } from 'rxjs';
import { RuleType } from 'src/app/common/domain/analyse';
import { ComplexRule, ComboRule, ValidationRule, SimpleRule, SaveRuleRequest, SaveComplexRequest, SaveComboRequest } from "../utils/models/md-validation-models";
import { MdValidateService } from '../utils/services/md-validate.service';

type State = {
  pickedRules: (SimpleRule | ComboRule)[];
  availableRules: (SimpleRule | ComboRule)[];
}

@Component({
  selector: "app-complex-rules",
  templateUrl: "./complex-rules.component.html",
  styleUrls: ["./complex-rules.component.scss"],
})
export class ComplexRulesComponent implements OnInit, OnDestroy {
  @Input() modal: any;
  @Input() selectedRule: ComplexRule | ComboRule;
  @Input() activeRules: ValidationRule[];
  @Input() isEditMode: boolean;
  @Output() isEditModeChange = new EventEmitter<boolean>()

  isRuleTypeSelected: boolean = false;

  complexRulesForm: UntypedFormGroup;
  pickedRules: ValidationRule[] = [];
  availableRules: ValidationRule[] = [];

  private subscriptions: Subscription[] = [];

  constructor(private formBuilder: UntypedFormBuilder, private validateService: MdValidateService) { }

  ngOnInit(): void {
    this.filterRules();
    this.initializeForm();
    this.subscriptions.push(
      this.onRuleTypeChange()
    )
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length > 0) this.subscriptions.forEach(s => s.unsubscribe);
  }

  initializeForm(): void {
    this.complexRulesForm = this.formBuilder.group({
      id: [{ value: this.selectedRule ? this.selectedRule.id : "", disabled: this.selectedRule ? true : false }],
      type: [{ value: this.selectedRule ? this.selectedRule.type : null, disabled: this.selectedRule ? true : false }, Validators.required],
      description: [this.selectedRule ? this.selectedRule.description : "", Validators.required],
      rule: [null]
    });
  }



  filterRules(): void {
    if (!this.selectedRule) this.availableRules = this.activeRules.filter(rule => rule.type === 'Simple' || rule.type === 'Combo');

    if (this.selectedRule) {
      const codesArray = this.selectedRule.id.includes('CX') ? (this.selectedRule as ComplexRule).combo_codes.split(', ') :
        (this.selectedRule as ComboRule).simple_codes.split(', ');

      if (this.selectedRule.type === 'Combo') { //if type combo, only select simple rules for available rules
      this.activeRules.forEach(rule => {
        if (rule.type === 'Simple') {
          codesArray.includes(rule.id) ? this.pickedRules.push(rule) : this.availableRules.push(rule);
        }
      });
      }
      if (this.selectedRule.type === 'Complex') { //if type complex, only select combo rules for available rules
        this.activeRules.forEach(rule => {
          if (rule.type === 'Combo') {
            codesArray.includes(rule.id) ? this.pickedRules.push(rule) : this.availableRules.push(rule);
          }
        })
      }
    }

  }

  addRule(): void {
    const ruleControl = this.complexRulesForm.get('rule');
    if (ruleControl.value) {
      const id = ruleControl.value.id;
      this.pickedRules.push(ruleControl.value);
      this.availableRules = this.availableRules.filter(rule => rule.id !== id);
    }
    ruleControl.patchValue(null);
  };

  removeRule(ruleToBeRemoved: ValidationRule): void {
    this.pickedRules = this.pickedRules.filter(rule => rule !== ruleToBeRemoved);
    if (this.pickedRules.length === 0) this.complexRulesForm.get('rule').reset();
    this.availableRules = [...this.availableRules, ruleToBeRemoved].sort((a, b) => a.id > b.id ? 1 : -1);
    this.complexRulesForm.get('rule').patchValue(null);
  };

  submit(): void {
    const { id, type, description } = this.selectedRule ? this.complexRulesForm.getRawValue() : this.complexRulesForm.value;

    let saveRuleRequest: SaveRuleRequest = { id, type, description };
    const codes = this.pickedRules.map(rule => rule.id).join(', ');
    if (type === 'Combo') saveRuleRequest = { ...saveRuleRequest, ...{ simple_codes: codes } } as SaveComboRequest;
    if (type === 'Complex') saveRuleRequest = { ...saveRuleRequest, ...{ combo_codes: codes } } as SaveComplexRequest;
    this.validateService.saveValidationRule(saveRuleRequest);
    this.isEditModeChange.emit(false);
    this.modal.close();
  }

  onRuleTypeChange(): Subscription {
    return this.complexRulesForm.get('type').valueChanges //listen to changes on the Type form feld
      .subscribe(ruleType => {
        this.isRuleTypeSelected = true;
        this.availableRules = this.activeRules.filter(rule => rule.type === 'Simple' || rule.type === 'Combo');
        this.pickedRules = []

        if (ruleType === 'Complex') { //if type complex is selected save previous state and set type value to null
          this.pickedRules = this.pickedRules.filter(rule => rule.type === 'Combo');
          this.availableRules = this.availableRules.filter(rule => rule.type === 'Combo');
          this.complexRulesForm.get('rule').patchValue(null);
        };

        if (ruleType === 'Combo') { //if type combo is selected, check for previous state and update picked and available rules
          this.pickedRules.forEach(rule => {
            if (rule.type === 'Simple') this.pickedRules.push(rule);
          })
          this.availableRules = this.availableRules.filter(rule => rule.type === 'Simple');
          this.complexRulesForm.get('rule').patchValue(null);

        }
      })
  }

  closeModal(): void {
    this.isEditModeChange.emit(false);
    this.modal.close();
  }

}

