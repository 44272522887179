import {SelectionModel} from '@angular/cdk/collections'
import {NestedTreeControl} from '@angular/cdk/tree'
import {AfterContentChecked, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog'
import {MatTreeNestedDataSource} from '@angular/material/tree'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import cloneDeep from 'clone-deep'
import deepFreeze from 'deep-freeze'
import {BehaviorSubject, Subscription} from 'rxjs'
import {take} from 'rxjs/operators'
import {ConfigKeyElement} from 'src/app/common/domain'
import {AnalyseMatch, AnalyseRow} from 'src/app/common/domain/analyse'
import {ChangeDetails, DocgenConfigAndMetadataChangeDetails} from 'src/app/common/domain/change'
import {ConfigItem} from 'src/app/common/domain/config'
import {NormalisedRuleSet} from 'src/app/model/normalised-ruleset'
import {ChangeService} from 'src/app/services/change.service'
import {PermissionsService} from 'src/app/services/permissions.service'
import {WorkspaceAutoSaveService} from 'src/app/services/workspace-auto-save.service'
import {ConfirmDialogComponent} from '../confirm-dialog.component'
import {MaintainRuleSetTabs} from './enum-maintain-ruleset-tabs'
import {Ruleset2021ViewEditSaveLogic} from './logic/ruleset-2021-view-edit-save-logic'
import {RuleSet2021ViewEditTreeViewLogic} from './logic/ruleset-2021-view-edit-save-treeview-logic'
import {RuleSet2021ViewEditDialogComponentData} from './ruleset-2021-view-edit-dialog-component-data'
import {TabObj} from './tab-obj'
import {TestSuiteRecomendationsComponent } from 'src/app/views/test-recommendation.component'
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component'
import { Combination } from 'src/app/model/combination.request'
import { all } from 'deepmerge'
import { set } from 'lodash'


export type MaintainRuleSetMode = 'view' | 'edit' | 'create'

interface RuleDefinition {
  currentFullMnemonic: string
  ruleMnemonic: string
  recomendedTest: string,
  assign: boolean,
}
@Component({
  templateUrl: './ruleset-2021-view-edit-dialog.component.html',
  styleUrls: ['./ruleset-2021-view-edit-dialog.component.scss'],
})
export class RuleSet2021ViewEditDialogComponent implements OnInit, AfterContentChecked, OnDestroy {

  constructor(public dialogRef: MatDialogRef<RuleSet2021ViewEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: RuleSet2021ViewEditDialogComponentData,
    private _changeService: ChangeService,
    private _modalService: NgbModal,
    private _permissionsService: PermissionsService,
    protected _workspaceAutoSaveService: WorkspaceAutoSaveService,
    private dialog: MatDialog

  ) {
  }

  private _docgenConfigItems: ConfigItem[]
  public CurrentlyExecuting = false
  public viewOnly = true
  public documentFormatTypes = ['both', 'word', 'pdf']
  public listOfWhiteListRules = [];
  public combinations = []
  public updateRuleCombination = false
  public displayRuleCombination = []
  public updateCombinationWithRule = false
  public updateInputRuleCombination = false
  public toggleRulesForRuleSet = false
  public chosenDocFormatType: string;
  public chosenRuleSetType: string
  ruleSetDialogRef: MatDialogRef<TestSuiteRecomendationsComponent>
  ruleDefinition: RuleDefinition[] = [];
  public addNewRuleCombination: string[] = []
  public updatedCombination: string[] = []
  public inputUpdateRuleCombintion: string[] = []
  public rulesetsOfLinkedRules =''
  private filterValue = ''
  public addedNewRuleCombination = false
  public combinationSelected = false
  public isInputUpdate = false
  public isDuplicateCombination = false

  @ViewChild('updatedValue') updatedComb: ElementRef;
  // TODO plugh Perhaps move below to a model class so all can update at once?
  public screenTitle = ''
  public treeControl = new NestedTreeControl<ConfigKeyElement>(node => node.children) // Used by Html
  public treeViewDataSource = new MatTreeNestedDataSource<ConfigKeyElement>() // Used by Html
  private _checklistSelection = new SelectionModel<ConfigKeyElement>(true)

  public isDirty = false // Used by html
  public isReadyToSave = false // Used by html
  public addingNewRule = false // Used by html
  public nextRuleMnemonicInSequence = '9999'
  public nextComplexRuleMnemonicInSequence = '9999'

  public newFieldValues = new Array<string>()
  public newOperators = new Array<object>()
  private _userFilteredDocgenConfigs: DocgenConfigAndMetadataChangeDetails[] = []
  private _currentSourceSystemDocgenConfigs: DocgenConfigAndMetadataChangeDetails[] = []

  public normalisedRuleSet: NormalisedRuleSet // Used by html
  public normalisedRuleSetOrginal: NormalisedRuleSet

  private _filteredNormalisedRulesetRulesSubject = new BehaviorSubject<AnalyseRow[]>(undefined)
  public filteredNormalisedRulesetRules: AnalyseRow[]
  private _deletedNormalisedRulesetRulesSubject = new BehaviorSubject<AnalyseRow[]>(undefined)
  public deletedNormalisedRulesetRules: AnalyseRow[]

  // Tab Related:
  public allPossibleTabs = new Array<TabObj>()
  public enabledTabs = new Set<string>()
  public currentTab: MaintainRuleSetTabs = MaintainRuleSetTabs.Basics
  public selectedTabEnumName = MaintainRuleSetTabs[MaintainRuleSetTabs.Basics]

  public canDeleteRule = false
  public deleteEnabled = false
  public canRestore = false
  public editRuleSets = false
  // Track Obserable subsriptions so we can unsubscribe when appropiate
  private _subscriptions = new Array<Subscription>()
  private combinedRules = []
  operatorTypeItems = [
    { id: '=', displayValue: '= (Equals)' },
    { id: '!', displayValue: '! (Not)' },
    { id: '!=', displayValue: '!= (Not equal)' },
    { id: '>', displayValue: '> (Greater than)' },
    { id: '<', displayValue: '< (Less than)' },
    { id: '>=', displayValue: '>= (Greater than or Equal to)' },
    { id: '<=', displayValue: '<= (Less than or Equal to)' },
    {
      id: '~',
      displayValue: '~ (Match regex)',
    },
    {
      id: 'all',
      displayValue: 'all',
    },
    {
      id: 'all-no-solo',
      displayValue: 'all-no-solo',
    },
    {
      id: 'allSame',
      displayValue: 'allSame',
    },
    {
      id: 'any-of',
      displayValue: 'any-of',
    },
    {
      id: 'date before oneMonthAgo',
      displayValue: 'date before oneMonthAgo',
    },
    {
      id: 'dateBefore',
      displayValue: 'dateBefore',
    },
    {
      id: 'dateInPast',
      displayValue: 'dateInPast',
    },
    {
      id: 'each',
      displayValue: 'each',
    },
    { id: 'ignored', displayValue: 'ignored' },
    {
      id: 'isempty',
      displayValue: 'isempty',
    },
    {
      id: 'match',
      displayValue: 'match',
    },
    {
      id: 'mix-of',
      displayValue: 'mix-of',
    },
    {
      id: 'not',
      displayValue: 'not',
    },
    {
      id:'not-all-same',
      displayValue:'not-all-same',
    },
    {
      id:'not-one-of',
      displayValue:'not-one-of',
    },
    {
      id: 'one-of',
      displayValue: 'one-of',
    },
    {
      id: 'present',
      displayValue: 'present',
    },
    {
      id: 'quanto',
      displayValue: 'quanto',
    },
    {
      id: 'quantoMix',
      displayValue: 'quantoMix',
    },
    {
      id: 'quantoPairs',
      displayValue: 'quantoPairs',
    },
    {
      id: 'quantoPairsCount',
      displayValue: 'quantoPairsCount',
    },
    {
      id: 'some',
      displayValue: 'some',
    },
  ];
public loading = false;

  public async ngOnInit() {
    this._subscriptions.forEach(x => x.unsubscribe())

    this.addingNewRule = false // Used by html
    this.nextRuleMnemonicInSequence = '9999'
    this.nextComplexRuleMnemonicInSequence = '9999'
    this.isDirty = false
    this.isReadyToSave = false

    // *** Wait for Change Server Constructor to complete before doing anthing else ***
    // await BasicHelper.WaitForChangeServiceConstructorToComplete(this.changeService)

    this.screenTitle = this.dialogData.mode === 'create' ? 'Create' : (this.dialogData.mode === 'edit' ? 'Edit' : 'View')
    this.screenTitle += ` ${this.dialogData.normalisedRuleSet.mnemonic}`

    this._populateAllPossibleTabs()
    this._resetVisibleTabs()

    this._subscriptions.push(this._permissionsService.deleteRule.subscribe(permission => this.canDeleteRule = permission))
    this._subscriptions.push(this._permissionsService.editRuleSets.subscribe(permission => this.editRuleSets = permission))
    this.viewOnly = this.dialogData.mode === 'edit' && this.editRuleSets === true ? false : true;

    const normalisedRuleSetChangeDetails: ChangeDetails = await this._changeService.getObject(`rulesets/${this.dialogData.normalisedRuleSet.mnemonic}.json`)
    {
      this.normalisedRuleSet = JSON.parse(normalisedRuleSetChangeDetails.content) as NormalisedRuleSet
      await this.saveToCombinationArray()
      this.normalisedRuleSetOrginal = cloneDeep(this.normalisedRuleSet, true) as unknown as NormalisedRuleSet
      // this.normalisedRuleSet.approvalRequiredRegEx.includes('(') ? this.normalisedRuleSet.approvalRequired = true : false;
      deepFreeze(this.normalisedRuleSetOrginal)
    }
    this._filterDeletedRulesFromNormalisedRuleset()

    this._subscriptions.push(this._filteredNormalisedRulesetRulesSubject.subscribe(value => {
      this.filteredNormalisedRulesetRules = value
    }))

    this._subscriptions.push(this._deletedNormalisedRulesetRulesSubject.subscribe(value => {
      this.deletedNormalisedRulesetRules = value
    }))

    this.canRestore = this.dialogData.showRulesetRestore ? true : false
    this._setChosenDocFormatType()

    // Get the Source System the User is working on
    // Get the SourceSystem the current user is working on (first one we find in the user's filter)
    // TODO plugh This assumes the user only has one SourceSystem selected. If not confusion and/or trouble  may occur when maintaining RuleSets
    // TODO plugh Perhaps the Config Filter screen should only permit one SourceSystem to be selected
    this._userFilteredDocgenConfigs = await this._changeService.getUserFilteredDocgenConfigs().pipe(take(1)).toPromise()
    const currentSourceSystems = Array.from(this._userFilteredDocgenConfigs.reduce((acc: Set<string>, curDocGenConfig: DocgenConfigAndMetadataChangeDetails) => {
      acc.add(curDocGenConfig.metadata.sourceSystem)
      return acc
    }, new Set<string>()).values())
    // console.info(`currentSourceSystems: ${currentSourceSystems}`)

    // Get all the DocGenConfigs (i.e. ignoring the user's filter)
    //  sort them and this becomes the 'selected' items for the new workspace
    let allDocgenConfigs = await this._changeService.getAllDocgenConfigs().pipe(take(1)).toPromise()
    allDocgenConfigs = allDocgenConfigs.filter(x => currentSourceSystems.includes(x.metadata.sourceSystem))
    this._currentSourceSystemDocgenConfigs = allDocgenConfigs

    this.treeViewDataSource.data = this._BuildTreeView()
    if (this.dialogData.normalisedRuleSet.ruleType === 'complex') {
        if (this.dialogData.isNewComplexRuleSet) {
          this.currentTab = MaintainRuleSetTabs.ProductDocumentApplications
          this.selectedTabEnumName = MaintainRuleSetTabs[MaintainRuleSetTabs.ProductDocumentApplications]
      }
      this._getListOfWhiteListRules();
      if(this.normalisedRuleSet.linkedRules){
      this.filterWhiteListRules(this.normalisedRuleSet.linkedRules?.combinations)
      }
    }
  }

  public ngAfterContentChecked() {
    const treeViewLogic = new RuleSet2021ViewEditTreeViewLogic(this._checklistSelection)
    treeViewLogic.expandAllSelectedTreeViewNodes(this.treeControl)
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(x => x.unsubscribe())
  }

  // tslint:disable-next-line: member-ordering
  // public _currentlyExecuting = false
  // public get CurrentlyExecuting(): boolean {
  //   return this._currentlyExecuting
  // }
  // public set CurrentlyExecuting(value: boolean) {
  //   // Set cursor to wait if we are executing
  //   const cursor = value ? 'wait' : 'default'
  //   document.body.style.cursor = cursor

  //   this._currentlyExecuting = value
  //   // this._changeDetectorRef.markForCheck()
  // }

  public camelCaseToWords(text: string) {
    let result = text?.substr(0, 1).toUpperCase() + text?.substr(1) // Ensure first letter is Uppercase
    result = result.replace(/([A-Z][a-z])/g, ' $1').replace(/(\d)/g, ' $1');
    return result.trim()
  }

  // Tab Related
  public actionSelectTab(tabName: string) {
    // console.info('select tab: ' + tabName)
    this.selectedTabEnumName = tabName
    this.dialogData.isNewComplexRuleSet = false;
  }

  // tabObjs is a class property the html uses to render tabs
  private _populateAllPossibleTabs() {
    const allPossibleTabs = new Array<TabObj>()

    Object.values(MaintainRuleSetTabs).forEach((curKey: number) => {
      if (isNaN(curKey)) {
        return
      }

      const curDesc = MaintainRuleSetTabs[curKey]
      allPossibleTabs.push({numericKey: curKey, name: curDesc})
    })

    this.allPossibleTabs = allPossibleTabs
  }

  private _resetVisibleTabs() {
    this.enabledTabs = new Set<string>()
    this.enabledTabs.add(MaintainRuleSetTabs[MaintainRuleSetTabs.Basics])
  }
  // End Tab Related

  // TreeView Related
  private _BuildTreeView(): ConfigKeyElement[] {
    this._docgenConfigItems = this._currentSourceSystemDocgenConfigs.map((c) => c.settings);
    // console.info('this.data.configItems')
    // console.dir(this.data.configItems)

    const userConfigFilterSummary = this._userFilteredDocgenConfigs.map(x => `${x.metadata.sourceSystem}/${x.metadata.programme}/${x.metadata.productType}/${x.metadata.documentType}`)
    // console.info(`userConfigFilterSummary`)
    // console.dir(userConfigFilterSummary)

    const treeview = RuleSet2021ViewEditTreeViewLogic.buildTree(this._docgenConfigItems)

    this.normalisedRuleSet.entries.forEach((curEntry: string) => {
      curEntry = 'settings/' + curEntry + '/Config.json'
      const match = curEntry.match(/^settings\/(.+)\/(.+)\/(.+)\/(.+)\/Config.json$/)
      if (match && match.length >= 5) {
        const sourceSystem = match[1]
        const sourceSystemNode = treeview ? treeview.find((curConfigKeyElement: ConfigKeyElement) => curConfigKeyElement.name === sourceSystem) : undefined

        const programme = match[2]
        const programmeNode = sourceSystemNode ? sourceSystemNode.children.find((curConfigKeyElement: ConfigKeyElement) => curConfigKeyElement.name === programme) : undefined

        const productType = match[3]
        const productTypeNode = programmeNode ? programmeNode.children.find(curConfigKeyElement => curConfigKeyElement.name === productType) : undefined

        const documentType = match[4]
        const documentTypeNode = productTypeNode ? productTypeNode.children.find(curConfigKeyElement => curConfigKeyElement.name === documentType) : undefined

        if (documentTypeNode) {
          documentTypeNode.isSelected = true
          this._checklistSelection.select(documentTypeNode)
        }

        const treeViewLogic = new RuleSet2021ViewEditTreeViewLogic(this._checklistSelection)
        treeViewLogic.setAncestors(documentTypeNode, true)
      }
    })

    // Hide TreView leaf items that are not in the current filter
    // TODO plugh Ideally we'd rewind back the tree and hide parents where there are no children
    treeview.forEach((configKeyElementLevel0: ConfigKeyElement) => {
      configKeyElementLevel0.children.forEach((configKeyElementLevel1: ConfigKeyElement) => {

        configKeyElementLevel1?.children.forEach((configKeyElementLevel2: ConfigKeyElement) => {

          configKeyElementLevel2?.children.forEach((configKeyElementLevel3: ConfigKeyElement) => {
            const documentPath = configKeyElementLevel0.name + '/' + configKeyElementLevel1.name + '/' + configKeyElementLevel2.name + '/' + configKeyElementLevel3.name
            // console.info(`documentPath: ${documentPath}`)

            // Hide leaves that are not in user's filter
            if (configKeyElementLevel3.isSelected === false) {
              configKeyElementLevel3.hide = !userConfigFilterSummary.includes(documentPath)
            }
          })
        })
      })
    })

    return treeview
  }

  public actionToggleSelection(node: ConfigKeyElement): void {
    this.isDirty = true
    this._checklistSelection.toggle(node)
    node.isSelected = !node.isSelected
    const treeViewLogic = new RuleSet2021ViewEditTreeViewLogic(this._checklistSelection)
    treeViewLogic.setAncestors(node, node.isSelected)
    treeViewLogic.setDescendents(node, node.isSelected)
  }

  // Used by TreeView html
  public isSelected(node: ConfigKeyElement): boolean {
    return this._checklistSelection.isSelected(node)
  }

  private _flattenSelectedTreeViewItems(configKeyElement: ConfigKeyElement[]) {
    const selectedConfigKeyElements = new Array<string>()
    configKeyElement.forEach((l1: ConfigKeyElement) => {
      if (l1.isSelected && l1.children) {
        l1.children.forEach((l2: ConfigKeyElement) => {
          if (l2.isSelected) {
            l2.children.forEach((l3: ConfigKeyElement) => {
              if (l3.isSelected) {
                l3.children.forEach((l4: ConfigKeyElement) => {
                  if (l4.isSelected) {
                    const selectedLeafNode = [l1.name, l2.name, l3.name, l4.name].join('/')
                    selectedConfigKeyElements.push(selectedLeafNode)
                  }
                })
              }
            });
          }
        })
      }
    })

    selectedConfigKeyElements.sort()
    return selectedConfigKeyElements
  }

  // Do not delete this function
  hasChild(_: number, node: ConfigKeyElement) {
    const result = !!node.children && node.children.length > 0
    return result
  }
  // End TreeView Related

  // Primary User Actions
  public async actionCancel() {
    // Warn if Dirty
    if (this.isDirty) {
      const confirmDialog = this._modalService.open(ConfirmDialogComponent, {
        size: 'lg',
        backdrop: false,
      })
      confirmDialog.componentInstance.title = 'Discard changes'
      confirmDialog.componentInstance.message = 'Are you sure you wish to discard your changes?'

      const dialogResult = await confirmDialog.result;
      console.warn(dialogResult)

      if (+dialogResult !== 1) {
        return
      }
    }

    this.dialogRef.close(false) // false means no changes were made
  }

  public async actionSave() {
    this.loading = true;
setTimeout(async()  => {
    const selectedConfigKeyElements = this._flattenSelectedTreeViewItems(this.treeViewDataSource.data)
    // console.info('this._selectedConfigKeyElements')
    this._validateNormalisedRulesetDocFormatType()

    // Add New Rule
    if (this.addingNewRule) {
      this.trimLeadingAndTrailingSpaces()
      const newAnalyseRow = new AnalyseRow()
      newAnalyseRow.mnemonic = this.nextRuleMnemonicInSequence
      newAnalyseRow.expected = new Array<AnalyseMatch>()

      this.combinedRules.forEach(curExpectedValue => {
        newAnalyseRow.expected.push(curExpectedValue.trim())
      })

      this.normalisedRuleSet.rule.push(newAnalyseRow)
      this.addingNewRule = false
    }
   await this.saveCombinations()
    Ruleset2021ViewEditSaveLogic.updateWithChangeService(this.normalisedRuleSet, selectedConfigKeyElements, this._changeService)

    // Inform User the Save is Complete
    {
      this.loading = false;
      // const confirmDialog = this._modalService.open(ConfirmDialogComponent, {
      //   size: 'lg',
      //   backdrop: false,
      // })
      // confirmDialog.componentInstance.title = 'Save Completed'
      // confirmDialog.componentInstance.message = 'Changes are saved. This screen will refresh its Data when you press OK.'

      // const dialogResult = await confirmDialog.result;

      // await BasicHelper.delay(250) // Delay in place because the original code had a debounce of 250ms. I do not think this line is needed
      // Here seems as good a place as anywhere to save any existing workspace...
      this._workspaceAutoSaveService.saveWorkspace()

      // Refresh the page
      this.ngOnInit()
    }
  }, 0);
  }

  public actionOpenAddNewRule() {
    this.addingNewRule = true
    this.nextRuleMnemonicInSequence = ((+this.normalisedRuleSetOrginal.rule.map(x => x.mnemonic).sort().reverse()[0] + 1) + '').padStart(4, '0')
    if (this.nextRuleMnemonicInSequence === '0NaN') {
      this.nextRuleMnemonicInSequence = '0000'
    }
    const numberOfFields = this.normalisedRuleSetOrginal.fields.length
    this.newFieldValues = new Array<string>(numberOfFields)
    this.newOperators = new Array<object>(numberOfFields)
  //   this.newOperators.fill({
  //     "id": "ignored",
  //     "displayValue": "ignored"
  // })
    this.newFieldValues.fill('')
    this.isDirty = true
    this.validateTabs()
  }

  public actionToggleDeleteEnabled(event: Event) {
    this.deleteEnabled = !this.deleteEnabled
  }

  public actionDeleteRule(ruleMnemonic: string) {
    const deletion = {
      comment: 'Deleted via storm UI',
      timestamp: new Date().toISOString(),
      user: this.dialogData.username
    }
    this.normalisedRuleSet.rule.find(x => x.mnemonic === ruleMnemonic).deletion = deletion
    this._filterDeletedRulesFromNormalisedRuleset()
    this.validateTabs()
  }

  public async actionRestoreRule(ruleMnemonic: string) {
    this.normalisedRuleSet.rule.find(x => x.mnemonic === ruleMnemonic).deletion = undefined
    this._filterDeletedRulesFromNormalisedRuleset()
    this.validateTabs()
  }

  public async actionDeleteRuleSet() {
    const confirmDialog = this._modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })
    confirmDialog.componentInstance.title = 'Delete RuleSet'
    confirmDialog.componentInstance.message = `Are you sure you wish to soft delete RuleSet ${this.dialogData.normalisedRuleSet.mnemonic}`

    const dialogResult = await confirmDialog.result;
    if (dialogResult === 1) {
      this.normalisedRuleSet.deletion = {
        comment: 'Deleted via storm UI',
        timestamp: new Date().toISOString(),
        user: this.dialogData.username
      }
      const selectedConfigKeyElements = this._flattenSelectedTreeViewItems(this.treeViewDataSource.data)
      Ruleset2021ViewEditSaveLogic.updateWithChangeService(this.normalisedRuleSet, selectedConfigKeyElements, this._changeService)
      await this._workspaceAutoSaveService.saveWorkspace()
    }
    this.dialogRef.close(true)
  }

  public async actionRestoreRuleset() {
    const confirmDialog = this._modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })
    confirmDialog.componentInstance.title = 'Restore RuleSet'
    confirmDialog.componentInstance.message = `Are you sure you wish to restore RuleSet ${this.dialogData.normalisedRuleSet.mnemonic}`

    const dialogResult = await confirmDialog.result;
    if (dialogResult === 1) {
      this.dialogRef.close(true)
      this.normalisedRuleSet.deletion = undefined
      const selectedConfigKeyElements = this._flattenSelectedTreeViewItems(this.treeViewDataSource.data)
      Ruleset2021ViewEditSaveLogic.updateWithChangeService(this.normalisedRuleSet, selectedConfigKeyElements, this._changeService)
      await this._workspaceAutoSaveService.saveWorkspace()
    }
    this.dialogRef.close(true)
  }

  private _filterDeletedRulesFromNormalisedRuleset() {
    this._filteredNormalisedRulesetRulesSubject.next(this.normalisedRuleSet.rule.filter(rule => !rule.deletion))
    this._deletedNormalisedRulesetRulesSubject.next(this.normalisedRuleSet.rule.filter(rule => rule.deletion))
  }

  private _setChosenDocFormatType() {
    if (this.normalisedRuleSet.documentFormatType === undefined) {
      this.chosenDocFormatType = 'both'
    } else {
      this.chosenDocFormatType = this.normalisedRuleSet.documentFormatType
    }
  }

  private _validateNormalisedRulesetDocFormatType() {
    if (this.chosenDocFormatType === 'both') {
      this.normalisedRuleSet.documentFormatType = undefined
      return
    }
    this.normalisedRuleSet.documentFormatType = this.chosenDocFormatType as any
  }

  public validateTabs() {
    this.isDirty = true
    let isReadyToSave = this.isDirty
    isReadyToSave = isReadyToSave && this.normalisedRuleSet?.name.length > 0 && this.normalisedRuleSet.description?.length > 0

    if (this.addingNewRule) {

      const allOperatorsPopulated = Object.values(this.newOperators).length !== this.newOperators.length;
      const newArray = []
      this.newOperators.forEach((operator, i) => {
        if (operator['id'] === 'ignored' || operator['id'] === 'present' || operator['id'] === 'isempty') {
          this.newFieldValues[i] = ''
          newArray.push(operator['id'])
        } else {
          let obj = operator['id'] + ' ' + this.newFieldValues[i]
         newArray.push(obj)
        }

      })
      const allRulesPopulated = newArray.every(x => {
        let y = x.split(' ')
        if (y.length >= 2) {
          let expression = x.substr(x.indexOf(' ') + 1);
          return expression.length > 0
        } else {
          return true
        }
       })
      this.combinedRules = newArray;
      isReadyToSave = isReadyToSave && !allOperatorsPopulated && allRulesPopulated
    }

    this.isReadyToSave = isReadyToSave
  }
  // End Primary User Actions

   async getRecomendedTest(curAnalyseRow: string, normalisedRuletest?: string) {
    const fullMenomic = `${this.normalisedRuleSet.stage.substring(0,1)}${this.normalisedRuleSet.ruleType.substring(0,1)}-${this.normalisedRuleSet.mnemonic}`;
    this.normalisedRuleSet.rule.map(rule => {
      if (curAnalyseRow === rule.mnemonic) {

        if (!this.ruleDefinition.some(ruleDef => ruleDef.ruleMnemonic === curAnalyseRow)) {
          const ruleObj = {
            currentFullMnemonic: rule['currentFullMnemonic'] || `${fullMenomic.toUpperCase()}-${rule.mnemonic}`,
            ruleMnemonic: rule.mnemonic,
            recomendedTest: rule.test || '',
            assign: false,
          }
          this.ruleDefinition.push(ruleObj)
        }
      }
    })

    const rule = this.ruleDefinition.filter(rule => {
      if (rule.ruleMnemonic === curAnalyseRow) {
        rule.recomendedTest = normalisedRuletest
        return rule
      }
    })

    this.ruleSetDialogRef = await this.dialog.open(TestSuiteRecomendationsComponent, {
      width: '40%',
      height: '40%',
      data: {
        ruleData: rule
      },

    })
    this.ruleSetDialogRef.componentInstance.submitClicked.subscribe(res => {
      this.normalisedRuleSet.rule.map(rule => {
        if (rule.mnemonic === curAnalyseRow) {
          rule.test = res
        }
      })
    })
    this.isReadyToSave = true;
  }

  trimLeadingAndTrailingSpaces() {
    if (this.newFieldValues) {
      this.newFieldValues = this.newFieldValues.map(expected => expected.trim());
    }
  }

  toggleApprovalRequiredRegEx() {
    this.normalisedRuleSet.approvalRequired = !this.normalisedRuleSet.approvalRequired;
    this.validateTabs()

  }

  private _getListOfWhiteListRules() {
    const selectedConfigKeyElements = this._flattenSelectedTreeViewItems(this.treeViewDataSource.data)
    this.dialogData.complex.forEach(rule => {
      const ruledata = {
        approvalRequiredRegEx: rule['approvalRequiredRegEx'],
        description: rule['description'],
        entries: rule['entries'],
        mnemonic: rule['mnemonic'],
        name: rule['name'],
        rule: rule['rule'],
        ruleType: rule['ruleType'],
        visible: true
      }
      this.listOfWhiteListRules.push(ruledata)
    })
    this.setIsSelected();
    if (selectedConfigKeyElements.length) {
      this.applyUserFilterToWhiteListRule(selectedConfigKeyElements)
    }
  }

  async actionAddNewRuleCombination() {
   await this.checkIfRuleComboExists(this.addNewRuleCombination)
   if (!this.isDuplicateCombination) {
    let filterNewMnemonic = await this.getComboMnemonic(this.addNewRuleCombination)
     const newCombo = await this.removeMnemonicFromNewCombo(this.addNewRuleCombination)
     const fullCombination = [filterNewMnemonic.toString(), ...newCombo]

    this.displayRuleCombination =  [...this.displayRuleCombination, fullCombination]
   } else {
     await this.displayWarningDialog(this.addNewRuleCombination)
   }
    if (this.displayRuleCombination.length === 1) {
      this.filterWhiteListRules(this.displayRuleCombination, true)
    }
    await this.clearAllOptions()
    await this.validateTabs()
  }

  isSelectedRule(mnemoic?: string, ruleMnemoic?: string) {
    this.isInputUpdate = false
    const fullRuleSetMnemonic = `${mnemoic}-${ruleMnemoic}`
    if (!this.updateRuleCombination) {
      this.addedNewRuleCombination = true;
      const generateRuleMnemonic = this.displayRuleCombination[this.displayRuleCombination?.length - 1]
      const findMnemonic = generateRuleMnemonic?.filter(m => {
        if (m.includes(this.normalisedRuleSet.mnemonic)) {
          return m
        }
      })
      this.nextComplexRuleMnemonicInSequence = ((+findMnemonic?.map(x => x.slice(x.lastIndexOf('-'))
        .replace('-', '')).sort().reverse()[0] + 1) + '').padStart(4, '0')
      if (this.nextComplexRuleMnemonicInSequence === '0NaN') {
        this.nextComplexRuleMnemonicInSequence = '0000'
      }
    }
    const combinationMnemonic = `${this.normalisedRuleSet.mnemonic}-${this.nextComplexRuleMnemonicInSequence}`
    this.listOfWhiteListRules.map((mnemonic) => {
      if (mnemonic.mnemonic === mnemoic) {
        mnemonic.rule.map(rule => {
          if (rule.mnemonic === ruleMnemoic) {
            rule.isSelected = !rule.isSelected
            if (!rule.isSelected) {
              if (this.combinations.includes(fullRuleSetMnemonic)) {
                this.combinations = this.combinations.filter(e => e !== fullRuleSetMnemonic && e !== combinationMnemonic);
                this.addNewRuleCombination = [...this.combinations]
              }
            }
            else {
            this.actionToggleRuleSetMnemonic(mnemoic, ruleMnemoic)
              if (!this.combinations.includes(fullRuleSetMnemonic)) {
                if(!this.combinations.includes(combinationMnemonic) && !this.updateRuleCombination){
                this.combinations.push(combinationMnemonic)
                }
                this.combinations.push(fullRuleSetMnemonic)
                this.addNewRuleCombination = [...this.combinations]
              }
            }
          }

        })
      }
    })
    this.validateNewRuleCombination()
  }

  async setIsSelected() {
    this.listOfWhiteListRules.forEach(ruleset => {
      ruleset.rule.map(rule => {
        rule.isSelected = false
      })
    })
    this.addedNewRuleCombination = false
    this.updateRuleCombination = false
    this.isDuplicateCombination = false
  }

  async saveCombinations() {
    this.displayRuleCombination = [...this.displayRuleCombination]
    let rulesetsOfLinkedRules = ''
    if (this.displayRuleCombination.length) {
      const filterComboMnemonics = await this.filterDisplayRuleMnemonic()
      filterComboMnemonics.map(ruleset => ruleset.map(rule => {
        const fields = rule.toString().split('-')
        const ruleSetMnemonic = fields[0] + ','
        rulesetsOfLinkedRules += ruleSetMnemonic
        return rulesetsOfLinkedRules;
      }))
      rulesetsOfLinkedRules = this.removeDuplicateRules(rulesetsOfLinkedRules.split(','))
    }
    const d = await this.getModifiedData(rulesetsOfLinkedRules)
    this.listOfWhiteListRules.length = 0;
    this.listOfWhiteListRules = [...this.listOfWhiteListRules]
  }

  removeDuplicateRules(rulesetOfLinkedRules) {
    rulesetOfLinkedRules = rulesetOfLinkedRules.filter(function (item, i, allItems) {
      return i == allItems.indexOf(item);
    }).join(',').slice(0, -1)
    return rulesetOfLinkedRules
  }

  actionCombinationInputValue($event) {
    this.updateRuleCombination = false
    this.addedNewRuleCombination = false
    this.updateInputRuleCombination = true
    this.isInputUpdate = true;
    this.clearAllOptions()
    this.validateTabs()
    const selectedRules = $event.target.value.split(',')
    this.inputUpdateRuleCombintion = [...selectedRules]
    if(this.displayRuleCombination.length > 1) {
      this.isInputUpdate =  this.inputUpdateRuleCombintion.length === this.displayRuleCombination[0].length ? true : false
    }
  }

  async actionSaveUpdateCombinationInputValue(comb?) {
    await this.checkIfRuleComboExists(this.inputUpdateRuleCombintion)
    const updatedMnemonic = await this.getComboMnemonic(this.updatedCombination)
    const filterMnemonicFromInputValue = await this.removeMnemonicFromNewCombo(this.inputUpdateRuleCombintion)
    const sortCombination = !this.isDuplicateCombination ? await this.sortCombo(filterMnemonicFromInputValue) : await this.sortCombo(this.addNewRuleCombination)
    this.displayRuleCombination = this.displayRuleCombination.map(value => {
      if (value.every((val, index) => val === this.updatedCombination[index])) {
        this.updateRuleCombination = false;
        this.addedNewRuleCombination = false
        if (!this.isDuplicateCombination) {
          return value = [ updatedMnemonic.toString(), ...sortCombination]
        } else {
            this.displayWarningDialog(this.inputUpdateRuleCombintion)
          return value = [...this.updatedCombination]
        }
      }
      return value = [...value];
    })

    this.clearAllOptions()
    this.validateTabs()
    this.isInputUpdate = false
    if(this.displayRuleCombination.length === 1) {
    this.filterWhiteListRules(this.displayRuleCombination, true)
    }
  }

  actionRulecombinationSelected($selectedCombination) {
    this.addedNewRuleCombination = false
    this.clearAllOptions()
    this.validateTabs()
    const selectedRules = $selectedCombination.target.value.split(',')
    this.updatedCombination = [...selectedRules]
    this.checkWhiteListRulesCombination(this.updatedCombination)
    this.updateRuleCombination = true;
    // this.addedNewRuleCombination = false
  }

  async checkWhiteListRulesCombination(updatedCombination) {
    const filterruleMnemoic = await this.filterComboMnemonic(updatedCombination)
    filterruleMnemoic.map(rulValue => {
      const fields = rulValue.split('-');
      const rulesetmnemoic = fields[0]
      const ruleMnemoic = fields[1]

      this.listOfWhiteListRules.find((mnemonic) => {
        if (mnemonic.mnemonic === rulesetmnemoic) {
          mnemonic.rule.find(rule => {
            if (rule.mnemonic === ruleMnemoic) {
              rule.isSelected = true
              this.combinations.push(rulValue);
            }
          })
        }
      })
    })
  }

  async saveToCombinationArray() {
    let arr = [];
    if (this.normalisedRuleSet.linkedRules?.combinations) {
      this.normalisedRuleSet.linkedRules?.combinations.forEach(data => {
        data.combos = data.combos.sort((a, b) =>
        a.localeCompare(b));
        const newCombination = [data?.mnemonic, ...data?.combos];
        arr.push(newCombination)
      })
    }
  this.displayRuleCombination = [...arr]
  }

  async clearAllOptions() {
    await this.setIsSelected();
    if (this.combinations.length) {
      this.combinations.splice(0);
    }
    this.displayRuleCombination = [...this.displayRuleCombination]
  }

  async actionUpdateSelectedRulesCombination() {
   const updatedMnemonic = await this.getComboMnemonic(this.updatedCombination)
   await this.checkIfRuleComboExists(this.addNewRuleCombination)
   if (!this.isDuplicateCombination) {
     const sortCombination = await this.sortCombo(this.addNewRuleCombination)
    this.displayRuleCombination = this.displayRuleCombination.map(value => {
      if (value.length === this.updatedCombination.length && value.every((val, index) => val === this.updatedCombination[index])) {
        this.updateRuleCombination = false;
        this.addedNewRuleCombination = false
        return value = this.addNewRuleCombination.length ? [ updatedMnemonic.toString(), ...sortCombination] : value
      }
      return value;
    })
   } else {
      this.displayWarningDialog(this.addNewRuleCombination)
    }
    this.clearAllOptions()
    this.validateTabs()
    this.filterWhiteListRules(this.displayRuleCombination, true)
  }

  deleteSelectedRulesCombination(combination) {
    const index = this.displayRuleCombination.indexOf(combination);
    if (index > -1) {
      this.displayRuleCombination.splice(index, 1);
    }
    this.clearAllOptions();
    this.validateTabs()
    this.combinationSelected = false
    if(!this.displayRuleCombination.length) {
      this.listOfWhiteListRules.length = 0;
      this._getListOfWhiteListRules()
    }
  }

  setFilter(filterValue: string) {
    this.filterValue = filterValue || ''
    this.applyFilter()
  }

  private applyFilter() {
    const terms = this.filterValue.toLowerCase().split(' ')
    this.listOfWhiteListRules.forEach(ruleSet => {
      const ts = ruleSet.mnemonic.toLowerCase()
      ruleSet.visible = terms.every(term => ts.includes(term))
    })
  }

  applyUserFilterToWhiteListRule(selectedConfigKeyElements) {
    const filteredRuleset = []

    this.listOfWhiteListRules = this.listOfWhiteListRules.filter(rule => {
      const allConfigKeys = rule['entries'];
      allConfigKeys.find(configKey => {
        if (selectedConfigKeyElements.includes(configKey)) {
          return !filteredRuleset.includes(rule) ? filteredRuleset.push(rule) : filteredRuleset

        }
      })
    });

    this.listOfWhiteListRules = [...filteredRuleset]
    if (this.listOfWhiteListRules.length) {
      this.clearAllOptions()
    }
  }

  filterWhiteListRules(selectRulesetFromFirstCombination, isDisplayRule?) {
    let collectionOfRulesetMnemonic = []
    if (selectRulesetFromFirstCombination?.length) {
      if(!isDisplayRule) {
      selectRulesetFromFirstCombination?.map(ruleset => ruleset?.combos?.map(rule => {
        const fields = rule.toString().split('-')
        const ruleSetMnemonic = fields[0]
        collectionOfRulesetMnemonic.push(ruleSetMnemonic);
      }))
      } else if (isDisplayRule) {
        selectRulesetFromFirstCombination.map(ruleset => ruleset.map(rule => {
          const fields = rule.toString().split('-')
          const ruleSetMnemonic = fields[0]
          collectionOfRulesetMnemonic.push(ruleSetMnemonic);
        }))
      }

      this.listOfWhiteListRules = this.listOfWhiteListRules.filter(rulesetMnemonic => {
        if (collectionOfRulesetMnemonic.includes(rulesetMnemonic.mnemonic)) {
          return rulesetMnemonic
        }
      })
    }
  }

  actionToggleRuleSetMnemonic(mnemoic?: string, ruleMnemoic?: string) {
    this.listOfWhiteListRules.map((mnemonic) => {
      if (mnemonic.mnemonic === mnemoic) {
        mnemonic.rule.forEach(rule => {
          if (rule.mnemonic !== ruleMnemoic) {
            rule.isSelected = false
            const unselectedRuleMnemonic = `${mnemoic}-${rule.mnemonic}`
            if (this.combinations.includes(unselectedRuleMnemonic)) {
              const findIndex = this.combinations.findIndex(fullRule => fullRule === unselectedRuleMnemonic);
              this.combinations.splice(findIndex, 1)
            }
          }
        })
      }
    })
  }

  validateNewRuleCombination() {
    if (this.displayRuleCombination.length >= 1) {
      if (this.addedNewRuleCombination) {
        if (this.combinations.length < this.displayRuleCombination[0].length) {
          this.addedNewRuleCombination = false
        }
      }
      else if (this.combinations.length === this.displayRuleCombination[0].length - 1) {
        this.updateRuleCombination = true
      }
    }
  }

  async checkIfRuleComboExists(ruleCombination) {
    let filterNewMnemonic = await this.filterComboMnemonic(ruleCombination)
    ruleCombination = ruleCombination.sort((a, b) =>
      a.localeCompare(b)
    );
    filterNewMnemonic = filterNewMnemonic.sort((a, b) =>
      a.localeCompare(b)
    );
    const filterRuleMnemonic = await this.filterDisplayRuleMnemonic()
      filterRuleMnemonic.map(combo => {
      combo = combo.sort((a, b) =>
        a.localeCompare(b)
      );
      if (!this.isDuplicateCombination) {
        if (combo.toString() === filterNewMnemonic.toString()) {
          this.isDuplicateCombination = true
        }
      }
    })
  }

  async displayWarningDialog(combo) {
    const filterMnemonic = await this.removeMnemonicFromNewCombo(combo)
    await this.dialog.open(AlertDialogComponent, {
      width: '40%',
      height: '25%',
      data: {
        title: "Error: Duplicate Rule Combination",
        message: `${filterMnemonic} already exists!`
      },
    }).afterClosed().toPromise()
  }

  async filterComboMnemonic(ruleCombination) {
    return ruleCombination.filter(m => {
      if (!m.includes(this.normalisedRuleSet.mnemonic)) {
        return m
      }
    })
  }
  async getComboMnemonic(ruleCombination) {
    return ruleCombination.filter(m => {
      if (m.includes(this.normalisedRuleSet.mnemonic)) {
        return m
      }
    })
  }

  async removeMnemonicFromNewCombo(ruleCombination) {
    return ruleCombination.filter(m => {
      if (!m.includes(this.normalisedRuleSet.mnemonic)) {
        return m
      }
    })
  }
  async filterDisplayRuleMnemonic() {
    return this.displayRuleCombination.map(c => c.filter(v => {
      if (!v.includes(this.normalisedRuleSet.mnemonic)) {
        return v
      }
    }))

  }
  async getModifiedData(rulesetsOfLinkedRules) {
    let arr: Combination[] = [];
    if (this.displayRuleCombination.length) {
      this.displayRuleCombination.forEach(data => {
        const c = data.filter(v => !v.includes(this.normalisedRuleSet.mnemonic))
        let  m = data.find(v =>  v?.match(this.normalisedRuleSet.mnemonic))
        arr.push({
          mnemonic: m
          , combos: c
        })
      })
      this.normalisedRuleSet.linkedRules = {
        rulesets: rulesetsOfLinkedRules,
        combinations: arr,
      }
    }
  }

  async sortCombo(combination) {
    combination = combination.sort((a, b) =>
      a.localeCompare(b)
    );
    return combination
  }
}
