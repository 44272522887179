<h2>Post Payload To Regxchange</h2>

<div class="form-group">
  <label for="Schema">Regxchange Schema: </label>
  <input id="Schema" type="text" [(ngModel)]="this.regxchangeSchema" />
</div>

<div class="form-group">
  <span>
    <mat-form-field class="rule-type-input">
      <mat-label>Regxchange type</mat-label>
      <mat-select (selectionChange)="selectRegxApi($event)" [value]="selectedRegxchangeApiOption">
        <mat-option *ngFor="let opt of regxchangeApiOptions" [value]="opt" [title]="opt.description">{{opt.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>
</div>

<div class="form-group">
  <span>
    <mat-form-field class="rule-type-input">
      <mat-label>Payload format</mat-label>
      <mat-select (selectionChange)="selectMimeType($event)" [value]="selectedMimeTypeOption">
        <mat-option *ngFor="let opt of mimeTypeOptions" [value]="opt" [title]="opt.description">{{opt.display}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>
</div>

<div class="container-fluid h-75">
  <ul ngbNav #publishNav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>Payload</a>
      <ng-template ngbNavContent>
        <div class="payload-input">
          <ngx-monaco-editor
            class="monaco-editor payload-editor"
            [options]="payloadEditorConfig"
            [(ngModel)]="payloadValueBody"
            (ngModelChange)="refresh()">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="responseBody !== undefined">
      <a ngbNavLink>Response</a>
      <ng-template ngbNavContent>
        <div class="payload-input">
          <ngx-monaco-editor
            class="monaco-editor payload-editor"
            [options]="responseConfig"
            [(ngModel)]="this.responseBody"
            (ngModelChange)="refresh()">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="publishNav" class="tab-content"></div>
</div>

<ng-container *ngIf="validPayload" class="actions">
  <button type="button" class="btn btn-info mt-2 " (click)="saveToRegxchange()">Save To Regxchange</button>
</ng-container>

