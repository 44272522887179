export const DEV_ACCOUNT_ID = 'DEV_ACCOUNT_ID_PLACEHOLDER'
export const STAGING_ACCOUNT_ID = 'STAGING_ACCOUNT_ID_PLACEHOLDER'
export const UAT_ACCOUNT_ID = 'UAT_ACCOUNT_ID_PLACEHOLDER'
export const PROD_ACCOUNT_ID = 'PROD_ACCOUNT_ID_PLACEHOLDER'
export const OPS_URL = 'OPS_URL_PLACEHOLDER'
export const PROJECT_NAME = 'PROJECT_NAME_PLACEHOLDER'
export const MENU_ITEM_EXCLUSIONS = 'MENU_ITEM_EXCLUSIONS_PLACEHOLDER'
export const ENVIRONMENT = 'ENVIRONMENT_PLACEHOLDER'

export const valueReplaced = (constant: string) => {
  return constant.toLowerCase().indexOf('placeholder') === -1
}
