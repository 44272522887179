import {Component, HostListener, OnDestroy, OnInit} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import deepEqual from 'deep-equal'
import lodash from 'lodash'
import {ToastrService} from 'ngx-toastr'
import {BehaviorSubject, Subscription} from 'rxjs'
import {debounceTime, distinctUntilChanged, map, take} from 'rxjs/operators'
import {RuleSetEditDialogComponent, RuleSetEditDialogComponentData} from 'src/app/dialogs/ruleset-edit-dialog/ruleset-edit-dialog.component'
import {SelectableConfigItem} from 'src/app/dialogs/selectable-config-item'
import {ProtectedRuleSet} from 'src/app/model/rationalised-rule'
import {v4 as uuid} from 'uuid'
import {AnalyseRow, AnalyseRuleTable, Deletion, RuleType} from '../../common/domain/analyse'
import {ChangeDetails, DocgenConfigAndMetadataChangeDetails} from '../../common/domain/change'
import {AnalyseConfig, CommonConfig, ConfigDetail} from '../../common/domain/config'
import {DocgenConfig, DocgenConfigAndMetadata} from '../../common/domain/docgen-config'
import {ShowRuleDeletions, ShowRuleDeletionsDialogComponent} from '../../dialogs/analysis-show-rule-deletions-dialog.component'
import {ConfirmDialogComponent} from '../../dialogs/confirm-dialog.component'
import {RuleErrorMessageDialogComponent} from '../../dialogs/rule-error-message-dialog/rule-error-message-dialog.component'
import {PushConfigRequest} from '../../model/config-requests'
import {GetConfigFileSuccessResponse, PublishConfigResponse} from '../../model/config-responses'
import {RuleExclusion} from '../../model/rule-exclusion'
import {ApiService} from '../../services/api.service'
import {ChangeService} from '../../services/change.service'
import {PermissionsService} from '../../services/permissions.service'
import {UserNameService} from '../../services/user-name.service'
import {WorkspaceAutoSaveService} from '../../services/workspace-auto-save.service'
import {DisplayableRuleRow, DisplayableRuleTable, RowUsage, Stage} from './logic/interfaces'
import {UserActionAddNewRule} from './logic/user-action-add-new-rule'
import {UserActionAddNewRuleset} from './logic/user-action-add-new-ruleset'
import {UserActionDeleteRule} from './logic/user-action-delete-rule'
import {UserActionDeleteRuleSet} from './logic/user-action-delete-ruleset'
import {UserActionDownloads} from './logic/user-action-downloads'

interface EnvEntry {
  id: string
  display: string
}

const ENVS: EnvEntry[] = [{id: 'tools', display: 'Tools'}, {id: 'dev', display: 'Dev'}, {id: 'uat', display: 'uat'}]

@Component({
  selector: 'app-config-rules',
  templateUrl: './config-rules.component.html',
  styleUrls: ['./config-rules.component.scss'],

})
export class NewConfigRulesComponent implements OnInit, OnDestroy {
  username: string
  subscriptions = new Subscription()
  allConfigs: ChangeDetails[]
  selectedConfigPath: string
  displayConfigKey: string
  /** All rules from all configurations. An automated test in lambda-ingent-product should ensure no duplicates */
  allRules: DisplayableRuleTable[]
  /** Reduced collection of unique rules.  Some genuinely unique rules have a non-unique name but will differ in their fields */
  uniqueRules: DisplayableRuleTable[]
  /** All or sub-set of {@link uniqueRules} consistent with list display choice */
  displayedRules: DisplayableRuleTable[]
  openRuleUuid: string
  canAddRule = false
  canDeleteRule = false
  canIgnoreRule = false
  showDeletions = false
  showDeleteActions = false

  /** Controls which rules are displayed */
  viewControl: BehaviorSubject<{
    isProductStageSelected: boolean
    isSoloStageSelected: boolean
    isNotSupportedListSelected: boolean
    isValidationListSelected: boolean
    isBlacklistSelected: boolean
    isWhitelistSelected: boolean
  }> = new BehaviorSubject<{
    isProductStageSelected: boolean,
    isSoloStageSelected: boolean,
    isNotSupportedListSelected: boolean,
    isValidationListSelected: boolean,
    isBlacklistSelected: boolean,
    isWhitelistSelected: boolean
  }>({
    isProductStageSelected: true,
    isSoloStageSelected: true,
    isNotSupportedListSelected: true,
    isValidationListSelected: true,
    isBlacklistSelected: true,
    isWhitelistSelected: true,
  })

  productChipSelected: boolean
  soloChipSelected: boolean
  notSupportedChipSelected: boolean
  blacklistChipSelected: boolean
  validationChipSelected: boolean
  whitelistChipSelected: boolean

  public protectedRuleSets: Array<ProtectedRuleSet>
  ruleExclusion: RuleExclusion = {}

  // Context Menu related
  public showOldRuleSetContextMenu = false;
  public currentlySelectedRuleSet: DisplayableRuleTable
  private mouseLocation: {left: number, top: number} = {left: 0, top: 0};

  @HostListener('document:click')
  @HostListener('document:rightclick')
  @HostListener('document:scroll')
  onClick() {
    this.showOldRuleSetContextMenu = false
  }

  constructor(
    private api: ApiService,
    private dialog: MatDialog,
    private changeService: ChangeService,
    private workspaceAutoSaveService: WorkspaceAutoSaveService,
    private toast: ToastrService,
    private permissionsService: PermissionsService,
    private userNameService: UserNameService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    this.allRules = []
    this.uniqueRules = []
    this.displayedRules = []
    this.initialiseRulesets()

    // Get (lamda-ingest-product)/config/protected-rulesets
    this.changeService.getObjectsOfType('protected-rulesets')
      .subscribe((changes: ChangeDetails[]) => {
        // console.info(JSON.stringify(changes))
        this.protectedRuleSets = changes.reduce((result: ProtectedRuleSet[], curChange) => {
          // If data exists then deserialise it
          if (curChange.content && curChange.content.length > 0) {
            const parsedJson = JSON.parse(curChange.content)
            // Convert json to a class
            const newRationalisedRule = new ProtectedRuleSet()
            Object.assign(newRationalisedRule, parsedJson)
            result.push(newRationalisedRule)
          }
          return result
        }, new Array<ProtectedRuleSet>())
      })

    this.userNameService.userName.subscribe((username: string) => {
      this.username = username
    })

    this.subscriptions.add(this.permissionsService.deleteRule.subscribe(permission => this.canDeleteRule = permission))
    this.subscriptions.add(this.permissionsService.canApproveRule.subscribe(permission => this.canIgnoreRule = permission))
    this.subscriptions.add(
      this.api.get<GetConfigFileSuccessResponse>(`/config/latest/lambda-approvals/config/ignored-mnemonics/ignored-mnemonics.json`)
        .subscribe((configResponse: GetConfigFileSuccessResponse) => {
          this.ruleExclusion = {}
          // @ts-ignore
          if (configResponse.code === 'FileDoesNotExistException') {
            configResponse.data = JSON.stringify({ignoredMnemonics: []})
          }
          ENVS.forEach(env => {
            (JSON.parse(configResponse.data) as RuleExclusion)[env.id]?.ignoredMnemonics.forEach(rule => {
              const ruleExclusion = this.ruleExclusion[env.id]?.ignoredMnemonics.find(r => r.mnemonic === rule.mnemonic)
              if (!ruleExclusion) {
                const newRuleExclusion = Object.assign({}, rule, {excludedIn: [env.id]})
                if (!this.ruleExclusion[env.id]) {
                  this.ruleExclusion[env.id] = {ignoredMnemonics: []}
                }
                this.ruleExclusion[env.id].ignoredMnemonics.push(newRuleExclusion)
              } else {
                ruleExclusion.excludedIn.push(env.display)
              }
            })
          })
        }),
    )
    this.subscriptions.add(this.viewControl.subscribe(value => this.updateDisplay(value)))
    this.subscriptions.add(this.changeService.selectedDocgenConfig.subscribe((config: DocgenConfigAndMetadata) => {
      if (config) {
        const metadata = config.metadata
        this.displayConfigKey = `${metadata.sourceSystem} : ${metadata.programme} : ${metadata.productType} : ${metadata.documentType}`
        this.selectedConfigPath = `settings/${metadata.sourceSystem}/${metadata.programme}/${metadata.productType}/${metadata.documentType}/Config.json`
      }
    }),
    )
    this.subscriptions.add(this.changeService.getUserFilteredDocgenConfigs()
      .pipe(
        debounceTime(250),
        distinctUntilChanged(deepEqual),
      )
      .subscribe((allConfigs: DocgenConfigAndMetadataChangeDetails[]) => {
        if (allConfigs) {

          // Here seems as good a place as anywhere to save any existing workspace...
          this.workspaceAutoSaveService.saveWorkspace()

          this.allConfigs = allConfigs.map(c => c.settings)

          // Get every occurrence of every rule from every config
          this.allRules = allConfigs.reduce((acc: DisplayableRuleTable[], current: DocgenConfigAndMetadataChangeDetails) => {
            const config = JSON.parse(current.settings.content) as DocgenConfig
            const configKey = current.settings.path.replace(/^config\//, '').replace(/^settings\//, '').replace(/\/Config.json$/, '')
            const currentRules = this.readAllRules(config, configKey)

            if (current.state === 'modified') {
              const original = this.changeService.getOriginal(current.settings.path)
              const originalConfig = JSON.parse(original.content) as DocgenConfig
              const originalRules = this.readAllRules(originalConfig, configKey)
              this.identifyChangedRules(originalRules, currentRules, configKey)
            }

            acc = acc.concat(...currentRules)

            return acc
          }, [])
        }

        // Remove all the duplicates, keeping important decorations
        this.uniqueRules = this.allRules
          .reduce((acc: DisplayableRuleTable[], current: DisplayableRuleTable) => {
            const ruleInDisplay = acc.find((displayRule: DisplayableRuleTable) => this.rulesUsedInSameStageAndList(displayRule, current) && this.rulesHaveSameStructure(displayRule, current))

            if (!ruleInDisplay) {
              // Display not currently showing any rule with this structure
              acc.push(current)

            } else {
              // Combine with the existing, similar rule on display
              if (current.modified === true) {
                ruleInDisplay.modified = true
              }
              if (current.new === true) {
                ruleInDisplay.new = true
              }
              const currentConfigKey = current.configKeys[0]
              if (ruleInDisplay.configKeys.indexOf(currentConfigKey) < 0) {
                ruleInDisplay.configKeys.push(currentConfigKey)
                ruleInDisplay.usedBy[currentConfigKey] = current.usedBy[currentConfigKey]
              }
              current.rule.forEach((rowMaybeIn: DisplayableRuleRow) => {
                const rowInDisplay = ruleInDisplay.rule.find((rowAlreadyIn: DisplayableRuleRow) => this.rowsMatch(rowMaybeIn, rowAlreadyIn))
                if (!rowInDisplay) {
                  ruleInDisplay.rule.push(rowMaybeIn)
                } else {
                  const rowCurrentConfigKey = rowMaybeIn.configKeys[0]
                  rowInDisplay.configKeys.push(rowCurrentConfigKey)
                  rowInDisplay.usedBy[rowCurrentConfigKey] = rowMaybeIn.usedBy[rowCurrentConfigKey]
                }
              })
            }
            return acc
          }, [])
          .sort((a, b) => this.sortByName(a, b))

        {
          const mapOfRationalisedRules: Map<string, ProtectedRuleSet> = new Map<string, ProtectedRuleSet>()
          this.protectedRuleSets.forEach((curRationalisedRule) => {
            mapOfRationalisedRules.set(curRationalisedRule.mnemonic, curRationalisedRule)
          })

          // Look for Rationalized Rules
          this.uniqueRules.forEach((curUniqueRule: DisplayableRuleTable) => {
            if (mapOfRationalisedRules.has(curUniqueRule.mnemonic)) {
              // console.log(curUniqueRule.mnemonic + ' is a protected RuleSet')
              const curRationalisedRule = mapOfRationalisedRules.get(curUniqueRule.mnemonic)
              curUniqueRule.isProtectedRuleSet = true
              curUniqueRule.protectedRule = curRationalisedRule
            } else {
              console.warn(curUniqueRule.mnemonic + ' is *not* a Protected RuleSet')
            }
          })
        }

        // Sort the rows within each rule in mnemonic order
        this.uniqueRules.forEach((rule: DisplayableRuleTable) => rule.rule.sort((a, b) => (a.mnemonic > b.mnemonic) ? 1 : (a.mnemonic < b.mnemonic) ? -1 : 0))

        // Detect incongruities
        // this.uniqueRules.forEach((rule: DisplayableRuleTable) => {
        //   rule.rule.forEach((row: DisplayableRuleRow) => {

        //     // duplicated row mnemonics
        //     if (rule.rule.filter((r: DisplayableRuleRow) => r.mnemonic === row.mnemonic).length > 1) {
        //       row.status.isDuplicateMnemonic = true
        //       rule.hasDuplicateMnemonics = true
        //     }

        //     // rows that duplicate the expected values (exclude rows with no active usages from the calculation)
        //     rule.rule.forEach((r: DisplayableRuleRow) => {
        //       if (r.expected.length === row.expected.length
        //         && r.expected.every((expected: string, index: number) => expected === row.expected[index])
        //         && r.uuid !== row.uuid
        //         && !this.ruleHasNoActiveUsages(r)) {

        //         if (!this.ruleHasNoActiveUsages(row)) {
        //           row.status.isDuplicatedData = true
        //           if (!row.status.duplicateDataIn) {
        //             row.status.duplicateDataIn = [r.uuid]
        //           } else {
        //             row.status.duplicateDataIn.push(r.uuid)
        //           }
        //           rule.hasDuplicateData = true
        //         }
        //       }
        //     })
        //   })
        // })

        // Initialise the displayed rules
        this.updateDisplay(this.viewControl.getValue())
      }),
    )
    this.permissionsService.addRules.subscribe(permission => this.canAddRule = permission)
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  private initialiseRulesets(): void {
  }

  public getConfigKeyFromPath(path: string): string {
    const match = path.match(/^settings\/(.+)\/(.+)\/(.+)\/(.+)\/Config.json$/)
    return `${match[1]}/${match[2]}/${match[3]}/${match[4]}`
  }

  private readAllRules(config: DocgenConfig, configKey: string): DisplayableRuleTable[] {
    return []
      .concat(...config.analysePayload.rules.map((ruleTable: AnalyseRuleTable) => this.toDisplayable(ruleTable, configKey, 'product', ruleTable.ruleType)))
      .concat(...config.analyseEnriched.rules.map((ruleTable: AnalyseRuleTable) => this.toDisplayable(ruleTable, configKey, 'solo', ruleTable.ruleType)))
      .sort((a, b) => this.sortByName(a, b))
  }

  /** Identify the changes for the rules in a single Config.json */
  private identifyChangedRules(originals: DisplayableRuleTable[], current: DisplayableRuleTable[], configKey: string): void {
    current.forEach((currentRule: DisplayableRuleTable) => {
      const originalRule = originals.find((o: DisplayableRuleTable) => this.rulesHaveSameStructure(o, currentRule))
      if (!originalRule) {
        currentRule.new = true
      } else if (this.hasRulesetMovedList(currentRule, originalRule, configKey)) {
        currentRule.modified = true
      } else if (this.areRowsChanged(originalRule.rule, currentRule.rule)) {
        currentRule.modified = true
      }
      // todo check each row too? - is it new, or modified?
    },
    )
  }

  private hasRulesetMovedList(current: DisplayableRuleTable, original: DisplayableRuleTable, configKey: string): boolean {
    return current.usedBy[configKey].ruleType !== original.usedBy[configKey].ruleType
  }

  private areRowsChanged(a: DisplayableRuleRow[], b: DisplayableRuleRow[]): boolean {
    if (a.length !== b.length) {
      return true
    }
    const rowChecks: boolean[] = a.map((aRow: DisplayableRuleRow, index: number) => {

      if ((aRow.mnemonic !== b[index].mnemonic)
        || (aRow.description && aRow.description !== b[index].description)
        || (aRow.test && aRow.test !== b[index].test)) {
        return true
      }

      const valueChecks: boolean[] = aRow.expected.map((value: string, ix: number) => value !== b[index].expected[ix])
      if (valueChecks.find((value: boolean) => value === true)) {
        return true
      }

      return false
    })

    return rowChecks.find((value: boolean) => value === true)
  }

  private rulesHaveSameStructure(a: DisplayableRuleTable, b: DisplayableRuleTable): boolean {
    return a.name === b.name
      && a.mnemonic === b.mnemonic
      && a.fields.length === b.fields.length
      && a.fields.every((field, index) => field === b.fields[index])
  }

  private rulesUsedInSameStageAndList(a: DisplayableRuleTable, b: DisplayableRuleTable): boolean {
    const aStagesAndLists = Object.values(a.usedBy).map(value => {
      return {
        stage: value.stage,
        list: value.ruleType,
      }
    })
    const bStagesAndLists = Object.values(b.usedBy).map(value => {
      return {
        stage: value.stage,
        list: value.ruleType,
      }
    })
    const intersection = lodash.intersectionWith(aStagesAndLists, bStagesAndLists, lodash.isEqual)
    return intersection.length > 0
  }

  /** Rows match if they have the same mnemonic and the same expected values */
  private rowsMatch(a: DisplayableRuleRow, b: DisplayableRuleRow): boolean {
    return a.mnemonic === b.mnemonic
      && a.expected.length === b.expected.length
      && a.expected.every((value: string, index: number) => value === b.expected[index])
  }

  private toDisplayable(ruleTable: AnalyseRuleTable, configKey: string, paramStage: Stage, list: RuleType): DisplayableRuleTable {

    const ruleDeletions: Deletion[] = ruleTable.rule.map((rule: AnalyseRow) => rule.deletion)

    const displayableRule = Object.assign({uuid: uuid()} as DisplayableRuleTable, ruleTable)
    displayableRule.configKeys = [configKey]
    displayableRule.usedBy = {}
    displayableRule.usedBy[configKey] = {
      stage: paramStage,
      ruleType: list,
      description: ruleTable.description,
      help: ruleTable.help,
      deletion: ruleTable.deletion,
    }
    displayableRule.rule.forEach((row: DisplayableRuleRow, index: number) => {
      row.uuid = uuid()
      row.configKeys = [configKey]
      row.usedBy = {}
      row.usedBy[configKey] = {
        stage: paramStage,
        ruleType: list,
        test: row.test,
        description: row.description,
        help: row.help,
        deletion: ruleDeletions[index],
      }
      row.status = {}
    })
    return displayableRule
  }

  private sortByName: (a: DisplayableRuleTable, b: DisplayableRuleTable) => (number) = (a: DisplayableRuleTable, b: DisplayableRuleTable) => {
    if (a.name > b.name) {
      return 1
    }
    if (a.name < b.name) {
      return -1
    }
    return 0
  }

  isRuleInConfig(rule: DisplayableRuleTable): boolean {
    return this.inConfig(rule)
  }

  displayRuleDeletion(row: DisplayableRuleRow): boolean {
    return this.showDeletions && this.ruleHasDeletion(row)
  }

  ruleHasDeletion(row: DisplayableRuleRow): boolean {
    return !!Object.values(row.usedBy).find(usedBy => usedBy.deletion !== undefined)
  }

  displayRulesetDeletion(ruleset: DisplayableRuleTable): boolean {
    return this.showDeletions && this.rulesetHasDeletion(ruleset)
  }

  rulesetHasDeletion(ruleset: DisplayableRuleTable): boolean {
    return !!Object.values(ruleset.usedBy).find(usedBy => usedBy.deletion !== undefined)
  }

  rulesetHasNoActiveUsages(ruleset: DisplayableRuleTable): boolean {
    return this.getActiveUsages(ruleset).length === 0
  }

  ruleHasNoActiveUsages(row: DisplayableRuleRow): boolean {
    return this.getActiveUsages(row).length === 0
  }

  public getActiveUsages(ruleOrRuleset: DisplayableRuleRow | DisplayableRuleTable): string[] {
    return Object.keys(ruleOrRuleset.usedBy)
      .map((configKey: string) => {
        if (ruleOrRuleset.usedBy[configKey].deletion) {
          return undefined
        } else {
          return configKey
        }
      })
      .filter(value => value)
  }

  getRuleDeletions(rule: DisplayableRuleRow): string {
    return this.getDeletions(rule)
  }

  getRulesetDeletions(ruleset: DisplayableRuleTable): string {
    return this.getDeletions(ruleset)
  }

  private getDeletions(ruleOrRuleset: DisplayableRuleRow | DisplayableRuleTable): string {
    return Object.keys(ruleOrRuleset.usedBy)
      .map((configKey: string) => {
        if (ruleOrRuleset.usedBy[configKey].deletion) {
          return configKey
        } else {
          return undefined
        }
      })
      .filter(value => value)
      .join('\n')
  }

  isRowInConfig(row: DisplayableRuleRow): boolean {
    return this.inConfig(row)
  }

  private inConfig(item: DisplayableRuleTable | DisplayableRuleRow): boolean {
    if (!this.selectedConfigPath) {
      return false
    }
    return !!item.configKeys.find((configKey: string) => this.selectedConfigPath.indexOf(configKey) > -1)
  }

  isRuleInStage(rule: DisplayableRuleTable, stage: Stage): boolean {
    return !!Object.values(rule.usedBy).find(usedBy => usedBy.stage === stage)
  }

  isRuleInList(rule: DisplayableRuleTable, list: RuleType): boolean {
    return !!Object.values(rule.usedBy).find(usedBy => usedBy.ruleType === list)
  }

  displayConfigKeysForRuleset(ruleset: DisplayableRuleTable): string {
    const activeRulesetUsages: string[] = this.getActiveUsages(ruleset)
    return activeRulesetUsages.length === 0 ? 'No Active Usage of this Ruleset' : activeRulesetUsages.join('\n')
  }

  displayConfigKeysForRow(row: DisplayableRuleRow): string {
    const activeRuleUsages: string[] = this.getActiveUsages(row)
    return activeRuleUsages.length === 0 ? 'No Active Usage of this Rule' : activeRuleUsages.join('\n')
  }

  isHighlighted(row: DisplayableRuleRow): boolean {
    return row.status.highlight
  }

  isCodeHighlighted(row: DisplayableRuleRow): boolean {
    return row.status.codeHighlight
  }

  openRule(rule: DisplayableRuleTable): void {
    this.openRuleUuid = rule.uuid
  }

  closeRule(rule: DisplayableRuleTable): void {
    if (this.openRuleUuid === rule.uuid) {
      this.openRuleUuid = undefined
    }
  }

  isOpened(rule: DisplayableRuleTable): boolean {
    return rule.uuid === this.openRuleUuid
  }

  toggleDeleteActions() {
    this.showDeleteActions = !this.showDeleteActions
  }

  toggleShowDeletions() {
    this.showDeletions = !this.showDeletions
  }

  toggleChip(chip: RuleType | Stage): void {
    const viewControl = this.viewControl.getValue()
    switch (chip) {
      case 'product': {
        viewControl.isProductStageSelected = !viewControl.isProductStageSelected
        break
      }
      case 'solo': {
        viewControl.isSoloStageSelected = !viewControl.isSoloStageSelected
        break
      }
      case 'whitelist': {
        viewControl.isWhitelistSelected = !viewControl.isWhitelistSelected
        break
      }
      case 'blacklist': {
        viewControl.isBlacklistSelected = !viewControl.isBlacklistSelected
        break
      }
      case 'validation': {
        viewControl.isValidationListSelected = !viewControl.isValidationListSelected
        break
      }
      case 'notSupported': {
        viewControl.isNotSupportedListSelected = !viewControl.isNotSupportedListSelected
        break
      }
      default: {
        throw new Error(`Unexpected value for list: ${chip}`)
      }
    }
    this.viewControl.next(viewControl)
  }

  private updateDisplay(viewControl: {
    isProductStageSelected: boolean, isSoloStageSelected: boolean, isNotSupportedListSelected: boolean; isValidationListSelected: boolean;
    isBlacklistSelected: boolean; isWhitelistSelected: boolean
  }): void {

    console.info('** method: updateDisplay')

    this.productChipSelected = viewControl.isProductStageSelected
    this.soloChipSelected = viewControl.isSoloStageSelected
    this.notSupportedChipSelected = viewControl.isNotSupportedListSelected
    this.blacklistChipSelected = viewControl.isBlacklistSelected
    this.validationChipSelected = viewControl.isValidationListSelected
    this.whitelistChipSelected = viewControl.isWhitelistSelected

    this.displayedRules = this.uniqueRules
      .map((rule: DisplayableRuleTable) => {
        const displayedRule = Object.assign({} as DisplayableRuleTable, rule)

        const usedBys = Object.values(displayedRule.usedBy)
        const usedByProductStage = usedBys.find(usedBy => usedBy.stage === 'product')
        const usedBySoloStage = usedBys.find(usedBy => usedBy.stage === 'solo')
        const usedByWhitelist = usedBys.find(usedBy => usedBy.ruleType === 'whitelist')
        const usedByBlacklist = usedBys.find(usedBy => usedBy.ruleType === 'blacklist')
        const usedByNotSupportedList = usedBys.find(usedBy => usedBy.ruleType === 'notSupported')
        const usedByValidationList = usedBys.find(usedBy => usedBy.ruleType === 'validation')

        if (
          // Product
          ((usedByProductStage && viewControl.isProductStageSelected) && (
            (usedByWhitelist && viewControl.isWhitelistSelected)
            || (usedByBlacklist && viewControl.isBlacklistSelected)
            || (usedByNotSupportedList && viewControl.isNotSupportedListSelected)
            || (usedByValidationList && viewControl.isValidationListSelected)
          )
          )
          ||
          // Solo
          ((usedBySoloStage && viewControl.isSoloStageSelected) && (
            (usedByWhitelist && viewControl.isWhitelistSelected)
            || (usedByBlacklist && viewControl.isBlacklistSelected)
            || (usedByNotSupportedList && viewControl.isNotSupportedListSelected)
            || (usedByValidationList && viewControl.isValidationListSelected)
          )
          )
        ) {
          return displayedRule
        } else {
          return undefined
        }
      })
      .filter(value => value)
      .sort((a, b) => {
        return a.mnemonic.localeCompare(b.mnemonic)
      })
  }

  public allEnvs() {
    return ENVS
  }

  trackByFunction(index, rule: DisplayableRuleTable): string {
    if (!rule) {
      return null
    }
    return rule.uuid
  }

  showRuleDeletionsAsDialog(row: DisplayableRuleRow) {

    const configKeysWithDeletion = Object.keys(row.usedBy)
      .map((configKey: string) => {
        if (row.usedBy[configKey].deletion) {
          return configKey
        } else {
          return undefined
        }
      })
      .filter(configKey => configKey)

    const deletions: RowUsage = configKeysWithDeletion.reduce((acc: RowUsage, configKey: string) => {
      acc[configKey] = row.usedBy[configKey]
      return acc
    }, {} as RowUsage)

    const showDeletionsDialog = this.dialog.open(ShowRuleDeletionsDialogComponent, {
      width: '1000px',
      data: {
        title: `Deletions for this rule`,
        deletions: deletions,
      } as ShowRuleDeletions,
      panelClass: 'dialogFormField500',
    })
    showDeletionsDialog.afterClosed().subscribe(async (_: any) => {
      // no-op
    })
  }

  showRulesetDeletionsAsDialog(ruleset: DisplayableRuleTable) {

    const configKeysWithDeletion = Object.keys(ruleset.usedBy)
      .map((configKey: string) => {
        if (ruleset.usedBy[configKey].deletion) {
          return configKey
        } else {
          return undefined
        }
      })
      .filter(configKey => configKey)

    const deletions: RowUsage = configKeysWithDeletion.reduce((acc: RowUsage, configKey: string) => {
      acc[configKey] = ruleset.usedBy[configKey]
      return acc
    }, {} as RowUsage)

    const showDeletionsDialog = this.dialog.open(ShowRuleDeletionsDialogComponent, {
      width: '1000px',
      data: {
        title: `Deletions for this ruleset`,
        deletions: deletions,
      } as ShowRuleDeletions,
      panelClass: 'dialogFormField500',
    })
    showDeletionsDialog.afterClosed().subscribe(async (_: any) => {
      // no-op
    })
  }

  /** Which test(s) should be displayed for a given rule depends upon whether a config is selected */
  getTestsForRule(row: DisplayableRuleRow): string[] {
    if (this.isRowInConfig(row)) {
      const configKey = this.getConfigKeyFromPath(this.selectedConfigPath)
      const usedByForConfig = row.usedBy[configKey]
      return usedByForConfig ? [usedByForConfig.test] : []
    } else {
      return Object.values(row.usedBy)
        .map((value: {stage: Stage; ruleType: RuleType; description?: string; test?: string}) => value.test)
        .filter((value: string) => value)
        .filter((value, index, array) => array.indexOf(value) === index)
    }
  }

  /** Which description(s) should be displayed for a given rule depends upon whether a config is selected */
  getDescriptionsForRule(row: DisplayableRuleRow): string[] {
    if (this.isRowInConfig(row)) {
      const configKey = this.getConfigKeyFromPath(this.selectedConfigPath)
      const usedByForConfig = row.usedBy[configKey]
      return usedByForConfig ? [usedByForConfig.description] : []
    } else {
      return Object.values(row.usedBy)
        .map((value: {stage: Stage; ruleType: RuleType; description?: string; test?: string}) => value.description)
        .filter((value: string) => value)
        .filter((value, index, arr) => arr.indexOf(value) === index)
    }
  }

  getHelpForRule(row: DisplayableRuleRow): string[] {
    if (this.isRowInConfig(row)) {
      const configKey = this.getConfigKeyFromPath(this.selectedConfigPath)
      const usedByForConfig = row.usedBy[configKey]
      return usedByForConfig ? [usedByForConfig.help] : []
    } else {
      return Object.values(row.usedBy)
        .map((value: {help?: string}) => value.help)
        .filter((value: string) => value)
        .filter((value, index, arr) => arr.indexOf(value) === index)
    }
  }

  public getSelectedConfigAsInitialSelection(): SelectableConfigItem[] | undefined {
    let selectedConfig: SelectableConfigItem
    if (this.selectedConfigPath) {
      const selectedConfigObject = this.changeService.getObject(this.selectedConfigPath)
      selectedConfig = Object.assign({isSelected: true} as SelectableConfigItem, selectedConfigObject)
    }
    return selectedConfig ? [selectedConfig] : []
  }

  public configHasRule(configPath: string, ruleset: DisplayableRuleTable, stage: Stage, list: RuleType): boolean {
    return !!this.findRulesetInList(this.getRulesFor(configPath, stage, list), ruleset)
  }

  private getRulesFor(configPath: string, stage: Stage, list: RuleType): AnalyseRuleTable[] {
    const configObject = this.changeService.getObject(configPath)
    const config = JSON.parse(configObject.content) as DocgenConfig
    const analyseConfig: AnalyseConfig = (stage === 'product') ? config.analysePayload : config.analyseEnriched
    return analyseConfig.rules.filter(r => r.ruleType === list)
  }


  /** Find the ruleset in the supplied list (blacklist | not support list | validation list | whitelist) */
  public findRulesetInList(list: AnalyseRuleTable[], ruleset: DisplayableRuleTable): AnalyseRuleTable | undefined {
    return list.find((r: AnalyseRuleTable) => {
      return r.name === ruleset.name
        && r.mnemonic === ruleset.mnemonic
        && r.ruleType === ruleset.ruleType
      // && r.fields.length === ruleset.fields.length
      // && r.fields.every((f: string, index: number) => f === ruleset.fields[index])
    })
  }

  public saveChangedConfig(filename: string, path: string, config: DocgenConfig): void {
    this.changeService.addChange({
      source: 'lambda-ingest-product',
      name: filename,
      path: path,
      content: JSON.stringify(config, undefined, 2),
      size: undefined,
      hash: undefined,
      modified: undefined,
      state: undefined,
    } as ConfigDetail)
  }

  setErrorMessage(ruleType: string, ruleMnemonic: string, rowMnemonic: string | undefined, description: string, help: string) {
    const dialogRef = this.modalService.open(RuleErrorMessageDialogComponent, {
      size: 'lg',
      backdrop: false,
    })

    dialogRef.componentInstance.ruleType = ruleType
    dialogRef.componentInstance.ruleMnemonic = ruleMnemonic
    dialogRef.componentInstance.rowMnemonic = rowMnemonic
    dialogRef.componentInstance.description = description
    dialogRef.componentInstance.help = help

    const updateErrorDescription = (configPath: string,
      updatedRuleDefinition: {ruleType: string; ruleMnemonic: string; rowMnemonic: string | undefined; description: string; help: string}) => rule => {
        if (rule.ruleType === updatedRuleDefinition.ruleType && rule.mnemonic === updatedRuleDefinition.ruleMnemonic) {
          if (updatedRuleDefinition.rowMnemonic) {
            rule.rule = rule.rule.map(row => {
              if (row.mnemonic === updatedRuleDefinition.rowMnemonic) {
                console.log('Updating Rule Row: ' + configPath)
                row.description = updatedRuleDefinition.description
                row.help = updatedRuleDefinition.help
              }
              return row
            })
          } else {
            console.log('Updating Rule: ' + configPath)
            rule.description = updatedRuleDefinition.description
            rule.help = updatedRuleDefinition.help
          }
        }
        return rule
      }

    dialogRef.result
      .then((updatedRuleDefinition: {
        ruleType: string,
        ruleMnemonic: string,
        rowMnemonic: string | undefined,
        description: string,
        help: string,
      }) => {
        // console.log('Will update rule error:')
        // console.dir(updatedRuleDefinition)

        this.changeService.getObjectsOfType('settings')
          .pipe(
            take(1),
            map((changes: ChangeDetails[]) => {
              const updated = changes.map(change => {
                const before = change.content.trim()
                const config = JSON.parse(change.content) as CommonConfig
                config.analysePayload.rules = config.analysePayload.rules.map(updateErrorDescription(change.path, updatedRuleDefinition))
                config.analyseEnriched.rules = config.analyseEnriched.rules.map(updateErrorDescription(change.path, updatedRuleDefinition))
                const after = JSON.stringify(config, undefined, 2).trim()
                if (after !== before) {
                  change.content = after
                  // console.log('Changed')
                  // console.dir({
                  //   before: JSON.parse(before),
                  //   after: JSON.parse(after),
                  // })
                  return change
                }
                return undefined
              }).filter(x => x)
              return updated
            }),
          ).subscribe((updated: ChangeDetails[]) => {
            updated.forEach(update => {
              console.log('Saving updated: ' + update.path)
              update.hash = undefined
              update.size = undefined
              update.modified = undefined
              update.state = undefined
              this.changeService.addChange({
                source: update.source,
                name: update.name,
                path: update.path,
                content: update.content,
                size: undefined,
                hash: undefined,
                modified: undefined,
                state: undefined,
              } as ConfigDetail)
            })
            if (updated.length > 0) {
              this.toast.info('Error Messages updated in ' + updated.length + ' configs')
            } else {
              this.toast.warning('No changes to the error messages detected')
            }
          })
      })
      .catch(res => {
        console.log('Will not update rule error...')
      })
  }

  public ruleIsExcluded(ruleMnemonic: string, useFullMatch: boolean, environment: string) {
    return !!this.ruleExclusion[environment]?.ignoredMnemonics?.find(rule =>
      (rule.mnemonic === ruleMnemonic || (!useFullMatch && rule.mnemonic.startsWith(`${ruleMnemonic}-`)))
      && rule.excludedIn.includes(environment),
    )
  }

  // User Actions:
  public actionAddNewRule(ruleset: DisplayableRuleTable) {
    const userActionAddNewRule = new UserActionAddNewRule(this.dialog, this.changeService, this)
    userActionAddNewRule.addNewRule(ruleset)
  }

  /** Delete a rule from a ruleset in one or more configs. */
  public actionDeleteRule(ruleToDelete: DisplayableRuleRow, ruleset: DisplayableRuleTable): void {

    const userActionDeleteRule = new UserActionDeleteRule(this, this.dialog, this.changeService, this.toast, this.modalService)
    userActionDeleteRule.deleteRule(ruleToDelete, ruleset)
  }

  public async actionAddNewRuleset(): Promise<void> {
    const userActionAddNewRuleset = new UserActionAddNewRuleset(this, this.dialog, this.changeService, this.toast, this.modalService)
    await userActionAddNewRuleset.addNewRuleset()
  }

  /** Delete a ruleset from one or more configs. */
  public async actionDeleteRuleSet(rulesetToDelete: DisplayableRuleTable): Promise<void> {
    const userActionDeleteRuleSet = new UserActionDeleteRuleSet(this, this.dialog, this.changeService, this.toast, this.modalService)
    await userActionDeleteRuleSet.deleteRuleSet(rulesetToDelete)
  }

  public actionDownloadRulesForSelectedConfig() {
    const userActionDownloads = new UserActionDownloads(this.dialog, this.changeService, this)
    userActionDownloads.downloadRulesForSelectedConfig()
  }

  public actionDownloadRulesAsCsv(rule: DisplayableRuleTable) {
    const userActionDownloads = new UserActionDownloads(this.dialog, this.changeService, this)
    userActionDownloads.downloadRulesAsCsv(rule)
  }

  public actionDownloadAllRules() {
    const userActionDownloads = new UserActionDownloads(this.dialog, this.changeService, this)
    userActionDownloads.downloadAllRules()
  }

  public actionDownloadSelectedRules() {
    const userActionDownloads = new UserActionDownloads(this.dialog, this.changeService, this)
    userActionDownloads.downloadSelectedRules()
  }

  public actionDownloadAllRulesNewFormat() {
    const userActionDownloads = new UserActionDownloads(this.dialog, this.changeService, this)
    userActionDownloads.downloadAllRulesNewFormat()
  }

  public actionDownloadAllErrorMessages() {
    const userActionDownloads = new UserActionDownloads(this.dialog, this.changeService, this)
    userActionDownloads.downloadAllErrorMessages()
  }

  public actionToggleIgnoreInEnv(checked: boolean, ruleMnemonic: string, environment: string) {
    const configPath = `/config/latest/lambda-approvals/config/ignored-mnemonics/ignored-mnemonics.json`
    const confirmDialog = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })
    confirmDialog.componentInstance.title = 'Ignore Rule Immediately'
    confirmDialog.componentInstance.message = `Are you sure you want to ${checked ? 'ignore' : 'include'} ${ruleMnemonic} immediately in ${environment}?`

    confirmDialog.result.then()
      .then(_ => {
        this.api.get<GetConfigFileSuccessResponse>(configPath)
          .subscribe(latest => {
            // @ts-ignore
            if (latest.code === 'FileDoesNotExistException') {
              latest.data = JSON.stringify({})
            }
            const ruleExclusionConfig = JSON.parse(latest.data) as RuleExclusion
            let updated
            if (!ruleExclusionConfig[environment]?.ignoredMnemonics) {
              ruleExclusionConfig[environment] = {ignoredMnemonics: []}
            }
            if (!ruleExclusionConfig[environment].ignoredMnemonics.find(rule => rule.mnemonic === ruleMnemonic)) {
              updated = Object.assign({}, ruleExclusionConfig, {
                [environment]: {
                  ignoredMnemonics: ruleExclusionConfig[environment].ignoredMnemonics.concat({
                    mnemonic: ruleMnemonic,
                    updatedBy: this.username,
                    updatedDate: new Date().toISOString(),
                  }),
                },
              })
            } else {
              updated = Object.assign({}, ruleExclusionConfig)
              const updatedRuleIndex = updated[environment].ignoredMnemonics.findIndex(rule => rule.mnemonic === ruleMnemonic)
              updated[environment].ignoredMnemonics.splice(updatedRuleIndex, 1)
            }
            const updatedConfig = JSON.stringify(updated, undefined, 2)
            const ruleIgnorePayload: PushConfigRequest = {
              commitMessage: `Rule ${ruleMnemonic} ignored in ${environment} via Storm by ${this.username}`,
              data: updatedConfig,
              parentCommit: latest.branchVersion,
            }
            this.api.post(configPath, ruleIgnorePayload)
              .subscribe((res: PublishConfigResponse) => {
                if (res.status === 'OK') {
                  this.toast.success('Rule ignore request submitted successfully')
                } else {
                  this.toast.error('Rule ignore failed - Please reload and try again')
                }
              })
          })
      })
      .catch(_ => {
        this.toast.warning('Ignore rule cancelled')
      })
  }

  // Start of Context Menu Code (right click menu)
  get locationCss() {
    return {
      position: 'fixed',
      display: this.showOldRuleSetContextMenu ? 'block' : 'none',
      left: this.mouseLocation.left + 'px',
      top: this.mouseLocation.top + 'px',
    };
  }

  hideContextMenu() {
    this.showOldRuleSetContextMenu = false
  }

  public actionRightClick(event: MouseEvent, selectedRuleSet: DisplayableRuleTable) {
    event.preventDefault();
    event.stopPropagation()

    if (!selectedRuleSet.isProtectedRuleSet) {
      this.hideContextMenu()
      return
    }

    this.currentlySelectedRuleSet = selectedRuleSet
    if (selectedRuleSet !== undefined && selectedRuleSet.mnemonic !== undefined) {
      this.mouseLocation = {left: event.clientX, top: event.clientY}
      this.showOldRuleSetContextMenu = true
    } else {
      this.hideContextMenu()
    }
  }

  public async actionEditRuleSet(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation()
    this.hideContextMenu()

    const dialogData = {
      ruleSetMnemonic: this.currentlySelectedRuleSet.mnemonic,
      ruleSetDescription: this.currentlySelectedRuleSet.name,
      username: this.username,
      dialog: this.dialog,
      changeService: this.changeService,
      toast: this.toast,
      modalService: this.modalService
    } as RuleSetEditDialogComponentData

    // Open dialog and wait until it is closed
    const dataFromUserDialog = await this.dialog.open(RuleSetEditDialogComponent, {
      width: '90vw',
      height: '90vh',
      data: dialogData,
      // panelClass: '',
      // hasBackdrop: true,
      // backdropClass: 'cdk-overlay-dark-backdrop',
      // disableClose: false
      // plugh Would be nice to disable behind the dialog
    }).afterClosed().toPromise()

    // return false
  }
  // End of Context Menu Code (right click menu)

}

  // highlightDupData(row: DisplayableRuleRow, rule: DisplayableRuleTable): void {
  //   this.setHighlightDupData(true, row, rule)
  // }

  // stopHighlightDupData(row: DisplayableRuleRow, rule: DisplayableRuleTable): void {
  //   this.setHighlightDupData(false, row, rule)
  // }

  // private setHighlightDupData(value: boolean, row: DisplayableRuleRow, rule: DisplayableRuleTable): void {
  //   if (value === true && row.status.isDuplicatedData) {
  //     row.status.highlight = true
  //     row.status.duplicateDataIn.forEach((uuid: string) => {
  //       const found = rule.rule.find(r => r.uuid === uuid)
  //       found.status.highlight = true
  //     })
  //   }
  //   if (value === false) {
  //     row.status.highlight = false
  //     row.status.duplicateDataIn.forEach((uuid: string) => {
  //       const found = rule.rule.find(r => r.uuid === uuid)
  //       found.status.highlight = false
  //     })
  //   }
  // }

  // highlightDupCodes(row: DisplayableRuleRow, rule: DisplayableRuleTable): void {
  //   this.setCodeHighlight(true, row, rule)
  // }

  // stopHighlightDupCodes(row: DisplayableRuleRow, rule: DisplayableRuleTable): void {
  //   this.setCodeHighlight(false, row, rule)
  // }

  // private setCodeHighlight(value: boolean, row: DisplayableRuleRow, rule: DisplayableRuleTable): void {
  //   rule.rule
  //     .filter(r => r.mnemonic === row.mnemonic)
  //     .forEach(r => r.status.codeHighlight = value)
  // }
