import { Pipe, PipeTransform } from '@angular/core';
import {ToastrService} from 'ngx-toastr'



@Pipe({
    name: 'search'
})

export class FilterPipe implements PipeTransform {

  constructor(
      private toast: ToastrService,
      ) {
      }
    
  transform(value?: any, keys?: string, term?: string) {
    if (!term) return value;

    try{
      const finalTerm = (value || []).filter(xpath => keys.split(',').some(key => xpath.hasOwnProperty(key) && new RegExp(term, 'gi').test(xpath[key])));
      return finalTerm;
    }
    catch(error){
      this.toast.error(`Please Enter valid Regular Expresstion!`)
  
    }
  
  }

}