import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { JsonEditorComponent, JsonEditorOptions } from "ang-jsoneditor";
import { get } from "lodash";
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import X2JS from "x2js";
import { availableMultiplicities } from '../utils/available-multiplicities';
import { leftOptions, rightOptions } from '../utils/editor-options';
import { AutoEnrichmentResponse } from '../utils/models/md-enrichment-rule-model';
import { GenerateRequest } from '../utils/models/md-gendoc-model';
import { OutputResponses, SendToSmartDxResponse } from "../utils/models/md-model";
import { MdPayload, PayloadResponse, UpdatePayloadRequest } from "../utils/models/md-payload-model";
import { ValidatePayloadResponse } from '../utils/models/md-validation-models';
import { multiplicityObjects } from '../utils/multiplicity-objets';
import { ManualDraftingAdminPayloadService } from "../utils/services/manual-drafting-administer-payload.service";
import { ManualDraftingAdminEnrichmentRuleService } from "../utils/services/manual-drafting-enrichment-rules.service";
import { ManualDraftingGendocService } from '../utils/services/manual-drafting-gendoc.service';
import { MdValidateService } from '../utils/services/md-validate.service';

@Component({
  selector: "app-payload-editor",
  templateUrl: "./payload-editor.component.html",
  styleUrls: ["./payload-editor.component.scss"],
})
export class PayloadEditorComponent implements OnInit {
  private subscriptionsArray: Subscription[] = [];
  @Input() modal: any;
  @Input() selectedPayload: MdPayload
  @Input() refresh: BehaviorSubject<Boolean>

  @ViewChild("leftEditor", { static: false })
  leftEditor!: JsonEditorComponent;

  @ViewChild("rightEditor", { static: false })
  rightEditor!: JsonEditorComponent;

  leftEditorOptions: JsonEditorOptions;
  rightEditorOptions: JsonEditorOptions;

  multiplicityForm: UntypedFormGroup;

  leftEditorPayload: any;
  rightEditorPayload: any;
  multiplicities: any[];
  multiplicityObjects: any[];
  isSmartDxPayload = true;

  isThereAnyOutput: boolean = false;
  outputResponses: OutputResponses = {
    validationResponse: null,
    autoTransformResponse: null,
    sendToSmartDxResponse: null,
    savePayloadResponse: null
  };

  constructor(
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private httpClient: HttpClient,
    private validateService: MdValidateService,
    private gendocService: ManualDraftingGendocService,
    private payloadService: ManualDraftingAdminPayloadService,
    private toastrService: ToastrService,
    private administerEnrichmentsService: ManualDraftingAdminEnrichmentRuleService,

  ) {
    this.leftEditorOptions = new JsonEditorOptions();
    this.rightEditorOptions = new JsonEditorOptions();

    this.leftEditorOptions = { ...leftOptions };
    this.leftEditorOptions.onChange = () => console.log(this.leftEditor.get());

    this.rightEditorOptions = { ...rightOptions };
    // this.rightEditorOptions.onChange = () =>
    //   console.log(this.rightEditor.get());
  }

  ngOnInit(): void {

    this.clearLocalStorage();
    this.getRightEditorPayload();
    this.initializeForm();
    this.multiplicities = availableMultiplicities;
    this.multiplicityObjects = multiplicityObjects;
    this.leftEditorPayload = JSON.parse(this.selectedPayload.draft_payload);

    if(this.selectedPayload.smartdx_payload) {
      this.isSmartDxPayload = false;
    };

    this.subscriptionsArray.push(this.gendocService.smartdxSuccesResponseSubject.subscribe(smartDxSuccesResponse => {
      if (smartDxSuccesResponse) this.outputResponses = this.clearSimpleObject(this.outputResponses);
    }));

    this.subscriptionsArray.push(this.gendocService.smartdxFailureResponseSubject.subscribe(smartDxFailureResponse => {
      if (smartDxFailureResponse) {
        this.setOutputResponses('smartDx', smartDxFailureResponse);
      }
    }));

    this.subscriptionsArray.push(this.administerEnrichmentsService.autoTransformSuccesResponseSubject.subscribe(autoTransformSuccessResponse => {
      if (autoTransformSuccessResponse) this.outputResponses = this.clearSimpleObject(this.outputResponses);
    }));

    this.subscriptionsArray.push(this.administerEnrichmentsService.autoTransformFailureResponseSubject.subscribe(autoTransformFailedResponse => {
      if (autoTransformFailedResponse && this.selectedPayload.id === autoTransformFailedResponse.updatedPayload.id) {
        this.setOutputResponses('auto-transform', autoTransformFailedResponse);
      }
    }));
  }

  ngOnDestroy(): void {
    this.clearLocalStorage();
    this.outputResponses = this.clearSimpleObject(this.outputResponses);
    this.isThereAnyOutput = false;
    this.subscriptionsArray.forEach(subscription => subscription.unsubscribe());
  }

  openMultiplicityModal(content) {
    this.modalService.open(content, {
      ariaLabelledBy: "add-multiplicity-modal",
      scrollable: true,
      size: "lg",
      centered: true,
      backdrop: "static",
    });
  }

  initializeForm(): void {
    this.multiplicityForm = this.formBuilder.group({
      multiplicity: [null, Validators.required],
      numberRequired: [
        1,
        [
          Validators.required,
          Validators.min(1),
          Validators.pattern("^([1-9][0-9]*)$"),
        ],
      ],
    });
  };

  openRuleValidationResultsWindow(): any {
    this.setLocalStorage();
    window.open('manual-drafting/output-results', '_blank');
  }


  getRightEditorPayload(): void {
    if (this.selectedPayload.smartdx_payload) {
      this.rightEditorPayload = JSON.parse(this.selectedPayload.smartdx_payload)
    } else {
      this.getDefaultSmartDXTemplate().subscribe((data) => {
        const x2js = new X2JS();
        this.rightEditorPayload = x2js.xml2js(data);
        console.log(this.rightEditorPayload);
      });
    }
  }

  addMultiplicity(): void {
    const { path, objectRef } = this.multiplicityForm.value.multiplicity;
    let numberRequired: number = this.multiplicityForm.value.numberRequired;
    const editorData: any = this.rightEditor.get();
    const multiplicityObj = this.multiplicityObjects[`${objectRef}`];
    const targetMultiplicity = get(editorData, path);

    while (numberRequired > 0) {
      targetMultiplicity.push(multiplicityObj);
      numberRequired--;
    }

    this.rightEditor.update(editorData);

    this.initializeForm();
  }

  validatePayload() {
    const payload = this.rightEditor.get();
    this.validateService.validatePayload(payload).subscribe(validationResponse => {
      const { ruleValidation, schemaValidation } = validationResponse;
      if (ruleValidation.status === 'PASSED' && schemaValidation.status === 'OK') {
        this.validationToastr('success');
        this.outputResponses = this.clearSimpleObject(this.outputResponses);
      } else {
        if (ruleValidation.status === 'FAILED' || ruleValidation.status === 'ERROR') {
          ruleValidation.status === 'FAILED' ? this.validationToastr('rule-validation-failed') : this.validationToastr('rule-validation-error');
        };
        if (schemaValidation) {
          if (schemaValidation.status === 'WARNING' || schemaValidation.status === 'ERROR') this.validationToastr('schema-validation-error');
          if (schemaValidation.status === 'FAILED') this.validationToastr('schema-validation-failed');
        };
        this.setOutputResponses('validation', validationResponse);
      }
      });
  }

  saveAsDraft() {
    const request: UpdatePayloadRequest = {
      smartdx_payload: JSON.stringify(this.rightEditor.get())
    }

    this.payloadService.updatePayload(this.selectedPayload.id, request).then(updatePayloadResponse => {
      if (updatePayloadResponse.status === 'PASSED') {
        this.isSmartDxPayload = false;
      };
      if (updatePayloadResponse.status === 'ERROR') {
        this.setOutputResponses('save-payload', updatePayloadResponse);
      };
    })
  }

  sendToSmartDx() {
    const request: GenerateRequest = {
      id: this.selectedPayload.id,
      payload: JSON.stringify(this.rightEditor.get()),
      smartDxProjectId: '157545743',
      smartDxTemplateSelectorId: '157759999'
    }
    this.gendocService.sendToSmartdx(request)
  }

  closePayloadEditor() {
    this.initializeForm()
    this.modal.close()
    this.refresh.next(true)
  }

  //temporary function for getting right editor xml payload from assets
  getDefaultSmartDXTemplate(): Observable<any> {
    return this.httpClient.get("/assets/clearedXmlFromSchemaV9.xml", {
      headers: new HttpHeaders()
        .set("Content-Type", "text/xml")
        .append("Access-Control-Allow-Methods", "GET")
        .append("Access-Control-Allow-Origin", "*")
        .append(
          "Access-Control-Allow-Headers",
          "Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method"
        ),
      responseType: "text",
    });
  }

  validationToastr(message: string): void {
    const toastrOptions = {
      positionClass: 'toast-bottom-right',
      timeOut: 5000
    };
    switch (message) {
      case "success":
        this.toastrService.success('Rule and schema validations successfully passed!', 'Success', toastrOptions);
        break;
      case "rule-validation-failed":
        this.toastrService.warning('One or more validation rules have failed!', 'Rule validation failed!', toastrOptions);
        break;
      case "rule-validation-error":
        this.toastrService.error('Internal server error: could not run rule validation!', 'Rule validation error!', toastrOptions);
        break;
      case "schema-validation-warning":
        this.toastrService.warning('Schema validation has passed, however there are some warnings!', 'Schema validation warnings!', toastrOptions);
        break;
      case "schema-validation-error":
        this.toastrService.warning('Errors/warnings encoutered while running schema validation!', 'Schema validation failed!', toastrOptions);
        break;
      case "schema-validation-failed":
        this.toastrService.error('Internal server error: could not run schema validation!', 'Schema validation error!', toastrOptions);
        break;

      default:
        break;
    }
  }

  async payloadTransformation(id:string ) {
    const smartdxPayload = JSON.stringify(this.rightEditor.get());
      await this.administerEnrichmentsService.autoTransformations(id, this.selectedPayload.draft_payload,smartdxPayload).then(res=> {
        const updatedSmartDxPayload = JSON.parse(res.updatedPayload.smartdx_payload)
        this.rightEditor.update(updatedSmartDxPayload)
      });

  }

  private clearSimpleObject(obj: any): object {
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== null) {
        obj = { ...obj, [key]: null };
      }
    }
    return obj;
  };

  private setLocalStorage(): void {
    localStorage.setItem('outputResponses', JSON.stringify(this.outputResponses));
  };

  private clearLocalStorage(): void {
    if (localStorage.getItem('outputResponses')) {
      localStorage.removeItem('outputResponses');
    }
  }

  private setOutputResponses(outputResponseType: string, outputResponseData: ValidatePayloadResponse | AutoEnrichmentResponse | SendToSmartDxResponse | PayloadResponse): void {
    this.outputResponses = this.clearSimpleObject(this.outputResponses);
    switch (outputResponseType) {
      case 'validation':
        this.outputResponses = { ...this.outputResponses, validationResponse: outputResponseData as ValidatePayloadResponse };
        break;
      case 'auto-transform':
        this.outputResponses = { ...this.outputResponses, autoTransformResponse: outputResponseData as AutoEnrichmentResponse };
        break;
      case 'save-payload':
        this.outputResponses = { ...this.outputResponses, savePayloadResponse: outputResponseData as PayloadResponse };
        break;
      case 'smartDx':
        this.outputResponses = { ...this.outputResponses, sendToSmartDxResponse: outputResponseData as SendToSmartDxResponse };
        break;

      default:
        break;
    }
    this.isThereAnyOutput = true;
  }
}
