import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router'
import {Observable} from 'rxjs'
import {TestManagerService} from '../services/test-manager.service'
import {TestHistory} from '../model/test-management-responses'
import {map} from 'rxjs/operators'

@Injectable({providedIn: 'root'})
export class TestHistoryResolver implements Resolve<TestHistory[]> {
  constructor(private testManagerService: TestManagerService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TestHistory[]> {
    return this.testManagerService.getTestHistory(route.paramMap.get('testId'))
      .pipe(
        map(x => x.history),
      )
  }
}
