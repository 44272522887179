import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {Environment, ENVIRONMENTS, EnvironmentService} from '../services/environment-service'
import {Subscription} from 'rxjs'
import {MatSelectChange} from '@angular/material/select'

@Component({
  selector: 'app-source-environment-selector',
  templateUrl: './source-environment-selector.component.html',
  styleUrls: ['./source-environment-selector.component.scss'],
})
export class SourceEnvironmentSelectorComponent implements OnInit, OnDestroy, OnChanges {
  subs = new Subscription()

  environments: Environment[]
  selectedEnvironment: Environment
  @Input() showStorm = false

  constructor(private environmentService: EnvironmentService) {
    this.environments = environmentService.availableSourceEnvironments
  }

  selectEnvironment(event: MatSelectChange) {
    this.environmentService.selectSourceEnvironment(event.value)
  }

  ngOnInit(): void {
    this.subs.add(
      this.environmentService.sourceEnvironment.subscribe(env => {
        this.selectedEnvironment = env
      }),
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.environments = this.environmentService.availableSourceEnvironments
      .filter(environment => {
        return this.showStorm || environment !== ENVIRONMENTS.STAGING
      })
  }
}
