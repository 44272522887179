import {Component, OnDestroy, OnInit} from '@angular/core'
import {PermissionsService} from '../../services/permissions.service'
import {Subscription} from 'rxjs'
import {Region, REGIONS, EnvironmentService} from '../../services/environment-service'
import {Environment, SmartDxAdminService} from '../../services/smart-dx-admin.service'
import {SmartDxRestartDetails, SmartDxRestartDialogComponent} from '../../dialogs/smart-dx-restart-dialog.component'
import {MatDialog} from '@angular/material/dialog'
import {ToastrService} from 'ngx-toastr'


@Component({
  selector: 'app-admin-smartdx',
  templateUrl: './admin-smartdx.component.html',
  styleUrls: ['./admin-smartdx.component.scss'],
})
export class AdminSmartDxComponent implements OnInit, OnDestroy {

  Regions = REGIONS
  subscriptions = new Subscription()
  canRestartSmartDx: boolean

  constructor(
    private permissionsService: PermissionsService,
    private environmentService: EnvironmentService,
    private smartDxService: SmartDxAdminService,
    private matDialog: MatDialog,
    private toast: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.permissionsService.restartSmartDx.subscribe(permission => this.canRestartSmartDx = permission),
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  restart(env: Environment, region: Region) {
    const dialog = this.matDialog.open(SmartDxRestartDialogComponent, {
      width: '750px',
      data: {
        env: env,
        region: region,
        jiraId: undefined,
        jiraDescription: undefined,
      } as SmartDxRestartDetails,
      panelClass: 'dialogFormField500',
    })

    dialog.afterClosed().subscribe(async (data: SmartDxRestartDetails) => {
      if (data) {
        if (!data.jiraId || !data.jiraDescription) {
          this.toast.error('Both Jira ID and description are required...')
          return
        }
        this.smartDxService.restart(env, region, data.jiraId, data.jiraDescription)
          .subscribe(res => {
            this.toast.info(`Smart DX restart requested for ${env} in ${region.display}...`)
          })
      }
    })
  }
  redeployTemplates(env: Environment, region: Region) {
    const dialog = this.matDialog.open(SmartDxRestartDialogComponent, {
      width: '750px',
      data: {
        env: env,
        region: region,
        jiraId: undefined,
        jiraDescription: undefined,
      } as SmartDxRestartDetails,
      panelClass: 'dialogFormField500',
    })

    dialog.afterClosed().subscribe(async (data: SmartDxRestartDetails) => {
      if (data) {
        if (!data.jiraId || !data.jiraDescription) {
          this.toast.error('Both Jira ID and description are required...')
          return
        }
        this.smartDxService.redeployTemplates(env, region, data.jiraId, data.jiraDescription)
          .subscribe(res => {
            this.toast.info(`Smart DX template redeployment requested for ${env} in ${region.display}...`)
          })
      }
    })
  }
  flushAppliance(env: Environment, region: Region) {
    const dialog = this.matDialog.open(SmartDxRestartDialogComponent, {
      width: '50%',
      data: {
        env: env,
        region: region,
        jiraId: undefined,
        jiraDescription: undefined,
        alternativeTitle: "Confirm Smart DX Flush Cache"
      } as SmartDxRestartDetails,
      panelClass: 'mat-dialog-without-padding',
    })

    dialog.afterClosed().subscribe(async (data: SmartDxRestartDetails) => {
      if (data) {
        if (!data.jiraId || !data.jiraDescription) {
          this.toast.error('Both Jira ID and description are required...')
          return
        }
        this.smartDxService.flushAppliance(env, region, data.jiraId, data.jiraDescription)
          .subscribe(res => {
            this.toast.info(`Smart DX flush appliance cache requested for ${env} in ${region.display}...`)
          })
      }
    })
  }

  envAvailable(envId: string) {
    return this.environmentService.availableSourceEnvironments.filter(env => env.id === envId).length > 0
  }

}
