import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import { Subject } from 'rxjs'

const base64 = require('base64-js')

export interface DiffRequest {
  mimeType: string
  expected: string
  actual: string
  visualDiff: boolean
  draftable: boolean,
  ignorePatterns?: string[]
}

export interface DiffReport {
  name: string
  differences: boolean
  added: number
  changed: number
  deleted: number
  mimeType?: string
  payload?: string | string[]
  error?: string
  totalDifferencesCount?: number;
  ignoredDifferencesCount?: number;
}

export interface DiffResponse {
  addedCount: number
  changedCount: number
  removedCount: number
  draftableUrl: string
  differences: boolean
  reports: { [key: string]: DiffReport }
  ignoreTotal?: number;
  ignoreCount?: number;
  error?: string;
}

@Injectable({
  providedIn: 'root',
})
export class DiffService {

  visualDiff = true
  showVisualDiffConfigChange: Subject<boolean> = new Subject<boolean>();

  constructor(private api: ApiService) {
    this.showVisualDiffConfigChange.subscribe((value) => {
      this.visualDiff = value
    })
  }


  toggleVisualDiff(value?: boolean) {
    this.showVisualDiffConfigChange.next(value ?? !this.visualDiff)
  }

  getIsVisualDiff() {
    return this.visualDiff
  }

  generatePdfDiffReport(expected: Uint8Array, actual: Uint8Array, draftable: boolean, ignorePatterns: string[] = []) {
    return this.api.post<DiffRequest, DiffResponse>('/diff', {
      mimeType: 'application/pdf',
      expected: base64.fromByteArray(expected),
      actual: base64.fromByteArray(actual),
      visualDiff: this.visualDiff,
      draftable,
      ignorePatterns: ignorePatterns,
    })
  }

  generateXmlDiffReport(expected: string, actual: string) {
    return this.api.post<DiffRequest, DiffResponse>('/diff', {
      mimeType: 'application/xml',
      expected,
      actual,
      draftable: false,
      visualDiff: false
    })
  }

  generateWordReport(expected: Uint8Array, actual: string, draftable: boolean) {
    console.log(base64.fromByteArray(expected))
    return this.api.post<DiffRequest, DiffResponse>('/diff', {
      mimeType: 'application/msword',
      expected: base64.fromByteArray(expected),
      actual: actual,
      draftable,
      visualDiff: false
    })
  }
}
