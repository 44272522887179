import {SelectionModel} from '@angular/cdk/collections'
import {NestedTreeControl} from '@angular/cdk/tree'
import {AfterContentChecked, Component, Inject, OnInit} from '@angular/core'
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import {MatTreeNestedDataSource} from '@angular/material/tree'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import cloneDeep from 'clone-deep'
import deepFreeze from 'deep-freeze'
import {ToastrService} from 'ngx-toastr'
import {take} from 'rxjs/operators'
import {ConfigKeyElement} from 'src/app/common/domain'
import {ChangeDetails, DocgenConfigAndMetadataChangeDetails} from 'src/app/common/domain/change'
import {ConfigItem} from 'src/app/common/domain/config'
import {ProtectedRuleSet} from 'src/app/model/rationalised-rule'
import {ChangeService} from 'src/app/services/change.service'
import {ConfirmDialogComponent} from '../confirm-dialog.component'
import {RulesetEditSaveLogic} from './logic/ruleset-edit-save-logic'
import {RuleSetEditTreeViewLogic} from './logic/ruleset-edit-treeview-logic'

export interface RuleSetEditDialogComponentData {
  ruleSetMnemonic: string,
  ruleSetDescription: string,
  username: string,
  dialog: MatDialog,
  changeService: ChangeService,
  toast: ToastrService,
  modalService: NgbModal,
}

@Component({
  templateUrl: './ruleset-edit-dialog.component.html',
  styleUrls: ['./ruleset-edit-dialog.component.scss'],
})
export class RuleSetEditDialogComponent implements OnInit, AfterContentChecked {

  constructor(public dialogRef: MatDialogRef<RuleSetEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: RuleSetEditDialogComponentData,
  ) {
  }

  private _docgenConfigItems: ConfigItem[]
  public CurrentlyExecuting = false

  public treeControl = new NestedTreeControl<ConfigKeyElement>(node => node.children) // Used by Html
  public dataSource = new MatTreeNestedDataSource<ConfigKeyElement>() // Used by Html
  private _checklistSelection = new SelectionModel<ConfigKeyElement>(true)

  public isDirty = false
  private _userFilteredDocgenConfigs: DocgenConfigAndMetadataChangeDetails[] = []

  public protectedRuleSet: ProtectedRuleSet = new ProtectedRuleSet()
  public protectedRuleSetDefinitionChangeDetails: ChangeDetails
  public protectedRuleSetOrginal: ProtectedRuleSet

  public async ngOnInit() {
    // *** Wait for Change Server Constructor to complete before doing anthing else ***
    // await BasicHelper.WaitForChangeServiceConstructorToComplete(this.dialogData.changeService)

    this.protectedRuleSetDefinitionChangeDetails = this.dialogData.changeService.getObject('protected-rulesets/' + this.dialogData.ruleSetMnemonic.toUpperCase() + '.json')
    // console.info('this.protectedRuleSetDefinitionFile')
    // console.dir(this.protectedRuleSetDefinitionChangeDetails)
    {
      this.protectedRuleSet = JSON.parse(this.protectedRuleSetDefinitionChangeDetails.content) as ProtectedRuleSet
      // const plugh = this.protectedRuleSet.entries
      this.protectedRuleSet.entries.sort()
      this.protectedRuleSetOrginal = cloneDeep(this.protectedRuleSet, true)
      deepFreeze(this.protectedRuleSetOrginal)
    }

    this._userFilteredDocgenConfigs = await this.dialogData.changeService.getUserFilteredDocgenConfigs()
      .pipe(take(1))
      .toPromise()

    this.dataSource.data = this._BuildTreeView()
  }

  public ngAfterContentChecked() {
    const treeViewLogic = new RuleSetEditTreeViewLogic(this._checklistSelection)
    treeViewLogic.expandAllSelectedTreeViewNodes(this.treeControl)
  }

  private _BuildTreeView(): ConfigKeyElement[] {
    this._docgenConfigItems = this._userFilteredDocgenConfigs.map((c) => c.settings);
    // console.info('this.data.configItems')
    // console.dir(this.data.configItems)

    const treeview = RuleSetEditTreeViewLogic.buildTree(this._docgenConfigItems)

    this.protectedRuleSet.entries.forEach((curEntry: string) => {
      curEntry = 'settings/' + curEntry + '/Config.json'
      const match = curEntry.match(/^settings\/(.+)\/(.+)\/(.+)\/(.+)\/Config.json$/)
      if (match && match.length >= 5) {
        const sourceSystem = match[1]
        const sourceSystemNode = treeview ? treeview.find((curConfigKeyElement: ConfigKeyElement) => curConfigKeyElement.name === sourceSystem) : undefined

        const programme = match[2]
        const programmeNode = sourceSystemNode ? sourceSystemNode.children.find((curConfigKeyElement: ConfigKeyElement) => curConfigKeyElement.name === programme) : undefined

        const productType = match[3]
        const productTypeNode = programmeNode ? programmeNode.children.find(curConfigKeyElement => curConfigKeyElement.name === productType) : undefined

        const documentType = match[4]
        const documentTypeNode = productTypeNode ? productTypeNode.children.find(curConfigKeyElement => curConfigKeyElement.name === documentType) : undefined

        if (documentTypeNode) {
          documentTypeNode.isSelected = true
          this._checklistSelection.select(documentTypeNode)
        }

        const treeViewLogic = new RuleSetEditTreeViewLogic(this._checklistSelection)
        treeViewLogic.setAncestors(documentTypeNode, true)
      }
    })

    return treeview
  }

  public actionToggleSelection(node: ConfigKeyElement): void {
    this.isDirty = true
    this._checklistSelection.toggle(node)
    node.isSelected = !node.isSelected
    const treeViewLogic = new RuleSetEditTreeViewLogic(this._checklistSelection)
    treeViewLogic.setAncestors(node, node.isSelected)
    treeViewLogic.setDescendents(node, node.isSelected)
  }

  isSelected(node: ConfigKeyElement): boolean {
    return this._checklistSelection.isSelected(node)
  }

  actionDescriptionChanged(newDescription: string) {
    console.info('actionDescriptionChanged')
    console.dir(newDescription)
    this.isDirty = true
    this.dialogData.ruleSetDescription = newDescription

    return true
  }

  public async actionCancel() {
    // Warn if Dirty
    if (this.isDirty) {
      const confirmDialog = this.dialogData.modalService.open(ConfirmDialogComponent, {
        size: 'lg',
        backdrop: false,
      })
      confirmDialog.componentInstance.title = 'Discard changes'
      confirmDialog.componentInstance.message = 'Are you sure you wish to discard your changes?'

      const dialogResult = await confirmDialog.result;
      console.warn(dialogResult)

      if (+dialogResult !== 1) {
        return
      }
    }

    this.dialogRef.close(false) // false means no changes were made
  }

  actionSave() {
    const selectedConfigKeyElements = this._flattenSelectedTreeViewItems(this.dataSource.data)
    console.info('this._selectedConfigKeyElements')
    // const xyzzy = this._selectedConfigKeyElements.map(x => x.name)
    console.dir(selectedConfigKeyElements)

    // this.protectedRuleSetOrginal.entries.sort()
    console.info('this.protectedRuleSetOrginal.entries')
    console.dir(this.protectedRuleSetOrginal.entries)

    // Delete items that have been removed
    let entriesToDelete: string[]
    {
      entriesToDelete = this.protectedRuleSetOrginal.entries.filter((curEntry: string) => {
        const found = selectedConfigKeyElements.includes(curEntry)
        return !found
      })
      console.info('Deleted Items:')
      console.dir(entriesToDelete)
    }

    // Add items that have been added
    let entriesToAdd: string[]
    {
      entriesToAdd = selectedConfigKeyElements.filter((curEntry: string) => {
        const found = this.protectedRuleSetOrginal.entries.includes(curEntry)
        return !found
      })
      console.info('Added Items:')
      console.dir(entriesToAdd)
    }

    const rulesetEditSave = new RulesetEditSaveLogic(this.dialogData.username, this.dialogData.changeService, this.protectedRuleSetOrginal, this.dialogData.ruleSetDescription)

    rulesetEditSave.synchroniseDescriptionAcrossProductDocumentConfigFiles(this.dialogData.ruleSetDescription)
    rulesetEditSave.updateProtectedRuleSetDefinitionFile(entriesToDelete, entriesToAdd)
    rulesetEditSave.softDeleteRuleSetFromProductDocumentConfigFiles(entriesToDelete)
    rulesetEditSave.addRuleSetToProductDocumentConfigFiles(entriesToAdd)

    this.dialogRef.close(true) // true means changes were made
  }

  private _flattenSelectedTreeViewItems(configKeyElement: ConfigKeyElement[]) {
    const selectedConfigKeyElements = new Array<string>()
    configKeyElement.forEach((l1: ConfigKeyElement) => {
      if (l1.isSelected && l1.children) {
        l1.children.forEach((l2: ConfigKeyElement) => {
          if (l2.isSelected) {
            l2.children.forEach((l3: ConfigKeyElement) => {
              if (l3.isSelected) {
                l3.children.forEach((l4: ConfigKeyElement) => {
                  if (l4.isSelected) {
                    const selectedLeafNode = [l1.name, l2.name, l3.name, l4.name].join('/')
                    selectedConfigKeyElements.push(selectedLeafNode)
                  }
                })
              }
            });
          }
        })
      }
    })

    selectedConfigKeyElements.sort()
    return selectedConfigKeyElements
  }

  // Do not delete this
  //  hasChild = (_: number, node: ConfigKeyElement) => !!node.children && node.children.length > 0
  public hasChild(_: number, node: ConfigKeyElement) {
    const result = !!node.children && node.children.length > 0
    return result
  }

}
