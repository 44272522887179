import {Component, OnDestroy, OnInit} from '@angular/core'
import {TestManagerService} from '../services/test-manager.service'
import {NameDialogComponent} from '../dialogs/name-dialog.component'
import {ConfirmDialogComponent} from '../dialogs/confirm-dialog.component'
import {StatusService} from '../services/status.service'
import {DocgenPayloadInformation, TestSuiteDefinition} from '../common/domain/tests'
import {PermissionsService} from '../services/permissions.service'
import {WorkspaceService} from '../services/workspace.service'
import {GetTestSuiteResponse} from '../model/test-management-responses'
import {DragulaService} from 'ng2-dragula'
import {combineLatest, Subscription} from 'rxjs'
import {MatDialog} from '@angular/material/dialog'
import {ToastrService} from 'ngx-toastr'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'

interface Test {
  visible: boolean
  name: string
  sourceSystem: string
  programme: string
  productType: string
  documentType: string
}

@Component({
  selector: 'app-test-suite-manager',
  templateUrl: './test-suite-manager.component.html',
  styleUrls: ['./test-suite-manager.component.scss'],
})
export class TestSuiteManagerComponent implements OnInit, OnDestroy {
  subs = new Subscription()

  selectedTestSuite: GetTestSuiteResponse

  selectedTests: Test[]
  unselectedTests: Test[]

  canSaveOrDelete = false

  isDeveloper = false
  isManager = false

  private filterValue = ''

  constructor(
    private testManager: TestManagerService,
    private toast: ToastrService,
    private statusService: StatusService,
    private permissionsService: PermissionsService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private workspaceService: WorkspaceService,
    private dragulaService: DragulaService,
  ) {
    this.permissionsService.developer.subscribe(developer => this.isDeveloper = developer)
    this.permissionsService.management.subscribe(manager => this.isManager = manager)
  }

  ngOnInit() {
    this.testManager.setService('docgen')
    this.subs.add(
      combineLatest([this.testManager.selectedTestSuite, this.testManager.tests])
        .subscribe(([testSuite, tests]) => {
          this.canSaveOrDelete = false
          this.selectedTestSuite = testSuite
          const allTests = tests
            .filter(test => test.product.service === 'docgen')
            .map((test): Test => {
              const productInfo = test.product as DocgenPayloadInformation
              return {
                visible: true,
                name: test.name,
                sourceSystem: productInfo.sourceSystem,
                programme: productInfo.programme,
                productType: productInfo.productType,
                documentType: productInfo.documentType,
              }
            })
          if (testSuite && allTests.length > 0) {
            const testSuiteTests = testSuite.tests.map(t => t.name)
            testSuite.tests.forEach(test => {
              if (!allTests.find(existing => existing.name === test.name)) {
                const productInfo = test.product as DocgenPayloadInformation
                allTests.push({
                  visible: true,
                  name: test.name,
                  sourceSystem: productInfo.sourceSystem,
                  programme: productInfo.programme,
                  productType: productInfo.productType,
                  documentType: productInfo.documentType,
                })
              }
            })
            this.selectedTests = allTests.filter(t => testSuiteTests.includes(t.name))
            this.unselectedTests = allTests.filter(t => !testSuiteTests.includes(t.name))
          } else {
            this.selectedTests = []
            this.unselectedTests = allTests
          }
          this.applyFilter()
        }),
    )
    this.subs.add(
      this.dragulaService.drop('DRAGULA_TESTS')
        .subscribe(() => this.applyFilter()),
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  setFilter(filterValue: string) {
    this.filterValue = filterValue || ''
    this.applyFilter()
  }

  private applyFilter() {
    const terms = this.filterValue.toLowerCase().split(' ')
    this.selectedTests.forEach(test => {
      const ts = test.name.toLowerCase()
      test.visible = terms.every(term => ts.includes(term))
    })
    this.unselectedTests.forEach(test => {
      const ts = test.name.toLowerCase()
      test.visible = terms.every(term => ts.includes(term))
    })
    this.canSaveOrDelete = this.selectedTests.length > 0
  }

  saveTestSuite() {
    const dialogRef = this.dialog.open(NameDialogComponent, {
      width: '500px',
      data: {
        title: 'Test Suite Name',
        name: this.selectedTestSuite ? this.selectedTestSuite.name : 'A Test Suite',
      },
      panelClass: 'dialogFormField500',
    })
    dialogRef.afterClosed().subscribe(async name => {
      if (name) {
        console.log('Got name: ' + name)

        const tests = this.selectedTests.map(test => test.name)
        const testSuite: TestSuiteDefinition = {
          name,
          tests,
        }
        console.log('Saving test suite: ' + name)
        this.testManager.saveTestSuite(testSuite)
          .then(res => {
            console.log('Saved Test Suite: ', res)
            this.toast.success('Saved test suite')
          })
      }
    })
  }

  deleteTestSuite() {
    const testSuite = this.selectedTestSuite
    if (testSuite) {
      const dialogRef = this.modalService.open(ConfirmDialogComponent, {
        size: 'lg',
        backdrop: false,
      })
      dialogRef.componentInstance.title = 'Please confirm'
      dialogRef.componentInstance.message = 'Are you sure you want to delete Test Suite ' + testSuite.name + '.'

      dialogRef.result.then(confirm => {
        console.log('Deleting: ' + testSuite)
        this.testManager.deleteTestSuite(testSuite.name)
          .then(res => {
            this.toast.success('Test suite deleted')
          })
      })
    }
  }

  deleteTests(tests: [{name}]) {
    const testsNames = tests.map( test => test.name)
    const dialogRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })
    dialogRef.componentInstance.title = 'Please confirm'
    dialogRef.componentInstance.message = 'Are you sure you want to delete ' + testsNames.length + ' Tests ?'

    dialogRef.result.then(confirm => {
      console.log('Deleting: ' + testsNames)
      this.testManager.deleteTests(testsNames)
        .then(res => {
          this.toast.success('Tests deleted')
        })
    })
     
  }
  addTestToSuite(test: Test) {
    this.selectedTests.push(test)
    const index = this.unselectedTests.indexOf(test)
    this.unselectedTests.splice(index, 1)
    this.canSaveOrDelete = this.selectedTests.length > 0
  }

  removeTestFromSuite(test: Test) {
    this.unselectedTests.push(test)
    const index = this.selectedTests.indexOf(test)
    this.selectedTests.splice(index, 1)
    this.canSaveOrDelete = this.selectedTests.length > 0
  }

  testName(test?: Test) {
    return test && test.name
  }

}