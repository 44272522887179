import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, Inject} from '@angular/core'
import {MatSelectChange} from '@angular/material/select'
import {Subscription} from 'rxjs'
import {DocgenPayloadInformation, TestSuiteDefinition, TestSuiteMetadata} from '../common/domain/tests'
import { GetTestSuiteResponse } from '../model/test-management-responses'
import {TestManagerService} from '../services/test-manager.service'
import {ToastrService} from 'ngx-toastr'
import { CloneXpaths } from '../model/test-management-requests'
import {MatDialog} from '@angular/material/dialog'
import {NameDialogComponent} from '../dialogs/name-dialog.component'
import { DOCUMENT } from '@angular/common'


interface  XpathObjectData {
  id: number,
  xpath: string,
  is_edit: boolean,
  toggleEdit: string
  isChecked: boolean,
  operation: '',
  increment: boolean,
  replace: boolean,

}

@Component({
  selector: 'app-test-suite-clone',
  templateUrl: './test-suite-clone.component.html',
  styleUrls: ['./test-suite-clone.component.scss'],
})


export class TestSuiteCloneComponent implements OnInit, OnDestroy {

    subs = new Subscription()

    selectedTestSuite: string
    isNewXpath: true
    xpathOfListItem = ''
    allTestsInTestSuite: GetTestSuiteResponse
    copyOtherTests = true
    summary = 'Summary';
    testsWillbeCloned = 0;
    testsWillBeCoppied = 0;
    query: string;
    givenRegex = '^(.*)2020(.*)$' ;
    @ViewChild('prefix') prefix: ElementRef;
    @ViewChild('suffix') suffix: ElementRef;
    @ViewChild('matchConfig') matchConfig: ElementRef;
    @ViewChild('targetConfig') targetConfig: ElementRef;
    @ViewChild('addXpath') addXpath: ElementRef;
    @ViewChild('getXpathListItem') getXpathListItem: ElementRef;
    @ViewChild('updatedXpathValue') updatedXpathValue: ElementRef;

    @Output() testSuite = new EventEmitter<TestSuiteDefinition>()

    testSuites: TestSuiteMetadata[]

    xpathsObj :XpathObjectData [] = [
      {
        id: 1,
        xpath: '//productDetails/subscriptionStartDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id: 2,
        xpath: '//productDetails/subscriptionEndDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:3,
        xpath: '//underlying/initialSettingDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false

      },
      {
        id:4,
        xpath: '//documentDetails/date',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:5,
        xpath: '//productDetails/asOfDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:6,
        xpath: '//productDetails/fromDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:7,
        xpath: '//productDetails/rhpIssueDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:8,
        xpath: '//productDetails/issueDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:9,
        xpath: '//productDetails/tradeDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:10,
        xpath: '//productDetails/underlying/underlyingBacktestingFromDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:11,
        xpath: '//productDetails/underlying/initialSettingDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:12,
        xpath: '//productDetails/initialSettingDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:13,
        xpath: '//productDetails/finalValuationDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:14,
        xpath: '//productDetails/maturityDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:15,
        xpath: '//productDetails/observations/observation/periodStartDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:16,
        xpath: '//productDetails/observations/observation/observationDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:17,
        xpath: '//productDetails/referenceShareIssueDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:18,
        xpath: '//productDetails/referenceShareMaturityDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:19,
        xpath: '//productDetails/referenceShareBoardMeetingDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:20,
        xpath: '//productDetails/finalTermsPublishDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:21,
        xpath: '//productDetails/tradeDateMinus3BusinessDays',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:22,
        xpath: '//unadjustedObservationDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:23,
        xpath: '//productDetails/initialFXDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:24,
        xpath: '//productDetails/finalFXDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:25,
        xpath: '//productDetails/filedOn',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:26,
        xpath: '//startDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:27,
        xpath: '//endDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:28,
        xpath: '//paymentDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:29,
        xpath: '//documentDetails/documentType',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
      {
        id:30,
        xpath: '//productDetails/asianOutObservations/asianObservation/observationDate',
        is_edit: true,
        toggleEdit: 'Edit',
        isChecked: false,
        operation: '',
        increment: true,
        replace: false
      },
    ];

  constructor(private testManagerService: TestManagerService,
    private toast: ToastrService,
    private dialog: MatDialog,
    @Inject(DOCUMENT) private _document: Document
    ) {
    }
  

  ngOnInit() {
    this.subs.add(
      this.testManagerService.testSuites.subscribe(results => {
        this.testSuites = results
      }),
    )
    this.subs.add(
      this.testSuite.subscribe((value: { name: any }) => {
        this.selectedTestSuite = value && value.name

      }),
    )
    this.subs.add(
      this.testManagerService.selectedTestSuite.subscribe(result => {
        if (result) {
          this.testSuite.emit({
            name: result.name,
            tests: result.tests.map(test => test.name),
          })
          this.allTestsInTestSuite = result
        } else {
          this.testSuite.emit(undefined)
        }
      }),
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  reloadData() {
      this.xpathsObj = [...this.xpathsObj];
  }

  refreshPage() {
  
    this._document.defaultView.location.reload();
  

  }

  selectTestSuite($event: MatSelectChange) {

    this.summary = 'Summary';
    this.testsWillBeCoppied = 0;
    this.testsWillbeCloned = 0;
    this.testManagerService.selectTestSuite($event.value)

  }

  isSelected(copyOtherTests?: boolean) {
    this.copyOtherTests = copyOtherTests ?  this.copyOtherTests : !this.copyOtherTests
    this.summaryOfNewTestSuite();
  }

  async cloneTestSuite() {
    const prefix = this.prefix.nativeElement.value;
    const [ suffix ]  = [ this.suffix.nativeElement.value ];
    const [ matchConfig ]  = [ this.matchConfig.nativeElement.value];
    const [ targetConfig ]  = [ this.targetConfig.nativeElement.value ];

    const xpaths : CloneXpaths[] = this.xpathsObj.map( (x) :CloneXpaths => {
      
      return {
        xpath: x.xpath,
        operation: x.replace? "replace" : "dateIncrement"      
      } 
    })

    

    await this.testManagerService.cloneTestSuite(this.selectedTestSuite, prefix, suffix,
    matchConfig,targetConfig,this.copyOtherTests,xpaths)
      .then(res => {
      console.log('saved Test Suite:', `${prefix}${this.selectedTestSuite}${suffix}`);
      this.toast.success(`Cloned Test Suite: ${prefix}${this.selectedTestSuite}${suffix}`)
      console.log(res);
      this.refreshPage();

    })
  }

  summaryOfNewTestSuite () {

    this.summary = 'Summary';
    this.testsWillBeCoppied = 0;
    this.testsWillbeCloned = 0;
    if(this.allTestsInTestSuite) {
      this.allTestsInTestSuite.tests.map(test => {
        const product = test.product as DocgenPayloadInformation
        const configKey = `${product.sourceSystem}/${product.programme}/${product.productType}/${product.documentType}`

        if(configKey.match(this.matchConfig.nativeElement.value)){
        this.testsWillbeCloned++;

        }
        else if(!configKey.match(this.matchConfig.nativeElement.value) && this.copyOtherTests) {
              this.testsWillBeCoppied++;
        }
      })
      this.summary =`${this.summary }
        A new test suite will be created
        ${this.prefix.nativeElement.value}${this.selectedTestSuite}${this.suffix.nativeElement.value}
        ${this.testsWillbeCloned} test will be Cloned
        ${this.testsWillBeCoppied} tests will not be cloned, but will be copied`
    }
  }

  valueOnChange(){
    this.summaryOfNewTestSuite();
  }

  navigateToRegexHelp(){
    window.location.href = 'https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#writing_a_regular_expression_pattern'
  }

  addXpathsToList(newXpathValue: string){
      try {
        document.evaluate(newXpathValue, document, null, XPathResult.ANY_TYPE, null)
      } catch (e) {
        return this.toast.error('Invalid XPath expression', e)
      }

        
      if (!this.xpathsObj.some(xpath => xpath.xpath === newXpathValue)) {
        const lastInsertedId = this.findLastInsertedId()
        
        this.xpathsObj.push({id: lastInsertedId.id+1, xpath: newXpathValue, is_edit: true, toggleEdit: 'Edit',isChecked: false, 
        operation: '', increment: false,
        replace: true
      })
      }
      else {
        this.toast.info('XPath already exists')
      }
    
      this.reloadData();

  }

  public editXPathValueToggle(index: number, xpathValue?: string) {
    
    const findIndex = this.xpathsObj.findIndex(xpath => xpath.xpath === xpathValue);
    
    if(this.xpathsObj[findIndex].toggleEdit == 'Save') {
      this.xpathsObj[findIndex].toggleEdit = 'Edit';
      this.xpathsObj[findIndex].is_edit = true;

    }
    else {
      this.xpathsObj[findIndex].toggleEdit = 'Save';
      this.xpathsObj[findIndex].is_edit = false;

    }
  
  }
 
  deleteXpathVlue(id: number,xpathValue: string){
    const findIndex = this.xpathsObj.findIndex(xpath => xpath.xpath === xpathValue);
    this.xpathsObj.splice(findIndex,1);

  }

  onChangeXpathVlue($event,xpathValue){
    const findIndex = this.xpathsObj.findIndex(xpath => xpath.xpath === xpathValue);
    this.xpathsObj[findIndex].xpath = $event.target.value;


  }


  openDialogToAddNewXpath() {
    const dialogRef = this.dialog.open(NameDialogComponent, {
      width: '500px',
      data: {
        title: 'Add New XPath',
        name: '',
        hint: 'This will add new xpath to the list!'
      },
      panelClass: 'dialogFormField500',
    })
    dialogRef.afterClosed().subscribe(async name => {
      
      if(name !== '' && name != ' ' && name !==  undefined) {
      this.addXpathsToList(name);
      }
    })
  }


  updateOperation(xpathVlue: string,id: number, operation: any) {
    const findIndex = this.xpathsObj.findIndex(xpath => xpath.xpath === xpathVlue);
    const getSelectedXpath = this.xpathsObj[findIndex];

  
    if(operation.value === 'dateIncrement'){

        getSelectedXpath.increment = !getSelectedXpath.increment
      
      if(getSelectedXpath.increment) {
        getSelectedXpath.replace = false;

      }
      if(!getSelectedXpath.increment) {
        getSelectedXpath.replace = true;

      }
    }

    if(operation.value === 'replace') {
      getSelectedXpath.replace =  !getSelectedXpath.replace
      if(getSelectedXpath.replace) {

        getSelectedXpath.increment = false;

      }
      if(!getSelectedXpath.replace) {

        getSelectedXpath.increment = true;

      }
    }
  }

  findLastInsertedId() {
    const id = this.xpathsObj.reduce((previous, current) => (+previous.id > +current.id) ? previous : current)
    return id;
  }

  checkFieldsValid() {
    if (!this.selectedTestSuite || !this.prefix.nativeElement.value && !this.suffix.nativeElement.value ) {
      return true
    }
    
    return false
  }



}