import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core'
import {ExpectedDocument, GenerateDocumentStatus} from '../model/generate-document-status'
import {DiffResponse} from '../services/diff.service'
import {COLOURS} from './colors'
import {IngestProductDocgenTraceResponse} from '../model/public-api-response'
import {SuccessAnalyseResult} from '../common/domain/analyse'

const numeral = require('numeral')

const ENCODINGS = {
  0: 0x3F,
  1: 0x06,
  2: 0x5B,
  3: 0x4F,
  4: 0x66,
  5: 0x6D,
  6: 0x7D,
  7: 0x07,
  8: 0x7F,
  9: 0x6F,
}

@Component({
  selector: 'app-docgen-graphic',
  templateUrl: './docgen-graphic.component.svg',
})
export class DocgenGraphicComponent implements OnInit, OnChanges {
  @Input() width = 1920
  @Input() height = 800
  @Input() timeTaken = 0.0
  @Input() genDocStatus: GenerateDocumentStatus
  @Input() expected: ExpectedDocument
  @Input() pdfDiffResponse: DiffResponse
  @Input() xmlDiffResponse: DiffResponse
  @Input() refresh: string

  tickColors = []
  tickOpacity = []

  lambdas = {
    ingest: {
      colours: COLOURS.UNKNOWN,
    },
    transform: {
      colours: COLOURS.UNKNOWN,
    },
    enrich: {
      colours: COLOURS.UNKNOWN,
    },
    generate: {
      colours: COLOURS.UNKNOWN,
    },
    validatePayload: {
      colours: COLOURS.UNKNOWN,
    },
    analysePayload: {
      colours: COLOURS.UNKNOWN,
    },
    validateEnriched: {
      colours: COLOURS.UNKNOWN,
    },
    analyseEnriched: {
      colours: COLOURS.UNKNOWN,
    },
    generateDiff: {
      colours: COLOURS.UNKNOWN,
    },
    raiseJira: {
      colours: COLOURS.UNKNOWN,
    },
  }

  apigateway = {
    colours: COLOURS.UNKNOWN,
  }

  icons = {
    sparrow: {
      colours: COLOURS.UNKNOWN,
    },
    jira: {
      colours: COLOURS.UNKNOWN,
    },
    document: {
      colours: COLOURS.UNKNOWN,
    },
    expected: {
      colours: COLOURS.UNKNOWN,
    },
    diffReport: {
      colours: COLOURS.UNKNOWN,
    },
    thunderhead: {
      colours: COLOURS.UNKNOWN,
    },
  }

  labels = {
    ingest: {
      colours: COLOURS.UNKNOWN,
    },
    transform: {
      colours: COLOURS.UNKNOWN,
    },
    enrich: {
      colours: COLOURS.UNKNOWN,
    },
    generate: {
      colours: COLOURS.UNKNOWN,
    },
    validate: {
      colours: COLOURS.UNKNOWN,
    },
    analyse: {
      colours: COLOURS.UNKNOWN,
    },
    diffReport: {
      colours: COLOURS.UNKNOWN,
    },
    thunderhead: {
      colours: COLOURS.UNKNOWN,
    },
  }

  lines = {
    apiToIngest: {
      colours: COLOURS.UNKNOWN,
    },
    ingestToTransform: {
      colours: COLOURS.UNKNOWN,
    },
    transformToEnrich: {
      colours: COLOURS.UNKNOWN,
    },
    enrichToGenerate: {
      colours: COLOURS.UNKNOWN,
    },
    generateToDocument: {
      colours: COLOURS.UNKNOWN,
    },
    ingestToAnalyse: {
      colours: COLOURS.UNKNOWN,
    },
    ingestToValidate: {
      colours: COLOURS.UNKNOWN,
    },
    enrichToAnalyse: {
      colours: COLOURS.UNKNOWN,
    },
    enrichToValidate: {
      colours: COLOURS.UNKNOWN,
    },
    documentToDiff: {
      colours: COLOURS.UNKNOWN,
    },
    expectedToDiff: {
      colours: COLOURS.UNKNOWN,
    },
    diffToDiffReport: {
      colours: COLOURS.UNKNOWN,
    },
    jiraToRaiseJira: {
      colours: COLOURS.UNKNOWN,
    },
    apiToSparrow: {
      colours: COLOURS.UNKNOWN,
    },
    thunderheadToSparrow: {
      colours: COLOURS.UNKNOWN,
    },
  }

  led = {
    0: {
      a: 'none',
      b: 'none',
      c: 'none',
      d: 'none',
      e: 'none',
      f: 'none',
      g: 'none',
      p: 'none',
    },
    1: {
      a: 'none',
      b: 'none',
      c: 'none',
      d: 'none',
      e: 'none',
      f: 'none',
      g: 'none',
      p: 'none',
    },
    2: {
      a: 'none',
      b: 'none',
      c: 'none',
      d: 'none',
      e: 'none',
      f: 'none',
      g: 'none',
    },
  }

  stopwatch = {
    opacity: '0.05',
  }

  constructor() {
  }

  ngOnInit() {
  }

  updateTimeTaken() {
    if (this.timeTaken > 0.01) {
      this.stopwatch.opacity = '1.0'
    } else {
      this.stopwatch.opacity = '0.05'
    }
    this.tickColors = []
    this.tickOpacity = []
    for (let i = 0; i < this.timeTaken; i++) {
      this.tickOpacity.push('1.0')
      if (i < 5) {
        this.tickColors.push('green')
      } else if (i < 10) {
        this.tickColors.push('orange')
      } else {
        this.tickColors.push('red')
      }
    }
    for (let i = 0; i < 20; i++) {
      this.tickOpacity.push('0.1')
    }

    const display = numeral(this.timeTaken).format('0.0[0]').slice(0, 4).replace(/\.$/, '')
    // console.log('Display: ' + display)

    let index = 0
    let ledIndex = 0
    let char = display.charAt(0)
    let bits = ENCODINGS[char]
    let currentLed = this.led['' + ledIndex]
    const on =
      this.timeTaken < 5
        ? 'green'
        : this.timeTaken < 10
        ? 'orange'
        : 'red'
    currentLed.a = (bits & (1 << 0)) ? on : 'none'
    currentLed.b = (bits & (1 << 1)) ? on : 'none'
    currentLed.c = (bits & (1 << 2)) ? on : 'none'
    currentLed.d = (bits & (1 << 3)) ? on : 'none'
    currentLed.e = (bits & (1 << 4)) ? on : 'none'
    currentLed.f = (bits & (1 << 5)) ? on : 'none'
    currentLed.g = (bits & (1 << 6)) ? on : 'none'

    index = index + 1
    char = display.charAt(index)
    currentLed.p = (char === '.') ? on : 'none'
    if (char === '.') {
      index = index + 1
      char = display.charAt(index)
    }
    ledIndex = ledIndex + 1
    currentLed = this.led[ledIndex]
    bits = ENCODINGS[char]
    currentLed.a = (bits & (1 << 0)) ? on : 'none'
    currentLed.b = (bits & (1 << 1)) ? on : 'none'
    currentLed.c = (bits & (1 << 2)) ? on : 'none'
    currentLed.d = (bits & (1 << 3)) ? on : 'none'
    currentLed.e = (bits & (1 << 4)) ? on : 'none'
    currentLed.f = (bits & (1 << 5)) ? on : 'none'
    currentLed.g = (bits & (1 << 6)) ? on : 'none'

    index = index + 1
    char = display.charAt(index)
    currentLed.p = (char === '.') ? on : 'none'
    if (char === '.') {
      index = index + 1
      char = display.charAt(index)
    }
    ledIndex = ledIndex + 1
    currentLed = this.led[ledIndex]
    bits = ENCODINGS[char]
    currentLed.a = (bits & (1 << 0)) ? on : 'none'
    currentLed.b = (bits & (1 << 1)) ? on : 'none'
    currentLed.c = (bits & (1 << 2)) ? on : 'none'
    currentLed.d = (bits & (1 << 3)) ? on : 'none'
    currentLed.e = (bits & (1 << 4)) ? on : 'none'
    currentLed.f = (bits & (1 << 5)) ? on : 'none'
    currentLed.g = (bits & (1 << 6)) ? on : 'none'
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('Updating graphic')
    // console.dir(changes)
    try {

      if (!this.genDocStatus) {
        this.reset()
        return
      }
      if (!this.genDocStatus.status) {
        this.reset()
        return
      }

      this.lines.apiToIngest.colours = COLOURS.OK
      this.lambdas.ingest.colours = COLOURS.OK
      this.labels.ingest.colours = COLOURS.OK

      let payloadStatus = 'UNKNOWN'
      let validatePayloadStatus = 'UNKNOWN'
      let enrichedStatus = 'UNKNOWN'
      let validateEnrichedStatus = 'UNKNOWN'

      if (this.genDocStatus.rawResponse) {
        console.dir(this.genDocStatus.rawResponse)
        this.timeTaken = this.genDocStatus.rawResponse.latency / 1000
        this.updateTimeTaken()

        const trace: IngestProductDocgenTraceResponse = this.genDocStatus.rawResponse
        if (trace.config.ingest.supported === 'NO') {
          this.apigateway.colours = COLOURS.BLACKLIST
          this.lines.apiToSparrow.colours = COLOURS.HIDDEN
          this.icons.thunderhead.colours = COLOURS.OK
          this.lines.thunderheadToSparrow.colours = COLOURS.OK
          this.labels.thunderhead.colours = COLOURS.OK
        } else {
          if (this.genDocStatus.rawResponse.documentReturned) {
            this.apigateway.colours = COLOURS.OK
            this.lines.apiToSparrow.colours = COLOURS.OK
            this.icons.sparrow.colours = COLOURS.OK
          } else {
            this.apigateway.colours = COLOURS.ERROR
            this.lines.apiToSparrow.colours = COLOURS.ERROR
            this.icons.sparrow.colours = COLOURS.ERROR

            if (trace.config.ingest.supported === 'MIGRATING') {
              this.icons.thunderhead.colours = COLOURS.WARNING
              this.lines.thunderheadToSparrow.colours = COLOURS.WARNING
              this.labels.thunderhead.colours = COLOURS.WARNING
              this.icons.sparrow.colours = COLOURS.WARNING
            }
          }
        }

        if (this.genDocStatus.rawResponse.sparrowWorkItem) {
          this.icons.sparrow.colours = COLOURS.ERROR
        }

        if (this.genDocStatus.rawResponse.jiraRaised) {
          // TODO - errors or warning?
          this.lambdas.raiseJira.colours = COLOURS.ERROR
          this.lines.jiraToRaiseJira.colours = COLOURS.ERROR
          this.icons.jira.colours = COLOURS.ERROR

        }
      }

      if (this.genDocStatus.analysed) {
        const json: SuccessAnalyseResult = JSON.parse(this.genDocStatus.analysed)
        payloadStatus = json.result
        if (payloadStatus === 'OK') {
          this.lines.ingestToAnalyse.colours = COLOURS.OK
          this.lambdas.analysePayload.colours = COLOURS.OK
        } else if (payloadStatus === 'SKIPPED') {
          this.lines.ingestToAnalyse.colours = COLOURS.SKIPPED
          this.lambdas.analysePayload.colours = COLOURS.SKIPPED
        } else if (payloadStatus === 'ERROR') {
          this.lines.ingestToAnalyse.colours = COLOURS.ERROR
          this.lambdas.analysePayload.colours = COLOURS.ERROR
        } else if (payloadStatus === 'WARNING') {
          this.lines.ingestToAnalyse.colours = COLOURS.WARNING
          this.lambdas.analysePayload.colours = COLOURS.WARNING
        } else {
          this.lines.ingestToAnalyse.colours = COLOURS.UNKNOWN
          this.lambdas.analysePayload.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.ingestToAnalyse.colours = COLOURS.PROCESSING
        this.lambdas.analysePayload.colours = COLOURS.PROCESSING
      }

      if (this.genDocStatus.validated) {
        const json = JSON.parse(this.genDocStatus.validated)
        validatePayloadStatus = json.status
        if (validatePayloadStatus === 'OK') {
          this.lines.ingestToValidate.colours = COLOURS.OK
          this.lambdas.validatePayload.colours = COLOURS.OK
        } else if (validatePayloadStatus === 'SKIPPED') {
          this.lines.ingestToValidate.colours = COLOURS.SKIPPED
          this.lambdas.validatePayload.colours = COLOURS.SKIPPED
        } else if (validatePayloadStatus === 'ERROR') {
          this.lines.ingestToValidate.colours = COLOURS.ERROR
          this.lambdas.validatePayload.colours = COLOURS.ERROR
        } else if (validatePayloadStatus === 'WARNING') {
          this.lines.ingestToValidate.colours = COLOURS.WARNING
          this.lambdas.validatePayload.colours = COLOURS.WARNING
        } else {
          this.lines.ingestToValidate.colours = COLOURS.UNKNOWN
          this.lambdas.validatePayload.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.ingestToValidate.colours = COLOURS.PROCESSING
        this.lambdas.validatePayload.colours = COLOURS.PROCESSING
      }

      if (this.genDocStatus.analysed && this.genDocStatus.enrichedAnalysed) {
        const payloadJson: SuccessAnalyseResult = JSON.parse(this.genDocStatus.analysed)
        payloadStatus = payloadJson.result
        const enrichedJson: SuccessAnalyseResult = JSON.parse(this.genDocStatus.enrichedAnalysed)
        enrichedStatus = enrichedJson.result
        if (payloadStatus === 'ERROR' || enrichedStatus === 'ERROR') {
          // TODO - Blacklist || Not Support Yet?
          this.labels.analyse.colours = COLOURS.ERROR
        } else if (payloadStatus === 'WARNING' || enrichedStatus === 'WARNING') {
          this.labels.analyse.colours = COLOURS.WARNING
        } else if (payloadStatus === 'OK' || enrichedStatus === 'OK') {
          // TODO - Whitelist?
          this.labels.analyse.colours = COLOURS.OK
        } else if (payloadStatus === 'SKIPPED' && enrichedStatus === 'SKIPPED') {
          this.labels.analyse.colours = COLOURS.SKIPPED
        }
      }

      if (this.genDocStatus.validated && this.genDocStatus.enrichedValidated) {
        const payloadJson = JSON.parse(this.genDocStatus.validated)
        payloadStatus = payloadJson.status
        const enrichedJson = JSON.parse(this.genDocStatus.enrichedValidated)
        enrichedStatus = enrichedJson.status
        if (payloadStatus === 'ERROR' || enrichedStatus === 'ERROR') {
          this.labels.validate.colours = COLOURS.ERROR
        } else if (payloadStatus === 'WARNING' || enrichedStatus === 'WARNING') {
          this.labels.validate.colours = COLOURS.WARNING
        } else if (payloadStatus === 'OK' || enrichedStatus === 'OK') {
          this.labels.validate.colours = COLOURS.OK
        } else if (payloadStatus === 'SKIPPED' && enrichedStatus === 'SKIPPED') {
          this.labels.validate.colours = COLOURS.SKIPPED
        }
      }

      if (this.genDocStatus.enrichedAnalysed) {
        const json: SuccessAnalyseResult = JSON.parse(this.genDocStatus.enrichedAnalysed)
        enrichedStatus = json.result
        if (enrichedStatus === 'OK') {
          this.lines.enrichToAnalyse.colours = COLOURS.OK
          this.lambdas.analyseEnriched.colours = COLOURS.OK
        } else if (enrichedStatus === 'SKIPPED') {
          this.lines.enrichToAnalyse.colours = COLOURS.SKIPPED
          this.lambdas.analyseEnriched.colours = COLOURS.SKIPPED
        } else if (enrichedStatus === 'ERROR') {
          this.lines.enrichToAnalyse.colours = COLOURS.ERROR
          this.lambdas.analyseEnriched.colours = COLOURS.ERROR
        } else if (enrichedStatus === 'WARNING') {
          this.lines.enrichToAnalyse.colours = COLOURS.WARNING
          this.lambdas.analyseEnriched.colours = COLOURS.WARNING
        } else {
          this.lines.enrichToAnalyse.colours = COLOURS.UNKNOWN
          this.lambdas.analyseEnriched.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.enrichToAnalyse.colours = COLOURS.PROCESSING
        this.lambdas.analyseEnriched.colours = COLOURS.PROCESSING
      }

      if (this.genDocStatus.enrichedValidated) {
        const json = JSON.parse(this.genDocStatus.enrichedValidated)
        validateEnrichedStatus = json.status
        if (validateEnrichedStatus === 'OK') {
          this.lines.enrichToValidate.colours = COLOURS.OK
          this.lambdas.validateEnriched.colours = COLOURS.OK
        } else if (validateEnrichedStatus === 'SKIPPED') {
          this.lines.enrichToValidate.colours = COLOURS.SKIPPED
          this.lambdas.validateEnriched.colours = COLOURS.SKIPPED
        } else if (validateEnrichedStatus === 'ERROR') {
          this.lines.enrichToValidate.colours = COLOURS.ERROR
          this.lambdas.validateEnriched.colours = COLOURS.ERROR
        } else if (validateEnrichedStatus === 'WARNING') {
          this.lines.enrichToValidate.colours = COLOURS.WARNING
          this.lambdas.validateEnriched.colours = COLOURS.WARNING
        } else {
          this.lines.enrichToValidate.colours = COLOURS.UNKNOWN
          this.lambdas.validateEnriched.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.enrichToValidate.colours = COLOURS.PROCESSING
        this.lambdas.validateEnriched.colours = COLOURS.PROCESSING
      }

      if (this.genDocStatus.transformed) {
        this.lines.ingestToTransform.colours = COLOURS.OK
        this.lambdas.transform.colours = COLOURS.OK
        this.labels.transform.colours = COLOURS.OK

        if (this.genDocStatus.enriched) {
          this.lines.transformToEnrich.colours = COLOURS.OK
          this.lambdas.enrich.colours = COLOURS.OK
          this.labels.enrich.colours = COLOURS.OK

          if (this.genDocStatus.smartDxMessages && this.genDocStatus.smartDxMessages.length > 0) {
            this.lines.enrichToGenerate.colours = COLOURS.OK
            this.labels.generate.colours = COLOURS.ERROR
            this.lambdas.generate.colours = COLOURS.ERROR
            this.lines.generateToDocument.colours = COLOURS.ERROR
            this.icons.document.colours = COLOURS.ERROR
          } else if (this.genDocStatus.base64Document) {
            this.lines.enrichToGenerate.colours = COLOURS.OK
            this.lambdas.generate.colours = COLOURS.OK
            this.labels.generate.colours = COLOURS.OK
            this.lines.generateToDocument.colours = COLOURS.OK
            this.icons.document.colours = COLOURS.OK
          } else {
            this.lines.enrichToGenerate.colours = COLOURS.PROCESSING
            this.lambdas.generate.colours = COLOURS.PROCESSING
            this.labels.generate.colours = COLOURS.PROCESSING
            this.lines.generateToDocument.colours = COLOURS.PROCESSING
            this.icons.document.colours = COLOURS.UNKNOWN
          }

          if (this.genDocStatus.generatingDiff && this.expected) {
            this.lines.documentToDiff.colours = COLOURS.OK
            this.lines.expectedToDiff.colours = COLOURS.OK
            this.lambdas.generateDiff.colours = COLOURS.PROCESSING
            this.lines.diffToDiffReport.colours = COLOURS.UNKNOWN
            this.icons.expected.colours = COLOURS.OK
            this.icons.diffReport.colours = COLOURS.UNKNOWN
            this.labels.diffReport.colours = COLOURS.UNKNOWN
          } else if (this.genDocStatus.base64Document && this.expected && this.expected.base64) {
            this.icons.expected.colours = COLOURS.OK
            this.lines.documentToDiff.colours = COLOURS.OK
            this.lines.expectedToDiff.colours = COLOURS.OK
            if (this.genDocStatus.generatingDiff) {
              this.lines.documentToDiff.colours = COLOURS.PROCESSING
              this.lines.expectedToDiff.colours = COLOURS.PROCESSING
              this.lambdas.generateDiff.colours = COLOURS.PROCESSING
              this.lines.diffToDiffReport.colours = COLOURS.UNKNOWN
              this.icons.diffReport.colours = COLOURS.UNKNOWN
              this.labels.diffReport.colours = COLOURS.UNKNOWN
            } else {
              if (this.pdfDiffResponse && this.pdfDiffResponse.differences) {
                this.lines.documentToDiff.colours = COLOURS.OK
                this.lines.expectedToDiff.colours = COLOURS.OK
                this.lambdas.generateDiff.colours = COLOURS.ERROR
                this.lines.diffToDiffReport.colours = COLOURS.ERROR
                this.icons.diffReport.colours = COLOURS.ERROR
                this.labels.diffReport.colours = COLOURS.ERROR
              } else if (this.xmlDiffResponse && this.xmlDiffResponse.differences) {
                this.lambdas.generateDiff.colours = COLOURS.WARNING
                this.lines.diffToDiffReport.colours = COLOURS.WARNING
                this.icons.diffReport.colours = COLOURS.WARNING
                this.labels.diffReport.colours = COLOURS.WARNING
              } else {
                this.lambdas.generateDiff.colours = COLOURS.OK
                this.lines.diffToDiffReport.colours = COLOURS.OK
                this.icons.diffReport.colours = COLOURS.OK
                this.labels.diffReport.colours = COLOURS.OK
              }
            }
          }
        } else {
          this.lines.transformToEnrich.colours = COLOURS.PROCESSING
          this.lambdas.enrich.colours = COLOURS.PROCESSING
          this.labels.enrich.colours = COLOURS.PROCESSING
        }
      } else {
        this.lines.ingestToTransform.colours = COLOURS.PROCESSING
        this.lambdas.transform.colours = COLOURS.PROCESSING
        this.labels.transform.colours = COLOURS.PROCESSING
      }
    } catch (e) {
      console.log('Failed to update graphic!')
      console.dir(e)
    }
  }

  reset() {
    this.timeTaken = 0.0
    this.updateTimeTaken()

    this.lambdas = {
      ingest: {
        colours: COLOURS.UNKNOWN,
      },
      transform: {
        colours: COLOURS.UNKNOWN,
      },
      enrich: {
        colours: COLOURS.UNKNOWN,
      },
      generate: {
        colours: COLOURS.UNKNOWN,
      },
      validatePayload: {
        colours: COLOURS.UNKNOWN,
      },
      analysePayload: {
        colours: COLOURS.UNKNOWN,
      },
      validateEnriched: {
        colours: COLOURS.UNKNOWN,
      },
      analyseEnriched: {
        colours: COLOURS.UNKNOWN,
      },
      generateDiff: {
        colours: COLOURS.UNKNOWN,
      },
      raiseJira: {
        colours: COLOURS.UNKNOWN,
      },
    }

    this.apigateway = {
      colours: COLOURS.UNKNOWN,
    }

    this.icons = {
      sparrow: {
        colours: COLOURS.UNKNOWN,
      },
      jira: {
        colours: COLOURS.UNKNOWN,
      },
      document: {
        colours: COLOURS.UNKNOWN,
      },
      expected: {
        colours: COLOURS.UNKNOWN,
      },
      diffReport: {
        colours: COLOURS.UNKNOWN,
      },
      thunderhead: {
        colours: COLOURS.UNKNOWN,
      },
    }

    this.labels = {
      ingest: {
        colours: COLOURS.UNKNOWN,
      },
      transform: {
        colours: COLOURS.UNKNOWN,
      },
      enrich: {
        colours: COLOURS.UNKNOWN,
      },
      generate: {
        colours: COLOURS.UNKNOWN,
      },
      validate: {
        colours: COLOURS.UNKNOWN,
      },
      analyse: {
        colours: COLOURS.UNKNOWN,
      },
      diffReport: {
        colours: COLOURS.UNKNOWN,
      },
      thunderhead: {
        colours: COLOURS.UNKNOWN,
      },
    }

    this.lines = {
      apiToIngest: {
        colours: COLOURS.UNKNOWN,
      },
      ingestToTransform: {
        colours: COLOURS.UNKNOWN,
      },
      transformToEnrich: {
        colours: COLOURS.UNKNOWN,
      },
      enrichToGenerate: {
        colours: COLOURS.UNKNOWN,
      },
      generateToDocument: {
        colours: COLOURS.UNKNOWN,
      },
      ingestToAnalyse: {
        colours: COLOURS.UNKNOWN,
      },
      ingestToValidate: {
        colours: COLOURS.UNKNOWN,
      },
      enrichToAnalyse: {
        colours: COLOURS.UNKNOWN,
      },
      enrichToValidate: {
        colours: COLOURS.UNKNOWN,
      },
      documentToDiff: {
        colours: COLOURS.UNKNOWN,
      },
      expectedToDiff: {
        colours: COLOURS.UNKNOWN,
      },
      diffToDiffReport: {
        colours: COLOURS.UNKNOWN,
      },
      jiraToRaiseJira: {
        colours: COLOURS.UNKNOWN,
      },
      apiToSparrow: {
        colours: COLOURS.UNKNOWN,
      },
      thunderheadToSparrow: {
        colours: COLOURS.UNKNOWN,
      },
    }
  }
}
