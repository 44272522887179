import {Component, Input, OnInit} from '@angular/core'
import {AnalyseRuleTable, AnalyseRuleTableResult, ComplexRuleResults} from '../common/domain/analyse'
import {LambdaStatus} from '../common/domain/lambdas'
import {AnalyseUtilResponse} from '../model/analyse-util-response'

export interface TableResult extends AnalyseRuleTableResult {
  status: string
}

export interface IgnoredTableResult extends AnalyseRuleTable {
  status: string
}

@Component({
  selector: 'app-analysis-view',
  templateUrl: './analysis-view.component.html',
  styleUrls: ['./analysis-view.component.scss'],
})
export class AnalysisViewComponent implements OnInit {

  constructor() {
  }

  @Input()
  set analysisResult(data: AnalyseUtilResponse | string) {
    console.log(data)
    if (!data) {
      this.analysisReport = undefined
      return
    }
    if (typeof data === 'object') {
      this.analysisReport = data
    } else {
      this.analysisReport = JSON.parse(data) as AnalyseUtilResponse
    }
    if (this.analysisReport) {
      this.analysisReport.rules = this.analysisReport.rules || []

      this.tables = this.analysisReport.rules.map((table: AnalyseRuleTableResult) => {
        const result: TableResult = Object.assign({}, table, {status: ''})

        if(table.ignored) result.status = 'IGNORED'
        else {
          if (table.ruleType === 'blacklist' || table.ruleType === 'notSupported') {
            result.status = table.matched ? 'ERROR' : 'OK'
          }
          if (table.ruleType === 'validation') {
            result.status = table.matched ? 'WARNING' : 'OK'
          }
          if (table.ruleType === 'whitelist') {
            result.status = table.matched ? 'OK' : 'ERROR'
          }
        }
        
        if(table.ruleType === 'complex') {
          result.status = table.matched ? 'OK' : 'ERROR'
          const linkedRuleResults = table.linkedRuleResults.map((combination: ComplexRuleResults) => {
            if (combination.matched) {
              const comb = combination.combination 
              combination.combination = [comb['mnemonic'],...comb['combos']]

              const clonedComplexRule = Object.assign({}, combination, {status: 'OK'})

              const updatedCombinationResults = clonedComplexRule.combinationResults.map(result => {
                return Object.assign({}, result, {status: result.matched ? 'OK' : 'NO MATCH'})
              })
              clonedComplexRule.combinationResults = updatedCombinationResults
              return clonedComplexRule
            }
          }).filter(x => x)
          result.linkedRuleResults = linkedRuleResults
        }
        return result
      })
    }
  }
  analysisReport: AnalyseUtilResponse
  ignoredRulesReport: AnalyseRuleTable[]
  tables: TableResult[]
  ignoredRulesTable: IgnoredTableResult[]

  @Input()
  set ignoredRules(ignoredRules: AnalyseRuleTable[] | string) {
    if (!ignoredRules) {
      return
    }
    if (typeof ignoredRules === 'object') {
      this.ignoredRulesReport = ignoredRules
    } else {
      this.ignoredRulesReport = JSON.parse(ignoredRules) as AnalyseRuleTable[]
    }
    this.ignoredRulesTable = this.ignoredRulesReport.map((ignoredRule: AnalyseRuleTable) => {
      const result: IgnoredTableResult = Object.assign({}, ignoredRule, {status: 'Ignored'})
      return result
    })
  }

  /** Indicates which particular stage of analysis is currently in scope. Injected by the parent component. */
  @Input() analysisStage: AnalyseUtilResponse = undefined

  @Input() analysisStatus: LambdaStatus = undefined

  /** Indicates which configuration is in scope for the analysis.  Injected by the parent component. */
  @Input() configKey: string = undefined

  @Input() test: string = undefined

  @Input() governanceMode = false

  ngOnInit() {
  }
}
