<app-config-workspace-buttons></app-config-workspace-buttons>

<h2>SmartDX Templates</h2>

<ng-container>
  <input id="uploadTemplate" type="file" class="inputfile" (change)="uploadTemplate($event)"/>
  <label for="uploadTemplate" class="mat-raised-button mat-accent"><span
    class="mat-button-wrapper">Upload New Template</span></label>
</ng-container>

<table class="table table-borderless">
  <thead>
  <tr>
    <th>Name</th>
    <th>State</th>
    <th>Actions</th>
  </tr>

  </thead>
  <tr *ngFor="let config of templates">
    <td>{{config.name}}</td>
    <td>{{getDisplayState(config)}}</td>
    <td>
      <button mat-raised-button color="accent" (click)="restore(config)"
              [disabled]="config.state === 'original'">Restore
      </button>
      <button mat-raised-button color="warn" (click)="delete(config)"
              [disabled]="config.state === 'new' || config.state === 'toDelete'">Delete
      </button>
    </td>
  </tr>
</table>
