import {Component, OnDestroy, OnInit} from '@angular/core'
import {StatusService} from '../services/status.service'
import {PayloadInspectorService} from '../services/payload-inspector.service'
import {TestManagerService} from '../services/test-manager.service'
import {InspectTestResult, XPath} from '../common/domain/payload-inspector'
import {Subscription} from 'rxjs'
import {InspectTestsResponse, InspectTestSuiteResponse} from '../model/payload-inspector-responses'
import {saveAs} from 'file-saver'
import {MatSelectChange} from '@angular/material/select'
import { unparse } from 'papaparse'
const lodash = require('lodash')
// const stringify = require('csv-stringify/lib/sync')

interface XpathDisplay extends XPath {
  id: number
}

interface XpathSelection {
  name: string
  paths: XpathDisplay[]
}

class Foo {
  result: XpathDisplay[] = []

  withPath(name: string, xpath: string): Foo {
    this.result.push({
      id: this.result.length + 1,
      name: name,
      xpath: xpath,
      count: false,
    })
    return this
  }

  withCount(name: string, xpath: string): Foo {
    this.result.push({
      id: this.result.length + 1,
      name: name,
      xpath: xpath,
      count: true,
    })
    return this
  }
}

@Component({
  selector: 'app-inspect',
  templateUrl: './inspect.component.html',
  styleUrls: ['./inspect.component.scss'],
})
export class InspectComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  selectedTestSuite: string | undefined
  availableTests: string[]

  editField: string
  xpathSelections: XpathSelection[] = []
  selectedXpaths: XpathSelection
  xpaths: XpathDisplay[] = []
  originals = false

  constructor(
    private statusService: StatusService,
    private testManager: TestManagerService,
    private payloadInspectorService: PayloadInspectorService,
  ) {
    this.xpathSelections = [
      {
        name: 'Simple',
        paths: new Foo()
          .withPath('sparrowRef', '//productDetails/ref')
          .withPath('sparrowVersion', '//productDetails/version')
          .withPath('issuer', '//productDetails/issuer')
          .withPath('program', '//productDetails/program')
          .withPath('productWrapper', '//productDetails/productWrapper')
          .withPath('productFamily', '//productDetails/family')
          .withPath('productType', '//productDetails/productType')
          .withPath('documentType', '//documentDetails/documentType')
          .withPath('retailOrInstitutional', '//productDetails/retailOrInstitutional')
          .result,
      },
      {
        name: 'Product Information',
        paths: new Foo()
          .withPath('sparrowRef', '//productDetails/ref')
          .withPath('sparrowVersion', '//productDetails/version')
          .withPath('issueDate', '//productDetails/issueDate')
          .withPath('salesDesk', '//productDetails/salesDesk')
          .withPath('retailOrInstitutional', '//productDetails/retailOrInstitutional')
          .withPath('documentType', '//documentDetails/documentType')
          .withPath('language', '//documentDetails/language')
          .withPath('isIndicative', '//documentDetails/isIndicative')
          .withPath('isSecondaryMarket', '//documentDetails/isSecondaryMarket')
          .withPath('isUpdated', '//documentDetails/isUpdated')
          .withPath('productType', '//productDetails/productType')
          .withPath('productFamily', '//productDetails/family')
          .withPath('priceType', '//productDetails/priceType')
          .withPath('program', '//productDetails/program')
          .withPath('offerForm', '//productDetails/offerForm')
          .withPath('issuer', '//productDetails/issuer')
          .withPath('issuerBranch', '//productDetails/issuerBranch')
          .withPath('delivery', '//productDetails/delivery')
          .withPath('securityForm', '//productDetails/securityForm')
          .withPath('productWrapper', '//productDetails/productWrapper')
          .withPath('countriesOfOffer', '//productDetails/countriesOfOffer')
          .withPath('distribution', '//productDetails/distribution')
          .withPath('assetClass', '//productDetails/assetClass')
          .withPath('performanceType', '//productDetails/performanceType')
          .withPath('settlementType', '//productDetails/settlementType')
          .withPath('observationPeriodFrequency', '//productDetails/observationPeriodFrequency')
          .withPath('couponBarrier', '//productDetails/couponBarrier')
          .withPath('couponFrequency', '//productDetails/couponFrequency')
          .withPath('memoryFeature', '//productDetails/memoryFeature')
          .withPath('couponRateIndicativeFeature', '//productDetails/couponRateIndicativeFeature')
          .withPath('knockInObservationFrequency', '//productDetails/knockInObservationFrequency')
          .result,
      },
      {
        name: 'Comprehensive',
        paths: new Foo()
          .withPath('environment', '//documentDetails/environment')
          .withPath('sparrowRef', '//productDetails/ref')
          .withPath('sparrowVersion', '//productDetails/version')
          .withPath('issueDate', '//productDetails/issueDate')
          .withPath('salesDesk', '//productDetails/salesDesk')
          .withPath('retailOrInstitutional', '//productDetails/retailOrInstitutional')
          .withPath('documentType', '//documentDetails/documentType')
          .withPath('language', '//documentDetails/language')
          .withPath('isIndicative', '//documentDetails/isIndicative')
          .withPath('isSecondaryMarket', '//documentDetails/isSecondaryMarket')
          .withPath('isUpdated', '//documentDetails/isUpdated')
          .withPath('clientName', '//clientDetails/client/clientName')
          .withPath('clientSalesDesks', '//clientDetails/client/clientSalesDesks')
          .withPath('productType', '//productDetails/productType')
          .withPath('productState', '//productDetails/state')
          .withPath('subscription', '//productDetails/subscription')
          .withPath('subscriptionEndIntraday', '//productDetails/subscriptionEndIntraday')
          .withPath('subscriptionEndTime', '//productDetails/subscriptionEndTime')
          .withPath('productFamily', '//productDetails/family')
          .withPath('priceType', '//productDetails/priceType')
          .withPath('program', '//productDetails/program')
          .withPath('issueSizeBounding', '//productDetails/issueSizeBounding')
          .withPath('offerForm', '//productDetails/offerForm')
          .withPath('calculationAgent', '//productDetails/calculationAgent')
          .withPath('clearing', '//productDetails/clearing')
          .withPath('issuer', '//productDetails/issuer')
          .withPath('issuerBranch', '//productDetails/issuerBranch')
          .withPath('leadManager', '//productDetails/leadManager')
          .withPath('payingAgent', '//productDetails/payingAgent')
          .withPath('delivery', '//productDetails/delivery')
          .withPath('governingLaw', '//productDetails/governingLaw')
          .withPath('securityForm', '//productDetails/securityForm')
          .withPath('productWrapper', '//productDetails/productWrapper')
          .withPath('countriesOfOffer', '//productDetails/countriesOfOffer')
          .withPath('distribution', '//productDetails/distribution')
          .withPath('counterpartyName', '//productDetails/counterparties/counterparty/counterpartyName')
          .withPath('counterpartySalesDesks', '//productDetails/counterparties/counterparty/counterpartySalesDesks')
          .withPath('agio', '//productDetails/agio')
          .withPath('custodianFee', '//productDetails/custodianFee')
          .withPath('discount', '//productDetails/discount')
          .withPath('introductoryFees', '//productDetails/introductoryFees')
          .withPath('runningFees', '//productDetails/runningFees')
          .withPath('upfrontFees', '//productDetails/upfrontFees')
          .withPath('approvedNonDisclosure', '//productDetails/approvedNonDisclosure')
          .withPath('assetClass', '//productDetails/assetClass')
          .withPath('initialSettingTime', '//productDetails/initialSettingTime')
          .withPath('initialSettingIntraday', '//productDetails/initialSettingIntraday')
          .withPath('underlyingMultiExchange', '//productDetails/underlyings/underlying/underlyingMultiExchange')
          .withCount('underlyingCount', '//productDetails/underlyings/underlying/underlyingName')
          .withPath('underlyingType', '//productDetails/underlyings/underlying/underlyingType')
          .withPath('underlyingName', '//productDetails/underlyings/underlying/underlyingName')
          .withPath('underlyingReutersCode', '//productDetails/underlyings/underlying/underlyingReutersCode')
          .withPath('underlyingInitialPrice', '//productDetails/underlyings/underlying/initialPrice')
          .withPath('performanceType', '//productDetails/performanceType')
          .withPath('settlementType', '//productDetails/settlementType')
          .withPath('observationPeriodFrequency', '//productDetails/observationPeriodFrequency')
          .withCount('observationCount', '//productDetails/observations/observation')
          .withPath('observationCallable', '//productDetails/observations/observation/callable')
          .withPath('couponBarrier', '//productDetails/couponBarrier')
          .withPath('couponBarrierObservationFrequency', '//productDetails/couponBarrierObservationFrequency')
          .withPath('couponFrequency', '//productDetails/couponFrequency')
          .withPath('memoryFeature', '//productDetails/memoryFeature')
          .withPath('couponRateIndicativeFeature', '//productDetails/couponRateIndicativeFeature')
          .withPath('knockInObservationFrequency', '//productDetails/knockInObservationFrequency')
          .withPath('listingExchange', '//productDetails/listingExchange')
          .result,
      },
    ]

    this.selectedXpaths = this.xpathSelections[0]
    this.xpaths = this.selectedXpaths.paths
  }

  async ngOnInit() {
    this.testManager.setService('docgen')
    this.sub.add(
      this.testManager.selectedTestSuite.subscribe(suite => {
        this.selectedTestSuite = suite && suite.name
      }),
    )
    this.sub.add(
      this.testManager.tests.subscribe(tests => {
        this.availableTests = tests && tests.map(t => t.name) || []
      }),
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  inspect() {
    this.payloadInspectorService.inspect(this.selectedTestSuite, this.xpaths, this.originals)
      .subscribe((results: InspectTestSuiteResponse) => {
        // console.log(JSON.stringify(results, undefined, 2))
        const statusId = this.statusService.start('Creating CSV')

        try {
          const csvData = results.results.map(testResult => {
            if (testResult.xpathResults) {
              const res = {
                testName: testResult.testName,
                testDate: testResult.testDate,
                testVersion: testResult.version,
              }
              testResult.xpathResults.forEach(xr => {
                res[xr.name] = xr.value
              })
              return res
            } else {
              return undefined
            }
          }).filter(x => x)

          const foo = unparse(csvData, {
            header: true,
          })

          const blob = new Blob([foo], {type: 'text/csv;charset=utf-8'})
          saveAs(blob, results.testSuite + '-analysis' + (this.originals ? '-originals' : '') + '.csv')
        } finally {
          this.statusService.complete(statusId)
        }
      })
  }

  async inspectAll() {
    const batches = lodash.chunk(this.availableTests, 250)
    let results: InspectTestResult[] = []
    let batch = 0
    for (const current of batches) {
      batch = batch + 1
      const status = this.statusService.start('Inspecting batch ' + batch + ' of ' + batches.length)
      try {
        await this.payloadInspectorService.inspectTests(current, this.xpaths)
          .toPromise()
          .then((batchResults: InspectTestsResponse) => {
            results = results.concat(batchResults.results)
          })
      } finally {
        this.statusService.complete(status)
      }
    }

    console.log(JSON.stringify(results, undefined, 2))
    const statusId = this.statusService.start('Creating CSV')

    try {
      const csvData = results.map(testResult => {
        if (testResult.xpathResults) {
          const res = {
            testName: testResult.testName,
            testDate: testResult.testDate,
            testVersion: testResult.version,
          }
          testResult.xpathResults.forEach(xr => {
            res[xr.name] = xr.value
          })
          return res
        } else {
          return undefined
        }
      }).filter(x => x)

      const foo = unparse(csvData, {
        header: true,
      })

      const blob = new Blob([foo], {type: 'text/csv;charset=utf-8'})
      saveAs(blob, 'tests-analysis.csv')
    } finally {
      this.statusService.complete(statusId)
    }
  }

  updateList(id: number, property: string, event: any) {
    const editField = event.target.textContent
    this.xpaths[id][property] = editField
  }

  remove(id: any) {
    this.xpaths.splice(id, 1)
  }

  add() {
    this.xpaths.push({
      id: this.xpaths.length + 1,
      name: 'name',
      xpath: '//xpath',
      count: false,
    })
  }

  changeValue(id: number, property: string, event: any) {
    this.editField = event.target.textContent
  }

  toggleValue(id: number, property: string) {
    this.xpaths[id][property] = !this.xpaths[id][property]
  }

  toggleOriginals(checked: boolean) {
    this.originals = checked
  }

  selectPaths(selected: MatSelectChange) {
    this.selectedXpaths = selected.value
    this.xpaths = this.selectedXpaths.paths
  }
}
