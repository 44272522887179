import {Component, OnDestroy, OnInit} from '@angular/core'
import {ChartDataSets, ChartOptions} from 'chart.js'
import {DateTime as LuxonDateTime} from 'luxon'
import {Color} from 'ng2-charts'
import {Subscription} from 'rxjs'
import {DashboardDatapoints, DashboardMetricsService} from '../services/dashboard-metrics.service'
import {ThemeService} from '../services/theme.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  public documentsStatisticsChartData: ChartDataSets[] = [{data: []}]
  public documentsStatisticsOptions: (ChartOptions & {annotation: any}) = {
    responsive: true,
    annotation: {},
    scales: {
      xAxes: [
        {
          type: 'time',
          ticks: {
            source: 'auto',
          },
          bounds: 'ticks',
          distribution: 'linear',
          time: {
            unit: 'day',
            displayFormats: {
              hour: 'DD/MM',
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            source: 'auto',
            min: 0,
            fontColor: 'white',
          },
          bounds: 'auto',
          position: 'left',
          id: 'latencyScale',
          scaleLabel: {
            labelString: 'Latency',
            fontColor: 'rgba(255,255,255,0.7)',
            fontSize: 14,
          },
        },
        {
          ticks: {
            source: 'auto',
            min: 0,
            fontColor: 'white',
          },
          bounds: 'auto',
          position: 'right',
          id: 'countScale',
          scaleLabel: {
            labelString: 'Count',
            fontColor: 'rgba(255,255,255,0.7)',
            fontSize: 14,
          },
        },
      ],
    },
  }
  public documentsStatisticsChartColors: Color[] = [
    {
      borderColor: 'red',
      backgroundColor: 'rgba(255,255,255,0.0)',
    },
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,255,255,0.4)',
    },
  ]

  public testsExecutedChartData: ChartDataSets[] = [{data: []}]
  public testsExecutedOptions: (ChartOptions & {annotation: any}) = {
    responsive: true,
    annotation: {},
    scales: {
      xAxes: [
        {
          type: 'time',
          ticks: {
            source: 'auto',
          },
          bounds: 'ticks',
          distribution: 'linear',
          time: {
            unit: 'day',
            displayFormats: {
              hour: 'DD/MM',
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            source: 'auto',
            min: 0,
            fontColor: 'white',
          },
          bounds: 'auto',
          position: 'left',
          id: 'countScale',
          scaleLabel: {
            labelString: 'Count',
            fontColor: 'rgba(128,255,128,0.7)',
            fontSize: 14,
          },
        },
      ],
    },
  }
  public testsExecutedChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(128,255,128,0.5)',
    },
  ]

  public documentsDetailsChartData: ChartDataSets[] = [{data: []}]
  public documentsDetailsOptions: (ChartOptions & {annotation: any}) = {
    responsive: true,
    annotation: {},
    scales: {
      xAxes: [
        {
          type: 'time',
          ticks: {
            source: 'auto',
          },
          bounds: 'ticks',
          distribution: 'linear',
          time: {
            unit: 'day',
            displayFormats: {
              hour: 'DD/MM',
            },
          },
        },
      ],
      yAxes: [
        // {
        //   ticks: {
        //     source: 'auto',
        //     min: 0,
        //     fontColor: 'white',
        //   },
        //   bounds: 'auto',
        //   position: 'left',
        //   id: 'latencyScale',
        //   scaleLabel: {
        //     labelString: 'Latency',
        //     fontColor: 'rgba(255,255,255,0.7)',
        //     fontSize: 14,
        //   },
        // },
        {
          ticks: {
            source: 'auto',
            min: 0,
            fontColor: 'white',
          },
          bounds: 'auto',
          // position: 'right',
          position: 'left',
          id: 'durationScale',
          stacked: true,
          scaleLabel: {
            labelString: 'Time Taken',
            fontColor: 'rgba(255,255,255,0.7)',
            fontSize: 14,
          },
        },
      ],
    },
  }
  public documentsDetailsChartColors: Color[] = [
    // {
    //   borderColor: 'red',
    //   backgroundColor: 'rgba(255,255,255,0.0)',
    // },
    {
      borderColor: 'darkgreen',
      backgroundColor: 'rgba(0,255,0,0.4)',
    },
    {
      borderColor: 'red',
      backgroundColor: 'rgba(255,0,0,0.4)',
    },
    {
      borderColor: 'white',
      backgroundColor: 'rgba(255,255,255,0.4)',
    },
    {
      borderColor: 'darkblue',
      backgroundColor: 'rgba(0,0,255,0.4)',
    },
  ]

  constructor(
    private dashboardMetricsService: DashboardMetricsService,
    private themeService: ThemeService,
  ) {
    this.themeService.theme.subscribe(theme => {
      if (theme === 'Clean') {
        this.documentsDetailsChartColors[2] = {
          borderColor: 'darkgrey',
          backgroundColor: 'rgba(0,0,0,0.3)',
        }
        this.documentsStatisticsOptions.scales.yAxes[0].scaleLabel.fontColor = 'rgba(0,0,0,0.7)'
        this.documentsStatisticsOptions.scales.yAxes[0].ticks.fontColor = 'black'
        this.documentsStatisticsOptions.scales.yAxes[1].scaleLabel.fontColor = 'rgba(0,0,0,0.7)'
        this.documentsStatisticsOptions.scales.yAxes[1].ticks.fontColor = 'black'
        this.testsExecutedOptions.scales.yAxes[0].scaleLabel.fontColor = 'rgba(0,0,0,0.7)'
        this.testsExecutedOptions.scales.yAxes[0].ticks.fontColor = 'black'
        this.documentsDetailsOptions.scales.yAxes[0].scaleLabel.fontColor = 'rgba(0,0,0,0.7)'
        this.documentsDetailsOptions.scales.yAxes[0].ticks.fontColor = 'black'
        this.documentsStatisticsChartColors = [
          {
            borderColor: 'red',
            backgroundColor: 'rgba(0,0,0,0.0)',
          },
          {
            borderColor: 'black',
            backgroundColor: 'rgba(0,0,0,0.4)',
          },
        ]
      } else {
        this.documentsDetailsChartColors[2] = {
          borderColor: 'white',
          backgroundColor: 'rgba(255,255,255,0.4)',
        }
        this.documentsStatisticsOptions.scales.yAxes[0].scaleLabel.fontColor = 'rgba(255,255,255,0.7)'
        this.documentsStatisticsOptions.scales.yAxes[0].ticks.fontColor = 'white'
        this.documentsStatisticsOptions.scales.yAxes[1].scaleLabel.fontColor = 'rgba(255,255,255,0.7)'
        this.documentsStatisticsOptions.scales.yAxes[1].ticks.fontColor = 'white'
        this.testsExecutedOptions.scales.yAxes[0].scaleLabel.fontColor = 'rgba(255,255,255,0.7)'
        this.testsExecutedOptions.scales.yAxes[0].ticks.fontColor = 'white'
        this.documentsDetailsOptions.scales.yAxes[0].scaleLabel.fontColor = 'rgba(255,255,255,0.7)'
        this.documentsDetailsOptions.scales.yAxes[0].ticks.fontColor = 'white'
        this.documentsStatisticsChartColors = [
          {
            borderColor: 'red',
            backgroundColor: 'rgba(255,255,255,0.0)',
          },
          {
            borderColor: 'black',
            backgroundColor: 'rgba(255,255,255,0.4)',
          },
        ]
      }
    })
  }

  getCounts(data: DashboardDatapoints[]) {
    const counts = data.map(datapoint => {
      // const momentTime: Moment = moment(datapoint.Timestamp).utc()
      // const luxonTime = LuxonDateTime.fromISO(datapoint.Timestamp).toUTC()

      // const momentTimeToDate = momentTime.toDate()
      // const luxonTimeToDate = luxonTime.toJSDate()

      // if (momentTimeToDate.getTime() !== luxonTimeToDate.getTime()) {
      //   throw Error('Dates do not match')
      // }
      const time = LuxonDateTime.fromISO(datapoint.Timestamp).toUTC()

      return {
        t: time,
        y: datapoint.Sum,
      }
    }).sort((a, b) => a.t.valueOf() - b.t.valueOf())
      .map(dataPoint => {
        return {
          // t: dataPoint.t.toDate(),
          t: dataPoint.t.toJSDate(),
          y: dataPoint.y,
        }
      })
    return counts
  }

  getDurations(data: DashboardDatapoints[]) {
    return data.map(datapoint => {
      // const time: Moment = moment(datapoint.Timestamp).utc()
      const time = LuxonDateTime.fromISO(datapoint.Timestamp).toUTC()

      return {
        t: time,
        y: datapoint.Average / 1000,
      }
    }).sort((a, b) => a.t.valueOf() - b.t.valueOf())
      .map(dataPoint => {
        return {
          // t: dataPoint.t.toDate(),
          t: dataPoint.t.toJSDate(),
          y: dataPoint.y,
        }
      })
  }

  ngOnInit() {
    //  'testsExecuted'
    //   | 'renderTime'
    //   | 'analysisTime'
    //   | 'validationTime'
    //   | 'transformationTime'
    //   | 'apiLatency'
    //   | 'documentsGenerated'
    this.sub.add(
      this.dashboardMetricsService.getDashboardMetrics()
        .subscribe(result => {
          this.documentsStatisticsChartData = [
            {
              data: this.getDurations(result.metrics.find(m => m.metricName === 'apiLatency')!.values),
              label: 'API Latency',
              type: 'line',
              yAxisID: 'latencyScale',
            },
            {
              data: this.getCounts(result.metrics.find(m => m.metricName === 'documentsGenerated')!.values),
              label: 'Documents Generated',
              type: 'bar',
              yAxisID: 'countScale',
            },
          ]

          this.documentsDetailsChartData = [
            // {
            //   data: this.getDurations(result.metrics.find(m => m.metricName === 'apiLatency')!.values),
            //   label: 'Document Returned',
            //   type: 'line',
            //   yAxisID: 'latencyScale',
            // },
            {
              data: this.getDurations(result.metrics.find(m => m.metricName === 'transformationTime')!.values),
              label: 'Transformation',
              type: 'line',
              yAxisID: 'durationScale',
            },
            {
              data: this.getDurations(result.metrics.find(m => m.metricName === 'renderTime')!.values),
              label: 'Render',
              type: 'line',
              yAxisID: 'durationScale',
            },
            {
              data: this.getDurations(result.metrics.find(m => m.metricName === 'validationTime')!.values),
              label: 'Validation',
              type: 'line',
              yAxisID: 'durationScale',
            },
            {
              data: this.getDurations(result.metrics.find(m => m.metricName === 'analysisTime')!.values),
              label: 'Analysis',
              type: 'line',
              yAxisID: 'durationScale',
            },
          ]

          this.testsExecutedChartData = [
            {
              data: this.getCounts(result.metrics.find(m => m.metricName === 'testsExecuted')!.values),
              label: 'Tests Executed',
              type: 'bar',
              yAxisID: 'countScale',
            },
          ]
        }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

}
