<button type="button" class="btn btn-primary" id="rollback-commit-button" *ngIf="enableRollback && canRollbackCommit && commitsToRollback.length > 0" (click)="rollbackCommit()">Rollback Commits</button>
<ng-container *ngIf="(pipeline | async) as p">
  <div class="build">
    <ng-container *ngIf="p.Build.state === 'FAILED'">
      <div class="alert alert-danger" role="alert">
        Build has failed
      </div>
    </ng-container>
    <ng-container *ngIf="p.Build.state === 'OK'">
      <div class="alert alert-success" role="alert">
        Build was successful
      </div>
    </ng-container>
    <ng-container *ngIf="p.Build.state === 'DEPLOYING'">
      <div class="alert alert-warning" role="alert">
        Currently building...
      </div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let change of buildChanges | async">
          {{change?.user?.replace('.', ' ') | titlecase}} - {{change?.message}} - {{change?.date | amTimeAgo}}
        </li>
      </ul>
    </ng-container>
  </div>

  <div class="development">
    <ng-container *ngIf="p.Tools.state === 'FAILED'">
      <div class="alert alert-danger" role="alert">
        Deploy to Tools failed
      </div>
    </ng-container>
    <ng-container *ngIf="p.Tools.state === 'OK'">
      <div class="alert alert-success" role="alert">
        Tools OK
      </div>
    </ng-container>
    <ng-container *ngIf="p.Tools.state === 'DEPLOYING'">
      <div class="alert alert-warning" role="alert">
        Currently deploying to Tools...
      </div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let change of developmentChanges | async">
          {{change?.user?.replace('.', ' ') | titlecase}} - {{change?.message}} - {{change?.date | amTimeAgo}}
        </li>
      </ul>
    </ng-container>
  </div>

  <div class="staging">
    <ng-container *ngIf="p.Dev.state === 'FAILED'">
      <div class="alert alert-danger" role="alert">
        Deploy to Development failed
      </div>
    </ng-container>
    <ng-container *ngIf="isOk(p.Dev, stagingChanges) | async">
      <div class="alert alert-success" role="alert">
        Development OK
      </div>
    </ng-container>
    <ng-container *ngIf="p.Dev.state === 'DEPLOYING'">
      <div class="alert alert-warning" role="alert">
        Currently deploying to Development...
      </div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let change of stagingChanges | async">
          {{change?.user?.replace('.', ' ') | titlecase}} - {{change?.message}} - {{change?.date | amTimeAgo}}
          <div class="rollback-checkbox">
            <mat-checkbox *ngIf="enableRollback && canRollbackCommit" class="checklist-leaf-node"
                          (change)="addCommitToRollback(change, $event)">Rollback</mat-checkbox>
          </div>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="isApprovalNeeded(p.Dev, stagingChanges) | async">
      <div class="alert alert-primary" role="alert">
        Pending approval to Development
        <button *ngIf="repository === 'lambda-ingest-product' || repository === 'auto-deploy-templates'" class='btn btn-sm rounded-pill mr-2 showDetails'
                [ngClass]="displayReleaseChangeDetails ? 'btn-primary' : 'btn-secondary'"
                (click)="showDetails('dev')">Details</button>
      </div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let change of stagingChanges | async">
          {{change?.user?.replace('.', ' ') | titlecase}} - {{change?.message}} - {{change?.date | amTimeAgo}}
          <div class="rollback-checkbox">
            <mat-checkbox *ngIf="enableRollback && canRollbackCommit" class="checklist-leaf-node"
                          (change)="addCommitToRollback(change, $event)">Rollback</mat-checkbox>
          </div>
        </li>
        <ng-container *ngIf="canApproveStaging && stagingApprovalToken">
          <li class="list-group-item d-flex justify-content-end">
            <button type="button" class="btn btn-success"
                    (click)="approveStaging(stagingApprovalToken, stagingApprovalPipelineExecutionId)">Approve Development
            </button>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>

  <div class="uat">
    <ng-container *ngIf="p.Uat.state === 'FAILED'">
      <div class="alert alert-danger" role="alert">
        Deploy to UAT failed
      </div>
    </ng-container>
    <ng-container *ngIf="isOk(p.Uat, uatChanges) | async">
      <div class="alert alert-success" role="alert">
        UAT OK
      </div>
    </ng-container>
    <ng-container *ngIf="p.Uat.state === 'DEPLOYING'">
      <div class="alert alert-warning" role="alert">
        Currently deploying to UAT...
      </div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let change of uatChanges | async">
          {{change?.user?.replace('.', ' ') | titlecase}} - {{change?.message}} - {{change?.date | amTimeAgo}}
          <div class="rollback-checkbox">
            <mat-checkbox *ngIf="enableRollback && canRollbackCommit" class="checklist-leaf-node"
                          (change)="addCommitToRollback(change, $event)">Rollback</mat-checkbox>
          </div>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="isApprovalNeeded(p.Uat, uatChanges) | async">
      <div class="alert alert-primary" role="alert">
        Pending approval to UAT
        <button *ngIf="repository === 'lambda-ingest-product' || repository === 'auto-deploy-templates'" class='btn btn-sm rounded-pill mr-2 showDetails'
                [ngClass]="displayReleaseChangeDetails ? 'btn-primary' : 'btn-secondary'"
                (click)="showDetails('uat')">Details</button>
      </div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let change of uatChanges | async">
          {{change?.user?.replace('.', ' ') | titlecase}} - {{change?.message}} - {{change?.date | amTimeAgo}}
          <div class="rollback-checkbox">
            <mat-checkbox *ngIf="enableRollback && canRollbackCommit" class="checklist-leaf-node"
                          (change)="addCommitToRollback(change, $event)">Rollback</mat-checkbox>
          </div>
        </li>
        <ng-container *ngIf="canApproveUat && uatApprovalToken">
          <li class="list-group-item d-flex justify-content-end">
            <button type="button" class="btn btn-success"
                    (click)="approveUat(uatApprovalToken, uatApprovalPipelineExecutionId)">Approve UAT
            </button>
          </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>

  <div class="prod">
    <ng-container *ngIf="p.Prod.state === 'FAILED'">
      <div class="alert alert-danger" role="alert">
        Deploy to Production failed
      </div>
    </ng-container>
    <ng-container *ngIf="isOk(p.Prod, prodChanges) | async">
      <div class="alert alert-success" role="alert">
        Production OK
      </div>
    </ng-container>
    <ng-container *ngIf="p.Prod.state === 'DEPLOYING'">
      <div class="alert alert-warning" role="alert">
        Currently deploying to Production...
      </div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let change of prodChanges | async">
          {{change?.user?.replace('.', ' ') | titlecase}} - {{change?.message}} - {{change?.date | amTimeAgo}}
          <div class="rollback-checkbox">
            <mat-checkbox *ngIf="enableRollback && canRollbackCommit" class="checklist-leaf-node"
                          (change)="addCommitToRollback(change, $event)">Rollback</mat-checkbox>
          </div>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="isApprovalNeeded(p.Prod, prodChanges) | async">
      <div class="alert alert-primary" role="alert">
        Pending approval to Production
        <button *ngIf="repository === 'lambda-ingest-product' || repository === 'auto-deploy-templates'" class='btn btn-sm rounded-pill mr-2 showDetails'
                [ngClass]="displayReleaseChangeDetails ? 'btn-primary' : 'btn-secondary'"
                (click)="showDetails('prod')">Details</button>
      </div>
      <ul class="list-group">
        <li class="list-group-item" *ngFor="let change of prodChanges | async">
          {{change?.user?.replace('.', ' ') | titlecase}} - {{change?.message}} - {{change?.date | amTimeAgo}}
          <div class="rollback-checkbox">
            <mat-checkbox *ngIf="enableRollback && canRollbackCommit" class="checklist-leaf-node"
                          (change)="addCommitToRollback(change, $event)">Rollback</mat-checkbox>
          </div>
        </li>
        <ng-container *ngIf="canApprove && prodApprovalToken">
        <li class="list-group-item d-flex justify-content-end">
            <button class="d-flex justify-content-end" type="button" class="btn btn-danger"
                    (click)="approveProd(prodApprovalToken, prodApprovalPipelineExecutionId)">Approve Prod
            </button>
        </li>
        </ng-container>
      </ul>
    </ng-container>
  </div>
</ng-container>
