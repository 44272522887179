import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocgenConfigSelectorComponent } from 'src/app/components/docgen-config-selector.component';
import { ConfigService } from 'src/app/services/config.service';
import { UserNameService } from 'src/app/services/user-name.service';

export const CONFIG_NOTE_FILE_NAME_SUFFIX = '.notes.json'

export interface CreateConfigNoteDetails {
    version: string
    configType: 'SCHEMA' | 'TRANSFORM'
}

interface SelectItem {
    value: string
    display: string
}

@Component({
    selector: 'app-create-config-note',
    templateUrl: './create-config-note.html',
    styleUrls: ['./create-config-note.scss']
})
export class CreateConfigNote implements OnInit {
    description: string
    sourceSystems: SelectItem[]
    form: FormGroup

    constructor(
        public dialogRef: MatDialogRef<CreateConfigNote>,
        @Inject(MAT_DIALOG_DATA) public data: CreateConfigNoteDetails,
        private configService: ConfigService,
        private userNameService: UserNameService,
        private fb: FormBuilder,
    ) {
    }

    ngOnInit(): void {
        let user, sourceSystem;

        this.userNameService.userName.subscribe(value => {
            user = value;
        })

        this.configService.docgenConfigsFiltered.subscribe(configs => {
            if (configs) {
                this.sourceSystems = []
                configs.forEach(config => {
                    this.sourceSystems = DocgenConfigSelectorComponent.addToSelection(this.sourceSystems, config.metadata.sourceSystem)
                })
            }
            this.configService.selectedDocgenConfig.subscribe(config => {
                if (config && config.metadata) {
                    sourceSystem = config && config.metadata && config.metadata.sourceSystem
                }

                sourceSystem ||= this.sourceSystems[0].value

                this.form = this.fb.group({
                    sourceSystem: [sourceSystem],
                    description: [''],
                    version: [this.data.version],
                    dateUpdated: [new Date().toLocaleDateString('en-CA')],
                    user: [user],
                    inputFormat: [''],
                    inputFormatVersion: [''],
                    outputFormat: [''],
                    outputFormatVersion: [''],
                });
            })
        })
    }

    isTransformConfig() {
        return this.data.configType === 'TRANSFORM'
    }

    selectSourceSystem(value: string) {
        this.form.get('sourceSystem').setValue(value);
    }

    actionCancel() {
        this.dialogRef.close(undefined)
    }

    submit() {
        if (this.form.valid) {
            const formValues = this.form.value;
            const commonNoteData = {
                description: formValues.description,
                dateUpdated: new Date(),
                user: formValues.user,
                version: formValues.version,
            }

            const schemaNoteData = {
                ...commonNoteData,
                sourceSystem: formValues.sourceSystem,
            }

            const transformNoteData = {
                ...commonNoteData,
                inputFormat: formValues.inputFormat,
                inputFormatVersion: formValues.inputFormatVersion,
                outputFormat: formValues.outputFormat,
                outputFormatVersion: formValues.outputFormatVersion,
            }

            this.dialogRef.close(this.isTransformConfig() ? transformNoteData : schemaNoteData)
        }
    }
}
