import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core'
import { ConfigService } from '../../services/config.service'
import { ObservableInput, Subscription } from 'rxjs'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { ChangeService } from '../../services/change.service'
import { switchMap } from 'rxjs/operators'
import { ChangeDetails } from '../../common/domain/change'
import { MatDialog } from '@angular/material/dialog'
import { DocgenConfig, DocgenConfigAndMetadata } from '../../common/domain/docgen-config'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { RepositoryAndNameDialogComponent } from '../../dialogs/repository-and-name-dialog.component'
import { NgxSpinnerService } from 'ngx-spinner'
import { CONFIG_NOTE_FILE_NAME_SUFFIX } from 'src/app/dialogs/note-dialog/create-config-note'

export type ChangeWithService = ChangeDetails & { service: string }
@Component({
  selector: 'app-config-list',
  templateUrl: './config-list.component.html',
  styleUrls: ['./config-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigListComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  type: string
  typeSingular: string
  changeDetails: ChangeWithService[] = []
  selectedConfig: DocgenConfigAndMetadata
  configs: DocgenConfigAndMetadata[] = []

  constructor(
    private configService: ConfigService,
    private changeService: ChangeService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private changeDetectorRef: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
    this.spinner.show()
    this.sub.add(
      this.route.paramMap.pipe(
        switchMap((params: ParamMap): ObservableInput<ChangeDetails[]> => {
          this.type = params.get('type')
          this.typeSingular = this.type.replace(/s$/, '')
          return this.changeService.getObjectsOfType(this.type)
        },
        ),
      ).subscribe((configs: ChangeDetails[]) => {
        configs = configs.filter(c => !c.path.endsWith(CONFIG_NOTE_FILE_NAME_SUFFIX))
        this.changeDetails = configs.map(c => Object.assign({}, c, { service: (c.source === 'lambda-ingest-product' ? 'Documents' : c.source === 'api-publish' ? 'Routing' : 'Templates') }))
        this.changeDetectorRef.detectChanges();
        this.spinner.hide()
      }),
    )
    this.sub.add(
      this.configService.selectedDocgenConfig.subscribe((config: DocgenConfigAndMetadata) => this.selectedConfig = config),
    )
    this.sub.add(
      this.configService.docgenConfigsFiltered.subscribe((configs) => {
        this.configs = configs
        this.changeDetectorRef.detectChanges()
      }),
    )

  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  delete(config: ChangeDetails): void {
    this.changeService.deleteObject(config)
  }

  edit(config: ChangeDetails): void {
    this.router.navigate(['config', config.source, this.type, 'edit', { path: config.path }])
  }

  add(): void {
    const filename = this.type === 'schemas'
      ? 'schema.xsd'
      : this.type === 'transforms'
        ? 'transform.xslt'
        : this.type === 'samples'
          ? 'sample.xml'
          : 'filename.ext'

    const dialog = this.modalService.open(RepositoryAndNameDialogComponent)
    dialog.componentInstance.title = 'Repository / ' + this.type.replace(/s$/, '') + ' name'
    dialog.componentInstance.type = this.type
    dialog.componentInstance.name = filename

    dialog.result.then(res => {
      console.log('Got result')
      console.dir(res)
      if (res) {
        this.router.navigate(['config', res.repository, this.type, 'edit', { path: `${this.type}/${res.name}` }])
      }
    }).catch(err => {
      console.log('Cancelled')
    })
  }

  getDisplayState(config: ChangeDetails): string {
    switch (config.state) {
      case 'original': {
        return ''
      }
      case 'modified': {
        return 'MODIFIED'
      }
      case 'new': {
        return 'NEW'
      }
      case 'toDelete': {
        return 'TO BE DELETED'
      }
      default: {
        return '?'
      }
    }
  }

  isReferencedByConfig(item: ChangeDetails): boolean {
    if (this.selectedConfig) {
      const metadata = this.selectedConfig.metadata
      const configurationObjectPath = `settings/${metadata.sourceSystem}/${metadata.programme}/${metadata.productType}/${metadata.documentType}/Config.json`
      const configurationObject = this.changeService.getObject(configurationObjectPath)
      const config: DocgenConfig = JSON.parse(configurationObject.content)
      return []
        .concat(config.validatePayload.schema)
        .concat(config.validateEnriched.schema)
        .concat(config.transform.transforms)
        .concat(config.enrich.transforms)
        .filter(x => x)
        .includes(item.path)
    }
    return false
  }

  isAvailableToConfig(item: ChangeDetails): boolean {
    if (this.configs && this.configs.length > 0) {
      return this.configs.some(availableConfig => {
        const metadata = availableConfig.metadata
        const configurationObjectPath = `settings/${metadata.sourceSystem}/${metadata.programme}/${metadata.productType}/${metadata.documentType}/Config.json`
        const configurationObject = this.changeService.getObject(configurationObjectPath)
        const config: DocgenConfig = JSON.parse(configurationObject.content)
        return []
          .concat(config.validatePayload.schema)
          .concat(config.validateEnriched.schema)
          .concat(config.transform.transforms)
          .concat(config.enrich.transforms)
          .filter(x => x)
          .includes(item.path)
      })
    }
    return false
  }

  restore(config: ChangeDetails) {
    this.changeService.revertChange(config)
  }
}
