import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {StatusService} from './status.service'
import {catchError, delay, mergeMap, retryWhen, switchMap, tap} from 'rxjs/operators'
import {Observable, of, throwError} from 'rxjs'
import * as JSZip from 'jszip'

export interface SummaryEvent {
  timestamp: string
  account: string
  region: string
}

export interface MiEvent {
  time: string
  account: string
  region: string
  'detail-type': string
}

type ManagmentInformationResponse = SummaryEvent | MiEvent

const MAX_RETRY = 2
const DELAY = 250
const BACKOFF = 250

const retryWithBackoff = (delayMs: number = DELAY, maxRetries: number = MAX_RETRY, backoff: number = BACKOFF) => {
  let retry = 0
  return (src: Observable<any>) => src.pipe(
    retryWhen((errors) => {
      return errors.pipe(
        mergeMap(error => {
          console.dir('Retry: ' + retry + ' - ' + error)
          return retry++ < maxRetries
            ? of(error)
              .pipe(
                delay(delayMs + (retry * backoff)),
              )
            : throwError('Failed to load.')
        }),
      )
    }),
  )
}


@Injectable({
  providedIn: 'root',
})
export class ManagementInformationService {

  constructor(private api: ApiService,
    private statusService: StatusService) {
  }

  getManagementInformation<T extends ManagmentInformationResponse[]>(environment: string, region: string, sourceEvents: string, dateRange: string, summaries: boolean = false) {
    // const id = this.statusService.start('Gathering Management Information...')
    const url = `/mi/${summaries ? 'summaries/' : ''}${environment}/${region}/${sourceEvents}/${dateRange}`
    return this.api.get(url)
      .pipe(
        retryWithBackoff(),
        // tap(data => {
        //   console.log('MI Before ' + environment + ' ' + region + ' ' + dateRange)
        //   console.dir(data)
        // }),
        switchMap(async data => {
          console.log('Checking if data[zip] exists')
          if (data['zip']) {
            console.log('Zip exists....About to instantiate JSZip')
            const zip = new JSZip()
            console.log('JSZip instantiated')
            return await zip.loadAsync(data['zip'], {base64: true})
              .then(async zipObject => {
                return await zipObject.file('events.json').async('text')
              })
              .then(events => {
                console.log('Loading events from zip content')
                return JSON.parse(events) as T
              })
          }
          return data as T
        }),
        // tap(data => {
        //   console.log('MI After ' + environment + ' ' + region + ' ' + dateRange)
        //   console.dir(data)
        // }),
        catchError(e => {
          this.statusService.start('Failed to get Management Information', 'ALERT', 10)
          return of({})
        }),
        // tap(_ => this.statusService.complete(id)),
      )
  }

  getClientReport<T>(dateRange: string) {
    // const id = this.statusService.start('Generating Client Report...')
    return this.api.get(`/mi/clientReport/` + dateRange)
      .pipe(
        retryWithBackoff(),
        catchError(e => {
          this.statusService.start('Failed to get client report', 'ALERT', 10)
          return of({})
        }),
        // tap(_ => this.statusService.complete(id)),
      )
  }

  getSlaReport<T>(dateRange: string) {
    // const id = this.statusService.start('Generating SLA Report...')
    return this.api.get(`/mi/slaReport/` + dateRange)
      .pipe(
        retryWithBackoff(),
        catchError(e => {
          this.statusService.start('Failed to get SLA Report', 'ALERT', 10)
          return of({})
        }),
        // tap(_ => this.statusService.complete(id)),
      )
  }

  getValidationsReport(environment: string, region: string, dateRange: string) {
    return this.api.get(`/mi/validations-report/${environment}/${region}/${dateRange}`)
      .pipe(
        retryWithBackoff(),
        catchError(e => {
          this.statusService.start('Failed to get Validations Report', 'ALERT', 10)
          return of({})
        }),
      )
  }
}
