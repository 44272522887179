import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {MatSort} from '@angular/material/sort'
import {MatTableDataSource} from '@angular/material/table'
import {MatTabChangeEvent} from '@angular/material/tabs'
import {ActivatedRoute, Router} from '@angular/router'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {saveAs} from 'file-saver'
import * as JSZip from 'jszip'
import {ToastrService} from 'ngx-toastr'
import {Subscription} from 'rxjs'
import {AnalyseRuleTable, SuccessAnalyseResult} from '../common/domain/analyse'
import {DocgenConfig} from '../common/domain/docgen-config'
import {SuccessRenderDocumentResult} from '../common/domain/render'
import {
  DocgenPayloadInformation,
  Name,
  TestExecution,
  TestPackReference,
  TestSuiteDefinition,
  TestSuiteExecution,
} from '../common/domain/tests'
import {Workspace} from '../common/domain/workspaces'
import {ConfirmCommentDialogComponent, ConfirmCommentResponse} from '../dialogs/confirm-comment-dialog.component'
import {ConfirmDialogComponent, ConfirmStatus} from '../dialogs/confirm-dialog.component'
import {NameDialogComponent} from '../dialogs/name-dialog.component'
import {AnalyseUtilResponse} from '../model/analyse-util-response'
import {IngestProductDocgenTraceResponse} from '../model/public-api-response'
import {GetTestSuiteResponse} from '../model/test-management-responses'
import {ChangeService} from '../services/change.service'
import {PermissionsService} from '../services/permissions.service'
import {StatusService} from '../services/status.service'
import {TestManagerService} from '../services/test-manager.service'
import {WorkspaceAutoSaveService} from '../services/workspace-auto-save.service'
import {WorkspaceService} from '../services/workspace.service'
import { unparse } from 'papaparse'
// const stringify = require('csv-stringify/lib/sync')

interface TestExecutionDetails extends TestExecution {
  whitelistrules: string
  blacklistrules: string
  notsupportedyetrules: string
  validationrules: string
  sourcesystem?: string
  programme?: string
  producttype?: string
  documenttype?: string
  sourceref?: string
}

export interface RuleAssignment {
  configKey: string
  mnemonic: string
  test: string
  existing: boolean
  reused: boolean
}

@Component({
  selector: 'app-test-manager',
  templateUrl: './test-suite-executions.component.html',
  styleUrls: ['./test-suite-executions.component.scss'],
})
export class TestSuiteExecutionsComponent implements OnInit, OnDestroy {
  subs = new Subscription()
  documentFormatType = 'pdf'

  selectedTestSuite: GetTestSuiteResponse
  workspace: Workspace

  testCount: number
  canExecute = false
  canRebaseline = false
  canRerunAll = false
  canRerunErrors = false
  numberOfDays = 10
  histories = [10, 30, 90]


  testSuiteExecutionDisplayedColumns = [
    'name',
    'group',
    'updated',
    'status',
    'environment',
    'region',
    'tests',
    'pending',
    'failed',
    'passed',
    'passedwhitelistcount',
    'errors',
    'validatedPayload',
    'transformed',
    'enriched',
    'validatedEnriched',
    'analysedPayload',
    'analysedTransformed',
    'generatedDocument',
    'documentFormatType',
    'pdfdiffs',
    'actions']
  testSuiteExecutionsDataSource = new MatTableDataSource<TestSuiteExecution>([])

  testSuiteExecutionDetailsDisplayedColumns = [
    'name',
    'status',
    'whitelist',
    'blacklist',
    'notsupported',
    'validation',
    'differences',
    'failurepoints',
    'documenterrors',
    'documentwarnings',
    'actions']
  testSuiteExecutionsDetailsDataSource = new MatTableDataSource<TestExecution>([])
  @ViewChild(MatSort, {static: true}) sort: MatSort

  assignments: RuleAssignment[] = undefined


  selectedTab = 0
  executionId: string
  groupExecutionId: string
  canDownloadTestPack = false
  canRationalise = false
  rationaliseError = undefined
  isDeveloper = false
  isManager = false

  TEST_SUITE_EXECUTION_FILTER_STRING_PLACEHOLDER = 'UNUSED_BY_FILTER'

  constructor(
    private testManager: TestManagerService,
    private toast: ToastrService,
    private statusService: StatusService,
    private permissionsService: PermissionsService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private workspaceService: WorkspaceService,
    private workspaceAutoSaveService: WorkspaceAutoSaveService,
    private changeService: ChangeService,
  ) {

    this.permissionsService.developer.subscribe(developer => this.isDeveloper = developer)
    this.permissionsService.management.subscribe(manager => this.isManager = manager)
    this.permissionsService.rebaselineTests.subscribe(rebaseline => this.canRebaseline = rebaseline)

    this.testSuiteExecutionsDataSource.filterPredicate = (data: TestSuiteExecution, filter: string) => {
      return this.workspace ?
        data.workspace && data.workspace === this.workspace.name :
        this.selectedTestSuite
          ? data.testsuitename === this.selectedTestSuite.name
          : data.workspace === undefined || data.workspace === 'NONE'
    }
  }

  ngOnInit() {
    this.testManager.setService('docgen')
    this.testSuiteExecutionsDetailsDataSource.sort = this.sort
    this.workspaceAutoSaveService.saveWorkspace()
    this.subs.add(
      this.activatedRoute.paramMap.subscribe(params => {
        this.assignments = undefined
        if (params.has('executionId')) {
          this.executionId = params.get('executionId')
          this.canDownloadTestPack = false
          this.canRationalise = false
          this.canRerunAll = false
          this.canRerunErrors = false
          this.rationaliseError = undefined
          this.testManager.getTestSuiteExecutionDetails(this.executionId)
            .then(res => {
              // @ts-ignore
              const testResponses = res.responses || []
              this.testManager.selectTestSuite(res['name'])
              this.testSuiteExecutionsDetailsDataSource.data = testResponses.map(d => {
                if (d.status === 'NOTFOUND') {
                  d.status = 'PENDING'
                }
                return d
              })
              // if (data.length > 0 && data.every(d => d.status === 'PASSED')) {
              this.canDownloadTestPack = true
              // }
              this.canRerunAll = testResponses.some(t => t.status === 'ERROR' || t.status === 'FAILED')
              this.canRerunErrors = testResponses.some(t => t.status === 'ERROR')
              if (testResponses.length === 0) {
                this.rationaliseError = 'No tests'
              } else if (testResponses.some(d => d['sourcesystem'] === undefined || d['sourcesystem'] === '')) {
                this.rationaliseError = 'Some tests have no source system'
              } else if (testResponses.some(d => d.status !== 'PASSED')) {
                this.rationaliseError = 'Some tests are not passing'
              } else if (testResponses.some(testInfo => {
                const whitelistKeys = testInfo.whitelistrules?.split(',') || []
                const notSupportedYetKeys = testInfo.notsupportedyetrules?.split(',') || []
                const validationKeys = testInfo.validationrules?.split(',') || []
                const blacklistKeys = testInfo.blacklistrules?.split(',') || []
                return (whitelistKeys.length + notSupportedYetKeys.length + validationKeys.length + blacklistKeys.length) === 0
              })) {
                this.rationaliseError = 'Some tests do not have any rules'
              } else {
                this.canRationalise = true
              }
            })
          this.selectedTab = 1
        } else if (params.has('groupExecutionId')) {
          this.groupExecutionId = params.get('groupExecutionId')
          this.getTestSuiteExecutionHistory(this.groupExecutionId)
        } else {
          this.selectedTab = 0
          this.executionId = undefined
          this.getTestSuiteExecutionHistory()
        }
      }),
    )
    this.subs.add(
      this.testManager.testExecution.subscribe(() => {
        this.getTestSuiteExecutionHistory()
      }),
    )
    this.subs.add(
      this.testManager.selectedTestSuite.subscribe(result => {
        this.selectedTestSuite = result
        this.testCount = result && result.tests.length || 0
        this.canExecute = this.testCount > 0
        this.testSuiteExecutionsDataSource.filter = 'filterThoseBabies'
      }),
    )
    this.subs.add(
      this.workspaceService.selectedWorkspace.subscribe(workspace => {
        this.workspace = workspace
        this.testSuiteExecutionsDataSource.filter = 'filterThoseBabies'
      }),
    )
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  refreshTestExecutions = async (toRefresh: string[]) => {
    const testManager = this.testManager
    console.log('Refreshing ' + toRefresh.join(','))
    await Promise
      .all(toRefresh
        .map((executionId) => {
          console.log('Refreshing - ' + executionId)
          return testManager.refreshTestSuiteExecutionDetails(executionId)
        }))
      .then(() => {
        this.getTestSuiteExecutionHistory()
      })
  }

  getTestSuiteExecutionHistory = (groupExecutionId: string = this.groupExecutionId, bustCache: boolean = false, numberOfDays: number = this.numberOfDays) => {
    this.testManager.getTestSuiteExecutionHistory(bustCache, numberOfDays)
      .then(executionHistory => {
        const executions = executionHistory.executions
          .filter(execution => groupExecutionId === undefined || execution.groupexecutionid === groupExecutionId)
          .map(execution => {
            execution.modifiedDate = new Date(execution.modified)
            execution.region = execution.region || 'London'
            if (execution.region === 'FRANKFURT') {
              execution.region = 'Frankfurt'
            } else if (execution.region === 'LONDON') {
              execution.region = 'London'
            }
            execution.environment = execution.environment || 'Storm'
            if (execution.environment === 'TOOLS') {
              execution.environment = 'Storm'
            }
            if (execution.environment === 'DEMO') {
              execution.environment = 'Demo'
            }
            if (execution.environment === 'DEV') {
              execution.environment = 'Dev'
            }
            if (execution.environment === 'UAT') {
              execution.environment = 'UAT'
            }
            if (execution.environment === 'PROD') {
              execution.environment = 'Production'
            }
            if (execution.status !== 'PENDING' && execution.format === undefined) {
              execution.format = 'pdf'
            }

            return execution
          })
        executions.sort((a, b) => b.modifiedDate.getTime() - a.modifiedDate.getTime())
        this.testSuiteExecutionsDataSource.data = executions

        // console.log('data:')
        // console.dir(executions)

        this.testSuiteExecutionsDataSource.filter = this.TEST_SUITE_EXECUTION_FILTER_STRING_PLACEHOLDER

        const toRefresh = executions
          .filter((execution: TestSuiteExecution) => execution.status === 'PENDING' && execution.pending !== '0')
          .map((execution: TestSuiteExecution) => {
            return execution.executionId
          })
        if (toRefresh.length > 0) {
          console.log('The following executions are pending - ' + toRefresh.join(','))
          window.setTimeout(() => {
              this.refreshTestExecutions(toRefresh)
            },
            15000)
        }
      })
  }

  reallyExecuteTests = (testSuite: Name, documentFormatType: string, workspace?: Workspace) => {
    console.log('Executing: ' + testSuite)
    this.testManager.executeTestSuite(testSuite, workspace && workspace.name, documentFormatType)
      .then(res => {
        this.router.navigate(['/tests'])
      })
  }


  executeTests = (format?: string) => {
    this.documentFormatType = format !== undefined ? format : 'pdf'
    const testSuite = this.selectedTestSuite
    if (testSuite) {
      console.log('Checking whether to execute ' + testSuite.name + ' which has ' + this.testCount + ' tests')
      if (this.testCount > 100) {
        const dialogRef = this.modalService.open(ConfirmDialogComponent, {
          size: 'lg',
          backdrop: false,
        })
        dialogRef.componentInstance.title = 'Please confirm'
        dialogRef.componentInstance.message = 'Are you sure you want to execute Test Suite ' + testSuite.name + '.  It contains ' + this.testCount + ' tests.'
        dialogRef.result.then(async confirm => {
          this.reallyExecuteTests(testSuite.name, this.documentFormatType, this.workspace)
        })
      } else {
        this.reallyExecuteTests(testSuite.name, this.documentFormatType, this.workspace)
      }
    }
  }

  refreshExecutionStatus = (execution: TestSuiteExecution) => {
    this.testManager.refreshTestSuiteExecutionDetails(execution.executionId)
      .then(res => {
        this.getTestSuiteExecutionHistory()
      })
      .then(res => {
        this.router.navigate(['/tests'])
      })
  }

  viewExecutionDetails = (execution: TestSuiteExecution) => {
    this.router.navigate(['/tests', {executionId: execution.executionId}])
  }

  viewGroupExecutionDetails = (execution: TestSuiteExecution) => {
    this.router.navigate(['/tests', {groupExecutionId: execution.groupexecutionid}])
  }

  selectTab = (tab: MatTabChangeEvent) => {
    if (tab.tab.textLabel === 'Test Suite Executions') {
      this.router.navigate(['/tests'])
    }
  }

  tabClick = () => {
    if (this.selectedTab === 0) {
      this.router.navigate(['/tests'])
    }
  }

  downloadCsv = () => {
    const data = this.testSuiteExecutionsDetailsDataSource.data

    const csvData = data.map(row => {
      // @ts-ignore
      const summary = JSON.parse(row.summary || '{}')
      const cleaned = Object.assign({}, row, summary)
      delete cleaned.summary
      return cleaned
    })

    const csv = unparse(csvData, {
      header: true,
    })

    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
    saveAs(blob, this.executionId + '.csv')

  }

  downloadTestPack = async (delta: boolean) => {
    interface TR {
      testName: string
    }

    interface AR {
      programme: string
      productType: string
      documentType: string
      menemonic: string
      name: string
      description: string
      fields: string[]
      testResults: TR[]
    }

    const analysisRules: AR[] = []
    const results: TestPackReference[] = await this.testManager.downloadTestPack(this.executionId)
    const downloadingDocsId = this.statusService.start('Downloading documents...')
    const jszip: JSZip = new JSZip()
    const folders = {}
    for (const result of results) {
      console.dir(result)
      const productInfo = result.product as DocgenPayloadInformation
      const documentType = productInfo.documentType
        .replace('EIS', 'EIS ')
        .replace('Supplemental', 'Supplemental ')
        .replace('FinalTerms', 'Final Terms')
        .replace(/20\d\d/, '')
      const productType = productInfo.productType
        .replace('TriggerRedeemable', 'TR ')
        .replace('KIPut', 'KI Put')
        .replace('LevPut', 'Lev Put')

      folders[documentType] = folders[documentType] || jszip.folder(documentType)
      const documentTypeFolder = folders[documentType]
      const prefix1 = delta ? 'Previous inSPire' : 'CS'
      const prefix2 = delta ? 'Current inSPire' : 'inSPire'
      await this.testManager.getTest(result.name, delta ? result.previousVersion : result.originalVersion)
        .subscribe(test => {
          documentTypeFolder.file(`${result.productRef} - ${prefix1} ${documentType} ${productType}.pdf`, test.document, {base64: true})
        })
      await this.testManager.getTestExecutionDetail(this.executionId, result.name)
        .toPromise()
        .then((test) => {
          console.log('Got Test: ' + result.name)
          console.dir(test)
          const docgenTraceResponse = test.response as IngestProductDocgenTraceResponse
          const generated = (docgenTraceResponse.renderDocument as SuccessRenderDocumentResult).data
          const analysePayload: AnalyseUtilResponse = JSON.parse((docgenTraceResponse.analysePayload as SuccessAnalyseResult).details)
          const analysePayloadResults = analysePayload.rules

          for (const wl of analysePayloadResults) {
            let ex: AR = analysisRules.find(r => {
              return r.productType === productType
                && r.documentType === documentType
                && r.programme === productInfo.programme
                && r.menemonic === 'PW-' + wl.mnemonic
            })
            if (!ex) {
              ex = {
                productType,
                documentType,
                programme: productInfo.programme,
                menemonic: 'PW-' + wl.mnemonic,
                name: wl.name,
                description: wl.description,
                testResults: [],
                fields: wl.fields,
              }
              analysisRules.push(ex)
            }
            const tx: TR = {
              testName: result.name,
            }
            wl.fields.forEach((f, i) => {
              tx[f] = wl.actual[i].value
            })
            ex.testResults.push(tx)
          }

          documentTypeFolder.file(`${result.productRef} - ${prefix2} ${documentType} ${productType}.pdf`, generated, {base64: true})
        })
    }
    const folder = jszip.folder('analysis')
    analysisRules.forEach(ar => {
      const foo = unparse(ar.testResults, {
        header: true,
      })

      console.log(foo)
      const blob = new Blob([foo], {type: 'text/csv;charset=utf-8'})

      folder.file(`${ar.programme}-${ar.productType}-${ar.documentType}-${ar.menemonic}.csv`, blob)
    })

    this.statusService.complete(downloadingDocsId)
    const zipId = this.statusService.start('Preparing Zip File')
    await jszip.generateAsync({type: 'blob'}).then(zip => {
      saveAs(zip, this.executionId + '.zip')
    })
    this.statusService.complete(zipId)
  }

  deleteExecution = (execution: TestSuiteExecution) => {
    const dialogRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })
    dialogRef.componentInstance.title = 'Please confirm'
    dialogRef.componentInstance.message = 'Are you sure you want to delete ' + execution.executionId
    dialogRef.result.then(confirm => {
      console.log('Deleting Execution: ' + execution.executionId)
      this.testManager.deleteExecution(execution.executionId)
        .then(res => {
          this.toast.success('Deleted test suite execution')
          this.getTestSuiteExecutionHistory()
        })
    })
  }

  rebaselineTests = (executionId) => {
    const dialogRef = this.dialog.open(ConfirmCommentDialogComponent, {
      width: '500px',
      data: {
        title: 'WARNING - PLEASE CONFIRM',
        message: 'WARNING - Are you ABSOLUTELY sure you want to re-baseline ' + executionId
          + ' --- '
          + 'This will update the expected documents for all the tests',
      },
      panelClass: 'dialogFormField500',
    })
    dialogRef.afterClosed().subscribe(async (confirm: ConfirmCommentResponse) => {
      if (confirm.status === ConfirmStatus.CONFIRMED) {
        console.log('Rebaseline Execution: ' + executionId)
        this.testManager.rebaselineTests(executionId, confirm.comment)
          .then(res => {
            this.toast.success('Rebaselined - ' + res['updated'].length + ' tests updated')
          })
      }
    })
  }

  viewTestExecutionDetails = (test: any) => {
    this.router.navigate(['/tests', 'execution', this.executionId, test.name])
  }

  baselinePossible() {
    const testExecutions = this.testSuiteExecutionsDetailsDataSource.data.map(test => {
      return {
        status: test.status,
        summary: test['summary'] && JSON.parse(test['summary']) || {},
        testType: test['testtype'],
      }
    })
    if (testExecutions.some(test => test.status === 'ERROR' || test.status === 'PENDING')) {
      return false
    }
    if (testExecutions.every(test => test.status === 'PASSED')) {
      return testExecutions.some(test => test.summary['xmlDiff'] !== 'OK' || test.testType === 'Acceptance')
    }
    return testExecutions.every(test => test.summary['generateDocument'] === 'OK')

  }

  canViewDetails(test: any) {
    return test.status === 'PASSED' || test.status === 'UNKNOWN' || (test.status === 'FAILED' && test.failedchecks)
  }

  rerun(test: string) {
    if (this.executionId) {
      this.testManager.rerunTests(this.executionId, [test])
        .then(res => {
          this.toast.info('Rerunning ' + test)
          return this.testManager.refreshTestSuiteExecutionDetails(this.executionId)
        })
        .then(res => {
          this.router.navigate(['/tests'])
        })
    }
  }

  rerunAll() {
    if (this.executionId) {
      const tests = this.testSuiteExecutionsDetailsDataSource.data
        .filter(t => t.status === 'ERROR' || t.status === 'FAILED')
        .map(t => t.name)
      this.testManager.rerunTests(this.executionId, tests)
        .then(res => {
          this.toast.info('Rerunning ' + tests.length + ' tests')
          return this.testManager.refreshTestSuiteExecutionDetails(this.executionId)
        })
        .then(res => {
          this.router.navigate(['/tests'])
        })
    }
  }

  retryErrors() {
    if (this.executionId) {
      const tests = this.testSuiteExecutionsDetailsDataSource.data
        .filter(t => t.status === 'ERROR')
        .map(t => t.name)
      this.testManager.rerunTests(this.executionId, tests)
        .then(res => {
          this.toast.info('Rerunning ' + tests.length + ' tests')
          return this.testManager.refreshTestSuiteExecutionDetails(this.executionId)
        })
        .then(res => {
          this.router.navigate(['/tests'])
        })
    }
  }

  async rationalise() {
    if (this.executionId) {
      const statusId = this.statusService.start('Analysing Rule Coverage')
      try {
        const rulesLookup: { [key: string]: { [key: string]: string[] } } = {}
        const testCounter: { [key: string]: number } = {}
        const testLookup: { [key: string]: TestExecutionDetails } = {}
        const requiredTests: string[] = []

        // TODO - as a function, this could get the executions for multiple test suites to further rationalise the tests allocated to rules
        const data: TestExecution[] = this.testSuiteExecutionsDetailsDataSource.data

        // Stage 1 - Bucket and score the tests
        for (const test of data) {
          const testInfo = test as TestExecutionDetails
          testLookup[test.name] = testInfo

          const configKey = `${testInfo.sourcesystem}/${testInfo.programme}/${testInfo.producttype}/${testInfo.documenttype}`
          const configObject = this.changeService.getObject(`settings/${configKey}/Config.json`)
          const config = JSON.parse(configObject.content) as DocgenConfig
          rulesLookup[configKey] = rulesLookup[configKey] || {}

          let count = 0
          console.dir(testInfo)
          const whitelistKeys = testInfo.whitelistrules?.split(',') || []
          const notSupportedYetKeys = testInfo.notsupportedyetrules?.split(',') || []
          const validationKeys = testInfo.validationrules?.split(',') || []
          const blacklistKeys = testInfo.blacklistrules?.split(',') || []

          const analyseConfig = config.analysePayload
          const analyseEnrichedConfig = config.analyseEnriched

          const matches = (prefix: string, rules: AnalyseRuleTable[], keys: string[]): number => {
            let result = 0
            rules.forEach(ruleSet => {
              ruleSet.rule.forEach(rule => {
                const mnemonic = prefix + '-' + ruleSet.mnemonic + '-' + rule.mnemonic
                if (keys.includes(mnemonic)) {
                  rulesLookup[configKey][mnemonic] = rulesLookup[configKey][mnemonic] || [] as string[]
                  rulesLookup[configKey][mnemonic].push(test.name)
                  result = result + 1
                }
              })
            })
            return result
          }
          count = count + matches('PW', analyseConfig.rules.filter(r => r.ruleType === 'whitelist'), whitelistKeys)
          count = count + matches('PU', analyseConfig.rules.filter(r => r.ruleType === 'notSupported'), notSupportedYetKeys)
          count = count + matches('PV', analyseConfig.rules.filter(r => r.ruleType === 'validation'), validationKeys)
          count = count + matches('PB', analyseConfig.rules.filter(r => r.ruleType === 'blacklist'), blacklistKeys)
          count = count + matches('SW', analyseEnrichedConfig.rules.filter(r => r.ruleType === 'whitelist'), whitelistKeys)
          count = count + matches('SU', analyseEnrichedConfig.rules.filter(r => r.ruleType === 'notSupported'), notSupportedYetKeys)
          count = count + matches('SV', analyseEnrichedConfig.rules.filter(r => r.ruleType === 'validation'), validationKeys)
          count = count + matches('SB', analyseEnrichedConfig.rules.filter(r => r.ruleType === 'blacklist'), blacklistKeys)

          testCounter[test.name] = count
        }
        console.dir(rulesLookup)

        // Stage 2 - For each affected config key, Select Tests and Update Rules
        this.assignments = Object.entries(rulesLookup).reduce((a, v) => {
          const configKey = v[0]
          const configObject = this.changeService.getObject(`settings/${configKey}/Config.json`)
          const config = JSON.parse(configObject.content) as DocgenConfig
          const rules = v[1]
          const assigned = Object.entries(rules).map(r => {
            const ruleMnemonic = r[0].split('-')
            const tests = r[1]
            let ruleTables: AnalyseRuleTable[]
            if (ruleMnemonic[0] === 'PW') {
              ruleTables = config.analysePayload.rules.filter(r => r.ruleType === 'whitelist')
            }
            if (ruleMnemonic[0] === 'PU') {
              ruleTables = config.analysePayload.rules.filter(r => r.ruleType === 'notSupported')
            }
            if (ruleMnemonic[0] === 'PV') {
              ruleTables = config.analysePayload.rules.filter(r => r.ruleType === 'validation')
            }
            if (ruleMnemonic[0] === 'PB') {
              ruleTables = config.analysePayload.rules.filter(r => r.ruleType === 'blacklist')
            }
            if (ruleMnemonic[0] === 'SW') {
              ruleTables = config.analyseEnriched.rules.filter(r => r.ruleType === 'whitelist')
            }
            if (ruleMnemonic[0] === 'SU') {
              ruleTables = config.analyseEnriched.rules.filter(r => r.ruleType === 'notSupported')
            }
            if (ruleMnemonic[0] === 'SV') {
              ruleTables = config.analyseEnriched.rules.filter(r => r.ruleType === 'validation')
            }
            if (ruleMnemonic[0] === 'SB') {
              ruleTables = config.analyseEnriched.rules.filter(r => r.ruleType === 'blacklist')
            }
            ruleTables = ruleTables || []
            const ruleTable = ruleTables.find(r => {
              return r.mnemonic === ruleMnemonic[1]
            })
            if (!ruleTable) {
              throw new Error('FAILED TO FIND RULE TABLE: ' + r[0])
            }
            const rulerow = ruleTable.rule.find(rr => rr.mnemonic === ruleMnemonic[2])
            if (!rulerow) {
              throw new Error('FAILED TO FIND RULE ROW: ' + r[0])
            }
            // get the test counts for all tests matching this rule
            const testCounts = tests.map(t => {
              return {name: t, count: testCounter[t]}
            })
            // if there is not already a test covering this rule
            const existingTestCoveringRule = requiredTests.find(t => tests.includes(t))
            if (!existingTestCoveringRule) {
              // sort the tests by count and then name (both descending)
              testCounts.sort((a, b) => {
                if (a.count === b.count) {
                  return a.name.localeCompare(b.name)
                } else {
                  return a.count - b.count
                }
              }).reverse()
              // to get the best match
              const selectedTest = testCounts[0]
              if (!requiredTests.includes(selectedTest.name)) {
                requiredTests.push(selectedTest.name)
              }
              const testInfo = testLookup[selectedTest.name]
              rulerow.test = testInfo.sourceref
              return {
                configKey: configKey,
                mnemonic: r[0],
                test: testInfo.name,
                reused: false,
              }
            } else {
              const testInfo = testLookup[existingTestCoveringRule]
              rulerow.test = testInfo.sourceref
              return {
                configKey: configKey,
                mnemonic: r[0],
                test: testInfo.name,
                reused: true,
              }
            }
          })

          this.changeService.addChange(Object.assign({}, configObject, {
            content: JSON.stringify(config, undefined, 2),
          }))
          console.log('Replacing')
          console.log(configObject.content)
          console.log('With')
          console.log(JSON.stringify(config, undefined, 2))
          return a.concat(...assigned)
        }, [])

        this.assignments.sort((a, b) => {
          if (a.configKey !== b.configKey) {
            return a.configKey.localeCompare(b.configKey)
          }
          return a.mnemonic.localeCompare(b.mnemonic)
        })
        console.dir(this.assignments)

        // Stage 3 - Save the test suite
        const dialogRef = this.dialog.open(NameDialogComponent, {
          width: '500px',
          data: {
            title: 'New Test Suite Name',
            name: 'Rationalised-' + this.selectedTestSuite.name,
          },
          panelClass: 'dialogFormField500',
        })
        dialogRef.afterClosed().subscribe(async name => {
          if (name) {
            const updatedTestSuite: TestSuiteDefinition = {
              name: name,
              tests: this.selectedTestSuite.tests.map(t => t.name).filter(t => requiredTests.includes(t)),
            }
            this.testManager.saveTestSuite(updatedTestSuite)
              .then(res => {
                this.toast.success('Saved new test suite - ' + name + ' with ' + updatedTestSuite.tests.length + ' tests.')
              })
          }
        })
      } catch (e) {
        console.log(e)
        this.toast.error('Failed to rationalise the rules')
      } finally {
        this.statusService.complete(statusId)
      }
    }
  }

  setNumberOfDays(value: string) {
    this.numberOfDays = parseInt(value, 10);
    this.getTestSuiteExecutionHistory()
  }
}
