import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core'
import {MatTableDataSource} from '@angular/material/table'
import {ManagementInformationService, MiEvent, SummaryEvent} from '../../../services/management-information.service'
import {DateTime} from 'luxon'
import {Environment, ENVIRONMENTS, Region} from '../../../services/environment-service'
import {of, Subject} from 'rxjs'
import {debounceTime, distinctUntilChanged, map, mergeMap, switchMap, takeUntil, tap, toArray} from 'rxjs/operators'
// import {stringify} from 'csv/lib/sync'
import {ErrorResponse} from '../../../model/responses'
import {MatPaginator} from '@angular/material/paginator'
import {MatSort} from '@angular/material/sort'
import {UntypedFormControl} from '@angular/forms'
import { unparse } from 'papaparse'
import saveAs from 'file-saver'
const equals = require('fast-deep-equal')

const environmentLookup = (account) => [ENVIRONMENTS.TOOLS,ENVIRONMENTS.STAGING, ENVIRONMENTS.UAT, ENVIRONMENTS.PROD].filter(env => !!env).find((v) => v && (v.account === account))

interface ConnectivityMiEvent extends MiEvent {
  source: 'inspire.api-publish'
  detail: {
    latency: number
    requestId: string
    productIdentifier: string
    configPrefix: string
    stageSummary?: {
      analysePayload: {
        result: string
        duration: number
      },
      validatePayload: {
        result: string
        duration: number
      },
      transform: {
        result: string
        duration: number
      },
      enrich: {
        result: string
        duration: number
      },
      retrieve: {
        result: string
        duration: number
        error?: string
      },
      combine: {
        result: string
        duration: number
      },
      encapsulate: {
        result: string
        duration: number
      },
      analyseEnriched: {
        result: string
        duration: number
      },
      validateEnriched: {
        result: string
        duration: number
      },
      publish: {
        result: string
        duration: number
        details?: {
          error?: string
        }[]
      }
    }
    errors?: ErrorResponse
  }
}

interface ConnectivitySummaryEvent extends SummaryEvent {
  timestamp: string
  account: string
  region: string
  analyseEnriched: string
  analysePayload: string
  businessUnit: string
  combine: string
  configPrefix: string
  encapsulate: string
  enrich: string
  latency: number
  payloadType: string
  productIdentifier: string
  publish: string
  publishErrors: string
  publishLatency: number
  requestId: string
  retrieve: string
  retrieveErrors: string
  retrieveLatency: number
  targetSystem: string
  transform: string
  type: string
  validateEnriched: string
  validatePayload: string
}

type ConnectivityEvent = ConnectivityMiEvent | ConnectivitySummaryEvent

const isDetailed = (event: ConnectivityEvent): event is ConnectivityMiEvent => {
  return (event as ConnectivityMiEvent).source === 'inspire.api-publish'
}

interface FlattenedConnectivityMiEvent {
  timestamp: string
  date: string
  time: string
  environment: string
  region: string
  status: string
  sourceIdentifier: string
  sourceSystem: string
  targetSystem: string
  config: string
  latency: number
  validatePayload: string
  analysePayload: string
  transform: string
  enrich: string
  retrieve: string
  combine: string
  encapsulate: string
  analyseEnriched: string
  validateEnriched: string
  publish: string
  retrieveLatency: number
  publishLatency: number
  processingTime: number
  publishError: string
  retrieveError: string
  retrieveDetails: string
  errorResponse: string
}

interface Request {
  environment: Environment
  fromDate: string
  toDate: string
}

@Component({
  selector: 'app-connectivity-management-information',
  templateUrl: './connectivity-management-information.component.html',
  styleUrls: ['./connectivity-management-information.component.scss'],
})
export class ConnectivityManagementInformationComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  loading = true
  cancelRequests = new Subject<void>()

  @Input() environment: Environment
  @Input() fromDate: string
  @Input() toDate: string

  filter = new UntypedFormControl('')

  results: FlattenedConnectivityMiEvent[] = []
  columns: string[] = [
    'date',
    'time',
    'environment',
    'region',
    'status',
    'sourceIdentifier',
    'config',
    'latency',
    'validatePayload',
    'transform',
    'enrich',
    'retrieve',
    'combine',
    'encapsulate',
    'validateTarget',
    'publish',
    'retrieveLatency',
    'publishLatency',
    'processingTime',
  ]

  dataSource = new MatTableDataSource<FlattenedConnectivityMiEvent>()
  requests = new Subject<Request>()

  constructor(private managementInformationService: ManagementInformationService) {
  }

  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  ngOnInit(): void {
    // this.loading = true
    this.requests
      .pipe(
        takeUntil(this.cancelRequests),
        debounceTime(1000),
        distinctUntilChanged(equals),
        tap(_ => this.loading = true),
        tap(request => {
          console.log('Processing: ')
          console.dir(request)
        }),
        switchMap((request: Request) => {
          if (request.environment && request.fromDate) {
            return of(...request.environment.regions)
              .pipe(
                mergeMap((region: Region) => {
                  console.log('Fetching data for ' + region.display)
                  const dateRange = request.fromDate + (request.toDate ? '/' + request.toDate : '')
                  return this.managementInformationService.getManagementInformation<ConnectivityEvent[]>(
                    request.environment.id,
                    region.id,
                    'api-publish',
                    dateRange,
                    !!this.toDate)
                    .pipe(
                      tap(results => {
                        console.log('Fetched Mi Data For ' + request.environment.display + ' in ' + region.display)
                        console.dir(results)
                      }),
                    )
                }),
                toArray(),
                tap((results: ConnectivityEvent[][]) => {
                  console.log('Raw Mi Data')
                  console.dir(results)
                }),
                map((events: ConnectivityEvent[][]): ConnectivityEvent[] => {
                  return events.reduce((r, a) => r.concat(...a), <ConnectivityEvent[]> [])
                  // fileName: `${request.report}-${request.environment.display}-${request.fromDate}${request.toDate ? '-' + request.toDate : ''}.csv`
                  // }
                }),
                tap((results: any[]) => {
                  console.log('Combined Mi Data')
                  console.dir(results)
                }),
                tap(_ => this.loading = false),
              )
          } else {
            return []
          }
        }),
      )
      .subscribe((events: ConnectivitySummaryEvent[]) => {
        const flattened = events.map(e => this.format(e))
        flattened.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
        this.results = flattened
        this.dataSource.data = this.results
        this.dataSource.sort = this.sort
      })
    this.filter.valueChanges
      .pipe(
        takeUntil(this.cancelRequests)
      )
      .subscribe(text => {
        this.dataSource.filter = text
      })
    this.ngOnChanges(undefined)
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.requests.next({
      environment: this.environment,
      fromDate: this.fromDate,
      toDate: this.toDate,
    })
  }

  ngOnDestroy(): void {
    this.cancelRequests.next()
  }

  format(e: ConnectivitySummaryEvent): FlattenedConnectivityMiEvent {
    return {
      timestamp: e.timestamp,
      date: DateTime.fromISO(e.timestamp).toFormat('dd/MM/yyyy'),
      time: DateTime.fromISO(e.timestamp).toFormat('HH:mm:ss'),
      environment: e.account,
      region: e.region,
      status: e.type,
      sourceIdentifier: e.productIdentifier,
      sourceSystem: e.businessUnit,
      targetSystem: e.targetSystem,
      config: e.configPrefix,
      latency: e.latency,
      validatePayload: e.validatePayload,
      analysePayload: e.analysePayload,
      transform: e.transform,
      enrich: e.enrich,
      retrieve: e.retrieve,
      combine: e.combine,
      encapsulate: e.encapsulate,
      analyseEnriched: e.analyseEnriched,
      validateEnriched: e.validateEnriched,
      publish: e.publish,
      retrieveLatency: e.retrieveLatency,
      publishLatency: e.publishLatency,
      processingTime: e.latency - e.retrieveLatency - e.publishLatency,
      publishError: e.publishErrors,
      retrieveError: e.retrieveErrors,
      retrieveDetails: '',
      errorResponse: '',
    };
  }

  downloadCsv() {
    const csv = unparse(this.results, {
      header: true,
    })
    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8'})
    const filename = ('mi-connectivity-' + this.environment.display.toLowerCase() + '-' + this.fromDate + (this.toDate ? '-to-' + this.toDate : '') + '.csv')
    console.log('Saving: ' + filename)
    saveAs(blob, filename)
  }
}
