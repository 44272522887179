import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {MdValidateService} from "../utils/services/md-validate.service";
import { SaveSimpleRuleRequest, SimpleRule } from "../utils/models/md-validation-models";

@Component({
  selector: "app-simple-rule",
  templateUrl: "./simple-rule.component.html",
  styleUrls: ["./simple-rule.component.scss"],
})
export class SimpleRuleComponent implements OnInit {
  @Input() modal: any;
  @Input() simpleRule: SimpleRule;

  @Input() isEditMode: boolean;
  @Output() isEditModeChange = new EventEmitter<boolean>();

  simpleRulesForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private validateService: MdValidateService) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.simpleRulesForm = this.formBuilder.group({
      id: [this.simpleRule ? this.simpleRule.id : ""],
      description: [this.simpleRule ? this.simpleRule.description : "", Validators.required],
      jmespath: [this.simpleRule ? this.simpleRule.jmespath : "", Validators.required],
      calculation: [this.simpleRule ? this.simpleRule.calculation : "", Validators.required],
      failmessage: [this.simpleRule ? this.simpleRule.failmessage : "", Validators.required],
    });
  }

  submit(): void {
    const saveRuleRequest: SaveSimpleRuleRequest = {...this.simpleRulesForm.value, type: 'Simple'}
    this.validateService.saveValidationRule(saveRuleRequest);
    this.isEditModeChange.emit(false);
    this.modal.close();
  }

  closeModal(): void {
    this.isEditModeChange.emit(false);
    this.modal.close();
  }
}
