<div class="container-fluid">
  <h1>Config Filter</h1>

  <div>You can select the configs you would like to work with below and this should simplify working in Storm by
    restricting the tests and rules you see.</div>
</div>

<div class="container-fluid">
  <app-configs-selector [preselected]="preselected" [configs]="configs"
                        (selectedConfigs)="selectConfigs($event)"></app-configs-selector>
</div>
