<h2 mat-dialog-title>{{data.title}}</h2>

<div mat-dialog-content>
  <ng-container *ngFor="let deletion of deletions">
    <div>For {{deletion.configKey}}</div>
    <table>
      <thead>
      <tr>
        <th>Username</th>
        <th>Description</th>
        <th>Timestamp</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>{{deletion.username}}</td>
        <td>{{deletion.comment}}</td>
        <td>{{deletion.timestamp}}</td>
      </tr>
      </tbody>
    </table>
    <p></p>
  </ng-container>
</div>

<div mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="undefined" color="primary">OK</button>
</div>
