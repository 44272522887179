<h2 mat-dialog-title>{{data.title}}</h2>

<div mat-dialog-content>
  <table>
    <tr>
      <td>Jira and description*</td>
      <td>
        <mat-form-field><input matInput [(ngModel)]="data.comment"></mat-form-field>
      </td>
    </tr>
  </table>
</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-button color="primary">Cancel</button>
  <button mat-button [mat-dialog-close]="data" color="warn" [disabled]="!data.comment">OK</button>
</div>
