<app-config-workspace-buttons></app-config-workspace-buttons>
<h2>Preprocessor Logic</h2>
<div class='errors' *ngFor="let error of errors">
  <ngb-alert class='errors' type="danger" >{{ error.message }} This error occur on line number {{error.startLineNumber}}.</ngb-alert>
</div>
<div class="preprocessor-input">
    <ngx-monaco-editor
      class="monaco-editor preprocessor-editor"
      [options]="options"
      [(ngModel)]="preProcessorModel"
      (ngModelChange)="getValue($event)"
    > 
    </ngx-monaco-editor>
    <button class="save" mat-raised-button color="primary" (click)="clickOfBtn()">Save</button>
</div>
 


