import {Injectable} from '@angular/core'
import {BehaviorSubject, of, timer} from 'rxjs'
import {HttpClient} from '@angular/common/http'
import {Auth} from 'aws-amplify'
import {distinctUntilChanged, map, shareReplay, skipWhile, switchMap, tap} from 'rxjs/operators'
import {OPS_URL, valueReplaced} from '../constants'

const deepEqual = require('deep-equal')
const jwt = require('jsonwebtoken')

@Injectable({
  providedIn: 'root',
})
export class TokenService {

  constructor(private httpClient: HttpClient) {
    this.oauthToken.subscribe(token => {
      console.log('Got Oauth Token')
    })
  }

  private static userSessionRefreshTimer = timer(0, 1000)
  private static userSessionObservable = TokenService.userSessionRefreshTimer
    .pipe(
      switchMap(() => {
        return Auth.currentSession()
          .then(userSession => {
            if (userSession?.isValid()) {
              return userSession
            } else {
              return undefined
            }
          })
          .catch(err => {
            return undefined
          })
      }),
      distinctUntilChanged((x, y) => deepEqual(x, y)),
      shareReplay(1),
    )

  private static globalOauthTokenSubject = new BehaviorSubject<string>(undefined)
  hostname = window.location.hostname.includes('localhost') ? valueReplaced(OPS_URL) ? OPS_URL : 'ops.cs.inspire.direct' : window.location.hostname
  apiRoot = 'https://api.' + this.hostname + '/v1'

  idToken = TokenService.userSessionObservable
    .pipe(
      skipWhile( token => !token ),
      map(token => token?.isValid() && token?.getIdToken().getJwtToken()),
      distinctUntilChanged((a, b) => deepEqual(a, b)),
      shareReplay(1),
    )

  accessToken = TokenService.userSessionObservable
    .pipe(
      skipWhile( token => !token ),
      map(token => token?.isValid() && token?.getAccessToken().getJwtToken()),
      distinctUntilChanged((a, b) => deepEqual(a, b)),
      shareReplay(1),
    )

  oauthToken = TokenService.userSessionObservable
    .pipe(
      switchMap(() => this.idToken),
      switchMap(idToken => {
        if (!idToken) {
          TokenService.globalOauthTokenSubject.next(undefined)
          return of(undefined)
        }
        if (TokenService.needsRefreshed()) {
          return this.httpClient.post<EnvironmentToken>(this.apiRoot + '/token', {}, {
            headers: {
              Authorization: 'Bearer ' + idToken,
              Accept: 'application/json',
            },
          }).pipe(
            map(token => token.access_token),
            tap(token => {
              TokenService.globalOauthTokenSubject.next(token)
            }),
          )
        }
        return TokenService.globalOauthTokenSubject.asObservable()
      }),
      distinctUntilChanged((a, b) => deepEqual(a, b)),
      shareReplay(1),
    )

  static needsRefreshed = () => {
    const token = TokenService.globalOauthTokenSubject.getValue()
    if (token) {
      const decoded = jwt.decode(token) as JwtToken
      const now = Date.now() / 1000
      const refreshAt = decoded.exp - 60
      return now > refreshAt
    }
    return true
  }
}

interface EnvironmentToken {
  access_token: string
}

interface JwtToken {
  auth_time: number
  client_id: string
  exp: number
  iat: number
  iss: string
  jti: string
  scope: string
  sub: string
  token_use: string
  version: number
}
