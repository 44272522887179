import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'
import {distinctUntilChanged, shareReplay} from 'rxjs/operators'

export type Theme = string

export const DEFAULT_THEME = 'Clean'

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeBehaviour = new BehaviorSubject<string>(DEFAULT_THEME)
  theme = this.themeBehaviour.pipe(
    distinctUntilChanged(),
    shareReplay(1)
  )

  setTheme(theme: Theme): void {
    const current = this.themeBehaviour.getValue()
    if (theme && current !== theme) {
      this.themeBehaviour.next(theme)
    }
  }

  nextTheme(): void {
    if (this.themeBehaviour.getValue() === 'Storm') {
      this.themeBehaviour.next('Aurora')
    } else if (this.themeBehaviour.getValue() === 'Aurora') {
      this.themeBehaviour.next('Clean')
    } else if (this.themeBehaviour.getValue() === 'Clean') {
      this.themeBehaviour.next('Storm')
    }
  }
}

