import {Injectable} from '@angular/core'
import {ApiService} from './api.service'
import {SmartDxAdminRequest, SmartDxAdminResponse} from '../common/domain/smartdx-admin'
import {Region} from './environment-service'
import {PermissionsService} from './permissions.service'
import {ToastrService} from 'ngx-toastr'

export type Environment = 'TOOLS' | 'DEV' | 'UAT' | 'PROD'

@Injectable({
  providedIn: 'root',
})
export class SmartDxAdminService {
  private readOnly = true

  constructor(private apiService: ApiService,
              private permissionsService: PermissionsService,
              private toast: ToastrService
  ) {
    permissionsService.readonly.subscribe(permission => this.readOnly = permission)
  }

  /**
   * Use to invoke a Smart DX restart in a 'remote' environment, not Storm's native environment.
   */
  restart(env: Environment, region: Region, jiraId: string, jiraDescription: string) {
    if (this.readOnly) {
      this.toast.warning('This would have restarted SmartDX and would have a small outage')
      return
    }
    console.log(`SmartDxAdminService making API call to restart appliance in ${env} / ${region.id}`)
    return this.apiService.post<SmartDxAdminRequest, SmartDxAdminResponse>('/smartdxadmin', {
      action: 'RestartAppliance',
      environment: env,
      region: region.id,
      jiraId: jiraId,
      jiraDescription: jiraDescription,
    })
  }

  redeployTemplates(env: Environment, region: Region, jiraId: string, jiraDescription: string) {
    if (this.readOnly) {
      this.toast.warning('The templates should be redeployed clean into ' + env + ' in ' + region.display)
      return
    }
    console.log(`SmartDxAdminService making API call to redeploy templates in in ${env} / ${region.id}`)
    return this.apiService.post<SmartDxAdminRequest, SmartDxAdminResponse>('/smartdxadmin', {
      action: 'DeployTemplates',
      environment: env,
      region: region.id,
      jiraId: jiraId,
      jiraDescription: jiraDescription,
    })
  }

  flushAppliance(env: Environment, region: Region, jiraId: string, jiraDescription: string) {
    if (this.readOnly) {
      this.toast.warning(`The appliance's cache should be flushed clean into ${env} in ${region.display}`)
      return
    }
    console.log(`SmartDxAdminService making API call to flush appliance cache in ${env} / ${region.id}`)
    return this.apiService.post<SmartDxAdminRequest, SmartDxAdminResponse>('/smartdxadmin', {
      action: 'FlushApplianceCache',
      environment: env,
      region: region.id,
      jiraId: jiraId,
      jiraDescription: jiraDescription,
    })
  }

}
