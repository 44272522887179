import {MatDialog} from '@angular/material/dialog'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {DateTime as LuxonDateTime} from 'luxon'
import {ToastrService} from 'ngx-toastr'
import {AnalyseRow, Deletion, RuleType} from 'src/app/common/domain/analyse'
import {AnalyseConfig} from 'src/app/common/domain/config'
import {DocgenConfig} from 'src/app/common/domain/docgen-config'
import {AnalysisDeleteRuleDialogComponent, DeleteAnalysisRuleDetails} from 'src/app/dialogs/analysis-delete-rule-dialog.component'
import {ConfirmDialogComponent} from 'src/app/dialogs/confirm-dialog.component'
import {ChangeService} from 'src/app/services/change.service'
import {NewConfigRulesComponent} from '../config-rules.component'
import {DisplayableRuleRow, DisplayableRuleTable, RowUsage, Stage} from './interfaces'
import {UserActionDeleteRuleSet} from './user-action-delete-ruleset'
export class UserActionDeleteRule {

    private parentComponent: NewConfigRulesComponent
    private dialog: MatDialog
    private changeService: ChangeService
    private toast: ToastrService
    private modalService: NgbModal

    constructor(
        parentControl: NewConfigRulesComponent,
        dialog: MatDialog,
        changeService: ChangeService,
        toast: ToastrService,
        modalService: NgbModal,
    ) {
        this.parentComponent = parentControl
        this.dialog = dialog
        this.changeService = changeService
        this.toast = toast
        this.modalService = modalService
    }

    public async deleteRule(ruleToDelete: DisplayableRuleRow, ruleset: DisplayableRuleTable) {

        const deletionAuditingDialog = this.dialog.open(AnalysisDeleteRuleDialogComponent, {
            width: '500px',
            data: {
                title: `Rule Deletion`,
            } as DeleteAnalysisRuleDetails,
            panelClass: 'dialogFormField500',
        })

        deletionAuditingDialog.afterClosed().subscribe(async (data: DeleteAnalysisRuleDetails) => {
            if (!data || !data.comment) {
                this.toast.error(`A Jira and description is required`)
                return
            }

            // Is this is a Rationalised Rule?
            // const isProtectedRuleSetRule = this.parentComponent.protectedRuleSets.findIndex(x => x.mnemonic === ruleset.mnemonic) > -1
            // if (!isProtectedRuleSetRule) {
            //     this.askUserWhereToDeleteFrom(ruleToDelete, ruleset, deletion)
            // } else {

            // We know where to delete from:
            const rationalisedRule = this.parentComponent.protectedRuleSets.find(x => x.mnemonic === ruleset.mnemonic)

            if (!rationalisedRule) {
                throw Error(`${ruleset.mnemonic} is not a Protected RuleSet`)
            }

            // Add 'settings/' to start of each path and '/Config.json' to end
            const selectedConfigPaths = rationalisedRule.entries.map((curEntry: string) => 'settings/' + curEntry + '/Config.json')
            const usedBy: RowUsage = ruleToDelete.usedBy

            const deletion = {
                user: this.parentComponent.username,
                timestamp: LuxonDateTime.utc().toISO(), // {suppressMilliseconds: true}
                comment: data.comment,
            } as Deletion
            // Delete from each file in turn
            selectedConfigPaths.forEach((curSelectedConfigPath: string) => {
                const configKey = this.parentComponent.getConfigKeyFromPath(curSelectedConfigPath)
                const list = usedBy[configKey].ruleType
                const stage = usedBy[configKey].stage
                this.deleteRuleFromConfig(curSelectedConfigPath, stage, list, ruleset, ruleToDelete, deletion)
            })

            // } // end else

        }) // end deletionAuditingDialog.afterClosed().subscribe
    }

    // private askUserWhereToDeleteFrom(ruleToDelete: DisplayableRuleRow, ruleset: DisplayableRuleTable, deletion: Deletion) {

    //     // Ask user where to delete this rule from
    //     let configSelectorDialog: MatDialogRef<CreateWorkspaceDialogComponent, any>
    //     const usedBy: RowUsage = ruleToDelete.usedBy
    //     {
    //         const configKeysUsingThisRule: string[] = Object.keys(usedBy)
    //         const configPathsUsingThisRule: string[] = configKeysUsingThisRule.map((configKey: string) => UserActionCommon.getConfigPathFromKey(configKey))
    //         const configsToDisplay: ChangeDetails[] = this.parentComponent.allConfigs
    //             .filter((config: ChangeDetails) => !/^settings\/system\/.+$/.test(config.path))
    //             .filter((config: ChangeDetails) => configPathsUsingThisRule.indexOf(config.path) > -1)
    //             .filter((config: ChangeDetails) => !usedBy[this.parentComponent.getConfigKeyFromPath(config.path)].deletion)

    //         // Open Dialog
    //         configSelectorDialog = this.dialog.open(CreateWorkspaceDialogComponent, {
    //             width: '500px',
    //             data: {
    //                 title: `Select configuration(s) to delete rule from:`,
    //                 configItems: configsToDisplay,
    //                 initialSelections: undefined,
    //             } as ConfigSelectorDialogData,
    //             panelClass: 'dialogFormField500',
    //         })
    //     }

    //     // Do the actual Delete
    //     configSelectorDialog.afterClosed().subscribe(async (selectedConfigs: string[]) => {
    //         if (selectedConfigs && selectedConfigs.length > 0) {

    //             selectedConfigs.forEach((configPath: string) => {
    //                 const configKey = this.parentComponent.getConfigKeyFromPath(configPath)
    //                 const list = usedBy[configKey].ruleType
    //                 const stage = usedBy[configKey].stage
    //                 this.deleteRuleFromConfig(configPath, stage, list, ruleset, ruleToDelete, deletion)
    //             })

    //         }
    //     }) // end configSelectorDialog.afterClosed().subscribe

    // }

    private deleteRuleFromConfig(
        configPath: string, stage: Stage, ruleType: RuleType, ruleset: DisplayableRuleTable,
        rule: DisplayableRuleRow, deletion: Deletion): void {

        const configKey = this.parentComponent.getConfigKeyFromPath(configPath)
        if (rule.usedBy[configKey].deletion) {
            this.toast.error(`Rule in ${configKey} already is deleted...`)
            return
        }

        const configObject = this.changeService.getObject(configPath)
        const config = JSON.parse(configObject.content) as DocgenConfig
        const analyseConfig: AnalyseConfig = (stage === 'product') ? config.analysePayload : config.analyseEnriched
        const existingRuleset = this.parentComponent.findRulesetInList(analyseConfig.rules.filter(r => r.ruleType === ruleType), ruleset)
        // Soft Delete only...decorate the rule with a deletion
        const ruleToDelete = existingRuleset.rule.find((r: AnalyseRow) => {
            return r.mnemonic === rule.mnemonic && r.expected.every((expected: string, index: number) => expected === rule.expected[index])
        })
        ruleToDelete.deletion = deletion
        this.parentComponent.saveChangedConfig(configObject.name, configObject.path, config)

        // If ruleset is now empty then prompt to delete the ruleset
        {
            const postDeletionRuleset = this.parentComponent.findRulesetInList(analyseConfig.rules.filter(r => r.ruleType === ruleType), ruleset)

            const remainingActiveRules = postDeletionRuleset.rule.filter((curRule: AnalyseRow) => {
                return !curRule.deletion
            })

            if (remainingActiveRules.length === 0) {
                const confirmRef = this.modalService.open(ConfirmDialogComponent, {
                    size: 'lg',
                    backdrop: false,
                })

                confirmRef.componentInstance.action = 'Delete'
                confirmRef.componentInstance.title = 'Ruleset is empty'
                confirmRef.componentInstance.message = `RuleSet in ${configPath} is now Empty. Delete the RuleSet too?`

                confirmRef.result
                    .then(res => {
                        // Do the Delete
                        const userActionDeleteRuleSet = new UserActionDeleteRuleSet(this.parentComponent, this.dialog, this.changeService, this.toast, this.modalService)
                        userActionDeleteRuleSet.deleteRulesetFromConfig(configPath, stage, ruleType, ruleset, deletion)
                    })
                    .catch(err => {
                        this.toast.warning('An empty ruleset has been left.')
                    })
            }
        }
    }

}
