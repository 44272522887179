import {Component, OnDestroy, OnInit} from '@angular/core';
import {jsonEditorOptions, xmlEditorOptions} from '../../util/editor-configurations';
import {PermissionsService} from '../../services/permissions.service';
import {Subscription} from 'rxjs';
import {RegxchangeAdminService} from '../../services/regxchange-admin.service';
import {TestManagerService} from '../../services/test-manager.service';
import {Environment, ENVIRONMENTS, EnvironmentService} from '../../services/environment-service';
import {ToastrService} from 'ngx-toastr';
import { ApiSelectorOption, MimeTypeOption } from 'src/app/model/test-management-requests';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-admin-regxchange',
  templateUrl: './admin-regxchange-post.component.html',
  styleUrls: ['./admin-regxchange-post.component.scss']
})
export class AdminRegxchangePostComponent implements OnInit, OnDestroy{

  constructor(private permissionsService: PermissionsService,
              private regxchangeAdminService: RegxchangeAdminService,
              private environmentService: EnvironmentService,
              private toast: ToastrService) { }

  private subscriptions = new Subscription()
  public payloadEditorConfig = jsonEditorOptions;
  public responseConfig = jsonEditorOptions;
  public payloadValueBody = '';
  public hasRegxchangeCSPRODSupportPermission: boolean
  public hasRegxchangeCSUATSupportPermission: boolean
  public validPayload = true
  public regxchangeSchema = ''
  public responseBody: string;
  private targetEnvironment: Environment

  regxchangeApiOptions: ApiSelectorOption[] = [{
    id: 'EMT',
    display: 'EMT (default)',
    description: 'EMT'
  },
  {
    id: 'EET',
    display: 'EET',
    description: 'EET'
  }]

  mimeTypeOptions: MimeTypeOption[] = [{
    id: 'application/json',
    display: 'JSON (default)',
    description: 'JSON'
  },
  {
    id: 'application/xml',
    display: 'XML',
    description: 'XML'
  }]

  public selectedRegxchangeApiOption = this.regxchangeApiOptions[0];
  public selectedMimeTypeOption = this.mimeTypeOptions[0];

  selectRegxApi(event: MatSelectChange) {
    this.selectedRegxchangeApiOption = event.value;
  }
  
  selectMimeType(event: MatSelectChange) {
    this.selectedMimeTypeOption = event.value;
    if (this.selectedMimeTypeOption.id === 'application/json')
      this.payloadEditorConfig = jsonEditorOptions
    else
      this.payloadEditorConfig = xmlEditorOptions
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.permissionsService.regxchangeCSPRODSupport.subscribe(permission => this.hasRegxchangeCSPRODSupportPermission = permission),
    )
    this.subscriptions.add(
      this.permissionsService.regxchangeCSUATSupport.subscribe(permission => this.hasRegxchangeCSUATSupportPermission = permission),
    )
    this.subscriptions.add(
      this.environmentService.sourceEnvironment.subscribe(env => this.targetEnvironment = env)
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }

  saveToRegxchange() {
    if (this.targetEnvironment === ENVIRONMENTS.PROD) {
      if (!this.hasRegxchangeCSPRODSupportPermission) {
        this.toast.warning('This action requires regxchange Production support permissions')
        return
      }
    }
    if (this.targetEnvironment === ENVIRONMENTS.UAT) {
      if (!this.hasRegxchangeCSUATSupportPermission) {
        this.toast.warning('This action requires regxchange UAT support permissions')
        return
      }
    }
    this.regxchangeAdminService.postPayloadToRegxchange(this.payloadValueBody, this.selectedMimeTypeOption.id, this.regxchangeSchema, this.selectedRegxchangeApiOption.id).subscribe(response => {
      const responseObj = (typeof response === 'string') ? JSON.parse(response) : response
      this.responseBody = responseObj['data'] ? JSON.stringify(responseObj['data'], undefined, 2) :
        JSON.stringify(responseObj, undefined, 2);
    })
  }

  refresh() {}
}
