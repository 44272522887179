import {Component, Inject, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {MdPayload} from "../utils/models/md-payload-model";
import {NewPayloadComponent} from "../newPayloadDialog/new-payload.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ManualDraftingAdminPayloadService} from "src/app/components/manual-drafting/utils/services/manual-drafting-administer-payload.service";
import {BehaviorSubject, Subscription} from "rxjs";
import {DOCUMENT} from "@angular/common";
import {ManualDraftingGendocService} from "../utils/services/manual-drafting-gendoc.service";
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-admin-payloads',
  templateUrl: './admin-payloads.component.html',
  styleUrls: ['./admin-payloads.component.scss'],
})
export class AdminPayLoadComponent implements OnInit {
  orderByASC = true;
  sortAlpha = false;
  showDeleted = false;
  showCompleted = true;
  showDraft = true;
  showNew = true;
  showFailed = true;
  showRestored = true;
  allPayload: MdPayload[] = [];
  deletedPayload: MdPayload[] = [];
  displayAdminScreenData: MdPayload[] = [];
  sub = new Subscription();
  selectedPayload: MdPayload
  refreshOnClosedPayloadEditorSubject: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false)
  private subscriptionsArray: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    private modalService: NgbModal,
    private administerPayloadService: ManualDraftingAdminPayloadService,
    private gendocService: ManualDraftingGendocService,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  async ngOnInit() {
    this.subscriptionsArray.push(this.gendocService.smartdxSuccesResponseSubject.subscribe(payloadId => {
      if(payloadId) {
        this.download(payloadId)
      }
    }))

    this.subscriptionsArray.push(this.refreshOnClosedPayloadEditorSubject.subscribe(async (refresh) => {
      if(refresh) {
        await this.getAndDisplayPayloads()
      }
    }))

    await this.getAndDisplayPayloads()
  }

  ngOnDestroy(): void {
    this.subscriptionsArray.forEach(subscription => {
      subscription.unsubscribe()
    })
  }

  async getAndDisplayPayloads() {
    this.resetArray();
    await this.administerPayloadService.getPayloadsLists().then((res) => {
      const payloadResponse = res as any as MdPayload[]
      payloadResponse.map((payload: MdPayload) => {
        payload.date_created = payload.date_created.replace('T', ' ');
        payload.date_created = payload.date_created.replace('.000Z', '');
        if (!payload.deleted) {
          return this.allPayload.push(payload);
        } else {
          return this.deletedPayload.push(payload);
        }
      });

      this.displayAdminScreenData = this.allPayload;
    });
    this._applyUserFilterToDisplayData()
    if(this.showDeleted) {
      this.filterData('DELETED',this.showDeleted)
    }
  }

  createNewPayLoadDialog() {
    this.dialog.open(NewPayloadComponent, {
      width: '90%',
      height: '85%',
    }).afterClosed().subscribe(async result => {
      if (result) {
        this.getAndDisplayPayloads()
      }
    })
  }

   editPayload(selectedId: string) {
    if (selectedId) {
      const obj = this.allPayload.filter((payload) => {
        return payload.id === selectedId;
      });

       this.dialog.open(NewPayloadComponent, {
        width: "90%",
        height: "85%",
        data: {
          payload: obj,
        },
      }).afterClosed().subscribe(result => {
       if(result) {
          this.getAndDisplayPayloads()
        }
      })

    }

  }

  async softDeletePayload(id: string) {
    const softDelete = await this.administerPayloadService.softDeletePayload(id)
    if(softDelete) {
      this.getAndDisplayPayloads()
    }
  }

  async restorePayload(id: string) {
    const restoreData = await this.administerPayloadService.restorePayload(id)
    if(restoreData) {
      this.getAndDisplayPayloads()
    }
  }

  sortByDate() {
    this.orderByASC = !this.orderByASC;

    if (this.orderByASC) {
      this.displayAdminScreenData.sort(
      (a, b) => a.date_created < b.date_created ? -1 : 1
      );
    } else {
      this.displayAdminScreenData.sort(
        (a, b) => a.date_created < b.date_created ? 1 : -1
      );
    }
  }

  sortAlphabetically(value?: string) {
    this.sortAlpha = !this.sortAlpha;

    if (this.sortAlpha) {
      if (value === 'status') {
        this.displayAdminScreenData.sort((a, b) =>
          a.status.localeCompare(b.status)
        );
      }
      if (value === 'description') {
        this.displayAdminScreenData.sort((a, b) => {
          return a.description.localeCompare(b.description);
        });
      }
    } else {
      return this.displayAdminScreenData.sort().reverse();
    }
  }

  resetArray() {
    if (this.allPayload.length) {
      this.allPayload.splice(0);
    }
    if (this.deletedPayload.length) {
      this.deletedPayload.splice(0);
    }

    if (this.displayAdminScreenData.length) {
      this.displayAdminScreenData.splice(0);
    }
  }

  openJsonEditor(content) {
    this.modalService.open(content, {
      // ariaLabelledBy: "modal-basic-title",
      windowClass: 'json-editor-modal',
      scrollable: true,
      size: 'xl',
      centered: true,
      backdrop: 'static',
    });
  }

  public togglePayload(event: MouseEvent): void {
    const payloadStatus = (event.target as Element).id.toUpperCase();

    switch (payloadStatus) {
      case 'NEW': {
        this.showNew = !this.showNew;
        this._applyUserFilterToDisplayData();
        this.filterData(payloadStatus, this.showNew);
        break;
      }
      case 'COMPLETED': {
        this.showCompleted = !this.showCompleted;
        this._applyUserFilterToDisplayData();
        this.filterData(payloadStatus, this.showCompleted);
        break;
      }
      case 'DELETED': {
        this.showDeleted = !this.showDeleted;
        this._applyUserFilterToDisplayData();
        this.filterData(payloadStatus, this.showDeleted);
        break;
      }
      case 'DRAFT': {
        this.showDraft = !this.showDraft;
        this._applyUserFilterToDisplayData();
        this.filterData(payloadStatus, this.showDraft);
        break;
      }
      case 'FAILED': {
        this.showFailed = !this.showFailed;
        this._applyUserFilterToDisplayData();
        this.filterData(payloadStatus, this.showFailed);
        break;
      }
      case 'RESTORED': {
        this.showRestored = !this.showRestored;
        this._applyUserFilterToDisplayData();
        this.filterData(payloadStatus, this.showRestored);
        break;
      }
      default: {
        throw new Error(`Unexpected value for list: ${payloadStatus}`);
      }
    }
  }

  private _applyUserFilterToDisplayData() {
    const ruleSetsToDisplay = this.displayAdminScreenData.filter((payload) => {
      let displaySelectedPayload = false;
      displaySelectedPayload = displaySelectedPayload || (this.showNew && payload.status === 'NEW');
      displaySelectedPayload = displaySelectedPayload || (this.showCompleted && payload.status === 'COMPLETED');
      displaySelectedPayload = displaySelectedPayload || (this.showDeleted && payload.status === 'DELETED');
      displaySelectedPayload = displaySelectedPayload || (this.showDraft && payload.status === 'DRAFT');
      displaySelectedPayload = displaySelectedPayload || (this.showFailed && payload.status === 'FAILED');
      displaySelectedPayload = displaySelectedPayload || (this.showRestored && payload.status === 'RESTORED');


      return displaySelectedPayload;
    });

    this.displayAdminScreenData = ruleSetsToDisplay;
  }

  filterData(status: string, isTrue: boolean) {
    const arr: MdPayload[] = [];
    const statusArr = ['NEW', 'DRAFT', 'FAILED', 'COMPLETED', 'DELETED', 'RESTORED'];
    if (statusArr.includes(status)) {
      if (isTrue || status === 'DELETED') {
        const val =
          this.displayAdminScreenData.filter((e) => e.status === status)
            .length > 0;

        if (!val) {
          this.allPayload.map((payload) => {
            if (payload.status === status) {
              arr.push(payload);
            }
          });
        }
      }
    }

    status === 'DELETED' && this.showDeleted
      ? (this.displayAdminScreenData = [
          ...this.displayAdminScreenData,
          ...this.deletedPayload,
        ])
      : (this.displayAdminScreenData = [
          ...this.displayAdminScreenData,
          ...arr,
        ]);

  }

  download(payloadId) {
    this.administerPayloadService.getWordDoc(payloadId).subscribe(result => {
      const byteArr = this.convertbase64toArrayBuffer(result.body);
      const blob = new Blob([byteArr], {type: 'application/msword'})
      saveAs(blob, `${payloadId}.doc`)
    })
  }

  convertbase64toArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

}
