import cloneDeep from 'clone-deep'
import {DateTime} from 'luxon'
import {AnalyseRuleTable, Deletion} from 'src/app/common/domain/analyse'
import {ChangeDetails} from 'src/app/common/domain/change'
import {AnalyseConfig} from 'src/app/common/domain/config'
import {DocgenConfig} from 'src/app/common/domain/docgen-config'
import {ProtectedRuleSet} from 'src/app/model/rationalised-rule'
import {ChangeService} from 'src/app/services/change.service'

export class RulesetEditSaveLogic {

    private readonly _masterRuleSet: AnalyseRuleTable
    public productDocumentsSelectedInTreeView: Array<string>

    constructor(
        private readonly _username: string,
        private readonly _changeService: ChangeService,
        private readonly _protectedRuleSetOrginal: ProtectedRuleSet,
        private readonly _newDescription: string
    ) {
        this._masterRuleSet = this._getMasterRuleSet()
    }

    public synchroniseDescriptionAcrossProductDocumentConfigFiles(newDescription: string) {
        if (this._newDescription !== this._masterRuleSet.name) {

            // Change even files were are going to soft delete (in case they are undeleted later)
            const allEntries = this._protectedRuleSetOrginal.entries

            allEntries.forEach((curPath) => {
                // Get current version from change service (this could be from a Workspace)
                const changeDetails: ChangeDetails = this._changeService.getObject(`settings/${curPath}/Config.json`)
                {
                    const docGenConfig: DocgenConfig = JSON.parse(changeDetails.content)
                    const curRuleSetsAnalyseConfig: AnalyseConfig = this._protectedRuleSetOrginal.stage === 'solo' ? docGenConfig.analyseEnriched : docGenConfig.analysePayload
                    const curRuleSetToModify: AnalyseRuleTable = curRuleSetsAnalyseConfig.rules.find(x => x.mnemonic.toLowerCase() === this._protectedRuleSetOrginal.mnemonic.toLowerCase())
                    curRuleSetToModify.name = newDescription
                    changeDetails.content = JSON.stringify(docGenConfig, null, 2)
                    changeDetails.state = 'modified'
                }
                // Send current version to change service (this could be to a Workspace)
                // this._changeService.addChange(changeDetails)
                this._changeService.upsertObject(changeDetails)
            })
        }
    }

    public addRuleSetToProductDocumentConfigFiles(filesToAddRuleSetTo: string[]) {
        // xyzzy5 Support removing a soft-delete, because Users sometimes relax rules temporarily

        const masterRuleSet: AnalyseRuleTable = this._getMasterRuleSet()

        filesToAddRuleSetTo.forEach((curPath) => {
            // plugh const curDocgenConfig = this.dialogData.changeService.getDocgenConfigObject(curPath)
            // Get current version from change service (this could be from a Workspace)
            const changeDetails: ChangeDetails = this._changeService.getObject(`settings/${curPath}/Config.json`)
            {
                const docGenConfig: DocgenConfig = JSON.parse(changeDetails.content)
                const curRuleSetsAnalyseConfig: AnalyseConfig = this._protectedRuleSetOrginal.stage === 'solo' ? docGenConfig.analyseEnriched : docGenConfig.analysePayload

                // Is there a soft-deleted entry for this RuleSet?
                const existingSoftDeleteRuleSet: AnalyseRuleTable = curRuleSetsAnalyseConfig.rules.find(x => x.mnemonic.toLowerCase() === this._protectedRuleSetOrginal.mnemonic.toLowerCase())
                if (existingSoftDeleteRuleSet !== undefined && existingSoftDeleteRuleSet.deletion !== undefined) {
                    // Remove the Soft-Deletion
                    existingSoftDeleteRuleSet.deletion = undefined
                } else {
                    curRuleSetsAnalyseConfig.rules.push(cloneDeep(masterRuleSet, true))
                }
                // Save current version to change service (this could be to a Workspace)
                // this._changeService.addChange({
                //     source: 'lambda-ingest-product',
                //     name: changeDetails.name,
                //     path: changeDetails.path,
                //     content: JSON.stringify(docGenConfig, undefined, 2),
                //     size: undefined,
                //     hash: undefined,
                //     modified: undefined,
                //     state: undefined,
                // } as ConfigDetail)

                // Send modified JSON to change service (this could be to a Workspace)
                changeDetails.content = JSON.stringify(docGenConfig, undefined, 2)
                this._changeService.upsertObject(changeDetails)

            }
        })
    }

    public softDeleteRuleSetFromProductDocumentConfigFiles(entriesToDelete: string[]) {
        const deletion = {
            user: this._username,
            timestamp: DateTime.utc().toISO(), // {suppressMilliseconds: true}
            comment: `Deleted via Storm UI`,
        } as Deletion

        entriesToDelete.forEach((curPath) => {
            // plugh const curDocgenConfig = this.dialogData.changeService.getDocgenConfigObject(curPath)
            // Get current version from change service (this could be from a Workspace)
            const changeDetails: ChangeDetails = this._changeService.getObject(`settings/${curPath}/Config.json`)
            const docGenConfig: DocgenConfig = JSON.parse(changeDetails.content)
            {
                const curRuleSetsAnalyseConfig: AnalyseConfig = this._protectedRuleSetOrginal.stage === 'solo' ? docGenConfig.analyseEnriched : docGenConfig.analysePayload
                const ruleSetToDelete: AnalyseRuleTable = curRuleSetsAnalyseConfig.rules.find(x => x.mnemonic.toLowerCase() === this._protectedRuleSetOrginal.mnemonic.toLowerCase())
                ruleSetToDelete.deletion = deletion
            }
            // Save current version to change service (this could be to a Workspace)
            // this._changeService.addChange({
            //     source: 'lambda-ingest-product',
            //     name: changeDetails.name,
            //     path: changeDetails.path,
            //     content: JSON.stringify(docGenConfig, undefined, 2),
            //     size: undefined,
            //     hash: undefined,
            //     modified: undefined,
            //     state: undefined,
            // } as ConfigDetail)

            // Send modified JSON to change service (this could be to a Workspace)
            changeDetails.content = JSON.stringify(docGenConfig, undefined, 2)
            this._changeService.upsertObject(changeDetails)

            // this._changeService.addChange(changeDetails)
            // this._parentControl.saveChangedConfig(configObject.name, configObject.path, docGenConfig)
        })
    }

    public updateProtectedRuleSetDefinitionFile(entriesToDelete: string[], entriesToAdd: string[]) {
        // Find RuleSet protection File
        const changeDetails: ChangeDetails = this._changeService.getObject('protected-rulesets/' + this._protectedRuleSetOrginal.mnemonic.toUpperCase() + '.json')
        {
            const protectedRuleSet: ProtectedRuleSet = JSON.parse(changeDetails.content)
            {
                // Delete Product / Documents that no longer contain this RuleSet
                protectedRuleSet.entries = protectedRuleSet.entries.filter(x => !entriesToDelete.includes(x)) as string[]
                // Add the new Product / Documents that now contain this RuleSet
                protectedRuleSet.entries.push(...entriesToAdd)
                // Sort (for consitency - we store the list of files in sorted orders)
                protectedRuleSet.entries.sort()
                protectedRuleSet.lastUpdated = DateTime.utc().toISO() // {suppressMilliseconds: true}
                // stringify object to send to Lamda
                changeDetails.content = JSON.stringify(protectedRuleSet, null, 2)
            }
            changeDetails.state = 'modified'
        }
        // Save current version to change service (this could be to a Workspace)
        // this._changeService.addChange(changeDetails)
        this._changeService.upsertObject(changeDetails)
    }

    private _getMasterRuleSet(): AnalyseRuleTable {
        // First record in the Protected RuleSet is considered the Master
        const masterDocgenConfigPath = this._protectedRuleSetOrginal.entries[0]
        // Get current version from change service (this could be from a Workspace)
        const changeDetails: ChangeDetails = this._changeService.getObject(`settings/${masterDocgenConfigPath}/Config.json`)
        const masterDocGenConfig: DocgenConfig = JSON.parse(changeDetails.content)
        const curRuleSetsAnalyseConfig: AnalyseConfig = this._protectedRuleSetOrginal.stage === 'solo' ? masterDocGenConfig.analyseEnriched : masterDocGenConfig.analysePayload
        const masterRuleSet = curRuleSetsAnalyseConfig.rules.find(x => x.mnemonic.toLowerCase() === this._protectedRuleSetOrginal.mnemonic.toLowerCase())

        return masterRuleSet
    }

    private _getTargetRuleSetFromChangeDetails(changeDetails: ChangeDetails): AnalyseRuleTable {
        const docGenConfig: DocgenConfig = JSON.parse(changeDetails.content)
        const curRuleSetAnalyseConfig: AnalyseConfig = this._protectedRuleSetOrginal.stage === 'solo' ? docGenConfig.analyseEnriched : docGenConfig.analysePayload
        const targetRuleSet: AnalyseRuleTable = curRuleSetAnalyseConfig.rules.find(x => x.mnemonic.toLowerCase() === this._protectedRuleSetOrginal.mnemonic.toLowerCase())

        return targetRuleSet
    }

}
