<div class="md-form form-inline form-group" *ngIf="selectedRegion">
  <mat-form-field class="rule-type-input">
    <mat-label>Environment</mat-label>
    <mat-select (selectionChange)="selectEnvironment($event)" [value]="selectedEnvironment" placeholder="Select">
      <mat-option *ngFor="let opt of environments" [value]="opt" [title]="opt.description">{{opt.display}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="rule-type-input" *ngIf="regions.length > 1">
    <mat-label>Region</mat-label>
    <mat-select (selectionChange)="selectRegion($event)" [value]="selectedRegion" placeholder="Select">
      <mat-option *ngFor="let opt of regions" [value]="opt">{{opt.display}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
