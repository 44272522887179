import {
  DocgenRequestResponse,
  DocgenTraceResponse,
  Environment,
  ErrorApiResponse,
  PublishRequestResponse,
  PublishTraceResponse,
} from '../common/domain/ingest'
import {DocgenEventType, PublishEventType} from '../common/domain/events'
import {DocumentMessage} from '../common/domain/render'
import {AnalyseRuleTable} from '../common/domain/analyse';

export interface IngestProductCommonResponse extends ErrorApiResponse{
  env: Environment
  jiraRaised: boolean
  sparrowWorkItem: boolean
  latency: number
  errorResponse?: ErrorApiResponse
}

export interface IngestProductPublishResponse extends IngestProductCommonResponse, PublishRequestResponse {
  published: boolean
  eventsRaised: PublishEventType[]
}

export interface IngestProductDocgenResponse extends IngestProductCommonResponse, DocgenRequestResponse {
  messages?: DocumentMessage[]
  eventsRaised: DocgenEventType[]
  documentReturned: boolean
  thunderheadFallback: boolean
  ignoredRules?: IgnoredRules
}

export interface ZippedTraceResponse {
  zip: string
}
export const isZippedTraceResponse = (request: any): request is ZippedTraceResponse => {
  return (request as ZippedTraceResponse).zip !== undefined
}

export type IngestProductPublishTraceResponse = IngestProductPublishResponse & PublishTraceResponse
export type IngestProductDocgenTraceResponse = IngestProductDocgenResponse & DocgenTraceResponse
export const isIngestProductPublishTraceResponse = (request: any): request is IngestProductPublishTraceResponse => {
  return (request as IngestProductPublishTraceResponse).env !== undefined
}
export type PublishSuccessApiResponse = {
  status: 'OK'
}
export type DocgenSuccessApiResponse = {
  payload: string
  mimeType: string
}
export type PublishApiResponse = PublishSuccessApiResponse | ErrorApiResponse | IngestProductPublishTraceResponse
export type DocgenApiResponse = DocgenSuccessApiResponse | ErrorApiResponse | IngestProductDocgenTraceResponse

export type ApiTraceResponse = IngestProductDocgenTraceResponse | IngestProductPublishTraceResponse

export type UnsupportedProductResponse = ErrorApiResponse & {
  status: 'UNSUPPORTED'
}
export const isUnsupportedProductResponse = (request: any): request is UnsupportedProductResponse => {
  return (request as UnsupportedProductResponse).status === 'UNSUPPORTED'
}
export type RejectedProductResponse = ErrorApiResponse & {
  status: 'REJECTED'
}
export const isRejectedProductResponse = (request: any): request is RejectedProductResponse => {
  return (request as RejectedProductResponse).status === 'REJECTED'
}
export type ErrorProductResponse = ErrorApiResponse & {
  status: 'ERROR'
}
export const isErrorProductResponse = (request: any): request is ErrorProductResponse => {
  return (request as ErrorProductResponse).status === 'ERROR'
}

export type IgnoredRules = {
  analysisPayload: AnalyseRuleTable[],
  analysisEnriched: AnalyseRuleTable[]
}
