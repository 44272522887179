<div class='flex-page-container local-dialog-container-padding'>
  <div class="flex-row inspire-surround-header-flex">
    <span class="h3">Chart Rendering&nbsp;</span>
    <span>&nbsp;(Config Map: {{ configMap }})</span>
  </div>


  <div class='flex-content-container'>

    <div class='flex-right-main-content'>

      <div class='inspire-surround-flex'>
        <div class="inspire-surround-flex flex-column-grow-one">
          <h5>Uses chart rendering?</h5>
          <!-- <h5>useChartRendering: {{ useChartRendering }} -- type: {{ getTypeOf(useChartRendering) }} -- selected: {{ useChartRendering === 'true' ? 'Yes' : 'No' }}</h5> -->
          <select name="useChartRendering" (change)="useChartRendering = $event.target.value">
            <option [value]="true" [selected]="useChartRendering === 'true'">Yes</option>
            <option [value]="false" [selected]="useChartRendering === 'false'">No</option>
          </select>
        </div>

        <div class="inspire-surround-flex flex-column-grow-one">
          <h5>Uses legacy rendering?</h5>
          <select name="useLegacyMode" (change)="useLegacyMode = $event.target.value">
            <option [value]="true" [selected]="useLegacyMode === 'true'">Yes</option>
            <option [value]="false" [selected]="useLegacyMode === 'false'">No</option>
          </select>
        </div>

      </div>
      <div class='inspire-surround-flex'>
        <div class="inspire-surround-flex flex-column-grow-one">
          <h5>Chart Setup Details</h5>
          <ngx-monaco-editor class="monaco-editor payload-editor" [options]="options" [(ngModel)]="chartMetadata">
          </ngx-monaco-editor>
        </div>


      </div>
    </div>

  </div>


  <div>
    <div class='flex-row-grow-one inspire-surround-flex'>
      <button type="button" class="btn btn-primary mr-2" (click)="ok()">Save</button>
      <button type="button" class="btn btn-secondary" (click)="this.cancel()">Cancel</button>
    </div>
  </div>
</div>
