import {Component, Input, OnInit} from '@angular/core';
import {jsonEditorOptions, xmlEditorOptions} from '../../util/editor-configurations';
import {TestInfo} from '../playground-model';
import {BehaviorSubject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {DocgenPayloadInformation, PayloadInformation, TestDefinition, TestType} from '../../common/domain/tests';
import {GenerateDocumentRequest} from '../../model/generate-document-request';
import {GenerateDocumentStatus} from '../../model/generate-document-status';
import {MatDialog} from '@angular/material/dialog';
import {TestManagerService} from '../../services/test-manager.service';
import {ToastrService} from 'ngx-toastr';
import {SavedTestMetadata} from '../../model/test-management-responses';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertDialogComponent} from '../../dialogs/alert-dialog/alert-dialog.component';
import {SourceFormat} from '../../common/domain/ingest';

declare var $: any;
const xmlParser = require('fast-xml-parser')

@Component({
  selector: 'app-listing-payload',
  templateUrl: './listing-payload.component.html',
  styleUrls: ['./listing-payload.component.scss']
})
export class ListingPayloadComponent implements OnInit {

  static DEBOUNCE_TIME = 500
  @Input() allowDelete = false;

  payloadEditorConfig = xmlEditorOptions;
  payloadValueBody = '';
  validPayload: boolean;
  activeTab: string;
  productsList: {key: string, payload: TestInfo}[] = [];
  requestParams = new GenerateDocumentRequest()
  documentStatus = new GenerateDocumentStatus()
  testDefinition: TestDefinition
  sub = new Subscription()

  payloadSubject = new BehaviorSubject<string>('')
  payload = this.payloadSubject.pipe(
    debounceTime(250),
    distinctUntilChanged(),
  )

  formatSubject = new BehaviorSubject<SourceFormat>(undefined)
  format = this.formatSubject.pipe(
    distinctUntilChanged(),
  )

  constructor(public dialog: MatDialog,
              private testManager: TestManagerService,
              private toast: ToastrService,
              private modalService: NgbModal) {
    this.validPayload = true
  }

  ngOnInit(): void {
    this.testManager.setService('italianlisting')
    this.sub.add(
      this.format.subscribe(format => {
        this.validPayload = format !== undefined
      }),
    )
    this.sub.add(
      this.testManager.tests.pipe(
        map(products => {
          return products.reduce((payload: TestInfo[], t: SavedTestMetadata & { product: DocgenPayloadInformation }) => {
            const meta = t.product
            if (meta.isin && meta.sourceRef) {
              if (!payload.find(x => x.isin === meta.isin && x.ref === meta.sourceRef)) {
                return payload.concat({
                  isin: meta.isin,
                  ref: meta.sourceRef,
                  test: t,
                  name: t.name,
                  visible: true,
                })
              }
            }
            return payload
          }, [])
        }),
      ).subscribe(products => {
        products.forEach(p => {
          this.productsList.push({key: p.name, payload: p})
        })
      } )
    )
    this.sub.add(
      this.payload
        .pipe(
          debounceTime(ListingPayloadComponent.DEBOUNCE_TIME),
          distinctUntilChanged(),
        )
        .subscribe(payload => {
          if (this.requestParams.payload !== payload) {
            console.log('Payload has been updated')
            this.requestParams.payload = payload
          }
          let newFormat: SourceFormat
          try {
            if (payload.startsWith('{')) {
              JSON.parse(payload)
              newFormat = 'json'
            } else if (payload.startsWith('<')) {
              if (xmlParser.validate(payload) === true) {
                newFormat = 'xml'
              }
            }
          } catch (e) {
          }
          this.formatSubject.next(newFormat)
        }),
    )
  }

  refresh() {
    this.payloadSubject.next(this.payloadValueBody)
  }

  testReset() {
    this.payloadValueBody = '';
  }

  async saveTestPayload() {
    this.save(undefined, 'Payload')
  }

  formatPayloadKey(): string {
    let isin: string;
    let sparrowref: string;
    const parser = new DOMParser();
    const dom = parser.parseFromString(this.payloadValueBody, 'application/xml');
    const childElements = dom.getElementsByTagName('productDetails').item(0).children;
    for (let i = 0; i < childElements.length; i++) {
      if (childElements[i].tagName === 'isin') {
        isin = childElements[i].textContent
      }
      if (childElements[i].tagName === 'ref') {
        sparrowref = childElements[i].textContent
      }
    }
    this.validPayload = !(isin === undefined || sparrowref === undefined || isin === '' || sparrowref === '');
    return isin + '-' + sparrowref;
  }

  selectProduct() {
    this.testManager.selectedTestName.subscribe(name => {
      if (name) {
        this.testManager.getTest(name).subscribe(test => {
          this.payloadValueBody = test.payload;
        })
      }
    })
  }

  save(document, testType: TestType) {
    const key = this.formatPayloadKey();
    if (this.validPayload) {
      const product: PayloadInformation = {
        service: 'italianlisting',
        documentFormat: this.requestParams.documentFormat,
        documentType: this.requestParams.documentType,
        productType: this.requestParams.productType,
        programme: this.requestParams.programme,
        sourceSystem: this.requestParams.sourceSystem,
        sourceFormat: this.requestParams.sourceFormat,
      }
      const test: TestDefinition = {
        name: key,
        testType,
        document,
        enriched: this.documentStatus && this.documentStatus.enriched,
        payload: this.payloadValueBody,
        product,
        migrated: this.testDefinition && this.testDefinition.migrated,
        config: undefined,
        expectedOutcome: 'Allow',
        schemas: {},
        transforms: {},
      }

      this.testManager.saveTest(test)
        .then(res => {
          this.toast.success('Saved test case')
        })
    } else {
      this.openInvalidPayloadPopup()
    }
  }

  async openInvalidPayloadPopup() {
    await this.dialog.open(AlertDialogComponent, {
      position: {top: '200px'},
      data: {
        title: 'Invalid Payload',
        message: 'Isin or sparrow ref are not defined',
      },
    }).afterClosed().toPromise();
  }
}
