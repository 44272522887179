import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core'
import {COLOURS} from './colors'
import {GovernanceResponse} from '../model/governance-responses'

@Component({
  selector: 'app-product-governance-status',
  templateUrl: './product-governance-status.component.svg',
})
export class ProductGovernanceStatusComponent implements OnInit, OnChanges {
  @Input() width = 1920
  @Input() height = 800
  @Input() governanceResponse: GovernanceResponse
  @Input() refresh: string

  lambdas = {
    ingest: {
      colours: COLOURS.UNKNOWN,
    },
    validatePayload: {
      colours: COLOURS.UNKNOWN,
    },
    transform: {
      colours: COLOURS.UNKNOWN,
    },
    validateEnriched: {
      colours: COLOURS.UNKNOWN,
    },
    analyseEnriched: {
      colours: COLOURS.UNKNOWN,
    },
  }

  apigateway = {
    colours: COLOURS.UNKNOWN,
  }

  icons = {
    sparrow: {
      colours: COLOURS.UNKNOWN,
    },
  }

  labels = {
    ingest: {
      colours: COLOURS.UNKNOWN,
    },
    transform: {
      colours: COLOURS.UNKNOWN,
    },
    validate: {
      colours: COLOURS.UNKNOWN,
    },
    analyse: {
      colours: COLOURS.UNKNOWN,
    },
  }

  lines = {
    apiToIngest: {
      colours: COLOURS.UNKNOWN,
    },
    ingestToTransform: {
      colours: COLOURS.UNKNOWN,
    },
    ingestToValidate: {
      colours: COLOURS.UNKNOWN,
    },
    enrichToAnalyse: {
      colours: COLOURS.UNKNOWN,
    },
    enrichToValidate: {
      colours: COLOURS.UNKNOWN,
    },
    apiToSparrow: {
      colours: COLOURS.UNKNOWN,
    },
  }

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.governanceResponse) {
      this.reset()
      return
    }
    this.lines.apiToIngest.colours = COLOURS.OK
    this.lambdas.ingest.colours = COLOURS.OK
    this.labels.ingest.colours = COLOURS.OK

    let validatePayloadStatus = 'UNKNOWN'
    let enrichedStatus = 'UNKNOWN'
    let validateEnrichedStatus = 'UNKNOWN'
    let analyseEnrichedStatus = 'UNKNOWN'

    try {
      const result = JSON.parse(this.governanceResponse.validatePayload)
      validatePayloadStatus = result.status
    } catch (e) {
    }
    try {
      const result = JSON.parse(this.governanceResponse.validateTransformed)
      validateEnrichedStatus = result.status
    } catch (e) {
    }
    try {
      const result = JSON.parse(this.governanceResponse.governance)
      analyseEnrichedStatus = result.status
    } catch (e) {
    }
    try {
      if (this.governanceResponse.transformed.startsWith('<')) {
        enrichedStatus = 'OK'
      } else {
        const result = JSON.parse(this.governanceResponse.transformed)
        enrichedStatus = result.status
      }
    } catch (e) {
    }
    if (validateEnrichedStatus === 'ERROR'
      || validatePayloadStatus === 'ERROR'
      || enrichedStatus === 'ERROR'
      || analyseEnrichedStatus === 'ERROR'
    ) {
      this.apigateway.colours = COLOURS.ERROR
      this.lines.apiToSparrow.colours = COLOURS.ERROR
      this.icons.sparrow.colours = COLOURS.ERROR
    } else if (validateEnrichedStatus === 'WARNING'
      || validatePayloadStatus === 'WARNING'
      || enrichedStatus === 'WARNING'
      || analyseEnrichedStatus === 'WARNING'
    ) {
      this.apigateway.colours = COLOURS.WARNING
      this.lines.apiToSparrow.colours = COLOURS.WARNING
      this.icons.sparrow.colours = COLOURS.WARNING
    } else if (enrichedStatus === 'OK' && analyseEnrichedStatus === 'OK') {
      this.apigateway.colours = COLOURS.OK
      this.lines.apiToSparrow.colours = COLOURS.OK
      this.icons.sparrow.colours = COLOURS.OK
    }
    if (validatePayloadStatus === 'OK') {
      this.lines.ingestToValidate.colours = COLOURS.OK
      this.lambdas.validatePayload.colours = COLOURS.OK
    } else if (validatePayloadStatus === 'SKIPPED') {
      this.lines.ingestToValidate.colours = COLOURS.SKIPPED
      this.lambdas.validatePayload.colours = COLOURS.SKIPPED
    } else if (validatePayloadStatus === 'ERROR') {
      this.lines.ingestToValidate.colours = COLOURS.ERROR
      this.lambdas.validatePayload.colours = COLOURS.ERROR
    } else if (validatePayloadStatus === 'WARNING') {
      this.lines.ingestToValidate.colours = COLOURS.WARNING
      this.lambdas.validatePayload.colours = COLOURS.WARNING
    }
    if (validateEnrichedStatus === 'OK') {
      this.lines.enrichToValidate.colours = COLOURS.OK
      this.lambdas.validateEnriched.colours = COLOURS.OK
    } else if (validateEnrichedStatus === 'SKIPPED') {
      this.lines.enrichToValidate.colours = COLOURS.SKIPPED
      this.lambdas.validateEnriched.colours = COLOURS.SKIPPED
    } else if (validateEnrichedStatus === 'ERROR') {
      this.lines.enrichToValidate.colours = COLOURS.ERROR
      this.lambdas.validateEnriched.colours = COLOURS.ERROR
    } else if (validateEnrichedStatus === 'WARNING') {
      this.lines.enrichToValidate.colours = COLOURS.WARNING
      this.lambdas.validateEnriched.colours = COLOURS.WARNING
    }
    if (analyseEnrichedStatus === 'OK') {
      this.lines.enrichToAnalyse.colours = COLOURS.OK
      this.lambdas.analyseEnriched.colours = COLOURS.OK
      this.labels.analyse.colours = COLOURS.OK
    } else if (analyseEnrichedStatus === 'SKIPPED') {
      this.lines.enrichToAnalyse.colours = COLOURS.SKIPPED
      this.lambdas.analyseEnriched.colours = COLOURS.SKIPPED
      this.labels.analyse.colours = COLOURS.SKIPPED
    } else if (analyseEnrichedStatus === 'ERROR') {
      this.lines.enrichToAnalyse.colours = COLOURS.ERROR
      this.lambdas.analyseEnriched.colours = COLOURS.ERROR
      this.labels.analyse.colours = COLOURS.ERROR
    } else if (analyseEnrichedStatus === 'WARNING') {
      this.lines.enrichToAnalyse.colours = COLOURS.WARNING
      this.lambdas.analyseEnriched.colours = COLOURS.WARNING
      this.labels.analyse.colours = COLOURS.WARNING
    }
    if (validatePayloadStatus === 'ERROR' || validateEnrichedStatus === 'ERROR') {
      this.labels.validate.colours = COLOURS.ERROR
    } else if (validatePayloadStatus === 'WARNING' || validateEnrichedStatus === 'WARNING') {
      this.labels.validate.colours = COLOURS.WARNING
    } else if (validatePayloadStatus === 'OK' || validateEnrichedStatus === 'OK') {
      this.labels.validate.colours = COLOURS.OK
    }
    if (enrichedStatus === 'ERROR') {
      this.lines.ingestToTransform.colours = COLOURS.ERROR
      this.lambdas.transform.colours = COLOURS.ERROR
      this.labels.transform.colours = COLOURS.ERROR
    } else if (enrichedStatus === 'WARNING') {
      this.lines.ingestToTransform.colours = COLOURS.WARNING
      this.lambdas.transform.colours = COLOURS.WARNING
      this.labels.transform.colours = COLOURS.WARNING
    } else if (enrichedStatus === 'OK') {
      this.lines.ingestToTransform.colours = COLOURS.OK
      this.lambdas.transform.colours = COLOURS.OK
      this.labels.transform.colours = COLOURS.OK
    }
  }

  reset() {
    this.lambdas = {
      ingest: {
        colours: COLOURS.UNKNOWN,
      },
      validatePayload: {
        colours: COLOURS.UNKNOWN,
      },
      transform: {
        colours: COLOURS.UNKNOWN,
      },
      validateEnriched: {
        colours: COLOURS.UNKNOWN,
      },
      analyseEnriched: {
        colours: COLOURS.UNKNOWN,
      },
    }

    this.apigateway = {
      colours: COLOURS.UNKNOWN,
    }

    this.icons = {
      sparrow: {
        colours: COLOURS.UNKNOWN,
      },
    }

    this.labels = {
      ingest: {
        colours: COLOURS.UNKNOWN,
      },
      transform: {
        colours: COLOURS.UNKNOWN,
      },
      validate: {
        colours: COLOURS.UNKNOWN,
      },
      analyse: {
        colours: COLOURS.UNKNOWN,
      },
    }

    this.lines = {
      apiToIngest: {
        colours: COLOURS.UNKNOWN,
      },
      ingestToTransform: {
        colours: COLOURS.UNKNOWN,
      },
      ingestToValidate: {
        colours: COLOURS.UNKNOWN,
      },
      enrichToAnalyse: {
        colours: COLOURS.UNKNOWN,
      },
      enrichToValidate: {
        colours: COLOURS.UNKNOWN,
      },
      apiToSparrow: {
        colours: COLOURS.UNKNOWN,
      },
    }
  }
}
