<h2>Post to WallStreetDocs</h2>

<div *ngIf="hasWallStreetDocsPermission" class="wsd-post-container">
	<p>Trigger the document rendering lambda for WallStreetDocs (PRIIP_CLOUD and DOC_AUTO)</p>
	<p>Select the service below and overwrite the lambda params. Obs.: if left blank, the lambda default params will be
		used instead.
	</p>
	<div class="form-group service-type-container">
		<span>
			<mat-form-field class="rule-type-input">
				<mat-label>Service type</mat-label>
				<mat-select (selectionChange)="selectServiceType($event)" [value]="selectedServiceType"
					placeholder="Select">
					<mat-option *ngFor="let opt of serviceTypes" [value]="opt" [title]="opt.description">{{opt.display}}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</span>
	</div>

	<div *ngIf="selectedServiceType.id == 'PRIIP_CLOUD'">
		<div class="form-group priipcloud-details">
			<span>
				<label for="">Auth Host URL</label>
				<input id="priip-cloud" type="text" [(ngModel)]="this.priipAuthUrl" placeholder="Default" />
				<ng-template
					*ngTemplateOutlet="infoPopover; context: {message: 'URL for authentication in WallStreetDocs'}">
				</ng-template>
			</span>
			<span>
				<label for="">Doc Host URL</label>
				<input id="priip-cloud" type="text" [(ngModel)]="this.priipDocUrl" placeholder="Default" />
				<ng-template
					*ngTemplateOutlet="infoPopover; context: {message: 'URL for document generation in WallStreetDocs'}">
				</ng-template>
			</span>
		</div>
	</div>

	<div *ngIf="selectedServiceType.id == 'DOC_AUTO'">
		<div class="form-group docauto-details">
			<span>
				<label for="">Structure ID</label>
				<input id="doc-auto" type="text" [(ngModel)]="this.docAutoStructureId" placeholder="Default" />
				<ng-template *ngTemplateOutlet="infoPopover; context: {message: 'Struture ID. Ex.: 10'}"></ng-template>
			</span>
			<span>
				<label for="">Termsheet template ID</label>
				<input id="doc-auto" type="text" [(ngModel)]="this.docAutoTermsheetId" placeholder="Default" />
				<ng-template
					*ngTemplateOutlet="infoPopover; context: {message: 'Template ID used for termsheets config. Ex.: 23'}">
				</ng-template>
			</span>
			<span>
				<label for="">Finalterms template ID</label>
				<input id="doc-auto" type="text" [(ngModel)]="this.docAutoFinalTermsId" placeholder="Default" />
				<ng-template
					*ngTemplateOutlet="infoPopover; context: {message: 'Template ID used for finalterms config. Ex.: 20'}">
				</ng-template>
			</span>
		</div>
	</div>

	<mat-progress-bar mode="indeterminate" *ngIf="requesting"></mat-progress-bar>

	<div *ngIf="selectedServiceType.id !== undefined">
		<div class="container-fluid h-75">
			<ul ngbNav #publishNav="ngbNav" class="nav-tabs">
				<li ngbNavItem="payload">
					<a ngbNavLink>Payload</a>
					<ng-template ngbNavContent>
						<div class="payload-input">
							<ngx-monaco-editor class="monaco-editor payload-editor" [options]="payloadEditorConfig"
								[(ngModel)]="payloadValueBody" (ngModelChange)="refresh()">
							</ngx-monaco-editor>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem="pdf" *ngIf="pdfDocument">
					<a ngbNavLink>PDF</a>
					<ng-template ngbNavContent>
						<div class="pdf" id="pdf">
							<ngx-extended-pdf-viewer [src]="pdfDocument"
								useBrowserLocale="true" delayFirstView="1000" [showPresentationModeButton]="false"
								[showOpenFileButton]="false" [showPrintButton]="false" [showBookmarkButton]="false"
								[showRotateButton]="false" minHeight="600px" height="600px"></ngx-extended-pdf-viewer>
						</div>
					</ng-template>
				</li>
				<li ngbNavItem="response" *ngIf="responseBody !== undefined">
					<a ngbNavLink>Response</a>
					<ng-template ngbNavContent>
						<div class="payload-input">
							<ngx-monaco-editor class="monaco-editor payload-editor" [options]="responseViewerConfig"
								[(ngModel)]="this.responseBody" (ngModelChange)="refresh()">
							</ngx-monaco-editor>
						</div>
					</ng-template>
				</li>
			</ul>
			<div [ngbNavOutlet]="publishNav" class="tab-content"></div>
		</div>

		<div class="actions">
			<button type="button" class="btn btn-info" (click)="postToWallStreetDocs('application/pdf')">
				Generate (PDF)
			</button>
			<button type="button" class="btn btn-info" (click)="postToWallStreetDocs('application/msword')">
				Generate (Word)
			</button>
			<button *ngIf="wordDocument" type="button" class="btn btn-primary" (click)="downloadDoc()">
				Download Word Document
			</button>
		</div>
	</div>
</div>
<div *ngIf="!hasWallStreetDocsPermission">
	<p>You do not have permissions to view this page</p>
</div>

<ng-template #infoPopover let-message="message">
	<mat-icon type="button" ngbPopover="{{message}}" triggers="mouseenter:mouseleave">
		info
	</mat-icon>
</ng-template>