<h2>JMES Path Evaluator</h2>

<app-test-selector [allowDelete]="false" service="docgen"></app-test-selector>

<div class="error" *ngIf="error">{{error}}</div>

<app-jmes-path-evaluator [payload]="payload" [isPayloadEvaluator]="isPayloadEvaluator"></app-jmes-path-evaluator>

<div class="help-link">
  <a href="https://jmespath.org/specification.html" target="_blank">JMESPath Specifications</a>
</div>
