import {Component, Input, OnChanges, OnInit} from '@angular/core'
import {ConfigService} from '../services/config.service'
import {DocgenConfigAndMetadata} from '../common/domain/docgen-config'
import {DynamicMappingService} from '../services/dynamic-mapping.service';
import {Subscription} from 'rxjs';
import { PreprocessorMappingService } from '../services/preprocessor-mapping-service';
import { DiffService } from '../services/diff.service';

interface SelectItem {
  value: string
  display: string
}

@Component({
  selector: 'app-docgen-config-selector',
  templateUrl: './docgen-config-selector.component.html',
  styleUrls: ['./docgen-config-selector.component.scss'],
})
export class DocgenConfigSelectorComponent implements OnInit {

  allConfigs: DocgenConfigAndMetadata[]
  filteredConfigs: DocgenConfigAndMetadata[]
  showDynamicMappingsConfig = false
  showVisualDiff = true
  showPreprocessorMappingConfig = false;
  private subscriptions = new Subscription()


  activeConfig = {
    sourceSystem: undefined,
    programme: undefined,
    productType: undefined,
    documentType: undefined,
  }

  selections: {[key: string]: SelectItem[]} = {
    sourceSystems: [],
    programmes: [],
    productTypes: [],
    documentTypes: [],
  }

  constructor(private configService: ConfigService, private dynamicMappingService: DynamicMappingService, private preprocessorMappingService: PreprocessorMappingService, private diffService: DiffService) {
    this.configService.docgenConfigsFiltered.subscribe(configs => {
      if (configs) {
        this.selections.sourceSystems = []
        this.selections.programmes = []
        this.selections.productTypes = []
        this.selections.documentTypes = []
        this.allConfigs = configs
        configs.forEach(config => {
          this.selections.sourceSystems = DocgenConfigSelectorComponent.addToSelection(this.selections.sourceSystems, config.metadata.sourceSystem)
          this.selections.programmes = DocgenConfigSelectorComponent.addToSelection(this.selections.programmes, config.metadata.programme)
          this.selections.productTypes = DocgenConfigSelectorComponent.addToSelection(this.selections.productTypes, config.metadata.productType)
          this.selections.documentTypes = DocgenConfigSelectorComponent.addToSelection(this.selections.documentTypes, config.metadata.documentType)
        })
      }
      this.configService.selectedDocgenConfig.subscribe(config => {
        if (config && config.metadata) {
          this.filteredConfigs = this.allConfigs
          this.activeConfig.sourceSystem = config && config.metadata && config.metadata.sourceSystem
          this.activeConfig.programme = config && config.metadata && config.metadata.programme
          this.activeConfig.productType = config && config.metadata && config.metadata.productType
          this.activeConfig.documentType = config && config.metadata && config.metadata.documentType
        }
      })
    })
  }

  ngOnInit(): void {
    this.subscriptions.add(this.dynamicMappingService.showDynamicMappingConfigChange.subscribe(value =>{
      this.showDynamicMappingsConfig = value
    }))
    this.subscriptions.add(this.preprocessorMappingService.showPreprocessorMappingConfigChange.subscribe(value =>{
      this.showPreprocessorMappingConfig = value
    }))
    this.subscriptions.add(this.diffService.showVisualDiffConfigChange.subscribe(value =>{
      this.showVisualDiff = value
    }))
  }

  static addToSelection = (selection: SelectItem[], value: string): SelectItem[] => {
    if (selection.find(s => s.value === value)) {
      return selection
    }

    const display = value
      // put a space between any lower-case letter and the upper-case letter following it
      .replace(/([a-z0-9])([A-Z])/g, '$1 $2')
      // put a space between any letter and the number following it
      .replace(/([a-zA-Z])(\d)/g, '$1 $2')
      .replace('KIP', 'KI P')
      // ensure the first letter is upper-cased
      .replace(/^[a-z]/, str => str.toUpperCase())
      // remove any white space left around the name
      .trim()

    return selection.concat({value, display})
  }

  selectSourceSystem(sourceSystem: string) {
    this.activeConfig.sourceSystem = sourceSystem
    this.activeConfig.programme = undefined
    this.activeConfig.productType = undefined
    this.activeConfig.documentType = undefined
    this.filteredConfigs = this.allConfigs.filter(f => f.metadata.sourceSystem === sourceSystem)
    this.selections.programmes = this.filteredConfigs.reduce((p, c) => {
      return DocgenConfigSelectorComponent.addToSelection(p, c.metadata.programme)
    }, [])
    if (this.selections.programmes.length === 1) {
      this.selectProgramme(this.selections.programmes[0].value)
    }
  }

  selectProgramme(programme: string) {
    this.activeConfig.programme = programme
    this.activeConfig.productType = undefined
    this.activeConfig.documentType = undefined
    this.filteredConfigs = this.allConfigs.filter(f => {
      return f.metadata.programme === programme
        && f.metadata.sourceSystem === this.activeConfig.sourceSystem
    })
    this.selections.productTypes = this.filteredConfigs.reduce((p, c) => {
      return DocgenConfigSelectorComponent.addToSelection(p, c.metadata.productType)
    }, [])
    if (this.selections.productTypes.length === 1) {
      this.selectProductType(this.selections.productTypes[0].value)
    }
  }

  selectProductType(productType: string) {
    this.activeConfig.productType = productType
    this.activeConfig.documentType = undefined
    this.filteredConfigs = this.allConfigs.filter(f => {
      return f.metadata.productType === productType
        && f.metadata.programme === this.activeConfig.programme
        && f.metadata.sourceSystem === this.activeConfig.sourceSystem
    })
    this.selections.documentTypes = this.filteredConfigs.reduce((p, c) => {
      return DocgenConfigSelectorComponent.addToSelection(p, c.metadata.documentType)
    }, [])
    if (this.selections.documentTypes.length === 1) {
      this.selectDocumentType(this.selections.documentTypes[0].value)
    }
  }

  selectDocumentType(documentType: string) {
    this.activeConfig.documentType = documentType
    if(this.showDynamicMappingsConfig) {
      this.activeConfig['dynamicMapping'] = 'true'
    }
    this.configService.selectDocgenConfig(this.activeConfig)
  }

  togglePreprocessorMappingsConfig() {
    this.preprocessorMappingService.toggleShowPreprocessorMapping()
  }

  toggleDynamicMappingsConfig() {
    this.dynamicMappingService.toggleShowDynamicMapping()
  }

  toggleVisualDiff() {
    this.diffService.toggleVisualDiff()
  }
}
