<ng-container>
  <app-test-selector service="italianlisting" allowDelete="true" (cleared)="testReset()" (click)="selectProduct()"></app-test-selector>
</ng-container>

<div class="container-fluid h-75">
  <ul ngbNav #docgenNav="ngbNav" [(activeId)]="activeTab" class="nav-tabs">
    <li ngbNavItem="payload">
      <a ngbNavLink>Payload</a>
      <ng-template ngbNavContent>
        <div class="payload-input">
          <ngx-monaco-editor
            class="monaco-editor payload-editor"
            [options]="payloadEditorConfig"
            [(ngModel)]="payloadValueBody"
            (ngModelChange)="refresh()">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="docgenNav" class="tab-content"></div>
</div>

<ng-container *ngIf="validPayload" class="actions">
  <button type="button" class="btn btn-info" (click)="saveTestPayload()">Save Product Payload</button>
</ng-container>
