<!--<div class="md-form form-inline form-group">-->
<!--  <mat-form-field class="test-case-selection">-->
<!--    <mat-label>Test Case</mat-label>-->
<!--    <input matInput type="text" placeholder="Test" [matAutocomplete]="testCase" [formControl]="testCaseControl">-->
<!--    <mat-autocomplete #testCase="matAutocomplete" (optionSelected)="selectTest($event)">-->
<!--      <mat-option *ngFor="let test of filteredTests" [value]="test">{{test}}</mat-option>-->
<!--    </mat-autocomplete>-->
<!--  </mat-form-field>-->

<!--  <button mat-raised-button color="accent" (click)="clearTestSelection()">Clear</button>-->

<!--  <button mat-raised-button color="warn" *ngIf="selectedTestName && allowDelete" (click)="deleteTest(selectedTestName)">-->
<!--    Delete Test-->
<!--  </button>-->
<!--</div>-->

<div class="form-inline form-group">
  <input id="testSelector" type="text" class="form-control"
         [disabled]='this.userConfigFilters === null'
         [class.is-invalid]="searchFailed"
         [(ngModel)]="model"
         (ngModelChange)="select($event)"
         [ngbTypeahead]="search"
         [editable]='false'
         (focus)="focus$.next($any($event).target.value)"
         (click)="click$.next($any($event).target.value)"
         #instance="ngbTypeahead"
         placeholder="Test or Example"/>
  <small *ngIf="searching" class="form-text text-muted">loading...</small>
  <div class="invalid-feedback" *ngIf="searchFailed">Failed to load tests.</div>

  <button type="button" class="btn btn-secondary ms-2" [disabled]="!model" (click)="clearTestSelection()">Clear
  </button>
  <button type="button" class="btn btn-danger ms-2" *ngIf="model && allowDelete" (click)="deleteTest(model)">
    Delete Test
  </button>
</div>
