import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {jsonDiffViewerOptions, jsonEditorOptions} from '../../util/editor-configurations';
import {DiffEditorModel} from 'ngx-monaco-editor';

interface AuditConfigChange {
  updatedConfig: string,
  originalConfig: string
}

@Component({
  selector: 'app-audit-history-dialog',
  templateUrl: './audit-history-dialog.component.html',
  styleUrls: ['./audit-history-dialog.component.scss']
})
export class AuditHistoryDialogComponent implements OnInit {

  public auditLog: string
  options = jsonEditorOptions

  jsonDiffOptions = jsonDiffViewerOptions

  originalModel: DiffEditorModel = {
    language: 'application/json',
    code: '',
  }
  modifiedModel: DiffEditorModel = {
    language: 'application/json',
    code: '',
  }

  constructor(public dialogRef: MatDialogRef<AuditHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: AuditConfigChange) { }

  ngOnInit(): void {
    if(this.data) {
      this.modifiedModel.code = this.data.updatedConfig
      this.originalModel.code = this.data.originalConfig
    }
  }

  close() {
    this.dialogRef.close(undefined)
  }

}
