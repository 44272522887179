import {Component, Inject, OnInit} from '@angular/core'
import {ConfigDialogData} from './config-dialog.component'
import {ConfirmStatus} from './confirm-dialog.component'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'

export interface ConfirmCommentDialogData extends ConfigDialogData {
  commentMaxLength?: number
}

export interface ConfirmCommentResponse {
  status: ConfirmStatus,
  comment?: string,
  description?: string
}

@Component({
  selector: 'app-confirm-comment-dialog',
  templateUrl: 'confirm-comment-dialog.component.html',
  styleUrls: ['./confirm-comment-dialog.component.scss'],
})
export class ConfirmCommentDialogComponent implements OnInit {
  comment: string
  commentMaxLength: number

  constructor(
    public dialogRef: MatDialogRef<ConfirmCommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmCommentDialogData) {
    this.comment = ''
    this.commentMaxLength = data.commentMaxLength || 64
  }

  ngOnInit(): void {
    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.cancel();
      }
    });
  }

  cancel() {
    this.dialogRef.close({
      status: ConfirmStatus.CANCELLED,
    })
  }

  confirm() {
    this.dialogRef.close({
      status: ConfirmStatus.CONFIRMED,
      comment: this.comment,
    })
  }
}
