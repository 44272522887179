import {Component, OnInit} from '@angular/core'

@Component({
  selector: 'app-publish-config-approval',
  templateUrl: './publish-config-approval.component.html',
  styleUrls: ['./publish-config-approval.component.scss']
})
export class PublishConfigApprovalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
