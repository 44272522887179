import {AnalyseRuleTable} from './analyse'
import { PublishConfigMetadata } from './publish-config'

export type ConfigPath = string

export type FriendlyConfigSources = 'docgen' | 'publish' | 'approvals' | 'templates'
export type ConfigSource = 'lambda-ingest-product' | 'api-publish' | 'lambda-approvals' | 'auto-deploy-templates'

export interface ConfigItem {
  source: ConfigSource
  name: string
  path: ConfigPath
  size: number
  hash: string
  modified: Date
}

export interface ConfigDetail extends ConfigItem {
  content: string
}

export interface ConfigMetadata {
  sourceSystem: string
  programme: string
  productType: string
  documentType: string
}

export interface ConfigAndMetadata {
  metadata: ConfigMetadata
  settings: ConfigDetail
  schemas: ConfigDetail[]
  transforms: ConfigDetail[]
}

export enum ConfigKeyIndex {
  SOURCE_SYSTEM = 1,
  PROGRAMME = 2,
  PRODUCT_TYPE = 3,
  DOCUMENT_TYPE = 4,
}

export type Supported = 'NO' | 'MIGRATING' | 'UAT' | 'YES'

export type ConfigLocation = string

type IncludeInResponse = {
  includeInResponse: boolean
}
export type ChildMappings = PublishConfigMetadata & IncludeInResponse

export class IngestConfig {
  supported: Supported = 'NO'
}

export interface OptionalStepConfig {
  skip?: boolean
}

export class TransformConfig implements OptionalStepConfig {
  skip = false
  transforms: ConfigLocation[]
}

export class ValidateConfig implements OptionalStepConfig {
  skip = true
  schema?: ConfigLocation
}

export class AnalyseConfig implements OptionalStepConfig {
  skip = false
  rules: AnalyseRuleTable[] = []
}

export class CommonConfig {
  maxPageSize: number
  ingest: IngestConfig
  transform: TransformConfig
  enrich: TransformConfig
  validatePayload: ValidateConfig
  analysePayload: AnalyseConfig
  validateEnriched: ValidateConfig
  analyseEnriched: AnalyseConfig
  multiTrigger?: boolean
  childKeys?: ChildMappings[]

  protected constructor() {
    this.ingest = new IngestConfig()
    this.transform = new TransformConfig()
    this.enrich = new TransformConfig()
    this.validatePayload = new ValidateConfig()
    this.validateEnriched = new ValidateConfig()
    this.analysePayload = new AnalyseConfig()
    this.analyseEnriched = new AnalyseConfig()
  }

  public static create(): CommonConfig {
    return new CommonConfig()
  }
}
