import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core'
import {TestManagerService} from '../../services/test-manager.service'
import {takeUntil} from 'rxjs/operators'

@Component({
  selector: 'app-jmes-path-view',
  templateUrl: './jmes-path-view.component.html',
  styleUrls: ['./jmes-path-view.component.scss']
})
export class JmesPathViewComponent implements OnInit, OnDestroy {
  $destroy = new EventEmitter()
  payload = {}
  isPayloadEvaluator = true;
  error = undefined

  constructor(
    private testManagerService: TestManagerService
  ) { }

  ngOnInit(): void {
    this.testManagerService.selectedTest
      .pipe(
        takeUntil(this.$destroy)
      )
      .subscribe(t => {
        if (t && t.payload) {
          try {
            this.payload = JSON.parse(t.payload)
            this.error = undefined
          } catch (e) {
            this.payload = {}
            this.error = 'Not a JSON Payload'
          }
        } else {
          this.payload = {}
          this.error = ''
        }
      })
  }

  ngOnDestroy(): void {
    this.$destroy.emit()
  }
}
