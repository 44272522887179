import {Component, OnDestroy, OnInit} from '@angular/core'
import {ChildMappings, ConfigDetail, Supported} from '../../common/domain/config'
import {saveAs} from 'file-saver'
import {StatusService} from '../../services/status.service'
import {ChangeService} from '../../services/change.service'
// import {stringify} from 'csv/lib/sync'
import {of, Subscription} from 'rxjs'
import {ConfigService} from '../../services/config.service'
import {ChangeDetails, ChangedState} from '../../common/domain/change'
import {PermissionsService} from '../../services/permissions.service'
import {FormatTransform, PublishConfig, PublishConfigAndMetadata, PublishConfigKeyIndex, SecondTargetSystem, TargetSystem} from '../../common/domain/publish-config'
import {switchMap} from 'rxjs/operators'
import { unparse } from 'papaparse'
import { animate, state, style, transition, trigger } from '@angular/animations';

interface TargetMapping {
  state: ChangedState
  configDetail: ConfigDetail
  sourceSystem: string
  businessUnit: string
  payloadType: string
  targetSystem: string
  sparrowSchema: string
  soloSchema: string
  transform: string
  enrich: string
  combine: string
  encapsulate: string
  target: TargetSystem
  secondTarget: SecondTargetSystem
  regxchangeRetrieveSchema: string
  regxchangeSubmitSchema: string
  targetParams: string
  supported: Supported
  formatTransformation: FormatTransform
  multiTrigger?: boolean,
  childKeys?: ChildMappings[]
}

@Component({
  selector: 'app-targets-map',
  templateUrl: './config-targets.component.html',
  styleUrls: ['./config-targets.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ConfigTargetsComponent implements OnInit, OnDestroy {
  columnsToDisplay = ['sourceSystem', 'businessUnit', 'payloadType', 'targetSystem'];
  columnsNames = ['Source', 'Business', 'Payload', 'Target']
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'supported', 'expand'];
  expandedElement: TargetMapping | null;
  sub = new Subscription()

  supportedConfigMode = ['NO', 'YES']
  mappings: TargetMapping[]
  multiTriggerMappings: TargetMapping[]
  selectedConfig: PublishConfigAndMetadata
  configs: PublishConfigAndMetadata[]

  canEditMappings = false
  canEditTargetSystem = false
  canEditSupportedStatus = false

  schemas: string[] = []
  transforms: string[] = []
  supportedTargets: { value: TargetSystem, display: string }[] = [
    {
      value: '',
      display: '',
    },
    {
      value: 'RegXChange',
      display: 'RegX_EMT',
    },
    {
      value: 'WMDaten',
      display: 'WMDaten',
    },
    {
      value: 'SIX',
      display: 'SIX',
    },
    {
      value: 'RegXChange_EET',
      display: 'RegX_EET',
    },
    {
      value: 'WMDaten_EDDy',
      display: 'WMDaten_EDDy',
    }
  ]

  supportedformatTransformations = ["None", "JSONTOXML"];

  constructor(
    private statusService: StatusService,
    private configService: ConfigService,
    private changeService: ChangeService,
    private permissionsService: PermissionsService,
  ) {
  }

  ngOnInit() {
    this.changeService.getObjectsOfType('settings')
      .pipe(
        switchMap((configs: ChangeDetails[]) => of(configs.filter(config => config.source === 'api-publish'))),
      )
      .subscribe(configDetails => {
        this.mappings = configDetails.map(configDetail => {
          const config: PublishConfig = Object.assign(PublishConfig.create(), JSON.parse(configDetail.content))
          const match = configDetail.path.match(/^.*\/([^\/]+)\/([^\/]+)\/([^\/]+)\/([^\/]+)\/Config\.json$/)
          const sourceSystem = match[PublishConfigKeyIndex.SOURCE_SYSTEM]
          const businessUnit = match[PublishConfigKeyIndex.BUSINESS_UNIT]
          const payloadType = match[PublishConfigKeyIndex.PAYLOAD_TYPE]
          const targetSystem = match[PublishConfigKeyIndex.TARGET_SYSTEM]
          const mapping: TargetMapping = {
            configDetail: configDetail,
            state: configDetail.state,
            sourceSystem: sourceSystem,
            businessUnit: businessUnit,
            payloadType: payloadType,
            targetSystem: targetSystem,
            sparrowSchema: (config.validatePayload.schema && config.validatePayload.schema.replace('schemas/', '')) || '',
            soloSchema: (config.validateEnriched.schema && config.validateEnriched.schema.replace('schemas/', '')) || '',
            transform: (config.transform.transforms && config.transform.transforms.length >= 1 && (config.transform.transforms[0].replace('transforms/', ''))) || '',
            enrich: (config.enrich.transforms && config.enrich.transforms.length >= 1 && (config.enrich.transforms[0].replace('transforms/', ''))) || '',
            combine: (config.combine.transforms && config.combine.transforms.length >= 1 && (config.combine.transforms[0].replace('transforms/', ''))) || '',
            encapsulate: (config.encapsulate.transforms && config.encapsulate.transforms.length >= 1 && (config.encapsulate.transforms[0].replace('transforms/', ''))) || '',
            target: config.publish.targetSystem,
            secondTarget: config.publish.secondTargetSystem || '',
            regxchangeRetrieveSchema: config.publish.regxchangeRetrieveSchema || '',
            regxchangeSubmitSchema: config.publish.regxchangeSubmitSchema || '',
            targetParams: config.publish.queryParams || '',
            supported: config.ingest.supported,
            formatTransformation: config.publish.formatTransformation || 'None',
            multiTrigger: config.multiTrigger,
            childKeys: config.childKeys
          }
          return mapping
        })

        this.multiTriggerMappings = this.mappings.filter(mapping => mapping.multiTrigger)
      })

    this.changeService.selectedPublishConfig.subscribe(config => {
      this.selectedConfig = config
    })

    this.sub.add(
      this.configService.publishConfigs.subscribe((configs) => this.configs = configs),
    )

    this.sub.add(
      this.changeService.getObjectsOfType('schemas')
        .pipe(
          switchMap((configs: ChangeDetails[]) => of(configs.filter(config => config.source === 'api-publish'))),
        )
        .subscribe(schemas => {
          this.schemas = [''].concat(schemas
            .map(schema => {
              return schema.name
            }),
          )
        }),
    )

    this.sub.add(
      this.changeService.getObjectsOfType('transforms')
        .pipe(
          switchMap((configs: ChangeDetails[]) => of(configs.filter(config => config.source === 'api-publish'))),
        )
        .subscribe(transforms => {
          this.transforms = [''].concat(transforms
            .filter(t => t.name.endsWith('.json'))
            .map(transform => {
              return transform.name
            }),
          )
        }),
    )

    this.permissionsService.editTargetSystem.subscribe((permission: boolean) => this.canEditTargetSystem = permission)
    this.permissionsService.editSupportedStatus.subscribe((permission: boolean) => this.canEditSupportedStatus = permission)
    this.permissionsService.editMappings.subscribe((permission: boolean) => this.canEditMappings = permission)
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  downloadTemplateMapping() {
    const csvData = this.mappings.filter((x: TargetMapping) => x.sourceSystem.toLowerCase() !== 'development')
      .map(x => {
        const result = Object.assign({}, x)
        delete result['configDetail']
        return result
      })

    const foo = unparse(csvData, {
      header: true,
    })

    const blob = new Blob([foo], {type: 'text/csv;charset=utf-8'})
    saveAs(blob, 'templateMappings.csv')
  }

  nonMultiTrigger(mappings: TargetMapping[]) {
    return mappings.filter(m => !m.multiTrigger)
  }

  updateItem(item: TargetMapping) {
    console.log('Update: ')
    console.dir(item)
    const updated: PublishConfig = Object.assign(PublishConfig.create(), JSON.parse(item.configDetail.content))
    updated.ingest.supported = item.supported
    updated.publish.targetSystem = item.target
    updated.publish.secondTargetSystem = item.secondTarget
    updated.publish.queryParams = item.targetParams
    updated.publish.regxchangeRetrieveSchema = item.regxchangeRetrieveSchema
    updated.publish.regxchangeSubmitSchema = item.regxchangeSubmitSchema
    updated.validatePayload.schema = item.sparrowSchema === '' ? '' : 'schemas/' + item.sparrowSchema
    updated.validateEnriched.schema = item.soloSchema === '' ? '' : 'schemas/' + item.soloSchema
    updated.transform.transforms = item.transform !== '' ? ['transforms/' + item.transform] : []
    updated.enrich.transforms = item.enrich !== '' ? ['transforms/' + item.enrich] : []
    updated.combine.transforms = item.combine !== '' ? ['transforms/' + item.combine] : []
    updated.encapsulate.transforms = item.encapsulate !== '' ? ['transforms/' + item.encapsulate] : []
    updated.publish.formatTransformation = item.formatTransformation || "None"

    updated.validatePayload.skip = item.sparrowSchema === ''
    updated.validateEnriched.skip = item.soloSchema === ''
    updated.transform.skip = updated.transform.transforms.length === 0
    updated.enrich.skip = updated.enrich.transforms.length === 0
    updated.combine.skip = updated.combine.transforms.length === 0
    updated.encapsulate.skip = updated.encapsulate.transforms.length === 0

    updated.childKeys = item.childKeys
    updated.multiTrigger = item.multiTrigger

    const configString = JSON.stringify(updated, undefined, 2)

    this.changeService.addChange(Object.assign({},
      item.configDetail,
      {
        hash: undefined,
        size: undefined,
        content: configString,
      },
    ))
  }

  newChildTarget(item: TargetMapping) {
    let changedItem = this.mappings.findIndex((e) => (
      e.payloadType === item.payloadType && e.businessUnit === item.businessUnit
      && e.targetSystem === item.targetSystem && item.sourceSystem === e.sourceSystem
    ))

    this.mappings[changedItem].childKeys = [...this.mappings[changedItem].childKeys, {
      businessUnit: '', payloadType: '', sourceSystem: '', targetSystem: '', includeInResponse: false
    }]
  }

  deleteChildTarget(item: TargetMapping, index: number) {
    let deletedItem = this.mappings.findIndex((e) => (
      e.payloadType === item.payloadType && e.businessUnit === item.businessUnit
      && e.targetSystem === item.targetSystem && item.sourceSystem === e.sourceSystem
    ))

    this.mappings[deletedItem].childKeys.splice(index, 1)
    this.updateItem(item)
  }

  isAvailableToConfig(item: TargetMapping) {
    if (this.configs && this.configs.length > 0) {
      return this.configs.some(c => c.settings.path === item.configDetail.path)
    }
    return true
  }

  isReferencedByConfig(item: TargetMapping) {
    return this.selectedConfig && this.selectedConfig.settings.path === item.configDetail.path
  }

  restore(item: TargetMapping) {
    this.changeService.revertChange(item.configDetail)
  }
}
