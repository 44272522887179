import {
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { regexValidator } from 'src/app/dialogs/regex-evaluator-dialog/regex-validator';
@Component({
  selector: 'app-regex-evaluator-dialog',
  templateUrl: './regex-evaluator-dialog.component.html',
  styleUrls: ['./regex-evaluator-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegexEvaluatorDialogComponent
  implements OnInit, OnDestroy, OnChanges
{
  payload: any = {};
  form = this.fb.group({
    ignorePatterns: this.fb.array([]),
  });
  constructor(public activeModel: NgbActiveModal, private fb: UntypedFormBuilder) {
    console.log(this.payload);
  }

  get ignorePatterns() {
    return this.form.controls['ignorePatterns'] as UntypedFormArray;
  }

  ngOnInit() {
    if (this.payload.ignoreRegex && this.payload.ignoreRegex.length > 0) {
      this.generateRegEx();
    } else {
      this.addRegEx();
    }
  }

  addRegEx(regex?, flags?) {
    const flagPattern = '(?!.*(.).*\\1)[gmixuUAJD]{1,9}';
    const ignorePatternsForm = this.fb.group({
      regexPattern: [regex ? regex : '', [Validators.required, regexValidator]],
      flags: [flags ? flags : '', Validators.pattern(flagPattern)],
    });

    this.ignorePatterns.push(ignorePatternsForm);
  }

  deleteRegEx(regExIndex: number) {
    this.ignorePatterns.removeAt(regExIndex);
  }

  generateRegEx() {
    this.payload.ignoreRegex.forEach((x) => {
      if (x.includes('|')) {
        const a = this.beforeLast(x, '|');
        const b = x.substring(x.lastIndexOf('|') + 1);
        this.addRegEx(a, b);
      } else {
        const a = this.beforeLast(x, '|');
        this.addRegEx(a);
      }
    });
  }

  beforeLast(value, delimiter) {
    value = value || '';
    if (delimiter === '') {
      return value;
    }
    const substrings = value.split(delimiter);
    return substrings.length === 1
      ? value // delimiter is not part of the string
      : substrings.slice(0, -1).join(delimiter);
  }

  escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  escapeReplacement(string) {
    return string.replace(/\$/g, '$$$$');
  }

  ngOnDestroy() {
    console.log(this.payload);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('changes', changes);
  }

  savePayload() {
     const ignorePatterns = this.form.value.ignorePatterns.map((x) => {
      return x.flags.length > 0
        ? `${x.regexPattern}|${x.flags}`
        : `${x.regexPattern}`;
    });
    this.payload.ignoreRegex = ignorePatterns;
    //return;
    this.activeModel.close(this.payload);
  }

  cancel() {
    this.activeModel.close('close');
  }
}
