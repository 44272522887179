<h1>Chart Layout Playground</h1>

<div class="container-fluid">
  <div class="row d-flex justify-content-start">
    <div class="chart-bg">
      <canvas baseChart
              [width]="width"
              [height]="height"
              [datasets]="chartDatasets"
              [options]="chartOptions"
              [colors]="chartColours"

              legend="false"
              chartType="line">
      </canvas>
    </div>
    <div class="chart-options">
      <div class="custom-slider">
        YEARS:
        <ngx-slider [(value)]="years" [options]="yearOptions" (userChange)="updateYears()"></ngx-slider>
      </div>
      <div class="mt-2 custom-slider">
        MONTHS:
        <ngx-slider [(value)]="months" [options]="monthOptions" (userChange)="updateMonths()"></ngx-slider>
      </div>
      <div class="mt-2 custom-slider">
        DAYS:
        <ngx-slider [(value)]="days" [options]="dayOptions" (userChange)="updateDays()"></ngx-slider>
      </div>
      <div class="mt-2 custom-slider">
        RANGE:
        <ngx-slider [(value)]="lowRange" [(highValue)]="highRange" [options]="rangeOptions"
                    (userChange)="updateValues()" (highValueChange)="updateValues()"></ngx-slider>
      </div>
      <div class="mt-4">
        <button class="btn btn-success" (click)="updateChart()">Update Chart</button>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <button class="btn btn-primary" (click)="saveChartConfiguration()">Save Chart Configuration</button>
  </div>
</div>
