<app-config-workspace-buttons></app-config-workspace-buttons>

<h2>Rule Approvals</h2>

<div class="row mb-2">
    <div class="col-4">
        <label for='filterTableData' class='inspire-surround-label-flex'>Filter By Mnemonic:</label>&nbsp;
<span class="inspire-surround-flex flex-column-grow-one">
    <input id='filterTableData' type="text" class='form-control' [(ngModel)]="this.filterTableData" maxlength="80"
        required (input)=this.displayTableData() />
</span>
    </div>
<div class="col-6">
    <div>&nbsp;</div>
    <button type="button" class="btn btn-info" *ngIf="rules.length > 0" (click)="downloadApprovals()"
    title="Download all approved">Download CSV</button>
</div>


</div>
<ng-container *ngIf="user">

    <div class="">

        <table mat-table [dataSource]="this.dataSource" matSort>

            <!-- Programme Column -->
            <ng-container matColumnDef="programme">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Programme </th>
                <td mat-cell *matCellDef="let element"> {{element.programme}} </td>
            </ng-container>

            <!-- Product Type Column -->
            <ng-container matColumnDef="productType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Type </th>
                <td mat-cell *matCellDef="let element"> {{element.productType}} </td>
            </ng-container>

            <!-- Document Type Column -->
            <ng-container matColumnDef="documentType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Document Type </th>
                <td mat-cell *matCellDef="let element"> {{element.documentType}} </td>
            </ng-container>

            <!-- Mnemonic Column -->
            <ng-container matColumnDef="mnemonic">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Mnemonic </th>
                <td mat-cell *matCellDef="let element"> {{element.mnemonic}} </td>
            </ng-container>

            <!-- Approved By Column -->
            <ng-container matColumnDef="approvedBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Approved By </th>
                <td mat-cell *matCellDef="let element"> {{element.approvedBy}} </td>
            </ng-container>

            <!-- Approval Date Column -->
            <ng-container matColumnDef="approvedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Approval Date </th>
                <td mat-cell *matCellDef="let element"> {{element.approvedDate}} </td>
            </ng-container>

            <!-- Actions Column -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="this.canApproveRule || readOnly">
                        <button title="Approve" type="button" class="btn btn-sm btn-primary" *ngIf="!element.approvedBy"
                            (click)="slowApprove(element)">
                            <fa-icon icon="thumbs-up" title="Approve"></fa-icon>
                        </button>
                        <button title="Approve Immediately" type="button" class="btn btn-sm btn-danger"
                            *ngIf="!element.approvedBy" (click)="approveNow(element)">
                            <fa-icon icon="bolt" title="Approve Immediately"></fa-icon>
                        </button>
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="this.displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <mat-paginator [pageSizeOptions]="[20, 1000, 5000, 10000]" showFirstLastButtons></mat-paginator>
    </div>

</ng-container>