import {Component, OnInit} from '@angular/core'
import {TestManagerService} from 'src/app/services/test-manager.service'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {ConfirmDialogComponent} from 'src/app/dialogs/confirm-dialog.component'
import {GetArchiveTestsResponseArray} from 'src/app/model/test-management-responses'

@Component({
  selector: 'app-display-test-archive',
  templateUrl: './display-test-archive.component.html',
  styleUrls: ['./display-test-archive.component.scss'],
})
export class DisplayTestArchiveComponent implements OnInit {
  allChecked: boolean = false
  checkedTests: any = []
  archiveTests: GetArchiveTestsResponseArray = []
  condensedArchivedTests = []


  filterOn = {
    trigger: false,
    filterTests: [],
  }

  selectedTests: any = []

  canRestore = false

  private filterValue = ''

  constructor(
    private testManager: TestManagerService,
    private modalService: NgbModal,
  ) {
  }

  async ngOnInit() {
    await this.fetchTests()
  }

  fetchTests = async () => {
    this.archiveTests = await this.testManager.getArchivedTests()
    this.condensedArchivedTests = this.archiveTests.map((test) => {
      return {
        name: test.metadata.testname,
        visible: true,
        checked: false,
      }
    })
  }

  setFilter(filterValue: string) {
    this.filterValue = filterValue || ''
    this.applyFilter()
  }

  private applyFilter() {
    this.filterOn.filterTests.length = 0
    this.filterValue.length > 0
      ? (this.filterOn.trigger = true)
      : (this.filterOn.trigger = false)
    const terms = this.filterValue.toLowerCase().split(' ')
    this.condensedArchivedTests.forEach((test) => {
      const ts = test.name.toLowerCase()
      test.visible = terms.every((term) => ts.includes(term))
      if (test.visible) {
        this.filterOn.filterTests.push(test.name)
      }
    })
  }

  addTestToSelected(test, testsArray?) {
    if (testsArray) {
      this.selectedTests = this.selectedTests.concat(testsArray)
      testsArray.forEach((test) => {
        const index = this.condensedArchivedTests.indexOf(test)
        this.condensedArchivedTests[index].checked = false
        this.condensedArchivedTests.splice(index, 1)
        this.canRestore = this.selectedTests.length > 0
        this.allChecked = false
      })
      this.checkedTests.length = 0
    } else {
      this.selectedTests.push(test)
      const index = this.condensedArchivedTests.indexOf(test)
      this.condensedArchivedTests.splice(index, 1)
      this.canRestore = this.selectedTests.length > 0
    }
  }

  removeTestFromSelected(test) {
    this.condensedArchivedTests.push(test)
    const index = this.selectedTests.indexOf(test)
    this.selectedTests.splice(index, 1)
    this.canRestore = this.selectedTests.length > 0
  }

  restoreFromArchive(selectedTests) {
    const selectedTestsNames = selectedTests.map((element) => element.name)
    const dialogRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'lg',
      backdrop: false,
    })
    dialogRef.componentInstance.title = 'Please confirm'
    dialogRef.componentInstance.message =
      'Are you sure you want to restore from Archive Test ?'
    dialogRef.result
      .then(async () => {
        await this.testManager.restoreArchivedTests(selectedTestsNames)
        this.selectedTests.length = 0
        this.canRestore = false
        await this.fetchTests()
      })
      .catch((err) => {
        console.log(err)
      })
  }

  checkOrUncheckAll(checkedValue: boolean) {
    if (!checkedValue) {
      this.checkedTests.length = 0
    }
    this.allChecked = checkedValue
    this.condensedArchivedTests.forEach((element) => {
      if (element.visible) {
        element.checked = checkedValue
        const index = this.checkedTests.indexOf(element)
        if (checkedValue && index === -1) {
          this.checkedTests.push(element)
        }
      }
    })
  }

  onEventChange(test, testChecked) {
    if (this.allChecked && !testChecked) {
      const index = this.checkedTests.indexOf(test)
      test.checked = testChecked
      this.checkedTests.splice(index, 1)
    } else if (this.allChecked && testChecked) {
      this.checkedTests.push(test)
      test.checked = testChecked
    } else if (!this.allChecked && testChecked) {
      this.checkedTests.push(test)
      test.checked = testChecked
    } else if (!this.allChecked && !testChecked) {
      test.checked = testChecked
      const index = this.checkedTests.indexOf(test)
      this.checkedTests.splice(index, 1)
    }
    if (this.checkedTests.length !== this.condensedArchivedTests.length) {
      this.allChecked = false
    }
  }


}
