import {RuleType} from '../common/domain/analyse'
import {UsageStage} from './usage-stage'

export class ProtectedRuleSet {
    public mnemonic = ''
    public stage: UsageStage = 'solo'
    public entries = new Array<string>()
    public lastUpdated = ''

    // Added to support migration to DeNomalised RuleSets
    public description?: string
    public name?: string
    public ruleType: RuleType
}
