import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core'
import {ConfigDetail} from '../common/domain/config'
import {ChangeService} from '../services/change.service'
import {Subscription} from 'rxjs'
import {MatSelectChange} from '@angular/material/select'

@Component({
  selector: 'app-schema-selector',
  templateUrl: './schema-selector.component.html',
  styleUrls: ['./schema-selector.component.scss'],
})
export class SchemaSelectorComponent implements OnInit, OnDestroy {
  sub = new Subscription()
  @Input() label = 'Schema'

  schemas: ConfigDetail[]
  selected: ConfigDetail
  @Output() schema = new EventEmitter<ConfigDetail>()

  constructor(private changeService: ChangeService) {
    this.schemas = []
  }

  ngOnInit() {
    this.sub.add(
      this.changeService.getObjectsOfType('schemas')
        .subscribe(schemas => {
            this.schemas = schemas
          },
        ),
    )
    this.sub.add(
      this.schema.subscribe(item => {
        this.selected = item
      }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  selectSchema(event: MatSelectChange) {
    this.schema.emit(event.value)
  }
}
