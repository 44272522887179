import {Injectable} from '@angular/core'
import deepEqual from 'deep-equal'
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs'
import {debounceTime, distinctUntilChanged, map, shareReplay, switchMap, filter} from 'rxjs/operators'
import {
  ChangeDetails,
  ChangedState,
  DocgenConfigAndMetadataChangeDetails,
  PublishConfigAndMetadataChangeDetails,
} from '../common/domain/change'
import {ConfigDetail, ConfigPath} from '../common/domain/config'
import {DocgenConfig, DocgenConfigKeyIndex} from '../common/domain/docgen-config'
import {PublishConfig, PublishConfigKeyIndex, TargetSystem} from '../common/domain/publish-config'
import {ChangeDetectingBehaviourSubject} from '../util/change-detecting-behaviour-subject'
import {ConfigService} from './config.service'
import {WorkspaceService} from './workspace.service'

interface ChangeMonitor {
  originals: ChangeDetails[]
  changes: ChangeDetails[]
}

@Injectable({
  providedIn: 'root',
})
export class ChangeService {
  /** Full set of configuration objects provided by the config service. */
  private originals: ChangeDetails[] = []
  private originalsSubject: ChangeDetectingBehaviourSubject<ChangeDetails[]> = new ChangeDetectingBehaviourSubject<ChangeDetails[]>('Originals', [])
  private changeMonitorSubject: ChangeDetectingBehaviourSubject<ChangeMonitor> = new ChangeDetectingBehaviourSubject<ChangeMonitor>('ChangeMonitor', {
    originals: [],
    changes: [],
  })

  /** Holds objects that are changed from the originals.  Will hold an empty array if there are no changes. */
  private changesSubject: BehaviorSubject<ChangeDetails[]> = new BehaviorSubject<ChangeDetails[]>([])
  changes: Observable<ChangeDetails[]> = this.changesSubject
    .pipe(
      debounceTime(100),
      // tap(c => {
      //   console.log('Checking changes')
      //   console.dir(c)
      // }),
      distinctUntilChanged(deepEqual),
      switchMap((storedChanges) => {
        const actualChanges = storedChanges
          .map((change) => this.getChange(change.path, storedChanges))
          .filter(x => x)
        return of(actualChanges)
      }),
      distinctUntilChanged(deepEqual),
      // tap(c => {
      //   console.log('Returning changes')
      //   console.dir(c)
      // }),
      shareReplay(1),
    )

  /** Holds the currently selected config for active change monitoring. */
  private selectedDocgenConfigSubject: ChangeDetectingBehaviourSubject<DocgenConfigAndMetadataChangeDetails> = new ChangeDetectingBehaviourSubject<DocgenConfigAndMetadataChangeDetails>('Selected Docgen Config Changes', undefined)
  selectedDocgenConfig = this.selectedDocgenConfigSubject.asObservable()

  private selectedPublishConfigSubject: ChangeDetectingBehaviourSubject<PublishConfigAndMetadataChangeDetails> = new ChangeDetectingBehaviourSubject<PublishConfigAndMetadataChangeDetails>('Selected Publish Config Changes', undefined)
  selectedPublishConfig = this.selectedPublishConfigSubject.asObservable()

  // Constructor code contains Extensive/ fairly long rnning logic
  //  This is leading to timing issues with calling this service
  //  New get property IsConstructorComplete can be used to mitigate the issue
  constructor(private configService: ConfigService, private workspaceService: WorkspaceService) {
    this.configService.allConfigs.subscribe((configs: ConfigDetail[]) => {
      const originals = configs.map(config => Object.assign({state: 'original'}, config) as ChangeDetails)
      this.originals = originals
      this.originalsSubject.next(originals)
      this._constructorPartAComplete = true;
    })

    this.workspaceService.selectedWorkspace.subscribe(workspace => {
      // console.log('Updating changes to match workspace')
      // console.dir(workspace)
      if (workspace && workspace.changes) {
        // TODO - Throw an error if there are already changes
        this.changesSubject.next(workspace.changes.map(change => {
          return Object.assign({source: 'lambda-ingest-product'}, change)
        }))
      }
      this._constructorPartBComplete = true;
    })

    combineLatest([this.originalsSubject, this.changes])
      .subscribe(([originals, changes]) => {
        this.changeMonitorSubject.next({originals, changes})
        this._constructorPartCComplete = true;
      })

    combineLatest([this.configService.selectedDocgenConfig, this.changes])
      .subscribe(([selectedConfig, changes]) => {
        this.selectedDocgenConfigSubject.next(selectedConfig && this.getDocgenConfigObject(selectedConfig.settings.path))
        this._constructorPartDComplete = true;
      })

    combineLatest([this.configService.selectedPublishConfig, this.changes])
      .subscribe(([selectedConfig, changes]) => {
        this.selectedPublishConfigSubject.next(selectedConfig && this.getPublishConfigObject(selectedConfig.settings.path))
        this._constructorPartEComplete = true;
      })
  }

  private _constructorPartAComplete = false
  private _constructorPartBComplete = false
  private _constructorPartCComplete = false
  private _constructorPartDComplete = false
  private _constructorPartEComplete = false
  public get IsConstructorComplete(): boolean {
    const result = this._constructorPartAComplete && this._constructorPartBComplete && this._constructorPartCComplete && this._constructorPartDComplete && this._constructorPartEComplete
    return result;
  }

  /**
   * Add an item to the collection of changed objects.
   *
   * @param item the item to add.
   */
  addChange(item: ConfigDetail): void {
    const changes = this.changesSubject.getValue().map((c: ChangeDetails) => Object.assign({}, c))
    const change = changes.find((c: ConfigDetail) => c.path === item.path)
    if (change) {
      changes.splice(changes.indexOf(change), 1)
    }

    // Handle Creation of New Protected RuleSet definition files
    let objectState = 'modified'
    if (item.source === 'lambda-ingest-product' && item.size === undefined && item.path.startsWith(`protected-rulesets/`)) {
      objectState = 'new'
    }
    if (item.source === 'lambda-ingest-product' && item.size === undefined && item.path.startsWith(`rulesets/`)) {
      objectState = 'new'
    }

    changes.push(Object.assign({source: 'lambda-ingest-product'}, item, {state: objectState}) as ChangeDetails)
    this.changesSubject.next(changes)
  }

  // Added Feb 2021. addChange was failing in some cases. This code was essentially copied from NewConfigRulesComponent.saveChangedConfig()
  //  Setting size, hash, modified and state as undefined result in the change being detected as Modified or New (as appropiate).
  upsertObject(item: ConfigDetail): void {
    const itemThatWillBeDetectedAsModifed = {
      source: item.source,
      name: item.name,
      path: item.path,
      content: item.content,
      size: undefined,
      hash: undefined,
      modified: undefined,
      state: undefined,
    } as ConfigDetail

    this.addChange(itemThatWillBeDetectedAsModifed)
  }

  /**
   * Mark an object for deletion.
   *
   * @param item the item to mark for deletion.
   */
  deleteObject(item: ConfigDetail): void {
    const changes = this.changesSubject.getValue().map((c: ChangeDetails) => Object.assign({}, c))
    console.log('Marking for deletion: ' + item.path)
    const change = changes.find((c: ConfigDetail) => c.path === item.path)
    if (change) {
      changes.splice(changes.indexOf(change), 1)
    }
    const deletion = {
      source: item.source,
      name: item.name,
      path: item.path,
      state: 'toDelete',
    }
    changes.push(deletion as ChangeDetails)
    this.changesSubject.next(changes)
  }

  /**
   * Reverts a change
   *
   * @param item the item to restored to the original value.
   */
  revertChange(item: ConfigDetail) {
    const changes = this.changesSubject.getValue().map((c: ChangeDetails) => Object.assign({}, c))
    console.log('Restoring original value for: ' + item.path)
    const change = changes.find((c: ConfigDetail) => c.path === item.path)
    if (change) {
      changes.splice(changes.indexOf(change), 1)
    }
    this.changesSubject.next(changes)
  }

  /**
   * Get the original object identified by the supplied path.
   *
   * @param path the path of the object sought.
   * @return the object, if it exists.
   */
  getOriginal(path: string): ChangeDetails | undefined {
    return this.originals.find((o: ChangeDetails) => o.path === path)
  }

  /**
   * Get the changed object identified by the supplied path.
   *
   * @param path the path of the object sought.
   * @return the object, if it has changes compared to its original form, but otherwise return undefined.
   */
  getChange(path: string, changes?: ChangeDetails[]): ChangeDetails | undefined {
    const actualChanges = changes || this.changesSubject.getValue()
    const change = actualChanges.find((c: ConfigDetail) => c.path === path)
    const original = this.originals.find((o: ConfigDetail) => o.path === path)

    if ((change && !original) || (change && original.content !== change.content) || (change && change.state === 'toDelete')) {
      return Object.assign({}, change)
    } else {
      if (change) {
        console.log('Skipping change - nothing relevant')
        console.dir({
          change: change,
          original: original,
        })
      }
      return undefined
    }
  }

  /**
   * Get the object identified by the supplied path, and decorated with its {@link ChangedState}.
   *
   * @param path the path in Storm's canonical form.
   * @param originals optionally supply the original state of objects held.
   * @return the current version of the object.
   */
  getObject(path: string, originals: ChangeDetails[] = this.originals, changes?: ChangeDetails[]): ChangeDetails {
    const original = originals.find((o: ConfigDetail) => o.path === path)
    const changed = (changes || this.changesSubject.getValue()).find((c: ConfigDetail) => c.path === path)

    if (original && !changed) {
      return Object.assign({}, original)
    }

    if (changed) {
      if (changed.state === 'toDelete') {
        // return changed
        return Object.assign({}, changed)
      }
      if (original) {
        if (original.content !== changed.content) {
          // changed.state = 'modified'
          // return changed
          return Object.assign({}, changed, {
            state: 'modified',
          })
        } else {
          return Object.assign({}, original)
        }
      } else {
        // changed.state = 'new'
        // return changed
        return Object.assign({}, changed, {
          state: 'new',
        })
      }
    }

    throw new Error(`Failed to find object with path: ${path}`)
  }

  /**
   * Get all changes.
   *
   * @return any and all objects that have been changed compared to their original form, or an empty array if none.
   */
  getChanges(): ChangeDetails[] {
    const changes = this.changesSubject.getValue()
    return changes
      .map((change) => this.getChange(change.path, changes))
      .filter(x => x)
  }

  /**
   * Get all objects of the requested type.  Each object will indicate whether it is unmodified, modified from the original, new, or
   * to be deleted.
   *
   * @param type the type of configuration object sought.
   * @return an observable of all objects of the requested type, or an empty array if none.
   */
  public getObjectsOfType(type: string): Observable<ChangeDetails[]> {
    console.log('getObjectsOfType = ' + type)
    return this.changeMonitorSubject
      .pipe(
        filter((changeMonitor: ChangeMonitor, index: number) => {
          if(index <= 1) return true; // Gets first emissions regardless of changes.length
          if(changeMonitor.changes.length === 0) return false;
          console.log('Change Type: ' + type)
          console.log('Change: '  + JSON.stringify(changeMonitor.changes))
          if(changeMonitor.changes.filter(change => change.path.startsWith(type)).length > 0) return true
          else return false
        }),
        switchMap((changeMonitor: ChangeMonitor) => {
          const allConfigs = [...changeMonitor.originals, ...changeMonitor.changes]
            // Just need the paths
            .map(c => c.path)
            // which we can filter
            .filter(path => path.startsWith(type))
            // and must then dedupe
            .filter((path, index, paths) => paths.indexOf(path) === index)
            // so we can get the latest state of the configs for each
            .map((path: string) => this.getObject(path, changeMonitor.originals, changeMonitor.changes))

          return of(allConfigs)
        }),
      )
  }

  getDocgenConfigObject(path: ConfigPath): DocgenConfigAndMetadataChangeDetails {
    const settings = this.getObject(path)

    const config = JSON.parse(settings.content) as DocgenConfig

    const configSchemas = []
      .concat(config.validatePayload.schema)
      .concat(config.validateEnriched.schema)
      .filter(x => x)
      .map(x => this.getObject(x))

    const configTransforms = []
      .concat(config.transform.transforms)
      .concat(config.enrich.transforms)
      .filter(x => x)
      .map(x => this.getObject(x))

    const rulesets = this.getRulessets()
    const docgenConfigAndMetadataChangeDetails = this.asDocgenConfigObject(settings, configSchemas, configTransforms, rulesets);
    return docgenConfigAndMetadataChangeDetails

  }

  private getRulessets() {
    return this.getAllChanges()
      .map(x => x.path)
      .filter(x => x.startsWith('rulesets'))
      .filter((value, index, self) => {
        return self.indexOf(value) === index;
      })
      .map(x => this.getObject(x))
  }

  getPublishConfigObject(path: ConfigPath): PublishConfigAndMetadataChangeDetails {
    const settings = this.getObject(path)
    const config: PublishConfig = Object.assign(PublishConfig.create(), JSON.parse(settings.content))
    const configSchemas = []
      .concat(config.validatePayload.schema)
      .concat(config.validateEnriched.schema)
      .filter(x => x)
    const configTransforms = []
      .concat(config.transform.transforms)
      .concat(config.enrich.transforms)
      .concat(config.combine.transforms)
      .concat(config.encapsulate.transforms)
      .filter(x => x)

    return this.asPublishConfigObject(settings, configSchemas.map(curPath => this.getObject(curPath)), configTransforms.map(curPath2 => this.getObject(curPath2)))
  }

  getAllDocgenConfigs(): Observable<DocgenConfigAndMetadataChangeDetails[]> {
    return combineLatest([
      this.getObjectsOfType('settings'),
      this.getObjectsOfType('schemas'),
      this.getObjectsOfType('transforms'),
      this.getObjectsOfType('rulesets')
    ])
      .pipe(
        switchMap(([settings, schemas, transforms, rulesets], ) => {
          if (settings && settings.length > 0 && schemas && schemas.length > 0 && transforms && transforms.length > 0) {
            const combined = settings.map((setting) => {
              return this.asDocgenConfigObject(setting, schemas, transforms, rulesets)
            })
            return of(combined)
          } else {
            return of([])
          }
        }),
        distinctUntilChanged(),
      )
  }

  public getUserFilteredDocgenConfigs(): Observable<DocgenConfigAndMetadataChangeDetails[]> {
    return this.configService.filteredDocgenConfigs.pipe(
      switchMap(configFilters => {
        return this.getAllDocgenConfigs().pipe(
          map(configs => {
            return configs.filter(config => {
              if (configFilters.length > 0) {
                const match = config.settings.path.match(/^.*\/([^\/]+)\/([^\/]+)\/([^\/]+)\/([^\/]+)\/Config\.json$/)
                if (match) {
                  const sourceSystem = match[DocgenConfigKeyIndex.SOURCE_SYSTEM]
                  const programme = match[DocgenConfigKeyIndex.PROGRAMME]
                  const productType = match[DocgenConfigKeyIndex.PRODUCT_TYPE]
                  const documentType = match[DocgenConfigKeyIndex.DOCUMENT_TYPE]
                  return configFilters.find(filter => {
                    return filter.sourceSystem === sourceSystem
                      && filter.programme === programme
                      && filter.productType === productType
                      && filter.documentType === documentType
                  })
                }
              }
              return true
            })
          }),
        )
      }),
      distinctUntilChanged(),
      // tap(c => console.dir(c)),
    )
  }

  getPublishConfigs(): Observable<PublishConfigAndMetadataChangeDetails[]> {
    return combineLatest([
      this.getObjectsOfType('settings'),
      this.getObjectsOfType('schemas'),
      this.getObjectsOfType('transforms'),
    ])
      .pipe(
        switchMap(([settings, schemas, transforms]) => {
          if (settings && settings.length > 0 && schemas && schemas.length > 0 && transforms && transforms.length > 0) {
            const combined = settings.map((setting) => {
              return this.asPublishConfigObject(setting, schemas, transforms)
            })
            return of(combined)
          } else {
            return of([])
          }
        }),
        distinctUntilChanged(),
      )
  }

  clearChanges() {
    this.changesSubject.next([])
  }

  getAllChanges() {
    return this.changesSubject.getValue()
  }

  private asDocgenConfigObject(setting: ChangeDetails, schemas: ChangeDetails[], transforms: ChangeDetails[], rulesets: ChangeDetails[]) {

    const match = setting.path.match(/^.*\/([^\/]+)\/([^\/]+)\/([^\/]+)\/([^\/]+)\/Config\.json$/)
    const sourceSystem = match[DocgenConfigKeyIndex.SOURCE_SYSTEM]
    const programme = match[DocgenConfigKeyIndex.PROGRAMME]
    const productType = match[DocgenConfigKeyIndex.PRODUCT_TYPE]
    const documentType = match[DocgenConfigKeyIndex.DOCUMENT_TYPE]
    const config = JSON.parse(setting.content) as DocgenConfig
    const configPathStripped = setting.path.replace('settings/', '')
      .replace('/Config.json', '');

    const configSchemaEntries: string[] = []
      .concat(config.validatePayload.schema)
      .concat(config.validateEnriched.schema)
      .filter(x => x)
    const configTransformEntries: string[] = []
      .concat(config.transform.transforms)
      .concat(config.enrich.transforms)
      .filter(x => x)

    const configSchemas = schemas.filter(schema => configSchemaEntries.includes(schema.path))
    const configTransformation = transforms.filter(transform => configTransformEntries.includes(transform.path));
    const configRuleSets = rulesets.filter(x => {
      switch (x.state) {
        case 'original':
        case 'modified':
        case 'new':
          return x.content && JSON.parse(x.content).entries.includes(configPathStripped)
        case 'toDelete':
          const original = this.originals.find(o=> o.path === x.path);
          return original && original.content && JSON.parse(original.content).entries.includes(configPathStripped)
        default:
          throw Error(`Change state '${x.state}' is not supported`)
      }
    });

    const allChanges: ChangeDetails[] = configSchemas
      .concat(configTransformation)
      .concat(configRuleSets)
      .concat(setting)

    const overallState: ChangedState = allChanges.every(change => change.state === 'original')
      ? 'original'
      : 'modified'

    const result: DocgenConfigAndMetadataChangeDetails = {
      state: overallState,
      metadata: {
        sourceSystem,
        programme,
        productType,
        documentType,
      },
      settings: setting,
      schemas: configSchemas,
      transforms: configTransformation,
      rulesets: configRuleSets
    }

    return result
  }

  private asPublishConfigObject(setting: ChangeDetails, schemas: ChangeDetails[], transforms: ChangeDetails[]) {
    const match = setting.path.match(/^.*\/([^\/]+)\/([^\/]+)\/([^\/]+)\/([^\/]+)\/Config\.json$/)
    const sourceSystem = match[PublishConfigKeyIndex.SOURCE_SYSTEM]
    const businessUnit = match[PublishConfigKeyIndex.BUSINESS_UNIT]
    const payloadType = match[PublishConfigKeyIndex.PAYLOAD_TYPE]
    const targetSystem = match[PublishConfigKeyIndex.TARGET_SYSTEM] as TargetSystem
    const loaded = JSON.parse(setting.content) as PublishConfig
    const config = Object.assign(PublishConfig.create(), loaded)
    const configSchemas = []
      .concat(config.validatePayload.schema)
      .concat(config.validateEnriched.schema)
      .filter(x => x)
    const configTransforms = []
      .concat(config.transform.transforms)
      .concat(config.enrich.transforms)
      .concat(config.combine.transforms)
      .concat(config.encapsulate.transforms)
      .filter(x => x)

    const allChanges = schemas.filter(schema => configSchemas.includes(schema.path))
      .concat(transforms.filter(transform => configTransforms.includes(transform.path)))
      .concat(setting)

    const overallState = allChanges.every(change => change.state === 'original')
      ? 'original'
      : 'modified'

    const result: PublishConfigAndMetadataChangeDetails = {
      state: overallState,
      metadata: {
        sourceSystem: sourceSystem,
        businessUnit: businessUnit,
        payloadType: payloadType,
        targetSystem: targetSystem,
      },
      settings: setting,
      schemas: schemas.filter(schema => configSchemas.includes(schema.path)),
      transforms: transforms.filter(transform => configTransforms.includes(transform.path)),
    }
    return result
  }
}
