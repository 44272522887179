export interface ColourScheme {
  opacity: number,
  main: string
  front: string
  side: string
  highlight: string
  background: string
  text: string
  label: string
  line: string
}

export interface ColourLookup {
  HIDDEN: ColourScheme
  UNKNOWN: ColourScheme
  PROCESSING: ColourScheme
  SKIPPED: ColourScheme
  WARNING: ColourScheme
  ERROR: ColourScheme
  OK: ColourScheme
  BLACKLIST: ColourScheme
  WHITELIST: ColourScheme
}

export const COLOURS: ColourLookup = {
  HIDDEN: {
    opacity: 0.1,
    main: '#9e9e9e',
    front: '#858585',
    side: '#6b6b6b',
    highlight: '#deeaf5',
    background: '#ffffff',
    text: '#000000',
    label: '#ffffff',
    line: '#9e9e9e',
  },
  UNKNOWN: {
    opacity: 0.2,
    main: '#9e9e9e',
    front: '#858585',
    side: '#6b6b6b',
    highlight: '#deeaf5',
    background: '#ffffff',
    text: '#000000',
    label: '#ffffff',
    line: '#9e9e9e',
  },
  PROCESSING: {
    opacity: 0.7,
    main: '#4286c5',
    front: '#326ca2',
    side: '#26527b',
    highlight: '#deeaf5',
    background: '#ffffff',
    text: '#000000',
    label: '#ffffff',
    line: '#4286c5',
  },
  SKIPPED: {
    opacity: 0.7,
    main: '#9e9e9e',
    front: '#858585',
    side: '#6b6b6b',
    highlight: '#deeaf5',
    background: '#ffffff',
    text: '#000000',
    label: '#ffffff',
    line: '#9e9e9e',
  },
  WARNING: {
    opacity: 1.0,
    main: '#ff9800',
    front: '#cc7a00',
    side: '#995b00',
    highlight: '#ffeacc',
    background: '#ffffff',
    text: '#000000',
    label: '#ff9800',
    line: '#ff9800',
  },
  ERROR: {
    opacity: 1.0,
    main: '#f44336',
    front: '#ea1c0d',
    side: '#ba160a',
    highlight: '#fff8f7',
    background: '#ffffff',
    text: '#000000',
    label: '#f44336',
    line: '#f44336',
  },
  OK: {
    opacity: 1.0,
    main: '#009688',
    front: '#00635a',
    side: '#00302c',
    highlight: '#63fff0',
    background: '#ffeb3b',
    text: '#000000',
    label: '#ffeb3b',
    line: '#009688',
  },
  BLACKLIST: {
    opacity: 1.0,
    main: '#3c3c3c',
    front: '#000000',
    side: '#3c3c3c',
    highlight: '#666666',
    background: '#ff0000',
    text: '#ffffff',
    label: '#f44336',
    line: '#f44336',
  },
  WHITELIST: {
    opacity: 1.0,
    main: '#009688',
    front: '#e6e6e6',
    side: '#cccccc',
    highlight: '#999999',
    background: '#ffeb3b',
    text: '#000000',
    label: '#ffffff',
    line: '#009688',
  },
}
