import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core'
import {Subscription} from 'rxjs'
import {TestManagerService} from '../../services/test-manager.service'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import {TestSuiteGroupEditDialogComponent} from '../../dialogs/test-suite-group-edit-dialog/test-suite-group-edit-dialog.component'
import {TestSuiteGroupMetadata} from 'src/app/model/test-management-responses'
import {ConfirmDialogComponent} from '../../dialogs/confirm-dialog.component'
import {TestSuiteDefinition, TestSuiteMetadata} from '../../common/domain/tests'
import {Workspace} from '../../common/domain/workspaces'
import {WorkspaceService} from '../../services/workspace.service'

@Component({
  selector: 'app-test-suite-group-selector',
  templateUrl: './test-suite-group-selector.component.html',
  styleUrls: ['./test-suite-group-selector.component.scss'],
})
export class TestSuiteGroupSelectorComponent implements OnInit, OnDestroy {
  subs = new Subscription()
  documentFormatType = 'pdf'

  selectedTestSuiteGroup: any

  @Output() testSuiteGroup = new EventEmitter<TestSuiteGroupMetadata>()
  @Input() displayProdClone = true

  testSuiteGroups: TestSuiteGroupMetadata[]
  testSuites: TestSuiteMetadata[]
  testSuitesToBeExecuted: TestSuiteMetadata[] = []
  testCount: number
  workspace: Workspace
  includeProdClone: boolean

  constructor(
    private testManagerService: TestManagerService,
    private workspaceService: WorkspaceService,
    private modalService: NgbModal,
    ) {
  }

  ngOnInit() {
    this.subs.add(
      this.testManagerService.testSuiteGroups.subscribe(results => {
        this.testSuiteGroups = results
      }),
    )
    this.subs.add(
      this.testSuiteGroup.subscribe(value => {
        this.selectedTestSuiteGroup = value && value.name
      }),
    )
    this.subs.add(
      this.testManagerService.selectedTestSuiteGroup.subscribe(result => {
        if (result) {
          this.testSuiteGroup.emit({
            name: result.name,
            testSuites: result.testSuites,
          })
        } else {
          this.testSuiteGroup.emit(undefined)
        }
      }),
    )
    this.subs.add(
      this.testManagerService.testSuites.subscribe(results => {
        this.testSuites = results
      }),
    )
    this.subs.add(
      this.workspaceService.selectedWorkspace.subscribe(workspace => {
        this.workspace = workspace
      }),
    )
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe()
  }

  public executeTestSuiteGroup(format?: string) {
    this.documentFormatType = format !== undefined ? format : 'pdf'
    const confirmDialog = this.modalService.open(ConfirmDialogComponent, {
      backdrop: false,
    })
    confirmDialog.componentInstance.title = 'Execute Test Suite Group'
    confirmDialog.componentInstance.message = `Are you sure you want to execute test suite group: ${this.selectedTestSuiteGroup.name} (${this.testCount} tests)`

    confirmDialog.result.then(confirmed => {
      if (this.testSuitesToBeExecuted.length > 0) {
        this.testManagerService.executeTestSuiteGroup(this.selectedTestSuiteGroup.name, this.workspace && this.workspace.name, this.documentFormatType)
      }
    }).catch(err => {
    })
  }

  public selectionChanged(event: any) {
    if (event && event.value) {
      this.testSuitesToBeExecuted = this.testSuites.filter(testSuite => event.value.testSuites.map(entry => entry.name).indexOf(testSuite.name) > -1)
      this.testCount = this.testSuitesToBeExecuted.map(testSuite => (testSuite as TestSuiteDefinition).tests.length).reduce((a, b) => a + b, 0)
    }

  }

  public deleteTestSuiteGroup() {
    const confirmDialog = this.modalService.open(ConfirmDialogComponent, {
      backdrop: false,
    })
    confirmDialog.componentInstance.title = 'Delete Test Suite Group'
    confirmDialog.componentInstance.message = 'Are you sure you want to delete test suite group: ' + this.selectedTestSuiteGroup.name

    confirmDialog.result.then(confirmed => {
      this.testManagerService.deleteTestSuiteGroup(
        {
          name: this.selectedTestSuiteGroup.name,
          testSuites: [],
        }
      )
    }).catch(err => {
    })
  }

  public testSuiteGroupDialog(newGroup: boolean) {
    if (!newGroup && this.selectedTestSuiteGroup == null) {
      return
    }
    const dialog = this.modalService.open(TestSuiteGroupEditDialogComponent)
    if (newGroup) {
      dialog.componentInstance.title = 'Add new test suite group'
    } else {
      dialog.componentInstance.title = 'Edit test suite group'
      dialog.componentInstance.name = this.selectedTestSuiteGroup.name
      dialog.componentInstance.selectedTestSuites = this.selectedTestSuiteGroup.testSuites.map(suite => suite.name)
    }
    dialog.result.then(res => {
      console.log('Got result')
      console.dir(res)
      if (res) {
        const testSuites = res.testSuites.filter(entry => !!entry).map(entry => {return { name: entry}})
        this.testManagerService.saveTestSuiteGroup(
          {
            name: res.name,
            testSuites,
          },
        )
      }
    }).catch(err => {
      console.log('Cancelled')
    })
  }
}
