import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {RuleType} from '../common/domain/analyse'

export interface AddAnalysisRuleDetails {
  title: string
  name: string
  description: string
  help: string
  mnemonic: string
  requireStageAndList?: boolean
  stage?: 'product' | 'solo'
  ruleType?: RuleType
  fields: string[]
  placeholderValue: string
  showNextButton?: boolean
}

@Component({
  templateUrl: 'analysis-add-rule-dialog.component.html',
  styleUrls: ['./analysis-add-rule-dialog.component.scss'],
})
export class AnalysisAddRuleDialogComponent {

  showNextButton: boolean
  stages = ['product', 'solo']
  ruleTypes = ['whitelist', 'blacklist', 'notSupported', 'validation','complex']

  constructor(public dialogRef: MatDialogRef<AnalysisAddRuleDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: AddAnalysisRuleDetails) {
    this.showNextButton = data.showNextButton || false
    this.data.fields = []
    this.addField()
  }

  cancel() {
    this.dialogRef.close(undefined)
  }

  addField() {
    const currentFields = [...this.data.fields]
    currentFields.push(this.data.placeholderValue)
    this.data.fields = currentFields
  }

  trackByIndex(index: number): number {
    return index
  }
}
