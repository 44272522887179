<div class="container-fluid h-75">
  <div class="row">
    <div class="col-lg-9">
      <ng-container>
        <app-test-selector service="docgen" allowDelete="false" (cleared)="testReset()"></app-test-selector>
      </ng-container>

      <ng-container *ngIf="loadedVersion">Loaded Version: {{loadedVersion}}</ng-container>
    </div>
    <div class="col-lg-3">
      <app-product-governance-status id="lambda-product-governance-graphic-icon"
                           [width]="240"
                           [height]="100"
                           [governanceResponse]="governanceResponse"
                           [refresh]="refreshGraphic"
      ></app-product-governance-status>
    </div>
  </div>
</div>

<h2>TODO - This needs to have a proper configuration / service definition similar to docgen / publish</h2>

<div class="container-fluid h-75">
  <ul ngbNav #governanceNav="ngbNav" class="nav-tabs">
    <li ngbNavItem>
      <a ngbNavLink>Payload</a>
      <ng-template ngbNavContent>
        <div class="payload-input">
          <ngx-monaco-editor
            class="monaco-editor payload-editor"
            [options]="payloadEditorOptions"
            [(ngModel)]="payloadValue"
            (ngModelChange)="refresh()"
          >
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem>
      <a ngbNavLink>Overview</a>
      <ng-template ngbNavContent>
        <div class="lambda-graphic">
          <app-product-governance-status id="lambda-product-governance-graphic"
                                         [width]="1200"
                                         [height]="560"
                                         [governanceResponse]="governanceResponse"
                                         [refresh]="refreshGraphic"
          ></app-product-governance-status>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="governance">
      <a ngbNavLink>Governance</a>
      <ng-template ngbNavContent>
        <div class="result analysis">
          <app-analysis-view [analysisResult]="governance" [governanceMode]="true"></app-analysis-view>
        </div>
      </ng-template>
    </li>


    <li ngbNavItem *ngIf="csSchemaValue">
      <a ngbNavLink>CS Schema</a>
      <ng-template ngbNavContent>
        <div class="container-fluid" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="source-schema-input">
                <ngx-monaco-editor
                  class="monaco-editor source-schema-editor"
                  [options]="schemaEditorOptions"
                  [(ngModel)]="csSchemaValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="csValidation">
                <div class="source-validation-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonOptions"
                    [ngModel]="csValidation">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="transformValue">
      <a ngbNavLink>Transform</a>
      <ng-template ngbNavContent>
        <div class="container-fluid" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="transform-input">
                <ngx-monaco-editor
                  class="monaco-editor transform-editor"
                  [options]="transformEditorOptions"
                  [(ngModel)]="transformValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="transformed">
                <div class="transform-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonOptions"
                    [ngModel]="transformed">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="targetSchemaValue">
      <a ngbNavLink>Target Schema</a>
      <ng-template ngbNavContent>
        <div class="container-fluid" fxFlex>
          <div class="row">
            <div class="col-md-6">
              <div class="target-schema-input">
                <ngx-monaco-editor
                  class="monaco-editor target-schema-editor"
                  [options]="schemaEditorOptions"
                  [(ngModel)]="targetSchemaValue"
                  (ngModelChange)="refresh()"
                >
                </ngx-monaco-editor>
              </div>
            </div>
            <div class="col-md-6">
              <ng-container *ngIf="targetValidation">
                <div class="target-validation-output">
                  <ngx-monaco-editor
                    class="monaco-editor"
                    [options]="readOnlyJsonOptions"
                    [ngModel]="targetValidation">
                  </ngx-monaco-editor>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="testHistory">
      <a ngbNavLink>History</a>
      <ng-template ngbNavContent>
        <app-test-history-list [history]="testHistory" (selectedVersion)="selectTestHistory($event)"></app-test-history-list>
      </ng-template>
    </li>

    <li ngbNavItem *ngIf="trace">
      <a ngbNavLink>Trace</a>
      <ng-template ngbNavContent>
        <div class="trace-output">
          <ngx-monaco-editor
            class="monaco-editor"
            [options]="readOnlyJsonOptions"
            [ngModel]="trace">
          </ngx-monaco-editor>
        </div>
      </ng-template>
    </li>
  </ul>


  <div [ngbNavOutlet]="governanceNav"></div>
</div>

<ng-container class="actions">
  <button type="button" class="btn btn-primary" (click)="performGovernanceCheck()" [disabled]="!docgenConfig">Perform Governance Check</button>
</ng-container>
