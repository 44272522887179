const js2xmlparser = require('js2xmlparser');

export const jsonToXml = (jsoninput: string) => {
  if (jsoninput.length === 0)
    throw new Error('JSON payload is empty, cannot transform into XML');

  try {
    const parsedInput = JSON.parse(jsoninput);
    const rootObject = Object.keys(parsedInput)[0];
    const xmlObject = js2xmlparser.parse(
      rootObject,
      parsedInput[Object.keys(parsedInput)[0]]
    );

    return xmlObject;
  } catch (error) {
    throw new Error('Error on transforming payload from JSON to XML. ' + error);
  }
};
