import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import deepFreeze from 'deep-freeze';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ChangeDetails } from 'src/app/common/domain/change';
import { Ruleset2021CreateNewDialogComponent } from 'src/app/dialogs/ruleset-2021-create-new-dialog/ruleset-2021-create-new-dialog';
import { UserActionDownloadCsvFiles } from 'src/app/dialogs/ruleset-2021-view-edit-dialog/logic/user-action-download-csvs';
import { UserActionDownloadsNewFormat } from 'src/app/dialogs/ruleset-2021-view-edit-dialog/logic/user-action-download-csvs-new-format';
import { UserActionDownloadErrors } from 'src/app/dialogs/ruleset-2021-view-edit-dialog/logic/user-action-download-errors';
import { RuleSet2021ViewEditDialogComponentData } from 'src/app/dialogs/ruleset-2021-view-edit-dialog/ruleset-2021-view-edit-dialog-component-data';
import { RuleSet2021ViewEditDialogComponent } from 'src/app/dialogs/ruleset-2021-view-edit-dialog/ruleset-2021-view-edit-dialog.component';
import { NormalisedRuleSet } from 'src/app/model/normalised-ruleset';
import { ChangeService } from 'src/app/services/change.service';
import { ConfigMonitorService } from 'src/app/services/config-monitor.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { StaleWorkspaceMonitorService } from 'src/app/services/stale-workspace-monitor.service';
import { UserNameService } from 'src/app/services/user-name.service';
import { Router } from "@angular/router";
import { BasicHelper } from 'src/app/common/basic-helper';
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiService } from 'src/app/services/api.service';
import { GetConfigFileSuccessResponse } from 'src/app/model/config-responses';
import { RuleExclusion } from 'src/app/model/rule-exclusion';
// Context Menu Fancy Footwork
var showRuleSetContextMenu = false

function hideContextMenu() {
  showRuleSetContextMenu = false
}

function closeContextMenuIfOpen() {
  // console.info('clicked or right-clicked')
  const event = window.event as MouseEvent
  const target = (event.target as any).id

  // Close the context Menu and Stop the Click even in its tracks (Unless we are click on the Context Menu itself)
  if (showRuleSetContextMenu && target !== 'ViewRuleSet' && target != 'EditRuleSet') {
    // Close the menu
    hideContextMenu()

    // Precent any other click/ rightclick events from firing UNLESS we are on the right click menu itself
    event.stopPropagation()
    event.preventDefault()
  }
}
// End Context Menu Fancy Footwork; Also see constructor and ngOnDestroy()
@Component({
  selector: 'app-maintain-2021-ruleset-component',
  templateUrl: './maintain-2021-ruleset-component.html',
  styleUrls: ['./maintain-2021-ruleset-component.scss'],
})
export class Maintain2021RulesetComponent implements OnInit {

  constructor(
    private changeService: ChangeService,
    private permissionsService: PermissionsService,
    private dialog: MatDialog,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private userNameService: UserNameService,
    private staleWorkspaceMonitorService: StaleWorkspaceMonitorService,
    private configMonitorService: ConfigMonitorService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private api: ApiService
  ) {
    // Add these event listeners in the constructor because we may run ngOnInit multiple times
    document.addEventListener('click', closeContextMenuIfOpen, true);
    document.addEventListener('contextmenu', closeContextMenuIfOpen, true);
  }

  // Context Menu related
  public rightClickedRuleSetMnemonic = ''
  private mouseLocation: { left: number, top: number } = { left: 0, top: 0 };
  private _username: string
  private _subscriptions = new Array<Subscription>()

  public chipProductSelected = true // Used by html
  public chipSoloSelected = true // Used by html
  public chipNotSupportedSelected = true // Used by html
  public chipBlacklistSelected = true // Used by html
  public chipValidationSelected = true // Used by html
  public chipWhitelistSelected = true // Used by html
  public chipComplexSelected = true // Used by html

  public loading = false;
  public canAddRule = false // used by html

  public newRuleSetMnemonics = new Array<string>() // Used by html to disaply 'new' icon
  public modifiedRuleSetMnemonics = new Array<string>() // Used by html to disaply 'modified' icon

  public ruleSetsToDisplay: Array<NormalisedRuleSet> = new Array<NormalisedRuleSet>() // Used by html
  public _ruleSetsAvailableToDisplay: Array<NormalisedRuleSet> = new Array<NormalisedRuleSet>() // Used by html

  public deletedRuleSetsToDisplay: Array<NormalisedRuleSet> = new Array<NormalisedRuleSet>()
  public showDeletedRuleSets = false

  async ngOnInit(): Promise<void> {
    // Unsubcribe any existing subscriptions
    this._subscriptions.forEach(x => x.unsubscribe())

    this._subscriptions.push(
      this.staleWorkspaceMonitorService.staleWorkspace.subscribe(stale => {
        console.info('staleWorkspace')
      })
    )
    this._subscriptions.push(
      this.configMonitorService.configStale.subscribe(stale => {
        console.info('configStale')
      }),
    )

    const apiRuleSets$ = this.changeService.getObjectsOfType('rulesets')
    this._subscriptions.push(apiRuleSets$.subscribe(
      (changes: ChangeDetails[]) => {
        this.spinner.show();
        // console.info(`this.changeService.getObjectsOfType('rulesets') ${changes.length}`)
        if (changes.length > 0) {
          // ChangeService is now populated with data, so get it
          this._getDataFromChangeService()
        }
      })
    )

    this._subscriptions.push(this.permissionsService.addRules.subscribe(
      permission => this.canAddRule = permission)
    )
    this._subscriptions.push(this.userNameService.userName.subscribe(
      username => this._username = username)
    )
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(x => x.unsubscribe())

    // Remove Event listeners related to Right Click Menu
    document.removeEventListener('click', closeContextMenuIfOpen, true)
    document.removeEventListener('contextmenu', closeContextMenuIfOpen, true)
  }

  private async _getDataFromChangeService() {
    console.log('getDataFromChangeService')
    // Reset new/ modified indicator flags
    this.newRuleSetMnemonics = new Array<string>()
    this.modifiedRuleSetMnemonics = new Array<string>()

    // const protectedRuleSets$ = this.changeService.getObjectsOfType('protected-rulesets/')
    const normalisedRuleSets$ = this.changeService.getObjectsOfType('rulesets/')
    const userFilteredDocgenConfigs$ = this.changeService.getUserFilteredDocgenConfigs()

    let [normalisedRuleSetsChangeDetails, userFilteredDocgenConfigs] = await Promise.all([
      normalisedRuleSets$.pipe(take(1)).toPromise(),
      userFilteredDocgenConfigs$.pipe(take(1)).toPromise()
    ])

    // Remove any Soft Deletes (the current soft delete mechanism I find quite a Gotcha)
    normalisedRuleSetsChangeDetails = normalisedRuleSetsChangeDetails.filter(x => x.state !== 'toDelete')
    deepFreeze(normalisedRuleSetsChangeDetails) // Just in case we accidentially try to change it

    // Handle new and modifed entries
    {
      const newEntries = normalisedRuleSetsChangeDetails.filter(x => x.state === 'new').map(x => JSON.parse(x.content).mnemonic)
      this.newRuleSetMnemonics.push(...newEntries)
    }
    {
      const modifiedEntries = normalisedRuleSetsChangeDetails.filter(x => x.state === 'modified').map(x => JSON.parse(x.content).mnemonic)
      this.modifiedRuleSetMnemonics.push(...modifiedEntries)
    }

    // Convert to Class Instances
    const normalisedRuleSets = normalisedRuleSetsChangeDetails.map(x => {
      const newNormalisedRuleSet = new NormalisedRuleSet(x.path)
      const deserialised: NormalisedRuleSet = JSON.parse(x.content)
      Object.assign(newNormalisedRuleSet, deserialised)
      return newNormalisedRuleSet
    })

    // Use only those selected in the filter page (Note: we always show RuleSets with associated product/document)
    // console.info(`Before filtering to user's selection on Config->Filter Page`)
    const usersCurrentFilter = userFilteredDocgenConfigs.map(x => `${x.metadata.sourceSystem}/${x.metadata.programme}/${x.metadata.productType}/${x.metadata.documentType}`)

    let ruleSetsAvailableToDisplay = normalisedRuleSets.filter((curNormalisedRuleSet: NormalisedRuleSet) => {
      let displayTheRuleSet = false

      // Regardless of filter always show RuleSets with zero entries. This ensures newly added ones display
      if (curNormalisedRuleSet.entries.length === 0) {
        displayTheRuleSet = true
      }

      if (!displayTheRuleSet) {
        // Loop through entries. If any single entry exists then we should display this RuleSet
        for (let i = 0; i < curNormalisedRuleSet.entries.length; i++) {
          if (usersCurrentFilter.includes(curNormalisedRuleSet.entries[i])) {
            displayTheRuleSet = true
            break // Exit the loop, no need to look at any more entries
          }
        }
      }

      return displayTheRuleSet
    })

    // console.info(`After filtering to user's selection on Config->Filter Page`)
    // console.info(`ruleSetsAvailableToDisplay: ${ruleSetsAvailableToDisplay.length}`)

    this._ruleSetsAvailableToDisplay = ruleSetsAvailableToDisplay.sort((a, b) => {
      return a.mnemonic.toUpperCase().localeCompare(b.mnemonic.toUpperCase())
    })

    this.deletedRuleSetsToDisplay = this._ruleSetsAvailableToDisplay.filter(rule => rule.deletion)
    this._applyChipFilterToDisplayedRuleSets()
    // console.info('RuleSets to Display Length: ' + this.filteredRuleSets.length)
    this.spinner.hide();
  }

  private _applyChipFilterToDisplayedRuleSets() {
    const ruleSetsToDisplay = this._ruleSetsAvailableToDisplay.filter((curRuleSet: NormalisedRuleSet) => {

      if (curRuleSet.deletion) {
        return false
      }

      let displayCurRuleSet = false
      displayCurRuleSet = displayCurRuleSet || (this.chipBlacklistSelected && curRuleSet.ruleType === 'blacklist')
      displayCurRuleSet = displayCurRuleSet || (this.chipNotSupportedSelected && curRuleSet.ruleType === 'notSupported')
      displayCurRuleSet = displayCurRuleSet || (this.chipValidationSelected && curRuleSet.ruleType === 'validation')
      displayCurRuleSet = displayCurRuleSet || (this.chipWhitelistSelected && curRuleSet.ruleType === 'whitelist')
      displayCurRuleSet = displayCurRuleSet || (this.chipComplexSelected && curRuleSet.ruleType === 'complex')

      //TODO plugh Goveranace?

      // Are we hiding Product RuleSets?
      if (this.chipProductSelected === false && curRuleSet.stage === 'product') {
        displayCurRuleSet = false
      }
      // Are we hiding Solo RuleSets?
      if (this.chipSoloSelected === false && curRuleSet.stage === 'solo') {
        displayCurRuleSet = false
      }

      return displayCurRuleSet
    })

    this.ruleSetsToDisplay = ruleSetsToDisplay
  }

  public toggleChip(event: MouseEvent): void {
    const chipId = (event.target as Element).id

    // const viewControl = this.viewControl.getValue()
    switch (chipId) {
      case 'product': {
        this.chipProductSelected = !this.chipProductSelected
        break
      }
      case 'solo': {
        this.chipSoloSelected = !this.chipSoloSelected
        break
      }
      case 'whitelist': {
        this.chipWhitelistSelected = !this.chipWhitelistSelected
        break
      }
      case 'blacklist': {
        this.chipBlacklistSelected = !this.chipBlacklistSelected
        break
      }
      case 'validation': {
        this.chipValidationSelected = !this.chipValidationSelected
        break
      }
      case 'notSupported': {
        this.chipNotSupportedSelected = !this.chipNotSupportedSelected
        break
      }
      default: {
        throw new Error(`Unexpected value for list: ${chipId}`)
      }
    }

    this._applyChipFilterToDisplayedRuleSets()
  }

  // Start of Context Menu Code (right click menu)
  get locationCss() {
    return {
      position: 'fixed',
      display: showRuleSetContextMenu ? 'block' : 'none',
      left: this.mouseLocation.left + 'px',
      top: this.mouseLocation.top + 'px',
    };
  }

  public actionRightClick(event: MouseEvent, ruleSetMnemonic: string) {
    console.log('right click')
    // console.info(`Right Click on '${ruleSetMnemonic}'`)
    event.preventDefault();
    event.stopPropagation()

    // If not a real normalised RuleSet then ignore this rightclick
    const ruleSetClickedOn = this.ruleSetsToDisplay.find(x => x.mnemonic === ruleSetMnemonic)
    const canEditThisRuleSet = !!ruleSetClickedOn && ruleSetClickedOn.filePath.startsWith('rulesets/')

    if (canEditThisRuleSet) {
      this.rightClickedRuleSetMnemonic = ruleSetMnemonic
      if (this.rightClickedRuleSetMnemonic !== undefined) {
        this.mouseLocation = { left: event.clientX, top: event.clientY - 35 }
        showRuleSetContextMenu = true
      }
    } else {
      // console.warn('Cannot Edit')
      hideContextMenu()
    }
  }

  public async actionViewRuleSetDialog(event: Event, action: string, ruleSetMnemonic: string, showRulesetRestore?: boolean): Promise<void> {
    this.loading = true;


    setTimeout(async () => {

      if (!!event) {
        event.preventDefault();
        event.stopPropagation()
      }
      hideContextMenu()

      if (ruleSetMnemonic === undefined) {
        ruleSetMnemonic = this.rightClickedRuleSetMnemonic
      }
      const normalisedRuleSet = this._ruleSetsAvailableToDisplay.find((x) => x.mnemonic === ruleSetMnemonic)
      const isNewComplexRuleset = event === null && normalisedRuleSet.ruleType === 'complex' ? true : false
      const complexRules = this._ruleSetsAvailableToDisplay.filter(rleset => rleset.ruleType === 'whitelist')
      const dialogData = {
        mode: action, // 'view' | 'edit' | 'create'
        normalisedRuleSet: normalisedRuleSet,
        username: this._username,
        dialog: this.dialog,
        changeService: this.changeService,
        toast: this.toastr,
        modalService: this.modalService,
        showRulesetRestore: showRulesetRestore,
        isNewComplexRuleSet: isNewComplexRuleset,
        complex: complexRules
      } as RuleSet2021ViewEditDialogComponentData

      // Open dialog and wait until it is closed
      const dataFromUserDialog = await this.dialog.open(RuleSet2021ViewEditDialogComponent, {
        width: '95vw',
        height: '95vh',
        panelClass: 'mat-dialog-without-padding',
        data: dialogData
        // hasBackdrop: true,
        // backdropClass: 'cdk-overlay-dark-backdrop',
        //TODO plugh Would be nice to disable behind the dialog
      }).afterOpened().toPromise().then(x => { this.loading = false; }).finally(() => {
        this.loading = false;
      })
      // Refresh the data (in case any were added or deleted). Takes 12ms when tested on a dev machine. So might was well always do this
      await this._getDataFromChangeService()
    }, 0);
  }



  public async actionAddNewRuleset(): Promise<void> {
    const dialogRef = this.dialog.open(Ruleset2021CreateNewDialogComponent, {
      width: '850px',
      data: {
        panelClass: 'dialogFormField500'
      }
    })

    const newRuleSetMnemonic = await dialogRef.afterClosed().toPromise()

    if (!!newRuleSetMnemonic) {
      await BasicHelper.delay(1000)
      // Refresh the data (in case any were added or deleted). Takes 12ms when tested on a dev machine. So might was well always do this
      await this._getDataFromChangeService()

      // Now send the user the Edit Screen
      this.actionViewRuleSetDialog(null, 'edit', newRuleSetMnemonic)
    }
  }

  public async actionDownloadRules(action: string) {
    const displayedNormalisedRules = this.ruleSetsToDisplay.filter(x => !!x.rule)

    switch (action) {
      case 'currentFilter':
        // Download all Rules
        const userActionDownloads = new UserActionDownloadCsvFiles(this.changeService)
        await userActionDownloads.downloadSelectedRules(displayedNormalisedRules)
        break;
      case 'allNewFormat':
        const userActionDownloadsNewFormat = new UserActionDownloadsNewFormat(this.dialog, this.changeService, this)
        await userActionDownloadsNewFormat.downloadAllRulesNewFormat()
        break;
    }
  }

  public async actionDownloadErrorMessages() {
    const userActionDownloadsErrors = new UserActionDownloadErrors(this.changeService)
    await userActionDownloadsErrors.downloadAllErrorMessages()
  }

  public async actionDownloadRuleSetAsCsv(event: Event, ruleSetMnemonic: string) {
    event.preventDefault();
    event.stopPropagation()
    const userActionDownloads = new UserActionDownloadCsvFiles(this.changeService)
    await userActionDownloads.downloadRuleSetAsCsv(ruleSetMnemonic)
  }

  public showDeletedRulesets() {
    this.showDeletedRuleSets = !this.showDeletedRuleSets
  }

  public async actionDownloadIgnoredMnemonicsAsCsv() {
    this.api.get<GetConfigFileSuccessResponse>(`/config/latest/lambda-approvals/config/ignored-mnemonics/ignored-mnemonics.json`)
      .subscribe((configResponse: GetConfigFileSuccessResponse) => {
        let ruleExclusion: RuleExclusion = {}
        
        // @ts-ignore
        if (configResponse.code !== 'FileDoesNotExistException') {
          ruleExclusion = JSON.parse(configResponse.data)
        }
        
        const userActionDownloads = new UserActionDownloadCsvFiles(this.changeService)
        userActionDownloads.downloadIgnoredMnemonicsAsCsv(ruleExclusion)
      })
  }

  showAuditHistory(event: Event, ruleSetMnemonic: string) {
    event.preventDefault();
    event.stopPropagation()
    this.router.navigate([]).then(result => { window.open(`config/rulesets/audit/${ruleSetMnemonic}`, '_blank') })
  }
}
