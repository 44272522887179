import { Injectable } from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class PreprocessorMappingService {

  isPreprocessorMapping = false
  showPreprocessorMappingConfigChange: Subject<boolean> = new Subject<boolean>();

  constructor() {
    this.showPreprocessorMappingConfigChange.subscribe((value) => {
        this.isPreprocessorMapping = value
    })
  }

  toggleShowPreprocessorMapping(show?: boolean) {
    if(show !== undefined) {
      this.showPreprocessorMappingConfigChange.next(show)
    } else {
      this.showPreprocessorMappingConfigChange.next(!this.isPreprocessorMapping)
    }
  }

  getIsPreprocessorMapping() {
    return this.isPreprocessorMapping
  }

}
