<div class="md-form  form-group"id="main-content" >
  <div class="inside-div">
  <mat-form-field class="test-suite-selection">
    <mat-label class="label">Test Suite</mat-label>
    <mat-select (selectionChange)="selectTestSuite($event)" [value]="selectedTestSuite" placeholder="Test Suite" #selectedTestSuite>
      <mat-option></mat-option>
      <mat-option *ngFor="let suite of testSuites" [value]="suite.name">{{suite.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button class="help" (click)="navigateToRegexHelp()">Help</button>
  <div>
    <mat-label class="prefix">Prefix: </mat-label>
    &nbsp;
    <input class="input-clone" value="CLONE-" #prefix >
    <mat-label class="suffix">suffix: </mat-label>
    &nbsp;
    <input class="input-suffix"value="-2021" #suffix>
  </div>
  <br><br>
  <div>
    <mat-label >Match Config: </mat-label>
    &nbsp;
    <input class="match-config" [(ngModel)]="givenRegex"(ngModelChange) ="valueOnChange()" placeholder="^(.*)2020(.*)$" #matchConfig>
  </div>
  <div>
    <br>
    <mat-label >Target Config: </mat-label> &nbsp;&nbsp;
    <input class="target-config" value="$12021$2" #targetConfig pattern="reg">
  </div>
  <br>
  <div>
    <mat-label >Select XPath and operation: </mat-label>
  </div>
  <br>
  <div  class="xpath-div"> 
    <input placeholder="Search existing XPath List"class="regex-pattern" [(ngModel)]="query" #addXpath pattern="#addXpath">
    <div *ngFor="let xpath of xpathsObj | search: 'xpath':query; let index as indexOfXpathObj">
      <ul>
        <li (ngModel)="xpath.xpath">
          <input type="text" [disabled]="xpath.is_edit" id="{{xpath.id}}" class="inside-input" value="{{xpath.xpath}}"
            #updatedXpathValue (change)="onChangeXpathVlue($event,xpath.xpath)"> 
          <br>
          <button class="edit xpath-btn" (click)="editXPathValueToggle(index.id,xpath.xpath)">
            {{xpath.toggleEdit}}</button> 
          <button class="edit xpath-btn" (click)="deleteXpathVlue(index.id,xpath.xpath)">Delete</button> 
          <mat-label title="Replace xpath value with Target Config if matches Match Config" class="operation">Replace: </mat-label>
          <input title="Replace xpath value with Target Config if matches Match Config" class="operation" value="replace"class="select-xpath"[checked]="xpath.replace" 
          (change)="updateOperation(xpath.xpath,index.id,replace)" 
          type="checkbox" #replace >
          <mat-label title="Increment xpath value date by 1 year" class="operation">Date Increment: </mat-label>
          <input title="Increment xpath value date by 1 year" class="operation" value="dateIncrement"class="select-xpath" [checked]="xpath.increment"
          (change)="updateOperation(xpath.xpath,index.id,dateIncrement)" 
          type="checkbox" #dateIncrement >
        </li>
      </ul>
    </div>
  </div>
  <div>
    <button class="add-xpath xpath-btn" (click)="openDialogToAddNewXpath()">Add New XPath</button>
  </div>
  <br> 
  <div class="checkbox">
    <mat-checkbox class="copy-tests" checked="copyOtherTests" (change)="isSelected()">
    Also copy tests which don't match
    </mat-checkbox>
  </div>
  <br>
  <div>
    <mat-form-field class="text-area" appearance="outline">
    <textarea
    matInput
    rows="6">{{summary}}
    </textarea>
    </mat-form-field>
    <div>
      <button (click)="summaryOfNewTestSuite()" class="summary btn">
        Get Summary
      </button>
      <button [disabled]="checkFieldsValid()"  (click)="cloneTestSuite()" class="clone-tests btn" disabled="allTestsInTestSuite"  >
      Clone Test Suite
      </button>
    </div>
  </div>
  </div>
</div>





