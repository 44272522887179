import {MatDialog} from '@angular/material/dialog'
import {ConfigSelectorDialogData} from 'src/app/dialogs/config-selector-dialog-data'
import {CreateWorkspaceDialogComponent} from 'src/app/dialogs/create-workspace-dialog.component'
import {ChangeService} from 'src/app/services/change.service'
import {AnalyseRow, AnalyseRuleTable} from '../../../common/domain/analyse'
import {ChangeDetails} from '../../../common/domain/change'
import {AnalyseConfig} from '../../../common/domain/config'
import {DocgenConfig} from '../../../common/domain/docgen-config'
import {AddAnalysisRowDetails, AnalysisAddRowDialogComponent} from '../../../dialogs/analysis-add-row-dialog.component'
import {FieldValue} from '../../../dialogs/analysis-list-add-edit-dialog.component'
import {NewConfigRulesComponent} from '../config-rules.component'
import {DisplayableRuleRow, DisplayableRuleTable} from './interfaces'

const lodash = require('lodash')
export class UserActionAddNewRule {

    private parentComponent: NewConfigRulesComponent
    private dialog: MatDialog
    private changeService: ChangeService

    constructor(
        dialog: MatDialog,
        changeService: ChangeService,
        parentControl: NewConfigRulesComponent,
    ) {
        this.dialog = dialog
        this.changeService = changeService
        this.parentComponent = parentControl;
    }

    /**
 * Add a new data rule to a ruleset, in one or more configs.
 *
 * @param ruleset the ruleset to which the new data rule will be added.
 */
    public addNewRule(ruleset: DisplayableRuleTable): void {

        const fields: FieldValue[] = []
            .concat({fieldName: 'description', fieldValue: undefined})
            .concat({fieldName: 'test', fieldValue: undefined})
            .concat(ruleset.fields.map((field: string) => {
                return {
                    fieldName: field,
                    fieldValue: undefined,
                }
            }),
            )

        const currentStageAndList = Object.values(ruleset.usedBy)[0] // there will always be an entry at index 0, at the very least
        const sourceList = currentStageAndList.ruleType
        const sourceStage = currentStageAndList.stage

        // === Dialog Open ===
        //  1st dialog to get expected values for the new rule
        const enterValuesDialog = this.dialog.open(AnalysisAddRowDialogComponent, {
            width: '750px',
            data: {
                title: `Enter Expected Values for New Rule`,
                ruleName: ruleset.name,
                ruleMnemonic: ruleset.mnemonic,
                fieldValues: fields,
                ruleType: sourceList,
                stage: sourceStage,
            } as AddAnalysisRowDetails,
            panelClass: 'dialogFormField500',
        })

        // When Dialog is Closed hand off to the next method
        enterValuesDialog.afterClosed()
            .subscribe(async (data: AddAnalysisRowDetails) => {
                if (data) {
                    this._processFirstDialogsData(ruleset, data)
                }
            })

    }

    private async _processFirstDialogsData(ruleset: DisplayableRuleTable, data: AddAnalysisRowDetails) {

        // TODO - CSDOC-1012 - Need to ensure we are not allowing it to be added where the mnemonic is already in use.
        const allConfigsThatAlreadyHaveThisRuleset: string[]
            = ruleset.configKeys.map((configKey: string) => `settings/${configKey}/Config.json`)

        const newRule = {
            mnemonic: 'TBD', // don't know what it will be, yet
            description: data.fieldValues.find((field: FieldValue) => field.fieldName === 'description').fieldValue || undefined,
            test: data.fieldValues.find((field: FieldValue) => field.fieldName === 'test').fieldValue || undefined,
            expected: data.fieldValues
                .filter((field: FieldValue) => field.fieldName !== 'description')
                .filter((field: FieldValue) => field.fieldName !== 'test')
                .map((field: FieldValue) => field.fieldValue || ''),
        } as AnalyseRow

        const allConfigsThatAlreadyHaveThisExactRule: string[]
            = this.getConfigPathsHavingTargetRule(allConfigsThatAlreadyHaveThisRuleset, newRule, ruleset, false)

        const configsToDisplay: ChangeDetails[] = this.parentComponent.allConfigs
            .filter((config: ChangeDetails) => !/^settings\/system\/.+$/.test(config.path))
            .filter((config: ChangeDetails) => allConfigsThatAlreadyHaveThisExactRule.indexOf(config.path) < 0)


        // Is this is a Rationalised Rule?
        const isProtectedRuleSetRule = this.parentComponent.protectedRuleSets.findIndex(x => x.mnemonic === ruleset.mnemonic) > -1

        // Dialog Route for Non Rationalised Rules
        if (!isProtectedRuleSetRule) {

            // === Dialog Open ===
            //  2nd dialog to get products that apply
            //   *Skipped* for a Rationalised Rule
            // TODO - CSDOC-1012 - Can we replace this with the config filter component in a new dialog?
            const configSelectorDialog = this.dialog.open(CreateWorkspaceDialogComponent, {
                width: '500px',
                data: {
                    title: `Select configuration(s) to add rule to:`,
                    configItems: configsToDisplay,
                    initialSelections: this.parentComponent.getSelectedConfigAsInitialSelection(),
                } as ConfigSelectorDialogData,
                panelClass: 'dialogFormField500',
            })

            // When Dialog is Closed hand off to the next method
            configSelectorDialog.afterClosed()
                .subscribe(async (selectedConfigs: string[]) => {
                    if (selectedConfigs && selectedConfigs.length > 0) {
                        // console.debug("selectedConfigs: " + selectedConfigs.join(','))
                        this._processSecondDialogsData(
                            data, selectedConfigs, ruleset, newRule, allConfigsThatAlreadyHaveThisRuleset, allConfigsThatAlreadyHaveThisExactRule
                        )
                    }
                })

        }

        // Just process Rationalised Rules (we already know products/documents it needs applying to)
        if (isProtectedRuleSetRule) {
            const rationalisedRule = this.parentComponent.protectedRuleSets.find(x => x.mnemonic === ruleset.mnemonic)
            // Add 'settings/' to start of each path and '/Config.json' to end
            const selectedConfigs = rationalisedRule.entries.map(x => 'settings/' + x + '/Config.json')
            // const selectedConfigs = rationalisedRule.entries

            this._processSecondDialogsData(
                data, selectedConfigs, ruleset, newRule, allConfigsThatAlreadyHaveThisRuleset, allConfigsThatAlreadyHaveThisExactRule)
        }

    }

    private async _processSecondDialogsData(
        data: AddAnalysisRowDetails,
        selectedConfigs,
        ruleset: DisplayableRuleTable,
        newRule: AnalyseRow,
        allConfigsThatAlreadyHaveThisRuleset: string[],
        allConfigsThatAlreadyHaveThisExactRule: string[]) {

        const selectedConfigsThatAlreadyHaveThisRuleset: string[]
            = selectedConfigs.filter((configPath: string) => allConfigsThatAlreadyHaveThisRuleset.indexOf(configPath) > -1)

        const selectedConfigsThatAlreadyHaveThisExactRule: string[]
            = this.getConfigPathsHavingTargetRule(selectedConfigsThatAlreadyHaveThisRuleset, newRule, ruleset, false)

        const selectedConfigsNeedingThisRuleAdded: string[]
            = selectedConfigs.filter((configPath: string) => selectedConfigsThatAlreadyHaveThisExactRule.indexOf(configPath) < 0)

        const allExistingMnemonicsOfThisRule: {configPath: string; ruleMnemonics: string[]}[]
            = this.getRuleMnemonics(allConfigsThatAlreadyHaveThisExactRule, newRule, ruleset)

        let newRuleMnemonic: string

        // Is there an existing rule mnemonic that can be re-used by every selected config needing this rule to be added?
        allExistingMnemonicsOfThisRule
            .map((existingUsages: {configPath: string; ruleMnemonics: string[]}) => {
                // Do not allow a rule mnemonic with no active usages to be re-used
                const ruleMnemonicsWithNoActiveUsages: string[] = ruleset.rule
                    .filter((rule: DisplayableRuleRow) => this.parentComponent.ruleHasNoActiveUsages(rule))
                    .map((rule: DisplayableRuleRow) => rule.mnemonic)

                const allCandidateMenemonics: string[] = lodash.intersectionWith(existingUsages.ruleMnemonics, ruleMnemonicsWithNoActiveUsages, lodash.isEqual).length === 0
                    ? existingUsages.ruleMnemonics
                    : existingUsages.ruleMnemonics.filter(value => ruleMnemonicsWithNoActiveUsages.indexOf(value) < 0)

                return {
                    configPath: existingUsages.configPath,
                    ruleMnemonics: allCandidateMenemonics,
                }
            })
            .map((existingUsages: {configPath: string; ruleMnemonics: string[]}) => existingUsages.ruleMnemonics)
            .reduce((acc: string[], curr: string[]) => {
                return acc.concat(curr)
            }, [] as string[])
            .forEach((candidateMnemonic: string) => {
                const isMnemonicUsableByAll = selectedConfigsNeedingThisRuleAdded.every((configPath: string) => {
                    const allMatchingRulesets = this.getAllMatchingRulesets([configPath], ruleset, false)
                    const ruleMnemonicsInUse = allMatchingRulesets
                        .map((curRuleset: AnalyseRuleTable) => curRuleset.rule)
                        .reduce((acc: AnalyseRow[], curr: AnalyseRow[]) => {
                            return acc.concat(curr)
                        }, [] as AnalyseRow[])
                        .map((rule: AnalyseRow) => rule.mnemonic)
                    return ruleMnemonicsInUse.indexOf(candidateMnemonic) < 0
                })

                if (newRuleMnemonic === undefined && isMnemonicUsableByAll) {
                    newRuleMnemonic = candidateMnemonic
                }
            })

        // If not, the next globally-unique mnemonic for this ruleset will be used instead.
        if (newRuleMnemonic === undefined) {
            const highestRuleMnemonicCurrentlyInUse: number = this.getAllMatchingRulesets(allConfigsThatAlreadyHaveThisRuleset, ruleset)
                .reduce((highest: number, curr: AnalyseRuleTable) => {
                    const mnemonicsSortedDesc: number[] = curr.rule.map((r: AnalyseRow) => Number(r.mnemonic)).sort((a, b) => b - a)
                    if ((mnemonicsSortedDesc && mnemonicsSortedDesc.length > 0) && ((!highest) || (highest && mnemonicsSortedDesc[0] > highest))) {
                        return mnemonicsSortedDesc[0]
                    } else {
                        return highest
                    }
                }, undefined)

            newRuleMnemonic = highestRuleMnemonicCurrentlyInUse !== undefined ? this.incrementAndGetMnemonic(highestRuleMnemonicCurrentlyInUse) : '0000'
        }

        // Finally...
        newRule.mnemonic = newRuleMnemonic

        // Tweak for Rationalised Rules
        // const isProtectedRuleSetRule = this.parentComponent.protectedRules.findIndex(x => x.mnemonic == ruleset.mnemonic) > -1

        selectedConfigsNeedingThisRuleAdded.forEach((configPath: string) => {
            const targetList = data.ruleType
            const targetStage = data.stage
            // Locate config. Remove settings (if it's on the path)
            // const configObject = this.changeService.getObject(configPath.replace('settings/', ''))
            const configObject = this.changeService.getObject(configPath)
            const config = JSON.parse(configObject.content) as DocgenConfig

            const analyseConfig: AnalyseConfig = (targetStage === 'product') ? config.analysePayload : config.analyseEnriched
            const listOfRules = analyseConfig.rules
            const existingRuleset = this.parentComponent.findRulesetInList(listOfRules.filter(r => r.ruleType === targetList), ruleset)

            if (existingRuleset) {
                existingRuleset.rule.push(newRule)
            } else {
                const newRuleset = {
                    ruleType: ruleset.ruleType,
                    name: ruleset.name,
                    mnemonic: ruleset.mnemonic,
                    description: ruleset.description,
                    fields: ruleset.fields,
                    rule: [newRule],
                } as AnalyseRuleTable

                listOfRules.push(newRuleset)
            }
            // plugh Change to use upsert?
            this.parentComponent.saveChangedConfig(configObject.name, configObject.path, config)
        })
    }


    /**
     * Filter the supplied config paths to include only those that already have the target rule (in any list).
     *
     * @param configPaths the configs to check.
     * @param targetRule the rule to be matched - data values are checked, not mnemonic.
     * @param ruleset the ruleset containing the rule.
     * @param includeDeletedRules optional flag which defaults to true but, if set to false, will exclude deleted rules from the response.
     */
    private getConfigPathsHavingTargetRule(configPaths: string[], targetRule: AnalyseRow, ruleset: DisplayableRuleTable, includeDeletedRules: boolean = true): string[] {
        if (includeDeletedRules) {
            return configPaths.filter((configPath: string) => {
                const matchingRules = this.getMatchingRules(configPath, targetRule, ruleset)
                return matchingRules.length > 0
            })
        } else {
            return configPaths.filter((configPath: string) => {
                const matchingRules = this.getMatchingRules(configPath, targetRule, ruleset)
                const matchingRulesExcludingDeleted = matchingRules.filter((rule: AnalyseRow) => !rule.deletion)
                return matchingRulesExcludingDeleted.length > 0
            })
        }
    }

    private incrementAndGetMnemonic(providedNumber: number): string {
        return String(providedNumber + 1).padStart(4, '0')
    }

    private getRuleMnemonics(configPaths: string[], targetRule: AnalyseRow, ruleset: DisplayableRuleTable): {configPath: string; ruleMnemonics: string[]}[] {
        return configPaths.map((providedConfigPath: string) => {
            const matchingRules = this.getMatchingRules(providedConfigPath, targetRule, ruleset)
            return {
                configPath: providedConfigPath,
                ruleMnemonics: matchingRules.map((r: AnalyseRow) => r.mnemonic),
            }
        })
    }

    private getMatchingRules(configPath: string, targetRule: AnalyseRow, ruleset: DisplayableRuleTable): AnalyseRow[] {
        const rulesets: AnalyseRuleTable[] = this.getAllMatchingRulesets([configPath], ruleset, false)
        const rules: AnalyseRow[] = rulesets
            .map((curRuleset: AnalyseRuleTable) => curRuleset.rule)
            .reduce((acc: AnalyseRow[], curr: any) => {
                return acc.concat(curr)
            }, [] as AnalyseRow[])
        return rules.filter((r: AnalyseRow) => {
            return r.expected.length === targetRule.expected.length
                && r.expected.every((value, index) => value === targetRule.expected[index])
        })
    }

    private getAllMatchingRulesets(configPaths: string[], rule: DisplayableRuleTable, matchRuleMnemonic: boolean = true): AnalyseRuleTable[] {
        return configPaths
            .map((path: string) => {
                const configObject = this.changeService.getObject(path)
                const config = JSON.parse(configObject.content) as DocgenConfig

                const productAnalyseConfig: AnalyseConfig = config.analysePayload
                const productRulesets = this.getMatchingRulesetsInList(productAnalyseConfig.rules.filter(r => r.ruleType === rule.ruleType), rule, matchRuleMnemonic)

                const soloAnalyseConfig: AnalyseConfig = config.analyseEnriched
                const soloRulesets = this.getMatchingRulesetsInList(soloAnalyseConfig.rules.filter(r => r.ruleType === rule.ruleType), rule, matchRuleMnemonic)

                return []
                    .concat(productRulesets)
                    .concat(soloRulesets)
            })
            .reduce((acc: AnalyseRuleTable[], curr: any) => {
                return acc.concat(curr)
            }, [])
    }

    private getMatchingRulesetsInList(list: AnalyseRuleTable[], rule: DisplayableRuleTable, matchRuleMnemonic: boolean = true): AnalyseRuleTable[] {
        return list.filter((r: AnalyseRuleTable) => {
            return matchRuleMnemonic ?
                (r.name === rule.name
                    && r.mnemonic === rule.mnemonic
                    && r.fields.length === rule.fields.length
                    && r.fields.every((f: string, index: number) => f === rule.fields[index])) :

                (r.name === rule.name
                    && r.fields.length === rule.fields.length
                    && r.fields.every((f: string, index: number) => f === rule.fields[index]))
        })
    }

}
