import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {ConfigDetail} from '../common/domain/config'
import {ChangeService} from '../services/change.service'
import {Subscription} from 'rxjs'
import {MatSelectChange} from '@angular/material/select'

@Component({
  selector: 'app-transform-selector',
  templateUrl: './transform-selector.component.html',
  styleUrls: ['./transform-selector.component.scss'],
})
export class TransformSelectorComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  transforms: ConfigDetail[]
  selected: ConfigDetail
  @Output() transform = new EventEmitter<ConfigDetail>()

  constructor(private changeService: ChangeService) {
    this.transforms = []
  }

  ngOnInit() {
    this.sub.add(
      this.changeService.getObjectsOfType('transforms').subscribe(transforms => {
        this.transforms = transforms
      }),
    )
    this.sub.add(
      this.transform.subscribe(item => {
        this.selected = item
      }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  selectTransform(event: MatSelectChange) {
    this.transform.emit(event.value)
  }
}
