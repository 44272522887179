<app-config-workspace-buttons></app-config-workspace-buttons>

<h3>Samples Screen</h3>

<button type="button" class="btn btn-info" (click)="add()">Add New {{typeSingular | titlecase}}</button>

<table class="table table-borderless">
  <thead>
  <tr>
    <!-- <th>Service</th> -->
    <th>Name</th>
    <th>Configs</th>
    <th>State</th>
    <th width="250px">Actions</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let config of changeDetails" [ngClass]="isReferencedByConfig(config) ? 'referenced' : isAvailableToConfig(config) ? '' : 'unreferenced'">
    <!-- <td>{{config.service}}</td> -->
    <td>{{config.name}}</td>
    <td>
      <ul>
        <li *ngFor="let config of usageMap[config.name]">{{config}}</li>
      </ul>
    </td>
    <td>{{getDisplayState(config)}}</td>
    <!-- <td>{{config.state}}</td> -->
    <td>
      <button type="button" class="btn btn-primary me-2"  (click)="edit(config)" [disabled]="config.state === 'toDelete'">Edit
      </button>
      <button type="button" class="btn btn-secondary me-2"  (click)="restore(config)"
              [disabled]="config.state === 'original'">Restore
      </button>
      <button type="button" class="btn btn-danger"  (click)="delete(config)"
              [disabled]="config.state === 'new' || config.state === 'toDelete'">Delete
      </button>
    </td>
  </tr>
  <tr *ngFor="let template of missingTemplates"> <!-- </tr> [ngClass]="isReferencedByConfig(config) ? 'referenced' : isAvailableToConfig(config) ? '' : 'unreferenced'"> -->
    <!-- <td></td> -->
    <td>{{template.name}}</td>
    <td>
      <ul>
        <li *ngFor="let config of usageMap[template.name]">{{config}}</li>
      </ul>
    </td>
    <td>{{getDisplayState(template)}}</td>
    <td>
      <button type="button" class="btn btn-primary"  (click)="edit(template)">Add</button>
    </td>
  </tr>
</tbody>
</table>
