import {Component, EventEmitter, OnDestroy, OnInit} from '@angular/core'
import {StatusService} from '../../services/status.service'
import {ChangeService} from '../../services/change.service'
import {ConfigDetail} from '../../common/domain/config'
import {ChangeDetails} from '../../common/domain/change'
import {ToastrService} from 'ngx-toastr'
import {TemplateVersion} from '../../services/config.service'
import {takeUntil} from 'rxjs/operators'
import {ENVIRONMENTS} from '../../services/environment-service';

const base64js = require('base64-js')

@Component({
  selector: 'app-config-templates',
  templateUrl: './config-templates.component.html',
  styleUrls: ['./config-templates.component.scss'],
})
export class ConfigTemplatesComponent implements OnInit, OnDestroy {
  $destroy = new EventEmitter()

  templates: ChangeDetails[]
  templateVersions: TemplateVersion[]
  environments = [
    ENVIRONMENTS.STAGING,
    ENVIRONMENTS.UAT,
    ENVIRONMENTS.PROD,
  ]

  constructor(
    private statusService: StatusService,
    private toast: ToastrService,
    private changeService: ChangeService
  ) {
  }

  ngOnInit(): void {

    const loadingTemplateStepId = this.statusService.start('Loading templates')

    this.changeService.getObjectsOfType('templates')
      .pipe(
        takeUntil(this.$destroy),
      )
      .subscribe(
        (templates: ChangeDetails[]) => {
          this.templates = templates
          if (this.templates && this.templates.length > 0) {
            this.statusService.complete(loadingTemplateStepId)
          }
        },
        () => {
          this.statusService.complete(loadingTemplateStepId)
          this.toast.error('Failed to load templates')
        }
      )
  }

  ngOnDestroy(): void {
    this.$destroy.emit()
  }

  uploadTemplate(event: Event) {
    // @ts-ignore
    if (event.target.files && event.target.files.length) {
      // @ts-ignore
      const [file] = event.target.files

      if (!file.name.endsWith('.zip')) {
        this.toast.error('Uploaded file must be a .zip file...')
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        new Response(file).arrayBuffer()
          .then(buffer => {
            const bytes = new Uint8Array(buffer)
            const content = base64js.fromByteArray(bytes)
            this.addTemplateChange(file.name, content)
          })
      }
    }
  }

  private addTemplateChange(fileName: string, content) {
    this.changeService.addChange({
      source: 'auto-deploy-templates',
      name: fileName,
      path: `templates/${fileName}`,
      size: undefined,
      content: content,
    } as ConfigDetail)
  }

  delete(template: ChangeDetails) {
    this.changeService.deleteObject(template)
  }

  getDisplayState(config: ChangeDetails): string {
    switch (config.state) {
      case 'original': {
        return ''
      }
      case 'modified': {
        return 'MODIFIED'
      }
      case 'new': {
        return 'NEW'
      }
      case 'toDelete': {
        return 'TO BE DELETED'
      }
      default: {
        return '?'
      }
    }
  }

  restore(config: ChangeDetails) {
    this.changeService.revertChange(config)
  }

}
