<h2 mat-dialog-title>{{data.title}}</h2>
<h4 mat-dialog-title>{{data.notes}}</h4>
<p></p>
<div mat-dialog-content>

  <form class="example-form">
    <mat-form-field class="example-full-width">
      <h5>Source System</h5>
      <input type="text"
             matInput
             [(ngModel)]="sourceSystem"
             [formControl]="myControl"
             [matAutocomplete]="autoSourceSystem">
      <mat-autocomplete #autoSourceSystem="matAutocomplete">
        <mat-option *ngFor="let sourceSystem of data.sourceSystems" [value]="sourceSystem">{{sourceSystem}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <h5>Programme</h5>
      <input type="text"
             matInput
             [(ngModel)]="programme"
             [formControl]="myControl"
             [matAutocomplete]="autoProgramme">
      <mat-autocomplete #autoProgramme="matAutocomplete">
        <mat-option *ngFor="let programme of data.programmes" [value]="programme">{{programme}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <h5>Product Type</h5>
      <input type="text"
             matInput
             [(ngModel)]="productType"
             [formControl]="myControl"
             [matAutocomplete]="autoProductType">
      <mat-autocomplete #autoProductType="matAutocomplete">
        <mat-option *ngFor="let productType of data.productTypes" [value]="productType">{{productType}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <h5>Document Type</h5>
      <input type="text"
             matInput
             [(ngModel)]="documentType"
             [formControl]="myControl"
             [matAutocomplete]="autoDocumentType">
      <mat-autocomplete #autoDocumentType="matAutocomplete">
        <mat-option *ngFor="let documentType of data.documentTypes" [value]="documentType">{{documentType}}</mat-option>
      </mat-autocomplete>
    </mat-form-field>

  </form>

</div>

<div mat-dialog-actions>
  <button (click)="cancel()" mat-raised-button color="secondary">Cancel</button>
  <button (click)="ok()" mat-raised-button color="primary">Create</button>
</div>
