<span (contextmenu)="actionRightClick($event, undefined)">

  <app-config-workspace-buttons></app-config-workspace-buttons>

  <h2>Analysis Rules</h2>

  <ng-container>
    <mat-chip-list selectable multiple class="list-view">
      <mat-chip color="primary" [selected]="productChipSelected" (click)="toggleChip('product')">
        Product
      </mat-chip>
      <mat-chip color="primary" [selected]="soloChipSelected" (click)="toggleChip('solo')">
        Solo
      </mat-chip>
      <mat-chip color="primary" [selected]="notSupportedChipSelected" (click)="toggleChip('notSupported')">
        Not-Supported List
      </mat-chip>
      <mat-chip color="primary" [selected]="validationChipSelected" (click)="toggleChip('validation')">
        Validation
        List
      </mat-chip>
      <mat-chip color="primary" [selected]="blacklistChipSelected" (click)="toggleChip('blacklist')">
        Blacklist
      </mat-chip>
      <mat-chip color="primary" [selected]="whitelistChipSelected" (click)="toggleChip('whitelist')">
        Whitelist
      </mat-chip>
    </mat-chip-list>
  </ng-container>

  <ng-container>
    <br>
    <button type="button" class="btn btn-success" (click)="actionAddNewRuleset()" *ngIf="this.canAddRule">Add New
      Ruleset</button>
    <ng-container *ngIf="displayConfigKey !== undefined">
      <button type="button" class="btn btn-info" (click)="actionDownloadRulesForSelectedConfig()"
        title="Download rules for {{displayConfigKey}}">
        Rules for Selected Config (CSV)
      </button>
    </ng-container>
    <button type="button" class="btn btn-info" (click)="actionDownloadAllRules()" title="Download all rules">
      All Rules (CSV)
    </button>
    <button type="button" class="btn btn-info" (click)="actionDownloadSelectedRules()"
      title="Download selected rules">Selected Rules (CSV)
    </button>
    <button type="button" class="btn btn-info" (click)="actionDownloadAllRulesNewFormat()"
      title="Download all rules (New Format)">All Rules (New Format)
    </button>
    <button type="button" class="btn btn-info" (click)="actionDownloadAllErrorMessages()"
      title="Download all messages">
      All Errors (CSV)
    </button>
    <button type="button" class="btn btn-warning" (click)="toggleShowDeletions()" *ngIf="canDeleteRule">{{showDeletions
      ? 'Hide' : 'Show'}}
      Deletions
    </button>
    <button type="button" class="btn btn-danger" *ngIf="canDeleteRule"
      (click)="toggleDeleteActions()">{{showDeleteActions ? 'Disable' : 'Enable'}} Deleting
    </button>
  </ng-container>

  <mat-tab-group>
    <mat-tab label="Rules">
      <mat-accordion *ngIf="displayedRules.length > 0" hideToggle="true">
        <ng-container *ngFor="let rule of displayedRules; trackBy: trackByFunction">

          <mat-expansion-panel *ngIf="showDeletions || !rulesetHasNoActiveUsages(rule)" [expanded]="isOpened(rule)"
            (opened)="openRule(rule)" (closed)="closeRule(rule)" [disabled]=!rule.isProtectedRuleSet>

            <mat-expansion-panel-header (contextmenu)="actionRightClick($event, rule)">

              <mat-panel-title>
                <div class="container-fluid">
                  <div class="row">

                    <div class="col-lg-1">
                      {{rule.mnemonic}}
                    </div>
                    <div class="col-lg-5"
                      [title]="rule.isProtectedRuleSet ? rule.protectedRule.entries.join('\n') : displayConfigKeysForRuleset(rule)">
                      {{rule.name}}
                    </div>
                    <div class="col-lg-1">
                      <ng-container *ngIf="isRuleInStage(rule, 'product')">
                        <fa-icon icon="sign-in-alt" title="Product Rule"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="isRuleInStage(rule, 'solo')">
                        <fa-icon icon="sign-out-alt" title="Solo Rule"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="isRuleInList(rule, 'whitelist')">
                        <fa-icon icon="list-alt" title="Whitelist"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="isRuleInList(rule, 'blacklist')">
                        <fa-icon icon="ban" title="Blacklist"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="isRuleInList(rule, 'validation')">
                        <fa-icon icon="check-circle" title="Validation"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="isRuleInList(rule, 'notSupported')">
                        <fa-icon icon="calendar-times" title="Not Supported Yet"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="rule.hasDuplicateMnemonics">
                        <fa-icon [styles]="{'stroke': 'red', 'color': 'red'}" icon="exclamation-triangle"
                          title="Duplicate Mnemonic"></fa-icon>
                      </ng-container>
                      <ng-container *ngIf="rule.hasDuplicateData">
                        <fa-icon [styles]="{'stroke': 'red', 'color': 'red'}" icon="exclamation" title="Duplicate Data">
                        </fa-icon>
                      </ng-container>
                    </div>
                    <div class="col-lg-2">
                      <div class="badge badge-primary" *ngIf="rule.modified">Modified</div>
                      <div class="badge badge-info" *ngIf="rule.new">New</div>
                      <div class="badge badge-danger" *ngIf="displayRulesetDeletion(rule)"
                        [matTooltip]="getRulesetDeletions(rule)" matTooltipPosition="after"
                        matTooltipClass="rules-tooltip" (click)="showRulesetDeletionsAsDialog(rule)">
                        Deletions
                      </div>
                    </div>
                    <div class="col-lg-1">
                      <button type="button" class="btn btn-sm btn-info" (click)="actionDownloadRulesAsCsv(rule)">CSV
                      </button>
                      <ng-container *ngIf="isOpened(rule)">
                        <button type="button" class="delete-ruleset btn btn-sm btn-danger"
                          (click)="actionDeleteRuleSet(rule)" *ngIf="canDeleteRule && showDeleteActions"
                          [disabled]="!canDeleteRule || rulesetHasNoActiveUsages(rule)">Delete Ruleset
                        </button>
                        <!--
                                  // TODO - CSDOC-1012 - This is where we copy an existing rule definition to other configs
                          -->
                        <!-- <button type="button" class="btn btn-sm btn-info" *ngIf="canAddRule && !rule.isProtectedRuleSet && !rulesetHasNoActiveUsages(rule)"
                                  (click)="copyRuleset(rule)">Copy
                          </button> -->
                      </ng-container>
                    </div>
                    <div class="col-lg-2">
                      <span *ngFor="let env of allEnvs()">
                        <span *ngIf="ruleIsExcluded(rule.mnemonic, false, env.id)">
                          <fa-icon icon="ban" title="Ignored in {{env.display | titlecase}}"></fa-icon>
                          {{env.display | titlecase}}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </mat-panel-title>

            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent (contextmenu)="actionRightClick($event, undefined)">
              <div align="right">
                <ng-container *ngIf="canIgnoreRule">
                  Ignore ruleset in:
                  <span *ngFor="let env of allEnvs()">
                    {{ env.display | titlecase }}
                    <mat-slide-toggle [checked]="ruleIsExcluded(rule.mnemonic, true, env.id)"
                      (change)="actionToggleIgnoreInEnv($event.checked,  rule.mnemonic, env.id)">

                    </mat-slide-toggle>
                  </span>
                </ng-container>
              </div>
              <table class="table table-responsive table-borderless text-nowrap">
                <thead>
                  <tr>
                    <th>
                      <ng-container *ngIf="rule.ruleType !== 'validation'">
                        <fa-icon icon="car-crash"
                          (click)="setErrorMessage(rule.ruleType, rule.mnemonic, undefined, rule.description, rule.help)">
                        </fa-icon>
                      </ng-container>
                    </th>
                    <th>code</th>
                    <th>description</th>
                    <th *ngFor="let field of rule.fields" align="left">{{field}}</th>
                    <th>help</th>
                    <th>test</th>
                    <th>actions</th>
                    <th>ignore rule in</th>
                  </tr>
                </thead>

                <tbody>

                  <tr *ngFor="let row of rule.rule">
                    <ng-container *ngIf="showDeletions || !ruleHasNoActiveUsages(row)">
                      <td>
                        <ng-container *ngIf="rule.ruleType !== 'whitelist' && rule.ruleType !== 'validation'">
                          <fa-icon icon="car-crash"
                            (click)="setErrorMessage(rule.ruleType, rule.mnemonic, row.mnemonic, row.description, row.help)">
                          </fa-icon>
                        </ng-container>

                        <!--                   <ng-container *ngIf="row.status.isDuplicateMnemonic">
                                            <fa-icon [styles]="{'stroke': 'red', 'color': 'red'}" icon="exclamation-triangle"
                                                     title="Duplicate Mnemonic"
                                                     (mouseover)="highlightDupCodes(row, rule)"
                                                     (mouseleave)="stopHighlightDupCodes(row, rule)"
                                            ></fa-icon>
                                          </ng-container>
                                          <ng-container *ngIf="row.status.isDuplicatedData">
                                            <fa-icon [styles]="{'stroke': 'red', 'color': 'red'}" icon="exclamation"
                                                     title="Duplicate Data"
                                                     (mouseover)="highlightDupData(row, rule)"
                                                     (mouseleave)="stopHighlightDupData(row, rule)"
                                            ></fa-icon>
                                          </ng-container>
                         -->
                      </td>
                      <td [className]="ruleHasNoActiveUsages(row) ? 'no-active-usages' : isHighlighted(row)
                  || isCodeHighlighted(row) ? 'highlight' : 'not-highlight'">{{rule.mnemonic}}-{{row.mnemonic}}
                      </td>
                      <td [className]="ruleHasNoActiveUsages(row) ? 'no-active-usages' : ''">
                        <div *ngFor="let description of getDescriptionsForRule(row)">{{description}}</div>
                      </td>
                      <td *ngFor="let cell of row.expected"
                        [className]="ruleHasNoActiveUsages(row) ? 'no-active-usages' : isHighlighted(row) ? 'data-highlighted' : isRowInConfig(row) ? 'row-in-config' : 'row-not-in-config'"
                        [matTooltip]='!rule.isProtectedRuleSet ? this.displayConfigKeysForRow(row): "Protected RuleSet"'
                        matTooltipPosition="after" matTooltipClass="rules-tooltip">{{cell}}
                      </td>
                      <td [className]="ruleHasNoActiveUsages(row) ? 'no-active-usages' : ''">
                        <div *ngFor="let help of getHelpForRule(row)">{{help}}</div>
                      </td>
                      <td [className]="ruleHasNoActiveUsages(row) ? 'no-active-usages' : ''">
                        <div *ngFor="let test of getTestsForRule(row)">{{test}}</div>
                      </td>
                      <td [className]="ruleHasNoActiveUsages(row) ? 'no-active-usages' : ''">
                        <button mat-raised-button color="warn" class="delete-row" (click)="actionDeleteRule(row, rule)"
                          *ngIf="canDeleteRule && showDeleteActions"
                          [disabled]="!canDeleteRule || ruleHasNoActiveUsages(row)">Delete Rule
                        </button>
                        <!--                  <mat-chip selected color="warn" *ngIf="row.status.isDuplicateMnemonic"-->
                        <!--                            (mouseover)="highlightDupCodes(row, rule)" (mouseleave)="stopHighlightDupCodes(row, rule)">-->
                        <!--                    DUPLICATE CODE-->
                        <!--                  </mat-chip>-->
                        <!--                  <mat-chip selected color="accent" *ngIf="row.status.isDuplicatedData"-->
                        <!--                            (mouseover)="highlightDupData(row, rule)"-->
                        <!--                            (mouseleave)="stopHighlightDupData(row, rule)">DUPLICATE DATA-->
                        <!--                  </mat-chip>-->
                        <button class="deleted-rule" *ngIf="displayRuleDeletion(row)"
                          [matTooltip]="getRuleDeletions(row)" matTooltipPosition="after"
                          matTooltipClass="rules-tooltip" (click)="showRuleDeletionsAsDialog(row)">DELETIONS
                        </button>
                      </td>
                      <td>
                        <ng-container *ngIf="canIgnoreRule">
                          <span *ngFor="let env of allEnvs()">
                            {{ env.display | titlecase }}
                            <mat-slide-toggle
                              [checked]="ruleIsExcluded(rule.mnemonic + '-'+ row.mnemonic, true, env.id)"
                              (change)="actionToggleIgnoreInEnv($event.checked,  rule.mnemonic + '-'+ row.mnemonic, env.id)">
                            </mat-slide-toggle>
                          </span>
                        </ng-container>
                      </td>
                    </ng-container>
                  </tr>
                  <tr>
                    <td colspan="7">
                      <!--
                              // TODO - CSDOC-1012 - This is where we add a new rule to multiple configs
                      -->
                      <button mat-raised-button color="primary" *ngIf="!rulesetHasNoActiveUsages(rule)"
                        (click)="actionAddNewRule(rule)">Add New Rule
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>

          </mat-expansion-panel>
        </ng-container>
      </mat-accordion>
    </mat-tab>

    <mat-tab label="Fields">
      <mat-accordion *ngIf="displayedRules.length > 0" hideToggle="true">
        <mat-expansion-panel *ngFor="let rule of this.displayedRules; trackBy: trackByFunction"
          [expanded]="isOpened(rule)" (opened)="openRule(rule)" (closed)="closeRule(rule)">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <div class="container-fluid">
                <div class="row">

                  <div class="col-lg-1">
                    {{rule.mnemonic}}
                  </div>
                  <div class="col-lg-5" [title]="displayConfigKeysForRuleset(rule)">
                    {{rule.name}}
                  </div>
                  <div class="col-lg-2">
                    <ng-container *ngIf="isRuleInStage(rule, 'product')">
                      <fa-icon icon="sign-in-alt" title="Product Rule"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="isRuleInStage(rule, 'solo')">
                      <fa-icon icon="sign-out-alt" title="Solo Rule"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="isRuleInList(rule, 'whitelist')">
                      <fa-icon icon="list-alt" title="Whitelist"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="isRuleInList(rule, 'blacklist')">
                      <fa-icon icon="ban" title="Blacklist"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="isRuleInList(rule, 'validation')">
                      <fa-icon icon="check-circle" title="Validation"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="isRuleInList(rule, 'notSupported')">
                      <fa-icon icon="calendar-times" title="Not Supported Yet"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="rule.hasDuplicateMnemonics">
                      <fa-icon icon="exclamation-triangle" title="Duplicate Mnemonic"></fa-icon>
                    </ng-container>
                    <ng-container *ngIf="rule.hasDuplicateData">
                      <fa-icon icon="exclamation" title="Duplicate Data"></fa-icon>
                    </ng-container>
                  </div>
                  <div class="col-lg-2">
                    <div class="badge badge-primary" *ngIf="rule.modified">Modified</div>
                    <div class="badge badge-info" *ngIf="rule.new">New</div>
                    <div class="badge badge-danger" *ngIf="displayRulesetDeletion(rule)"
                      [matTooltip]="getRulesetDeletions(rule)" matTooltipPosition="after"
                      matTooltipClass="rules-tooltip" (click)="showRulesetDeletionsAsDialog(rule)">
                      Deletions
                    </div>
                  </div>
                  <div class="col-lg-2" *ngIf="isOpened(rule)">
                    <button type="button" class="delete-ruleset btn btn-sm btn-danger"
                      (click)="actionDeleteRuleSet(rule)" *ngIf="canDeleteRule && showDeleteActions"
                      [disabled]="!canDeleteRule || rulesetHasNoActiveUsages(rule)">Delete
                      Ruleset
                    </button>
                    <!-- <button type="button" class="btn btn-sm btn-info" *ngIf="this.canAddRule && !rulesetHasNoActiveUsages(rule)"
                              (click)="copyRuleset(rule)">Copy
                      </button> -->
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent>
            <table>
              <thead>
                <tr>
                  <td>#</td>
                  <td>xpath</td>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let field of rule.fields; let index = index;">
                  <td class="rule-field">{{index}}</td>
                  <td>{{field}}</td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-tab>

    <!--   <mat-tab label="Protected Rulesets">
    
        <ng-container>
    
          <table class="table table-hover tableDownloadProtectedRules">
            <thead>
              <tr>
                <th scope="col">Mnemonic</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                <button type="button" class="btn btn-info" (click)="actionDownloadCsvForAllRationlisedRules()"
                title="Download all Protected Rules">CSV for All</button>
              </td>
    
              </tr>
              <tr *ngFor='let curProtectedRule of ProtectedRules; let index = index;' [title]="curProtectedRule.entries.join('\n')">
                <td >
                  <button type="button" class="btn btn-info" (click)="actionDownloadCsvForRationlisedRule(curProtectedRule)"
                  title="Download rules for {{curProtectedRule.mnemonic}}">CSV</button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {{curProtectedRule.mnemonic}}
                </td>
              </tr>
            </tbody>
          </table>
        </ng-container>
    
      </mat-tab>
     -->
  </mat-tab-group>

</span>

<!-- Right Click Context Menu -->
<div *ngIf='currentlySelectedRuleSet !== undefined' [ngStyle]="locationCss"
  class="dropdown-menu dropdown-menu-sm basic-box-shadow hide-scroll" id="context-menu"
  (contextmenu)='this.showOldRuleSetContextMenu = false'>
  <a class="dropdown-item" (click)='this.actionEditRuleSet($event)' href="#">Edit RuleSet {{
    currentlySelectedRuleSet.mnemonic }}</a>
  <!-- <a class="dropdown-item" href="#">Delete RuleSet {{ currentlySelectedRuleSet.mnemonic }}</a>
  <a class="dropdown-item" href="#">Rename RuleSet {{ currentlySelectedRuleSet.mnemonic }}</a> -->
</div>
