import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core'
import {COLOURS} from './colors'
import {PublishOutcome} from '../views/publish/publish.component'

const numeral = require('numeral')

const ENCODINGS = {
  0: 0x3F,
  1: 0x06,
  2: 0x5B,
  3: 0x4F,
  4: 0x66,
  5: 0x6D,
  6: 0x7D,
  7: 0x07,
  8: 0x7F,
  9: 0x6F,
}

@Component({
  selector: 'app-publish-graphic',
  templateUrl: './publish-graphic.component.svg',
})
export class PublishGraphicComponent implements OnInit, OnChanges {
  @Input() width = 1920
  @Input() height = 800
  @Input() timeTaken = 0.0
  @Input() trace: string
  @Input() refresh: string

  tickColors = []
  tickOpacity = []

  lambdas = {
    ingest: {
      colours: COLOURS.UNKNOWN,
    },
    transform: {
      colours: COLOURS.UNKNOWN,
    },
    enrich: {
      colours: COLOURS.UNKNOWN,
    },
    combine: {
      colours: COLOURS.UNKNOWN,
    },
    encapsulate: {
      colours: COLOURS.UNKNOWN,
    },
    publish: {
      colours: COLOURS.UNKNOWN,
    },
    regXchangeGet: {
      colours: COLOURS.UNKNOWN,
    },
    regXchangePublish: {
      colours: COLOURS.UNKNOWN,
    },
    validatePayload: {
      colours: COLOURS.UNKNOWN,
    },
    validateEncapsulated: {
      colours: COLOURS.UNKNOWN,
    },
    generateDiff: {
      colours: COLOURS.UNKNOWN,
    },
    raiseJira: {
      colours: COLOURS.UNKNOWN,
    },
  }

  apigateway = {
    colours: COLOURS.UNKNOWN,
  }

  icons = {
    creditSuisse: {
      colours: COLOURS.UNKNOWN,
    },
    jira: {
      colours: COLOURS.UNKNOWN,
    },
    regXchangeGet: {
      colours: COLOURS.UNKNOWN,
    },
    regXchangePublish: {
      colours: COLOURS.UNKNOWN,
    },
    diffReport: {
      colours: COLOURS.UNKNOWN,
    },
  }

  labels = {
    ingest: {
      colours: COLOURS.UNKNOWN,
    },
    transform: {
      colours: COLOURS.UNKNOWN,
    },
    enrich: {
      colours: COLOURS.UNKNOWN,
    },
    combine: {
      colours: COLOURS.UNKNOWN,
    },
    encapsulate: {
      colours: COLOURS.UNKNOWN,
    },
    publish: {
      colours: COLOURS.UNKNOWN,
    },
    validate: {
      colours: COLOURS.UNKNOWN,
    },
    diffReport: {
      colours: COLOURS.UNKNOWN,
    },
    regXchangeGet: {
      colours: COLOURS.UNKNOWN,
    },
  }

  lines = {
    apiToIngest: {
      colours: COLOURS.UNKNOWN,
    },
    ingestToTransform: {
      colours: COLOURS.UNKNOWN,
    },
    transformToEnrich: {
      colours: COLOURS.UNKNOWN,
    },
    enrichToCombine: {
      colours: COLOURS.UNKNOWN,
    },
    combineToEncapsulate: {
      colours: COLOURS.UNKNOWN,
    },
    encapsulateToPublish: {
      colours: COLOURS.UNKNOWN,
    },
    publishToRegXchange: {
      colours: COLOURS.UNKNOWN,
    },
    regXchangeGetToRetrieve: {
      colours: COLOURS.UNKNOWN,
    },
    retrieveToCombine: {
      colours: COLOURS.UNKNOWN,
    },
    ingestToValidate: {
      colours: COLOURS.UNKNOWN,
    },
    encapsulateToValidate: {
      colours: COLOURS.UNKNOWN,
    },
    regXchangeToDiff: {
      colours: COLOURS.UNKNOWN,
    },
    diffToDiffReport: {
      colours: COLOURS.UNKNOWN,
    },
    jiraToRaiseJira: {
      colours: COLOURS.UNKNOWN,
    },
    apiToCreditSuisse: {
      colours: COLOURS.UNKNOWN,
    },
  }

  led = {
    0: {
      a: 'none',
      b: 'none',
      c: 'none',
      d: 'none',
      e: 'none',
      f: 'none',
      g: 'none',
      p: 'none',
    },
    1: {
      a: 'none',
      b: 'none',
      c: 'none',
      d: 'none',
      e: 'none',
      f: 'none',
      g: 'none',
      p: 'none',
    },
    2: {
      a: 'none',
      b: 'none',
      c: 'none',
      d: 'none',
      e: 'none',
      f: 'none',
      g: 'none',
    },
  }

  stopwatch = {
    opacity: '0.75',
  }

  constructor() {
  }

  ngOnInit() {
  }

  updateTimeTaken() {
    if (this.timeTaken >= 0.01) {
      this.stopwatch.opacity = '1.0'
    } else {
      this.stopwatch.opacity = '0.75'
    }
    this.tickColors = []
    this.tickOpacity = []
    for (let i = 0; i < this.timeTaken; i++) {
      this.tickOpacity.push('1.0')
      if (i < 5) {
        this.tickColors.push('green')
      } else if (i < 10) {
        this.tickColors.push('orange')
      } else {
        this.tickColors.push('red')
      }
    }
    for (let i = 0; i < 20; i++) {
      this.tickOpacity.push('0.1')
    }

    const display = numeral(this.timeTaken).format('0.0[0]').slice(0, 4).replace(/\.$/, '')
    // console.log('Display: ' + display)

    let index = 0
    let ledIndex = 0
    let char = display.charAt(0)
    let bits = ENCODINGS[char]
    let currentLed = this.led['' + ledIndex]
    const on =
      this.timeTaken < 5
        ? 'green'
        : this.timeTaken < 10
        ? 'orange'
        : 'red'
    currentLed.a = (bits & (1 << 0)) ? on : 'none'
    currentLed.b = (bits & (1 << 1)) ? on : 'none'
    currentLed.c = (bits & (1 << 2)) ? on : 'none'
    currentLed.d = (bits & (1 << 3)) ? on : 'none'
    currentLed.e = (bits & (1 << 4)) ? on : 'none'
    currentLed.f = (bits & (1 << 5)) ? on : 'none'
    currentLed.g = (bits & (1 << 6)) ? on : 'none'

    index = index + 1
    char = display.charAt(index)
    currentLed.p = (char === '.') ? on : 'none'
    if (char === '.') {
      index = index + 1
      char = display.charAt(index)
    }
    ledIndex = ledIndex + 1
    currentLed = this.led[ledIndex]
    bits = ENCODINGS[char]
    currentLed.a = (bits & (1 << 0)) ? on : 'none'
    currentLed.b = (bits & (1 << 1)) ? on : 'none'
    currentLed.c = (bits & (1 << 2)) ? on : 'none'
    currentLed.d = (bits & (1 << 3)) ? on : 'none'
    currentLed.e = (bits & (1 << 4)) ? on : 'none'
    currentLed.f = (bits & (1 << 5)) ? on : 'none'
    currentLed.g = (bits & (1 << 6)) ? on : 'none'

    index = index + 1
    char = display.charAt(index)
    currentLed.p = (char === '.') ? on : 'none'
    if (char === '.') {
      index = index + 1
      char = display.charAt(index)
    }
    ledIndex = ledIndex + 1
    currentLed = this.led[ledIndex]
    bits = ENCODINGS[char]
    currentLed.a = (bits & (1 << 0)) ? on : 'none'
    currentLed.b = (bits & (1 << 1)) ? on : 'none'
    currentLed.c = (bits & (1 << 2)) ? on : 'none'
    currentLed.d = (bits & (1 << 3)) ? on : 'none'
    currentLed.e = (bits & (1 << 4)) ? on : 'none'
    currentLed.f = (bits & (1 << 5)) ? on : 'none'
    currentLed.g = (bits & (1 << 6)) ? on : 'none'
  }

  ngOnChanges(changes: SimpleChanges): void {
    try {

      if (!this.trace) {
        this.reset()
        return
      }
      this.lines.apiToIngest.colours = COLOURS.OK
      this.lambdas.ingest.colours = COLOURS.OK
      this.labels.ingest.colours = COLOURS.OK

      const trace = JSON.parse(this.trace) as PublishOutcome
      if (trace.latency) {
        this.timeTaken = trace.latency / 1000
        this.updateTimeTaken()
      }

      if (trace.config.ingest.supported === 'NO') {
        this.apigateway.colours = COLOURS.BLACKLIST
        this.lines.apiToCreditSuisse.colours = COLOURS.HIDDEN
      } else {
        if (trace.published) {
          this.apigateway.colours = COLOURS.OK
          this.lines.apiToCreditSuisse.colours = COLOURS.OK
          this.icons.creditSuisse.colours = COLOURS.OK
        } else {
          this.apigateway.colours = COLOURS.ERROR
          this.lines.apiToCreditSuisse.colours = COLOURS.ERROR
          this.icons.creditSuisse.colours = COLOURS.ERROR
        }
      }

      if (trace.sparrowWorkItem) {
        this.icons.creditSuisse.colours = COLOURS.ERROR
      }

      if (trace.jiraRaised) {
        // TODO - errors or warning?
        this.lambdas.raiseJira.colours = COLOURS.ERROR
        this.lines.jiraToRaiseJira.colours = COLOURS.ERROR
        this.icons.jira.colours = COLOURS.ERROR

      }

      if (trace.validatePayload) {
        if (trace.validatePayload.result === 'OK') {
          this.lines.ingestToValidate.colours = COLOURS.OK
          this.lambdas.validatePayload.colours = COLOURS.OK
        } else if (trace.validatePayload.result === 'SKIPPED') {
          this.lines.ingestToValidate.colours = COLOURS.SKIPPED
          this.lambdas.validatePayload.colours = COLOURS.SKIPPED
        } else if (trace.validatePayload.result === 'ERROR') {
          this.lines.ingestToValidate.colours = COLOURS.ERROR
          this.lambdas.validatePayload.colours = COLOURS.ERROR
        } else if (trace.validatePayload.result === 'WARNING') {
          this.lines.ingestToValidate.colours = COLOURS.WARNING
          this.lambdas.validatePayload.colours = COLOURS.WARNING
        } else {
          this.lines.ingestToValidate.colours = COLOURS.UNKNOWN
          this.lambdas.validatePayload.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.ingestToValidate.colours = COLOURS.PROCESSING
        this.lambdas.validatePayload.colours = COLOURS.PROCESSING
      }

      if (trace.validatePayload && trace.validateEnriched) {
        if (trace.validatePayload.result === 'ERROR' || trace.validateEnriched.result === 'ERROR') {
          this.labels.validate.colours = COLOURS.ERROR
        } else if (trace.validatePayload.result === 'WARNING' || trace.validateEnriched.result === 'WARNING') {
          this.labels.validate.colours = COLOURS.WARNING
        } else if (trace.validatePayload.result === 'OK' || trace.validateEnriched.result === 'OK') {
          this.labels.validate.colours = COLOURS.OK
        } else if (trace.validatePayload.result === 'SKIPPED' && trace.validateEnriched.result === 'SKIPPED') {
          this.labels.validate.colours = COLOURS.SKIPPED
        }
      }

      if (trace.validateEnriched) {
        if (trace.validateEnriched.result === 'OK') {
          this.lines.encapsulateToValidate.colours = COLOURS.OK
          this.lambdas.validateEncapsulated.colours = COLOURS.OK
        } else if (trace.validateEnriched.result === 'SKIPPED') {
          this.lines.encapsulateToValidate.colours = COLOURS.SKIPPED
          this.lambdas.validateEncapsulated.colours = COLOURS.SKIPPED
        } else if (trace.validateEnriched.result === 'ERROR') {
          this.lines.encapsulateToValidate.colours = COLOURS.ERROR
          this.lambdas.validateEncapsulated.colours = COLOURS.ERROR
        } else if (trace.validateEnriched.result === 'WARNING') {
          this.lines.encapsulateToValidate.colours = COLOURS.WARNING
          this.lambdas.validateEncapsulated.colours = COLOURS.WARNING
        } else {
          this.lines.encapsulateToValidate.colours = COLOURS.UNKNOWN
          this.lambdas.validateEncapsulated.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.encapsulateToValidate.colours = COLOURS.PROCESSING
        this.lambdas.validateEncapsulated.colours = COLOURS.PROCESSING
      }

      if (trace.transform) {
        if (trace.transform.result === 'OK') {
          this.lines.ingestToTransform.colours = COLOURS.OK
          this.lambdas.transform.colours = COLOURS.OK
          this.labels.transform.colours = COLOURS.OK
        } else if (trace.transform.result === 'SKIPPED') {
          this.lines.ingestToTransform.colours = COLOURS.SKIPPED
          this.lambdas.transform.colours = COLOURS.SKIPPED
          this.labels.transform.colours = COLOURS.SKIPPED
        } else if (trace.transform.result === 'ERROR') {
          this.lines.ingestToTransform.colours = COLOURS.ERROR
          this.lambdas.transform.colours = COLOURS.ERROR
          this.labels.transform.colours = COLOURS.ERROR
        } else if (trace.transform.result === 'WARNING') {
          this.lines.ingestToTransform.colours = COLOURS.WARNING
          this.lambdas.transform.colours = COLOURS.WARNING
          this.labels.transform.colours = COLOURS.WARNING
        } else {
          this.lines.ingestToTransform.colours = COLOURS.UNKNOWN
          this.lambdas.transform.colours = COLOURS.UNKNOWN
          this.labels.transform.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.ingestToTransform.colours = COLOURS.PROCESSING
        this.lambdas.transform.colours = COLOURS.PROCESSING
        this.labels.transform.colours = COLOURS.PROCESSING
      }

      if (trace.enrich) {
        if (trace.enrich.result === 'OK') {
          this.lines.transformToEnrich.colours = COLOURS.OK
          this.lambdas.enrich.colours = COLOURS.OK
          this.labels.enrich.colours = COLOURS.OK
        } else if (trace.enrich.result === 'SKIPPED') {
          this.lines.transformToEnrich.colours = COLOURS.SKIPPED
          this.lambdas.enrich.colours = COLOURS.SKIPPED
          this.labels.enrich.colours = COLOURS.SKIPPED
        } else if (trace.enrich.result === 'ERROR') {
          this.lines.transformToEnrich.colours = COLOURS.ERROR
          this.lambdas.enrich.colours = COLOURS.ERROR
          this.labels.enrich.colours = COLOURS.ERROR
        } else if (trace.enrich.result === 'WARNING') {
          this.lines.transformToEnrich.colours = COLOURS.WARNING
          this.lambdas.enrich.colours = COLOURS.WARNING
          this.labels.enrich.colours = COLOURS.WARNING
        } else {
          this.lines.transformToEnrich.colours = COLOURS.UNKNOWN
          this.lambdas.enrich.colours = COLOURS.UNKNOWN
          this.labels.enrich.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.transformToEnrich.colours = COLOURS.PROCESSING
        this.lambdas.enrich.colours = COLOURS.PROCESSING
        this.labels.enrich.colours = COLOURS.PROCESSING
      }

      if (trace.retrieve) {
        if (trace.retrieve.result === 'OK') {
          this.lines.regXchangeGetToRetrieve.colours = COLOURS.OK
          this.lines.retrieveToCombine.colours = COLOURS.OK
          this.lambdas.regXchangeGet.colours = COLOURS.OK
          this.labels.regXchangeGet.colours = COLOURS.OK
          this.icons.regXchangeGet.colours = COLOURS.OK
        } else if (trace.retrieve.result === 'SKIPPED') {
          this.lines.regXchangeGetToRetrieve.colours = COLOURS.SKIPPED
          this.lines.retrieveToCombine.colours = COLOURS.SKIPPED
          this.lambdas.regXchangeGet.colours = COLOURS.SKIPPED
          this.labels.regXchangeGet.colours = COLOURS.SKIPPED
          this.icons.regXchangeGet.colours = COLOURS.SKIPPED
        } else if (trace.retrieve.result === 'ERROR') {
          this.lines.regXchangeGetToRetrieve.colours = COLOURS.ERROR
          this.lines.retrieveToCombine.colours = COLOURS.ERROR
          this.lambdas.regXchangeGet.colours = COLOURS.ERROR
          this.labels.regXchangeGet.colours = COLOURS.ERROR
          this.icons.regXchangeGet.colours = COLOURS.ERROR
        } else {
          this.lines.regXchangeGetToRetrieve.colours = COLOURS.UNKNOWN
          this.lines.retrieveToCombine.colours = COLOURS.UNKNOWN
          this.lambdas.regXchangeGet.colours = COLOURS.UNKNOWN
          this.labels.regXchangeGet.colours = COLOURS.UNKNOWN
          this.icons.regXchangeGet.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.regXchangeGetToRetrieve.colours = COLOURS.PROCESSING
        this.lines.retrieveToCombine.colours = COLOURS.PROCESSING
        this.lambdas.regXchangeGet.colours = COLOURS.PROCESSING
        this.labels.regXchangeGet.colours = COLOURS.PROCESSING
        this.icons.regXchangeGet.colours = COLOURS.PROCESSING
      }

      if (trace.combine) {
        // TODO - regXchangeGet
        if (trace.combine.result === 'OK') {
          this.lines.enrichToCombine.colours = COLOURS.OK
          this.lambdas.combine.colours = COLOURS.OK
          this.labels.combine.colours = COLOURS.OK
        } else if (trace.combine.result === 'SKIPPED') {
          this.lines.enrichToCombine.colours = COLOURS.SKIPPED
          this.lambdas.combine.colours = COLOURS.SKIPPED
          this.labels.combine.colours = COLOURS.SKIPPED
        } else if (trace.combine.result === 'ERROR') {
          this.lines.enrichToCombine.colours = COLOURS.ERROR
          this.lambdas.combine.colours = COLOURS.ERROR
          this.labels.combine.colours = COLOURS.ERROR
        } else if (trace.combine.result === 'WARNING') {
          this.lines.enrichToCombine.colours = COLOURS.WARNING
          this.lambdas.combine.colours = COLOURS.WARNING
          this.labels.combine.colours = COLOURS.WARNING
        } else {
          this.lines.enrichToCombine.colours = COLOURS.UNKNOWN
          this.lambdas.combine.colours = COLOURS.UNKNOWN
          this.labels.combine.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.enrichToCombine.colours = COLOURS.PROCESSING
        this.lambdas.combine.colours = COLOURS.PROCESSING
        this.labels.combine.colours = COLOURS.PROCESSING
      }

      if (trace.encapsulate) {
        if (trace.encapsulate.result === 'OK') {
          this.lines.combineToEncapsulate.colours = COLOURS.OK
          this.lambdas.encapsulate.colours = COLOURS.OK
          this.labels.encapsulate.colours = COLOURS.OK
        } else if (trace.encapsulate.result === 'SKIPPED') {
          this.lines.combineToEncapsulate.colours = COLOURS.SKIPPED
          this.lambdas.encapsulate.colours = COLOURS.SKIPPED
          this.labels.encapsulate.colours = COLOURS.SKIPPED
        } else if (trace.encapsulate.result === 'ERROR') {
          this.lines.combineToEncapsulate.colours = COLOURS.ERROR
          this.lambdas.encapsulate.colours = COLOURS.ERROR
          this.labels.encapsulate.colours = COLOURS.ERROR
        } else if (trace.encapsulate.result === 'WARNING') {
          this.lines.combineToEncapsulate.colours = COLOURS.WARNING
          this.lambdas.encapsulate.colours = COLOURS.WARNING
          this.labels.encapsulate.colours = COLOURS.WARNING
        } else {
          this.lines.combineToEncapsulate.colours = COLOURS.UNKNOWN
          this.lambdas.encapsulate.colours = COLOURS.UNKNOWN
          this.labels.encapsulate.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.combineToEncapsulate.colours = COLOURS.PROCESSING
        this.lambdas.encapsulate.colours = COLOURS.PROCESSING
        this.labels.encapsulate.colours = COLOURS.PROCESSING
      }

      if (trace.publish) {
        if (trace.publish.result === 'OK') {
          this.lines.encapsulateToPublish.colours = COLOURS.OK
          this.labels.publish.colours = COLOURS.OK
          this.lambdas.publish.colours = COLOURS.OK
          this.lambdas.regXchangePublish.colours = COLOURS.OK
          this.lines.publishToRegXchange.colours = COLOURS.OK
          this.icons.regXchangePublish.colours = COLOURS.OK
        } else if (trace.publish.result === 'SKIPPED') {
          this.lines.encapsulateToPublish.colours = COLOURS.SKIPPED
          this.labels.publish.colours = COLOURS.SKIPPED
          this.lambdas.publish.colours = COLOURS.SKIPPED
          this.lambdas.regXchangePublish.colours = COLOURS.SKIPPED
          this.lines.publishToRegXchange.colours = COLOURS.SKIPPED
          this.icons.regXchangePublish.colours = COLOURS.SKIPPED
        } else if (trace.publish.result === 'ERROR') {
          this.lines.encapsulateToPublish.colours = COLOURS.ERROR
          this.labels.publish.colours = COLOURS.ERROR
          this.lambdas.publish.colours = COLOURS.ERROR
          this.lambdas.regXchangePublish.colours = COLOURS.ERROR
          this.lines.publishToRegXchange.colours = COLOURS.ERROR
          this.icons.regXchangePublish.colours = COLOURS.ERROR
        } else {
          this.lines.encapsulateToPublish.colours = COLOURS.UNKNOWN
          this.labels.publish.colours = COLOURS.UNKNOWN
          this.lambdas.publish.colours = COLOURS.UNKNOWN
          this.lambdas.regXchangePublish.colours = COLOURS.UNKNOWN
          this.lines.publishToRegXchange.colours = COLOURS.UNKNOWN
          this.icons.regXchangePublish.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.encapsulateToPublish.colours = COLOURS.PROCESSING
        this.labels.publish.colours = COLOURS.PROCESSING
        this.lambdas.publish.colours = COLOURS.PROCESSING
        this.lambdas.regXchangePublish.colours = COLOURS.PROCESSING
        this.lines.publishToRegXchange.colours = COLOURS.PROCESSING
        this.icons.regXchangePublish.colours = COLOURS.PROCESSING
      }

      if (trace.difference) {
        if (trace.difference.result === 'OK') {
          this.lines.regXchangeToDiff.colours = COLOURS.OK
          this.lines.diffToDiffReport.colours = COLOURS.OK
          this.lambdas.regXchangePublish.colours = COLOURS.OK
          this.lambdas.generateDiff.colours = COLOURS.OK
          this.labels.diffReport.colours = COLOURS.OK
          this.icons.regXchangePublish.colours = COLOURS.OK
          this.icons.diffReport.colours = COLOURS.OK
        } else if (trace.difference.result === 'SKIPPED') {
          this.lines.regXchangeToDiff.colours = COLOURS.SKIPPED
          this.lines.diffToDiffReport.colours = COLOURS.SKIPPED
          this.lambdas.generateDiff.colours = COLOURS.SKIPPED
          this.labels.diffReport.colours = COLOURS.SKIPPED
          this.icons.diffReport.colours = COLOURS.SKIPPED
        } else if (trace.difference.result === 'ERROR') {
          this.lines.regXchangeToDiff.colours = COLOURS.ERROR
          this.lines.diffToDiffReport.colours = COLOURS.ERROR
          this.lambdas.regXchangePublish.colours = COLOURS.ERROR
          this.lambdas.generateDiff.colours = COLOURS.ERROR
          this.labels.diffReport.colours = COLOURS.ERROR
          this.icons.regXchangePublish.colours = COLOURS.ERROR
          this.icons.diffReport.colours = COLOURS.ERROR
        } else {
          this.lines.regXchangeToDiff.colours = COLOURS.UNKNOWN
          this.lines.diffToDiffReport.colours = COLOURS.UNKNOWN
          this.lambdas.generateDiff.colours = COLOURS.UNKNOWN
          this.labels.diffReport.colours = COLOURS.UNKNOWN
          this.icons.diffReport.colours = COLOURS.UNKNOWN
        }
      } else {
        this.lines.regXchangeToDiff.colours = COLOURS.UNKNOWN
        this.lines.diffToDiffReport.colours = COLOURS.UNKNOWN
        this.lambdas.generateDiff.colours = COLOURS.UNKNOWN
        this.labels.diffReport.colours = COLOURS.UNKNOWN
        this.icons.diffReport.colours = COLOURS.UNKNOWN
      }


    } catch (e) {
      console.log('Failed to update graphic!')
      console.dir(e)
    }
  }

  reset() {
    this.timeTaken = 0.0
    this.updateTimeTaken()

    this.lambdas = {
      ingest: {
        colours: COLOURS.UNKNOWN,
      },
      transform: {
        colours: COLOURS.UNKNOWN,
      },
      enrich: {
        colours: COLOURS.UNKNOWN,
      },
      combine: {
        colours: COLOURS.UNKNOWN,
      },
      encapsulate: {
        colours: COLOURS.UNKNOWN,
      },
      publish: {
        colours: COLOURS.UNKNOWN,
      },
      regXchangeGet: {
        colours: COLOURS.UNKNOWN,
      },
      regXchangePublish: {
        colours: COLOURS.UNKNOWN,
      },
      validatePayload: {
        colours: COLOURS.UNKNOWN,
      },
      validateEncapsulated: {
        colours: COLOURS.UNKNOWN,
      },
      generateDiff: {
        colours: COLOURS.UNKNOWN,
      },
      raiseJira: {
        colours: COLOURS.UNKNOWN,
      },
    }

    this.apigateway = {
      colours: COLOURS.UNKNOWN,
    }

    this.icons = {
      creditSuisse: {
        colours: COLOURS.UNKNOWN,
      },
      jira: {
        colours: COLOURS.UNKNOWN,
      },
      regXchangeGet: {
        colours: COLOURS.UNKNOWN,
      },
      regXchangePublish: {
        colours: COLOURS.UNKNOWN,
      },
      diffReport: {
        colours: COLOURS.UNKNOWN,
      },
    }

    this.labels = {
      ingest: {
        colours: COLOURS.UNKNOWN,
      },
      transform: {
        colours: COLOURS.UNKNOWN,
      },
      enrich: {
        colours: COLOURS.UNKNOWN,
      },
      combine: {
        colours: COLOURS.UNKNOWN,
      },
      encapsulate: {
        colours: COLOURS.UNKNOWN,
      },
      publish: {
        colours: COLOURS.UNKNOWN,
      },
      validate: {
        colours: COLOURS.UNKNOWN,
      },
      diffReport: {
        colours: COLOURS.UNKNOWN,
      },
      regXchangeGet: {
        colours: COLOURS.UNKNOWN,
      },
    }

    this.lines = {
      apiToIngest: {
        colours: COLOURS.UNKNOWN,
      },
      ingestToTransform: {
        colours: COLOURS.UNKNOWN,
      },
      transformToEnrich: {
        colours: COLOURS.UNKNOWN,
      },
      enrichToCombine: {
        colours: COLOURS.UNKNOWN,
      },
      combineToEncapsulate: {
        colours: COLOURS.UNKNOWN,
      },
      encapsulateToPublish: {
        colours: COLOURS.UNKNOWN,
      },
      publishToRegXchange: {
        colours: COLOURS.UNKNOWN,
      },
      regXchangeGetToRetrieve: {
        colours: COLOURS.UNKNOWN,
      },
      retrieveToCombine: {
        colours: COLOURS.UNKNOWN,
      },
      ingestToValidate: {
        colours: COLOURS.UNKNOWN,
      },
      encapsulateToValidate: {
        colours: COLOURS.UNKNOWN,
      },
      regXchangeToDiff: {
        colours: COLOURS.UNKNOWN,
      },
      diffToDiffReport: {
        colours: COLOURS.UNKNOWN,
      },
      jiraToRaiseJira: {
        colours: COLOURS.UNKNOWN,
      },
      apiToCreditSuisse: {
        colours: COLOURS.UNKNOWN,
      },
    }
  }
}
