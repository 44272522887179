import cloneDeep from 'clone-deep'
import {DateTime} from 'luxon'
import {ChangeDetails} from 'src/app/common/domain/change'
import {NormalisedRuleSet} from 'src/app/model/normalised-ruleset'
import {ChangeService} from 'src/app/services/change.service'

export class Ruleset2021ViewEditSaveLogic {

    public static async updateWithChangeService(normalisedRuleSet: NormalisedRuleSet, selectedConfigKeyElements: string[], changeService: ChangeService) {
        const newNormalisedRuleSet = cloneDeep(normalisedRuleSet)
        newNormalisedRuleSet.entries = selectedConfigKeyElements;
        newNormalisedRuleSet.lastUpdated = DateTime.utc().toISO() // {suppressMilliseconds: true}

        // Get current version from change service (this could be from a Workspace)
        const ruleSetMnemonic = normalisedRuleSet.mnemonic
        const fileName = `rulesets/${ruleSetMnemonic.toUpperCase()}.json`
        const changeDetails: ChangeDetails = changeService.getObject(fileName)

        changeDetails.content = JSON.stringify(newNormalisedRuleSet, undefined, 2)
        changeDetails.state = 'modified'
        await changeService.upsertObject(changeDetails)
        // console.info('Persisted:')
        // console.dir(changeDetails)
    }

}
