<div class="jmespath">
  <div class="jmespath-expression">
    <h3>JMESPath Expression</h3>
    <input [(ngModel)]="expression" (keyup)="evaluate()">
  </div>
  <div class="jmespath-input">
    <h3>Payload</h3>
    <ngx-monaco-editor
      class="payload-editor"
      [ngClass]="this.isPayloadEvaluator ? 'maxsize-editor' : 'monaco-editor'"
      [options]="options"
      [(ngModel)]="payloadModel"
      (ngModelChange)="evaluate()"
    ></ngx-monaco-editor>
  </div>
  <div class="jmespath-result">
    <h3>Result</h3>
    <div>{{result}}</div>
  </div>
</div>

