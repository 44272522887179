<div class="main-content">
  <ng-container>
    <div>
      <h2>Manual Drafting - Save a Payload</h2>
    </div>
    &nbsp; &nbsp;
    <div>
      <label for="nameInput">Unique Reference</label>
      <input
        class="form-control"
        id="unique-reference"
        [value]="
          this.newPayloadObj.length
            ? this.newPayloadObj[0].id
            : ''
        "
         disabled
        placeholder="Unique Reference"
        #uniqueReference
      />
    </div>
    <div>
      <label for="nameInput">Description</label>
      <input
        class="form-control"
        maxlength="200"
        id="description"
        [value]="
          this.newPayloadObj.length ? this.newPayloadObj[0].description : ''
        "
        placeholder="Description"
        #description
      />
    </div>
    &nbsp; &nbsp;
    <div>
      <label for="nameInput">Payload</label></div>
    <div class="container-fluid">
      <ul ngbNav #manualDraftingNav="ngbNav">
        <li ngbNavItem="payload">
          <!-- <label for="nameInput" ngbNavLink>Payload</label> -->
          <ng-template ngbNavContent>
            <div class="payload-input">
              <ngx-monaco-editor
                class="monaco-editor payload-editor payload-border"
                [options]="payloadEditorConfig"
                [(ngModel)]="payloadValue"
                (ngModelChange)="refresh()"
              >
              </ngx-monaco-editor>
            </div>
          </ng-template>
        </li>
      </ul>
    </div>
    <div [ngbNavOutlet]="manualDraftingNav" class="tab-content"></div>
    &nbsp;
    <div>
      <button 
        type="button"
        class="btn btn-primary"
        (click)="savePayLoad(uniqueReference.value, description.value)"
      >
        Save Payload
      </button>
      <button type="button" class="btn btn-secondary" (click)="cancel()">
        Cancel
      </button>
    </div>
  </ng-container>
</div>
