import {Component, Input, OnDestroy, OnInit} from '@angular/core'
import {MatDialog} from '@angular/material/dialog'
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'
import deepEqual from 'deep-equal'
import {ToastrService} from 'ngx-toastr'
import {from, merge, Observable, of, Subscription, timer, zip} from 'rxjs'
import {
  catchError, concatMap,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  tap,
  toArray
} from 'rxjs/operators'
import {AlertDialogComponent} from 'src/app/dialogs/alert-dialog/alert-dialog.component'
import {PendingApproval} from '../../common/domain/approvals'
import {PipelineVersionInfo, StageState} from '../../common/domain/version'
import {JiraIssueDialogComponent} from '../../dialogs/jira-issue-dialog/jira-issue-dialog.component'
import {ConfigVersion} from '../../model/infra-config-response'
import {ApiService} from '../../services/api.service'
import {ApprovalsService} from '../../services/approvals.service'
import {ConfigVersionService} from '../../services/config-version.service'
import {PermissionsService} from '../../services/permissions.service'
import {ConfigService} from '../../services/config.service';
import {ConfigDetail} from '../../common/domain/config';
import {ChangeService} from '../../services/change.service';
import {ConfirmStatus} from '../../dialogs/confirm-dialog.component';
import {ConfirmCommentDialogComponent} from '../../dialogs/confirm-comment-dialog.component';
import {Router} from '@angular/router';
import { versions } from 'process'
import {MatCheckboxChange} from '@angular/material/checkbox';

interface AccountVersion {
  account: 'tools' | 'dev' | 'uat' | 'prod'
  version: string
  buildNumber: string
  buildTime: string
}

interface CommitLog {
  id: string
  message: string
  date: string
  user: string
  email: string
  parents: string[]
}

@Component({
  selector: 'app-config-approval',
  templateUrl: './config-approval.component.html',
  styleUrls: ['./config-approval.component.scss'],
})
export class ConfigApprovalComponent implements OnInit, OnDestroy {
  @Input() repository: string
  @Input() lambda: string
  @Input() enableRollback?: boolean
  environment: string

  private _isDeveloper = true // Set to correct value in ngOnInit

  // TODO - allow the pipeline to be defined as an input.
  timer$ = timer(0, 15000)

  sub = new Subscription()

  private accountList = [{
    name: 'Tools',
    first: true,
    previous: undefined,
    next: 'Dev'
  },
    {
      name: 'Dev',
      previous: 'Tools',
      next: 'Uat'
    }, {
      name: 'Uat',
      previous: 'Dev',
      next: 'Prod'
    }, {
      name: 'Prod',
      previous: 'Uat',
      next: undefined
    }]

  private accountMap = {}

  pipeline: Observable<PipelineVersionInfo> = merge(this.timer$).pipe(
    switchMap(_ => {
      return this.api.get<PipelineVersionInfo>(`/config/pipeline/${this.lambda}`)
    }),
    distinctUntilChanged(deepEqual),
    tap(x => {
      console.log('Pipeline: ')
      console.dir(x)
    },
    ),
    tap(pipelineInfo => {
      let index = 0
      while (index < this.accountList.length) {
        if (pipelineInfo[this.accountList[index].name].state === 'UNKNOWN' && index < this.accountList.length - 1) {
          this.accountList[index - 1].next = this.accountList[index].next
          this.accountList[index + 1].previous = this.accountList[index].previous
          this.accountList.splice(index, 1)
        } else {
          index++
        }
      }
      this.accountMap = this.accountList.reduce((acc, curr) => {
        acc[curr.name] = curr
        return acc
      }, {})
    }),
    shareReplay(1),
  )
  deployedVersions: Observable<AccountVersion[]> = this.timer$.pipe(
    switchMap(_ => {
      return of(...this.accountList.map(account => account.name.toLowerCase()))
        .pipe(
          mergeMap(account => {
            return this.api.get<ConfigVersion>(`/config/version/${this.lambda}/${account}/LONDON`)
              .pipe(
                map((accountVersion: ConfigVersion) => {
                  return {
                    account: account,
                    version: accountVersion.version.version,
                    buildNumber: accountVersion.version.buildNumber,
                    buildTime: accountVersion.version.buildTime,
                  }
                }),
              )
          }),
          toArray(),
        )
    }),
    distinctUntilChanged(deepEqual),
    shareReplay(1),
  )

  commitLog: Observable<CommitLog[]> = this.deployedVersions
    .pipe(
      switchMap((versions: AccountVersion[]) => {
        const prodVersion = versions.find(v => v.account === 'prod').version
        return of(prodVersion)
      }),
      distinctUntilChanged(),
      switchMap((commitId: string) => {
        return this.api.get<CommitLog[]>(`/config/log/${this.repository}/${commitId}`)
          .pipe(
            map(r => {
              if (r && !r.length) {
                return [r]
              }
              return r
            })
          )
      }),
      distinctUntilChanged(deepEqual),
      shareReplay(1),
    )



  buildChanges: Observable<CommitLog[]> = this.pipeline.pipe(
    switchMap((pipeline: PipelineVersionInfo) => {
      if (pipeline.Build.state === 'DEPLOYING') {
        return this.deployedVersions.pipe(
          map(versions => versions.find(v => v.account === 'tools')),
        )
      } else {
        return of(undefined)
      }
    }),
    map((devVersion?: AccountVersion) => devVersion?.version),
    switchMap((devVersion?: string) => {
      if (!devVersion) {
        return of([])
      }
      return this.commitLog.pipe(
        map((log: CommitLog[]) => {
          if (log && log.length > 0) {
            const devCommit = log.find(l => l.id === devVersion)
            return devCommit ? log.splice(0, log.indexOf(devCommit)) : []
          } else {
            return []
          }
        }),
      )
    }),
  )

  developmentChanges: Observable<CommitLog[]> = this.getChanges('Tools')
  stagingChanges: Observable<CommitLog[]> = this.getChanges('Dev')
  uatChanges: Observable<CommitLog[]> = this.getChanges('Uat')
  prodChanges: Observable<CommitLog[]> = this.getChanges('Prod')

  private getChanges(currEnv: string) {
    return this.pipeline.pipe(
      switchMap((pipeline: PipelineVersionInfo) => {
        const prevEnv = this.accountMap[currEnv].previous
        const nextEnv = this.accountMap[currEnv].next
        const firstEnv = this.accountMap[currEnv].first
        if ((pipeline[currEnv].state === 'PENDING_APPROVAL' || pipeline[currEnv].state === 'DEPLOYING') && (pipeline[prevEnv].state === 'PENDING_APPROVAL' || pipeline[prevEnv].state === 'OK')) {
          return this.deployedVersions.pipe(
            map(versions => {
              return [
                firstEnv ? undefined : versions.find(v => v.account === prevEnv.toLowerCase()),
                nextEnv ? versions.find(v => v.account === currEnv.toLowerCase()) : undefined
              ]
            }),
          )
        } else {
          return of([undefined, undefined])
        }
      }),
      map(([v1, v2]: [AccountVersion | undefined, AccountVersion | undefined]) => [v1?.version, v2?.version]),
      switchMap(([prevVer, currVer]: [string | undefined, string | undefined]) => {
        if (prevVer && currVer) {
          return this.getCommitLogBetween(prevVer, currVer)
        } else if (prevVer) {
          return this.getChangesAfter(prevVer)
        } else if (currVer) {
          return this.getChangesUpTo(currVer)
        }
      }),
    )
  }


    stagingApprovalToken: string
  stagingApprovalPipelineExecutionId: string

  uatApprovalToken: string
  uatApprovalPipelineExecutionId: string

  prodApprovalToken: string
  prodApprovalPipelineExecutionId: string

  canApprove: boolean
  canRollbackCommit: boolean

  displayReleaseChangeDetails = false

  private canApproveStaging: boolean
  private canApproveUat: boolean

  commitsToRollback: CommitLog[] = []

  constructor(
    private configVersionService: ConfigVersionService,
    private api: ApiService,
    private permissionsService: PermissionsService,
    private approvalsService: ApprovalsService,
    private configService: ConfigService,
    private changeService: ChangeService,
    private modalService: NgbModal,
    private toast: ToastrService,
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  getCommitLogBetween: (v1: string, v2: string) => Observable<CommitLog[]> = (v1: string, v2: string) => {
    if (!v1 || !v2) {
      return of([])
    }
    return this.commitLog.pipe(
      switchMap((log: CommitLog[]) => {
        if (log && log.length > 0) {
          const startingVersion = log.find(l => l.id === v1)
          const targetVersion = log.find(l => l.id === v2)
          if (targetVersion && startingVersion) {
            return of(log.slice(log.indexOf(startingVersion), log.indexOf(targetVersion)))
          } else {
            if (startingVersion) {
              return of(log.slice(log.indexOf(startingVersion)))
            } else {
              return of([])
            }
          }
        } else {
          return of([])
        }
      }),
    )
  }

  getChangesUpTo: (devVersion: string) => (Observable<CommitLog[]>) = (devVersion: string) => {
    if (!devVersion) {
      return of([])
    }
    return this.commitLog.pipe(
      map((log: CommitLog[]) => {
        if (log && log.length > 0) {
          const devCommit = log.find(l => l.id === devVersion)
          return devCommit ? log.slice(0, log.indexOf(devCommit)) : []
        } else {
          return []
        }
      }),
    )
  }

  getChangesAfter: (version: string) => (Observable<CommitLog[]>) = (version: string) => {
    if (!version) {
      return of([])
    }
    return this.commitLog.pipe(
      switchMap((log: CommitLog[]) => {
        if (log && log.length > 0) {
          const uatVersion = log.find(l => l.id === version)
          return of(log.slice(log.indexOf(uatVersion)))
        } else {
          return of([])
        }
      }),
    )
  }


  ngOnInit(): void {
    this.permissionsService.developer.subscribe(
      (permission: boolean) => {
        this._isDeveloper = permission
        console.info('_isDeveloper: ' + this._isDeveloper)
      })

    this.sub.add(
      this.permissionsService.approver.subscribe(canApprove => this.canApprove = canApprove)
    )
    this.sub.add(
      this.permissionsService.stagingApprover.subscribe(canApproveStaging => this.canApproveStaging = canApproveStaging)
    )
    this.sub.add(
      this.permissionsService.uatApprover.subscribe(canApproveUat => this.canApproveUat = canApproveUat)
    )
    this.sub.add(
      this.pipeline.subscribe(pipeline => {
        this.stagingApprovalToken = (pipeline.Tools.state === 'OK' && pipeline.Dev.token) ? pipeline.Dev.token : undefined
        this.stagingApprovalPipelineExecutionId = (pipeline[this.accountMap['Dev']?.previous || 'Tools'].state === 'OK' && pipeline.Dev.pipelineExecutionId) ? pipeline.Dev.pipelineExecutionId : undefined
        this.uatApprovalToken = ((pipeline[this.accountMap['Uat']?.previous || 'Dev'].state === 'OK' || pipeline[this.accountMap['Uat']?.previous || 'Dev'].state === 'PENDING_APPROVAL') && pipeline.Uat.token) ? pipeline.Uat.token : undefined
        this.uatApprovalPipelineExecutionId = ((pipeline[this.accountMap['Uat']?.previous || 'Dev'].state === 'OK' || pipeline[this.accountMap['Uat']?.previous || 'Dev'].state === 'PENDING_APPROVAL') && pipeline.Uat.pipelineExecutionId) ? pipeline.Uat.pipelineExecutionId : undefined
        this.prodApprovalToken = ((pipeline[this.accountMap['Prod']?.previous || 'Uat'].state === 'OK' || pipeline[this.accountMap['Prod']?.previous || 'Uat'].state === 'PENDING_APPROVAL') && pipeline.Prod.token) ? pipeline.Prod.token : undefined
        this.prodApprovalPipelineExecutionId = ((pipeline[this.accountMap['Prod']?.previous || 'Uat'].state === 'OK' || pipeline[this.accountMap['Prod']?.previous || 'Uat'].state === 'PENDING_APPROVAL') && pipeline.Prod.pipelineExecutionId) ? pipeline.Prod.pipelineExecutionId : undefined
      }),
    )
    this.sub.add(
      this.permissionsService.canRollbackCommit.subscribe(value => this.canRollbackCommit = value)
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  private approveTransition(pipelineApprovalAction: string, pipelineApprovalStage: string, token: string, executionId: string) {
    const dialog = this.modalService.open(JiraIssueDialogComponent)
    dialog.result
      .then((res: { jiraIssue: string, description: string }) => {
        const pending: PendingApproval = {
          pipelineApprovalAction,
          pipelineApprovalStage,
          pipelineApprovalToken: token,
          pipelineExecutionId: executionId,
          pipelineName: this.repository + '-pipeline',
        }
        this.approvalsService.approve([pending], res.jiraIssue, res.description)
          .pipe(
            catchError((e: any) => {
              this.toast.error('Failed to publish - ' + e)
              return of(undefined)
            }),
          )
          .subscribe(res => {
            if (res) {
              this.toast.success('Approved')
            }
          })
      })
      .catch(_ => {
        this.toast.warning('Approval cancelled')
      })
  }

  public async approveStaging(token: string, executionId: string) {
    this.approveTransition('DevApproval','DeployToDev', token, executionId);
  }

  public async approveUat(token: string, executionId: string) {
    const dialog = this.modalService.open(JiraIssueDialogComponent)
    dialog.result
      .then((res: {jiraIssue: string, description: string}) => {
        const pending: PendingApproval = {
          pipelineApprovalAction: 'UatApproval',
          pipelineApprovalStage: 'DeployToUat',
          pipelineApprovalToken: token,
          pipelineExecutionId: executionId,
          pipelineName: this.repository + '-pipeline',
        }
        this.approvalsService.approve([pending], res.jiraIssue, res.description)
          .pipe(
            catchError((e: any) => {
              this.toast.error('Failed to publish - ' + e)
              return of(undefined)
            }),
          )
          .subscribe(res => {
            if (res) {
              this.toast.success('Approved')
            }
          })
      })
      .catch(_ => {
        this.toast.warning('Approval cancelled')
      })
  }

  public async approveProd(token: string, executionId: string) {

    // Ensure Developers cannot Publish
    if (this._isDeveloper) {
      console.info('Approve Disabled')

      await this.dialog.open(AlertDialogComponent, {
        position: {top: '200px'},
        data: {
          title: '== Approve Prod Disabled ==',
          message: 'Developer logins cannot Approve Promotion to Production',
        },
      }).afterClosed().toPromise();
      return
    }

    const dialog = this.modalService.open(JiraIssueDialogComponent)
    dialog.result
      .then((res: {jiraIssue: string, description: string}) => {
        const pending: PendingApproval = {
          pipelineApprovalAction: 'ProdApproval',
          pipelineApprovalStage: 'DeployToProd',
          pipelineApprovalToken: token,
          pipelineExecutionId: executionId,
          pipelineName: this.repository + '-pipeline',
        }
        this.approvalsService.approve([pending], res.jiraIssue, res.description)
          .pipe(
            catchError((e: any) => {
              this.toast.error('Failed to publish - ' + e)
              return of(undefined)
            }),
          )
          .subscribe(res => {
            if (res) {
              this.toast.success('Approved')
            }
          })
      })
      .catch(_ => {
        this.toast.warning('Approval cancelled')
      })
  }

  addCommitToRollback(item: CommitLog, event: MatCheckboxChange) {
    if(event.checked) {
      this.commitsToRollback.push(item)
    } else {
      this.commitsToRollback = this.commitsToRollback.filter(commit => commit !== item)
    }
  }

  rollbackCommit() {
    if(this.commitsToRollback.length === 0) {
      this.toast.info('No commits selected to rollback')
      return;
    }
    const decodeContent = this.repository === 'lambda-ingest-product'
    let confirmRollbackComment: string
    of(...this.commitsToRollback).pipe(
      concatMap(value => this.configService.getConfigDifferences(this.repository, value.id, value.parents[0]).pipe(
        map(differences => {
          return {
            commit: value,
            differences: differences
          }
        })
        )
      ),
      toArray(),
      tap(commitDifferences => commitDifferences.sort((a, b) => Date.parse(b.commit.date) - Date.parse(a.commit.date)))
    ).subscribe(commitDifferences => {

      if (!commitDifferences || commitDifferences.length === 0) {
        this.toast.info('This commit does not contain config changes')
        return
      }

      const commitMessages: string[] = commitDifferences.map(diff => diff.commit.message)

      this.dialog.open(ConfirmCommentDialogComponent, {
        width: '500px',
        data: {
          title: 'Do you want to rollback selected commits?',
          description: commitMessages,
          warning: 'Please be aware of any later changes you may also be reverting as a result of this rollback, it may result in the platform being in an unexpected condition. ' +
            'In case of issues please raise a ticket with tech.'
        },
        panelClass: 'dialogFormField500',
      }).afterClosed().subscribe(async confirm => {

        console.log(confirm)
        if (confirm.status === ConfirmStatus.CONFIRMED) {

          if (!confirm.comment) {
            this.toast.error('Rollback message is required')
            return
          }
          confirmRollbackComment = confirm.comment

          const addChanges = from(commitDifferences).pipe(
            concatMap(diff => diff.differences),
            filter(c => c.changeType === 'D' || c.changeType === 'M'),
            mergeMap(c => {
              const blobId = c.beforeBlob.blobId
              const path = c.beforeBlob?.path?.replace('config/', '')

              return this.configService.getConfigBlob(this.repository, blobId,decodeContent).pipe(
                map(b => {
                  return {
                    source: this.repository,
                    name: path,
                    path: path,
                    size: undefined,
                    content: b.data,
                  } as ConfigDetail
                })
              )
            }),
            tap(c => {
              this.changeService.addChange(c)
            })
          )

          const deleteChanges = from(commitDifferences).pipe(
            concatMap(diff => diff.differences),
            filter(c => c.changeType === 'A'),
            map(c => {
              const path = c.afterBlob?.path?.replace('config/', '')
              return {
                source: this.repository,
                name: path,
                path: path,
                size: undefined,
                content: undefined,
              } as ConfigDetail
            }),
            tap(c => {
              this.changeService.deleteObject(c)
            })
          )

          merge(addChanges, deleteChanges).pipe(
            toArray()
          ).subscribe(c => {
            const message = 'Reverting commit \'' + commitMessages.toString() + '\' because \'' + confirmRollbackComment + '\''
            const editedConfigChange = this.changeService.getChanges()
            this.changeService.clearChanges()
            console.log(c, message, editedConfigChange)

            if (editedConfigChange.length === 0) {
              this.toast.warning('Nothing to publish...')
              return
            }
            this.configService.rollbackCommitChanges(message, editedConfigChange)
          })
        } else {
          this.toast.warning('Rollback cancelled')
        }
      })
    })
  }

  showDetails(env: string) {
    this.router.navigate([]).then(result => {window.open(`approvals/docgen/details/${env}`, '_blank')})
  }

  isOk(pipelineStage: StageState, changesForStage: Observable<CommitLog[]>): Observable<boolean> {
    if (pipelineStage.state === 'OK') {
      return of(true)
    }
    if (pipelineStage.state === 'PENDING_APPROVAL') {
      return changesForStage.pipe(
        map(changes => changes === undefined || changes.length === 0)
      )
    }
    return of(false)
  }

  isApprovalNeeded(pipelineStage: StageState, changesForStage: Observable<CommitLog[]>): Observable<boolean> {
    if (pipelineStage.state === 'PENDING_APPROVAL') {
      return changesForStage.pipe(
        map(changes => changes && changes.length > 0)
      )
    }
    return of(false)
  }
}

