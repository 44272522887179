<h2>Dashboard</h2>
<div fxLayout="row">
  <div fxFill>
    <h3>Document Generation Metrics</h3>
    <canvas baseChart width="400" height="100"
            [datasets]="documentsStatisticsChartData"
            [options]="documentsStatisticsOptions"
            [colors]="documentsStatisticsChartColors"
            legend="true"
            chartType="bar">
    </canvas>
  </div>
</div>
<div fxLayout="row">
  <div fxFill>
    <h3>Details</h3>
    <canvas baseChart width="400" height="100"
            [datasets]="documentsDetailsChartData"
            [options]="documentsDetailsOptions"
            [colors]="documentsDetailsChartColors"
            legend="true"
            chartType="bar">
    </canvas>
  </div>
</div>
<div fxLayout="row">
  <div fxFill>
    <h3>Test Metrics</h3>
    <canvas baseChart width="400" height="100"
            [datasets]="testsExecutedChartData"
            [options]="testsExecutedOptions"
            [colors]="testsExecutedChartColors"
            legend="true"
            chartType="bar">
    </canvas>
  </div>
</div>
