import {Component, OnDestroy, OnInit} from '@angular/core'
import {Subscription} from 'rxjs'
import {ChangeService} from '../../services/change.service'

@Component({
  selector: 'app-config-revert',
  templateUrl: './config-revert.component.html',
  styleUrls: ['./config-revert.component.scss'],
})
export class ConfigRevertComponent implements OnInit, OnDestroy {
  sub = new Subscription()

  canRevert = false

  constructor(
    private changeService: ChangeService,
  ) {
  }

  ngOnInit(): void {
    this.sub.add(
      this.changeService.changes.subscribe(changes => {
        this.canRevert = changes.some(c => c.state !== 'original')
      }),
    )
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  revertChanges() {
    this.changeService.clearChanges()
  }
}
